<template>
  <div>
    <h6
      class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
    >
      Dettagli Attività - {{ activity.name }}
    </h6>
    <div>
      <p class="text-left text-medium-grey fw-bolder">
        {{ activity.description }}
      </p>
      <b-alert variant="info" show v-if="!asAdmin">
        Con la somministrazione della presente prestazione, l'operatore
        sanitario, sotto la propria esclusiva responsabilità, si impegna ad
        arruolare e includere nella sperimentazione i soli pazienti aderenti ai
        criteri di inclusione previsti dalle delibere della Regione di
        appartenenza. L'operatore sanitario, pertanto, è consapevole che le
        somministrazioni ad assistiti non aderenti a tali criteri e limiti
        previsti dalle regioni di appartenenza potranno essere escluse dal
        rimborso previsto.
      </b-alert>
      <b-row
        class="text-left mb-3"
        v-if="
          (asAdmin &&
            !user.permessi.includes('gestire aziende propria regione') &&
            !user.permessi.includes('gestire aziende propria asl')) ||
          activity.created_by !== null
        "
      >
        <b-col>
          <b-button
            size="sm"
            variant="outline-primary"
            class="mr-2 my-2"
            @click="openUpdate"
          >
            <b-icon icon="pencil"></b-icon> Modifica attività
          </b-button>
          <b-button size="sm" variant="outline-danger" @click="openDelete()">
            <b-icon icon="bucket"></b-icon> Elimina attività
          </b-button>
        </b-col>
      </b-row>
      <b-row
        :cols="$root.isMobile ? '1' : '2'"
        v-if="surveys && surveys.length > 0"
      >
        <b-col v-for="(surv, ind) in surveys" :key="ind" class="my-2">
          <div
            class="panel__box__shadow bg-light-panel border border-light-panel general_border text-left px-2 py-1"
          >
            <h5 class="fw-bolder">
              {{ surv.name }}
              <b-icon
                icon="circle-fill"
                :variant="
                  asAdmin == true
                    ? surv.enabled == 1
                      ? 'success'
                      : 'danger'
                    : surv.active == 1
                    ? 'success'
                    : 'danger'
                "
              ></b-icon>
            </h5>
            <p>{{ surv.description }}</p>
            <template v-if="activity.prof2 !== 1">
              <p class="text-capitalize mb-0">{{ surv.tipo }}</p>
              <p class="text-capitalize">
                somministrati:
                {{ asAdmin == true ? surv.tot_filled : surv.filled }}
              </p>
              <p v-if="surv.cap" class="mb-0">
                Compilazioni richieste: {{ surv.cap }}
              </p>
            </template>
            <div class="d-flex justify-content-between">
              <span class="text-medium-grey" v-if="surv.created_at">
                {{ surv.created_at | moment("DD/MM/YYYY") }}
              </span>
              <span class="text-medium-grey" v-else>--</span>
              <div>
                <b-button
                  v-if="activity.prof2 !== 1"
                  variant="info info_gradient fw-bolder rounded-pill px-3"
                  size="sm"
                  @click="selectThis(surv)"
                  v-b-tooltip.hover
                  title="Visualizza"
                >
                  <b-icon icon="eye-fill"></b-icon>
                </b-button>
                <!-- progetto prof2 -->
                <b-button
                  v-if="activity.prof2 === 1"
                  variant="secondary secondary_gradient fw-bolder rounded-pill px-3"
                  size="sm"
                  @click="
                    $router.push({
                      name: 'progetto prof2',
                      query: { activity: activity.name },
                    })
                  "
                  v-b-tooltip.hover
                  title="Report"
                >
                  <b-icon icon="collection-fill"></b-icon>
                </b-button>
                <b-button
                  v-else
                  variant="secondary secondary_gradient fw-bolder rounded-pill px-3"
                  size="sm"
                  @click="
                    $router.push({
                      name: asAdmin
                        ? 'report questionario'
                        : 'report per questionario',
                      params: { quest: surv.hashid },
                    })
                  "
                  v-b-tooltip.hover
                  title="Report"
                >
                  <b-icon icon="collection-fill"></b-icon>
                </b-button>
                <b-button
                  v-if="!asAdmin"
                  variant="primary primary_gradient fw-bolder rounded-pill px-3"
                  size="sm"
                  @click="
                    $router.push({
                      name: 'somministra questionario',
                      params: { quest: surv.hashid },
                      query: { activity: activity.name },
                    })
                  "
                  v-b-tooltip.hover
                  :title="
                    activity.prof2 === 1 ? 'Nuova Terapia' : 'Somministra'
                  "
                  :disabled="surv.active === 0"
                >
                  <b-icon icon="clipboard-plus"></b-icon>
                </b-button>
              </div>
            </div>
          </div>
        </b-col>
        <b-col class="my-2">
          <template v-if="asAdmin">
            <b-button
              v-if="
                !user.permessi.includes('gestire aziende propria regione') &&
                !user.permessi.includes('gestire aziende propria asl')
              "
              variant="e"
              class="d-flex align-items-center justify-content-between fw-bolder"
              @click="
                $router.push({
                  name: 'nuovo questionario',
                  query: { activity: activity.hashid },
                })
              "
            >
              <b-iconstack font-scale="2.3">
                <b-icon stacked icon="circle-fill" variant="primary"></b-icon>
                <b-icon
                  stacked
                  icon="plus"
                  scale="0.8"
                  variant="white"
                ></b-icon>
                <b-icon stacked icon="circle" variant="primary"></b-icon>
              </b-iconstack>
              <span> Nuovo Questionario</span>
            </b-button>
          </template>
          <b-button
            v-else-if="activity.created_by !== null"
            variant="e"
            class="d-flex align-items-center justify-content-between fw-bolder"
            @click="
              $router.push({
                name: 'crea questionario',
                query: { activity: activity.hashid },
              })
            "
          >
            <b-iconstack font-scale="2.3">
              <b-icon stacked icon="circle-fill" variant="primary"></b-icon>
              <b-icon stacked icon="plus" scale="0.8" variant="white"></b-icon>
              <b-icon stacked icon="circle" variant="primary"></b-icon>
            </b-iconstack>
            <span> Nuovo Questionario</span>
          </b-button>
          <b-button
            variant="secondary secondary_gradient text-white fw-bolder spacing_1"
            class="m-1"
            @click="downloadReport"
            >Scarica report attività in formato pdf</b-button
          >
          <b-button
            variant="secondary secondary_gradient text-white fw-bolder spacing_1"
            class="m-1"
            @click="downloadXlsReport"
            >Scarica report attività in formato xlsx</b-button
          >
          <!-- <b-button
            variant="e"
            class="d-flex align-items-center justify-content-between fw-bolder"
            @click="
              $router.push({
                name: asAdmin ? 'nuovo questionario' : 'crea questionario',
                query: { activity: activity.hashid },
              })
            "
          >
            <b-iconstack font-scale="2.3">
              <b-icon stacked icon="circle-fill" variant="primary"></b-icon>
              <b-icon stacked icon="plus" scale="0.8" variant="white"></b-icon>
              <b-icon stacked icon="circle" variant="primary"></b-icon>
            </b-iconstack>
            <span> Nuovo Questionario</span>
          </b-button> -->
        </b-col>
      </b-row>
      <p class="text-left" v-else>
        Nessun questionario per l'attività.
        <template v-if="asAdmin">
          <b-button
            v-if="
              !user.permessi.includes('gestire aziende propria regione') &&
              !user.permessi.includes('gestire aziende propria asl')
            "
            variant="e"
            class="d-flex align-items-center justify-content-between fw-bolder"
            @click="
              $router.push({
                name: 'nuovo questionario',
                query: { activity: activity.hashid },
              })
            "
          >
            <b-iconstack font-scale="2.3">
              <b-icon stacked icon="circle-fill" variant="primary"></b-icon>
              <b-icon stacked icon="plus" scale="0.8" variant="white"></b-icon>
              <b-icon stacked icon="circle" variant="primary"></b-icon>
            </b-iconstack>
            <span> Nuovo Questionario</span>
          </b-button>
        </template>
        <b-button
          v-else-if="activity.created_by !== null"
          variant="e"
          class="d-flex align-items-center justify-content-between fw-bolder"
          @click="
            $router.push({
              name: 'crea questionario',
              query: { activity: activity.hashid },
            })
          "
        >
          <b-iconstack font-scale="2.3">
            <b-icon stacked icon="circle-fill" variant="primary"></b-icon>
            <b-icon stacked icon="plus" scale="0.8" variant="white"></b-icon>
            <b-icon stacked icon="circle" variant="primary"></b-icon>
          </b-iconstack>
          <span> Nuovo Questionario</span>
        </b-button>
      </p>
    </div>

    <b-modal ref="modal-showsurvey" :title="modalTitle" size="xl" hide-footer>
      <SurveyShower v-if="selectedRow" :survey="selectedRow" class="px-3" />
    </b-modal>
    <b-modal
      id="del-confirm"
      centered
      title="Conferma operazione"
      ok-title="Sì, conferma"
      cancel-title="No, annulla"
      ok-variant="danger"
      cancel-variant="outline-primary"
      footer-border-variant="white"
      button-size="sm"
      @cancel="$bvModal.hide('del-confirm')"
      @ok="doDelete"
    >
      <h5>Vuoi eliminare l'attività "{{ activity.name }}"?</h5>
      <p>
        L'azione comporta l'eliminazione di tutti i questionari dell'attività e
        gli eventuali relativi questionari compilati.
      </p>
    </b-modal>
    <b-modal
      centered
      id="edit-ativity"
      title="Modifica Attività"
      size="lg"
      ok-title="Salva modifiche"
      cancel-title="Annulla"
      ok-variant="primary primary_gradient"
      cancel-variant="outline-danger"
      footer-border-variant="white"
      @cancel="$bvModal.hide('edit-ativity')"
      @ok="doUpdate"
    >
      <b-form class="text-left fw-bolder">
        <b-form-group id="name" label="Nome*:" label-for="name">
          <b-form-input
            id="name"
            v-model="editForm.name"
            type="text"
            placeholder="Nome attività.."
            class="shadow_6"
            required
            :state="validName"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="description"
          label="Descrizione:"
          label-for="description"
        >
          <b-form-input
            id="description"
            v-model="editForm.description"
            type="text"
            placeholder="Descrizione attività.."
            class="shadow_6"
          ></b-form-input>
        </b-form-group>
        <!-- <div class="text-center">
        <b-button
          type="submit"
          variant="primary primary_gradient text-white fw-bolder spacing_1"
          >Crea attività</b-button
        >
      </div> -->
      </b-form>
    </b-modal>
  </div>
</template>
<script>
import { supportService } from "@/_services";
import { mapState, mapActions } from "vuex";
import SurveyShower from "@/components/attivita/SurveyShower.vue";
export default {
  name: "ActivityDetails",
  props: ["activity", "asAdmin"],
  components: { SurveyShower },
  data() {
    return {
      surveys: null,
      filterSetted: {
        search: null,
        position_ref: null,
        page: 1,
        take: 50,
        activity: null,
      },
      selectedRow: null,
      modalTitle: null,
      editForm: {
        name: null,
        description: null,
      },
    };
  },
  computed: {
    validName: function () {
      var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
      if (this.activity.name !== null && this.activity.name.length > 0) {
        return format.test(this.activity.name) ? false : true;
      }
      return false;
    },
    ...mapState({
      alert: (state) => state.alert,
    }),
    ...mapState("utente", ["status", "user"]),
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    fetchSurveys() {
      var self = this;
      supportService
        .getSurveys(this.filterSetted)
        .then(function (response) {
          self.surveys = response.data.data.data;
          self.filterSetted.page = response.data.data.current_page;
          // self.total = response.data.data.total;
          // self.fromPage = response.data.data.from;
          // self.toPage = response.data.data.to;
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare le attività");
          console.log(err);
        });
    },
    downloadReport() {
      var self = this;
      supportService
        .reportActivityPdf(this.activity.hashid)
        .then(function (response) {
          let blob = new Blob([response.data]);
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "Report_" + self.activity.name + ".pdf";
          link.click();
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          self.errorAlert(
            "Non è stato possibile scaricare il report l'attività"
          );
          console.log(err);
        });
    },
    downloadXlsReport() {
      var self = this;
      supportService
        .reportActivityXls(this.activity.hashid)
        .then(function (response) {
          let blob = new Blob([response.data]);
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "Report_" + self.activity.name + ".xlsx";
          link.click();
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          self.errorAlert(
            "Non è stato possibile scaricare il report l'attività"
          );
          console.log(err);
        });
    },
    selectThis(row) {
      this.selectedRow = row;
      this.modalTitle = row.name;
      this.$refs["modal-showsurvey"].show();
    },
    openDelete(item) {
      this.selectedItem = item;
      this.$bvModal.show("del-confirm");
    },
    doDelete() {
      this.$bvModal.hide("del-confirm");
      this.$emit("do-delete", this.activity.hashid);
    },
    openUpdate() {
      this.editForm = {
        name: this.activity.name,
        description: this.activity.description,
      };
      this.$bvModal.show("edit-ativity");
    },
    doUpdate() {
      this.$bvModal.hide("edit-ativity");
      let data = {
        form: this.editForm,
        hashid: this.activity.hashid,
      };
      this.$emit("do-update", data);
    },
  },
  watch: {
    activity(val) {
      this.filterSetted.activity = val.name;
      this.filterSetted.take = val.tot_surveys + 1;
      this.fetchSurveys();
    },
  },
  created() {
    if (this.activity) {
      this.filterSetted.activity = this.activity.name;
      this.filterSetted.take = this.activity.tot_surveys + 1;
      this.fetchSurveys();
    }
    // //console.log(this.activity);
  },
};
</script>
