<template>
  <div v-if="detail">
    <div class="">
      <p class="mb-0">
        Durata prevista: <strong>{{ detail.durata }} minuti</strong>
        <span class="mb-0" v-if="stato == 4">
          <br />
          Durata effettiva:
          <strong>{{ detail.durata_effettiva }} minuti</strong>
        </span>
        <br />
      </p>
      <b-button v-if="stato === 2 || stato === 3" @click="
        $router.push({ name: 'il tuo consulto', params: { reserv: hash } })
      " type="button" variant="primary primary_gradient fw-bolder text-white mr-0 ml-auto">Vai al Teleconsulto
      </b-button>
    </div>

    <div class="mt-2 mb-4" v-if="suspended">
      <h5 class="text-secondary fw-bolder text-danger">
        Questionario da completare !
      </h5>
      <b-button size="sm" variant="ter-1 rounded-pill fw-bolder" @click="
        $router.push({
          name: 'completa questionario',
          params: { quest: suspended.hashid },
        })
      "><span class="mx-1 text-white">Completa questionario</span>
        <b-icon icon="patch-exclamation-fill" variant="white"></b-icon>
      </b-button>
    </div>
    <div class="mt-2 mb-4" v-if="survey !== null && survey.response">
      <h5 class="text-secondary fw-bolder">Questionario:</h5>
      <b-button variant="info info_gradient fw-bolder rounded-pill" size="sm" class="px-2 m-1"
        @click="$refs['show-filleditem'].show()">apri questionario</b-button>
      <b-button variant="secondary secondary_gradient fw-bolder rounded-pill" size="sm" class="px-2 m-1"
        @click="downloadOne">scarica in formato pdf</b-button>
    </div>
    <p class="mb-0" v-if="detail.details">
      Dettagli:
      <strong>{{ detail.details }} </strong>
    </p>

    <div class="d-flex flex-wrap my-2" v-if="Math.abs(stato) !== 4 && checkValidity">
      <template v-if="stato === 5">
        <b-button @click="confirmRequest" type="button" variant="primary primary_gradient fw-bolder text-white"
          class="col-12 my-1 col-lg-6 my-lg-0">Conferma Prenotazione</b-button>
        <b-button @click="setAction('reject')" type="button" variant="outline-danger fw-bolder"
          class="col-12 my-1 col-lg-6 my-lg-0">Rifiuta Modifiche
        </b-button>
      </template>
      <b-button v-else-if="stato > 1" @click="setAction('cancel')" type="button" variant="outline-danger fw-bolder">
        <b-spinner variant="primary" label="loading" v-if="loading"></b-spinner>
        <span v-else> Annulla Prenotazione</span>
      </b-button>
    </div>
    <b-modal size="xl" hide-footer centered ref="modal-confirm" title="Conferma Annullamento">
      <p class="mb-0">Sei sicuro di voler annullare questo appuntamento?</p>
      <div>
        <b-form-group label="Allega messaggio:" v-slot="{ ariaDescribedby }">
          <b-form-radio class="my-1" v-model="message" :aria-describedby="ariaDescribedby" :value="1">"{{ msgOptions[1]
          }}"</b-form-radio>
          <b-form-radio class="my-1" v-model="message" :aria-describedby="ariaDescribedby" :value="2">"{{ msgOptions[2]
          }}"</b-form-radio>
          <!-- <b-form-radio
            class="my-1"
            v-model="message"
            :aria-describedby="ariaDescribedby"
            :value="3"
            >"{{ msgOptions[3] }}"</b-form-radio
          >
          <b-form-radio
            class="my-1"
            v-model="message"
            :aria-describedby="ariaDescribedby"
            :value="4"
            >"{{ msgOptions[4] }}"</b-form-radio
          > -->
          <b-form-radio v-model="message" :aria-describedby="ariaDescribedby" :value="6">nessun messaggio</b-form-radio>
          <b-form-radio v-model="message" :aria-describedby="ariaDescribedby" :value="5">scrivi messaggio</b-form-radio>
          <b-form-input v-if="message == 5" class="simple__input border-top-0 border-left-0 border-right-0"
            name="freeText" type="text" placeholder="scrivi messaggio..." v-model="freeText"></b-form-input>
        </b-form-group>
      </div>
      <div class="d-flex justify-content-end">
        <b-button @click="$refs['modal-confirm'].hide()" type="button" variant="info fw-bolder ">No, chiudi</b-button>
        <b-button @click="cancelRequest" type="button" variant="outline-danger fw-bolder ml-2">Sì, procedi</b-button>
      </div>
    </b-modal>
    <b-modal ref="show-filleditem" hide-footer title="Questionario" size="xl">
      <FilledSurveyShower v-if="survey" :response="survey.response"  :score="survey.score"/>
    </b-modal>
  </div>
</template>
<script>
import * as moment from "moment/moment";
import { supportService } from "@/_services";
import FilledSurveyShower from "@/components/attivita/FilledSurveyShower.vue";
export default {
  name: "UserDetailTeleconsulto",
  props: ["detail", "stato", "datainizio", "quest", "hash", "suspended"],
  components: { FilledSurveyShower },
  data() {
    return {
      message: 6,
      msgOptions: {
        1: "Non sono più interessato.",
        2: "Non posso rispettare l'impegno.",
        3: "",
        4: "",
        5: null,
        6: null,
      },
      freeText: null,
      actionName: null,
      loading: false,
      allegati: null,
      survey: null,
      editBtn: false,
    };
  },
  computed: {
    checkValidity: function () {
      var data = moment(this.datainizio);
      //console.log("data", data);
      return moment().isBefore(data);
    },
  },
  methods: {
    confirmRequest() {
      //console.log(this.detail.hashid);
      var data = {
        confirmed: true,
        action: "confirm",
      };
      this.$emit("update-request-status", data);
    },
    setAction(action) {
      this.actionName = action;
      this.$refs["modal-confirm"].show();
    },
    cancelRequest() {
      this.loading = true;
      var data = {
        confirmed: false,
        message: this.freeText,
        action: this.actionName,
      };
      //console.log(data);
      if (this.message !== null && this.message !== 5) {
        data = {
          confirmed: false,
          message: this.msgOptions[this.message],
          action: this.actionName,
        };
      }
      if (this.message === 6) {
        data = {
          confirmed: false,
          message: null,
          action: this.actionName,
        };
      }
      this.$refs["modal-confirm"].hide();
      this.$emit("update-request-status", data);
    },
    downloadOne() {
      var self = this;
      supportService
        .downloadFilledSurvey(this.quest.hashid)
        .then(function (response) {
          let blob = new Blob([response.data]);
          // let blob = new Blob([response.data], { type: "text/csv" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "teleconsulto_questionario.pdf";
          link.click();
        })
        .catch(function (err) {
          console.log(err);
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare il questionario");
        });
    },
  },
  watch: {
    quest(val) {
      if (val) {
        this.survey = val;
      } else {
        this.survey = null;
      }
    },
  },
  created() {
    if (this.quest) {
      this.survey = this.quest;
    }
  },
};
</script>
<style >
.simple__input {
  border-radius: 0 !important;
  font-size: 93% !important;
}

.simple__input:focus {
  box-shadow: none !important;
}
</style>