<template>
  <div v-if="contratti">
    <b-row cols="1" cols-md="2" class="text-left">
      <h6 class="mt-3 pl-3">Licenza d'uso</h6>
      <a
        v-if="contratti.contratto"
        :href="contratti.contratto"
        target="_blank"
        class="bg-primary text-white text-center my-1 py-2 fw-bolder"
        >Vedi e scarica contratto</a
      ><span v-else class="py-2"> documento assente</span>
      <h6 class="mt-3 pl-3">Nomina responsabili esterni Promofarma</h6>
      <a
        v-if="contratti.nomina_p"
        :href="contratti.nomina_p"
        target="_blank"
        class="bg-primary text-white text-center my-1 py-2 fw-bolder"
        >Vedi e scarica nomina Promofarma</a
      >
      <span v-else class="py-2"> documento assente</span>
      <h6 class="mt-3 pl-3">Nomina responsabili esterni Qwince</h6>
      <a
        v-if="contratti.nomina_q"
        :href="contratti.nomina_q"
        target="_blank"
        class="bg-primary text-white text-center my-1 py-2 fw-bolder"
        >Vedi e scarica nomina Qwince</a
      >
      <span v-else class="py-2"> documento assente</span>
    </b-row>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { adminService } from "@/_services";
export default {
  name: "ContrattiFarmacia",
  props: ["hashid"],
  data() {
    return {
      contratti: null,
    };
  },
  computed: {
    ...mapState("utente", ["status", "user"]),
    ...mapState("company", ["companyLoggedIn", "company"]),
    ...mapState({
      alert: (state) => state.alert,
    }),
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    fetchData() {
      var self = this;
      adminService
        .getContrattiFarmacia(this.hashid)
        .then(function (res) {
          self.contratti = res.data.data;
        })
        .catch(function (error) {
          self.errorAlert("Si è verificato un errore inatteso.");
        });
    },
  },
  created() {
    this.fetchData();
  },
};
</script>
