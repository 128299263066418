<template>
  <div class="home">
    <b-container class="mb-3" v-if="user">
      <b-row cols="3">
        <b-col>
          <div class="bg-white shadow_7 general_border my-3 pb-3">
            <div class="col-6 mx-auto">
              <p class="h1 mb-2 py-4">
                <b-icon icon="card-list"></b-icon>
              </p>
            </div>
            <p class="fw-bolder text-secondary">Gestione aziende</p>

            <div class="col-10 mx-auto">
              <p class="fs-6 text-medium-grey">Gestione aziende</p>
            </div>
            <div class="col-12 pb-4">
              <router-link
                class="primary_gradient btn btn-primary py-1 rounded-pill text-white fw-bolder"
                :to="{ name: 'gestione aziende' }"
              >
                <span class="fs-5 text-white spacing_1">Accedi</span>
              </router-link>
            </div>
          </div>
        </b-col>
        <b-col>
          <div class="bg-white shadow_7 general_border my-3 pb-3">
            <div class="col-6 mx-auto">
              <p class="h1 mb-2 py-4">
                <b-icon icon="list-check"></b-icon>
              </p>
            </div>
            <p class="fw-bolder text-secondary">Gestione attività</p>

            <div class="col-10 mx-auto">
              <p class="fs-6 text-medium-grey">Attività e questionari</p>
            </div>
            <div class="col-12 pb-4">
              <router-link
                tag="button"
                class="primary_gradient btn btn-primary py-1 rounded-pill text-white fw-bolder"
                :to="'/admin/attivita'"
              >
                <span class="fs-5 text-white spacing_1">Accedi</span>
              </router-link>
            </div>
          </div>
        </b-col>
        <b-col
          v-if="
            !user.permessi.includes('gestire aziende propria regione') &&
            !user.permessi.includes('gestire aziende propria asl')
          "
        >
          <div class="bg-white shadow_7 general_border my-3 pb-3">
            <div class="col-6 mx-auto">
              <p class="h1 mb-2 py-4">
                <b-icon icon="journal-medical"></b-icon>
              </p>
            </div>
            <p class="fw-bolder text-secondary">Gestione servizi</p>

            <div class="col-10 mx-auto">
              <p class="fs-6 text-medium-grey">Facile, Connessa,PT</p>
            </div>
            <div class="col-12 pb-4">
              <router-link
                tag="button"
                class="primary_gradient btn btn-primary py-1 rounded-pill text-white fw-bolder"
                :to="{ name: 'gestione servizi' }"
              >
                <span class="fs-5 text-white spacing_1">Accedi</span>
              </router-link>
            </div>
          </div>
        </b-col>
        <b-col
          v-if="
            !user.permessi.includes('gestire aziende propria regione') &&
            !user.permessi.includes('gestire aziende propria asl')
          "
        >
          <div class="bg-white shadow_7 general_border my-3 pb-3">
            <div class="col-6 mx-auto">
              <p class="h1 mb-2 py-4">
                <b-icon icon="clipboard-data"></b-icon>
              </p>
            </div>
            <p class="fw-bolder text-secondary">Dati Applicazione</p>

            <div class="col-10 mx-auto">
              <p class="fs-6 text-medium-grey">Dati applicazione</p>
            </div>
            <div class="col-12 pb-4">
              <router-link
                class="primary_gradient btn btn-primary py-1 rounded-pill text-white fw-bolder"
                :to="{ name: 'dati applicazione' }"
              >
                <span class="fs-5 text-white spacing_1">Accedi</span>
              </router-link>
            </div>
          </div>
        </b-col>
        <b-col v-if="user.permessi.includes('vedere log di sistema')">
          <div class="bg-white shadow_7 general_border my-3 pb-3">
            <div class="col-6 mx-auto">
              <p class="h1 mb-2 py-4">
                <b-icon icon="graph-up"></b-icon>
              </p>
            </div>
            <p class="fw-bolder text-secondary">Log di sistema</p>

            <div class="col-10 mx-auto">
              <p class="fs-6 text-medium-grey">Log di sistema</p>
            </div>
            <div class="col-12 pb-4">
              <router-link
                class="primary_gradient btn btn-primary py-1 rounded-pill text-white fw-bolder"
                :to="{ name: 'log di sistema' }"
              >
                <span class="fs-5 text-white spacing_1">Accedi</span>
              </router-link>
            </div>
          </div>
        </b-col>
        <!-- <b-col v-if="user.permessi.includes('vedere pt')">
          <div class="bg-white shadow_7 general_border my-3 pb-3">
            <div class="col-6 mx-auto">
              <p class="h1 mb-2 py-4">
                <b-icon icon="journal-medical"></b-icon>
              </p>
            </div>
            <p class="fw-bolder text-secondary">Piani Terapeutici</p>

            <div class="col-10 mx-auto">
              <p class="fs-6 text-medium-grey">Piani Terapeutici</p>
            </div>
            <div class="col-12 pb-4">
              <router-link
                class="primary_gradient btn btn-primary py-1 rounded-pill text-white fw-bolder"
                :to="{ name: 'piani asl' }"
              >
                <span class="fs-5 text-white spacing_1">Accedi</span>
              </router-link>
            </div>
          </div>
        </b-col> -->
        <b-col v-if="user.permessi.includes('vedere utenti')">
          <div class="bg-white shadow_7 general_border my-3 pb-3">
            <div class="col-6 mx-auto">
              <p class="h1 mb-2 py-4">
                <b-icon icon="people"></b-icon>
              </p>
            </div>
            <p class="fw-bolder text-secondary">Utenti</p>

            <div class="col-10 mx-auto">
              <p class="fs-6 text-medium-grey">Cerca utente</p>
            </div>
            <div class="col-12 pb-4">
              <router-link
                class="primary_gradient btn btn-primary py-1 rounded-pill text-white fw-bolder"
                :to="{ name: 'cerca utente' }"
              >
                <span class="fs-5 text-white spacing_1">Accedi</span>
              </router-link>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <!-- <img alt="Vue logo" src="../assets/logo.png" /> -->
    <!-- {{ user }} -->
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

import { mapState, mapActions } from "vuex";
export default {
  name: "AdminHome",
  components: {
    // HelloWorld,
  },
  computed: {
    ...mapState("utente", ["status", "user"]),
  },
  mounted() {
    //console.log(this.user);
  },
};
</script>
