<template>
  <b-container fluid>
    <b-form @submit.prevent="saveSchedule">
      <b-row
        cols="1"
        class="my-2 col-12 px-0 align-items-center align-content-center flex-wrap my-2"
      >
        <!-- <div class="text-left col-12 px-0"> -->
        <!-- <b-row
            class="
              my-2
              col-12
              px-0
              align-items-center align-content-center
              flex-wrap
              my-2
            "
          > -->
        <SelectTherapy
          :subject="subject"
          v-on:selected-therapy="selectTherapy($event)"
        />
        <!-- </b-row> -->
        <!-- </div> -->
      </b-row>
      <template v-if="selectedTherapy">
        <b-row cols="1" class="my-2 pt-1">
          <h6
            class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
          >
            Dettaglio terapia
          </h6>
          <div class="text-left">
            <p class="font-weight-bolder text-medium-grey mb-0">
              Specifica la quantità di farmaco di cui dispone il paziente ed
              indica la data della prima somministrazione affinché il sistema
              possa calcolare correttamente i giorni in cui inviare la notifica.
            </p>
            <p class="font-weight-bolder text-medium-grey pb-2">
              La terapia selezionata prevede
              {{ posNum }}
              {{
                posNum > 1
                  ? selectedTherapy.posology_type
                  : typesMap[selectedTherapy.posology_type]
              }}
              di {{ selectedTherapy.product_name }}
              {{ selectedTherapy.posologia.tipo }}
              <span v-if="selectedTherapy.posologia.frequenza">
                {{ selectedTherapy.posology_every_num }}
                {{ selectedTherapy.posologia.frequenza }}
              </span>
              per via
              <span class="text-lowercase"
                >{{ selectedTherapy.posology_via }}.
              </span>
            </p>
            <b-col class="px-0">
              <b-row cols="1" class="font-weight-bolder">
                <b-col class="my-1">
                  <b-form-group class="col-12 col-md-10 col-lg-7 px-0">
                    <label class="mb-3" for=""
                      >Confezioni consegnate al paziente*</label
                    >
                    <b-input-group
                      class="shadow_6"
                      style="border-radius: 0.7rem"
                    >
                      <b-form-input
                        id="num-conf"
                        name="num-conf"
                        type="number"
                        min="1"
                        v-model="form.confezioni"
                        required
                      ></b-form-input>
                      <template #append v-if="!$root.isMobile">
                        <b-input-group-text
                          class="text-white bg-prim-grad-1 border-prim-grad-1 font-weight-bolder"
                          >Confezioni</b-input-group-text
                        >
                      </template>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col class="my-1">
                  <b-form-group class="col-12 col-md-10 col-lg-7 px-0">
                    <label class="mb-3" for=""
                      >{{ selectedTherapy.posology_type }} per
                      confezione*</label
                    >
                    <b-input-group
                      class="shadow_6"
                      style="border-radius: 0.7rem"
                    >
                      <b-form-input
                        id="num-conf"
                        name="num-conf"
                        type="number"
                        min="1"
                        v-model="form.uic"
                        required
                      >
                      </b-form-input>
                      <template #append v-if="!$root.isMobile">
                        <b-input-group-text
                          class="text-white bg-prim-grad-1 border-prim-grad-1 font-weight-bolder"
                          >{{ selectedTherapy.posology_type }} per
                          confezione</b-input-group-text
                        >
                      </template>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col class="my-1">
                  <b-form-group class="col-12 col-md-10 col-lg-7 px-0">
                    <label class="mb-3" for="">Data inizio terapia*</label>
                    <b-form-input
                      class="shadow_6"
                      name="start_at"
                      type="date"
                      v-model="form.start_at"
                      required
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </div>
        </b-row>
        <b-row cols="1" class="my-2 pt-1">
          <h6
            class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
          >
            Pianificazione
          </h6>
          <p class="font-weight-bolder text-medium-grey">
            Il sistema invierà al paziente una notifica in prossimità
            dell'esaurimento delle sue scorte di farmaco. Seleziona una o più
            opzioni relative a frequenza e canale di notifica, e indica la
            fascia oraria di preferenza.
          </p>
        </b-row>
        <b-row cols="2" class="my-3 mt-2">
          <b-col class="font-weight-bolder">
            <b-form-group label="Invia notifica*" v-slot="{ ariaDescribedby }">
              <b-form-checkbox-group
                id="checkbox-freq"
                v-model="form.schedule"
                :options="scheduleOptions"
                :aria-describedby="ariaDescribedby"
                name="checkbox-freq"
                stacked
              ></b-form-checkbox-group>
              <span
                class="fs-5"
                :class="
                  form.schedule.length > 3 ? 'text-danger' : 'text-font-grey'
                "
                >**min 1 opzione, max 3 opzioni</span
              >
            </b-form-group>
          </b-col>
          <b-col class="font-weight-bolder">
            <b-form-group label="Via*" v-slot="{ ariaDescribedby }">
              <b-form-checkbox-group
                id="checkbox-channel"
                v-model="form.channels"
                :options="channels"
                :aria-describedby="ariaDescribedby"
                name="checkbox-channel"
                stacked
              ></b-form-checkbox-group>
              <span class="fs-5 text-font-grey">**min 1 opzione</span>
            </b-form-group>
          </b-col>
          <b-col class="col-12">
            <b-form-group
              class="font-weight-bolder"
              label="Il paziente riceverà la notifica* "
              :description="timingDescription"
            >
              <b-form-radio-group
                id="radio-timing"
                v-model="form.timing"
                name="radio-timing"
              >
                <b-form-radio :value="0">al mattino</b-form-radio>
                <b-form-radio :value="1">alla sera</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row cols="1" class="my-2 pt-1">
          <h6
            class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
          >
            Note per il paziente
          </h6>
          <p class="font-weight-bolder text-medium-grey pb-2">
            L'eventuale nota sarà aggiunta alla notifica inviata al paziente, ad
            eccezione degli sms.
          </p>
          <b-col class="col-12 col-md-10 mt-2">
            <b-form-group>
              <b-form-textarea
                style="overflow-y: auto"
                class="shadow_6"
                v-model="form.company_notes"
                placeholder="Se vuoi, aggiungi una nota per il paziente..."
                rows="2"
                max-rows="2"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
          <div class="text-left">
            <p>*parametri obbligatori</p>
          </div>
        </b-row>
        <b-row cols="2" class="justify-content-around col-10">
          <b-col class="col-12 col-md-6 my-1 text-center">
            <b-button
              type="button"
              variant="outline-danger"
              class="font-weight-bolder"
              @click="
                $router.push({
                  name: 'impostazione alerts',
                  params: { hash: $route.params.hash },
                })
              "
              >Annulla</b-button
            >
          </b-col>
          <b-col class="col-12 col-md-6 my-1 text-center">
            <b-button
              type="submit"
              variant="primary primary__gradient"
              class="font-weight-bolder text-white"
              >Salva pianificazione</b-button
            >
          </b-col>
        </b-row>
      </template>
    </b-form>
  </b-container>
</template>
<script>
import * as moment from "moment/moment";
import { utilityService, supportService } from "@/_services";
import SelectTherapy from "@/components/profile/sanitario/SelectTherapy.vue";
import { mapState, mapActions } from "vuex";
export default {
  name: "NewAlert",
  props: ["subject", "atype"],
  components: { SelectTherapy },
  data() {
    return {
      form: {
        type: 0,
        user_id: null,
        alertable_id: null,
        alertable_type: null,
        channels: [0],
        schedule: [1],
        confezioni: 1,
        uic: 10,
        start_at: null,
        timing: 1,
        company_notes: null,
      },
      selectedTherapy: null,
      channels: [
        { text: "email", value: 0, disabled: false },
        { text: "telegram/whatsapp", value: 2, disabled: false },
        { text: "sms", value: 1, disabled: true },
        { text: "chiamata operatore", value: 3, disabled: true },
      ],
      scheduleOptions: [
        { text: "1 giorno prima", value: 1, disabled: false },
        { text: "2 giorni prima", value: 2, disabled: false },
        { text: "3 giorni prima", value: 3, disabled: false },
        { text: "5 giorni prima", value: 5, disabled: false },
        { text: "10 giorni prima", value: 10, disabled: false },
        { text: "15 giorni prima", value: 15, disabled: false },
        { text: "20 giorni prima", value: 20, disabled: false },
        { text: "30 giorni prima", value: 30, disabled: false },
      ],
      typesMap: {
        Compresse: "compressa",
        Capsule: "capsula",
        Gocce: "goccia",
        Inalazioni: "inalazione",
        Buste: "busta",
        Supposte: "supposta",
        Scatole: "scatola",
        Cerotti: "cerotto",
        Fiale: "fiala",
        Fleboclisi: "fleboclisi",
        Ovuli: "ovulo",
        Sacche: "sacca",
        Flaconi: "flacone",
        Confezioni: "confezione",
        Kilogrammi: "kilogrammo",
        Kit: "kit",
        Millilitri: "millilitro",
        Milligrammi: "milligrammo",
        Applicazioni: "applicazione",
        UI: "UI",
      },
      forEveryUmsSing: {
        0: "mese",
        1: "settimana",
        2: "giorno",
      },
      forEveryUmsPlur: {
        0: "mesi",
        1: "settimane",
        2: "giorni",
      },
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
    posNum: function () {
      return this.selectedTherapy ? this.selectedTherapy.posology_num * 1 : 0;
    },
    timingDescription: function () {
      let time = this.form.timing === 1 ? "20:00" : "8:00";
      return "la notifica verrà inviata intorno alle ore " + time;
    },
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    formatData(data) {
      return moment(data).format("DD MMM YYYY");
    },
    selectTherapy(therapy) {
      this.selectedTherapy = therapy;
      this.form.alertable_id = therapy.hashid;
      this.form.alertable_type = "terapia";
      this.form.start_at = therapy.start_at;
    },
    saveSchedule() {
      //console.log(this.form);
      var self = this;
      supportService
        .storeAlerts(this.form)
        .then(function (response) {
          //console.log(response.data.data);
          if (response.data.data.error) {
            window.scrollTo(0, 0);
            self.errorAlert(response.data.data.error);
          } else {
            self.$emit("save-alerts", response.data.data);
            self.successAlert("Notifica salvata");
          }
        })
        .catch(function (error) {
          console.log(error);
          self.errorAlert("Si è verificato un errore durante il salvataggio");
        });
    },
  },
  created() {
    if (this.subject) {
      this.form.user_id = this.subject.hashid;
    }
  },
  mounted() {
    var today = moment();
    this.form.start_at = today.format("YYYY-MM-DD");
  },
};
</script>
