<template>
  <b-container class="mt-2 px-4">
    <b-row class="pt-4">
      <b-col class="col-12 col-lg-7">
        <h5 class="text-prim-grad-2 fw-bolder text-left pb-1">
          Frequenza, modalità e dosaggio somministrazione:
        </h5>
        <div class="my-2">
          <h6 class="font-weight-bolder mt-4 pb-2 text-uppercase">
            {{ posNum }}
            {{
              posNum > 1
                ? therapy.posology_type
                : typesMap[therapy.posology_type]
            }}
            di {{ therapy.product_name }} {{ therapy.posologia.tipo }}
            <span v-if="therapy.posologia.frequenza">
              {{ therapy.posology_every_num }} {{ therapy.posologia.frequenza }}
            </span>
            per via
            <span>{{ therapy.posology_via }}</span>
          </h6>
          <p
            class="mb-4 text-transform-italic"
            v-if="therapy.product_principio_attivo"
          >
            Principio attivo: {{ therapy.product_principio_attivo }}
          </p>
        </div>
      </b-col>
      <b-col class="col-12 col-lg-5">
        <h5 class="text-prim-grad-2 fw-bolder text-left pb-1">Durata:</h5>
        <div class="my-2">
          <h6 v-if="therapy.type === 2" class="font-weight-bolder my-4 pb-2">
            Da {{ formatData(therapy.start_at) }} fino a
            {{ formatData(therapy.end_at) }} .
          </h6>
          <h6
            v-else-if="therapy.type === 1"
            class="font-weight-bolder my-4 pb-2"
          >
            La terapia va seguita ogni {{ therapy.every_num }}
            {{
              therapy.every_num > 1
                ? forEveryUmsPlur[therapy.every_um]
                : forEveryUmsSing[therapy.every_um]
            }}
            per
            {{ therapy.for_num }}
            {{
              therapy.for_num > 1
                ? forEveryUmsPlur[therapy.for_um]
                : forEveryUmsSing[therapy.for_um]
            }}
            a partire da {{ formatData(therapy.start_at) }}.
          </h6>
          <h6 v-else class="font-weight-bolder my-4 pb-2">indeterminata</h6>
        </div>
        <h5
          v-if="user.type === 1 && therapy.adherence"
          class="text-prim-grad-2 fw-bolder text-left pb-1"
        >
          Indice aderenza:
          <span class="text-secondary">{{ therapy.adherence }}%</span>
        </h5>
      </b-col>
      <b-col class="col-12 col-lg-7">
        <h5 class="text-prim-grad-2 fw-bolder text-left pb-1">Prescrittore:</h5>
        <div class="pb-4">
          <h6 class="my-4 pb-2">
            {{ therapy.prescrittore ? therapy.prescrittore : "non indicato" }}
          </h6>
        </div>
      </b-col>
      <b-col class="col-12 col-lg-5" v-if="user.type === 1">
        <h5 class="text-prim-grad-2 fw-bolder text-left pb-1">
          Fonte informativa:
        </h5>
        <div class="pb-4">
          <h6 class="my-4 pb-2">
            {{
              therapy.fonte_informativa
                ? therapy.fonte_informativa
                : "non indicata"
            }}
          </h6>
        </div>
      </b-col>
      <b-col class="col-12 col-lg-7">
        <h5 class="text-prim-grad-2 fw-bolder text-left pb-1">
          Reazioni allergiche:
        </h5>
        <div class="pb-4">
          <h6 class="my-4 pb-2 text-justify">
            {{
              therapy.reazione_allergica
                ? therapy.reazione_allergica
                : "nessuna"
            }}
          </h6>
        </div>
      </b-col>
      <b-col class="col-12 col-lg-5">
        <h5 class="text-prim-grad-2 fw-bolder text-left pb-1">
          Effetti collaterali:
        </h5>
        <div class="pb-4">
          <h6 class="my-4 pb-2 text-justify">
            {{
              therapy.effetti_collaterali
                ? therapy.effetti_collaterali
                : "nessuno"
            }}
          </h6>
        </div>
      </b-col>
      <b-col class="col-12 col-lg-7">
        <h5 class="text-prim-grad-2 fw-bolder text-left pb-1">Interazioni:</h5>
        <div class="pb-4">
          <h6 class="my-4 pb-2 text-justify">
            {{ therapy.interazioni ? therapy.interazioni : "nessuna" }}
          </h6>
        </div>
      </b-col>
      <b-col class="col-12 col-lg-5">
        <h5 class="text-prim-grad-2 fw-bolder text-left pb-1">Annotazioni:</h5>
        <div class="pb-4">
          <h6 class="my-4 pb-2 text-justify">
            {{ therapy.details ? therapy.details : "nessuna nota" }}
          </h6>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import * as moment from "moment/moment";
import { mapState, mapActions } from "vuex";
export default {
  name: "RecapTherapy",
  props: ["therapy"],
  data() {
    return {
      typesMap: {
        Compresse: "compressa",
        Capsule: "capsula",
        Gocce: "goccia",
        Inalazioni: "inalazione",
        Buste: "busta",
        Supposte: "supposta",
        Scatole: "scatola",
        Cerotti: "cerotto",
        Fiale: "fiala",
        Fleboclisi: "fleboclisi",
        Ovuli: "ovulo",
        Sacche: "sacca",
        Flaconi: "flacone",
        Confezioni: "confezione",
        Kilogrammi: "kilogrammo",
        Kit: "kit",
        Millilitri: "millilitro",
        Milligrammi: "milligrammo",
        Applicazioni: "applicazione",
        UI: "UI",
      },
      forEveryUmsSing: {
        0: "mese",
        1: "settimana",
        2: "giorno",
      },
      forEveryUmsPlur: {
        0: "mesi",
        1: "settimane",
        2: "giorni",
      },
    };
  },
  computed: {
    ...mapState("utente", ["status", "user"]),
    posNum: function () {
      return this.therapy.posology_num * 1;
    },
  },
  methods: {
    formatData(data) {
      return moment(data).format("dddd DD MMMM YYYY");
    },
    formatDataShort(data) {
      return moment(data).format("DD/MM/YYYY");
    },
  },
  created() {
    //
  },
};
</script>
