<template>
  <div class="col-8 mx-auto mt-4">
    <h1>Recupera la tua password</h1>
    <br />
    <template v-if="passwordRecoveryType === 1">
      <template v-if="status.recoverSent === true">
        <p>Mail inviata! Controlla la tua posta elettronica.</p>
        <p class="mt-4">
          <span class="fw-bolder point" @click="passwordRecoveryType = 2"
            >Non ho ricevuto l'email</span
          >
        </p>
      </template>
      <template v-else>
        <p>Inserisci l'indirizzo email utilizzato in fase di registrazione.</p>
        <b-form @submit.prevent="OnEmailSubmit">
          <b-form-input
            class="mx-auto my-3 shadow_6"
            name="email"
            type="email"
            v-model="recoverEmail"
            placeholder="email@ ..."
            :state="recoverEmail == null ? null : validEmail"
            required
          ></b-form-input>

          <b-row class="mt-4 justify-content-center">
            <b-button
              @click="$router.go(-1)"
              type="button"
              variant="outline-primary mx-2 fw-bolder"
              >Annulla</b-button
            >
            <b-button
              :disabled="recoverEmail == null ? false : !validEmail"
              type="submit"
              variant="primary text-white primary_gradient mx-2 fw-bolder"
              >Invia</b-button
            >
          </b-row>
          <p class="mt-4">
            <span class="fw-bolder point" @click="passwordRecoveryType = 2"
              >Non ricordo l'indirizzo email</span
            >
          </p>
        </b-form>
      </template>
    </template>
    <template v-else-if="passwordRecoveryType === 2">
      <template v-if="status.recoverSent === true">
        <p>
          Abbiamo inviato un sms contenente il link per ripristinare la password
          al numero di telefono indicato per questo codice fiscale. <br />
          Attendi qualche istante.
        </p>
        <p class="mt-4">
          <span class="fw-bolder point" @click="passwordRecoveryType = 3"
            >Non ho ricevuto l'sms</span
          >
        </p>
      </template>
      <template v-else>
        <p>
          Inserisci il tuo codice fiscale. Questa opzione è riservata ai soli
          cittadini.
        </p>
        <b-form @submit.prevent="OnCFSubmit">
          <b-form-input
            class="mx-auto mt-3 mb-4 shadow_6"
            name="cf"
            type="text"
            v-model="recoverCF"
            placeholder="CODICE FISCALE..."
            maxlength="16"
            minlength="16"
            :state="recoverCF == null ? null : validCf"
            required
          ></b-form-input>
          <b-row class="mt-4 justify-content-center">
            <b-button
              @click="passwordRecoveryType = 1"
              type="button"
              variant="outline-primary  mx-2 fw-bolder"
              >Annulla</b-button
            >
            <b-button
              :disabled="recoverCF == null ? false : !validCf"
              type="submit"
              variant="primary text-white primary_gradient  mx-2 fw-bolder"
              >Invia</b-button
            >
          </b-row>
        </b-form>
      </template>
    </template>
    <template v-else>
      <template v-if="status.recoverSent === true">
        <p>
          Un sms contenente il link per ripristinare la password al numero di
          telefono di riserva indicato su questo codice fiscale, se impostato.
          <br />
          Attendi qualche istante.
        </p>
        <p class="mt-4">
          Se non lo hai impostato o non hai ricevuto nessun link e non sei
          quindi riuscito a recuperare l'accesso,
          <a class="fw-bolder point" href="mailto:assistenza@dottorfarma.it">
            invia una richiesta di supporto in assistenza
          </a>
          .
        </p>
        <b-row class="text-center">
          <b-button
            @click="$router.push({ name: 'login' })"
            variant="outline-primary mx-auto fw-bolder"
            >Torna alla pagina di login</b-button
          >
        </b-row>
      </template>
      <template v-else>
        <p>
          Inserisci il tuo codice fiscale. Questa opzione è riservata ai soli
          cittadini.
        </p>
        <b-form @submit.prevent="OnCFSubmit">
          <b-form-input
            class="mx-auto mt-3 mb-4 shadow_6"
            name="cf"
            type="text"
            v-model="recoverCF"
            placeholder="CODICE FISCALE..."
            maxlength="16"
            minlength="16"
            :state="recoverCF == null ? null : validCf"
            required
          ></b-form-input>
          <b-row class="mt-4 justify-content-center">
            <b-button
              @click="$router.go(-1)"
              type="button"
              variant="outline-primary  mx-2 fw-bolder"
              >Annulla</b-button
            >
            <b-button
              :disabled="recoverCF == null ? false : !validCf"
              type="submit"
              variant="primary text-white primary_gradient  mx-2 fw-bolder"
              >Invia al numero di riserva</b-button
            >
          </b-row>
        </b-form>
      </template>
    </template>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "PasswordRecovery",
  data() {
    return {
      form: {
        email: this.$route.query.email,
        password: null,
        password_confirmation: null,
        token: this.$route.query.token,
      },
      has_minimum_lenth: true,
      has_number: false,
      has_lowercase: false,
      has_uppercase: false,
      has_special: false,
      recoveryTypes: {
        1: "mail",
        2: "firstPhone",
        3: "secondPhone",
      },
      passwordRecoveryType: 1,
      recoverCF: null,
      recoverEmail: null,
      showSecondNumberOption: false,
    };
  },
  computed: {
    validatePassword() {
      if (this.form.password) {
        this.has_minimum_lenth = this.form.password.length > 8;
        this.has_number = /\d/.test(this.form.password);
        this.has_lowercase = /[a-z]/.test(this.form.password);
        this.has_uppercase = /[A-Z]/.test(this.form.password);
        this.has_special = /[!@#\$%\^\&*\)\(+=._-]/.test(this.form.password);
        return (
          this.has_minimum_lenth &&
          this.has_number &&
          this.has_lowercase &&
          this.has_uppercase &&
          this.has_special
        );
      }
      return false;
    },
    validateConfirm() {
      if (this.form.password) {
        return this.form.password_confirmation === this.form.password;
      }
      return false;
    },
    validEmail() {
      const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      return this.recoverEmail.match(validRegex) ? true : false;
    },
    validCf() {
      const validRegex = /^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$/;
      if (this.recoverCF === 16) {
        return this.recoverCF.match(validRegex) ? true : false;
      }
      return this.recoverCF.match(validRegex) ? true : false;
    },
    ...mapState("utente", ["status", "user"]),
  },
  watch: {
    passwordRecoveryType: function (val) {
      this.resetRecoverAction();
    },
  },
  methods: {
    ...mapActions("utente", [
      "resetPassword",
      "recoverPassword",
      "recoverPasswordViaCf",
      "resetRecoverAction",
    ]),
    OnEmailSubmit() {
      const self = this;
      this.recoverPassword({ email: this.recoverEmail })
        .then((res) => {
          self.recoverEmail = null;
        })
        .catch((error) => {});
    },
    OnCFSubmit() {
      const self = this;
      var form = { cf: this.recoverCF };
      if (this.passwordRecoveryType > 2) {
        form = { cf: this.recoverCF, second: true };
      }
      this.recoverPasswordViaCf(form)
        .then((res) => {
          // self.recoverCF = null;
          self.showSecondNumberOption = true;
        })
        .catch((error) => {});
    },
  },
};
</script>
<style scoped>
input {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

input::placeholder {
  color: #f75010;
}
</style>
