<template>
  <div>
    <b-row cols="8">
      <b-col></b-col>
      <b-col></b-col>
      <b-col></b-col>
      <b-col>
        <b-icon stacked icon="cone-striped" animation="fade" variant="primary" scale="0.75"></b-icon>
      </b-col>
      <b-col></b-col>
      <b-col></b-col>
      <b-col></b-col>
    </b-row>
    <h5>Sistema in manutenzione</h5>
    <p>DottorFARMA tornerà disponibile appena possibile!</p>

  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "MaintenanceMode",
  data() {
    return {
    };
  },
  computed: {
    ...mapState("utente", ["status", "user"]),
  },
  mounted() {
    if (this.user) {
      this.logout();
    }
  },
  methods: {
    ...mapActions("utente", ["logout"]),
  },
};
</script>
