<template>
  <div class="my-2">
    <h6
      class="
        text-prim-grad-1
        fw-bolder
        text-left
        pb-1
        border-bottom border-line-grey
      "
    >
      La tua agenda
    </h6>
    <b-row>
      <Calendario />
    </b-row>
  </div>
</template>
<script>
import Calendario from "@/components/prenotazioni/Calendario.vue";
export default {
  name: "CompanyAgenda",
  components: { Calendario },
  data() {
    return {};
  },
  created() {},
};
</script>