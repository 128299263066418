<template>
  <b-row cols="1" cols-md="2" cols-xl="2">
    <!-- <h6 class="fw-bolder">Terapia</h6> -->
    <div v-for="(domanda, ind) in quest.domande" :key="ind" class="my-1">
      <div class="p-2" v-if="shouldBeShown(domanda)">
        <div class="
            panel__box__shadow
            bg-light-panel
            border border-light-panel
            min_border
            text-justify
            pl-2
            pt-2
          ">
          <h6 class="fw-bolder">{{ domanda.q }}:</h6>

          <p class="text-font-grey" v-if="domanda.k && domanda.k === 'orari'">
            <strong>{{ readableOrari(domanda) }} <br /></strong>
          </p>
          <p class="text-font-grey" v-else>
            <strong>{{ domanda.a }} <br /></strong>
          </p>
        </div>
      </div>
    </div>
    <!-- {{ survey.template }} -->
  </b-row>
</template>
<script>
import ImageShower from "@/components/utilities/ImageShower.vue";
export default {
  name: "FilledIrstSurveyShower",
  props: ["response"],
  components: { ImageShower },
  data() {
    return {
      quest: null,
    };
  },
  methods: {
    shouldBeShown(domanda) {
      var prodsArray = ["prod1", "prod2", "prod3", "prod4"];
      if (prodsArray.includes(domanda.k)) {
        return domanda.a !== null;
      }
      return true;
    },
    readableOrari(orariObject) {
      try {
        var orariArray = orariObject.a.split("");
        var readableArray = [];
        orariArray.map(function (el, ind) {
          if (el === "x") {
            readableArray.push(orariObject.o[ind])
          }
        })
        return readableArray.toString()

      } catch (err) {
        return orariObject.a.toString()
      }
    }
  },
  created() {
    this.quest = JSON.parse(this.response);
  },
};
</script>
<style>
.min_border {
  border-radius: 5px;
}
</style>