<template>
  <div class="home">
    <b-alert
      variant="danger"
      :show="!companyLoggedIn"
      class="col-lg-8 col-sm-12 mr-0 ml-auto point"
    >
      <span>Farmacia non trovata </span>
    </b-alert>
    <b-container class="mb-3" v-if="company && $route.name == 'CompanyHome'">
      <b-row align-v="stretch">
        <b-col
          v-if="hasRefPazienti"
          key="clienti"
          class="col-12 col-md-6 col-lg-4 my-3"
        >
          <div class="bg-white shadow_7 general_border pb-md-3 pb-0 h-100">
            <div class="col-6 mx-auto min__height">
              <p class="h1 mb-2 pt-4 pb-1" v-if="clientiSet.logo">
                <img :src="clientiSet.logo" :alt="clientiSet.name + '_logo'" />
              </p>
              <p class="h1 mt-2 py-4" v-else>
                <b-icon font-scale="1.5" icon="receipt-cutoff"></b-icon>
              </p>
            </div>
            <p class="mb-0 mt-1 fw-bolder text-secondary text-capitalize">
              {{ clientiSet.name }}
            </p>

            <div class="col-md-11 col-lg-10 col-12 mx-auto">
              <p class="fs-6 text-medium-grey">{{ clientiSet.description }}</p>
            </div>
            <div class="col-12 pb-4">
              <router-link
                class="primary_gradient btn btn-primary py-1 rounded-pill text-white fw-bolder"
                :to="positions[clientiSet.position_ref]"
              >
                <span class="fs-5 text-white spacing_1">{{
                  clientiSet.btnText
                }}</span>
              </router-link>
            </div>
          </div>
        </b-col>
        <b-col
          v-for="(service, ind) in company.active_services"
          :key="ind"
          class="col-12 col-md-6 col-lg-4 my-3"
        >
          <!-- <div
            v-if="company.stato_servizi[service.name] == 1"
            class="bg-white shadow_7 general_border my-3 pb-md-3 pb-0"
          > -->
          <div
            v-if="service.client === 0"
            class="bg-white shadow_7 general_border pb-md-3 pb-0 h-100"
          >
            <div class="col-6 mx-auto min__height">
              <p class="h1 mb-2 pt-4 pb-1" v-if="service.logo">
                <img :src="service.logo" :alt="service.name + '_logo'" />
              </p>
              <p class="h1 mt-2 py-4" v-else>
                <b-icon font-scale="1.5" icon="receipt-cutoff"></b-icon>
              </p>
            </div>
            <p class="mb-0 mt-1 fw-bolder text-secondary text-capitalize">
              {{ service.name }}
            </p>

            <div class="col-md-11 col-lg-10 col-12 mx-auto">
              <p class="fs-6 text-medium-grey">{{ service.description }}</p>
            </div>
            <div class="col-12 pb-4">
              <router-link
                class="primary_gradient btn btn-primary py-1 rounded-pill text-white fw-bolder"
                :to="positions[service.position_ref]"
              >
                <span class="fs-5 text-white spacing_1">Accedi</span>
              </router-link>
            </div>
          </div>
          <!-- <div
            v-else
            class="bg-light-panel general_border my-3 pb-md-3 pb-0"
            style="opacity: 0.7"
          >
            <div class="col-6 mx-auto min__height">
              <p class="h1 mb-2 pt-4 pb-1" v-if="service.logo">
                <img :src="service.logo" :alt="service.name + '_logo'" />
              </p>
              <p class="h1 mt-2 py-4" v-else>
                <b-icon font-scale="1.5" icon="receipt-cutoff"></b-icon>
              </p>
            </div>
            <p class="mb-0 mt-1 fw-bolder text-secondary text-capitalize">
              {{ service.name }}
            </p>

            <div class="col-md-11 col-lg-10 col-12 mx-auto">
              <p class="fs-6 text-medium-grey">{{ service.description }}</p>
            </div>
            <div class="col-12 pb-4">
              <router-link
                class="
                  info_gradient
                  btn btn-info
                  py-1
                  rounded-pill
                  text-white
                  fw-bolder
                "
                :to="{
                  name: 'Impostazioni',
                  query: { scope: 'sottoscrizioni' },
                }"
              >
                <span class="fs-5 text-white spacing_1">Acquista</span></router-link
              >
            </div>
          </div> -->
        </b-col>
        <b-col
          v-if="hasRefPrenotazioni"
          key="agenda"
          class="col-12 col-md-6 col-lg-4 my-3"
        >
          <div class="bg-white shadow_7 general_border pb-md-3 pb-0 h-100">
            <div class="col-6 mx-auto min__height">
              <p class="h1 mb-2 pt-4 pb-1" v-if="agendaSet.logo">
                <img :src="agendaSet.logo" :alt="agendaSet.name + '_logo'" />
              </p>
              <p class="h1 mt-2 py-4" v-else>
                <b-icon font-scale="1.5" icon="receipt-cutoff"></b-icon>
              </p>
            </div>
            <p class="mb-0 mt-1 fw-bolder text-secondary text-capitalize">
              {{ agendaSet.name }}
            </p>

            <div class="col-md-11 col-lg-10 col-12 mx-auto">
              <p class="fs-6 text-medium-grey">{{ agendaSet.description }}</p>
            </div>
            <div class="col-12 pb-4">
              <router-link
                class="primary_gradient btn btn-primary py-1 rounded-pill text-white fw-bolder"
                :to="positions[agendaSet.position_ref]"
              >
                <span class="fs-5 text-white spacing_1">{{
                  agendaSet.btnText
                }}</span>
              </router-link>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
import { mapState, mapActions } from "vuex";
export default {
  name: "CompanyHome",
  components: {},
  data() {
    return {
      showCompanyAlert: null,
      positions: {
        1: "prenotazioni",
        2: "teleconsulto",
        3: "attivita",
        4: "pazienti",
        5: "agenda",
        6: "clienti",
        7: "pt",
      },
      agendaSet: {
        name: "La mia agenda",
        description: "Agenda appuntamenti",
        position_ref: 5,
        logo: "/assets/book.svg",
        disabled: false,
        btnText: "Consulta",
      },
      clientiSet: {
        name: "Pazienti",
        description: "Gestione anagrafica",
        position_ref: 6,
        logo: "/assets/people.svg",
        disabled: false,
        btnText: "Consulta",
      },
    };
  },
  computed: {
    hasRefPazienti() {
      if (this.company) {
        return this.company.active_services.find((el) => el.position_ref === 4)
          ? true
          : false;
      }
      return false;
    },
    hasRefPrenotazioni() {
      if (this.company) {
        return this.company.active_services.find(
          (el) => el.position_ref === 1 || el.position_ref === 2
        )
          ? true
          : false;
      }
      return false;
    },
    ...mapState("utente", ["status", "user"]),
    ...mapState("company", ["companyLoggedIn", "company"]),
  },
  methods: {
    ...mapActions("company", ["logUserCompanyIn", "companyLogin"]),
  },
  created() {
    if (this.$route.query.token) {
      this.companyLogin(this.$route.query.token);
    } else {
      this.logUserCompanyIn();
    }
    // //console.log(this.company);
  },
  mounted() {
    //console.log(this.$route);
    if (this.company && !this.company.valid_subscription) {
      this.$router.push({ name: "Home" });
    }
  },
};
</script>
<style scoped>
.min__height {
  /* min-height: 130px;
  height: 130px; */
}
</style>
