var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pb-4"},[(_vm.user.type === 0)?_c('div',{staticClass:"col-12"},[_c('AccessoEPermessi'),_c('ShowUserCode'),_c('NotificationChannels')],1):(_vm.user.type === 1)?_c('b-row',{attrs:{"cols":"2"}},[(!_vm.isLevelAsl)?_c('b-col',{staticClass:"col-12 col-md-2 mb-3"},[(_vm.user.permessi.includes('gestire impostazioni dei servizi'))?_c('b-button',{staticClass:"fw-bolder text-capitalize",attrs:{"size":_vm.$root.isMobile ? 'sm' : '',"variant":_vm.activePanel === 'gestioneservs'
            ? 'primary primary_gradient text-white'
            : 'bg-light text-primary'},on:{"click":function($event){_vm.activePanel = 'gestioneservs'}}},[_vm._v("gestione servizi")]):_vm._e(),(_vm.user.permessi.includes('gestire operatori'))?_c('b-button',{staticClass:"fw-bolder text-capitalize",attrs:{"size":_vm.$root.isMobile ? 'sm' : '',"variant":_vm.activePanel === 'operatori'
            ? 'primary primary_gradient text-white'
            : 'bg-light text-primary'},on:{"click":function($event){_vm.activePanel = 'operatori'}}},[_vm._v("operatori")]):_vm._e(),(_vm.user.permessi.includes('visualizzare anagrafica azienda'))?_c('b-button',{staticClass:"fw-bolder text-capitalize",attrs:{"size":_vm.$root.isMobile ? 'sm' : '',"variant":_vm.activePanel === 'anagrafica'
            ? 'primary primary_gradient text-white'
            : 'bg-light text-primary'},on:{"click":function($event){_vm.activePanel = 'anagrafica'}}},[_vm._v("anagrafica")]):_vm._e(),(_vm.user.permessi.includes('visualizzare anagrafica azienda'))?_c('b-button',{staticClass:"fw-bolder text-capitalize",class:_vm.$root.isMobile ? 'text__control' : '',attrs:{"size":_vm.$root.isMobile ? 'sm' : '',"variant":_vm.activePanel === 'adesioni'
            ? 'primary primary_gradient text-white'
            : 'bg-light text-primary'},on:{"click":function($event){_vm.activePanel = 'adesioni'}}},[_vm._v("adesioni")]):_vm._e(),(_vm.user.permessi.includes('visualizzare anagrafica azienda'))?_c('b-button',{staticClass:"fw-bolder text-capitalize",class:_vm.$root.isMobile ? 'text__control' : '',attrs:{"size":_vm.$root.isMobile ? 'sm' : '',"variant":_vm.activePanel === 'social'
            ? 'primary primary_gradient text-white'
            : 'bg-light text-primary'},on:{"click":function($event){_vm.activePanel = 'social'}}},[_vm._v("Social&Web")]):_vm._e(),(_vm.user.permessi.includes('visualizzare anagrafica azienda'))?_c('b-button',{staticClass:"fw-bolder text-capitalize",class:_vm.$root.isMobile ? 'text__control' : '',attrs:{"size":_vm.$root.isMobile ? 'sm' : '',"variant":_vm.activePanel === 'sottoscrizioni'
            ? 'primary primary_gradient text-white'
            : 'bg-light text-primary'},on:{"click":function($event){_vm.activePanel = 'sottoscrizioni'}}},[_vm._v("sottoscrizioni")]):_vm._e()],1):_c('b-col',{staticClass:"col-12 col-md-2 mb-3"},[(_vm.user.permessi.includes('gestire operatori'))?_c('b-button',{staticClass:"fw-bolder text-capitalize",attrs:{"size":_vm.$root.isMobile ? 'sm' : '',"variant":_vm.activePanel === 'operatori'
            ? 'primary primary_gradient text-white'
            : 'bg-light text-primary'},on:{"click":function($event){_vm.activePanel = 'operatori'}}},[_vm._v("operatori")]):_vm._e(),(_vm.user.permessi.includes('visualizzare anagrafica azienda'))?_c('b-button',{staticClass:"fw-bolder text-capitalize",attrs:{"size":_vm.$root.isMobile ? 'sm' : '',"variant":_vm.activePanel === 'anagrafica'
            ? 'primary primary_gradient text-white'
            : 'bg-light text-primary'},on:{"click":function($event){_vm.activePanel = 'anagrafica'}}},[_vm._v("anagrafica")]):_vm._e()],1),_c('b-col',{staticClass:"mx-auto",attrs:{"cols":_vm.$root.isMobile ? '12' : '10'}},[_c('transition-group',{attrs:{"name":"list","mode":"out-in","appear":""}},[(
            _vm.activePanel === 'gestioneservs' &&
            _vm.user.permessi.includes('gestire impostazioni dei servizi')
          )?_c('div',{key:"gestioneservs"},[_c('OrariCompany')],1):_vm._e(),(
            _vm.activePanel === 'anagrafica' &&
            _vm.user.permessi.includes('visualizzare anagrafica azienda')
          )?_c('div',{key:"anagrafica"},[_c('h6',{staticClass:"text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"},[_vm._v(" Anagrafica Azienda ")]),(_vm.company.dottorfarma == 1)?[_c('p',{staticClass:"text-left text-medium-grey fs-5"},[_vm._v(" Scheda Anagrafica dell'azienda. Le informazioni qui presenti possono essere modificate accedendo al sito "),_c('a',{attrs:{"href":"https://www.federfarma.it/"}},[_vm._v("Federfarma")]),_vm._v(". ")]),_c('AnagraficaCompanyDF')]:_c('AnagraficaCompany')],2):_vm._e(),(
            _vm.activePanel === 'operatori' &&
            _vm.user.permessi.includes('gestire operatori')
          )?_c('div',{key:"operatori"},[_c('h6',{staticClass:"text-justify fw-bolder text-medium-grey my-3 bg-light py-2 px-3 general_border"},[_vm._v(" Nella sezione "),_c('strong',{staticClass:"text-info"},[_vm._v("\"Operatori\"")]),_vm._v(" puoi creare nuove utenze di accesso alla piattaforma per i tuoi dipendenti, visualizzare il tuo codice azienda e gestire i tuoi operatori. L'operatore potrà registrare la propria utenza sul portale DottorFarma ed accedere in autonomia tramite il login con le sue credenziali; la sua libertà di azione sui servizi della Farmacia in DottorFarma sarà limitata dal ruolo che gli verrà assegnato (vedi sezione "),_c('strong',{staticClass:"text-info"},[_vm._v("\"Ruoli\"")]),_vm._v(" per maggiori dettagli). ")]),(
              _vm.operatorCount !== null &&
              (!_vm.company.operators_limit ||
                (_vm.company.operators_limit > 0 &&
                  _vm.operatorCount < _vm.company.operators_limit))
            )?[_c('h6',{staticClass:"text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"},[_vm._v(" Codice Azienda ")]),_c('p',{staticClass:"text-left text-medium-grey fs-5"},[_vm._v(" Clicca \"mostra codice azienda\" per visualizzare il tuo codice aziendale. Il codice azienda andrà inserito dall'operatore che intende registrarsi autononamente nel portale in qualità di operatore nella tua Azienda. Se invece preferisci inviare il codice via email, clicca su \"invia codice azienda\" ed inserisci l'indirizzo email del destinatario nell'apposito campo. ")]),_c('ShowCode',{staticClass:"mb-4"})]:_vm._e(),_c('h6',{staticClass:"text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"},[_vm._v(" Operatori Azienda ")]),_c('p',{staticClass:"text-left text-medium-grey fs-5"},[_vm._v(" L'elenco dei tuoi operatori. Per abilitare una nuova utenza alla gestione dei tuoi servizi DottorFarma puoi inviare un invito via email contenente il codice azienda necessario per la sua registrazione. ")]),_c('OperatorsList',{on:{"total-count":function($event){return _vm.handleOperatorsCount($event)}}})],2):_vm._e(),(
            _vm.activePanel === 'sottoscrizioni' &&
            _vm.user.permessi.includes('visualizzare anagrafica azienda')
          )?_c('div',{key:"sottoscrizioni",staticClass:"mx-auto"},[_c('Sottoscrizioni'),(_vm.company.contract)?[_c('h6',{staticClass:"text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey mt-4"},[_vm._v(" Contratto di adesione a DottorFARMA e nomine ")]),_c('Contratti')]:_vm._e()],2):_vm._e(),(_vm.activePanel === 'adesioni')?_c('div',{key:"adesioni",staticClass:"mx-auto"},[_c('h6',{staticClass:"text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"},[_vm._v(" Farmacia dei servizi ")]),_c('Adesioni')],1):_vm._e(),(_vm.activePanel === 'social')?_c('div',{key:"social",staticClass:"mx-auto"},[_c('h6',{staticClass:"text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"},[_vm._v(" Canali Social ")]),_c('CanaliSocial'),_c('h6',{staticClass:"text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"},[_vm._v(" Sito Web ")]),_c('SitoWeb')],1):_vm._e()])],1)],1):_c('div',[_c('h5',[_vm._v("Nessuna impostazione per questa utenza")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }