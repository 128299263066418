<template>
  <div class="col-12 px-0">
    <div class="col-12">
      <h6 class="
                  text-prim-grad-1
                  fw-bolder
                  text-left
                  pb-1
                  border-bottom border-line-grey
                ">
        Trattamento dati personali
      </h6>
      <p class="text-secondary text-justify">
        Per procedere alla registrazione è necessario <a target="_blank" class="text-underlined" :href="infoLinks.box1">
          <u>leggere ed
            accettare l'informativa sul trattamento dei dati personali</u></a> ed in particolare:
      </p>
      <p class="text-secondary text-justify">
        <a target="_blank" class="text-underlined" :href="infoLinks.box1"> <u>Capitolo 2 - "Finalità e base
            giuridica del trattamento", paragrafo A</u></a>
      </p>
      <b-row class="justify-content-center mb-2">
        <b-form-checkbox class="text-font-light-grey ml-0 mr-auto mx-lg-1" name="data_consense" :value="false"
          :unchecked-value="false" v-model="form.data_consense" :state="false">
          Non acconsento
        </b-form-checkbox>
        <b-form-checkbox class="text-font-light-grey ml-0 mr-auto mx-lg-1" name="data_consense" :value="true"
          :unchecked-value="false" v-model="form.data_consense" :state="form.data_consense == true">
          Ho letto e do il mio consenso
        </b-form-checkbox>
      </b-row>
      <p class="text-secondary text-justify">
        <a target="_blank" class="text-underlined" :href="infoLinks.box2"> <u>Capitolo 2 - "Finalità e base
            giuridica del trattamento", paragrafo B</u></a>
      </p>
      <b-row class="justify-content-center  mb-2">
        <b-form-checkbox class="text-font-light-grey ml-0 mr-auto mx-lg-1" name="share_consense" :value="false"
          :unchecked-value="false" v-model="form.share_consense" :state="false">
          Non acconsento
        </b-form-checkbox>
        <b-form-checkbox class="text-font-light-grey ml-0 mr-auto mx-lg-1" name="share_consense" :value="true"
          :unchecked-value="false" v-model="form.share_consense" :state="form.share_consense == true">
          Ho letto e do il mio consenso
        </b-form-checkbox>
      </b-row>
    </div>
    <div class="col-12">
      <h6 class="
                  text-prim-grad-1
                  fw-bolder
                  text-left
                  pb-1
                  border-bottom border-line-grey
                ">
        Termini e condizioni
      </h6>
      <p class="text-secondary text-justify">
        Per procedere alla registrazione è necessario <a target="_blank" class="text-underlined" :href="infoLinks.box3">
          <u>leggere ed
            accettare l'informativa sul termini e condizioni di utilizzo</u></a>.
      </p>
      <b-row class="justify-content-center">
        <b-form-checkbox class="text-font-light-grey mx-1" name="charge_consense" :value="false"
          :unchecked-value="false" v-model="form.charge_consense" :state="false">
          Non acconsento
        </b-form-checkbox>
        <b-form-checkbox class="text-font-light-grey mx-1" name="charge_consense" :value="true" :unchecked-value="false"
          v-model="form.charge_consense" :state="form.charge_consense == true">
          Acconsento
        </b-form-checkbox>
      </b-row>
    </div>
    <b-row class="my-2">
      <b-col>
        <b-button v-if="allOk" variant="primary primary_gradient" @click="sendConsenses">
          <span v-if="!loading">Conferma le mie scelte</span>
          <b-spinner v-else></b-spinner>
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { cittadinoService } from "@/_services";
import { mapState, mapActions } from "vuex";
export default {
  name: "ConsenseComponent",
  computed: {
    allOk: function () {
      return (
        this.form.data_consense &&
        this.form.charge_consense &&
        this.form.share_consense
      );
    },
    ...mapState("utente", ["status", "user"]),
  },
  data() {
    return {
      form: {
        data_consense: false,
        charge_consense: false,
        share_consense: false,
        // notification_consense: false,
      },
      loading: false,
      infoLinks: {
        box1: process.env.VUE_APP_LINK_INFO_REGISTRAZIONE_BOX1,
        box2: process.env.VUE_APP_LINK_INFO_REGISTRAZIONE_BOX2,
        box3: process.env.VUE_APP_LINK_INFO_REGISTRAZIONE_BOX3,
      }
    };
  },
  methods: {
    ...mapActions("utente", ["unMe", "me"]),
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    sendConsenses() {
      this.loading = true;
      var formData = {
        hashid: this.user.hashid,
        data: this.form,
      };
      var self = this;
      cittadinoService
        .updateUser(formData)
        .then(function (res) {
          // //console.log(res);
          self.handleUpdate();
          // localStorage.removeItem("user");
        })
        .catch(function (err) {
          //console.log("err", err);
          self.loading = false;
          self.errorAlert("Non è stato possibile memorizzare le preferenze");
          self.resetAll();
        });
    },
    handleUpdate() {
      this.me();
      var self = this;
      setTimeout(function () {
        self.loading = false;
        self.$emit("consense-ok");
      }, 7000);
    },
    resetAll() {
      this.form = {
        data_consense: false,
        charge_consense: false,
        share_consense: false,
        // notification_consense: false,
      };
    },
  },
  created() { },
};
</script>