<template>
  <div>
    <h6 v-if="score !== null" class="fw-bolder mt-2">
      Punteggio totalizzato: {{ score }}
    </h6>
    <h6 v-if="identificativo_deblistering" class="fw-bolder mt-2">
      IDENTIFICATIVO CONFEZIONAMENTO: {{ identificativo_deblistering }}
    </h6>
    <h6 class="fw-bolder mt-2">Domande</h6>
    <div v-for="(domanda, ind) in quest.domande" :key="ind" class="my-1">
      <h6 class="fw-bolder">{{ ind + 1 }}. {{ domanda.q }}:</h6>
      <div
        class="panel__box__shadow bg-light-panel border border-light-panel min_border text-justify px-2"
        v-if="domanda.o == 'file'"
      >
        <div v-if="Array.isArray(domanda.a)">
          <ImageShower
            v-for="(hh, kk) in domanda.a"
            :key="kk"
            :hash="hh"
            :indx="kk + 1"
          />
        </div>
        <p v-else>--- nessuna immagine caricata</p>
      </div>

      <template v-else-if="domanda.o == 'ricognizione' && quest.ricognizione">
        <div
          v-for="(domanda, ind) in quest.ricognizione"
          :key="'r_' + ind"
          class="mt-2 mb-3"
        >
          <h6 class="text-left bg-prim-grad-1 text-white fw-bolder p-2">
            Farmaco {{ ind + 1 }}.
          </h6>
          <b-row
            cols="1"
            cols-lg="2"
            class="panel__box__shadow bg-light-panel border border-light-panel min_border text-justify px-2"
          >
            <b-col
              v-for="(field, indx) in domanda"
              :key="'r' + indx + 'q_' + ind"
            >
              <span class="fw-bolder">{{ field.q }}:</span>
              <span class="font-italic"> {{ field.a }}</span>
            </b-col>
          </b-row>
        </div>
      </template>
      <p
        class="panel__box__shadow bg-light-panel border border-light-panel min_border text-justify p-2"
        v-else
      >
        <strong>{{ domanda.a }} <br /></strong>
      </p>
    </div>
    <h6 class="fw-bolder">Informativa</h6>
    <p
      v-if="quest.info"
      v-html="quest.info"
      class="panel__box__shadow bg-light-panel border border-light-panel min_border text-justify p-2"
    ></p>
    <!-- <p v-if="quest.info" class="panel__box__shadow
          bg-light-panel
          border border-light-panel
          min_border
          text-justify
          p-2">
      {{ quest.info }}
    </p> -->
    <p v-else>---</p>
  </div>
</template>
<script>
import ImageShower from "@/components/utilities/ImageShower.vue";
export default {
  name: "FilledSurveyShower",
  props: ["response", "score", "identificativo_deblistering"],
  components: { ImageShower },
  data() {
    return {
      quest: null,
    };
  },
  created() {
    this.quest = JSON.parse(this.response);
  },
};
</script>
<style>
.min_border {
  border-radius: 5px;
}
</style>
