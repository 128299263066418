<template>
  <div class="col-8 mx-auto mt-4">
    <h1>Reimposta la tua password</h1>
    <br />
    <p>
      La nuova password deve contenere almeno 8 caratteri, di cui almeno un
      numero, una maiuscola, una minuscola ed un
      <span
        v-b-tooltip
        title="Devi usare almeno un caratterere tra ! @ # $ % ^ & * ) ( + = . _ -"
        >carattere speciale</span
      >.
    </p>
    <b-form @submit.prevent="OnSubmit" class="col-lg-">
      <b-form-input
        class="col-lg-5 col-sm-8 mx-auto my-3 shadow_6"
        name="password"
        type="password"
        v-model="form.password"
        placeholder="password..."
        :state="validatePassword"
        required
      ></b-form-input>
      <b-form-input
        class="col-lg-5 col-sm-8 mx-auto my-3 shadow_6"
        name="password"
        type="password"
        v-model="form.password_confirmation"
        placeholder="conferma password..."
        :state="validateConfirm"
        required
      >
      </b-form-input>
      <span v-if="status.loggedIn == false" class="text-danger"
        >Credenziali errate.</span
      >
      <b-button
        type="submit"
        variant="primary text-white primary_gradient fw-bolder mt-3 col-lg-4 col-sm-7"
        >Salva nuova password</b-button
      >
    </b-form>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "RecoverPassword",
  data() {
    return {
      form: {
        username: this.$route.query.username,
        password: null,
        password_confirmation: null,
        token: this.$route.query.token,
      },
      has_minimum_lenth: true,
      has_number: false,
      has_lowercase: false,
      has_uppercase: false,
      has_special: false,
    };
  },
  computed: {
    validatePassword() {
      if (this.form.password) {
        this.has_minimum_lenth = this.form.password.length > 8;
        this.has_number = /\d/.test(this.form.password);
        this.has_lowercase = /[a-z]/.test(this.form.password);
        this.has_uppercase = /[A-Z]/.test(this.form.password);
        this.has_special = /[!@#\$%\^\&*\)\(+=._-]/.test(this.form.password);
        return (
          this.has_minimum_lenth &&
          this.has_number &&
          this.has_lowercase &&
          this.has_uppercase &&
          this.has_special
        );
      }
      return false;
    },
    validateConfirm() {
      if (this.form.password) {
        return this.form.password_confirmation === this.form.password;
      }
      return false;
    },
    ...mapState("utente", ["status", "user"]),
  },
  methods: {
    ...mapActions("utente", ["resetPassword"]),
    OnSubmit() {
      //console.log(this.form);
      if (this.form) {
        this.resetPassword(this.form)
          .then((res) => {
            //console.log("after login ok", res);
          })
          .catch((error) => {
            //console.log("after login error", error);
          });
      }
    },
  },
};
</script>
<style scoped>
input {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

input::placeholder {
  color: #f75010;
}
</style>
