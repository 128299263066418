<template>
  <b-row cols="1" class="pls__left__btn" :class="menuOpen ? ' bg-white-faded' : ''">
    <transition-group name="slide" mode="out-in">
      <b-col v-if="menuOpen" key="neworder"
        class="bg-primary general_border shadow_6 p-1 col-12 col-lg-10 col-xl-8 ml-auto mr-0">

        <b-row key="maindivmen"
          class="text-left flex-column justify-content-end bg-light-panel general_border py-4 px-2">
          <h4 class="text-left mb-4 mx-2">Inserisci una prenotazione</h4>

          <!-- <template v-if="!arruolamento"> -->
          <p class="text-left fw-bolder mx-2">Cerca l'utente per cui vuoi inserire la prenotazione. In alternativa,
            <span>
              <router-link class="fw-bolder text-primary no__hover text-right col-7 px-0 col-md-6" :to="{
                name: 'Pazienti'
              }">
                vai alla lista
                pazienti
                <b-icon icon="arrow-right-circle-fill" variant="primary"></b-icon>
              </router-link>
            </span>
          </p>
          <SearchUtente :type="'paziente'" custombtn="Prenota per il paziente" v-on:invite-user="goToScheda($event)"
            v-on:arruola-user="doRegister($event)" class="ml-0" />
          <!-- </template>
          <template cols="1" class="my-2" v-else>
            <SchedaArruolamento :params="precompiled" v-on:cancel-operation="undoArruolamento"
              v-on:arruolamento-completed="goToScheda($event)" />
          </template> -->
        </b-row>
      </b-col>
      <b-col key="openbtn">
        <div class="d-flex justify-content-end mt-2">
          <span class="ml-2 point shadow_6 rounded-circle" @click="menuOpen = !menuOpen">
            <b-iconstack font-scale="4">
              <b-icon stacked icon="circle-fill" variant="primary"></b-icon>
              <b-icon stacked :icon="menuOpen ? 'x' : 'plus'" scale="0.8" variant="white"></b-icon>
              <b-icon stacked icon="circle" variant="primary"></b-icon>
            </b-iconstack>
          </span>
        </div>
      </b-col>
    </transition-group>
  </b-row>
</template>
<script>
import SearchUtente from "@/components/utilities/SearchUtente.vue";
import SchedaArruolamento from "@/components/pazienti/SchedaArruolamento.vue";
export default {
  name: "CalendarAddButton",
  components: { SearchUtente, SchedaArruolamento },
  data() {
    return {
      menuOpen: false,
      // arruolamento:false,
      // precompiled: null,
    };
  },
  methods: {
    undoArruolamento() {
      this.arruolamento = false;
      this.precompiled = null;
    },
    goToScheda(hashid) {
      console.log(hashid);
      this.$router.push({
        name: "nuova prenotazione servizio",
        params: { hash: hashid },
      });
    },
    doRegister(params) {
      console.log(params);
      // this.arruolamento = true;
      // this.precompiled = params;
      this.$router.push({
        name: "Pazienti",
        params: params,
        query: { arruola: true }
      });
    },
  },
  created() { },
};
</script>
<style>
.bg-white-faded {
  background-color: rgba(255, 255, 255, 0.459);
  padding-top: 1rem;
}
.pls__left__btn{
  position: sticky;
    position: -webkit-sticky;
    bottom: 5vh;
    right: 1vw;
    padding-right: 3rem;
}
</style>