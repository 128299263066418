import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "./sass/dottorfarma/app.scss";
import axios from "axios";
import VueAxios from "vue-axios";
import VueMoment from "vue-moment";
import it from "moment/locale/it";
// import moment from 'moment-timezone'
import vueDebounce from "vue-debounce";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import firebaseConfig from "./_config/firebase";
import VueGeolocation from "vue-browser-geolocation";

// import { ImageBarcodeReader } from "vue-barcode-reader";

require("moment/locale/it");
// moment.tz.setDefault('Europe/Rome')
// Vue.prototype.$moment = moment
// //console.log(process.env.VUE_APP_PRESTAZIONI_ONLY);

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueAxios, axios);
// Vue.use(VueMoment, {
//   moment,
// });
Vue.use(VueMoment);
Vue.use(vueDebounce);
// Vue.use(ImageBarcodeReader);
Vue.use(VueGeolocation);

Vue.config.productionTip = false;

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
// const analytics = getAnalytics(firebaseApp);

// var serviceWorkerRegistration=null;
// console.log(navigator);
if (navigator && navigator.serviceWorker) {
  navigator.serviceWorker
    .register("/firebase-messaging-sw.js")
    .then((registration) => {
      // //console.log("registration",registration)
      // serviceWorkerRegistration = registration
    })
    .catch((err) => {
      console.log(err);
    });
}

new Vue({
  router,
  store,
  render: (h) => h(App),
  data() {
    return {
      menuLeft: false,
      isTablet: window.innerWidth <= 820, //to include pads
      isMobile: window.innerWidth <= 576,
      prestazioniOnly: process.env.VUE_APP_PRESTAZIONI_ONLY,
      firebaseApp: firebaseApp,
      // swRegistration:serviceWorkerRegistration
    };
  },
}).$mount("#app");
