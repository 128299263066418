<template>
  <b-container fluid v-if="user" class="mb-4">
    <b-row cols="2" class="text-left mb-3">
      <router-link
        class="fw-bolder text-primary no__hover"
        :to="{ name: 'pazienti' }"
      >
        <b-icon icon="arrow-left-circle-fill" variant="primary"></b-icon>
        Pazienti
      </router-link>
      <router-link
        class="fw-bolder text-primary no__hover text-right"
        :to="{
          name: 'scheda Paziente',
          params: { hash: $route.params.hash },
        }"
      >
        Scheda paziente
        <b-icon icon="arrow-right-circle-fill" variant="primary"></b-icon>
      </router-link>
    </b-row>

    <template v-if="!blockView">
      <b-row cols="1" class="mb-2">
        <b-card no-body class="mb-1 border-0 col-12 px-0 px-md-2">
          <b-card-header
            header-tag="header"
            class="p-1 text-left bg-white border-bottom border-line-grey"
            role="tab"
          >
            <b-button
              block
              v-b-toggle.accordion-anagrafica
              class="text-prim-grad-1 fw-bolder pb-1 bg-white border-0 p-0 accordion__hover text-left"
            >
              <span class="mr-auto">Anagrafica</span>

              <span
                class="when-closed ml-4 btn btn-sm btn-prim-grad-1 font-weight-bolder"
                >mostra <b-icon icon="arrow-down-circle-fill"></b-icon> </span
              ><span
                class="when-open ml-4 btn btn-sm btn-prim-grad-1 font-weight-bolder"
                >nascondi <b-icon icon="arrow-up-circle-fill"></b-icon
              ></span>
            </b-button>
          </b-card-header>
          <b-collapse
            id="accordion-anagrafica"
            visible
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body class="p-0">
              <b-row cols="1" class="mb-2">
                <div
                  class="text-left mt-3"
                  style="font-size: 1.15rem; letter-spacing: 0.05rem"
                >
                  <b-row cols="1" cols-md="3" cols-lg="4" class="px-3">
                    <b-col class="my-1" offset-lg="1" offset="0" offset-xl="0">
                      <strong>Nome </strong><br />
                      <span class="text-capitalize"
                        >{{ user.anagrafica.name }}
                        {{ user.anagrafica.surname }}
                      </span>
                    </b-col>
                    <b-col class="my-1" offset-lg="1" offset="0" offset-xl="0">
                      <strong>Genere </strong><br />
                      <span>{{ user.anagrafica.gender }} </span>
                    </b-col>
                    <b-col class="my-1" offset-lg="1" offset="0" offset-xl="0">
                      <strong>Data di nascita </strong><br />
                      <span v-if="user.anagrafica.birthday"
                        >{{ user.anagrafica.birthday | moment("DD/MM/YYYY") }}
                      </span>
                      <span v-else>Non indicata</span>
                    </b-col>
                    <b-col class="my-1" offset-lg="1" offset="0" offset-xl="0">
                      <strong>Codice fiscale </strong><br />
                      <span class="text-uppercase"
                        >{{ user.anagrafica.cf }}
                      </span>
                    </b-col>
                    <b-col class="my-1" offset-lg="1" offset="0" offset-xl="0">
                      <strong>Regione </strong><br />
                      <span class="text-capitalize"
                        >{{ user.anagrafica.regione }}
                      </span>
                    </b-col>
                    <b-col class="my-1" offset-lg="1" offset="0" offset-xl="0">
                      <strong>Provincia </strong><br />
                      <span class="text-capitalize"
                        >{{ user.anagrafica.provincia }}
                      </span>
                    </b-col>
                    <b-col class="my-1" offset-lg="1" offset="0" offset-xl="0">
                      <strong>Comune </strong><br />
                      <span class="text-capitalize"
                        >{{ user.anagrafica.comune }}
                      </span>
                    </b-col>
                    <b-col class="my-1" offset-lg="1" offset="0" offset-xl="0">
                      <strong>Indirizzo </strong><br />
                      <span class="text-capitalize"
                        >{{
                          user.anagrafica.address
                            ? user.anagrafica.address
                            : "Non indicato"
                        }}
                      </span>
                    </b-col>
                    <b-col class="my-1" offset-lg="1" offset="0" offset-xl="0">
                      <strong>Telefono </strong><br />
                      <span
                        >{{
                          user.anagrafica.tel_number
                            ? user.anagrafica.tel_number
                            : "Non indicato"
                        }}
                      </span>
                    </b-col>
                    <b-col class="my-1" offset-lg="1" offset="0" offset-xl="0">
                      <strong>Numero casa </strong><br />
                      <span
                        >{{
                          user.anagrafica.home_number
                            ? user.anagrafica.home_number
                            : "Non indicato"
                        }}
                      </span>
                    </b-col>
                    <b-col
                      v-if="user.email"
                      class="my-1"
                      offset-lg="1"
                      offset="0"
                      offset-xl="0"
                    >
                      <strong>Email </strong><br />
                      <span>{{ user.email }} </span>
                    </b-col>
                    <b-col class="my-1" offset-lg="1" offset="0" offset-xl="0">
                      <strong>Numero di riserva </strong><br />
                      <span
                        >{{
                          user.anagrafica.tel_number_secondary
                            ? user.anagrafica.tel_number_secondary
                            : "-"
                        }}
                      </span>
                    </b-col>
                  </b-row>
                </div>
              </b-row>
            </b-card-body>
          </b-collapse>
        </b-card>
      </b-row>

      <transition-group name="slideside" mode="out-in">
        <b-row v-if="activePanel === 'alerts'" key="alerts">
          <div class="col-12 text-left px-0 px-md-2" ref="alertPanel">
            <SettedAlerts :subject="subject" />
          </div>
        </b-row>
      </transition-group>
    </template>
    <div v-else class="py-4 text-center">
      <OtpValidation
        :user="user"
        :cause="1"
        :validatable="user.hashid"
        :second="doBackupNumber"
        @otp-validated="handleOtpValidation($event)"
      >
        <template v-slot:instruction>
          <h1>
            <b-icon icon="exclamation-circle-fill" variant="danger"></b-icon>
          </h1>
          <h2>
            E' necessario verificare il numero di telefono
            {{ doBackupNumber ? " di riserva " : "" }} del paziente.
          </h2>
          <h5 class="my-3">
            Clicca su "invia codice di verifica" per inviare il codice OTP di
            verifica via SMS
          </h5>
        </template>
      </OtpValidation>
    </div>
  </b-container>
  <h5 v-else-if="notFound">Utente Non trovato</h5>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { cittadinoService } from "@/_services";
import RilevazioniComponent from "@/components/profile/sanitario/RilevazioniComponent.vue";
import PatologieComponent from "@/components/profile/sanitario/PatologieComponent.vue";
import ArmadiettoComponent from "@/components/profile/sanitario/ArmadiettoComponent.vue";
import PrenotazioniPaziente from "@/components/pazienti/PrenotazioniPaziente.vue";
import SettedAlerts from "@/components/profile/sanitario/SettedAlerts.vue";
import OtpValidation from "@/components/utilities/OtpValidation.vue";

export default {
  name: "SchedaPaziente",
  components: {
    RilevazioniComponent,
    PatologieComponent,
    ArmadiettoComponent,
    PrenotazioniPaziente,
    SettedAlerts,
    OtpValidation,
  },
  data() {
    return {
      user: null,
      activePanel: "alerts",
      subject: null,
      notFound: false,
      blockView: false,
      doBackupNumber: false,
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    fetchUser() {
      const self = this;
      cittadinoService
        .getUserById(this.$route.params.hash)
        .then(function (res) {
          self.user = res.data.data;
          self.subject = {
            hashid: res.data.data.hashid,
            type: "user_in_app",
          };
          self.verifyCredentials(res.data.data);
          // self.memberList = res.data.data;
        })
        .catch(function (err) {
          console.log(err);
          self.notFound = true;
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare le informazioni.");
        });
    },
    verifyCredentials(user) {
      if (!user.number_verified_at) {
        return (this.blockView = true);
      }
      //DISABILITATA VERIFICA OBBLIGATORIA SUL SECONDO NUMERO
      // if (!user.email && !user.second_number_verified_at) {
      //   this.doBackupNumber = true;
      //   return (this.blockView = true);
      // }
      this.blockView = false;
    },
    handleOtpValidation(hasVerified) {
      if (hasVerified) {
        return this.fetchUser();
      }
      this.$router.push({ name: "pazienti" });
    },
  },
  created() {
    this.fetchUser();
  },
  updated() {
    if (this.activePanel == "alerts") {
      this.$nextTick(function () {
        this.$refs.alertPanel.scrollIntoView();
      });
    }
  },
};
</script>
<style scoped>
.accordion__hover:hover,
.accordion__hover:focus {
  background-color: inherit !important;
  box-shadow: none !important;
}

.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}

.when-open,
.when-closed {
  position: absolute;
  right: 0;
}
</style>
