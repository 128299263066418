<template>
  <b-container>
    <div class="my-3">
      <b-row align-v="stretch" class="
          my-2
          col-12
          px-0
          align-items-center align-content-center
          flex-wrap
          my-2
        ">
        <b-col class="col-12 col-md-3 my-2" v-for="(res, ind) in reservations" :key="ind">
          <div class="
              h-100
              text-left
              py-2
              px-3
              point
              border
              bg-white
              col-12
              border__2
              d-flex
              flex-column
              border-primary
            ">
            <p class="mb-0 fs-5 pt-1 text-uppercase text-primary" v-if="res.tipo_ordine === 'prestazione'">
              {{ res.orderable.prestazione.name }}
            </p>
            <p class="mb-0 fs-5 pt-1 text-uppercase text-primary" v-else>
              {{ res.tipo_ordine }}
            </p>
            <!-- <p class="mb-0 fs-5 pt-1 text-uppercase">
              {{ res.stato }}
            </p> -->
            <p class="
                mb-0
                mt-auto
                pt-1
                text-capitalize
                font-weight-bolder
                d-flex
                justify-items-between
              ">
              <span class="mr-auto">{{ formatData(res.data_inizio) }}</span>
              <span class="ml-auto" @click="showDetail(res)">
                <b-icon icon="eye-fill" font-scale="1.5" variant="secondary"></b-icon>
              </span>
            </p>
          </div>
        </b-col>
        <b-col class="text-left col-md-3">
          <div class="
              align-items-center align-content-center
              pt-4
              pb-3
              px-2
              point
              bg-white
            " @click="goToNew">
            <p>
              <b-iconstack font-scale="3.5" class="shadow_7 rounded-circle">
                <b-icon class="shadow_7" stacked icon="circle-fill" variant="secondary"></b-icon>
                <b-icon stacked icon="plus" scale="0.5" variant="white"></b-icon>
              </b-iconstack>
            </p>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <div v-if="reservations && reservations.length > 0">
          <div class="d-flex">
            <span class="text-info fw-bolder mr-1" v-if="total > 0">{{ fromPage }}-{{ toPage }} di
            </span>
            <span class="text-info fw-bolder"> {{ total }}</span>
          </div>
          <b-pagination v-model="filter.page" pills :total-rows="total" :per-page="10" align="center"
            :size="$root.isMobile ? 'sm' : 'lg'"></b-pagination>
        </div>
      </b-row>
      <b-modal id="modal-resdetails" hide-footer size="xl" @hidden="selectedReservation = null">
        <div v-if="selectedReservation">
          <!-- {{ row.item[selectedDay][selectedIndex] }} -->
          <b-card :header="
            'Dettaglio richiesta #' + selectedReservation.codice_richiesta
          " header-text-variant="info" header-bg-variant="white" header-tag="h4" class="text-left text-font-grey">
            <b-card-text>
              <p class="text-font-grey text-right mb-0">
                <strong class="text-secondary">Tipo richiesta: </strong>{{ selectedReservation.tipo_ordine }}
              </p>
              <h5 class="text-secondary fw-bolder mt-1">
                Stato:
                <span class="text-uppercase">{{
                selectedReservation.stato
                }}</span>
              </h5>
              <p class="mb-1 fw-bolder">
                <strong class="text-secondary">Codice Richiesta: </strong>{{ selectedReservation.codice_richiesta }}
              </p>
              <p class="mb-1 fw-bolder">
                <strong class="text-secondary">Numero Richiesta: </strong>#{{
                selectedReservation.numero
                }}
              </p>
              <p class="mb-2 text-secondary fw-bolder">
                Giorno e ora:
                <strong class="text-font-grey">{{
                selectedReservation.data_inizio | moment("DD.MM.YY HH:mm")
                }}</strong>
                <strong v-if="selectedReservation.data_fine">
                  -
                  {{ selectedReservation.data_fine | moment("HH:mm") }}</strong>
              </p>
              <DetailTeleconsulto :dettaglio="selectedReservation.orderable" :stato="selectedReservation.status"
                :quest="selectedReservation.filled_survey" :datainizio="selectedReservation.data_inizio"
                :hash="selectedReservation.hashid" v-if="selectedReservation.tipo_ordine === 'teleconsulto'" />
              <DetailPrestazione :detail="selectedReservation.orderable" :stato="selectedReservation.status"
                :quest="selectedReservation.filled_survey" v-if="selectedReservation.tipo_ordine === 'prestazione'"
                v-on:update-request-status="
                  handleStatusUpdate(selectedReservation, $event)
                " />
              <DetailRitiro :detail="selectedReservation.orderable" :stato="selectedReservation.status"
                v-if="selectedReservation.tipo_ordine === 'ritiro'" />
              <DetailProdotto :dettaglio="selectedReservation.orderable" :stato="selectedReservation.status"
                :datainizio="selectedReservation.data_inizio" v-if="selectedReservation.tipo_ordine === 'prodotto'"
                v-on:update-request-status="
                  handleStatusUpdate(selectedReservation, $event)
                " v-on:update-request-response="
                  sendOrderResponse(selectedReservation, $event)
                " ref="dett" />
              <p class="mb-0 bg-light p-1">
                <strong> Note Operatore </strong>
                <UpdateNotesComponent v-on:note-updated="handleNotesUpdate($event)" class="d__innline"
                  :hashid="selectedReservation.hashid" :reserdate="selectedReservation.data_inizio">
                </UpdateNotesComponent>
                <template v-if="selectedReservation.company_notes"><br>
                  {{ selectedReservation.company_notes }}
                </template>
              </p>
              <p class="mb-0  p-1 bg-light" v-if="selectedReservation.user_notes">
                <strong> Note dell'utente:</strong>
                {{ selectedReservation.user_notes }}
              </p>
              <div class="d-flex">
                <b-button size="sm" variant="primary primary_gradient rounded-pill fw-bolder px-3 fs-6 mx-2 mt-1"
                  @click="downloadPdf()">Scarica promemoria
                </b-button>
              </div>
              <div class="d-flex justify-content-end">
                <p class="mb-0 text-sec-2">
                  Effettuata il
                  <strong>{{
                  selectedReservation.created_at || moment("DD.MM.YY HH:mm")
                  }}</strong>
                </p>
              </div>
            </b-card-text>
          </b-card>
        </div>
      </b-modal>
    </div>
  </b-container>
</template>
<script>
import * as moment from "moment/moment";
import { mapState, mapActions } from "vuex";
import { reservationService } from "@/_services";
import DetailPrestazione from "@/components/prenotazioni/DetailPrestazione.vue";
import DetailTeleconsulto from "@/components/prenotazioni/DetailTeleconsulto.vue";
import DetailProdotto from "@/components/prenotazioni/DetailProdotto.vue";
import UpdateNotesComponent from "@/components/prenotazioni/UpdateNotesComponent.vue";
export default {
  name: "PrenotazioniPaziente",
  props: ["subject"],
  components: {
    DetailPrestazione,
    DetailTeleconsulto,
    DetailProdotto,
    UpdateNotesComponent
  },
  data() {
    return {
      reservations: [],
      countPrev: 0,
      count: 0,
      total: 0,
      fromPage: 0,
      toPage: 0,
      filter: { page: 0 },
      selectedReservation: null,
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
  },
  watch: {
    filter: {
      handler(prev, post) {
        //console.log("changed prev", prev.page, post);
        //console.log("changed post", post.page);
        this.prenotazioni = [];
        this.fetchData();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    goToNew() {
      this.$router.push({
        name: "prenota servizio",
        params: {
          hash: this.subject.hashid,
        },
      });
    },
    fetchData() {
      var self = this;
      reservationService
        .getUsersReservsInCompany(this.filter, this.subject.hashid)
        .then(function (res) {
          self.reservations = res.data.data.data;
          self.count = res.data.data.length;
          self.filter.page = res.data.data.current_page;
          self.total = res.data.data.total;
          self.fromPage = res.data.data.from;
          self.toPage = res.data.data.to;
        })
        .catch(function (error) {
          self.reservations = [];
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare le prenotazioni");
        });
    },
    formatData(data) {
      return moment(data).format("DD/MM/YYYY HH:mm");
    },
    showDetail(res) {
      //console.log(res.orderable);
      this.selectedReservation = res;
      this.$bvModal.show("modal-resdetails");
    },
    downloadPdf() {
      const hash = this.selectedReservation.hashid;
      const fName = this.selectedReservation.codice_richiesta;
      var self = this;
      reservationService
        .scaricaPromemoria(hash)
        .then(function (response) {
          let blob = new Blob([response.data]);
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = fName + ".pdf";
          link.click();
        })
        .catch(function (error) {
          self.loading = false;
          window.scrollTo(0, 0);
          self.errorAlert("non è stato possibile scaricare il promemoria");
        });
    },
    handleStatusUpdate(item, data) {
      // //console.log("item to edit", item);
      // //console.log("data to submit", data);
      var self = this;
      if (!data.confirmed) {
        // this.loading = true;
        var form = data.message ? { message: data.message } : {};
        reservationService
          .companyCancelOrReject(form, data.action, item.hashid)
          .then(function (re) {
            self.$bvModal.hide("modal-resdetails");
            // self.$refs["modal-resdetails"].hide();
            window.scrollTo(0, 0);
            self.successAlert("Richiesta aggiornata con successo");
            self.fetchData();
          })
          .catch(function (error) {
            self.$bvModal.hide("modal-resdetails");
            // self.$refs["resev-details"].hide();
            window.scrollTo(0, 0);
            self.errorAlert("non è stato possibile effettuare l'operazione");
            // self.loading = false;
          });
      } else {
        // this.loading = true;
        var form = data.message ? { message: data.message } : {};
        reservationService
          .companyConfirmOrder(item.hashid)
          .then(function (re) {
            window.scrollTo(0, 0);
            self.successAlert("Richiesta confermata con successo");
            self.fetchData();
          })
          .catch(function (error) {
            window.scrollTo(0, 0);
            self.errorAlert("non è stato possibile effettuare l'operazione");
            // self.loading = false;
          });
      }
      // if (data.action == "reject") {
      //   //do reject
      // }
      // if (data.action == "cancel") {
      //   //do Cancel
      // }
    },
    handleNotesUpdate(data) {
      this.selectedReservation.company_notes = data.company_notes
    }
  },
  created() {
    //console.log(this.subject);
    this.fetchData();
  },
};
</script>