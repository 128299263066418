<template>
  <div class="col-12 px-md-0 px-1 d-flex my-4">
    <b-button class="ml-0 mr-auto font-weight-bolder" v-if="!first" variant="outline-primary" @click="subtract">
      Indietro
    </b-button>
    <b-button class="mr-0 ml-auto font-weight-bolder" v-if="!islast && !waiting" variant="primary" @click="add">
      Avanti
    </b-button>
  </div>
</template>
<script>
export default {
  name: "StepperButtons",
  props: ["first", "islast", "waiting"],
  data() {
    return {
      count: 1,
    };
  },
  // watch: {
  //   count: function (val) {
  //     //console.log("emitting val", val);
  //     this.$emit("counter-update", val);
  //   },
  // },
  methods: {
    add() {
      window.scrollTo(0, 0)
      this.$emit("counter-update", +1);
    },
    subtract() {
      window.scrollTo(0, 0)
      this.$emit("counter-update", -1);
    },
  },
  created() {
    this.islast;
  },
};
</script>