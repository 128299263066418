<template>
  <b-container v-if="notFound">
    <b-row cols="1" class="text-left mb-4">
      <router-link class="fw-bolder text-primary no__hover" :to="{ name: 'clienti' }">
        <b-icon icon="arrow-left-circle-fill" variant="primary"></b-icon>
        Clienti
      </router-link>
    </b-row>
    <h5 class="font-weight-bolder" v-if="notFound">
      Nessun Cliente selezionato
    </h5>
  </b-container>
  <b-container v-else>
    <b-row cols="1" class="text-left mb-4">
      <router-link class="fw-bolder text-primary no__hover"
        :to="{ name: 'scheda Paziente', params: { hash: $route.query.sub } }">
        <b-icon icon="arrow-left-circle-fill" variant="primary"></b-icon>
        Scheda paziente
      </router-link>
    </b-row>
    <div v-if="therapy" class="text-left">
      <h3 class="font-weight-bolder text-center">Riepilogo</h3>
      <RecapTherapy :therapy="therapy" class="fs-5" />
      <b-button @click="downloadRecap" variant="secondary text-white secondary_gradient mx-2">Scarica promemoria
      </b-button>
      <b-button @click="
        $router.push({
          name: 'nuova notifica paziente',
          params: { hash: $route.query.sub },
        })
      " variant="primary text-white primary_gradient mx-2">Imposta alert
        <b-icon icon="arrow-right"></b-icon>
      </b-button>
    </div>
    <NewTherapy v-else :subject="subject" v-on:save-therapy="showRecap($event)" />
  </b-container>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { cittadinoService } from "@/_services";
import NewTherapy from "@/components/profile/sanitario/NewTherapy.vue";
import RecapTherapy from "@/components/profile/sanitario/RecapTherapy.vue";
export default {
  name: "NuovaTerapiaCliente",
  components: { NewTherapy, RecapTherapy },
  data() {
    return {
      activePanel: "rilevazioni",
      subject: {
        hashid: this.$route.query.sub,
        type: this.$route.query.type,
      },
      notFound: false,
      therapy: null,
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
    ...mapState("utente", ["status", "user"]),
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    ...mapActions("utente", ["me"]),
    showRecap(therapy) {
      //console.log(therapy);
      this.therapy = therapy;
    },
    downloadRecap() {
      var self = this;
      cittadinoService
        .therapyRecap(this.therapy.hashid)
        .then(function (response) {
          let blob = new Blob([response.data]);
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "promemoria_terapia.pdf";
          link.click();
        })
        .catch(function (error) {
          self.errorAlert("Non è stato possibile scaricare il promemoria");
        });
    },
  },
  created() {
    if (!this.$route.query.sub) {
      this.notFound = true;
    }
  },
};
</script>