var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-container',[(_vm.showDisponibilita)?_c('b-row',{ref:"calendarchoice",staticClass:"my-2",attrs:{"cols":"1"}},[(!_vm.notitle)?_c('h6',{staticClass:"mt-2 text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"},[_vm._v(" Scegli data ed ora ")]):_vm._e()]):_vm._e(),(_vm.showDisponibilita && _vm.calendarChoice)?_c('b-row',{staticClass:"my-2",attrs:{"cols":"1"}},[_c('b-col',{staticClass:"text-center"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('b-button',{attrs:{"variant":"outline-info","size":_vm.$root.isMobile ? 'sm' : '',"type":"button","disabled":_vm.checkPrecedente},on:{"click":_vm.setPrev}},[_c('b-icon',{attrs:{"icon":"chevron-double-left"}}),_vm._v(_vm._s(!_vm.$root.isMobile ? "Precedente" : "")+" ")],1),_c('h5',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.start,"D MMM"))+" - "+_vm._s(_vm._f("moment")(_vm.end,"D MMM"))+" ")]),_c('b-button',{attrs:{"variant":"outline-info","type":"button","size":_vm.$root.isMobile ? 'sm' : ''},on:{"click":_vm.setPost}},[_vm._v(_vm._s(!_vm.$root.isMobile ? "Successiva" : "")+" "),_c('b-icon',{attrs:{"icon":"chevron-double-right"}})],1)],1),_c('b-table',{attrs:{"borderless":"","responsive":"","sticky-header":"","table-variant":"white text-info","fields":_vm.fields,"items":_vm.disponibilita,"foot-clone":"","small":"","show-empty":""},scopedSlots:_vm._u([{key:"cell(1)",fn:function(row){return _vm._l((row.item['1']),function(slot){return _c('p',{key:slot,staticClass:"general_border point border border-info slot__tm",class:_vm.checkIfSelected(1, slot)
                  ? 'bg-info text-white'
                  : 'bg-white text-info',on:{"click":function($event){return _vm.selectDate(1, slot)}}},[_c('strong',{},[_vm._v(_vm._s(slot))])])})}},{key:"cell(2)",fn:function(row){return _vm._l((row.item['2']),function(slot){return _c('p',{key:slot,staticClass:"general_border point border border-info slot__tm",class:_vm.checkIfSelected(2, slot)
                  ? 'bg-info text-white'
                  : 'bg-white text-info',on:{"click":function($event){return _vm.selectDate(2, slot)}}},[_c('strong',{},[_vm._v(_vm._s(slot))])])})}},{key:"cell(3)",fn:function(row){return _vm._l((row.item['3']),function(slot){return _c('p',{key:slot,staticClass:"general_border point border border-info slot__tm",class:_vm.checkIfSelected(3, slot)
                  ? 'bg-info text-white'
                  : 'bg-white text-info',on:{"click":function($event){return _vm.selectDate(3, slot)}}},[_c('strong',{},[_vm._v(_vm._s(slot))])])})}},{key:"cell(4)",fn:function(row){return _vm._l((row.item['4']),function(slot){return _c('p',{key:slot,staticClass:"general_border point border border-info slot__tm",class:_vm.checkIfSelected(4, slot)
                  ? 'bg-info text-white'
                  : 'bg-white text-info',on:{"click":function($event){return _vm.selectDate(4, slot)}}},[_c('strong',{},[_vm._v(_vm._s(slot))])])})}},{key:"cell(5)",fn:function(row){return _vm._l((row.item['5']),function(slot){return _c('p',{key:slot,staticClass:"general_border point border border-info slot__tm",class:_vm.checkIfSelected(5, slot)
                  ? 'bg-info text-white'
                  : 'bg-white text-info',on:{"click":function($event){return _vm.selectDate(5, slot)}}},[_c('strong',{},[_vm._v(_vm._s(slot))])])})}},{key:"cell(6)",fn:function(row){return _vm._l((row.item['6']),function(slot){return _c('p',{key:slot,staticClass:"general_border point border border-info slot__tm",class:_vm.checkIfSelected(6, slot)
                  ? 'bg-info text-white'
                  : 'bg-white text-info',on:{"click":function($event){return _vm.selectDate(6, slot)}}},[_c('strong',{},[_vm._v(_vm._s(slot))])])})}},{key:"cell(7)",fn:function(row){return _vm._l((row.item['7']),function(slot){return _c('p',{key:slot,staticClass:"general_border point border border-info slot__tm",class:_vm.checkIfSelected(7, slot)
                  ? 'bg-info text-white'
                  : 'bg-white text-info',on:{"click":function($event){return _vm.selectDate(7, slot)}}},[_c('strong',{},[_vm._v(_vm._s(slot))])])})}},{key:"empty",fn:function(scope){return [(scope)?_c('p',{staticClass:"fw-bolder text-secondary"},[_vm._v(" Nessuna disponibilità per questa settimana. Prova a verificare le disponibilità per le prossime settimane. ")]):_vm._e()]}}],null,false,3921529006)})],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }