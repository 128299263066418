<template>
  <div class="pt-2 pb-4">
    <p class="text-justify fw-bolder text-medium-grey">
      Inserisci il collegamento al sito web della tua farmacia
    </p>
    <template v-if="loading">
      <b-spinner
        v-show="isSearching"
        class="mt-2"
        variant="primary"
        label="Loading..."
      ></b-spinner>
    </template>
    <template v-else>
      <b-row class="my-3">
        <b-col class="text-left col-md-3 col-lg-2 d-flex align-items-center">
          <b-icon
            class="mr-3 point"
            icon="globe"
            font-scale="1.5"
            variant="primary"
          />
          <strong class="text-capitalize fw-bolder"> Sito Web </strong>
        </b-col>
        <b-col class="text-justify col-md-6 col-lg-8">
          <b-form-input v-model="sitoweb" class="shadow_6"></b-form-input>
        </b-col>
        <b-col class="text-center d-flex">
          <b-button
            v-if="sitoweb"
            size="sm"
            variant="outline-danger fw-bolder"
            class="rounded-pill px-3"
            @click="sitoweb = null"
          >
            Rimuovi
          </b-button>
        </b-col>
      </b-row>
      <div
        class="mt-5 px-4"
        :class="
          !allFieldsAreValidUrl
            ? 'd-flex justify-content-between align-items-center'
            : 'text-right'
        "
      >
        <div class="d-col" v-if="!allFieldsAreValidUrl">
          <p class="text-danger text-left">
            Il campo deve essere un URL valido
          </p>
          <p class="mb-0">
            Un URL valido è ad esempio: https://www.farmacia.it
          </p>
        </div>
        <b-button
          variant="outline-primary"
          v-if="hasEdited"
          :disabled="!allFieldsAreValidUrl"
          class="point"
          @click="saveEdits()"
          >Salva modifiche</b-button
        >
      </div>
    </template>
  </div>
</template>
<script>
import { companyService } from "@/_services"
import { mapState, mapActions } from "vuex"
export default {
  name: "SitoWeb",
  data() {
    return {
      loading: false,
      sitoweb: null
    }
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert
    }),
    ...mapState("utente", ["status", "user"]),
    hasEdited() {
      return this.sitoweb != this.user.company.sito_web
    },
    allFieldsAreValidUrl() {
      if (this.sitoweb) {
        try {
          new URL(this.sitoweb)
          return (
            this.sitoweb.includes("http://") ||
            this.sitoweb.includes("https://") ||
            this.sitoweb.includes("www.")
          )
        } catch (e) {
          return false
        }
      } else return true
    }
  },
  watch: {
    sitoweb: {
      handler: function (newVal) {
        if (newVal === "") {
          this.sitoweb = null
        }
      },
      deep: true
    }
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    ...mapActions("utente", ["unMe", "me"]),
    saveEdits() {
      this.loading = true
      var form = {
        sito_web: this.sitoweb
      }
      var self = this
      companyService
        .updateCompanySitoWeb(form)
        .then(function (res) {
          self.successAlert("Modifiche salvate con successo")
          self.me()
        })
        .catch(function (err) {
          window.scrollTo(0, 0)
          self.errorAlert("non è stato possibile aggiornare il sito web")
        })
        .finally(function () {
          self.loading = false
        })
    }
  },
  created() {
    this.sitoweb = this.user.company.sito_web
  }
}
</script>
