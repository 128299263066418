<template>
  <div class="col-12 py-4">
    <h6
      class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
    >
      Statistiche - dati rendicontazione
    </h6>
    <p class="text-justify text-medium-grey fw-bolder">
      Visualizza il numero di compilazioni effettuate nel periodo per tutte le
      attività ed i questionari abilitati.
    </p>
    <b-alert variant="info" show>
      I report sono riepilogativi delle attività e questionari registrati in piattaforma e l'eventuale remunerazione, come indicato nelle delibere regionali, avverrà esclusivamente sulla base dei controlli effettuati dal soggetto promotore della sperimentazione.
    </b-alert>
    <b-row
      class="shadow_6 border border-secondary bg-light p-3"
      align-v="center"
    >
      <!--b-form-group class="text-left mx-2">
        <label for="">Servizio</label>
        <b-form-select
          class="shadow_6"
          v-model="filterSetted.service_ref"
          @change="fetchData"
        >
          <b-form-select-option :value="3">Con Te</b-form-select-option>
          <b-form-select-option :value="2">Connessa</b-form-select-option>
          <b-form-select-option :value="1"
            >Facile Prestazioni</b-form-select-option
          >
          <b-form-select-option :value="undefined"
            >Tutti i servizi</b-form-select-option
          >
        </b-form-select>
      </b-form-group-->
      <b-form-group class="text-left mx-2">
        <label for="">Compilazioni dal*</label>
        <b-form-input
          type="date"
          class="shadow_6"
          v-model="filterSetted.from"
          :state="validDates"
          @input="fetchData"
        ></b-form-input>
      </b-form-group>
      <b-form-group class="text-left mx-2">
        <label for="">Compilazioni al*</label>
        <b-form-input
          type="date"
          class="shadow_6"
          v-model="filterSetted.to"
          :state="validDates"
          @input="fetchData"
        ></b-form-input>
      </b-form-group>
      <b-col class="pr-0 text-left">
        <b-button variant="info fw-bolder mt-3" @click="resetSearch"
          >Reset</b-button
        ></b-col
      >
      <b-col class="pr-0 text-right">
        <b-button
          variant="primary primary_gradient text-white fw-bolder spacing_1 mt-3"
          @click="downloadReport"
        >
          <b-icon icon="download"></b-icon>
          Esporta</b-button
        ></b-col
      >
    </b-row>
    <div class="mt-3">
      <b-table
        borderless
        hover
        responsive
        table-variant="white text-info"
        tbody-tr-class="text-secondary fw-bolder"
        head-variant="info"
        :fields="fields"
        :items="activities"
        :small="$root.isMobile"
        :busy="loading"
        select-mode="single"
        @row-selected="onRowSelected"
        id="activity-stats"
      >
      </b-table>
      <p v-if="activities && activities.length == 0">
        Nessun risultato per la selezione indicata
      </p>
    </div>
  </div>
</template>
<script>
import * as moment from "moment/moment";
import { supportService } from "@/_services";
import { mapState, mapActions } from "vuex";
import NuovaAttivita from "@/components/attivita/NuovaAttivita.vue";
import ActivityDetails from "@/components/attivita/ActivityDetails.vue";
export default {
  name: "HomeStatisticheAttivita",
  components: { NuovaAttivita, ActivityDetails },
  data() {
    return {
      filterSetted: { search: null, from: null, to: null, service_ref: 3 },
      fields: [
        {
          key: "activity_name",
          label: "Attività",
          class: "text-justify",
        },
        {
          key: "survey_name",
          label: "Questionario",
          class: "text-justify",
        },
        { key: "arruolamento", label: "Pazienti Arruolati", class: "" },
        { key: "follow_up", label: "Follow Up", class: "" },
        { key: "total", label: "Totale compilazioni", class: "" },
        { key: "con_prescrizione", label: "Con Prescrizione", class: "" },
      ],
      activities: null,
      selectedActivity: null,
      showDetails: false,
      selectedItem: null,
      loading: true,
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
    ...mapState("utente", ["status", "user"]),
    validDates: function () {
      if (this.filterSetted.from && this.filterSetted.to) {
        return moment(this.filterSetted.from).isSameOrBefore(
          moment(this.filterSetted.to)
        );
      }
      return null;
    },
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    fetchData() {
      this.loading = true;
      const self = this;
      supportService
        .getActivitiesStatistics(this.filterSetted)
        .then(function (res) {
          self.activities = res.data.data;
          self.loading = false;
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare le attività");
          self.loading = false;
          console.log(err);
        });
      //
    },
    resetSearch() {
      this.filterSetted.from = null;
      this.filterSetted.to = null;
      this.filterSetted.service_ref = 3;
      this.fetchData();
    },
    handleNewActivity(resp) {
      this.$refs["modal-newactivity"].hide();
      window.scrollTo(0, 0);
      if (resp == true) {
        this.successAlert("Attività creata con successo!");
        this.fetchData();
      } else {
        this.errorAlert("Non è stato possibile creare l'attività");
      }
    },
    selectChangeStatus(row) {
      this.selectedItem = row.index;
      this.$refs["modal-statusconfirm"].show();
    },
    cancelOp() {
      this.$refs["modal-statusconfirm"].hide();
      this.fetchData();
    },
    changeStatus() {
      var formData = {
        hash: this.activities[this.selectedItem].hashid,
        form: {
          active: this.activities[this.selectedItem].active,
        },
      };
      var self = this;
      supportService
        .updateActivity(formData)
        .then(function (res) {
          window.scrollTo(0, 0);
          self.successAlert("Stato attività aggiornato con successo!");
          self.cancelOp();
        })
        .catch(function (err) {
          console.log(err);
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile aggiornare lo stato attività");
          self.cancelOp();
        });
    },
    onRowSelected(items) {
      this.selectedActivity = items[0];
      //console.log(this.selectedActivity);
      if (this.selectedActivity.prof2 === 1) {
        this.$router.push({
          name: "progetto prof2",
          query: { activity: this.selectedActivity.name },
        });
      } else {
        this.showDetails = true;
        this.$nextTick(function () {
          this.$refs["activitydetail"].scrollIntoView();
        });
      }
    },
    deleteActivity(hash) {
      //console.log("hash", hash);
      var self = this;
      supportService
        .deleteActivity(hash)
        .then(function (res) {
          window.scrollTo(0, 0);
          self.successAlert("Attività eliminata con successo!");
          self.fetchData();
          self.selectedActivity = null;
          self.showDetails = false;
        })
        .catch(function (err) {
          console.log(err);
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile eliminare l'attività");
          self.fetchData();
        });
    },
    updateActivity(data) {
      //console.log("data", data);
      var formData = {
        hash: data.hashid,
        form: data.form,
      };
      var self = this;
      supportService
        .updateActivity(formData)
        .then(function (res) {
          window.scrollTo(0, 0);
          self.successAlert("Attività aggiornata con successo!");
          self.fetchData();
        })
        .catch(function (err) {
          console.log(err);
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile aggiornare l'attività");
          self.fetchData();
        });
    },
    downloadReport() {
      //var today = moment().format("DD/MM/YYYY");
      var self = this;
      this.loading = true;
      supportService
        .getActivitiesStatisticsReport(this.filterSetted)
        .then(function (response) {
          self.loading = false;
          let blob = new Blob([response.data]);
          // let blob = new Blob([response.data], { type: "text/csv" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "report_Statistiche_dati_rendicontazione.xlsx";
          link.click();
        })
        .catch(function (err) {
          self.loading = false;
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare le attività");
          self.loading = false;
          console.log(err);
        });
    },
  },
  created() {
    this.fetchData();
  },
};
</script>
