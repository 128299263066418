<template>
  <div>
    <b-container>
      <b-row
        cols="1"
        v-if="showDisponibilita"
        class="my-2"
        ref="calendarchoice"
      >
        <h6
          v-if="!notitle"
          class="mt-2 text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
        >
          Scegli data ed ora
        </h6>
      </b-row>
      <b-row cols="1" v-if="showDisponibilita && calendarChoice" class="my-2">
        <b-col class="text-center">
          <div class="d-flex justify-content-between">
            <b-button
              variant="outline-info"
              :size="$root.isMobile ? 'sm' : ''"
              type="button"
              @click="setPrev"
              :disabled="checkPrecedente"
            >
              <b-icon icon="chevron-double-left"></b-icon
              >{{ !$root.isMobile ? "Precedente" : "" }}
            </b-button>
            <h5 class="text-capitalize">
              {{ start | moment("D MMM") }} - {{ end | moment("D MMM") }}
            </h5>
            <b-button
              variant="outline-info"
              type="button"
              :size="$root.isMobile ? 'sm' : ''"
              @click="setPost"
              >{{ !$root.isMobile ? "Successiva" : "" }}
              <b-icon icon="chevron-double-right"></b-icon>
            </b-button>
          </div>
          <b-table
            borderless
            responsive
            sticky-header
            table-variant="white text-info"
            :fields="fields"
            :items="disponibilita"
            foot-clone
            small
            show-empty
          >
            <template #cell(1)="row">
              <p
                @click="selectDate(1, slot)"
                class="general_border point border border-info slot__tm"
                v-for="slot in row.item['1']"
                :key="slot"
                :class="
                  checkIfSelected(1, slot)
                    ? 'bg-info text-white'
                    : 'bg-white text-info'
                "
              >
                <strong class="">{{ slot }}</strong>
              </p>
            </template>
            <template #cell(2)="row">
              <p
                @click="selectDate(2, slot)"
                class="general_border point border border-info slot__tm"
                v-for="slot in row.item['2']"
                :key="slot"
                :class="
                  checkIfSelected(2, slot)
                    ? 'bg-info text-white'
                    : 'bg-white text-info'
                "
              >
                <strong class="">{{ slot }}</strong>
              </p>
            </template>
            <template #cell(3)="row">
              <p
                @click="selectDate(3, slot)"
                class="general_border point border border-info slot__tm"
                v-for="slot in row.item['3']"
                :key="slot"
                :class="
                  checkIfSelected(3, slot)
                    ? 'bg-info text-white'
                    : 'bg-white text-info'
                "
              >
                <strong class="">{{ slot }}</strong>
              </p>
            </template>
            <template #cell(4)="row">
              <p
                @click="selectDate(4, slot)"
                class="general_border point border border-info slot__tm"
                v-for="slot in row.item['4']"
                :key="slot"
                :class="
                  checkIfSelected(4, slot)
                    ? 'bg-info text-white'
                    : 'bg-white text-info'
                "
              >
                <strong class="">{{ slot }}</strong>
              </p>
            </template>
            <template #cell(5)="row">
              <p
                @click="selectDate(5, slot)"
                class="general_border point border border-info slot__tm"
                v-for="slot in row.item['5']"
                :key="slot"
                :class="
                  checkIfSelected(5, slot)
                    ? 'bg-info text-white'
                    : 'bg-white text-info'
                "
              >
                <strong class="">{{ slot }}</strong>
              </p>
            </template>
            <template #cell(6)="row">
              <p
                @click="selectDate(6, slot)"
                class="general_border point border border-info slot__tm"
                v-for="slot in row.item['6']"
                :key="slot"
                :class="
                  checkIfSelected(6, slot)
                    ? 'bg-info text-white'
                    : 'bg-white text-info'
                "
              >
                <strong class="">{{ slot }}</strong>
              </p>
            </template>
            <template #cell(7)="row">
              <p
                @click="selectDate(7, slot)"
                class="general_border point border border-info slot__tm"
                v-for="slot in row.item['7']"
                :key="slot"
                :class="
                  checkIfSelected(7, slot)
                    ? 'bg-info text-white'
                    : 'bg-white text-info'
                "
              >
                <strong class="">{{ slot }}</strong>
              </p>
            </template>
            <template #empty="scope">
              <p class="fw-bolder text-secondary" v-if="scope">
                Nessuna disponibilità per questa settimana. Prova a verificare
                le disponibilità per le prossime settimane.
              </p>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import * as moment from "moment/moment";
import { mapState, mapActions } from "vuex";
import { reservationService } from "@/_services";
export default {
  name: "DatetimeSelection",
  props: ["company", "service", "hservice", "restype", "prevdate", "notitle"],
  computed: {
    checkPrecedente: function () {
      var today = moment(this.today).week();
      var start = moment(this.filters.from, "D/M/YY").week();
      return start <= today;
    },
    ...mapState("utente", ["status", "user"]),
    ...mapState({
      alert: (state) => state.alert,
    }),
  },
  watch: {
    form: {
      handler(val) {
        if (val.data_inizio !== null) {
          this.$nextTick(function () {
            this.$refs["orderConfirm"].scrollIntoView();
          });
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      form: {
        company_id: this.company,
        service_id: this.service,
      },
      selectedCompany: null,
      start: null,
      end: null,
      month: null,
      today: null,
      fields: [],
      disponibilita: null,
      selectedSlot: null,
      selectedCol: null,
      filters: {
        from: null,
        to: null,
      },
      showDisponibilita: false,
      sending: false,
      calendarChoice: true,
      errorMsg: null,
    };
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    ...mapActions("utente", ["me"]),
    formatDisponibilitaService() {
      var data = {
        hash: this.service,
        company: this.company,
        params: this.filters,
      };
      var self = this;
      reservationService
        .disponibilitaServizio(data)
        .then(function (res) {
          self.disponibilita = [res.data.data];
          if (res.data.data.length == 0) {
            self.errorMsg =
              "Nessuna disponibilità per la settimana e la prestazione scelta";
          }
        })
        .catch(function (error) {
          self.disponibilita = [];
          self.errorMsg =
            "Non è stato possibile trovare disponibilità per questo servizio";
        });
    },
    formatDisponibilitaPrestazione() {
      var data = {
        hash: this.hservice,
        params: this.filters,
      };
      var self = this;
      reservationService
        .disponibilitaPrestazione(data)
        .then(function (res) {
          self.disponibilita = [res.data.data];
          if (res.data.data.length == 0) {
            window.scrollTo(0, 0);
            self.infoAlert(
              "La prestazione scelta non ha nessuna disponibilità per la settimana corrente"
            );
          }
        })
        .catch(function (error) {
          self.disponibilita = [];
          window.scrollTo(0, 0);
          self.errorAlert(
            "Non è stato possibile trovare disponibilità per questo servizio"
          );
        });
    },
    setPrev() {
      this.start.subtract(1, "weeks");
      this.end.subtract(1, "weeks");
      this.filters.from = moment(this.start).startOf("week").format("D/M/YY");
      this.filters.to = moment(this.end).endOf("week").format("D/M/YY");
      this.selectedSlot = null;
      this.selectedCol = null;
      this.setupCalendar();
    },
    setPost() {
      this.start.add(1, "weeks");
      this.end.add(1, "weeks");
      this.filters.from = moment(this.start).startOf("week").format("D/M/YY");
      this.filters.to = moment(this.end).endOf("week").format("D/M/YY");
      this.selectedSlot = null;
      this.selectedCol = null;
      this.setupCalendar();
    },
    setupCalendar() {
      this.selectedSlot = null;
      this.selectedCol = null;
      var fields = [];
      for (let x = 0; x <= 6; x++) {
        let startDay = moment(this.start);
        let todayDay = moment(this.today).format("ddd DD/M");
        let date = startDay.add(x, "days").format("ddd DD/M");
        let variant = undefined;
        if (todayDay === date) {
          variant = "sec-4";
        }
        let chiave = x + 1;
        let newField = {
          key: chiave.toString(),
          label: date,
          variant: variant,
        };
        fields.push(newField);
      }
      this.fields = fields;

      if (this.restype === "prestazione") {
        this.formatDisponibilitaPrestazione();
      } else {
        this.formatDisponibilitaService();
      }

      this.showDisponibilita = true;
    },
    selectDate(col, slot) {
      this.selectedCol = col;
      this.selectedSlot = slot;
      let startDay = moment(this.start);
      let date = startDay.add(col - 1, "days").format("ddd DD/MM/YYYY");
      date += " " + slot;
      this.form.data_inizio = moment(date, "ddd DD/MM/YYYY HH:mm");
      this.$emit("date-selected", this.form.data_inizio);
    },
    checkIfSelected(col, slot) {
      if (!this.selectedCol || !this.selectedSlot) {
        return false;
      }
      if (this.selectedCol == col && this.selectedSlot == slot) {
        return true;
      }
    },
  },
  created() {
    this.start = moment().startOf("week");
    this.end = moment().endOf("week");
    this.today = moment();
    this.month = moment().month();
    this.filters.from = moment().startOf("week").format("D/M/YY");
    this.filters.to = moment().endOf("week").format("D/M/YY");
  },
  mounted() {
    this.setupCalendar();
  },
};
</script>
