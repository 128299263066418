<template>
  <b-container>
    <b-row cols="1" class="text-left mb-3">
      <router-link class="fw-bolder text-primary no__hover" :to="{ name: 'CittadinoHome' }">
        <b-icon icon="arrow-left-circle-fill" variant="primary"></b-icon>
        Homepage
      </router-link>
    </b-row>
    <div class="col-12">
      <b-button-group class="mb-3 bg-line-grey rounded-pill">
        <b-button class="fw-bolder text-capitalize" :size="$root.isMobile ? 'sm' : ''"
          @click="activePanel = 'calendario'" :variant="
            activePanel === 'calendario'
              ? 'primary primary_gradient text-white rounded-pill'
              : 'bg-light text-primary'
          ">calendario</b-button>
        <b-button class="fw-bolder text-capitalize" :size="$root.isMobile ? 'sm' : ''" @click="activePanel = 'recenti'"
          :variant="
            activePanel === 'recenti'
              ? 'primary primary_gradient text-white rounded-pill'
              : 'bg-light text-primary'
          ">recenti</b-button>

        <b-button class="fw-bolder text-capitalize" :size="$root.isMobile ? 'sm' : ''" @click="activePanel = 'storico'"
          :variant="
            activePanel === 'storico'
              ? 'primary primary_gradient text-white rounded-pill'
              : 'bg-light text-primary'
          ">storico</b-button>
      </b-button-group>
    </div>
    <transition-group name="list" mode="in-out">
      <div v-if="activePanel === 'recenti'" key="recenti">
        <h6 class="
            text-prim-grad-1
            fw-bolder
            text-left
            pb-1
            border-bottom border-line-grey
          ">
          Richieste Recenti
        </h6>
        <p class="text-left text-font-light-grey fs-5">
          I tuoi ordini e prenotazioni recenti.
        </p>
        <UserLatests />
      </div>
      <div v-if="activePanel === 'calendario'" key="calendario">
        <template v-if="!$root.isMobile">
          <h6 class="
              text-prim-grad-1
              fw-bolder
              text-left
              pb-1
              border-bottom border-line-grey
            ">
            Calendario
          </h6>
          <p class="text-left text-font-light-grey fs-5">Il tuo calendario</p>
          <CalendarioCittadino />
        </template>
        <CalendarioCittadinoMobile v-else />
      </div>
      <div v-if="activePanel === 'storico'" key="storico">
        <h6 class="
            text-prim-grad-1
            fw-bolder
            text-left
            pb-1
            border-bottom border-line-grey
          ">
          Storico Richieste
        </h6>
        <p class="text-left text-font-light-grey fs-5">
          Lo storico dei tuoi ordini e delle tue prenotazioni.
        </p>
        <UserStorico />
      </div>
    </transition-group>
    <!-- <b-container class="mt-4 pt-4"> -->
    <!-- <b-row class="justify-content-end">
        <b-col> -->
    <NewStuff />
    <!-- </b-col>
      </b-row> -->
    <!-- </b-container> -->
  </b-container>
</template>
<script>
import NewStuff from "@/components/home/NewStuff.vue";
import CalendarioCittadino from "@/components/prenotazioni/CalendarioCittadino.vue";
import CalendarioCittadinoMobile from "@/components/prenotazioni/CalendarioCittadinoMobile.vue";
import UserLatests from "@/components/prenotazioni/UserLatests.vue";
import UserStorico from "@/components/prenotazioni/UserStorico.vue";
export default {
  name: "AgendaCittadino",
  components: {
    CalendarioCittadino,
    CalendarioCittadinoMobile,
    UserLatests,
    NewStuff,
    UserStorico,
  },
  data() {
    return {
      activePanel: "calendario",
    };
  },
};
</script>
