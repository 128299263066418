<template>
  <h5 v-if="blockView" class="text-danger font-weight-bolder">
    Per accedere al servizio è necessario acquistare l'abbonamento
  </h5>
  <b-container class="mt-4" v-else>
    <b-row cols="1" class="my-2" v-if="!arruolamento">
      <h6 class="
          text-prim-grad-1
          fw-bolder
          text-left
          pb-1
          border-bottom border-line-grey
        ">
        Terapie inserite per i tuoi pazienti
      </h6>
      <b-col cols="12" cols-md="6" class="text-left my-3 pb-3 px-0">
        <b-row cols="2">
          <b-col cols="1" class="px-0 text-right mr-1">
            <transition name="slidevert" mode="out-in">
              <h5 :key="counter">
                <span>{{ counter }}</span>
              </h5>
            </transition>
          </b-col>
          <b-col cols="10" class="px-0 ml-1">
            <h5>terapie inserite</h5>
          </b-col>
        </b-row>
      </b-col>
      <h6 class="
          text-prim-grad-1
          fw-bolder
          text-left
          pb-1
          border-bottom border-line-grey
        ">
        Arruola Paziente
      </h6>
      <p class="text-left text-font-light-grey fs-5">
        Prima di registrare un nuovo paziente verifica se è già presente in
        piattaforma
      </p>

      <SearchUtente :type="'paziente'" v-on:invite-user="goToScheda($event)" v-on:arruola-user="doRegister($event)" />
    </b-row>
    <b-row cols="1" class="my-2" v-else>
      <h6 class="
          text-prim-grad-1
          fw-bolder
          text-left
          pb-1
          border-bottom border-line-grey
        ">
        Arruola Paziente
      </h6>
      <p class="text-left text-font-light-grey fs-5"></p>
      <SchedaArruolamento :params="precompiled" v-on:cancel-operation="undoArruolamento"
        v-on:arruolamento-completed="goToScheda($event)" />
    </b-row>
  </b-container>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { supportService } from "@/_services";
import SearchUtente from "@/components/utilities/SearchUtente.vue";
import SchedaArruolamento from "@/components/pazienti/SchedaArruolamento.vue";
export default {
  name: "HomePazienti",
  components: { SearchUtente, SchedaArruolamento },
  data() {
    return {
      arruolamento: false,
      precompiled: null,
      blockView: false,
      counter: 0,
      counterValue: 0,
      timer: null,
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
    ...mapState("company", ["companyLoggedIn", "company"]),
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    goToScheda(hashid) {
      //console.log(hashid);
      this.$router.push({
        name: "impostazione alerts",
        params: { hash: hashid },
      });
    },
    doRegister(params) {
      this.arruolamento = true;
      this.precompiled = params;
    },
    undoArruolamento() {
      this.arruolamento = false;
      this.precompiled = null;
    },
    fetchData() {
      var self = this;
      supportService
        .getTotalPatients()
        .then(function (res) {
          self.counterValue = res.data.data.terapie;
          self.counter = self.counterValue;

          // self.setCounterTimer();
        })
        .catch(function (err) {
          self.errorAlert(
            "Non è stato possibile aggiornare il totale pazienti"
          );
        });
    },
    // setCounterTimer() {
    //   this.timer = setInterval(() => {
    //     if (this.counter < this.counterValue) {
    //       this.counter++;
    //     } else {
    //       this.stopCounterTimer();
    //     }
    //   }, 50);
    // },
    // stopCounterTimer() {
    //   clearInterval(this.timer);
    //   this.timer = null;
    // },
  },
  created() {
    // this.blockView = this.company.stato_servizi["facile+"] == 0;
    this.fetchData();
  },
  // mounted() {
  //   document.addEventListener('keydown', function (event) {
  //     if (event.keyCode == 13 || event.keyCode == 17 || event.keyCode == 74)
  //       event.preventDefault();
  //   });
  // },
  // beforeDestroy() {
  //   document.removeEventListener('keydown', function (event) {
  //     console.log("HERE remove", event)
  //   });
  // },
  // beforeDestroy() {
  //   if (this.timer !== null) {
  //     clearInterval(this.timer);
  //     this.timer = null;
  //   }
  // },
};
</script>