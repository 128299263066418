<template>
  <div>
    <div v-if="dati">
      <h6
        class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
      >
        Utenze
      </h6>
      <p class="text-left text-medium-grey fs-5">
        Utenze registrate in piattaforma.
      </p>
      <div
        class="d-flex justify-content-between align-items-center"
        key="cittadini"
      >
        <div class="col-3 text-left">
          <strong>Cittadini: {{ dati.utenti.cittadini }}</strong>
        </div>
        <div class="col-9">
          <b-progress
            :max="dati.utenti.total"
            :value="dati.utenti.cittadini"
            variant="info"
            show-value
          ></b-progress>
        </div>
      </div>
      <div
        class="d-flex justify-content-between align-items-center"
        key="operatori"
      >
        <div class="col-3 text-left">
          <strong>Operatori: {{ dati.utenti.operatori }}</strong>
        </div>
        <div class="col-9">
          <b-progress
            :max="dati.utenti.total"
            :value="dati.utenti.operatori"
            variant="prim-grad-1"
            show-value
          >
          </b-progress>
        </div>
      </div>
      <div
        class="d-flex justify-content-between align-items-center"
        key="totali"
      >
        <div class="col-3 text-left">
          <strong>Totale: {{ dati.utenti.total }}</strong>
        </div>
        <div class="col-9">
          <b-progress
            :max="dati.utenti.total"
            :value="dati.utenti.total"
            variant="prim-grad-2"
            show-value
          ></b-progress>
        </div>
      </div>
      <h6
        class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey mt-4"
      >
        Aziende
      </h6>
      <p class="text-left text-medium-grey fs-5">
        Aziende registrate in piattaforma suddivise per settore.
      </p>
      <div
        class="d-flex justify-content-between align-items-center"
        :key="ind"
        v-for="(azienda, ind) in dati.aziende"
      >
        <div class="col-3 text-left">
          <strong>{{ azienda.tipo }}: </strong>
        </div>
        <div class="col-9">
          <b-progress
            :value="azienda.totale"
            :max="dati.aziende[dati.aziende.length - 1].totale"
            :variant="colors[ind]"
            show-value
          ></b-progress>
        </div>
      </div>
      <h6
        class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey mt-4"
      >
        Utilizzo
      </h6>
      <p class="text-left text-medium-grey fs-5">Dati utilizzo piattaforma.</p>
      <p class="text-left text-font-grey fs-5">
        Ordini in piattaforma suddivisi per tipologia:
      </p>
      <div
        class="d-flex justify-content-between align-items-center"
        :key="'d' + ind"
        v-for="(ordine, ind) in dati.ordini"
      >
        <div class="col-3 text-left">
          <strong>{{ ordine.tipo }}: </strong>
        </div>
        <div class="col-9">
          <b-progress
            :value="ordine.totale"
            :max="dati.ordini[dati.ordini.length - 1].totale"
            :variant="ind == 3 ? 'primary' : colors[ind]"
            show-value
          ></b-progress>
        </div>
      </div>
      <h6
        class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey mt-4"
      >
        SMS & OTP
      </h6>
      <p class="text-left text-medium-grey fs-5">
        Sms inviati in piattaforma per inoltro OTP e per recupero credenziali di
        accesso.
      </p>
      <template v-if="dati.otp">
        <p class="text-left mb-0">
          Totale OTP inviati ad oggi: <strong>{{ dati.otp.totale }} </strong> di
          cui
          <strong>{{ dati.otp.mese_corrente }} </strong>
          inviati questo mese.
        </p>
      </template>
      <template v-if="dati.sms">
        <p class="text-left mb-0">
          Totale SMS di recupero credenziali inviati ad oggi:
          <strong>{{ dati.sms.totale }} </strong> di cui
          <strong>{{ dati.sms.mese_corrente }} </strong>
          inviati questo mese.
        </p>
      </template>
    </div>
  </div>
</template>
<script>
import { adminService } from "@/_services";
export default {
  name: "DatiApp",
  data() {
    return {
      dati: null,
      colors: ["info", "prim-grad-1", "prim-grad-2", "success"],
    };
  },
  methods: {
    fetchData() {
      var self = this;
      adminService
        .appData()
        .then(function (resp) {
          self.dati = resp.data.data;
        })
        .catch(function (err) {
          console.log(err);
        });
    },
  },
  created() {
    this.fetchData();
  },
};
</script>
