<template>
  <div>
    <b-row align-v="end">
      <b-col class="px-0 align-self-center">
        <div
          class="d-flex align-items-center point"
          @click="$refs['modal-newprestazione'].show()"
        >
          <span class="ml-2">
            <b-iconstack font-scale="2.5">
              <b-icon stacked icon="circle-fill" variant="primary"></b-icon>
              <b-icon stacked icon="plus" scale="0.8" variant="white"></b-icon>
              <b-icon stacked icon="circle" variant="primary"></b-icon>
            </b-iconstack>
          </span>
          <span class="text-primary ml-2 fw-bolder"
            >Crea Nuova Prestazione</span
          >
        </div>
      </b-col>
      <!-- <b-col class="text-left">
        <b-form-group>
          <label for="activeOptions" class="fw-bolder">Stato attività</label>
          <b-form-select
            class="shadow_6 border border-info text-info"
            name="activeOptions"
            v-model="filters.active"
            :options="activeOptions"
          >
          </b-form-select>
        </b-form-group>
      </b-col> -->
      <b-col class="text-left">
        <b-form-group>
          <label for="namesearch" class="fw-bolder"
            >Cerca <b-icon icon="search"></b-icon
          ></label>
          <b-form-input
            class="shadow_6 border border-info text-info"
            name="namesearch"
            placeholder="..."
            v-model="filters.name"
            type="search"
            size="sm"
          >
          </b-form-input>
        </b-form-group>
      </b-col>
      <b-col class="text-left">
        <b-form-group>
          <label for="orderoption" class="fw-bolder">Ordinamento</label>
          <b-form-select
            class="shadow_6 border border-info text-info"
            name="orderoption"
            v-model="filters.indesc"
            :options="orderOptions"
            size="sm"
          >
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col class="text-left">
        <b-form-group>
          <label for="takeoption" class="fw-bolder">N°elementi mostrati</label>
          <b-form-select
            class="shadow_6 border border-info text-info"
            name="takeoption"
            v-model="filters.take"
            :options="takeOptions"
            size="sm"
          >
          </b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
    <!-- <div class="d-flex flex-wrap">
      <a
        v-for="(hsh, name) in company.prestazioni_attive"
        :key="hsh"
        :href="'#' + name"
        class="point m-1 px-1 py-2 no_underlined"
      >
        <span
          class="
            border
            rounded-pill
            px-2
            py-1
            border-secondary
            text-secondary text-capitalize
            fw-bolder
          "
          >{{ name }}</span
        >
      </a>
    </div> -->
    <!-- <div class="my-3 text-left">
      <div
        class="d-flex align-items-center point"
        @click="$refs['modal-newprestazione'].show()"
      >
        <span class="ml-2">
          <b-iconstack font-scale="2.5">
            <b-icon stacked icon="circle-fill" variant="primary"></b-icon>
            <b-icon stacked icon="plus" scale="0.8" variant="white"></b-icon>
            <b-icon stacked icon="circle" variant="primary"></b-icon>
          </b-iconstack>
        </span>
        <span class="text-primary ml-2 fw-bolder">Crea Nuova Prestazione</span>
      </div>
    </div> -->
    <div v-if="company" class="my-2">
      <transition-group name="list" mode="out-in" v-if="prestazioni">
        <!-- v-for="prestazione in company.prestazioni_sanitarie" -->
        <div
          v-for="prestazione in prestazioni"
          :key="prestazione.hashid"
          class="panel__box__shadow bg-light-panel border border-light-panel general_border my-3 p-2"
        >
          <h6
            :id="prestazione.name"
            class="text-uppercase text-left text-secondary fw-bolder mt-2"
          >
            {{ prestazione.name }}
            <span
              v-if="!prestazione.fds && !prestazione.htn"
              class="ml-1 point text-lowercase"
              @click="openEdit(prestazione)"
              ><u>modifica</u></span
            >

            <b-badge
              v-else-if="prestazione.fds"
              variant="prim-grad-1"
              class="ml-4 font-weight-bolder p-2"
              >farmacia dei servizi</b-badge
            >
            <b-badge
              v-else
              variant="prim-grad-1"
              class="ml-4 font-weight-bolder p-2"
              >RETE HTN
            </b-badge>
            <!-- <span v-else class="ml-4 point text-sec-2"
              >farmacia dei servizi</span
            > -->
          </h6>
          <p class="text-medium-grey text-left mb-0">
            {{ prestazione.description }}
          </p>
          <p class="text-medium-grey text-left mb-0">
            {{
              prestazione.private
                ? "Prestazione privata"
                : "Prestazione aperta al pubblico"
            }}
          </p>
          <b-row cols="2" class="justify-content-between">
            <b-col cols="12" class="pl-0">
              <div class="text-left">
                <span class="text-secondary fw-bolder"
                  >Icona <b-icon :icon="prestazione.icona"></b-icon>
                </span>
              </div>
            </b-col>
            <b-col cols="12" class="pl-0">
              <div class="text-left">
                <span class="text-secondary fw-bolder"
                  >Etichetta
                  <span class="text-uppercase text-info">
                    {{ prestazione.tag ? prestazione.tag : "-" }}</span
                  >
                </span>
              </div>
            </b-col>
            <b-col :cols="$root.isMobile ? '4' : '3'" class="pl-0">
              <div class="text-left">
                <span class="text-secondary fw-bolder">Stato servizio </span>
                <b-icon
                  :icon="
                    prestazione.active == 1
                      ? 'check-circle-fill'
                      : 'circle-fill'
                  "
                  :variant="prestazione.active == 1 ? 'success' : 'danger'"
                ></b-icon>
              </div>
            </b-col>
            <b-col :cols="$root.isMobile ? '8' : '7'" class="text-right">
              <b-button
                v-if="!prestazione.fds && !prestazione.htn"
                :disabled="prestazione.active == 0"
                class="mb-1 text-white rounded-pill fw-bolder mx-1"
                variant="sec-2"
                size="sm"
                @click="emitCollapse('allegati-' + prestazione.hashid)"
                >Allegati
                <b-icon
                  :ref="'icon-collapse-allegati-' + prestazione.hashid"
                  icon="arrow-down-circle"
                ></b-icon>
              </b-button>
              <b-button
                :disabled="prestazione.active == 0"
                class="mb-1 text-white rounded-pill fw-bolder mx-1"
                variant="sec-2"
                size="sm"
                @click="emitCollapse('survey-' + prestazione.hashid)"
                >Questionario
                <b-icon
                  :ref="'icon-collapse-survey-' + prestazione.hashid"
                  icon="arrow-down-circle"
                ></b-icon>
              </b-button>
              <b-button
                :disabled="prestazione.active == 0"
                class="mb-1 text-white rounded-pill fw-bolder"
                variant="sec-2"
                size="sm"
                v-b-toggle="'collapse-' + prestazione.hashid"
                >Gestisci Orari
                <b-icon
                  :ref="'icon-collapse-' + prestazione.hashid"
                  icon="arrow-down-circle"
                ></b-icon>
              </b-button>
            </b-col>
            <b-col cols="12" class="pl-0">
              <!-- <b-col :cols="$root.isMobile ? '6' : '5'"> -->
              <template v-if="!prestazione.fds">
                <ServiceStatus
                  :statoservizio="prestazione.active == true ? 1 : 0"
                  :servicename="prestazione.name"
                  v-on:store-service-status="
                    submitStatusPrestazioneChange($event, prestazione.hashid)
                  "
                />
              </template>
            </b-col>
            <b-col
              cols="12"
              v-if="prestazione.active && hasFacilePiu"
              class="pl-0"
            >
              <template v-if="!prestazione.fds && !prestazione.htn">
                <ServicePrivacy
                  :statoservizio="prestazione.private == true ? 1 : 0"
                  :servicename="prestazione.name"
                  v-on:store-service-status="
                    submitStatusPrivacyChange($event, prestazione.hashid)
                  "
                />
              </template>
            </b-col>
          </b-row>
          <b-row cols="1">
            <b-collapse :id="'collapse-allegati-' + prestazione.hashid">
              <div
                v-if="prestazione.active === 1"
                :key="'active-allegati' + prestazione.hashid"
              >
                <AllegatiPrestazioni
                  :ref="'allegati-' + prestazione.hashid"
                  :hash="prestazione.hashid"
                />
              </div>
              <p v-else class="fw-bolder">Prestazione disattivata.</p>
            </b-collapse>
            <b-collapse :id="'collapse-survey-' + prestazione.hashid">
              <div
                v-if="prestazione.active === 1"
                :key="'active-survey' + prestazione.hashid"
              >
                <QuestionarioPrestazione
                  :ref="'survey-' + prestazione.hashid"
                  :prestazione="prestazione.hashid"
                  :isfds="prestazione.fds === 1"
                  :ishtn="prestazione.htn === true || prestazione.htn === 1"
                />
              </div>
              <p v-else class="fw-bolder">Prestazione disattivata.</p>
            </b-collapse>
            <b-collapse :id="'collapse-' + prestazione.hashid">
              <div
                v-if="prestazione.active === 1"
                :key="'active' + prestazione.hashid"
              >
                <SettingServiceOrari
                  v-if="prestazione.openings"
                  :orari="prestazione.openings"
                  :servicename="prestazione.name"
                  v-on:store-service-openings="
                    submitPrestazioneOpenings($event, prestazione.hashid)
                  "
                  v-on:force-update="fetchData"
                />
              </div>
              <p v-else class="fw-bolder">Prestazione disattivata.</p>
            </b-collapse>
          </b-row>
        </div>
      </transition-group>
      <b-spinner v-else></b-spinner>

      <b-pagination
        v-model="filters.page"
        pills
        :total-rows="total"
        :per-page="perPage"
        align="center"
        :size="$root.isMobile ? 'sm' : 'lg'"
      ></b-pagination>
    </div>
    <b-modal
      size="lg"
      id="edit-prestazione"
      title="Modifica"
      centered
      hide-footer
      @hide="fetchData()"
    >
      <div v-if="editPrestazione">
        <b-form @submit.prevent="saveEditPrestazione" name="editServiceH">
          <b-form-group
            label="Nome"
            label-for="nome"
            class="text-left fs-6 text-font-grey mx-auto col"
          >
            <b-form-input
              class="my-3 shadow_6"
              name="nome"
              type="text"
              placeholder="nome prestazione..."
              v-model="editPrestazione.name"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label="Descrizione"
            label-for="descrizione"
            class="text-left fs-6 text-font-grey mx-auto col"
          >
            <b-form-input
              class="my-3 shadow_6"
              name="descrizione"
              type="text"
              placeholder="descrizione..."
              v-model="editPrestazione.description"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            label="Conferma automatica"
            label-for="default_status"
            class="text-left fs-6 text-font-grey mx-auto col pt-2"
          >
            <b-form-checkbox
              id="default_status"
              name="default_status"
              :value="3"
              :unchecked-value="1"
              v-model="editPrestazione.default_status"
            >
              {{
                editPrestazione.default_status == 3
                  ? "richieste confermate automaticamente"
                  : "le nuove richieste andranno manualmente confermate"
              }}
            </b-form-checkbox>
            <p class="my-1 text-font-grey">
              Nota: tutte le richieste possono sempre e comunque essere
              annullate dall'operatore.
            </p>
          </b-form-group>
          <div class="text-left col-6 col-lg-4">
            <b-form-group
              label="Etichetta"
              label-for="tag"
              class="text-left fs-6 text-font-grey mx-auto"
            >
              <b-form-input
                class="my-3 shadow_6"
                name="tag"
                type="text"
                placeholder="etichetta..."
                maxlength="3"
                minlength="1"
                :state="
                  editPrestazione.tag == null || editPrestazione.tag.length == 0
                    ? null
                    : editPrestazione.tag.length > 1
                "
                v-model="editPrestazione.tag"
              >
              </b-form-input>
            </b-form-group>
          </div>
          <IconSelection
            :preselected="editPrestazione.icon"
            v-on:selected-icon="handleIconUpdate($event, editPrestazione)"
          />
          <div class="text-center mt-3 pt-1">
            <b-button
              type="button"
              variant="outline-danger mx-2"
              @click="$bvModal.hide('edit-prestazione')"
              >Annulla
            </b-button>
            <b-button
              variant="primary text-white primary_gradient mx-2"
              type="submit"
              >Salva modifiche
            </b-button>
          </div>
        </b-form>
      </div>
    </b-modal>
    <b-modal
      size="lg"
      ref="modal-newprestazione"
      title="Crea nuova prestazione"
      centered
      hide-footer
    >
      <!-- <div class="col-12 d-flex"> -->
      <b-form-group
        label="Nome"
        label-for="nome"
        class="text-left fs-6 text-font-grey mx-auto col"
      >
        <b-form-input
          class="my-3 shadow_6"
          name="nome"
          type="text"
          placeholder="nome prestazione..."
          v-model="nuovaPrestazione.name"
          :state="nuovaPrestazione.name == null ? null : validPrestName"
          required
        >
        </b-form-input>
      </b-form-group>
      <b-form-group
        label="Descrizione"
        label-for="descrizione"
        class="text-left fs-6 text-font-grey mx-auto col"
      >
        <b-form-input
          class="my-3 shadow_6"
          name="descrizione"
          type="text"
          placeholder="descrizione..."
          v-model="nuovaPrestazione.description"
        ></b-form-input>
      </b-form-group>
      <div class="text-left col-6 col-lg-4">
        <b-form-group
          label="Etichetta"
          label-for="tag"
          class="text-left fs-6 text-font-grey mx-auto"
        >
          <b-form-input
            class="my-3 shadow_6"
            name="tag"
            type="text"
            placeholder="etichetta..."
            maxlength="3"
            minlength="1"
            :state="
              nuovaPrestazione.tag == null
                ? null
                : nuovaPrestazione.tag.length > 1
            "
            v-model="nuovaPrestazione.tag"
          >
          </b-form-input>
        </b-form-group>
      </div>
      <IconSelection
        :preselected="2"
        v-on:selected-icon="handleIconUpdate($event)"
      />
      <b-form-group
        v-if="hasFacilePiu"
        label="Apertura al pubblico"
        label-for="privacy"
        class="text-left fs-6 text-font-grey mx-auto col pt-2"
      >
        <b-form-checkbox
          id="privacy"
          name="privacy"
          :value="false"
          :unchecked-value="true"
          v-model="nuovaPrestazione.private"
        >
          {{
            nuovaPrestazione.private
              ? "la prestazione è prenotabile soltanto da te o da un tuo operatore"
              : "tutti gli utenti in piattaforma possono prenotare la prestazione"
          }}
        </b-form-checkbox>
      </b-form-group>
      <b-form-group
        label="Conferma automatica"
        label-for="default_status"
        class="text-left fs-6 text-font-grey mx-auto col pt-2"
      >
        <b-form-checkbox
          id="default_status"
          name="default_status"
          :value="3"
          :unchecked-value="1"
          v-model="nuovaPrestazione.default_status"
        >
          {{
            nuovaPrestazione.default_status == 3
              ? "richieste confermate automaticamente"
              : "le nuove richieste andranno manualmente confermate"
          }}
        </b-form-checkbox>
        <p class="my-1 text-font-grey">
          Nota: tutte le richieste possono sempre e comunque essere annullate
          dall'operatore.
        </p>
      </b-form-group>
      <!-- </div> -->
      <div class="text-center mt-3 pt-1">
        <b-button
          type="button"
          variant="outline-danger mx-2"
          @click="$refs['modal-newprestazione'].hide()"
          >Annulla
        </b-button>
        <b-button
          variant="primary text-white primary_gradient mx-2"
          type="button"
          @click="saveNewPrestazione"
          >Conferma
        </b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { companyService } from "@/_services";
import SettingServiceOrari from "@/components/impostazioni/SettingServiceOrari.vue";
import ServiceStatus from "@/components/impostazioni/ServiceStatus.vue";
import ServicePrivacy from "@/components/impostazioni/ServicePrivacy.vue";
import AllegatiPrestazioni from "@/components/impostazioni/AllegatiPrestazioni.vue";
import QuestionarioPrestazione from "@/components/impostazioni/QuestionarioPrestazione.vue";
import IconSelection from "@/components/impostazioni/IconSelection.vue";
export default {
  name: "PrestazioniSanitarie",
  props: ["servicename", "statoservizio"],
  components: {
    SettingServiceOrari,
    ServiceStatus,
    AllegatiPrestazioni,
    QuestionarioPrestazione,
    ServicePrivacy,
    IconSelection,
  },
  computed: {
    validPrestName: function () {
      var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
      if (this.nuovaPrestazione.name !== null) {
        return format.test(this.nuovaPrestazione.name) ? false : true;
      }
      return false;
    },
    ...mapState({
      alert: (state) => state.alert,
    }),
    ...mapState("company", ["companyLoggedIn", "company"]),
  },
  data() {
    return {
      nuovaPrestazione: {
        name: null,
        description: null,
        active: true,
        private: false,
        has_limitations: false,
        default_status: 3,
        icon: 2,
        tag: null,
        plan_type: 0,
      },
      hasFacilePiu: true,
      icons: {
        2: "file-ppt",
        3: "chat-text",
        4: "screwdriver",
        5: "droplet-fill",
        6: "flower1",
        7: "emoji-smile",
        8: "exclude",
        9: "egg-fried",
        10: "gem",
        11: "heart-fill",
        12: "hourglass-split",
        13: "journal-medical",
        14: "life-preserver",
        15: "lock-fill",
        16: "moon",
        17: "node-minus-fill",
        18: "x-diamond-fill",
        19: "cart-fill",
        20: "bug-fill",
        21: "brightness-high-fill",
        22: "bell-fill",
      },
      editPrestazione: null,
      filters: {
        page: 1,
        take: 5,
        indesc: true,
        active: null,
        name: undefined,
      },
      total: 0,
      fromPage: 0,
      toPage: 0,
      perPage: 0,
      takeOptions: [5, 10, 25, 50, 100],
      orderOptions: [
        { value: true, text: "Più recenti" },
        { value: false, text: "Meno recenti" },
      ],
      activeOptions: [
        { value: true, text: "Attive" },
        { value: false, text: "Non attive" },
        { value: null, text: "Tutte" },
      ],
      prestazioni: null,
    };
  },
  methods: {
    ...mapActions("company", [
      "updateAnagrafica",
      "storePrestazione",
      "storeOrariPrestazione",
      "updatePrestazione",
      "logUserCompanyIn",
    ]),
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    fetchData() {
      this.prestazioni = null;
      var self = this;
      companyService
        .getOwnHealthServices(this.filters)
        .then(function (res) {
          self.prestazioni = res.data.data.data;
          self.filters.page = res.data.data.current_page;
          self.total = res.data.data.total;
          self.fromPage = res.data.data.from;
          self.toPage = res.data.data.to;
          self.perPage = res.data.data.per_page
            ? res.data.data.per_page
            : self.filters.take;
        })
        .catch(function (err) {
          console.log(err);
          self.errorAlert("Non è stato possibile recuperare le prestazioni");
        });
    },
    emitCollapse(id) {
      this.$refs[id][0].doFetch = true;
      this.$root.$emit("bv::toggle::collapse", "collapse-" + id);
    },
    handleIconUpdate(icon, prestazione = null) {
      if (!prestazione) {
        this.nuovaPrestazione.icon = icon;
      } else {
        this.editPrestazione.icon = icon;
      }
    },
    openEdit(pres) {
      this.editPrestazione = pres;
      this.$bvModal.show("edit-prestazione");
    },
    saveEditPrestazione() {
      var newFormData = {
        service_hash: this.editPrestazione.hashid,
        form: {
          icon: this.editPrestazione.icon,
          name: this.editPrestazione.name,
          description: this.editPrestazione.description,
          default_status: this.editPrestazione.default_status,
          tag: this.editPrestazione.tag,
        },
      };
      // //console.log("service status data to submit", newFormData);
      const self = this;
      this.updatePrestazione(newFormData).then(function () {
        self.$bvModal.hide("edit-prestazione");
        window.scrollTo(0, 0);
        self.fetchData();
      });
    },
    saveNewPrestazione() {
      // //console.log("new prestazione");
      const self = this;
      if (this.nuovaPrestazione.name) {
        this.storePrestazione(this.nuovaPrestazione).then(function () {
          window.scrollTo(0, 0);
          self.$refs["modal-newprestazione"].hide();
          self.nuovaPrestazione = {
            name: null,
            description: null,
            active: true,
            private: false,
            has_limitations: false,
            default_status: 3,
            icon: 2,
            tag: null,
            plan_type: 0,
          };

          self.fetchData();
        });
      }
    },
    submitPrestazioneOpenings(dataToSubmit, serviceHash) {
      const self = this;
      // var serviceHash =
      //   this.company.prestazioni_attive[dataToSubmit.service_name];
      dataToSubmit.service_hash = serviceHash;
      this.storeOrariPrestazione(dataToSubmit).then(function () {
        window.scrollTo(0, 0);
        self.fetchData();
        // self.logUserCompanyIn();
      });
    },
    submitStatusPrestazioneChange(dataToSubmit, serviceHash) {
      var newFormData = {
        service_hash: serviceHash,
        form: {
          active: dataToSubmit.form.status,
          name: dataToSubmit.service_name,
        },
      };
      // //console.log("service status data to submit", newFormData);
      var self = this;
      this.updatePrestazione(newFormData).then(function () {
        window.scrollTo(0, 0);
        self.fetchData();
      });
    },
    submitStatusPrivacyChange(dataToSubmit, serviceHash) {
      // this.company.prestazioni_attive[dataToSubmit.service_name],
      var newFormData = {
        service_hash: serviceHash,
        form: {
          private: dataToSubmit.form.private,
          name: dataToSubmit.service_name,
        },
      };
      // //console.log("service status data to submit", newFormData);
      var self = this;
      this.updatePrestazione(newFormData).then(function () {
        window.scrollTo(0, 0);
        self.fetchData();
      });
    },
    scrollUp() {
      window.scrollTo(0, 0);
    },
  },
  watch: {
    filters: {
      handler(prev, post) {
        this.fetchData();
      },
      deep: true,
    },
  },
  created() {
    this.hasFacilePiu = this.company.stato_servizi["facile+"] !== 0;
    // //console.log(this.company.stato_servizi["facile+"]);
    this.fetchData();
  },
  mounted() {
    var self = this;
    this.$root.$on("bv::collapse::state", function (collapseId, isJustShown) {
      // //console.log("collapseId:", collapseId);
      // //console.log("isJustShown:", isJustShown);
      if (
        self.$refs["icon-" + collapseId] &&
        self.$refs["icon-" + collapseId][0]
      ) {
        self.$refs["icon-" + collapseId][0].style.transform = isJustShown
          ? "rotate(180deg)"
          : "none";
      }
      if (
        self.$refs["icon-allegati-" + collapseId] &&
        self.$refs["icon-allegati-" + collapseId][0]
      ) {
        self.$refs[
          "icon-allegati-" + collapseId
        ][0].style.transform = isJustShown ? "rotate(180deg)" : "none";
      }
      if (
        self.$refs["icon-survey-" + collapseId] &&
        self.$refs["icon-survey-" + collapseId][0]
      ) {
        self.$refs["icon-survey-" + collapseId][0].style.transform = isJustShown
          ? "rotate(180deg)"
          : "none";
      }
    });
  },
};
</script>
<style>
.panel__box__shadow {
  box-shadow: 2px 1px 4px #3867d652;
}
</style>
