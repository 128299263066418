<template>
  <b-container class="mb-4">
    <b-form @submit.prevent="saveTherapyUpdates">
      <b-row cols="1" class="my-3">
        <h5
          class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
        >
          Farmaco indicato
        </h5>
        <div class="text-left mb-4">
          <b-row
            cols="1"
            class="panel__box__shadow bg-light-panel border border-light-panel general_border py-4 px-2 mx-0"
          >
            <b-col>
              <b-form-group
                label="Cerca farmaco"
                class="font-weight-bolder col-12 col-md-10 pl-0"
              >
                <b-form-input
                  class="shadow_6"
                  type="search"
                  name="pharmasearch"
                  placeholder="Cerca farmaco per nome.."
                  v-model="prodSearch.q"
                  v-debounce:500ms="searchProds"
                  :debounce-events="'keyup'"
                >
                </b-form-input>
              </b-form-group>
              <b-spinner
                v-show="isSearching"
                class="ml-4"
                variant="primary"
                label="Loading..."
              ></b-spinner>
            </b-col>

            <b-col v-if="products">
              <b-form-group
                label="Seleziona farmaco"
                class="font-weight-bolder col-12 col-md-10 pl-0"
              >
                <b-form-select v-model="selectedProduct" class="">
                  <b-form-select-option
                    v-for="(prod, ind) in products"
                    :key="ind"
                    :value="prod"
                    >{{ prod.Nome }}
                  </b-form-select-option>
                  <b-form-select-option :value="null" disabled
                    >---</b-form-select-option
                  >
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col v-if="showNotFound">
              <p class="text-danger font-weight-bolder">Prodotto non trovato</p>
            </b-col>
          </b-row>
        </div>
      </b-row>
      <b-row cols="1" class="my-3" v-if="selectedProduct">
        <h5
          class="text-prim-grad-1 fw-bolder text-left pb-1 mb-0 border-bottom border-line-grey"
        >
          Frequenza, modalità e dosaggio somministrazione
        </h5>
        <div class="text-left">
          <div class="mt-1 border border-prim-grad-1">
            <h6
              class="text-white font-weight-bolder text-uppercase px-1 pb-2 pt-4 bg-prim-grad-1"
            >
              {{ selectedProduct.Nome }}
            </h6>
            <b-row class="align-items-end px-1 my-2">
              <b-col class="col-6 col-md-2 align-items-end ml-2">
                <b-form-group class="font-weight-bolder" label="Dosaggio">
                  <b-form-input
                    class="shadow_6"
                    type="number"
                    step="0.25"
                    min="0.25"
                    v-model="form.posology_num"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col class="ml-1">
                <b-form-radio-group
                  v-model="form.posology_type"
                  name="posology-type-options"
                  class="font-weight-bolder"
                >
                  <b-form-radio
                    v-for="type in posologyTypes"
                    :key="type"
                    :value="type"
                    >{{ type }}</b-form-radio
                  >
                </b-form-radio-group>
              </b-col>
            </b-row>
            <b-row class="align-items-end px-1 my-2">
              <b-col class="ml-2 col-12 col-md-3 col-lg-2">
                <b-form-group class="font-weight-bolder" label="Frequenza">
                  <b-form-radio-group
                    v-model="form.posology_every_type"
                    name="posology-every-type-options"
                    class="font-weight-bolder"
                  >
                    <b-form-radio :value="0">al dì</b-form-radio>
                    <b-form-radio :value="2">al bisogno</b-form-radio>
                    <b-form-radio :value="1">ogni</b-form-radio>
                  </b-form-radio-group>
                </b-form-group>
              </b-col>
              <b-col
                v-if="form.posology_every_type === 1"
                class="ml-0 col-11 col-md-7 d-flex align-items-center"
              >
                <b-form-group class="font-weight-bolder my-auto ml-0">
                  <b-input-group class="align-items-center">
                    <b-form-input
                      class="shadow_6"
                      type="number"
                      step="1"
                      min="1"
                      max="36"
                      v-model="form.posology_every_num"
                    >
                    </b-form-input>
                    <!-- <span class="ml-3">Ore</span>
                  <b-input-group-append></b-input-group-append> -->
                  </b-input-group>
                </b-form-group>
                <b-form-group class="font-weight-bolder my-auto ml-4" label="">
                  <b-form-radio-group
                    v-model="form.posology_every_um"
                    name="posology-every-um-options"
                    class="font-weight-bolder"
                  >
                    <b-form-radio :value="1">ore</b-form-radio>
                    <b-form-radio :value="0">giorni</b-form-radio>
                  </b-form-radio-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="px-1 my-2">
              <b-col class="ml-2">
                <b-form-group class="font-weight-bolder" label="Per via">
                  <b-form-radio-group
                    v-model="form.posology_via"
                    name="posology-via-options"
                    class="font-weight-bolder"
                  >
                    <b-form-radio
                      v-for="via in posologyVias"
                      :key="via"
                      :value="via"
                      >{{ via }}</b-form-radio
                    >
                  </b-form-radio-group>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-row>

      <b-row cols="1" class="my-3 pt-2">
        <h5
          class="text-prim-grad-1 fw-bolder text-left pb-1 mb-0 border-bottom border-line-grey"
        >
          Durata della terapia
        </h5>
        <div class="text-left">
          <b-form-radio-group
            v-model="form.type"
            name="end-options"
            class="d-flex my-3 font-weight-bolder"
          >
            <b-form-radio :value="0">per sempre</b-form-radio>
            <b-form-radio :value="1">periodica</b-form-radio>
            <b-form-radio :value="2">occasionale</b-form-radio>
          </b-form-radio-group>
          <b-row
            v-if="form.type === 1"
            cols="2"
            class="my-3 col-12 px-0 align-items-center"
          >
            <b-col class="col-md-6 col-12">
              <b-row cols="2" class="align-items-end">
                <b-col class="col-md-5 col-12">
                  <b-form-group label="Per " class="font-weight-bolder">
                    <b-form-input
                      class="shadow_6"
                      type="number"
                      step="1"
                      min="1"
                      max="60"
                      v-model="form.for_num"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col class="ml-2 col-md-6 col-12">
                  <b-form-radio-group
                    v-model="form.for_um"
                    name="for-ums-options"
                    class="my-3 font-weight-bolder"
                  >
                    <b-form-radio :value="0">mesi</b-form-radio>
                    <b-form-radio :value="1">settimane</b-form-radio>
                    <b-form-radio :value="2">giorni</b-form-radio>
                  </b-form-radio-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col class="col-md-6 col-12">
              <b-row cols="2" class="align-items-end">
                <b-col class="col-md-5 col-12">
                  <b-form-group label="Ogni " class="font-weight-bolder">
                    <b-form-input
                      class="shadow_6"
                      type="number"
                      step="1"
                      min="1"
                      max="60"
                      v-model="form.every_num"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col class="ml-2 col-md-6 col-12">
                  <b-form-radio-group
                    v-model="form.every_um"
                    name="every-ums-options"
                    class="my-3 font-weight-bolder"
                  >
                    <b-form-radio :value="0">mesi</b-form-radio>
                    <b-form-radio :value="1">settimane</b-form-radio>
                    <b-form-radio :value="2">giorni</b-form-radio>
                  </b-form-radio-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row cols="1" cols-md="2" class="text-left mt-3">
            <b-col>
              <b-form-group
                :label="form.type >= 1 ? 'A partire dal*' : 'Iniziata il'"
                label-for="start_at"
                class="text-left fs-6 text-secondary mx-auto"
              >
                <b-form-input
                  class="my-3 shadow_6"
                  name="start_at"
                  type="date"
                  v-model="form.start_at"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col v-if="form.type > 1">
              <b-form-group
                label="Fino al*"
                label-for="end_at"
                class="text-left fs-6 text-secondary mx-auto"
              >
                <b-form-input
                  class="my-3 shadow_6"
                  name="end_at"
                  type="date"
                  v-model="form.end_at"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </b-row>
      <div v-if="selectedProduct">
        <b-row cols="1" class="my-3 pt-2">
          <b-col class="px-0" v-if="form.own && user.type === 0">
            <b-form-group
              label=""
              description="**se selezioni questa opzione soltanto tu potrai vedere questa terapia"
              label-for="private"
              class="text-left font-weight-bolder text-secondary"
            >
              <b-form-checkbox
                id="private"
                v-model="form.private"
                name="private"
                :value="1"
                :unchecked-value="0"
              >
                visibile solo a me**
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row cols="1">
          <h5
            class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
          >
            Dettagli
          </h5>
          <b-col class="px-0">
            <b-row cols="2">
              <b-col class="pl-0">
                <b-form-group
                  label="Prescrittore terapia*"
                  label-for="prescrittore"
                  class="text-left fs-6 text-secondary mx-auto"
                >
                  <b-form-select
                    class="my-3 shadow_6"
                    name="prescrittore"
                    type="date"
                    v-model="form.prescrittore"
                    required
                  >
                    <b-form-select-option
                      v-for="ikey in irstKeys.prescr"
                      :key="ikey.descrizione"
                      :value="ikey.descrizione"
                      >{{ ikey.descrizione }}</b-form-select-option
                    >
                    <b-form-select-option :value="null"
                      >indica chi ha prescritto la terapia</b-form-select-option
                    >
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col class="px-0">
                <b-form-group
                  v-if="user.type === 1"
                  label="Fonte informativa*"
                  label-for="fonte"
                  class="text-left fs-6 text-secondary mx-auto"
                >
                  <b-form-select
                    class="my-3 shadow_6"
                    name="fonte"
                    type="date"
                    v-model="form.fonte_informativa"
                    required
                  >
                    <b-form-select-option
                      v-for="ikey in irstKeys.fonte"
                      :key="ikey.descrizione"
                      :value="ikey.descrizione"
                      >{{ ikey.descrizione }}</b-form-select-option
                    >
                    <b-form-select-option :value="null"
                      >indica fonte informativa</b-form-select-option
                    >
                  </b-form-select>
                </b-form-group>
              </b-col>

              <b-col class="pl-0">
                <b-form-group
                  label="Effetti collaterali"
                  label-for="effetti_collaterali"
                  class="text-left fs-6 text-secondary mx-auto"
                >
                  <b-form-textarea
                    class="my-3 shadow_6"
                    name="effetti_collaterali"
                    type="text"
                    placeholder="eventuali effetti collaterali attribuite al farmaco.."
                    v-model="form.effetti_collaterali"
                  >
                  </b-form-textarea>
                </b-form-group>
              </b-col>
              <b-col class="px-0">
                <b-form-group
                  label="Reazioni allergiche"
                  label-for="reazione_allergica"
                  class="text-left fs-6 text-secondary mx-auto"
                >
                  <b-form-textarea
                    class="my-3 shadow_6"
                    name="reazione_allergica"
                    type="text"
                    placeholder="eventuali reazioni allergiche attribuite al farmaco.."
                    v-model="form.reazione_allergica"
                  >
                  </b-form-textarea>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row cols="1" class="my-3 pt-2">
          <b-col class="px-0">
            <b-form-group
              label="Interazioni"
              label-for="interazioni"
              class="text-left font-weight-bolder text-secondary"
            >
              <b-form-textarea
                class="my-3 shadow_6"
                name="interazioni"
                type="text"
                placeholder="possibili interazioni con farmaci o alimenti individuate.."
                v-model="form.interazioni"
              >
              </b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row cols="1" class="my-3 pt-2">
          <b-col class="px-0">
            <h5
              class="text-prim-grad-1 fw-bolder text-left pb-1 mb-0 border-bottom border-line-grey"
            >
              Annotazioni
            </h5>
            <b-form-group
              label-for="details"
              class="text-left font-weight-bolder text-secondary"
            >
              <b-form-textarea
                class="my-3 shadow_6"
                name="details"
                type="text"
                v-model="form.details"
              >
              </b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row cols="1" class="my-3">
          <b-col class="text-center m-1">
            <b-button
              type="button"
              @click="$emit('cancel-operation')"
              variant="outline-danger font-weight-bolder"
              class="m-1"
              >Annulla</b-button
            >
            <b-button
              type="submit"
              variant="primary primary_gradient font-weight-bolder"
              class="m-1"
              >Salva modifiche
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-form>
  </b-container>
</template>
<script>
import * as moment from "moment/moment";
import { utilityService, cittadinoService } from "@/_services";
import PatologieComponent from "@/components/profile/sanitario/PatologieComponent.vue";
import { mapState, mapActions } from "vuex";
export default {
  name: "EditTherapy",
  props: ["therapy"],
  components: { PatologieComponent },
  data() {
    return {
      form: {
        type: 0,
        start_at: null,
        end_at: null,
        details: null,
        own: true,
        private: false,
        subjectable_id: null,
        subjectable_type: null,
        chronic: true,
        pathology_id: null,
        every_num: 1,
        every_um: 0,
        for_num: 1,
        for_um: 0,
        posology_num: 1,
        posology_type: "Compresse",
        posology_via: "Orale",
        posology_every_num: 1,
        posology_every_um: 0,
        posology_every_type: 0,
        product_name: null,
        product_aic: null,
        prescrittore: null,
        fonte_informativa: null,
        reazione_allergica: null,
        effetti_collaterali: null,
        interazioni: null,
      },
      hasPathology: 0,
      everyUms: {
        0: "mesi",
        1: "settimane",
        2: "giorni",
      },
      everyPosologyTypes: {
        0: "dì",
        1: "ore",
        2: "bisogno",
      },
      everyPosologyUms: {
        1: "ore",
        2: "giorni",
      },
      posologyTypes: [
        "Compresse",
        "Capsule",
        "Gocce",
        "Inalazioni",
        "Buste",
        "Supposte",
        "Scatole",
        "Cerotti",
        "Fiale",
        "Fleboclisi",
        "Ovuli",
        "Sacche",
        "Flaconi",
        "Confezioni",
        "Kilogrammi",
        "Kit",
        "Millilitri",
        "Milligrammi",
        "Applicazioni",
        "UI",
      ],
      posologyVias: [
        "Orale",
        "Transdermica",
        "Nasale",
        "Endovena",
        "Intramuscolo",
        "Oftalmica",
        "Rettale",
        "Uretale",
        "Vaginale",
        "Sottocute",
        "Cutanea",
      ],
      pathologies: null,
      typesColors: ["ter-4", "ter-1", "ter-2"],
      prodSearch: {
        q: null,
      },
      products: null,
      selectedProduct: null,
      isSearching: false,
      showNotFound: false,
      irstKeys: {
        prescr: [],
        fonte: [],
      },
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
    ...mapState("utente", ["user"]),
  },
  watch: {
    prodSearch: {
      handler(val) {
        if (val.q == null || val.q.length < 3) {
          this.products = null;
          this.selectedProduct = null;
        }
      },
      deep: true,
    },
    selectedProduct: {
      handler(val) {
        if (val !== null) {
          this.form.product_name = val.Nome;
          this.form.product_aic = val.Aic;
          this.form.product_principio_attivo = val.PrincipioAttivo;
          this.form.product_codice_principio_attivo = val.CodicePrincipioAttivo;
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    formatData(data) {
      return moment(data).format("DD MMM YYYY");
    },
    selectPathology(pathology) {
      this.form.pathology_id = pathology.hashid;
      this.form.chronic = pathology.type === 0;
      this.form.type = pathology.type !== 0 ? 2 : 0;
    },
    searchProds() {
      if (this.prodSearch.q && this.prodSearch.q.length >= 3) {
        this.isSearching = true;
        var self = this;
        this.showNotFound = false;
        utilityService
          .products(this.prodSearch)
          .then(function (response) {
            self.products = response.data;
            if (!(response.data instanceof Array)) {
              self.products = [response.data];
            }
            self.isSearching = false;
          })
          .catch(function (error) {
            self.showNotFound = true;
            self.isSearching = false;
          });
      } else if (!this.selectedProduct) {
        this.products = null;
      }
    },
    saveTherapyUpdates() {
      var self = this;
      cittadinoService
        .updateTherapy(this.form)
        .then(function (response) {
          self.$emit("save-therapy", response.data.data);
          self.successAlert("Nuova terapia salvata");
        })
        .catch(function (error) {
          console.log(error);
          self.errorAlert("Si è verificato un errore durante il salvataggio");
        });
    },
    getKeys() {
      var filters = {
        key: "prescr,fonte",
      };
      var self = this;
      utilityService
        .getIrstKeys(filters)
        .then(function (res) {
          self.irstKeys = res.data.data;
        })
        .catch(function (err) {
          {
            self.errorAlert("Si è verificato un errore.");
          }
        });
    },
  },
  created() {
    if (this.therapy) {
      this.form = this.therapy;

      this.selectedProduct = {
        Nome: this.therapy.product_name,
        Aic: this.therapy.product_aic,
      };
    }
    this.getKeys();
  },
};
</script>
