<template>
  <div class="col-sm-12 col-md-8 mx-auto mt-4">
    <h1 class="fw-bolder">Benvenuto</h1>
    <div class="col-lg-4 col-sm-7 mx-auto my-3 pb-4">
      <span class="text-primary fs-6"
        >Inserisci le credenziali <br />per accedere al tuo account</span
      >
    </div>
    <b-container fluid>
      <b-overlay
        :show="status.loggingIn || $route.query.access_token !== undefined"
        spinner-variant="primary"
        rounded="sm"
      >
        <b-form @submit.prevent="onSubmit">
          <b-form-input
            class="col-lg-8 col-md-10 col-sm-11 mx-auto my-3 shadow_6"
            name="email"
            type="text"
            v-model="form.username"
            placeholder="username"
            autocomplete="username"
            required
          ></b-form-input>
          <b-form-input
            class="col-lg-8 col-md-10 col-sm-11 mx-auto my-3 shadow_6"
            name="password"
            type="password"
            v-model="form.password"
            placeholder="password"
            autocomplete="current-password"
            required
          ></b-form-input>
          <p v-if="status.loggedIn == false" class="text-danger">
            <br />
            Credenziali errate.
          </p>
          <b-button
            type="submit"
            variant="primary text-white primary_gradient fw-bolder mt-2 col-lg-4 col-sm-7"
            >LOG IN
          </b-button>
        </b-form>

        <h6 class="fw-bolder my-3 pt-3">
          Non sei registrato?
          <router-link to="/register">Registrati</router-link>
        </h6>
        <h6
          class="fw-bolder my-3 pt-3 point p-2"
          @click="$router.push({ name: 'recupero password' })"
        >
          Recupera Password
        </h6>
        <!-- <h6 v-b-modal.modal-1 class="fw-bolder my-3 pt-3 point p-2">
          Recupera Password
        </h6> -->
        <h6 class="fw-bolder my-3 pt-3 point">
          <a style="color: #008323" href="https://www.federfarma.it/Login.aspx">
            Accesso per le farmacie iscritte Federfarma</a
          >
        </h6>
      </b-overlay>
      <b-modal
        id="modal-1"
        title="Recupera password"
        size="lg"
        centered
        hide-footer
      >
        <p class="text-center">
          Inserisci l'indirizzo email utilizzato in fase di registrazione.
        </p>
        <b-form-input
          class="col-11 mx-auto my-3 shadow_6"
          name="email"
          type="email"
          v-model="recoverEmail"
          placeholder="inserisci email..."
          required
        ></b-form-input>
        <div class="col-12 text-center mb-4">
          <p v-if="status.recoverSent === true">
            Mail inviata! Controlla la tua posta elettronica.
          </p>
          <p v-else-if="status.recoverSent === false" class="text-danger">
            Indirizzo non valido
          </p>
          <b-button
            :disabled="!canSubmit"
            @click="submitRecover"
            type="button"
            variant="primary text-white primary_gradient fw-bolder mt-2 mx-auto col-lg-4 col-sm-7"
            >Invia</b-button
          >
        </div>
        <br />
      </b-modal>
    </b-container>
    <CookieBanner
      :showbanner="doShowBanner"
      v-on:cookies-done="handleAcceptedCookie"
    ></CookieBanner>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import CookieBanner from "@/components/utilities/CookieBanner.vue";
export default {
  name: "LoginPage",
  components: { CookieBanner },
  data() {
    return {
      form: {
        // email: null,
        username: null,
        password: null,
      },
      recoverEmail: null,
      canSubmit: false,
      privacyAccepted: false,
      doShowBanner: false,
    };
  },
  computed: {
    ...mapState("utente", ["status", "user"]),
  },
  methods: {
    ...mapActions("utente", ["login", "logout", "recoverPassword", "me"]),
    onSubmit() {
      // //console.log(this.status);
      if (this.form && this.privacyAccepted) {
        var data = {
          form: this.form,
          redirect: this.$route.query.redirect,
        };
        this.login(data)
          .then((res) => {
            // //console.log("after login ok", res);
          })
          .catch((error) => {
            // //console.log("after login error", error);
          });
      } else if (!this.doShowBanner) {
        this.doShowBanner = true;
      }
    },
    handleAcceptedCookie() {
      (this.privacyAccepted = true), this.onSubmit();
    },
    submitRecover() {
      this.recoverPassword({ email: this.recoverEmail })
        .then((res) => {
          this.$root.$emit("bv::hide::modal", "modal-1");
        })
        .catch((error) => {
          // this.$root.$emit("bv::hide::modal", "modal-1");
        });
    },
  },
  watch: {
    recoverEmail(val) {
      this.canSubmit = val !== null && val.indexOf("@") > 1;
    },
  },
  mounted() {
    if (this.$route.query.access_token) {
      let form = {
        token: this.$route.query.access_token,
        redirect: this.$route.query.redirect,
      };
      this.me(form);
    }
  },
};
</script>
<style scoped>
input {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.custom__content__df {
  background-color: transparent !important;
}
</style>
<style>
header.modal-header {
  border-color: transparent;
}
</style>
