<template>
  <div>
    <b-row class="my-3 py-2" v-if="memberList">
      <b-col class="col-md-4 col-12" v-for="(familiare, ind) in memberList" :key="ind">
        <FamiliareCard :familiare="familiare" v-on:select-familiare="showDetails($event)" />
      </b-col>
    </b-row>

    <b-modal size="lg" id="member-detail" hide-footer :title="modalTitle">
      <div v-if="selectedMember">
        <b-form @submit.prevent="onSubmit">
          <div class="col-12 d-flex flex-wrap px-0 px-md-2">
            <b-form-group label="Nome*" label-for="name" class="
                text-left
                fs-6
                text-font-grey
                mx-auto
                col-12 col-md-6
                px-0 px-md-2
              ">
              <b-form-input class="my-3 shadow_6 text-capitalize" name="name" type="text" placeholder="nome..."
                v-model="selectedMember.name" :readonly="noEdit" required></b-form-input>
            </b-form-group>

            <b-form-group label="Cognome*" label-for="surname" class="
                text-left
                fs-6
                text-font-grey
                mx-auto
                col-12 col-md-6
                px-0 px-md-2
              ">
              <b-form-input class="my-3 shadow_6 text-capitalize" name="surname" type="text" placeholder="cognome..."
                v-model="selectedMember.surname" :readonly="noEdit" required></b-form-input>
            </b-form-group>
            <b-form-group label="Codice fiscale*" label-for="cf" class="
                text-left
                fs-6
                text-font-grey
                mx-auto
                col-12 col-md-6
                px-0 px-md-2
              ">
              <b-form-input class="my-3 shadow_6 text-uppercase" name="cf" type="text" placeholder="codice fiscale..."
                maxlength="16" minlength="16" v-model="selectedMember.cf" :readonly="noEdit" required></b-form-input>
            </b-form-group>

            <b-form-group label="Numero Tessera Sanitaria" label-for="ts_number" class="
                text-left
                fs-6
                text-font-grey
                mx-auto
                col-12 col-md-6
                px-0 px-md-2
              ">
              <b-form-input class="my-3 shadow_6" name="ts_number" type="text" placeholder="numero tessera..."
                v-model="selectedMember.ts_number" maxlength="20" minlength="20" :readonly="noEdit"></b-form-input>
            </b-form-group>
            <b-form-group label="Fascia d'età*" label-for="type" class="
                text-left
                fs-6
                text-font-grey
                mx-auto
                col-12 col-md-6
                px-0 px-md-2
              ">
              <b-form-select class="my-3 shadow_6 text-capitalize" :options="memberTypes" name="type"
                v-model="selectedMember.type" :disabled="noEdit" required>
                <b-form-select-option :value="null">Seleziona fascia d'età</b-form-select-option>
              </b-form-select>
            </b-form-group>

            <b-form-group label="Relazione*" label-for="relazione" class="
                text-left
                fs-6
                text-font-grey
                mx-auto
                col-12 col-md-6
                px-0 px-md-2
              ">
              <b-form-select class="my-3 shadow_6 text-capitalize" name="relazione"
                v-model="selectedMember.relationship" :disabled="noEdit" required>
                <b-form-select-option v-for="rel in relationships" :key="rel" :value="rel">{{ rel }}
                </b-form-select-option>
                <b-form-select-option :value="null">Seleziona relazione</b-form-select-option>
              </b-form-select>
            </b-form-group>
            <b-form-group label="Genere*" label-for="gender"
              class="text-left fs-6 text-font-grey mx-auto col-12 px-0 px-md-2">
              <b-form-radio-group v-model="selectedMember.gender" name="gender-options" class="d-flex">
                <b-form-radio value="maschile">maschile</b-form-radio>
                <b-form-radio value="femminile">femminile</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </div>
          <div class="mx-auto px-2 pb-4 mb-4 text-right" v-if="noEdit">
            <b-button variant="primary text-white fw-bolder spacing_1" @click.prevent="noEdit = !noEdit">Modifica Dati
            </b-button>
          </div>
          <div class="mx-auto px-2 pb-4 mb-4 text-right" v-else>
            <p class="text-left pl-4">*Campi obbligatori</p>
            <b-button type="button" variant="outline-danger fw-bolder m-2" @click.prevent="handleAnnulla">Annulla
            </b-button>
            <b-button type="submit" variant="primary text-white primary_gradient fw-bolder m-2">Salva
            </b-button>
          </div>
        </b-form>
      </div>
      <p v-else>Nessun elemento selezionato</p>
    </b-modal>
  </div>
</template>
<script>
import { cittadinoService } from "@/_services";
import { mapState, mapActions } from "vuex";
import FamiliareCard from "@/components/familiari/FamiliareCard.vue";
export default {
  name: "FamiliariComponent",
  components: {
    FamiliareCard,
  },
  data() {
    return {
      memberList: null,
      selectedMember: null,
      noEdit: true,
      modalTitle: "",
      memberTypes: ["età pediatrica", "adulto", "anziano"],
      genders: ["femminile", "maschile"],
      relationships: [
        "figlio",
        "genitore",
        "coniuge",
        "convivente",
        "nipote",
        "altro(parente)",
        "altro(non parente)",
      ],
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    getMembers() {
      var self = this;
      cittadinoService
        .getFamilyMembers()
        .then(function (res) {
          self.memberList = res.data.data;
        })
        .catch(function (err) {
          console.log(err);
          self.errorAlert(
            "Non è stato possibile recuperare le informazioni richieste"
          );
        });
    },
    updateMember() {
      var self = this;
      cittadinoService
        .updateFamilyMembers(this.selectedMember)
        .then(function (res) {
          // //console.log(res.data.data);
          self.$bvModal.hide("member-detail");
          self.successAlert("Informazioni aggiornate con successo");
          self.getMembers();
          // self.memberList = res.data.data;
        })
        .catch(function (err) {
          console.log(err);
          self.$bvModal.hide("member-detail");
          self.errorAlert("Non è stato possibile aggiornare le informazioni.");
        });
    },
    onSubmit() {
      this.updateMember();
    },
    handleAnnulla() {
      this.$bvModal.hide("member-detail");
      this.getMembers();
    },
    showDetails(selected) {
      this.selectedMember = selected;
      this.modalTitle = selected.name + " " + selected.surname;
      this.$bvModal.show("member-detail");
    },
  },
  created() {
    this.getMembers();
  },
};
</script>