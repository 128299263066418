<template>
  <div class="text-justify" v-if="user && user.company">
    <h6 class="text-center">
      ATTO DI NOMINA A RESPONSABILE ESTERNO DEL TRATTAMENTO
    </h6>
    <br />
    <p>Il presente Atto di nomina viene stipulato da e tra:</p>
    <p>
      <span class="text-uppercase"> {{ user.company.denominazione }}</span>
      con sede in
      <span class="text-capitalize">
        {{ user.company.frazione }} - {{ user.company.comune }} </span
      >,
      <span class="text-capitalize">
        {{ user.company.indirizzo }} {{ user.company.cap }} </span
      >, codice fiscale
      <span class="text-uppercase"> {{ user.company.codice_fiscale }}</span
      >, p.iva {{ user.company.partita_iva }} in persona del legale
      rappresentante
      <span class="text-capitalize">
        {{ user.company.nome }} {{ user.company.cognome }}</span
      >
      (nel seguito anche solo <strong>Titolare</strong>)
    </p>
    <h6 class="text-center">E</h6>
    <p>
      <br />
      <strong>Promofarma S.r.l.</strong>
      con sede in via Emanuele Filiberto, 190 - 00185 Roma, codice fiscale e P.
      IVA IT05317231008 in persona del legale rappresentante pro-tempore (nel
      seguito anche solo Responsabile)
      <br />
      <br />
      nel seguito indicati congiuntamente anche come “<strong>Parti</strong>”.
      <br />
    </p>
    <h6 class="text-center">PREMESSE</h6>
    <ol style="list-style-type: lower-alpha">
      <li>
        <span class="text-uppercase"> {{ user.company.denominazione }}</span> è
        interessata ad utilizzare Dottorfarma per erogare servizi a valore
        aggiunto ai propri pazienti, sia nell’ambito di progetti che coinvolgono
        partner istituzionali che nell’esercizio della propria attività
        professionale, in entrambi i casi appositamente regolamentati di volta
        in volta tra le parti direttamente coinvolte;
      </li>
      <li>
        nell’espletamento di talie attività ed il perseguimento degli obiettivi
        indicati al punto precedente ha in essere un contratto per la licenza
        d’uso della piattaforma Dottorfarma (di seguito solo “Contratto”) con
        Promofarma S.r.l. avente ad oggetto la messa a disposizione di una
        pluralità di servizi volti a valorizzare il ruolo professionale del
        farmacista ed a fornire la possibilità di realizzare quanto indicato nel
        punto <i>a</i> ;
      </li>
      <li>
        l’esecuzione di tale contratto comporta da parte di Promofarma S.r.l. il
        trattamento di dati di cui
        <span class="text-uppercase"> {{ user.company.denominazione }}</span> è
        titolare
      </li>
      <li>
        Il presente Atto di nomina costituisce parte integrante del contratto
        stipulato da
        <span class="text-uppercase"> {{ user.company.denominazione }}</span> e
        da Promofarma S.r.l. ed è finalizzato a disciplinare le obbligazioni di
        ciascuna Parte in relazione al Trattamento dei dati personali necessario
        ai fini dell’esecuzione del Contratto.
      </li>
    </ol>
    <p>Tanto premesso, le Parti</p>

    <h6 class="text-center">Convengono quanto segue</h6>
    <h6 class="text-left">1. PREMESSE E ALLEGATI</h6>
    <p>
      Le Premesse e gli Allegati costituiscono parte sostanziale ed integrante
      del presente Atto di nomina.
      <br />
    </p>
    <h6 class="text-left">2. DEFINIZIONI</h6>
    <p>
      Ai fini del presente Atto di nomina, i termini e le espressioni definiti
      avranno il seguente significato convenzionalmente attribuito:
      <br />
    </p>
    <ul style="list-style-type: decimal">
      <li>
        <b>“Atto di nomina”</b> indica il presente Atto di nomina a Responsabile
        del Trattamento dei dati personali ai sensi dell’art. 28 del Reg. UE
        2016/679 (di seguito GDPR) e dell’art. 29 del D.lgs. 196/2003 e
        successive modifiche, compresi gli allegati;
      </li>
      <li>
        <b>“Autorità di controllo”</b> indica l’Autorità Garante per la
        protezione dei dati personali
      </li>
      <li>
        <b>“Contratto”</b> indica il contratto in essere tra le parti, cui il
        presente atto di nomina si riferisce;
      </li>
      <li>
        <b>"Leggi sulla protezione dei dati"</b> indica tutte le leggi e i
        regolamenti, inclusi ma non limitati al Regolamento (UE) 2016/679 del
        Parlamento Europeo e del Consiglio del 27 aprile 2016 in materia di
        protezione delle persone fisiche con riguardo al Trattamento dei dati
        personali, nonché alla libera circolazione dei dati ( c.d. GDPR) e al
        Codice in materia di protezione dei dati personali ex D.lgs. 196/2003 e
        successive modifiche (Codice Privacy) nonché provvedimenti di volta in
        volta in vigore che sono applicabili al Trattamento dei dati personali
        effettuato in forza del Contratto;
      </li>
      <li>
        <b>"Responsabile del Trattamento dei dati personali"</b> indica il
        soggetto che tratta dati personali per conto del Titolare del
        Trattamento dei dati personali ai sensi dell’Art. 28 del GDPR;
      </li>
      <li>
        <b>"DPO"</b> indica il Responsabile della Protezione dei dati ai sensi
        degli artt. 37 e ss. del GDPR;
      </li>
      <li>
        <b>"Richiesta dell’interessato"</b> indica la richiesta presentata
        dall’interessato al Titolare o al Responsabile mediante la quale lo
        stesso esercita i propri diritti riconosciuti dalle Leggi sulla
        protezione dei dati (art. 12 e seg. del GDPR);
      </li>
      <li>
        <b>"Sub-responsabile"</b> indica il soggetto che su nomina ed incarico
        del Responsabile del Trattamento dei dati personali, tratta i dati
        oggetto del presente contratto;
      </li>
      <li>
        <b>"Titolare del Trattamento dei dati"</b> indica il soggetto che
        determina le finalità e i mezzi di Trattamento dei dati personali;
      </li>
      <li>
        <b>"Trasferimento dati verso Paesi Terzi"</b> indica il trasferimento di
        dati personali da paesi appartenenti all’UE verso Paesi non appartenenti
        all’UE o allo Spazio Economico Europeo – SEE (Norvegia, Islanda,
        Liechtenstein);
      </li>
      <li>
        <b>"Violazione dei dati personali"</b> indica una violazione di
        sicurezza che comporta, accidentalmente o in modo illecito, la
        distruzione, la perdita, la modifica, la divulgazione non autorizzata o
        l'accesso ai dati personali trasmessi, conservati o trattati in altro
        modo.
      </li>
    </ul>
    <h6 class="text-left">3. RUOLO DELLE PARTI NEL TRATTAMENTO DEI DATI</h6>
    <p>
      3.1 In relazione ai dati personali trattati ai fini dell’esecuzione del
      Contratto,
      <span class="text-uppercase"> {{ user.company.denominazione }}</span>
      agisce in qualità di Titolare del Trattamento dei dati mentre Promofarma
      S.r.l. opera quale Responsabile del trattamento dei dati personali.

      <br />
      3.2 Ogni trattamento di dati personali viene effettuato dal Responsabile
      nel rispetto delle istruzioni impartite dal Titolare. Laddove il
      Responsabile, in violazione del presente Atto di nomina e delle Leggi
      sulla protezione dei dati applicabili, determini autonomamente le finalità
      e i mezzi di qualsiasi attività di trattamento di cui al Contratto, verrà
      considerato, in relazione a tale attività di trattamento, quale titolare
      autonomo del trattamento dei dati.

      <br />
      3.3 I termini e le condizioni del presente Atto di nomina saranno
      applicabili a ogni trattamento di dati personali condotto dal Responsabile
      sulla base del Contratto e agiranno in aggiunta o sostituiranno le
      disposizioni, i diritti e gli obblighi specificati nel Contratto. In caso
      di controversia tra i termini del presente Atto di nomina e il Contratto,
      prevarranno i termini del presente Atto di nomin
      <br />
    </p>
    <h6 class="text-left">4. TRATTAMENTO DEI DATI PERSONALI</h6>
    <p>
      4.1 <b>Istruzioni</b>. Il Responsabile si impegna a trattare i dati
      personali unicamente secondo le istruzioni scritte impartite dal Titolare.
      A tal riguardo, il Titolare garantisce che tutte le istruzioni fornite al
      Responsabile saranno impartite in conformità al presente Atto di nomina e
      alle Leggi sulla protezione dei dati applicabili.

      <br />
      4.2 Nel caso in cui Responsabile ritenga che le istruzioni fornite dal
      Titolare siano in conflitto con le disposizioni delle Leggi sulla
      protezione dei dati applicabili deve informare immediatamente il Titolare.

      <br />
      4.3 L'Allegato 1 al presente Atto di nomina, in conformità alle
      disposizioni di cui all’Art. 28, comma 3 del RGPD o a disposizioni
      equivalenti di qualsiasi altra Legge sulla protezione dei dati
      applicabile, riporta le istruzioni rilevanti relative al trattamento dei
      dati personali effettuato ai sensi del Contratto.

      <br />
      4.4 <b>Conformità alle Leggi sulla protezione dei dati</b>. Il
      Responsabile si impegna ad effettuare le operazioni di trattamento dei
      dati personali di cui al Contratto nel pieno rispetto di tutte le Leggi
      sulla protezione dei dati applicabili.

      <br />
      4.5 <b>Riservatezza</b>. Il Responsabile riconosce espressamente che i
      dati personali trattati ai fini dell’esecuzione del Contratto hanno natura
      riservata e confidenziale. Pertanto, il Responsabile si impegna ad
      utilizzare i dati personali esclusivamente ai fini dell’esecuzione del
      Contratto e nei limiti del presente Atto di nomina, nonché a mantenere i
      dati personali strettamente confidenziali e a non divulgarli o
      trasferirli, in tutto o in parte, in qualsiasi modo, salvo autorizzazione
      scritta del Titolare e secondo le disposizioni di cui all’articolo 5 del
      GDPR o salvo sia obbligato in forza delle leggi a cui il Responsabile è
      soggetto. Nel caso in cui il Responsabile sia obbligato a comunicare i
      dati personali a terzi in forza di obblighi di legge a cui lo stesso è
      soggetto e/o a specifici ordini della pubblica autorità, informerà
      tempestivamente il Titolare di tale obbligo prima del trattamento o
      comunque entro e non oltre 24 ore dal momento in cui il Responsabile è
      venuto a conoscenza o sarebbe dovuto venire a conoscenza di tale obbligo
      giuridico. Il Responsabile è esentato da tale obbligo di comunicazione nei
      confronti del Titolare solo qualora la legge ad esso applicabile o il
      provvedimento della pubblica autorità ordinante vieti la comunicazione di
      tali informazioni per rilevanti motivi di interesse pubblico.

      <br />
    </p>
    <h6 class="text-left">5. SUB-RESPONSABILI</h6>
    <p>
      5.1. <b>Nomina</b>. Il Responsabile può subappaltare o assegnare, in tutto
      o in parte, le attività di trattamento al medesimo affidate a un
      Sub-responsabile solo previa approvazione scritta rilasciata dal Titolare.

      <br />
      5.2. <b>Obblighi del Sub-responsabile</b>. Ai fini del sub-Trattamento, il
      Responsabile si impegna a stipulare accordi in forma scritta con i
      Sub-responsabili da esso individuati. Tali accordi scritti dovranno, tra
      l’altro, prevedere a carico del Sub-responsabile gli stessi obblighi a cui
      il Responsabile è vincolato dal presente Atto di nomina, in particolare:
      <br />
    </p>
    <ul style="list-style-type: lower-alpha">
      <li>
        l'obbligo per il Sub-responsabile di attenersi a tutte le istruzioni
        impartite dal Titolare e dal Responsabile in merito al trattamento dei
        dati personali;
      </li>
      <li>
        l'obbligo per il Sub-responsabile di non trattare o fare qualsiasi altro
        uso dei DATI personali per finalità diverse da quelle previste dal
        Contratto stipulato dal Titolare con il Responsabile, salvo espressa
        autorizzazione del Titolare o dello stesso Responsabile;
      </li>
      <li>
        l'obbligo per il Sub-responsabile di implementare Misure Tecniche ed
        Organizzative appropriate per soddisfare i requisiti delle Leggi sulla
        protezione dei dati personali applicabili;
      </li>
      <li>
        l’obbligo del Sub-responsabile di consentire al Titolare e al
        Responsabile di monitorare l’adempimento del Sub-responsabile rispetto
        all'obbligo di implementare Misure Tecniche ed Organizzative come
        stabilito all’art. 5.4 (c);
      </li>
      <li>
        il diritto del Fornitore del Servizio di risolvere il Contratto di
        sub-Trattamento stipulato con il Sub-responsabile con effetto immediato
        nel caso in cui il Sub-responsabile non osservi le istruzioni del
        Titolare del trattamento o del Responsabile o le Leggi sulla protezione
        dei dati applicabili o nel caso in cui il Sub-responsabile non abbia
        implementato Misure Tecniche ed Organizzative così come meglio
        specificate all’art. 7.1.
      </li>
    </ul>
    <p>
      5.3. <b>Responsabilità</b>. Il Responsabile sarà direttamente responsabile
      verso il Titolare per gli atti, omissioni, inadempimenti e negligenze dei
      Sub-responsabili dallo stesso individuati, nella stessa misura in cui il
      Responsabile sarebbe responsabile se eseguisse direttamente i Servizi
      affidati a ciascun Sub-responsabile.

      <br />
      5.4. <b>Manleva</b>. Pertanto, il Responsabile sarà tenuto a mantenere il
      Titolare manlevato ed indenne da ogni e qualsivoglia danno, costo,
      sanzione o pregiudizio che quest’ultima dovesse subire a causa della
      violazione da parte del Sub-responsabile degli obblighi relativi al
      trattamento dei dati personali derivanti dalle Leggi sulla protezione dei
      dati, dal presente Atto di nomina o dagli atti di nomina del
      Sub-responsabile.

      <br />
    </p>
    <h6 class="text-left">6. PERSONALE DEL RESPONSABILE</h6>
    <p>
      6.1. <b>Riservatezza</b>. Il Responsabile garantisce che il personale
      impegnato nel trattamento dei dati personali ai sensi del Contratto è
      informato circa la natura riservata di tali dati personali e ha ricevuto
      una formazione adeguata e delle istruzioni operative specifiche sul
      corretto trattamento dei dati. Il Responsabile garantisce, altresì, di
      aver sottoscritto accordi di riservatezza con il personale impegnato nel
      trattamento dei dati personali di cui al Contratto. Resta inteso che gli
      obblighi di cui al presente articolo 6 restano in vigore anche dopo la
      scadenza o la cessazione per qualsivoglia motivo del rapporto di lavoro.
      Il Responsabile adotterà ogni ragionevole misura per garantire
      l'affidabilità del personale impegnato nel trattamento dei dati personali.

      <br />
      6.2. <b>Limitazione dell'accesso</b>. Il Responsabile si impegna a
      limitare l'accesso ai dati personali al solo personale che esegue le
      attività necessarie ai fini dell’esecuzione del Contratto.

      <br />
      6.3. <b>DPO</b>. Il Responsabile nominerà un DPO nella misura in cui le
      Leggi sulla protezione dei dati applicabili richiedano la nomina di un
      Responsabile della protezione dei dati. Il DPO del Responsabile può essere
      contattato via e-mail: dpopromofarma@pec.federfarma.it

      <br />
    </p>
    <h6 class="text-left">7. SICUREZZA DEI DATI E AUDIT</h6>
    <p>
      7.1. <b>Sicurezza</b>. Tenendo conto dello stato dell'arte e dei costi di
      implementazione, nonché della natura, dell’oggetto, del contesto e delle
      finalità del trattamento, come anche del rischio di probabilità e gravità
      per i diritti e le libertà delle persone fisiche coinvolte, il
      Responsabile adotterà tutte le Misure Tecniche ed Organizzative
      ragionevolmente necessarie per garantire un livello di sicurezza adeguato
      al rischio.

      <br />
      7.2. <b>Audit</b>. Il Responsabile si impegna a fornire al Titolare e ai
      dipendenti di quest’ultimo, assistenza e cooperazione adeguate al fine di
      consentire al Titolare di verificare se il Responsabile (e i
      Sub-responsabili) agiscano in conformità alle Leggi sulla protezione dei
      dati applicabili. Tale assistenza potrà comprendere, tra l’altro, la
      compilazione di questionari di qualificazione e la consegna di certificati
      di conformità agli standard del settore e/o alle Leggi sulla protezione
      dei dati applicabili.
      <br />
    </p>
    <h6 class="text-left">8. RICHIESTE DEGLI INTERESSATI</h6>
    <p>
      8.1. <b>Assistenza</b>. Il Responsabile dovrà assistere il Titolare, nella
      misura in cui ciò sia possibile, al fine di soddisfare l’obbligo del
      Titolare di dare seguito alle richieste effettuate da parte degli
      interessati per l’esercizio dei diritti ad essi riconosciuti dal GDPR o da
      disposizioni equivalenti di altre Leggi sulla protezione dei dati
      applicabili.
      <br />
      8.2. <b>Gestione delle Richieste degli interessati</b>. Il Responsabile,
      coerentemente con quanto previsto dalle Leggi sulla protezione dei dati
      applicabili, e in particolare con quanto previsto dal GDPR, avviserà
      tempestivamente il Titolare qualora riceva una richiesta dell’interessato.
      Nella misura in cui il Titolare, nel suo utilizzo dei Servizi, non abbia
      la capacità di rispondere alla richiesta di un interessato, il
      Responsabile porrà in essere ogni misura necessaria al fine di assistere
      il Titolare a rispondere a tale richiesta. Nella misura consentita dalla
      legge, il Titolare sarà responsabile per qualsiasi costo derivante dalla
      fornitura di tale assistenza da parte del Responsabile.

      <br />
    </p>
    <h6 class="text-left">9. VIOLAZIONE DEI DATI PERSONALI</h6>
    <p>
      9.1. <b>Politiche e procedure</b>. Il Responsabile è tenuto ad
      implementare politiche e procedure di sicurezza per la gestione degli
      incidenti al fine di garantire la corretta esecuzione degli adempimenti
      richiesti dal GDPR o da disposizioni equivalenti di altre Leggi sulla
      protezione dei dati applicabili in caso di violazioni dei dati personali.
      <br />
      9.2. <b>Comunicazione della Violazione</b>. In accordo con quanto previsto
      dalle Leggi in materia di protezione di dati, il Responsabile informerà
      immediatamente il Titolare di eventuali violazioni dei dati personali
      effettive o ragionevolmente sospette subite da parte dello stesso
      Responsabile o da parte di uno dei suoi Sub-responsabili. La notifica
      dovrà includere le informazioni di cui all'articolo 28 (3) del GDPR.
      <br />
      9.3. <b>Rimedio</b>. Nella misura in cui la violazione dei dati personali
      sia causata da una violazione del Responsabile o dei suoi Sub-responsabili
      delle disposizioni del presente Atto di nomina, del Contratto o delle
      Leggi sulla protezione dei dati applicabili, tenendo conto della natura
      della violazione dei dati personali e del rischio di varia probabilità e
      gravità per i diritti e le libertà delle persone fisiche coinvolte, il
      Responsabile, su istruzione del Titolare, opererà tutti gli sforzi
      necessari per identificare e porre rimedio alla causa della violazione dei
      dati personali, per mitigare i rischi per i diritti e le libertà delle
      persone fisiche coinvolte e per assistere ulteriormente il Titolare con
      ogni ragionevole richiesta nel rispetto delle Leggi sulla protezione dei
      dati relative alle violazioni dei dati personali.
      <br />
      9.4. Tali stessi rimedi dovranno posti in essere anche nel caso in cui la
      Violazione dei dati personali non si sia ancora verificata ma vi è il
      fondato rischio che si verifichi.

      <br />
      9.5. Il Responsabile sarà responsabile per qualsiasi costo derivante dalla
      fornitura di tale assistenza.

      <br />
      9.6. <b>Ulteriore assistenza</b>. Nella misura in cui la violazione dei
      dati personali non sia causata da una violazione del Responsabile o dei
      suoi Sub-responsabili delle disposizioni del presente Atto di nomina, del
      Contratto o delle Leggi sulla protezione dei dati applicabili, tenendo
      conto della natura della violazione dei dati e del rischio di varia
      probabilità e gravità per i diritti e le libertà delle persone fisiche
      coinvolte, il Responsabile fornirà al Titolare tutta l’assistenza
      necessaria al fine di consentire alla stessa la gestione della violazione
      dei dati personali.

      <br />
      9.7. Il Titolare sarà responsabile per qualsiasi costo derivante dalla
      fornitura di tale assistenza da parte del Responsabile.

      <br />
    </p>
    <h6 class="text-left">
      10. VALUTAZIONE DELL'IMPATTO SULLA PROTEZIONE DEI DATI E CONSULTAZIONE
      PREVENTIVA
    </h6>
    <p>
      10.1. Ove il Titolare ritenga di dover condurre una Valutazione d’Impatto
      ai sensi dell’art. 35 del GDPR, il Responsabile fornirà un'assistenza
      ragionevole al Titolare per effettuare tale Valutazione e l‘eventuale
      Consultazione preventiva ex art. 36 con l’Autorità di controllo. In ogni
      caso, tenendo conto della natura del trattamento e delle informazioni a
      disposizione del Responsabile, tale assistenza sarà resa in favore del
      Titolare esclusivamente in relazione al trattamento dei dati personali
      effettuato da parte del Responsabile in esecuzione del Contratto.

      <br />
    </p>
    <h6 class="text-left">
      11. TRASFERIMENTO E TRATTAMENTO FUROI DALL’AREA ECONOMICA EUROPEA
    </h6>
    <p>
      11.1. Il Responsabile del trattamento dichiara che non trasferisce e
      tratta dati personali fuori dall’ Area economica europea (AEE).

      <br />
    </p>
    <h6 class="text-left">12. CANCELLAZIONE E RESTITUZIONE</h6>
    <p>
      12.1. <b>Cancellazione e restituzione</b>. A scelta del Titolare, il
      Responsabile dovrà, ai sensi degli artt. 12.2 e 12.3, cancellare o
      restituire i dati personali al Titolare dopo la conclusione della
      fornitura dei Servizi ai sensi dell’Atto di nomina.

      <br />
      12.2. <b>Cancellazione</b>. Nel caso in cui il Titolare scegliesse
      l'opzione della cancellazione ai sensi dell’art. 12.1, il Responsabile
      cancellerà, e farà cancellare tutti i dati personali ed eventuali copie
      prontamente e in ogni caso entro una (1) settimana dalla richiesta di
      cancellazione.

      <br />
      12.3. <b>Restituzione</b>. Nel caso in cui il Titolare scegliesse
      l'opzione di restituzione ai sensi dell’art. 12.1, il Responsabile (a)
      restituirà una copia completa di tutti i dati personali al Titolare
      tramite trasmissione sicura dei file in un formato comunicato dalla stessa
      e (b) cancellerà e farà cancellare tutte le altre copie di dati personali
      trattati da qualsiasi altro Responsabile e/o Sub-responsabile di cui il
      Responsabile si è avvalso per il trattamento di dati personali di cui al
      Contratto. Il Responsabile soddisferà tale richiesta scritta entro una (1)
      settimana dalla data della richiesta.
      <br />
      12.4. <b>Conservazione</b>. Il Responsabile potrà conservare una copia dei
      dati per i soli fini e secondo le modalità e tempistiche previste dalle
      Leggi sulla protezione dei dati applicabili e sempre a condizione che il
      Responsabile garantisca la riservatezza dei dati personali conservati. È
      fatto espresso divieto al Responsabile di conservare i dati personali di
      cui al Contratto per finalità e secondo modalità e tempistiche diverse da
      quelle previste dalle Leggi sulla protezione dei dati applicabili.

      <br />
      12.5. <b>Dichiarazione finale</b>. Il Responsabile fornirà al Titolare una
      dichiarazione finale scritta attestante il pieno rispetto del presente
      articolo entro trenta (30) giorni dalla data in cui il Titolare ha
      presentato la richiesta di cancellazione o restituzione.

      <br />
      12.6. <b>Costi</b>. I costi per la cancellazione o restituzione dei dati
      personali saranno a carico dello stesso Responsabile.

      <br />
    </p>
    <h6 class="text-left">13. TERMINE E RISOLUZIONE</h6>
    <p>
      13.1. Il presente Atto di nomina dati è in vigore parallelamente al
      Contratto e produce effetti dal momento della sottoscrizione e termina
      alla data di scadenza o alla risoluzione dello stesso. Ciò premesso
      l’eventuale cessazione del presente Contratto non avrà alcun effetto sui
      diritti o le responsabilità maturati da entrambe le parti e non avrà alcun
      effetto sull’entrata in vigore o sulla prosecuzione dell’efficacia di
      qualsiasi clausola del presente Contratto la quale, per espresso
      riferimento o in forza di legge, si intenda acquistare efficacia o
      proseguire nell’efficacia anche successivamente alla cessazione.

      <br />
    </p>
    <h6 class="text-left">
      14. MODIFICHE ALLE LEGGI SULLA PROTEZIONE DEI DATI.
    </h6>
    <p>
      14.1. Il presente contratto non è cedibile dalle parti a terzi, in
      mancanza del consenso da parte del contraente ceduto. È vietata la
      cessione, in tutto o in parte, dei crediti derivanti dall’esecuzione del
      contratto.
      <br />
    </p>
    <h6 class="text-left">15. Trattamento dei dati personali</h6>
    <p>
      14.1. Qualora intervengano modifiche alle Leggi sulla protezione dei dati
      personali che possano incidere sul presente Atto di nomina, il Titolare e
      il Responsabile si impegnano a modificare il presente contratto per
      renderlo conforme alle modifiche intervenute.
      <br />
    </p>
    <h6 class="text-left">15. DIRITTO APPLICABILE E GIURISDIZIONE.</h6>
    <p>
      15.1. Il presente Atto di nomina è regolato dalla legge Italiana.

      <br />
      15.2. Per tutte le controversie derivanti da o in connessione con il
      presente Atto di nomina il Foro Competente esclusivo sarà il Tribunale di
      Roma.

      <br />
    </p>
    <p>
      Finalità ☐ Marketing ☐ Profilazione ☐ Gestione delle Risorse Umane ☐
      Erogazione di servizi IT ☐ Gestione della contabilità ☐ Svolgimento di
      studi clinici ☐ Assistenza sanitaria ☐ Attività di vigilanza ☐ Attività
      amministrative ☐ Produzione di dispositivi medici ☐ Gestione sito web ☐
      Organizzazione eventi ☐ Analisi di campioni biologici ☐ Analisi di dati ☐
      Gestione degli ordini / magazzino ☒ Gestione piattaforma web ☐
      ___________________________ ☐ ___________________________ ☐
      ___________________________ ☐ ___________________________
    </p>

    <br />
    <br />

    <h6 class="text-left">
      ALLEGATO 1: Dettagli sul trattamento di dati personali
    </h6>
    <p>
      L'Allegato 1 comprende le informazioni relative al trattamento dei dati
      personali richieste dall'articolo 28 (3) del GDPR.
    </p>
    <h6 class="text-left">
      Oggetto e durata del trattamento dei dati personali
    </h6>
    <p>
      L’oggetto e la durata del trattamento dei dati personali sono stabiliti
      nel Contratto e nel presente Atto di nomina.
    </p>
    <h6 class="text-left">
      Natura e finalità del trattamento dei dati personali
    </h6>
    <p>
      <b>Finalità</b>:
      <br />
      ☐ Marketing<br />
      ☐ Profilazione<br />
      ☐ Gestione delle Risorse Umane<br />
      ☐ Erogazione di servizi IT<br />
      ☐ Gestione della contabilità<br />
      ☐ Svolgimento di studi clinici<br />
      ☐ Assistenza sanitaria<br />
      ☐ Attività di vigilanza<br />
      ☐ Attività amministrative<br />
      ☐ Produzione di dispositivi medici<br />
      ☐ Gestione sito web<br />
      ☐ Organizzazione eventi<br />
      ☐ Analisi di campioni biologici<br />
      ☐ Analisi di dati<br />
      ☐ Gestione degli ordini / magazzino<br />
      ☒ Gestione piattaforma web<br />
      ☐ ___________________________<br />
      ☐ ___________________________<br />
      ☐ ___________________________<br />
      ☐ ___________________________
    </p>

    <h6 class="text-left">Tipologie di dati personali da trattare</h6>
    <p>
      <b>Dati comuni</b>:
      <br />
      ☒ Anagrafici<br />
      ☒ Recapiti (telefonici, e-mail, PEC, etc.)<br />
      ☒ Codice identificativo univoco<br />
      ☐ Curriculum<br />
      ☐ Dati economici/patrimoniali<br />
      ☐ Ubicazione geografica/localizzazione<br />
      ☐ Stato di famiglia/dati relativi alla famiglia<br />
      ☐ Beni, proprietà e possesso<br />
      ☒ Codice ASL<br />
      ☒ Codice tracciabilità<br />
      ☒ partita IVA<br />
      ☐ ___________________________<br />
    </p>
    <p>
      <b>Dati particolari</b>:
      <br />
      ☐ Origini razziali o etniche<br />
      ☐ Convinzioni politiche, religiose o filosofiche<br />
      ☐ Adesione a sindacati<br />
      ☒ Stato di salute <br />
      ☒ Stato di salute di un familiare<br />
      ☐ Vita sessuale<br />
      ☐ Dati genetici<br />
      ☐ Dati biometrici<br />
      ☐ Dati relativi a condanne penali o reati<br />
    </p>
    <h6 class="text-left">
      Le categorie degli interessati a cui fanno riferimento i dati personali
    </h6>
    <p>
      <b>Interessati</b>:
      <br />
      ☐ Dipendenti del Titolare <br />
      ☐ Collaboratori del Titolare <br />
      ☐ Clienti del Titolare <br />
      ☐ Prospect del Titolare <br />
      ☐ Consulenti del Titolare <br />
      ☐ Fornitori del Titolare <br />
      ☐ Candidati del Titolare <br />
      ☐ Pazienti del Titolare <br />
      ☐ Visitatori del Titolare <br />
      ☒ Utenti del Titolare <br />
      ☐ Associati del Titolare <br />
      ☐ ___________________________ <br />
      ☐ ___________________________ <br />
    </p>
    <h6 class="text-left">
      Gli obblighi e i diritti de Titolare e delle affiliate del Titolare
    </h6>
    <p>
      Gli obblighi e i diritti del Titolare e delle affiliate del Titolare sono
      indicati nel Contratto e nel presente Atto di nomina.
    </p>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "NominaPromofarma",
  computed: {
    ...mapState("utente", ["status", "user"]),
  },
};
</script>
