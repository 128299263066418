<template>
  <div>
    <div class="text-justify" id="informativa_stampabile">
      <h4 class="text-center">
        INFORMATIVA SUL TRATTAMENTO DEI DATI PERSONALI CREAZIONE PROFILO
        SANITARIO
      </h4>
      <h6 class="text-center">(art. 13 Reg. UE 679/2016)</h6>
      <div class="border border-dark my-4" v-if="company">
        <h6 class="text-center bg-sec-4 py-2 border-bottom border-dark">
          Chi tratta i miei dati?
        </h6>
        <p class="px-1 mb-0 border-bottom border-dark">
          Il Titolare del trattamento è {{ company.denominazione }}
        </p>
        <p class="px-1 mb-0 border-bottom border-dark">
          Sede legale:
          <span class="text-capitalize"
            >{{ company.indirizzo }} {{ company.frazione }}
            {{ company.comune }} {{ company.cap }}</span
          >
        </p>
        <p class="px-1 mb-0 border-bottom border-dark">
          Indirizzo mail: {{ company.email }} / PEC: {{ company.emailpec }}
        </p>
        <h6 class="text-center bg-sec-4 py-2 border-bottom border-dark">
          Come posso contattare il responsabile della protezione dei dati?
        </h6>
        <p class="px-1 mb-0">
          Dati di contatto del Data Protection Officer (DPO):
          _____________________________________
        </p>
      </div>
      <div class="border border-dark my-4" v-else>
        <h6 class="text-center bg-sec-4 py-2 border-bottom border-dark">
          Chi tratta i miei dati?
        </h6>
        <p class="px-1 mb-0 border-bottom border-dark">
          Il Titolare del trattamento è la Farmacia aderente a Dottorfarma che
          tratta i Suoi dati personali per tutte le finalità di seguito
          precisate, della quale può visualizzare i dati di riferimento nella
          sezione
          <router-link
            :to="{ name: 'circolo di cura' }"
            class="text-prim-grad-1 fw-bolder"
            >“Il mio circolo di cura”</router-link
          >
          della sua area riservata.
        </p>
      </div>

      <h5 class="text-center">
        1. PER QUALI FINALITÀ SONO TRATTATI I MIEI DATI E PERCHÉ IL TRATTAMENTO
        È LEGITTIMO?
      </h5>
      <p>Il Titolare tratta i Suoi dati:</p>
      <ul>
        <li>
          per consentirLe la condivisione con il farmacista dei dati relativi
          alla Sua salute, nello specifico: rilevazioni relative ai parametri
          vitali, esami, referti, patologie e terapie in atto; questo
          trattamento è legittimo perché:
          <ul>
            <li>
              per quanto riguarda i Suoi dati comuni (es. anagrafici), è
              necessario per dare esecuzione a un contratto di cui Lei è parte;
            </li>
            <li>
              per quanto riguarda i Suoi dati di salute, è basato sul consenso
              che Lei può manifestare spuntando le apposite caselle situate
              prima del tasto di invio dei dati che Lei inserisce.
            </li>
          </ul>
        </li>
        <li>
          per valutare la Sua aderenza terapeutica con finalità di contribuire a
          sensibilizzarLa in merito ad iniziative di aderenza terapeutica ed
          utilizzo di strumenti di Healthcare e ad aumentare la Sua
          consapevolezza generalizzata sull'aderenza alla terapia, sulle
          implicazioni di una corretta assunzione dei farmaci e di uno stile di
          vita coerente con le indicazioni fornite dai medici; questo
          trattamento è legittimo perché è basato sul consenso che Lei può
          manifestare spuntando le apposite caselle situate prima del tasto di
          invio dei dati che Lei inserisce.
        </li>
      </ul>
      <h5 class="text-center">2. A CHI SONO COMUNICATI I MIEI DATI?</h5>
      <p>I Suoi dati sono comunicati:</p>
      <ul>
        <li>
          a una società di consulenza informatica e a Promofarma S.r.l. per la
          gestione della Piattaforma;
        </li>
        <li>
          ai soggetti (es. familiari, caregiver) a cui Lei liberamente deciderà
          di rendere di volta in volta conoscibili i Suoi dati tramite la
          piattaforma.
        </li>
      </ul>
      <p>
        Lei può richiedere l’elenco completo dei destinatari dei Suoi dati
        personali scrivendo all’indirizzo mail assistenza@dottorfarma.it . In
        ogni caso i Suoi dati personali non saranno diffusi.
      </p>
      <h5 class="text-center">
        3. I MIEI DATI SONO TRASFERITI FUORI DALL’UNIONE EUROPEA?
      </h5>
      <p>I dati non vengono trasferiti al di fuori dell’Unione Europea.</p>
      <h5 class="text-center">
        4. PER QUANTO TEMPO SONO CONSERVATI I MIEI DATI?
      </h5>
      <p>
        Il Titolare conserverà i dati personali per un periodo di tempo non
        superiore a quello necessario a conseguire le finalità per le quali li
        sta trattando. Nello specifico i Suoi dati saranno conservati per tutto
        il tempo in cui il profilo rimarrà attivo e pertanto fino alla
        disattivazione del Suo profilo. Al termine di tale periodo, salvo
        obblighi di legge, il Titolare cancellerà i dati in maniera
        irreversibile – tramite metodi di distruzione o cancellazione sicura – o
        li conserverà in una forma anonima che non consente, neanche
        indirettamente, la Sua identificazione.
      </p>
      <h5 class="text-center">5. SARÒ SOTTOPOSTO A PROFILAZIONE?</h5>
      <p>
        I dati relativi alla Sua salute inseriti nel Suo profilo sanitario
        (rilevazioni relative ai parametri vitali, esami, referti, patologie e
        terapie in atto) verranno utilizzati per ottenere informazioni che
        consentiranno di valutare il Suo livello di aderenza terapeutica. Il
        trattamento <b> <u>non</u></b> porterà a prendere decisioni basate sul
        profilo generato tramite processi decisionali automatizzati, ma è
        unicamente finalizzato a contribuire a sensibilizzarLa in merito ad
        iniziative di aderenza terapeutica ed utilizzo di strumenti di
        Healthcare e ad aumentare la Sua consapevolezza generalizzata
        sull'aderenza alla terapia, sulle implicazioni di una corretta
        assunzione dei farmaci e di uno stile di vita coerente con le
        indicazioni fornite dai medici. La profilazione non avrà pertanto su di
        Lei alcun effetto giuridico né alcun effetto equivalente o similmente
        significativo nel suo impatto. La logica utilizzata corrisponde ai
        criteri descritti dalla normativa di settore, linee guida, best
        practice, progetti riguardanti la sanità pubblica.
      </p>
      <h5 class="text-center">6. QUALI SONO I MIEI DIRITTI?</h5>
      <p>Lei ha i seguenti diritti:</p>
      <ul>
        <li>
          <b> Diritto di accesso ai dati:</b> diritto di ottenere dal Titolare
          la conferma che sia o meno in corso un trattamento di dati personali
          che La riguardano e in tal caso, di ottenere l’accesso ai Suoi dati
          personali – e una copia degli stessi – e di ricevere informazioni
          relative al trattamento;
        </li>
        <li>
          <b> Diritto di rettifica dei dati:</b> diritto di ottenere dal
          titolare del trattamento la rettifica dei dati personali inesatti che
          La riguardano senza ingiustificato ritardo e l’integrazione dei dati
          personali incompleti, anche fornendo una dichiarazione integrativa.
        </li>
        <li>
          <b>Diritto alla cancellazione dei dati:</b> diritto di ottenere dal
          titolare del trattamento la cancellazione dei dati personali che La
          riguardano senza ingiustificato ritardo se sussiste uno dei motivi
          seguenti:
          <ul>
            <li>
              i dati personali non sono più necessari rispetto alle finalità per
              le quali sono stati raccolti o altrimenti trattati;
            </li>
            <li>i dati personali sono stati trattati illecitamente;</li>
            <li>
              i dati personali devono essere cancellati per adempiere un obbligo
              legale a cui è soggetto il Titolare;
            </li>
            <li>
              i dati personali sono stati raccolti relativamente all’offerta di
              servizi della società dell’informazione.
            </li>
          </ul>
        </li>
        <li>
          <b>Diritto di limitazione del trattamento:</b> diritto di ottenere dal
          Titolare la limitazione del trattamento quando ricorre una delle
          seguenti ipotesi:
          <ul>
            <li>
              Lei contesta l’esattezza dei dati personali, per il periodo
              necessario al Titolare per verificare l’esattezza di tali dati
              personali;
            </li>
            <li>
              il trattamento è illecito e Lei si oppone alla cancellazione dei
              dati personali e chiede invece che ne sia limitato l’utilizzo;
            </li>
            <li>
              benché il Titolare non ne abbia più bisogno ai fini del
              trattamento, i dati personali sono necessari a Lei per
              l’accertamento, l’esercizio o la difesa di un diritto in sede
              giudiziaria;
            </li>
            <li>
              Lei si è opposto al trattamento in attesa della verifica in merito
              all’eventuale prevalenza dei motivi legittimi del titolare del
              trattamento rispetto a quelli dell’interessato.
            </li>
          </ul>
        </li>
        <li>
          <b> Diritto alla portabilità dei dati:</b> diritto di ricevere in un
          formato strutturato, di uso comune e leggibile da dispositivo
          automatico i dati personali che La riguardano forniti al Titolare e di
          trasmettere tali dati a un altro titolare del trattamento qualora il
          trattamento si basi sul consenso o sul contratto e sia effettuato con
          mezzi automatizzati.
        </li>
        <li>
          Diritto di non essere sottoposto a una
          <b>decisione basata unicamente sul trattamento automatizzato</b>,
          compresa la profilazione, che produca effetti giuridici che La
          riguardano o che incida in modo analogo significativamente sulla Sua
          persona.
        </li>
      </ul>
      <p>
        Potrà esercitare i Suoi diritti inviando una richiesta all’indirizzo
        e-mail del Titolare. Il Titolare Le risponderà il prima possibile e, in
        ogni caso, non oltre 30 giorni dalla Sua richiesta.
      </p>
      <h5 class="text-center">7. SONO OBBLIGATO A FORNIRE I MIEI DATI?</h5>
      <p>
        Lei è libero di non fornire i Suoi dati per la condivisione con il
        farmacista di dati relativi alla mia salute. Potrà in ogni caso
        comunicare al farmacista i propri dati utilizzando modalità
        tradizionali, ove previste e, in ogni caso, utilizzare senza alcun
        pregiudizio le altre funzionalità della piattaforma. Lei è altresì
        libero di non acconsentire all’attività di profilazione per la
        valutazione della Sua aderenza terapeutica senza che ciò pregiudichi la
        Sua possibilità di utilizzare tutti gli altri servizi messi a
        disposizione dalla piattaforma.
      </p>
      <h5 class="text-center">8. COME POSSO PRESENTARE RECLAMO?</h5>
      <p>
        Qualora Lei desideri proporre un reclamo in merito alle modalità
        attraverso cui i Suoi dati personali sono trattati dal Titolare ovvero
        in merito alla gestione di un reclamo da Lei proposto, Lei ha il diritto
        di presentare un’istanza direttamente all’Autorità di controllo secondo
        le modalità indicate nel sito
        <a href="www.garanteprivacy.it" target="_blank">
          www.garanteprivacy.it</a
        >
        .
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "InformativaArruolamento",
  props: ["company"],
};
</script>
