<template>
  <div class="pl-3 mt-2 text-left">
    <h6 class="fw-bolder">Questionario associato:</h6>
    <div
      class="d-flex flex-wrap align-items-center align-content-center my-2 py-2"
      v-if="service"
    >
      <b-form-group size="sm" class="fw-bolder text-left col-12 col-md-8 px-0">
        <b-form-select
          class="shadow_6"
          v-model="selectedSurvey"
          :disabled="isfds || ishtn"
        >
          <b-form-select-option
            v-for="(surv, ind) in surveys"
            :key="ind"
            :value="surv.hashid"
            >{{ surv.name }}
          </b-form-select-option>
          <b-form-select-option :value="null"
            >Nessun questionario</b-form-select-option
          >
        </b-form-select>
      </b-form-group>
      <div class="col-12 col-md-2 px-0 text-center">
        <b-button
          size="sm"
          v-if="showBtn"
          variant="primary primary_gradient text-white fw-bolder spacing_1"
          @click="sendSelection"
          >Conferma</b-button
        >
      </div>
      <div v-if="!isfds && !ishtn" class="col-12 col-md-2 px-0 text-center">
        <!-- <b-button size="sm" variant="secondary secondary_gradient text-white fw-bolder spacing_1"
          @click="$router.push({ name: 'crea questionario' })">Crea questionario</b-button> -->
        <b-button
          size="sm"
          variant="secondary secondary_gradient text-white fw-bolder spacing_1"
          @click="disableInfo()"
          >Crea questionario</b-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import { supportService } from "@/_services";
import { mapState, mapActions } from "vuex";
export default {
  name: "QuestionarioPrestazione",
  props: ["prestazione", "isfds", "ishtn"],
  data() {
    return {
      surveys: null,
      selectedSurvey: null,
      filterSetted: {
        position_ref: this.isfds ? 3 : 1,
      },
      showBtn: false,
      service: null,
      doFetch: false,
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
  },
  watch: {
    selectedSurvey(val) {
      if (val == null) {
        this.showBtn = this.service.questionario !== null;
      } else {
        this.showBtn =
          (this.service.questionario !== null &&
            this.service.questionario.hashid !== val) ||
          this.service.questionario == null;
      }
    },
    doFetch: function (val) {
      if (this.surveys == null) {
        this.fetchData();
      }
    },
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    disableInfo() {
      window.scrollTo(0, 0);
      this.infoAlert("Funzionalità disabilitata per questa utenza");
    },
    fetchData() {
      var self = this;
      supportService
        .getSurveys(this.filterSetted)
        .then(function (response) {
          self.surveys = response.data.data.data;
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare le attività");
          console.log(err);
        });
      supportService
        .getFreshHealthService(this.prestazione)
        .then(function (response) {
          self.service = response.data.data;
          if (response.data.data.questionario) {
            self.selectedSurvey = response.data.data.questionario.hashid;
          }
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          self.errorAlert("Si è verificato un errore");
          console.log(err);
        });
    },
    sendSelection() {
      if (this.isfds) {
        return false;
      }
      var formData = {
        hash: this.prestazione,
        form: {
          survey_id: this.selectedSurvey,
        },
      };
      var self = this;
      supportService
        .attachSurveyToHealthService(formData)
        .then(function (response) {
          self.showBtn = false;
          self.service = response.data.data;
          window.scrollTo(0, 0);
          self.successAlert("Questionario associato con successo");
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile associare il questionario");
          console.log(err);
        });
    },
  },
};
</script>
