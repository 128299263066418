<template>
  <div class="text-left col-lg-9 col-sm-7">
    <!-- <div class="text-left col-lg-10 col-sm-12"> -->
    <div v-if="user.farmacia_preferita">
      <b-icon icon="heart-fill"></b-icon>
      <strong class="ml-1 fw-bolder">{{
        user.farmacia_preferita.ff_denominazione
      }}</strong>
      <br v-if="$root.isMobile" />
      <span v-else>, </span>
      <span class="ml-1 text-capitalize">
        {{ user.farmacia_preferita.indirizzo }}</span
      >
      <span
        class="ml-1 text-capitalize"
        v-if="user.farmacia_preferita.frazione"
      >
        {{ user.farmacia_preferita.frazione }}</span
      >
      <span v-else class="ml-1 text-capitalize">
        {{ user.farmacia_preferita.comune }}</span
      >
      <span class="mx-3">
        <span class="mx-2" v-if="user.farmacia_preferita.sito_web">
          <span title="Sito Web">
            <a :href="user.farmacia_preferita.sito_web" class="point">
              <b-icon icon="globe"></b-icon>
            </a>
          </span>
        </span>
        <span class="mx-2" v-if="user.farmacia_preferita.social_fb">
          <a :href="user.farmacia_preferita.social_fb" class="point">
            <b-icon icon="facebook"></b-icon>
          </a>
        </span>
        <span class="mx-2" v-if="user.farmacia_preferita.social_ig">
          <a :href="user.farmacia_preferita.social_ig" class="point">
            <b-icon icon="instagram"></b-icon>
          </a>
        </span>
        <span class="ml-2" v-if="user.farmacia_preferita.social_tg">
          <a :href="user.farmacia_preferita.social_tg" class="point"
            ><img
              src="/assets/telegram-logo.svg"
              alt="Telegram Logo"
              width="17px"
              height="17px"
              class="point mr-1 mb-1"
            />
          </a>
        </span>
      </span>

      <strong class="point ml-2" @click="$refs['modal-preferenze'].show()">
        <b-icon
          icon="pencil-square"
          font-scale="1.5"
          variant="primary"
        ></b-icon>
      </strong>
      <!-- <strong class="point ml-2" @click="$refs['modal-preferenze'].show()"
        >Modifica preferenza</strong
      > -->
    </div>
    <div v-else>
      <strong class="point" @click="$refs['modal-preferenze'].show()"
        >Scegli farmacia preferita</strong
      >
    </div>
    <b-modal
      size="xl"
      centered
      ref="modal-preferenze"
      title="Scegli nuova farmacia preferita"
      hide-footer
    >
      <SearchCompany
        v-on:new-selection="updatePreferenze($event)"
        class="px-0 px-md-3 py-3"
      />
    </b-modal>
  </div>
</template>
<script>
import SearchCompany from "@/components/home/SearchCompany.vue"
import { mapState, mapActions } from "vuex"
export default {
  name: "farmacia-preferita",
  components: {
    SearchCompany
  },
  computed: {
    ...mapState("utente", ["status", "user"])
  },
  data() {
    return {}
  },
  methods: {
    ...mapActions("utente", ["me"]),
    updatePreferenze(nuovaPreferita) {
      this.me()
      this.$refs["modal-preferenze"].hide()
    }
  }
}
</script>
