<template>
  <b-row>
    <b-container v-if="!selectedCompany">
      <b-row cols="1" class="text-left mb-3">
        <router-link
          class="fw-bolder text-primary no__hover"
          :to="{ name: 'CittadinoHome' }"
        >
          <b-icon icon="arrow-left-circle-fill" variant="primary"></b-icon>
          Homepage
        </router-link>
      </b-row>
      <b-row align-v="stretch" cols="4" class="my-1 py-2 d-flex flex-wrap">
        <b-col
          v-for="(azienda, ind) in user.my_circle"
          :key="ind"
          class="my-2 col-sm-12 col-md-3 col-12"
        >
          <CompanyCard
            :azienda="azienda"
            v-on:company-selection="selectCompany($event, ind)"
          />
        </b-col>
        <b-col class="d-flex flex-wrap align-items-stretch">
          <div class="mt-2 col-sm-12 col-md-3 d-flex align-items-center">
            <span @click="$refs['modal-preferenze'].show()">
              <b-iconstack font-scale="3">
                <b-icon stacked icon="circle-fill" variant="primary"></b-icon>
                <b-icon
                  stacked
                  icon="plus"
                  scale="0.8"
                  variant="white"
                ></b-icon>
                <b-icon stacked icon="circle" variant="primary"></b-icon>
              </b-iconstack>
            </span>
          </div>
        </b-col>
      </b-row>
      <!-- <b-row cols="1" class="my-1">
        <b-col class="d-flex flex-wrap align-items-stretch">
          <div
            v-for="(azienda, ind) in user.my_circle"
            :key="ind"
            class="mt-2 col-sm-12 col-md-3"
          >
            <CompanyCard
              :azienda="azienda"
              v-on:company-selection="selectCompany($event, ind)"
            />
          </div>
          <div class="mt-2 col-sm-12 col-md-3 d-flex align-items-center">
            <span @click="$refs['modal-preferenze'].show()">
              <b-iconstack font-scale="3">
                <b-icon stacked icon="circle-fill" variant="primary"></b-icon>
                <b-icon
                  stacked
                  icon="plus"
                  scale="0.8"
                  variant="white"
                ></b-icon>
                <b-icon stacked icon="circle" variant="primary"></b-icon>
              </b-iconstack>
            </span>
          </div>
        </b-col>
      </b-row> -->
    </b-container>
    <b-container v-else>
      <b-row cols="1" class="text-left mb-3">
        <span class="fw-bolder text-primary point" @click.prevent="backToView">
          <b-icon icon="arrow-left-circle-fill" variant="primary"></b-icon>
          Il mio circolo di cura
        </span>
      </b-row>
      <b-row cols="1" class="my-4" v-if="selectedCompany">
        <h6
          class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
        >
          {{ selectedCompany.denominazione }}
        </h6>
        <b-col class="d-flex flex-wrap mt-2">
          <b-row cols="2" class="text-left col-12">
            <b-col class="col-12 col-md-6">
              <h6 class="fw-bolder text-secondary my-2">Sede</h6>
              <p class="mb-0">
                {{ selectedCompany.indirizzo }} {{ selectedCompany.cap }},
                <span class="text-capitalize"
                  >{{ selectedCompany.frazione }}
                  {{ selectedCompany.comune }}</span
                >
              </p>
              <p class="mb-0 text-capitalize">
                {{ selectedCompany.provincia }} {{ selectedCompany.regione }}
              </p>
              <h6 class="fw-bolder text-secondary my-2">Contatti</h6>
              <p>
                <strong class="text-lowercase"> email: </strong>
                {{ selectedCompany.email }}
                <br />
                <strong class="text-lowercase">telefono: </strong>
                {{ selectedCompany.telefono ? selectedCompany.telefono : "-" }}
                <br />
                <span class="d-flex flex-wrap mt-3 align-items-center">
                  <span class="mr-2" v-if="selectedCompany.sito_web">
                    <a :href="selectedCompany.sito_web" class="point">
                      <b-icon icon="globe" class="mr-1"></b-icon> Sito Web
                    </a>
                  </span>
                  <span class="mx-2" v-if="selectedCompany.social_fb">
                    <a :href="selectedCompany.social_fb" class="point">
                      <b-icon icon="facebook"></b-icon>
                    </a>
                  </span>
                  <span class="mx-2" v-if="selectedCompany.social_ig">
                    <a :href="selectedCompany.social_ig" class="point">
                      <b-icon icon="instagram"></b-icon>
                    </a>
                  </span>
                  <span class="ml-2" v-if="selectedCompany.social_tg">
                    <a :href="selectedCompany.social_tg" class="point"
                      ><img
                        src="/assets/telegram-logo.svg"
                        alt="Telegram Logo"
                        width="17px"
                        height="17px"
                        class="point mr-1 mb-1"
                      />
                    </a>
                  </span>
                </span>
              </p>
            </b-col>
            <b-col class="col-12 col-md-6">
              <h6 class="fw-bolder text-secondary">Apertura</h6>
              <p
                v-if="
                  selectedCompany.apertura &&
                  selectedCompany.apertura.length > 0
                "
              >
                <span
                  v-for="(gg, index) in selectedCompany.apertura"
                  :key="index"
                >
                  <strong>{{ Object.keys(gg)[0] }}: </strong>
                  <strong v-if="gg[Object.keys(gg)[0]] == 'chiuso'"
                    >Chiuso</strong
                  >
                  <strong v-else>
                    {{ gg[Object.keys(gg)[0]]["mattina"] }}
                    {{ gg[Object.keys(gg)[0]]["pomeriggio"] }}</strong
                  >
                  <br />
                </span>
              </p>
              <p v-else>Non inseriti</p>
            </b-col>
          </b-row>
          <b-row cols="1" class="text-left col-12">
            <div class="my-2">
              <router-link
                tag="button"
                class="primary_gradient btn btn-primary py-1 rounded-pill fw-bolder"
                :to="{
                  name: 'Nuovo Ordine',
                  params: { type: 'nuova prenotazione' },
                  query: { cindex: circleIndex }
                }"
              >
                <b-icon icon="bag-fill" class="mr-1"></b-icon
                ><span class="fs-5 text-white spacing_1">Prenota</span>
              </router-link>
            </div>
            <div
              class="my-2"
              v-if="
                !user.farmacia_preferita ||
                selectedCompany.hashid !== user.farmacia_preferita.hashid
              "
            >
              <b-button
                @click="setAsPreferito"
                variant="secondary text-white"
                class="py-1 rounded-pill fw-bolder secondary_gradient"
              >
                <b-icon icon="heart-fill" class="mr-1"></b-icon>Imposta come
                preferito
              </b-button>
            </div>
            <div class="my-2">
              <b-button
                variant="danger text-white"
                class="py-1 rounded-pill fw-bolder"
                @click="removeRelationship"
              >
                <b-icon icon="x-circle-fill" class="mr-1"></b-icon>
                <!-- <b-icon icon="bookmark-x-fill" class="mr-1"></b-icon> -->
                Rimuovi dal mio circolo di cura
              </b-button>
            </div>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
    <b-modal
      size="xl"
      centered
      ref="modal-preferenze"
      title="Aggiungi al tuo circolo di cura"
      hide-footer
    >
      <SearchCompany
        v-on:new-selection="updatePreferenze($event)"
        class="px-3 py-3"
      />
    </b-modal>
  </b-row>
</template>
<script>
import { supportService } from "@/_services"
import { mapState, mapActions } from "vuex"
import CompanyCard from "@/components/prenotazioni/CompanyCard.vue"
import SearchCompany from "@/components/home/SearchCompany.vue"
export default {
  name: "CircoloCura",
  components: {
    CompanyCard,
    SearchCompany
  },
  data() {
    return {
      selectedCompany: null,
      circleIndex: null
    }
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert
    }),
    ...mapState("utente", ["status", "user"])
  },
  // watch:{
  //   showMainView:function(val){
  //     val === null;
  //   }
  // },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    ...mapActions("utente", ["me"]),
    selectCompany(company, index) {
      this.selectedCompany = company
      this.circleIndex = index
    },
    backToView() {
      this.selectedCompany = null
      this.circleIndex = null
    },
    removeRelationship() {
      var form = {
        company: this.selectedCompany.hashid
      }
      //console.log("removing selected");
      var self = this
      supportService
        .removeRelationshipWithCompany(form)
        .then(function (response) {
          self.me()
          self.backToView()
          window.scrollTo(0, 0)
          self.successAlert("Elemento rimosso correttamente!")
        })
        .catch(function (error) {
          window.scrollTo(0, 0)
          self.errorAlert(
            "non è stato possibile rimuovere l'elemento selezionato"
          )
          self.backToView()
        })
    },
    setAsPreferito() {
      var form = {
        company: this.selectedCompany.hashid,
        preferito: true
      }
      var self = this
      supportService
        .updateRelationshipWithCompany(form)
        .then(function (response) {
          self.me()
          self.backToView()
          window.scrollTo(0, 0)
          self.successAlert("preferenze aggiornate correttamente!")
        })
        .catch(function (error) {
          window.scrollTo(0, 0)
          self.errorAlert("non è stato possibile aggiornare le preferenze")
          self.backToView()
        })
    },
    updatePreferenze(nuovaPreferita) {
      this.me()
      this.$refs["modal-preferenze"].hide()
    }
  },
  created() {},
  mounted() {}
}
</script>
