<template>
  <div class="col-12 py-4">
    <div
      class="d-flex justify-content-between"
      v-if="
        !user.permessi.includes('gestire aziende propria regione') &&
        !user.permessi.includes('gestire aziende propria asl')
      "
    >
      <router-link
        class="fw-bolder text-primary no__hover"
        :to="{ name: 'attività e questionari' }"
      >
        <b-icon
          icon="arrow-left-circle-fill"
          variant="primary"
          v-b-tooltip.hover
          title="Indietro"
        ></b-icon>
        Indietro
      </router-link>
      <router-link
        class="fw-bolder text-primary no__hover"
        :to="{ name: 'gestione aziende' }"
      >
        Assegna attività
        <b-icon
          icon="arrow-right-circle-fill"
          variant="primary"
          v-b-tooltip.hover
          title="Gestione Aziende"
        ></b-icon>
      </router-link>
    </div>

    <div>
      <ActivityDetails
        v-if="activity"
        :activity="activity"
        :asAdmin="true"
        v-on:survey-list="aList($event)"
      />
    </div>
    <h6 class="text-prim-grad-1 fw-bolder text-left pb-1">
      Farmacie abilitate
    </h6>
    <!-- <b-row cols="2" cols-md="3" cols-lg="4" class="justify-items-start bg-light py-2"> -->
    <b-row align-v="end" class="justify-items-start flex-wrap bg-light py-2">
      <b-form-group
        label="Regione"
        label-for="regione"
        class="text-left ml-0 mr-auto col-6 col-md-4 col-lg-3 mb-1"
      >
        <b-form-select
          class="my-1 shadow_6 px-2"
          :options="regioni"
          name="regione"
          v-model="selected.regione"
          @change="getProvince(selected)"
          required
        >
          <b-form-select-option :value="null">Regione</b-form-select-option>
        </b-form-select>
      </b-form-group>
      <b-form-group
        label="Provincia"
        label-for="provincia"
        class="text-left ml-0 mr-auto col-6 col-md-4 col-lg-3 mb-1"
      >
        <b-form-select
          class="my-1 shadow_6 px-2"
          :options="province"
          name="provincia"
          v-model="selected.provincia"
          @change="getComuni(selected)"
          required
        >
          <b-form-select-option :value="null">Provincia</b-form-select-option>
        </b-form-select>
      </b-form-group>
      <b-form-group
        label="Comune"
        label-for="comune"
        class="text-left ml-0 mr-auto col-6 col-md-4 col-lg-3 mb-1"
      >
        <b-form-select
          class="my-1 shadow_6 px-2"
          name="comune"
          v-model="selected.comune"
          required
        >
          <b-form-select-option
            v-for="(com, ind) in comuni"
            :key="ind"
            :value="com"
            @change="setData"
            >{{ com.comune }}</b-form-select-option
          >
          <b-form-select-option :value="null">Comune</b-form-select-option>
        </b-form-select>
      </b-form-group>
      <b-form-group
        label="Codice Tracciabilità"
        label-for="codice_tracciabilita"
        class="text-left ml-0 mr-auto col-6 col-md-4 col-lg-3 mb-1"
      >
        <b-form-input
          class="my-1 shadow_6 px-2"
          name="codice_tracciabilita"
          placeholder="..."
          type="number"
          v-model="filters.codice_tracciabilita"
          @change="getAbilitate"
        >
        </b-form-input>
      </b-form-group>

      <b-form-group
        label="Questionari/Servizi"
        label-for="questionario"
        class="text-left ml-0 mr-auto col-6 col-md-4 col-lg-9 mb-1"
      >
        <b-form-select
          class="my-1 shadow_6 px-2"
          name="questionario"
          v-model="filters.survey"
          required
          @change="getAbilitate"
        >
          <b-form-select-option
            v-for="(surv, ind) in surveys"
            :key="ind"
            :value="surv.name"
            >{{ surv.name }}</b-form-select-option
          >
          <b-form-select-option :value="null">Tutti</b-form-select-option>
        </b-form-select>
      </b-form-group>
      <b-form-group
        label="Codice Regionale"
        label-for="codice_regionale"
        class="text-left ml-0 mr-auto col-6 col-md-4 col-lg-3 mb-1"
      >
        <b-form-input
          class="my-1 shadow_6 px-2"
          name="codice_regionale"
          placeholder="..."
          type="text"
          v-model="filters.codice_regionale"
          @change="getAbilitate"
        >
        </b-form-input>
      </b-form-group>

      <b-form-group
        label="ASL/ASST assistito"
        label-for="patient_asl"
        class="text-left ml-0 mr-auto col-6 col-md-6 col-lg-6 mb-1"
      >
        <b-form-select
          class="my-1 shadow_6 px-2"
          name="patient_asl"
          v-model="filters.patient_asl"
          required
          @change="getAbilitate"
        >
          <b-form-select-option
            v-for="(aslInfo, ind) in asl"
            :key="'asl_' + ind"
            :value="aslInfo"
            >{{ aslInfo }}</b-form-select-option
          >
          <b-form-select-option :value="null">tutte</b-form-select-option>
        </b-form-select>
      </b-form-group>
      <b-form-group
        label="ASL/ATS farmacia"
        label-for="company_asl"
        class="text-left ml-0 mr-auto col-6 col-md-6 col-lg-6 mb-1"
      >
        <b-form-select
          class="my-1 shadow_6 px-2"
          name="company_asl"
          v-model="filters.company_asl"
          required
          @change="getAbilitate"
        >
          <b-form-select-option
            v-for="aslInfo in asl"
            :key="aslInfo"
            :value="aslInfo"
            >{{ aslInfo }}</b-form-select-option
          >
          <b-form-select-option :value="null">tutte</b-form-select-option>
        </b-form-select>
      </b-form-group>
      <b-form-group
        label="Livello criticità"
        label-for="threshold_level"
        class="text-left ml-0 mr-auto col-6 col-md-6 col-lg-3 mb-1"
      >
        <b-form-select
          class="my-1 shadow_6 px-2"
          name="threshold_level"
          v-model="filters.threshold_level"
          required
          @change="getAbilitate"
        >
          <b-form-select-option
            v-for="(level, ind) in thresholdOptions"
            :key="level.key"
            :value="level.value"
            >{{ level.label }}</b-form-select-option
          >
          <b-form-select-option :value="null">Tutti</b-form-select-option>
        </b-form-select>
      </b-form-group>

      <b-form-group
        label="Inserimenti dal"
        label-for="addfrom"
        class="text-left ml-0 mr-auto col-6 col-md-4 col-lg-3 mb-1"
      >
        <b-form-input
          :max="todayLimit"
          class="my-1 shadow_6 px-2"
          name="addfrom"
          type="date"
          v-model="filters.addfrom"
          @change="getAbilitate"
        >
        </b-form-input>
      </b-form-group>
      <b-form-group
        label="Inserimenti al"
        label-for="addto"
        class="text-left ml-0 mr-auto col-6 col-md-4 col-lg-3 mb-1"
      >
        <b-form-input
          :max="todayLimit"
          class="my-1 shadow_6 px-2"
          name="addto"
          type="date"
          v-model="filters.addto"
          @change="getAbilitate"
        >
        </b-form-input>
      </b-form-group>
      <b-form-group
        label=""
        label-for="show_all"
        class="text-left ml-0 mr-auto col-6 col-md-6 col-lg-3 mb-1 px-1"
      >
        <b-form-checkbox
          v-if="filters.addto || filters.addfrom"
          id="show_all"
          v-model="filters.all"
          name="show_all"
          :value="1"
          :unchecked-value="0"
          @change="getAbilitate"
        >
          Mostra tutte le farmacie abilitate
        </b-form-checkbox>
      </b-form-group>

      <b-form-group
        v-if="
          !user.permessi.includes('gestire aziende propria regione') &&
          !user.permessi.includes('gestire aziende propria asl')
        "
        label="Associazione"
        label-for="associazione"
        class="text-left ml-0 mr-auto col-6 col-md-6 col-lg-3 mb-1"
      >
        <b-form-select
          class="my-1 shadow_6 px-2"
          name="associazione"
          v-model="filters.associazione"
          required
          @change="getAbilitate"
        >
          <b-form-select-option
            v-for="(associazione, ind) in associazioneOptions"
            :key="associazione.key"
            :value="associazione.value"
            >{{ associazione.label }}</b-form-select-option
          >
        </b-form-select>
      </b-form-group>
      <!-- <b-col></b-col> -->
      <b-col class="text-right">
        <b-button
          variant="warning"
          class="m-md-2 fw-bolder text-white"
          @click="resetFilters"
          >Reset
        </b-button>
        <!-- </b-col>
      <b-col class="px-0"> -->
        <b-dropdown
          id="dropdown-downloader"
          text="Download selezione"
          variant="primary primary_gradient text-white fw-bolder spacing_1"
          class="m-md-2"
        >
          <template v-if="user.permessi.includes('vedere dati cittadini')">
            <b-dropdown-item @click="getReport">PDF in chiaro</b-dropdown-item>
            <b-dropdown-item @click="getReportXsl"
              >excel in chiaro</b-dropdown-item
            >
          </template>
          <b-dropdown-item @click="getReportPseudo"
            >PDF pseudoanonimo</b-dropdown-item
          >
          <b-dropdown-item @click="getReportPseudoXsl"
            >excel pseudoanonimo</b-dropdown-item
          >
          <b-dropdown-item @click="getReportAggregatoXsl"
            >excel in forma aggregata</b-dropdown-item
          >
        </b-dropdown>
      </b-col>
      <!-- <b-form-group label="Ragione sociale" label-for="denominazione" class="text-left mx-auto col mb-1">
        <b-form-input class="my-1 shadow_6" name="denominazione" type="text" placeholder="ragione sociale..."
          v-model="filters.denominazione" minlength="5"></b-form-input>
      </b-form-group>
      <b-form-group label="Codice Tracciabilità" label-for="codice_tracciabilita" class="text-left mx-auto col mb-1">
        <b-form-input class="my-1 shadow_6" name="codice_tracciabilita" type="text"
          placeholder="codice tracciabilità..." v-model="filters.codice_tracciabilita"></b-form-input>
      </b-form-group>
      <b-col></b-col>
      <b-col></b-col> -->
    </b-row>
    <b-row align-v="end" class="justify-content-between">
      <b-col class="text-left">
        <span class="text-info fw-bolder mr-1" v-if="total > 0"
          >{{ fromPage }}-{{ toPage }} di
        </span>
        <span class="text-info fw-bolder"> {{ total }}</span>
      </b-col>
      <b-col cols="6" md="4" lg="3">
        <b-form-group
          label="N°righe mostrate"
          label-for="vedi"
          class="text-left ml-auto mr-0 col mb-1"
        >
          <b-form-select
            class="my-1 shadow_6 px-2"
            name="vedi"
            v-model="filters.take"
            :options="takeOptions"
            @change="getAbilitate"
          >
          </b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
    <div>
      <b-table
        hover
        borderless
        responsive
        table-variant="white text-info text-capitalize text-left row__font"
        :fields="fields"
        :items="abilitate"
        small
        thead-class=" border-medium-grey text-left"
        tbody-class=""
        ref="abilitateTable"
        tbody-tr-class="text-black border-bottom border-medium-grey"
        :busy="busyTable"
      >
        <template #table-busy>
          <div class="text-center text-info my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
        <template #cell(enabled)="row">
          <p class="mb-0">
            <b-icon
              icon="circle-fill"
              :variant="row.item.enabled == 1 ? 'success' : 'danger'"
            ></b-icon>
          </p>
        </template>
        <template #cell(dottorfarma)="row">
          <p class="mb-0" v-if="row.item.dottorfarma === 1">
            <span>Federfarma</span>
          </p>
          <p class="mb-0" v-else>
            <span v-if="row.item.farmacieunite === 1">Farmacie Unite</span>
            <span v-else>Altra associazione</span>
          </p>
        </template>
        <template #cell(updated_at)="row">
          <span>{{ row.item.updated_at | moment("DD/MM/YYYY hh:mm") }}</span>
        </template>
        <template #cell(azioni)="row">
          <b-row cols="1" class="d-flex text-center flex-wrap">
            <b-button
              size="sm"
              variant="info info_gradient rounded-pill fw-bolder px-3 fs-6 m-1"
              @click="row.toggleDetails"
            >
              {{ row.detailsShowing ? "-" : "+" }} dettagli
            </b-button>
            <router-link
              :to="{
                name: 'report farmacia',
                params: { company: row.item.hashid, activity: activity.hashid },
              }"
              tag="b-button"
              class="primary primary_gradient fw-bolder rounded-pill text-white fs-6 btn-sm m-1"
              >Report</router-link
            >
            <!-- <template
              v-if="
                !user.permessi.includes('gestire aziende propria regione') &&
                !user.permessi.includes('gestire aziende propria asl')
              "
            >
              <router-link
                :to="{
                  name: 'dettaglio azienda',
                  params: { company: row.item.hashid },
                }"
                tag="b-button"
                class="primary primary_gradient fw-bolder rounded-pill text-white fs-6 btn-sm m-1"
                >Panoramica</router-link
              >
            </template> -->
          </b-row>
        </template>
        <template #row-details="row">
          <b-card
            :header="'Dettaglio ' + row.item.denominazione"
            header-text-variant="secondary fw-bolder"
            header-bg-variant="white"
            header-tag="h4"
            class="text-left text-font-grey"
          >
            <b-card-text class="text-left">
              <b-row cols="2">
                <b-col>
                  <h5 class="fw-bolder text-secondary">Sede</h5>
                  <p class="mb-0">
                    {{ row.item.indirizzo }} {{ row.item.cap }},
                    {{ row.item.frazione }} {{ row.item.comune }}
                  </p>
                  <p class="mb-0">
                    {{ row.item.provincia }} {{ row.item.regione }}
                  </p>
                  <p class="">
                    <strong class="text-lowercase">codice asl: </strong>
                    {{ row.item.codice_asl }}
                    <strong class="ml-2 text-lowercase">comunale: </strong>
                    {{ row.item.comunale == 1 ? "sì" : "no" }}
                    <strong class="ml-2 text-lowercase">azienda: </strong>
                    {{ row.item.azienda == 1 ? "sì" : "no" }}
                  </p>
                  <h5 class="fw-bolder text-secondary">Titolare</h5>
                  <p class="mb-0 text-capitalize">
                    {{ row.item.nome }} {{ row.item.cognome }}
                  </p>
                  <p class="mb-0 text-uppercase">
                    <strong class="text-lowercase">codice fiscale: </strong>
                    {{ row.item.codice_fiscale }}
                    <strong class="ml-2 text-lowercase">partita iva:</strong>
                    {{ row.item.partita_iva }}
                  </p>
                  <p class="mb-0 text-uppercase">
                    <strong class="text-lowercase">codice titolare: </strong>
                    {{ row.item.codice_titolare }}
                    <strong class="ml-2 text-lowercase"
                      >codice tracciabilità:
                    </strong>
                    {{ row.item.codice_tracciabilita }}
                  </p>
                  <p class="text-uppercase">
                    <strong class="text-lowercase">codice regionale: </strong>
                    {{ row.item.codice_regionale }}
                  </p>
                  <h5 class="fw-bolder text-secondary">Contatti</h5>
                  <p class="text-uppercase">
                    <strong class="text-lowercase">email: </strong>
                    {{ row.item.email }}
                    <strong class="ml-2 text-lowercase">pec: </strong>
                    {{ row.item.emailpec }}<br />
                    <strong class="text-lowercase">telefono: </strong>
                    {{ row.item.telefono }}
                    <strong class="ml-2 text-lowercase">fax: </strong>
                    {{ row.item.fax }}
                  </p>
                </b-col>
                <b-col>
                  <h5 class="fw-bolder text-secondary">
                    Soggetti
                    <span class="text-lowercase">
                      registrati dalla farmacia su</span
                    >
                    DottorFARMA
                  </h5>
                  <p>Tot: {{ row.item.tot_arruolati_sistema }}</p>
                  <h5 class="fw-bolder text-secondary">
                    Assistiti
                    <span class="text-lowercase">
                      arruolati per l'attività/servizio</span
                    >
                  </h5>
                  <p>Tot: {{ row.item.tot_arruolati }}</p>
                  <h5 class="fw-bolder text-secondary">
                    Questionari somministrati/Servizi erogati
                  </h5>
                  <p>Tot: {{ row.item.tot_filled }}</p>

                  <div
                    v-if="
                      !user.permessi.includes(
                        'gestire aziende propria regione'
                      ) &&
                      !user.permessi.includes('gestire aziende propria asl')
                    "
                  >
                    <router-link
                      :to="{
                        name: 'dettaglio azienda',
                        params: { company: row.item.hashid },
                      }"
                      tag="b-button"
                      class="primary primary_gradient fw-bolder text-white"
                      >Vai alla panoramica della farmacia
                    </router-link>
                  </div>
                </b-col>
              </b-row>
            </b-card-text>
          </b-card>
        </template>
      </b-table>
      <p class="mb-0" v-if="abilitate !== null && abilitate.length == 0">
        Nessun risultato.
      </p>
      <b-pagination
        v-model="filters.page"
        pills
        :total-rows="total"
        :per-page="filters.take"
        align="center"
      >
      </b-pagination>
    </div>
  </div>
</template>
<script>
import * as moment from "moment/moment";
import { utilityService, adminService } from "@/_services";
import { mapState, mapActions, mapGetters } from "vuex";
import NuovaAttivita from "@/components/admin/attivita/NuovaAttivita.vue";
import AdminQuestionari from "@/components/admin/attivita/AdminQuestionari.vue";
import ActivityDetails from "@/components/admin/attivita/ActivityDetails.vue";
import { format } from "path";
export default {
  name: "AbilitateAttivita",
  components: { NuovaAttivita, AdminQuestionari, ActivityDetails },
  data() {
    const today = moment().format("YYYY-MM-DD");
    return {
      // fields: [
      //   {
      //     key: "denominazione",
      //     label: "Ragione Sociale",
      //     class: "text-justify",
      //   },
      //   { key: "regione", label: "Regione" },
      //   { key: "provincia", label: "Provincia" },
      //   { key: "comune", label: "Comune" },
      //   { key: "codice_tracciabilita", label: "Codice Tracciabilità" },
      //   { key: "codice_regionale", label: "Codice Regionale" },
      //   { key: "dottorfarma", label: "Associazione" },
      //   { key: "tot_arruolati", label: "Assist. Arruolati" },
      //   // { key: "tot_arruolati_sistema", label: "Paz. Arruolati Sistema" },
      //   { key: "tot_filled", label: "Tot Somministrati" },
      //   { key: "azioni", label: "" },
      // ],
      filters: {
        page: 1,
        take: 10,
        comune: null,
        provincia: null,
        regione: null,
        xls: undefined,
        survey: null,
        addto: null,
        addfrom: null,
        codice_tracciabilita: null,
        all: 1,
        threshold_level: null,
        patient_asl: null,
        company_asl: null,
        codice_regionale: null,
        associazione: null,
      },
      selected: {
        comune: null,
        provincia: null,
        regione: null,
      },
      takeOptions: [10, 25, 50, 100, 150, 200, 250, 300],
      total: 0,
      fromPage: 0,
      toPage: 0,
      abilitate: null,
      activity: null,
      surveys: null,
      busyTable: true,
      todayLimit: today,
      thresholdOptions: [
        { key: "assente", value: 1, label: "assente/bassa" },
        { key: "presente", value: 3, label: "media/alta" },
      ],
      associazioneOptions: [
        { key: "tutte", value: null, label: "tutte" },
        { key: "Federfarma", value: "FF", label: "Federfarma" },
        { key: "Farmacie Unite", value: "FU", label: "Farmacie Unite" },
        { key: "altro", value: "altro", label: "Altra associazione" },
      ],
      aslList: null,
      basicFields: [
        {
          key: "denominazione",
          label: "Ragione Sociale",
          class: "text-justify",
        },
        { key: "regione", label: "Regione" },
        { key: "provincia", label: "Provincia" },
        { key: "comune", label: "Comune" },
        { key: "codice_tracciabilita", label: "Codice Tracciabilità" },
        { key: "codice_regionale", label: "Codice Regionale" },
        { key: "tot_arruolati", label: "Assist. Arruolati" },
        { key: "tot_filled", label: "Tot Somministrati" },
        { key: "azioni", label: "" },
      ],
    };
  },
  watch: {
    selected: {
      handler(val) {
        if (val.comune !== null) {
          this.filters.comune = val.comune.comune;
        }
        this.filters.regione = val.regione;
        this.filters.provincia = val.provincia;
        var self = this;
        this.$nextTick(function () {
          self.getAbilitate();
        });
      },
      deep: true,
    },
    "filters.page": function (val) {
      var self = this;
      this.$nextTick(function () {
        self.getAbilitate();
        window.scrollTo(0, 0);
      });
    },
    "filters.addfrom": function (val) {
      if (val) {
        this.filters.all = 0;
      }
    },
    "filters.addto": function (val) {
      if (val) {
        this.filters.all = 0;
      }
    },
  },
  computed: {
    fields: function () {
      if (this.user) {
        if (
          !this.user.permessi.includes("gestire aziende propria regione") &&
          !this.user.permessi.includes("gestire aziende propria asl")
        ) {
          return [
            {
              key: "denominazione",
              label: "Ragione Sociale",
              class: "text-justify",
            },
            { key: "regione", label: "Regione" },
            { key: "provincia", label: "Provincia" },
            { key: "comune", label: "Comune" },
            { key: "codice_tracciabilita", label: "Codice Tracciabilità" },
            { key: "codice_regionale", label: "Codice Regionale" },
            { key: "dottorfarma", label: "Associazione" },
            { key: "tot_arruolati", label: "Assist. Arruolati" },
            { key: "tot_filled", label: "Tot Somministrati" },
            { key: "con_prescrizione", label: "Con Prescrizione" },
            { key: "azioni", label: "" },
          ];
        }
        return this.basicFields;
      }
      return this.basicFields;
    },
    ...mapState({
      alert: (state) => state.alert,
    }),
    ...mapState("utente", ["status", "user"]),
    ...mapState("utility", [
      "searching",
      "comuni",
      "province",
      "regioni",
      "asl",
    ]),
  },
  methods: {
    ...mapActions("utility", [
      "getComuni",
      "getProvince",
      "getRegioni",
      "getAsl",
    ]),
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    resetFilters() {
      this.filters = {
        page: 1,
        take: 10,
        comune: null,
        provincia: null,
        regione: null,
        xls: undefined,
        survey: null,
        addto: null,
        addfrom: null,
        codice_tracciabilita: null,
        all: 1,
        threshold_level: null,
        patient_asl: null,
        company_asl: null,
        codice_regionale: null,
        associazione: null,
      };
      this.selected = {
        comune: null,
        provincia: null,
        regione: null,
      };
    },
    aList(surveyList) {
      this.surveys = surveyList;
    },
    setData() {
      if (this.selected.comune) {
        this.filters.comune = this.selected.comune.comune;
      }
      this.filters.regione = this.selected.regione;
      this.filters.provincia = this.selected.provincia;
      this.getAbilitate();
    },
    fetchData() {
      const self = this;
      adminService
        .getActivityById(this.$route.params.activity)
        .then(function (res) {
          self.activity = res.data.data;
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare le attività");
          console.log(err);
        });
      //
    },
    showAbilitate() {
      this.getAbilitate();
      this.$bvModal.show("modal-comp-list");
    },
    getAbilitate() {
      this.busyTable = true;
      var self = this;
      var dataFilter = {
        hash: this.$route.params.activity,
        filters: this.filters,
      };
      adminService
        .getAbilitateForActivity(dataFilter)
        .then(function (res) {
          self.abilitate = res.data.data.data;
          self.busyTable = false;
          self.filters.page = res.data.data.current_page;
          self.total = res.data.data.total;
          self.fromPage = res.data.data.from;
          self.toPage = res.data.data.to;
          self.perPage = res.data.data.per_page;
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare le attività");
          console.log(err);
          self.busyTable = false;
        });
    },
    getReport() {
      var today = moment().format("DD/MM/YYYY");
      var self = this;
      adminService
        .reportActivityPdf(this.$route.params.activity, this.filters)
        .then(function (response) {
          if (response.status === 201) {
            self.successAlert(
              "Report in fase di generazione. Riceverai il report via email non appena sarà pronto."
            );
          } else {
            let blob = new Blob([response.data]);
            // let blob = new Blob([response.data], { type: "text/csv" });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = "report_" + today + ".pdf";
            link.click();
          }
        })
        .catch(function (err) {
          console.log(err);
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare i report");
        });
    },
    getReportXsl() {
      var today = moment().format("DD/MM/YYYY");
      var self = this;
      adminService
        .reportActivityXls(this.$route.params.activity, this.filters)
        .then(function (response) {
          // console.log(response)
          if (response.status === 201) {
            self.successAlert(
              "Report in fase di generazione. Riceverai il report via email non appena sarà pronto."
            );
          } else {
            let blob = new Blob([response.data]);
            // let blob = new Blob([response.data], { type: "text/csv" });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = "report_" + today + ".xlsx";
            link.click();
          }
        })
        .catch(function (err) {
          console.log(err);
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare i report");
        });
    },
    getReportPseudo() {
      var today = moment().format("DD/MM/YYYY");
      var self = this;
      adminService
        .reportActivityPseudonymPdf(this.$route.params.activity, this.filters)
        .then(function (response) {
          if (response.status === 201) {
            self.successAlert(
              "Report in fase di generazione. Riceverai il report via email non appena sarà pronto."
            );
          } else {
            let blob = new Blob([response.data]);
            // let blob = new Blob([response.data], { type: "text/csv" });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = "report_pseudoanonimo_" + today + ".pdf";
            link.click();
          }
        })
        .catch(function (err) {
          console.log(err);
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare i report");
        });
    },
    getReportPseudoXsl() {
      var today = moment().format("DD/MM/YYYY");
      var self = this;
      adminService
        .reportActivityPseudonymXls(this.$route.params.activity, this.filters)
        .then(function (response) {
          if (response.status === 201) {
            self.successAlert(
              "Report in fase di generazione. Riceverai il report via email non appena sarà pronto."
            );
          } else {
            let blob = new Blob([response.data]);
            // let blob = new Blob([response.data], { type: "text/csv" });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = "report_pseudoanonimo_" + today + ".xlsx";
            link.click();
          }
        })
        .catch(function (err) {
          console.log(err);
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare i report");
        });
    },
    getReportAggregatoXsl() {
      var today = moment().format("DD/MM/YYYY");
      var self = this;
      adminService
        .reportActivityAggregatoXls(this.$route.params.activity, this.filters)
        .then(function (response) {
          let blob = new Blob([response.data]);
          // let blob = new Blob([response.data], { type: "text/csv" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "report_aggregato_" + today + ".xlsx";
          link.click();
        })
        .catch(function (err) {
          console.log(err);
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare i report");
        });
    },
  },
  created() {
    if (this.$route.params.activity) {
      this.fetchData();
      this.getAbilitate();
      this.getRegioni();
      this.getAsl({ denominazione: true });
    }
  },
  // mounted() {
  //   if (
  //     this.user.permessi.includes("gestire aziende propria regione") ||
  //     this.user.permessi.includes("gestire aziende propria asl")
  //   ) {
  //     if (this.user.anagrafica && this.user.anagrafica.regione) {
  //       console.log("inif", this.user.anagrafica);
  //       this.filters.regione = this.user.anagrafica.regione;
  //       this.getAbilitate();
  //     }
  //   }
  // },
};
</script>
