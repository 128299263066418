<template>
  <div>
    <b-row cols="8">
      <b-col></b-col>
      <b-col></b-col>
      <b-col></b-col>
      <b-col>
        <b-icon stacked icon="eyeglasses" animation="cylon" variant="secondary" scale="0.75"></b-icon>
      </b-col>
      <b-col></b-col>
      <b-col></b-col>
      <b-col></b-col>
    </b-row>
    <h5>Pagina non trovata</h5>
    <p></p>

  </div>
</template>
<script>
export default {
  name: "NotFoundPage",
  data() {
    return {
    };
  },
};
</script>
