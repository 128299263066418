<template>
  <div>
    <b-row class="text-left my-2">
      <b-col class="col-12 col-md-4">
        <b-form-group>
          <b-form-radio class="font-weight-bold" v-model="selectedSubject" aria-describedby="mestesso" name="sub-me"
            :value="0">Per me</b-form-radio>
        </b-form-group>
      </b-col>
      <b-col class="col-12 col-md-4">
        <b-form-group>
          <b-form-radio class="font-weight-bold" v-model="selectedSubject" aria-describedby="miofamiliare"
            name="sub-fam" :value="1" :disabled="familymemberList.length == 0">Per un mio familiare</b-form-radio>
        </b-form-group>
      </b-col>
      <b-col class="col-12 col-md-4">
        <b-form-group>
          <b-form-radio class="font-weight-bold" v-model="selectedSubject" aria-describedby="mioassistito"
            name="sub-ass" :value="2" :disabled="assisted.length == 0">Per un mio assistito</b-form-radio>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="my-3 py-2" v-if="selectedSubject === 1">
      <b-col>
        <b-form-group label="Seleziona familiare" label-for="type" class="text-left fs-6 text-secondary">
          <b-form-select name="type" class="mx-auto shadow_6 select__dis" v-model="subjectable.subject_id" required
            @change="emitSubject">
            <b-form-select-option v-for="(familiare, ind) in familymemberList" :key="ind" :value="familiare.hashid">{{
                familiare.name
            }} {{ familiare.surname }} ({{
    familiare.relationship
}})</b-form-select-option>
            <b-form-select-option :value="null" disabled>scegli familiare</b-form-select-option>
          </b-form-select>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row class="my-3 py-2" v-else-if="selectedSubject === 2">
      <b-col>
        <b-form-group label="Seleziona assistito" label-for="type" class="text-left fs-6 text-secondary">
          <b-form-select name="type" class="mx-auto shadow_6 select__dis" v-model="subjectable.subject_id" required
            @change="emitSubject">
            <b-form-select-option v-for="(assistito, ind) in assisted" :key="ind" :value="assistito.hashid">{{
                assistito.anagrafica.name
            }}
              {{ assistito.anagrafica.surname }}
            </b-form-select-option>
            <b-form-select-option :value="null" disabled>scegli assistito</b-form-select-option>
          </b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { cittadinoService } from "@/_services";
export default {
  name: "SubjectSelection",
  data() {
    return {
      selectedSubject: 0,
      familymemberList: [],
      assisted: [],
      subjectable: {
        subject_id: null,
        subject_type: "user_in_app",
      },
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
    ...mapState("utente", ["status", "user"]),
  },
  watch: {
    selectedSubject: function (val) {
      switch (val) {
        case 0:
          this.subjectable.subject_id = this.user.hashid;
          this.subjectable.subject_type = "user_in_app";
          break;
        case 1:
          this.subjectable.subject_id = null;
          this.subjectable.subject_type = "familiare";
          break;
        case 2:
          this.subjectable.subject_id = null;
          this.subjectable.subject_type = "user_in_app";
          break;
      }
      this.emitSubject();
    },
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    emitSubject() {
      this.$emit("selected-subject", this.subjectable);
    },
    getFamilyMembers() {
      var self = this;
      cittadinoService
        .getFamilyMembers()
        .then(function (res) {
          self.familymemberList = res.data.data;
        })
        .catch(function (err) {
          console.log(err);
          self.errorAlert(
            "Non è stato possibile recuperare le informazioni richieste"
          );
        });
    },
    getCaregivers() {
      var parms = {
        only: "given",
      };
      var self = this;
      cittadinoService
        .getCaregivers(parms)
        .then(function (res) {
          self.assisted = res.data.data;
        })
        .catch(function (err) {
          console.log(err);
          var msg = "Non è stato possibile recuperare le informazioni.";
          window.scrollTo(0, 0);
          self.errorAlert(msg);
        });
    },
  },
  created() {
    this.getFamilyMembers();
    this.getCaregivers();
  },
};
</script>