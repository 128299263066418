<template>
  <b-container v-if="abilitata === true">
    <div v-if="plans !== null">
      <b-row cols="2" class="justify-content-end">
        <b-col> <b-spinner v-if="searching"></b-spinner></b-col>
        <b-col>
          <b-input-group
            size="sm"
            class="mt-2 ml-auto mr-0 shadow_6 rounded-pill border border-prim-grad-1"
          >
            <b-form-input
              class="align-items-center align-content-center text-uppercase rounded-pill border-right-0"
              name="req-code"
              v-model="filters.search"
              type="search"
              placeholder="Cerca identificativo.."
              @input="handleSearch"
            >
            </b-form-input>
            <b-input-group-append>
              <span class="mx-2">
                <b-icon icon="search"></b-icon>
              </span>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <p v-if="filters.search && plans.length == 0">
        La ricerca non ha prodotto risultati.
      </p>
      <b-row
        v-for="status in Object.keys(plans)"
        :key="status"
        class="mt-4"
        cols="1"
      >
        <h6
          class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
        >
          <span v-b-toggle="'det_stat_' + status">
            <b-icon icon="chevron-down"></b-icon>
          </span>
          Piani {{ status > 0 ? statiPT[status] : statiPT[status.toString()] }}
        </h6>
        <b-collapse :visible="status > 0" :id="'det_stat_' + status">
          <b-row
            cols="1"
            v-for="plan in plans[status]"
            align-v="stretch"
            class="my-2 col-12 px-0 align-items-center align-content-center flex-wrap my-2 bg-light shadow_6 px-2 py-1 border border-grey"
          >
            <b-row class="justify-content-between">
              <b-col class="px-0">
                <p class="fw-bolder text-left">
                  <span v-b-toggle="'det_' + plan.codice_richiesta">
                    <b-icon icon="chevron-down"></b-icon>
                  </span>
                  Piano Terapeutico #{{ plan.codice_richiesta }} valido dal
                  {{ plan.valid_from | moment("DD/MM/YYYY") }}
                  <span v-if="plan.valid_until"
                    >al {{ plan.valid_until | moment("DD/MM/YYYY") }}</span
                  >
                </p>
              </b-col>
              <b-col class="px-0 text-right" cols="2" md="2" lg="2" xl="2">
                <template v-if="user.type === 1">
                  <b-button
                    v-if="plan.status === 1"
                    @click="confirmDeletePiano(plan.hashid)"
                    size="sm"
                    variant="outline-danger"
                    class="m-1"
                    ><b-icon icon="trash"></b-icon>
                  </b-button>
                  <b-button
                    v-else-if="plan.status === 4"
                    @click="startRenewModal(plan)"
                    size="sm"
                    variant="info"
                    class="m-1"
                    v-b-tooltip.hover
                    title="Rinnovo fornitura"
                    ><b-icon icon="plus"></b-icon>
                  </b-button>
                  <template v-if="plan.status >= 3 && plan.survey">
                    <b-button
                      v-if="!plan.filled_survey"
                      @click="startSurveyModal(plan)"
                      size="sm"
                      variant="primary"
                      class="m-1"
                      v-b-tooltip.hover
                      title="Somministra questionario"
                      ><b-icon icon="clipboard-plus"></b-icon>
                    </b-button>
                    <span
                      class="m-1 px-2 pb-2 pt-1 bg-white btn__radius"
                      v-else
                      v-b-tooltip.hover
                      title="Questionario completato"
                    >
                      <b-icon
                        class="align__middle"
                        font-scale="1.25"
                        icon="clipboard-check"
                        variant="info"
                      ></b-icon>
                    </span>
                  </template>
                </template>
                <b-button
                  @click="goToDetail(plan.hashid)"
                  size="sm"
                  variant="secondary"
                  class="m-1"
                  ><b-icon icon="eye"></b-icon>
                </b-button>
              </b-col>
            </b-row>
            <b-collapse
              :id="'det_' + plan.codice_richiesta"
              class="border-top border-grey"
            >
              <p class="my-2 text-left ml-3" v-if="plan.status === -4">
                <span class="text-danger">
                  Il piano è stato invalidato dall'Asl.</span
                >
                <span v-if="plan.invalidation_note"
                  >Motivazioni: {{ plan.invalidation_note }}</span
                >
              </p>
              <p class="my-2 text-left ml-3" v-if="plan.status === -5">
                <span class="text-danger">
                  Il rinnovo fornitura è stato rifiutato dall'Asl.</span
                >
                <span v-if="plan.invalidation_note"
                  >Motivazioni: {{ plan.invalidation_note }}</span
                >
              </p>
              <template>
                <!-- <template v-if="plan.included_therapies && user.type === 1">
                  <b-row
                    class="my-1 ml-3"
                    v-for="product in plan.included_therapies"
                    :key="product.hashid"
                  >
                    <p class="fw-bolder mb-0">
                      {{ product.therapy.product_name }}
                      <span
                        class="font-italic text-prim-grad-1"
                        v-if="product.tipo"
                      >
                        - {{ product.tipo }}</span
                      >
                    </p>
                    <template v-if="product.type >= 3">
                      <p class="ml-lg-4 ml-0 mb-0">
                        <label class="mx-lg-2 mx-1" for=""
                          >Quantità:
                          <span class="font-italic text-prim-grad-1">{{
                            product.quantity
                          }}</span>
                        </label>

                        <label class="mx-lg-2 mx-1" for=""
                          >Lotto:
                          <span class="font-italic text-prim-grad-1">{{
                            product.batch
                          }}</span></label
                        >
                        <label class="mx-lg-2 mx-1" for=""
                          >Scadenza:
                          <span class="font-italic text-prim-grad-1">{{
                            product.expiration | moment("DD-MM-YYYY")
                          }}</span></label
                        >
                      </p>
                    </template>
                  </b-row>
                </template> -->
                <template v-if="plan.deliveries.length > 0">
                  <b-row
                    class="fw-bolder text-left pl-2 border-top border-primary pt-2 mx-3"
                    v-for="(delivery, num) in plan.deliveries"
                    :key="num"
                  >
                    <b-col class="px-0">
                      <span class="text-primary"
                        >Pacchetto #{{ plan.deliveries.length - num }}</span
                      >
                      <template v-if="user.type === 1">
                        <CambiaStatoConsegna
                          class="d-inline ml-2"
                          v-if="
                            delivery.hashid &&
                            (delivery.status === 2 || delivery.status === 3)
                          "
                          :delivery="delivery"
                          :deliveryIndex="num"
                          @status-changed="handleDeliveryStatusChange"
                        ></CambiaStatoConsegna>
                      </template>
                    </b-col>
                    <b-col class="px-0">
                      <p class="mb-0 text-primary text-right text-uppercase">
                        {{ delivery.stato }}
                        <span class="" v-if="delivery.delivered_at">
                          il
                          {{
                            delivery.delivered_at | moment("DD/MM/YYYY HH:mm")
                          }}</span
                        >
                        <span class="" v-else-if="delivery.rejected_at">
                          il
                          {{
                            delivery.rejected_at | moment("DD/MM/YYYY HH:mm")
                          }}</span
                        >
                      </p>
                    </b-col>
                    <b-col cols="12">
                      <p class="mb-0" v-if="delivery.label">
                        <span> Etichetta: </span>
                        <span class="text-primary">{{ delivery.label }} </span>
                      </p>
                      <b-row
                        class="my-1"
                        v-for="product in delivery.product_list"
                        :key="product.hashid"
                      >
                        <p class="fw-bolder mb-0">
                          <span> Contenuto: </span>
                          {{ product.therapy.product_name }}
                          <span
                            class="font-italic text-prim-grad-1"
                            v-if="product.tipo"
                          >
                            - {{ product.tipo }}</span
                          >
                        </p>
                        <template v-if="product.type >= 3">
                          <p class="ml-lg-4 ml-0 mb-0">
                            <label class="mx-lg-2 mx-1" for=""
                              >Quantità:
                              <span class="font-italic text-prim-grad-1">{{
                                product.quantity
                              }}</span>
                            </label>

                            <label class="mx-lg-2 mx-1" for=""
                              >Lotto:
                              <span class="font-italic text-prim-grad-1">{{
                                product.batch
                              }}</span></label
                            >
                            <label class="mx-lg-2 mx-1" for=""
                              >Scadenza:
                              <span class="font-italic text-prim-grad-1">{{
                                product.expiration | moment("DD-MM-YYYY")
                              }}</span></label
                            >
                          </p>
                        </template>
                      </b-row>
                    </b-col>
                    <b-col
                      cols="12"
                      v-if="delivery.asl_notes && user.type === 1"
                    >
                      <p class="">
                        Note Asl:
                        <span class="font-italic fs-5">{{
                          delivery.asl_notes
                        }}</span>
                      </p>
                    </b-col>
                  </b-row>
                </template>
                <b-row v-else>
                  <p class="my-1 fs-5 ml-3">
                    Ancora nessun pacchetto previsto per questo piano.
                  </p>
                </b-row>
              </template>
            </b-collapse>
          </b-row>
          <p class="text-left" v-if="plans[status].length === 0">
            Nessun elemento tra i piani {{ statiPT[status] }}
          </p>
        </b-collapse>
      </b-row>
    </div>
    <b-spinner v-else></b-spinner>

    <div class="my-3" v-if="user.type === 1">
      <b-row
        align-v="stretch"
        class="my-2 col-12 px-0 align-items-center align-content-center flex-wrap my-2"
      >
        <b-col class="text-left">
          <span
            class="d-flex align-items-center point"
            @click="$bvModal.show('new_plan')"
          >
            <b-iconstack font-scale="3">
              <b-icon
                class="shadow_7"
                stacked
                icon="circle-fill"
                variant="secondary"
              ></b-icon>
              <b-icon stacked icon="plus" scale="0.5" variant="white"></b-icon>
              <b-icon stacked icon="circle" variant="secondary"></b-icon>
            </b-iconstack>
            <span class="fw-bolder text-secondary ml-2">Nuovo Piano</span>
          </span>
        </b-col>
      </b-row>
    </div>

    <b-modal
      id="new_plan"
      size="lg"
      centered
      title="Carica nuovo Piano Terapeutico"
      cancel-title="Annulla"
      cancel-variant="outline-danger px-3 rounded-pill font-weight-bolder"
      ok-title="Salva"
      ok-variant="primary primary_gradient text-white px-3 font-weight-bolder rounded-pill"
      hide-footer
    >
      <b-form id="new_plan_form" @submit.prevent="submitPlan">
        <p class="text-left text-medium-grey fw-bolder">
          Ricorda di inserire e verificare tra le terapie i farmaci prescritti
          prima di caricare il Piano Terapeutico. Si ricorda che per ottenere il
          rinnovo fornitura su piani terapeutici precedentemente caricati ed
          ancora in corso di validità occorre
          <u>non caricare un nuovo piano</u> ma utilizzare la funzione "rinnovo
          fornitura" sulla riga piano terapeutico completato.
          <span
            @click="showInfo = !showInfo"
            class="point text-left text-secondary"
            ><b-icon icon="info-circle" font-scale="1.5"></b-icon
          ></span>
        </p>
        <p
          class="text-justify transform_class"
          :class="showInfo ? 'bg-line-grey  py-2 px-3' : 'mb-0'"
        >
          <span v-if="showInfo"
            >Ad ogni nuovo Piano Terapeutico emesso e portato in farmacia,
            verificare ed aggiornare la scheda del paziente con particolare
            attenzione alle terapie, sezione nella quale vanno inseriti i
            farmaci prescritti nel Piano Terapeutico. Dopo aver aggiornato la
            scheda del paziente, procedere al caricamento del Piano Terapeutico;
            in seguito al caricamento del documento sarà necessario indicare le
            date di validita del Piano e selezionare il/i farmaci inclusi nel
            piano dal selettore "seleziona terapie". Clicca su "salva piano" per
            concludere l'operazione: l'ufficio Asl di competenza riceverà una
            notifica e prenderà in carico il Piano. Quando il farmaco sarà
            pronto per la consegna al paziente, l'Asl inoltrerà una notifica
            agli operatori della farmacia ed al paziente.</span
          >
        </p>
        <h5 class="text-left">Documento</h5>
        <RefertoUploader
          v-if="form.resource_id === null"
          dest="plans"
          v-on:upload-result="handleUploadResult($event)"
        ></RefertoUploader>
        <template v-else>
          <p class="text-left mb-0" v-if="uploadedFile">
            File "
            <span class="fw-bolder text-prim-grad-1">{{
              uploadedFile.name
            }}</span
            >" caricato correttamente.
          </p>
          <h5 class="text-left pt-3">Validità del Piano Terapeutico</h5>
          <b-row class="text-left">
            <b-form-group class="mr-4">
              <label for="">Dal*</label>
              <b-form-input
                type="date"
                class="shadow_6"
                v-model="form.valid_from"
                :state="validDates"
                required
              ></b-form-input>
            </b-form-group>
            <b-form-group class="ml-4">
              <label for="">Al*</label>
              <b-form-input
                type="date"
                class="shadow_6"
                v-model="form.valid_until"
                :state="validDates"
                required
              ></b-form-input>
            </b-form-group>
          </b-row>
          <h5 class="text-left pt-3">Farmaci inclusi nel piano</h5>
          <b-row class="text-left pt-3">
            <b-form-group label-for="therapy_selector">
              <label for="" :class="showTherapiesAlert ? 'text-danger' : ''"
                >Seleziona terapie*</label
              >
              <b-form-tags
                id="therapy_selector"
                v-model="selectedTherapies"
                size="lg"
                add-on-change
                no-outer-focus
              >
                <template
                  v-slot="{
                    tags,
                    inputAttrs,
                    inputHandlers,
                    disabled,
                    removeTag,
                  }"
                >
                  <b-form-select
                    class="shadow_6"
                    v-bind="inputAttrs"
                    v-on="inputHandlers"
                  >
                    <b-form-select-option
                      v-for="therapy in therapies.active"
                      :key="therapy.hashid"
                      :value="therapy.product_name"
                    >
                      {{ therapy.product_name }} - {{ therapy.product_aic }}
                    </b-form-select-option>
                    <template #first>
                      <!-- This is required to prevent bugs with Safari -->
                      <option disabled value="">Scegli terapie</option>
                    </template>
                  </b-form-select>
                  <ul
                    v-if="tags.length > 0"
                    class="list-inline d-inline-block mt-3"
                  >
                    <li
                      v-for="tag in selectedTherapies"
                      :key="tag"
                      class="list-inline-item"
                    >
                      <b-form-tag
                        @remove="removeTag(tag)"
                        :title="tag"
                        :disabled="disabled"
                        variant="prim-grad-1 px-4 py-2 fw-bolder my-1"
                        >{{ tag }}</b-form-tag
                      >
                    </li>
                  </ul>
                </template>
              </b-form-tags>
            </b-form-group>
          </b-row>
          <h5 class="text-left pt-3">Annotazioni</h5>
          <b-row cols="1" class="text-left">
            <b-form-group>
              <label for=""
                >Inserisci eventuali note relative al Piano Terapeutico</label
              >
              <b-textarea
                rows="1"
                class="shadow_6"
                v-model="form.pharmacy_notes"
              >
              </b-textarea>
            </b-form-group>
          </b-row>
          <b-row class="justify-content-end mt-3">
            <b-button
              @click="$bvModal.hide('new_plan')"
              type="button"
              variant="outline-danger rounded-pill fw-bolder"
              class="mx-1"
              >Annulla</b-button
            >
            <b-button
              type="submit"
              variant="primary primary_gradient text-white rounded-pill fw-bolder"
              class="mx-1"
            >
              <span v-if="!loading"> Salva Piano</span>
              <b-spinner v-else></b-spinner>
            </b-button>
          </b-row>
        </template>
      </b-form>
    </b-modal>
    <b-modal
      id="delete_plan"
      size="lg"
      centered
      footer-class="border-0"
      title="Conferma Operazione"
      cancel-title="No, Annulla"
      cancel-variant="outline-danger px-3 rounded-pill font-weight-bolder"
      ok-title="Sì, elimina"
      ok-variant="danger px-3 font-weight-bolder rounded-pill"
      @ok="deletePiano"
      @cancel="$bvModal.hide('delete_plan')"
    >
      <h6>Sei sicuro di voler eliminare questo piano?</h6>
    </b-modal>
    <b-modal
      id="renew_modal"
      size="lg"
      centered
      footer-class="border-0"
      title="Conferma Operazione"
      cancel-title="Annulla"
      cancel-variant="outline-primary px-3 rounded-pill font-weight-bolder"
      ok-title="Conferma"
      ok-variant="primary px-3 font-weight-bolder rounded-pill"
      :ok-disabled="selectedTherapies.length < 1"
      @ok="renewPlan"
      @cancel="$bvModal.hide('renew_modal')"
    >
      <div v-if="selectedPlan">
        <h5>Per quali farmaci vuoi richiedere il rinnovo?</h5>
        <b-form-group label-for="therapy_selector">
          <label for="" :class="showTherapiesAlert ? 'text-danger' : ''"
            >Seleziona farmaci*</label
          >
          <b-form-tags
            id="therapy_selector"
            v-model="selectedTherapies"
            size="lg"
            add-on-change
            no-outer-focus
          >
            <template
              v-slot="{ tags, inputAttrs, inputHandlers, disabled, removeTag }"
            >
              <b-form-select
                class="shadow_6"
                v-bind="inputAttrs"
                v-on="inputHandlers"
              >
                <template v-for="therapy in selectedPlan.included_therapies">
                  <b-form-select-option
                    v-if="!therapy.delivered"
                    :key="therapy.hashid"
                    :value="therapy.therapy.product_name"
                  >
                    {{ therapy.therapy.product_name }} -
                    {{ therapy.therapy.product_aic }}
                  </b-form-select-option>
                </template>
                <template #first>
                  <!-- This is required to prevent bugs with Safari -->
                  <option disabled value="">Scegli terapie</option>
                </template>
              </b-form-select>
              <ul
                v-if="tags.length > 0"
                class="list-inline d-inline-block mt-3"
              >
                <li
                  v-for="tag in selectedTherapies"
                  :key="tag"
                  class="list-inline-item"
                >
                  <b-form-tag
                    @remove="removeTag(tag)"
                    :title="tag"
                    :disabled="disabled"
                    variant="prim-grad-1 px-4 py-2 fw-bolder my-1"
                    >{{ tag }}</b-form-tag
                  >
                </li>
              </ul>
            </template>
          </b-form-tags>
        </b-form-group>
      </div>
    </b-modal>
    <b-modal
      id="survey_modal"
      size="xl"
      centered
      scrollable
      ok-only
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      title="Somministra questionario"
      @hide="handleSurveyModalClosure"
    >
      <div v-if="selectedPlan && selectedPlan.survey">
        <template v-if="!survey.complete">
          <h4
            class="mb-0 text-prim-grad-1 fw-bolder text-center"
            ref="survey_start"
          >
            {{ survey.proceed ? "Questionario per il paziente" : "" }}
          </h4>
          <div v-if="selectedPlan.survey.info">
            <!-- deve accettare l'informativa -->
            <div class="text-center" v-if="!survey.proceed">
              <h4 class="pb-4">Informativa per il paziente</h4>
              <p class="text-justify" v-html="selectedPlan.survey.info"></p>
              <!-- <p class="text-justify">{{ survey.info }}</p> -->
              <b-form-checkbox
                class="text-font-light-grey"
                name="data_consense"
                :value="true"
                :unchecked-value="false"
                v-model="survey.consent"
                :state="survey.consent == true"
              >
                Ho consegnato/comunicato l'informativa al cittadino
              </b-form-checkbox>
              <b-button
                type="button"
                variant="primary primary_indo text-white fw-bolder spacing_1 mt-2"
                :disabled="!survey.consent"
                @click="showStartSurvey"
                >Procedi</b-button
              >
            </div>
            <div v-else class="text-center">
              <compila-questionario
                :survey="selectedPlan.survey"
                v-on:filled-form="submitForm($event)"
              />
            </div>
          </div>
          <div v-else class="text-center">
            <!-- nessuna informativa -->
            <compila-questionario
              :survey="selectedPlan.survey"
              v-on:filled-form="submitForm($event)"
            />
          </div>
        </template>
        <div v-else>
          <h4 class="fw-bolder">Operazione completata.</h4>
          <template v-if="selectedPlan.survey.type === 2">
            <h6 class="text-justify py-3">
              Cliccando sul tasto "scarica pdf per il paziente" puoi scaricare
              il questionario appena compilato in versione stampabile,
              comprensivo di informativa (laddove presente) e di spazio dedicato
              per la firma. Potrai comunque scaricare il pdf per il paziente e
              il questionario in altri formati accendendo alla reportistica
              dedicata al questionario. <br />
            </h6>
            <b-row class="mt-3 text-center">
              <b-col>
                <b-button
                  variant="outline-primary fw-bolder spacing_1"
                  @click="$bvModal.hide('survey_modal')"
                >
                  chiudi finestra
                </b-button>
              </b-col>
              <b-col>
                <b-button
                  variant="primary primary_gradient text-white fw-bolder spacing_1"
                  @click="downloadPatientVersion()"
                >
                  <b-icon icon="download"></b-icon>
                  scarica pdf per il paziente
                </b-button>
              </b-col>
            </b-row>
          </template>
        </div>
      </div>
    </b-modal>
  </b-container>
  <b-container fluid v-else-if="abilitata === false">
    <h5 class="text-center">Servizio non abilitato</h5>
  </b-container>
  <b-spinner v-else></b-spinner>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { cittadinoService, supportService, aslService } from "@/_services";
import * as moment from "moment/moment";
import RefertoUploader from "@/components/utilities/RefertoUploader.vue";
import CambiaStatoConsegna from "@/components/pianoterapeutico/CambiaStatoConsegna.vue";
import CompilaQuestionario from "@/components/attivita/CompilaQuestionario.vue";
export default {
  name: "PianiTerapeutici",
  props: ["subject"],
  components: { RefertoUploader, CambiaStatoConsegna, CompilaQuestionario },
  data() {
    return {
      therapies: { active: [], ended: [] },
      plans: null,
      selectedTherapies: [],
      uploadedFile: null,
      form: {
        resource_id: null,
        subjectable_id: null,
        valid_from: null,
        valid_until: null,
        pharmacy_notes: null,
        therapies: null,
      },
      showTherapiesAlert: null,
      loading: false,
      statiPT: {
        1: "nuovi",
        2: "in elaborazione",
        3: "in consegna",
        4: "completati",
        "-4": "invalidati",
        5: "in attesa rinnovo fornitura",
        "-5": "con rinnovo fornitura rifiutato",
      },
      abilitata: null,
      selectedPlan: null,
      filters: {
        search: undefined,
      },
      searching: false,
      showInfo: false,
      survey: {
        consent: false,
        proceed: false,
        complete: false,
        filledHashid: null,
      },
    };
  },
  computed: {
    validDates: function () {
      if (this.form.valid_from && this.form.valid_until) {
        return moment(this.form.valid_from).isBefore(
          moment(this.form.valid_until)
        );
      }
      return null;
    },
    ...mapState({
      alert: (state) => state.alert,
    }),
    ...mapState("utente", ["status", "user"]),
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    handleSearch() {
      console.log(this.filters.search);
      if (this.filters.search && this.filters.search.length >= 20) {
        this.searching = true;
        this.fetchData();
      }
      if (this.filters.search == "") {
        this.searching = true;
        this.filters.search = undefined;
        this.fetchData();
      }
    },
    handleUploadResult(upload) {
      this.form.resource_id = upload.hashid;
      this.uploadedFile = upload;
    },
    selectTherapy(ter) {
      this.selectedTherapies = ter;
      this.$bvModal.show("edit-therapy");
    },
    fetchTherapies() {
      if (this.subject) {
        return this.fetchUserTherapies();
      }
      this.fetchOwnTherapies();
    },
    fetchOwnTherapies() {
      var self = this;
      cittadinoService
        .getTherapies(this.filters)
        .then(function (response) {
          self.therapies = response.data.data;
        })
        .catch(function (error) {
          self.errorAlert("Non è stato possibile recuperare le terapie");
        });
    },
    fetchUserTherapies() {
      var self = this;
      var filters = {
        user: this.subject.hashid,
      };
      cittadinoService
        .getTherapies(filters)
        .then(function (response) {
          self.therapies = response.data.data;
        })
        .catch(function (error) {
          self.errorAlert("Non è stato possibile recuperare le terapie");
        });
    },
    submitPlan() {
      if (this.validDates === false) {
        return false;
      }
      if (this.selectedTherapies.length > 0) {
        this.loading = true;
        const self = this;
        this.form.therapies = this.selectedTherapies.map(function (el) {
          const find = self.therapies.active.find(
            (val) => val.product_name === el
          );
          return find.hashid;
        });
        this.form.subjectable_id = this.subject.hashid;
        console.log(this.form.therapies);
        aslService
          .storeNewTherapeuticPlan(this.form)
          .then(function (response) {
            self.$bvModal.hide("new_plan");
            self.successAlert("Piano caricato correttamente");
            self.loading = false;
            self.fetchData();
            self.resetForm();
          })
          .catch(function (error) {
            self.loading = false;
            self.$bvModal.hide("new_plan");
            self.errorAlert("Non è stato possibile eseguire l'operazione");
          });
      } else {
        return (this.showTherapiesAlert = true);
      }
    },
    renewPlan() {
      if (this.selectedPlan && this.selectedTherapies.length > 0) {
        this.loading = true;
        const self = this;
        const therapies = this.selectedTherapies.map(function (el) {
          const find = self.selectedPlan.included_therapies.find(
            (val) => val.therapy.product_name === el
          );
          return find.hashid;
        });
        const formData = {
          therapies: therapies,
          status: 5,
        };
        aslService
          .renewPlan(formData, this.selectedPlan.hashid)
          .then(function (response) {
            self.$bvModal.hide("renew_modal");
            self.successAlert("Richiesta inoltrata correttamente");
            self.loading = false;
            self.fetchData();
            self.resetForm();
          })
          .catch(function (error) {
            self.loading = false;
            self.$bvModal.hide("renew_modal");
            self.errorAlert("Non è stato possibile inoltrare la richiesta");
          });
      } else {
        return (this.showTherapiesAlert = true);
      }
    },
    resetForm() {
      this.form = {
        resource_id: null,
        subjectable_id: null,
        valid_from: null,
        valid_until: null,
        pharmacy_notes: null,
        therapies: null,
      };
      this.selectedTherapies = [];
      this.selectedPlan = null;
    },
    formatData(data) {
      return moment(data).format("DD/MM/YYYY");
    },
    fetchOwnPlans() {
      const self = this;
      cittadinoService
        .getOwnTherapeuticPlans(this.filters)
        .then(function (res) {
          self.plans = res.data.data;
          self.searching = false;
        })
        .catch(function (err) {
          self.searching = false;
          self.errorAlert("Non è stato possibile recuperare le informazioni");
        });
    },
    fetchUserPlans() {
      const self = this;
      aslService
        .getUserTherapeuticPlans(this.filters, this.subject.hashid)
        .then(function (res) {
          self.plans = res.data.data;
          self.searching = false;
        })
        .catch(function (err) {
          self.searching = false;
          self.errorAlert("Non è stato possibile recuperare le informazioni");
        });
    },
    fetchData() {
      if (this.subject) {
        this.fetchUserPlans();
        return this.fetchUserTherapies();
      }
      this.fetchOwnPlans();
    },
    handleDeliveryStatusChange() {
      this.fetchData();
    },
    verifyService() {
      const self = this;
      aslService
        .isCompanyPicEnabled()
        .then(function (res) {
          self.abilitata = res.data.data;
        })
        .catch(function (err) {
          self.abilitata = false;
        });
    },
    confirmDeletePiano(hashid) {
      this.selectedPlan = hashid;
      this.$bvModal.show("delete_plan");
    },
    deletePiano() {
      const self = this;
      aslService
        .deletePiano(this.selectedPlan)
        .then(function (res) {
          self.$bvModal.hide("delete_plan");
          self.successAlert("Piano eliminato correttamente");
          self.fetchData();
          self.selectedPlan = null;
        })
        .catch(function (err) {
          self.errorAlert("Non è stato possibile eliminare il piano");
        });
    },
    goToDetail(planHash) {
      var route = {
        name: "il tuo piano terapeutico",
        params: { hash: planHash },
      };
      if (this.user.type === 1) {
        route.name = "dettaglio piano terapeutico";
      }
      this.$router.push(route);
    },
    startSurveyModal(plan) {
      this.selectedPlan = plan;
      this.$bvModal.show("survey_modal");
    },
    startRenewModal(plan) {
      this.selectedPlan = plan;
      this.$bvModal.show("renew_modal");
    },
    submitForm(form) {
      var formData = {
        response: JSON.stringify(form.template),
        survey_id: this.selectedPlan.survey.hashid,
        user_id:
          this.selectedPlan.survey.type === 2
            ? this.selectedPlan.subjectable.hashid
            : undefined,
      };
      const self = this;
      supportService
        .storeFilledSurvey(formData)
        .then(function (res) {
          //console.log(res);
          self.survey.complete = true;
          self.survey.filledHashid = res.data.data.hashid;
          self.setFilledSurveyInPlan(res.data.data.hashid);
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile inviare il questionario");
          console.log(err);
        });
    },
    setFilledSurveyInPlan(hashid) {
      const self = this;
      const update = {
        filled_survey: hashid,
      };
      aslService
        .setFilledSurveyInPlan(this.selectedPlan.hashid, update)
        .then(function (res) {
          //console.log(res);
          // self.$bvModal.hide("survey_modal");
          // window.scrollTo(0, 0);
          self.fetchData();
          self.successAlert("Questionario inviato correttamente.");
        })
        .catch(function (err) {
          // window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile inviare il questionario");
          console.log(err);
        });
    },
    downloadPatientVersion() {
      const self = this;
      var fileName = "patient_" + self.selectedPlan.survey.name + ".pdf";
      if (
        self.selectedPlan.survey.type === 2 &&
        self.selectedPlan.subjectable.anagrafica.cf
      ) {
        fileName =
          self.selectedPlan.subjectable.anagrafica.cf + "_patient_version.pdf";
      }
      supportService
        .downloadFilledSurveyPatientVersion(this.survey.filledHashid)
        .then(function (response) {
          let blob = new Blob([response.data]);
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
        })
        .catch(function (err) {
          console.log(err);
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare i report");
        });
    },
    handleSurveyModalClosure() {
      this.survey = {
        consent: false,
        proceed: false,
        complete: false,
        filledHashid: null,
      };
    },
    showStartSurvey() {
      this.survey.proceed = true;

      this.$refs["survey_start"].scrollIntoView();
    },
  },
  created() {
    this.fetchData();
    if (this.user.type === 1) {
      this.verifyService();
      this.fetchTherapies();
    } else {
      this.abilitata = true;
    }
  },
  mounted() {
    // this.$root.$on("bv::modal::hide", (bvEvent, modalId) => {
    //   this.selectedTherapies = null;
    //   this.editing = false;
    // });
  },
};
</script>
