<template>
  <div>
    <vue-gauge
      v-if="showGauge"
      :refid="'type-unique-id'"
      :options="options"
    ></vue-gauge>
    <div class="text-dark text-left fs-4 pl-2 mb-2">
      <p class="mb-0"><b>Riferimenti:</b></p>
      <p class="mb-0"><b> 0%</b>: non determinabile</p>
      <p class="mb-0"><b>1-40%</b>: scarso</p>
      <p class="mb-0"><b>40-80%</b>:medio</p>
      <p class="mb-0"><b>>80%</b>: ottimale</p>
    </div>
  </div>
</template>
<style scoped>
.fs-4 {
  font-size: 70%;
}
.bg_white {
  background-color: #fbfbfb;
}
</style>
<script>
import VueGauge from "vue-gauge";
import { mapState, mapActions } from "vuex";
import { supportService } from "@/_services";
let GaugeChart = require("vue-gauge/assets/bundle.js");
export default {
  name: "AdherenceIndex",
  props: ["hash"],
  components: {
    VueGauge,
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
  },
  data() {
    return {
      options: {
        hasNeedle: true,
        needleColor: ["rgb(0,0,0)"],
        arcColors: ["rgb(247,80,16)", "rgb(255,237,74)", "rgb(123,228,149)"],
        // arcColors: ["rgb(247,80,16)", "rgb(255,207,92)", "rgb(123,228,149)"],
        // arcColors: ["rgb(247,80,16)", "rgb(255,237,74)", "rgb(56,193,114)"],
        // arcColors: ["rgb(255,84,84)", "rgb(239,214,19)", "rgb(61,204,91)"],
        arcDelimiters: [40, 80],
        rangeLabel: ["0%", "100%"],
        centralLabel: "0%",
        needleValue: 0,
        labelsFont: "inherit",
        arcOverEffect: false,
      },
      showGauge: false,
    };
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    getAdherenceIndex() {
      var self = this;
      supportService
        .getAdherenceIndex(this.hash)
        .then(function (res) {
          self.options.needleValue = res.data.data.index;
          self.options.centralLabel = res.data.data.index + "%";
          self.showGauge = true;
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          self.errorAlert(
            "Non è stato possibile elaborare l'indice di aderenza"
          );
          self.showGauge = true;
        });
    },
  },
  mounted() {
    if (this.hash) {
      this.getAdherenceIndex();
    }
  },
};
</script>
