<template>
  <transition-group name="slide">
    <div
      class="d-flex justify-content-between align-items-center"
      key="invalidati"
    >
      <div class="col-3 text-left">
        <strong>Invalidati: {{ attivita.invalidati }}</strong>
      </div>
      <div class="col-9">
        <b-progress
          :max="attivita.tot"
          :value="attivita.invalidati"
          variant="font-light-grey"
          show-value
        ></b-progress>
      </div>
    </div>
    <div
      class="d-flex justify-content-between align-items-center"
      key="elaborazione"
    >
      <div class="col-3 text-left">
        <strong>In Elaborazione: {{ attivita.elaborazione }}</strong>
      </div>
      <div class="col-9">
        <b-progress
          :max="attivita.tot"
          :value="attivita.elaborazione"
          variant="prim-grad-1"
          show-value
        ></b-progress>
      </div>
    </div>
    <div
      class="d-flex justify-content-between align-items-center"
      key="completi"
    >
      <div class="col-3 text-left">
        <strong>Completi: {{ attivita.completi }}</strong>
      </div>
      <div class="col-9">
        <b-progress
          :max="attivita.tot"
          :value="attivita.completi"
          variant="success"
          show-value
        ></b-progress>
      </div>
    </div>
    <div class="d-flex justify-content-between align-items-center" key="totali">
      <div class="col-3 text-left">
        <strong>Totale: {{ attivita.tot }}</strong>
      </div>
      <div class="col-9">
        <b-progress
          :max="attivita.tot"
          :value="attivita.tot"
          variant="prim-grad-2"
          show-value
        ></b-progress>
      </div>
    </div>
  </transition-group>
</template>
<script>
export default {
  name: "ProgressPT",
  props: ["valori"],
  data() {
    return {
      attivita: {
        tot: 0,
        invalidati: 0,
        completi: 0,
        elaborazione: 0,
      },
    };
  },
  mounted() {
    if (this.valori) {
      console.log(this.valori);
      this.attivita.tot = this.valori.tot;
      this.attivita.invalidati = this.valori.invalidati;
      this.attivita.completi = this.valori.completi;
      this.attivita.elaborazione = this.valori.elaborazione;
    }
  },
};
</script>
