<template>
  <b-container>
    <b-row class="">
      <b-col class="col-12 col-md-5">
        <b-form-group label="Inserisci codice utente" label-for="code" class="text-left fs-6 text-font-grey mx-auto">
          <b-form-input class="my-3 shadow_6" name="code" type="text" placeholder="codice utente..." minlength="10"
            v-model="form.code" :state="form.code == null ? null : validcode" @input="nullcode">
          </b-form-input>
        </b-form-group>
      </b-col>
      <b-col class="col-12 col-md-2 align-self-end my-3">
        <b-button variant="primary text-white fw-bolder spacing_1" :disabled="form.code == null" @click="searchUser">
          Cerca</b-button>
      </b-col>
    </b-row>
    <b-modal size="lg" id="member-found" hide-footer>
      <b-row v-if="!notFound" class="justify-content-center">
        <b-col class="col-12 text-center my-3">
          <h5 v-if="searchResult">
            <span> Trovato un utente: </span>
            <br />
            <br />
            <span class="text-capitalize font-weight-bolder">{{ searchResult.anagrafica.name }}
              {{ searchResult.anagrafica.surname }}</span>
            <span>
              <br />
              <br />
              Inviare richiesta?
            </span>
          </h5>
        </b-col>
        <b-col class="col-12 d-flex justify-content-center my-3">
          <b-button class="mx-2" variant="outline-danger fw-bolder" @click="$bvModal.hide('member-found')">Annulla
          </b-button>
          <b-button class="mx-2" variant="primary text-white fw-bolder spacing_1" @click="sendInvite">Invita</b-button>
        </b-col>
      </b-row>
      <p v-else>Nessun utente trovato.</p>
    </b-modal>
  </b-container>
</template>
<script>
import { cittadinoService } from "@/_services";
export default {
  name: "SearchUserByCode",
  data() {
    return {
      form: {
        code: null,
      },
      searchResult: null,
      notFound: false,
    };
  },
  computed: {
    validcode() {
      return this.form.code.length >= 10;
    },
  },
  methods: {
    searchUser() {
      var self = this;
      this.notFound = false;
      cittadinoService
        .validateUserCode(this.form)
        .then(function (res) {
          //console.log(res.data.data);
          self.searchResult = res.data.data;
          self.$bvModal.show("member-found");
        })
        .catch(function (err) {
          //console.log("errorr");
          console.log(err);
          self.notFound = true;
          self.$bvModal.show("member-found");
        });
    },
    sendInvite() {
      //console.log(this.searchResult);
      this.$emit("invite-user", this.searchResult.hashid);
      this.$bvModal.hide("member-found");
    },
    nullcode() {
      if (this.form.code.length == 0) {
        this.form.code = null;
      }
    },
  },
};
</script>