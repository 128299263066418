<template>
  <div class="home">
    <b-alert variant="danger" :show="!user" class="col-lg-8 col-sm-12 mr-0 ml-auto point">
      <span>Effettua il login </span>
    </b-alert>
    <h1></h1>
    <!-- <div class="
        col-lg-10 col-sm-11 col-11
        mx-auto
        py-4
        mb-2
        shadow_7
        general_border
        d-flex
        flex-wrap
        bg-white
      ">
      <farmacia-preferita class="ml-md-4" />
      <div class="col mt-3 mt-md-0">
        <b-button @click="
          $router.push({
            name: 'Nuovo Ordine',
            params: { type: 'nuova prenotazione' },
          })
        " size="sm" class="px-4 px-md-3 px-lg-4 mx-auto"
          variant="sec-2 text-white rounded-pill text-uppercase fw-bolder">
          prenota
        </b-button>
      </div>
    </div> -->

    <transition-group tag="div" name="slidevert">
      <div key="bordered_menu_container"
        class="col-lg-10 col-sm-11 col-11 mx-auto py-4 mb-2 shadow_7 general_border d-flex flex-wrap bg-white mx-auto">
        <b-row class="w-100" key="top_menu_container">
          <farmacia-preferita class="ml-md-4" key="fav_farma" />
          <div class="col mt-3 mt-md-0" key="btn_container">
            <b-button @click="menuOpen = !menuOpen" size="sm" class="px-4 px-md-3 px-lg-4 mx-auto"
              variant="sec-2 text-white rounded-pill text-uppercase fw-bolder">
              prenota i servizi
            </b-button>
          </div>
        </b-row>
        <b-row cols="1" class="w-100" key="menu_container">
          <!-- <b-col v-if="menuOpen" key="neworder" style="position: absolute; bottom: 40px; right: 0" class=" px-0"> -->

          <transition-group tag="div" name="fade">
            <b-col v-if="menuOpen" key="neworder" class="px-0">
              <div class="d-flex flex-column justify-content-end">
                <b-row align-v="center" @click="
                  $router.push({
                    name: 'Nuovo Ordine',
                    params: { type: 'nuova prenotazione' },
                  })
                "
                  class="point submenu__border bg-white shadow_6 fw-bolder my-2 py-2 col-md-10 col-12 col-lg-6 px-0 px-md-2 ml-auto">
                  <span class="text-right col-6 col-lg-8">
                    <strong class="pl-md-2 pl-1 pr-md-2">
                      Nuova Richiesta</strong>
                  </span>
                  <b-col class="text-sm-left text-lg-right">
                    <b-img height="25%" :src="require('@/assets/facile.svg')" alt="logo facile">
                    </b-img>
                  </b-col>
                </b-row>
                <b-row align-v="center" v-if="!$root.prestazioniOnly" @click="
                  $router.push({
                    name: 'Nuovo Ordine',
                    params: { type: 'nuovo ordine' },
                  })
                "
                  class="point submenu__border bg-white shadow_6 fw-bolder my-2 py-2 col-md-10 col-12 col-lg-6 px-0 px-md-2 ml-auto">
                  <span class="text-right col-6 col-lg-8">
                    <strong class="pl-md-2 pl-1 pr-md-2"> Nuovo Ordine </strong>
                  </span>
                  <b-col class="text-sm-left text-lg-right">
                    <b-img height="25%" :src="require('@/assets/facile.svg')" alt="logo facile">
                    </b-img>
                  </b-col>
                </b-row>
                <b-row align-v="center" v-if="!$root.prestazioniOnly" @click="
                  $router.push({
                    name: 'Nuovo Ordine',
                    params: { type: 'nuovo teleconsulto' },
                  })
                "
                  class="point submenu__border bg-white shadow_6 fw-bolder my-2 py-2 col-md-10 col-12 col-lg-6 px-0 px-md-2 ml-auto">
                  <span class="text-right col-6 col-lg-8">
                    <strong class="pl-md-2 pl-1 pr-md-2">
                      Nuovo Teleconsulto
                    </strong>
                  </span>
                  <b-col class="text-sm-left text-lg-right">
                    <b-img height="25%" :src="require('@/assets/connessa.svg')" alt="logo facile">
                    </b-img>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </transition-group>
        </b-row>
      </div>
    </transition-group>

    <b-container class="mb-3" v-if="$route.name == 'CittadinoHome'">
      <b-row align-v="stretch">
        <b-col v-for="(service, ind) in services" :key="ind" class="col-12 col-md-6 col-lg-4 my-3">
          <div class="bg-white shadow_7 general_border pb-md-3 pb-0 h-100">
            <div class="col-6 mx-auto">
              <p class="h1 mb-2 pt-4 pb-1" v-if="service.logo">
                <img :src="service.logo" :alt="service.name + '_logo'" class="home__card" />
              </p>
              <p class="h1 mt-2 py-4" v-else>
                <b-icon font-scale="1.4" icon="calendar2-check" variant="prim-grad-1"></b-icon>
              </p>
            </div>
            <p class="mb-0 mt-1 fw-bolder text-secondary">{{ service.name }}</p>

            <div class="col-lg-11 col-12 px-0 mx-auto">
              <p class="fs-6 text-medium-grey">{{ service.description }}</p>
            </div>
            <div class="col-12 pb-3">
              <router-link tag="button" :disabled="service.disabled"
                class="primary_gradient btn btn-primary py-1 rounded-pill fw-bolder"
                :to="positions[service.position_ref]">
                <span class="fs-5 spacing_1" :class="service.disabled ? 'text-secondary' : 'text-white'">{{
                    service.btnText
                }}</span>
              </router-link>
            </div>
          </div>
        </b-col>
      </b-row>
      <!-- <NewStuff /> -->
    </b-container>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
import NewStuff from "@/components/home/NewStuff.vue";
import FarmaciaPreferita from "@/components/profile/FarmaciaPreferita.vue";
import { mapState, mapActions } from "vuex";
export default {
  name: "CittadinoHome",
  components: {
    FarmaciaPreferita,
    NewStuff,
  },
  data() {
    return {
      menuOpen: false,
      services: [
        {
          name: "Il mio profilo sanitario",
          description: "Terapie ed armadietto farmaceutico",
          position_ref: 1,
          logo: "/assets/records.svg",
          disabled: false,
          btnText: "Consulta",
        },
        {
          name: "I miei familiari",
          description: "Consulta e modifica la lista",
          position_ref: 2,
          logo: "/assets/people.svg",
          disabled: false,
          btnText: "Consulta",
        },
        {
          name: "Il mio circolo di cura",
          description: "Farmacie e operatori sanitari",
          position_ref: 3,
          logo: "/assets/pill.svg",
          disabled: false,
          btnText: "Consulta",
        },
        {
          name: "La mia agenda",
          description: "Agenda appuntamenti",
          position_ref: 4,
          logo: "/assets/book.svg",
          disabled: false,
          btnText: "Consulta",
        },
        // {
        //   name: "Nuova Prenotazione",
        //   description: "richiedi una prenotazione",
        //   position_ref: 5,
        //   logo: null,
        //   disabled: false,
        //   btnText: "Prenota",
        // },
      ],
      positions: {
        1: "profilosanitario",
        2: "familiari",
        3: "circolocura",
        4: "agenda",
        5: "prenotazioni/nuova%20prenotazione",
      },
      // menuOpen: false,
    };
  },
  computed: {
    ...mapState("utente", ["status", "user"]),
  },
  mounted() {
    // //console.log(this.user);
  },
};
</script>
<style>
.submenu__border {
  border-radius: 0.6rem;
}

.fs-4 {
  font-size: 92%;
}

.home__card {
  height: 66px;
}
</style>
<style scoped>
.shadow_6:hover {
  box-shadow: 4px 12px 15px #168bb436;
}
</style>
