<template>
  <div>
    <b-spinner v-if="plan === null"></b-spinner>
    <b-container fluid v-else-if="plan">
      <b-overlay :show="saving">
        <div class="d-flex justify-content-between mb-2">
          <router-link
            v-if="plan && plan.subjectable"
            class="fw-bolder text-primary no__hover"
            :to="{
              name: 'profilo sanitario',
              query: { scope: 'pic_plan' },
            }"
          >
            <b-icon icon="arrow-left-circle-fill" variant="primary"></b-icon>
            Indietro
          </router-link>
          <h5 v-if="plan">Piano terapeutico #{{ plan.codice_richiesta }}</h5>
        </div>
        <b-row
          cols="1"
          class="py-3 text-sec-1 border border-sec-1 shadow_6 px-2 bg-light mb-3 radius_02"
        >
          <b-row class="text-left justify-content-between">
            <h6>
              <span
                class="text-secondary point px-2"
                @click="showPianoDetails = !showPianoDetails"
              >
                <b-icon
                  :icon="showPianoDetails ? 'chevron-up' : 'chevron-down'"
                ></b-icon>
              </span>
              Caricato in data:
              <span class="text-uppercase">{{ plan.created_at }}</span>
            </h6>
            <h6>
              Validità:
              {{ plan.valid_from | moment("DD/MM/YYYY") }}
              <span v-if="plan.valid_until">
                - {{ plan.valid_until | moment("DD/MM/YYYY") }}</span
              >
            </h6>
            <h6>
              Stato:
              <span class="text-uppercase">{{ plan.stato }}</span>
            </h6>
          </b-row>
          <b-row
            cols-md="2"
            cols-lg="3"
            cols="1"
            v-if="showPianoDetails"
            class="text-secondary text-left mt-2 justify-content-between pt-1 bg-white"
          >
            <template v-if="plan.company">
              <b-col>
                <h6>Farmacia</h6>
                <p class="mb-0">
                  <span class="font-italic text-sec-1">
                    {{ plan.company.denominazione }}
                  </span>
                </p>
              </b-col>
              <b-col v-if="plan.company.telefono">
                <h6>Telefono</h6>
                <p class="mb-0">
                  <span class="font-italic text-sec-1">
                    {{ plan.company.telefono }}
                  </span>
                </p>
              </b-col>
              <b-col>
                <h6>Indirizzo</h6>
                <p class="mb-0">
                  <span class="font-italic text-sec-1 text-capitalize">
                    {{ plan.company.indirizzo }},
                    <span v-if="plan.company.frazione"
                      >{{ plan.company.frazione }} -
                    </span>
                    {{ plan.company.comune }}
                  </span>
                </p>
              </b-col>
            </template>
          </b-row>
        </b-row>
        <!-- <b-form @submit.prevent="savePiano"> -->
        <b-row class="py-2 flex-wrap" cols="1" cols-lg="2">
          <b-col cols="12" md="6" lg="6">
            <b-button
              aria-controls="pdf_viewer"
              variant="info"
              size="sm"
              class="fw-bolder"
              @click="visiblePdf = !visiblePdf"
              block
            >
              {{ visiblePdf ? "Nascondi" : "Mostra" }} Piano Terapeutico
            </b-button>
            <b-collapse
              id="pdf_viewer"
              v-model="visiblePdf"
              class="mt-2 shadow_6 radius_02"
            >
              <template v-if="attachmentLink">
                <PDFViewer
                  v-if="plan.attachment.ext == 'pdf'"
                  :source="attachmentLink"
                  style="height: 55vh; width: 100%; min-width: 100%"
                  loading-text="Caricamento in corso..."
                  @download="handleViewerDownload"
                />
                <b-img
                  v-else
                  ref="allegato"
                  :src="attachmentLink"
                  no-body
                  fluid-grow
                  width="100%"
                  height="100%"
                  class="zoomable mt-2"
                />
              </template>
            </b-collapse>
          </b-col>
          <b-col cols="12" md="6" lg="6" class="text-left">
            <h6 class="bg-secondary text-white px-3 py-1 radius_02">
              Farmaci previsti dal Piano Terapeutico
            </h6>
            <!-- <draggable
                class="dragArea list-group"
                :list="plan.included_therapies"
                :group="{ name: 'people', pull: 'clone', put: false }"
              > -->
            <b-row
              class="mb-2"
              :class="
                therapy.delivered
                  ? 'bg-grey py-2 '
                  : 'bg-light general_border shadow_6 py-3 '
              "
              v-for="(therapy, indx) in plan.included_therapies"
              :key="indx"
            >
              <b-col cols="12">
                <b-row class="justify-content-between">
                  <h6 class="text-prim-grad-1 fw-bolder">
                    <span
                      class="text-secondary px-2"
                      v-b-toggle="'prodotto_' + indx"
                    >
                      <b-icon icon="chevron-down"></b-icon>
                    </span>
                    <span>
                      {{
                        therapy.product_name
                          ? therapy.product_name
                          : therapy.therapy.product_name
                      }}
                    </span>
                  </h6>
                  <h6 class="text-prim-grad-1 fw-bolder">
                    <span class="text-secondary">AIC:</span>
                    {{
                      therapy.product_aic
                        ? therapy.product_aic
                        : therapy.therapy.product_aic
                    }}
                  </h6>
                </b-row>
              </b-col>
              <b-col cols="12" v-if="therapy.delivered">
                <p class="text-right font-italic mb-0">Consegnato</p>
              </b-col>
              <b-collapse
                :visible="!therapy.delivered"
                :id="'prodotto_' + indx"
                class="w-100"
              >
                <b-col cols="12">
                  <b-row>
                    <b-col cols="12" xl="9">
                      <label for="">{{ getAuthorization(therapy.type) }}</label>
                    </b-col>
                    <template v-if="therapy.type >= 3">
                      <b-col class="text-right">
                        <label for="">Quantità: {{ therapy.quantity }} </label>
                      </b-col>
                    </template>
                  </b-row>
                </b-col>
              </b-collapse>
            </b-row>
            <!-- </draggable> -->
          </b-col>
        </b-row>
        <b-row v-if="plan.status >= 3" cols="1" class="mt-3 px-3">
          <h6 class="bg-secondary text-white px-3 py-1 radius_02 text-left">
            Pacchetti consegna programmati per il Piano Terapeutico
          </h6>
          <b-card
            v-for="(delivery, ind) in plan.deliveries"
            :key="ind"
            class="border-secondary general_border fw-bolder my-1"
            header-tag="header"
            header-border-variant="transparent border-0 general_border"
            body-bg-variant="white py-0 general_border"
            hide-footer
          >
            <template #header>
              <b-row
                class="justify-content-between"
                v-b-toggle="'pacchetto_' + ind"
              >
                <h6 class="mb-0 text-right">
                  <span class="text-secondary px-2">
                    <b-icon icon="chevron-down"></b-icon>
                  </span>
                  Pacchetto #{{ ind + 1 }}
                </h6>
                <h6 class="mb-0 text-right">
                  Stato pacchetto:
                  <span class="text-uppercase">{{ delivery.stato }}</span>
                </h6>
              </b-row>
            </template>
            <b-collapse
              :visible="delivery.status <= 3"
              :id="'pacchetto_' + ind"
            >
              <b-card-body class="px-2 py-1">
                <b-row cols="1" cols-md="2" class="d-flex">
                  <b-col class="text-left order-2 order-md-1 mt-1">
                    <h6 for="">Etichetta</h6>
                    <span class="text-prim-grad-1">{{ delivery.label }}</span>
                  </b-col>
                  <b-col
                    class="bg-light text-left order-1 order-md-2 mt-1 cell"
                    :class="emptyList ? 'border border-danger' : ''"
                  >
                    <label for="">Farmaci contenuti nel pacchetto</label>
                    <span
                      v-for="(product, index) in delivery.product_list"
                      class="text-prim-grad-1 px-3 py-1 d-flex justify-content-between initial my-1"
                      slot="footer"
                    >
                      {{
                        product.product_name
                          ? product.product_name
                          : product.therapy.product_name
                      }}
                      -
                      {{
                        product.product_aic
                          ? product.product_aic
                          : product.therapy.product_aic
                      }}
                      <span class="text-right fs-6" v-if="delivery.status >= 3">
                        {{ product.quantity }} conf. - scad.
                        {{ product.expiration | moment("DD/MM/YYYY") }} <br />
                        lotto: {{ product.batch }}
                      </span>
                    </span>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-collapse>
          </b-card>
        </b-row>
      </b-overlay>
    </b-container>
    <h5 v-else>Piano non trovato</h5>
  </div>
</template>
<style>
.start > .title {
  display: none;
}

.radius_02 {
  border-radius: 0.2rem;
}

.grab {
  cursor: grab;
  cursor: -webkit-grab;
}

.cell {
  cursor: cell;
}

.initial {
  cursor: initial;
}

.pdf-viewer {
  min-width: 100%;
}

.card-header:first-child {
  border-radius: 1.5rem !important;
}

.zoomable:hover {
  cursor: zoom-in;
  position: relative;
  z-index: 90;
  transform: scale(1.8);
  /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}
</style>
<script>
import * as moment from "moment/moment";
import { mapState, mapActions } from "vuex";
import { aslService, supportService } from "@/_services";
import PDFViewer from "pdf-viewer-vue/dist/vue2-pdf-viewer";
export default {
  name: "PianoTerapeuticoUtente",
  components: {
    PDFViewer,
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    fetchData() {
      this.loading = true;
      var self = this;
      aslService
        .getTherapeuticPlanById(this.$route.params.hash)
        .then(function (response) {
          self.plan = response.data.data;
          self.getAllegato();
          self.loading = false;
          self.edit.valid_from = response.data.data.valid_from;
          self.edit.valid_until = response.data.data.valid_until;
        })
        .catch(function (error) {
          self.errorAlert(
            "non è stato possibile prelevare le informazioni richieste"
          );
          self.loading = false;
        });
    },
    getAllegato() {
      var self = this;
      supportService
        .getAllegato(this.plan.attachment.hashid)
        .then(function (res) {
          self.attachmentLink = window.URL.createObjectURL(
            new Blob([res.data])
          );
          const contentType = res.headers?.["content-type"];
          const contentName = res.headers?.["content-disposition"];
          var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          var matches = filenameRegex.exec(contentName);
          if (matches != null && matches[1]) {
            self.fileName = matches[1].replace(/['"]/g, "");
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    handleViewerDownload(source, filename) {
      let link = document.createElement("a");
      link.href = this.attachmentLink;
      link.download = this.fileName;
      link.click();
    },
    getAuthorization(type) {
      const authObj = this.planTypes.find((el) => el.value === type);
      return authObj.description;
    },
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
  },
  created() {
    this.fetchData();
  },
  data() {
    return {
      loading: null,
      plan: null,
      attachmentLink: null,
      fileName: "allegato",
      planTypes: [
        {
          value: null,
          text: "scegli...",
          disabled: true,
          description: "In attesa di autorizzazione",
        },
        { value: 1, text: "classe C", description: "Non a carico SSN" },
        {
          value: 2,
          text: "autorizzato classe A",
          description: "Autorizzato come rimborsabile",
        },
        {
          value: 3,
          text: "autorizzato DPC",
          description: "Autorizzato per la dispensazione per conto",
        },
        {
          value: 4,
          text: "autorizzato DD",
          description: "Autorizzato per la distribuzione diretta",
        },
      ],
      edit: { valid_from: null, valid_until: null },
      list: [],
      deliveries: [],
      visiblePdf: true,
      emptyList: false,
      selectedDeliveryIndex: null,
      showPianoDetails: false,
      unsavedChanges: false,
      refreshed: false,
      saving: false,
      invalidation_note: null,
    };
  },
};
</script>
