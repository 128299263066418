<template>
  <div>
    <p class="fw-bolder text-primary point" v-if="!showPic" @click="getAllegato">
      <u> mostra immagine {{ indx }}</u>
    </p>
    <div v-else>
      <b-img v-if="picLink" ref="allegato" :src="picLink" no-body fluid-grow width="100%" height="100%" />
      <p v-if="showError" class="text-danger">
        non è stato possibile recuperare l'immagine
      </p>
      <b-button v-if="picLink" size="sm" variant="danger fw-bolder rounded-pill px-3 my-1"
        class="mr-0 ml-auto text-right" @click="showPic = false">
        nascondi immagine {{ indx }}
      </b-button>
    </div>
  </div>
</template>
<script>
import { supportService } from "@/_services";
export default {
  name: "ImageShower",
  props: ["hash", "indx"],
  data() {
    return {
      picLink: null,
      showPic: false,
      showError: false,
    };
  },
  methods: {
    getAllegato() {
      this.showPic = true;
      var self = this;
      supportService
        .getAllegato(this.hash)
        .then(function (res) {
          self.picLink = window.URL.createObjectURL(new Blob([res.data]));
          self.showError = false;
        })
        .catch(function (err) {
          console.log(err);
          self.showError = true;
        });
    },
  },
};
</script>