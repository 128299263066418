<template>
  <div>
    <b-spinner v-if="plan === null"></b-spinner>
    <b-container fluid v-else-if="plan">
      <b-overlay :show="saving">
        <div class="d-flex justify-content-between mb-2">
          <router-link
            v-if="plan && plan.subjectable"
            class="fw-bolder text-primary no__hover"
            :to="{
              name: 'scheda Paziente',
              params: { hash: plan.subjectable.hashid },
              query: { panel: 'pic_plan' },
            }"
          >
            <b-icon icon="arrow-left-circle-fill" variant="primary"></b-icon>
            Indietro
          </router-link>
          <h5 v-if="plan">Piano terapeutico #{{ plan.codice_richiesta }}</h5>
        </div>
        <b-row
          cols="1"
          class="py-3 text-sec-1 border border-sec-1 shadow_6 px-2 bg-light mb-3 radius_02"
        >
          <b-row class="text-left justify-content-between">
            <h6>
              <span
                class="text-secondary point px-2"
                @click="showPianoDetails = !showPianoDetails"
              >
                <b-icon
                  :icon="showPianoDetails ? 'chevron-up' : 'chevron-down'"
                ></b-icon>
              </span>
              Paziente:
              <span v-if="plan.subjectable.anagrafica" class="text-capitalize">
                {{ plan.subjectable.anagrafica.name }}
                {{ plan.subjectable.anagrafica.surname }},
                <span class="text-uppercase">{{
                  plan.subjectable.anagrafica.cf
                }}</span>
              </span>
              <span v-else>*****utenza rimossa****</span>
            </h6>
            <h6>
              Validità:
              {{ plan.valid_from | moment("DD/MM/YYYY") }}
              <span v-if="plan.valid_until">
                - {{ plan.valid_until | moment("DD/MM/YYYY") }}</span
              >
              <b-button
                v-if="Math.abs(plan.status) === 1"
                title="Modifica date validità"
                size="sm"
                class="ml-1"
                variant="outline-primary border-light"
                @click="$bvModal.show('edit_validities')"
              >
                <b-icon icon="pencil-fill"></b-icon>
              </b-button>
            </h6>
            <h6>
              Stato:
              <span class="text-uppercase">{{ plan.stato }}</span>
            </h6>
          </b-row>
          <b-row
            cols-md="2"
            cols-lg="3"
            cols="1"
            v-if="showPianoDetails"
            class="text-secondary text-left mt-2 justify-content-between pt-1 bg-white"
          >
            <b-col>
              <h6>Data caricamento piano</h6>
              <p class="mb-0">
                <span class="font-italic text-sec-1">
                  {{ plan.created_at }}
                </span>
              </p>
            </b-col>
            <template v-if="plan.subjectable.anagrafica">
              <b-col>
                <h6>Telefono del paziente</h6>
                <p class="mb-0">
                  <span class="font-italic text-sec-1">
                    {{ plan.subjectable.anagrafica.tel_number }}
                  </span>
                </p>
              </b-col>
              <b-col>
                <h6>Email del paziente</h6>
                <p class="mb-0">
                  <span class="font-italic text-sec-1">
                    {{ plan.subjectable.email }}
                  </span>
                </p>
              </b-col>
            </template>
          </b-row>
        </b-row>
        <!-- <b-form @submit.prevent="savePiano"> -->
        <b-row class="py-2 flex-wrap" cols="1" cols-lg="2">
          <b-col cols="12" md="6" lg="6">
            <b-button
              aria-controls="pdf_viewer"
              variant="info"
              size="sm"
              class="fw-bolder"
              @click="visiblePdf = !visiblePdf"
              block
            >
              {{ visiblePdf ? "Nascondi" : "Mostra" }} Piano Terapeutico
            </b-button>
            <b-collapse
              id="pdf_viewer"
              v-model="visiblePdf"
              class="mt-2 shadow_6 radius_02"
            >
              <template v-if="attachmentLink">
                <PDFViewer
                  v-if="plan.attachment.ext == 'pdf'"
                  :source="attachmentLink"
                  style="height: 55vh; width: 100%; min-width: 100%"
                  loading-text="Caricamento in corso..."
                  @download="handleViewerDownload"
                />
                <b-img
                  v-else
                  ref="allegato"
                  :src="attachmentLink"
                  no-body
                  fluid-grow
                  width="100%"
                  height="100%"
                  class="zoomable mt-2"
                />
              </template>
            </b-collapse>
          </b-col>
          <b-col cols="12" md="6" lg="6" class="text-left">
            <h6 class="bg-secondary text-white px-3 py-1 radius_02">
              Farmaci previsti dal Piano Terapeutico
            </h6>
            <template v-for="(therapy, indx) in plan.included_therapies">
              <b-row
                class="bg-light py-3 general_border shadow_6 mb-2"
                :key="indx"
                v-if="!therapy.delivered"
              >
                <b-col cols="12">
                  <b-row
                    class="justify-content-between"
                    v-if="!therapy.rejected"
                  >
                    <h6 class="text-prim-grad-1 fw-bolder">
                      <span
                        class="text-secondary px-2"
                        v-b-toggle="'prodotto_' + indx"
                      >
                        <b-icon icon="chevron-down"></b-icon>
                      </span>
                      <span>
                        {{
                          therapy.product_name
                            ? therapy.product_name
                            : therapy.therapy.product_name
                        }}
                      </span>
                    </h6>
                    <h6 class="text-prim-grad-1 fw-bolder">
                      <span class="text-secondary">AIC:</span>
                      {{
                        therapy.product_aic
                          ? therapy.product_aic
                          : therapy.therapy.product_aic
                      }}
                    </h6>
                  </b-row>
                  <b-row
                    style="text-decoration: line-through"
                    class="justify-content-between"
                    v-else
                  >
                    <h6 class="text- fw-bolder">
                      <span class="text-danger ml-2">
                        <b-icon icon="x-octagon-fill"></b-icon>
                      </span>
                      <span class="ml-1">
                        {{
                          therapy.product_name
                            ? therapy.product_name
                            : therapy.therapy.product_name
                        }}
                      </span>
                    </h6>
                    <h6 class="text- fw-bolder">
                      <span class="text-secondary">AIC:</span>
                      {{
                        therapy.product_aic
                          ? therapy.product_aic
                          : therapy.therapy.product_aic
                      }}
                    </h6>
                  </b-row>
                </b-col>
                <b-collapse
                  :visible="!therapy.rejected"
                  :id="'prodotto_' + indx"
                  class="w-100"
                >
                  <b-col cols="12">
                    <b-row>
                      <b-col cols="12" xl="7">
                        <label
                          v-if="therapy.tipo"
                          for=""
                          class="text-capitalize"
                          >{{ therapy.tipo }}</label
                        >

                        <label v-else for="">In attesa di autorizzazione</label>
                      </b-col>
                      <template v-if="therapy.type >= 3">
                        <b-col>
                          <label for=""
                            >Quantità: {{ therapy.quantity }}
                          </label>
                        </b-col>
                        <b-col cols="12" xl="7">
                          <label for="">Lotto: {{ therapy.batch }}</label>
                        </b-col>
                        <b-col cols="12" xl="5">
                          <label for=""
                            >Scadenza:
                            {{
                              therapy.expiration | moment("DD-MM-YYYY")
                            }}</label
                          >
                        </b-col>
                      </template>
                    </b-row>
                  </b-col>
                </b-collapse>
              </b-row>
              <b-row v-else class="bg-grey fs-6 py-3 mb-2" :key="indx">
                <b-col cols="12">
                  <b-row class="justify-content-between">
                    <span class="text-secondary fw-bolder">
                      <span
                        class="text-secondary px-2"
                        v-b-toggle="'prodotto_' + indx"
                      >
                        <b-icon icon="chevron-down"></b-icon>
                      </span>
                      <span>
                        {{
                          therapy.product_name
                            ? therapy.product_name
                            : therapy.therapy.product_name
                        }}
                      </span>
                    </span>
                    <span class="text-secondary fw-bolder">
                      <span class="text-secondary">AIC:</span>
                      {{
                        therapy.product_aic
                          ? therapy.product_aic
                          : therapy.therapy.product_aic
                      }}
                    </span>
                  </b-row>
                </b-col>
                <b-col cols="12">
                  <p class="text-right font-italic mb-0">Consegnato</p>
                </b-col>
                <b-collapse :id="'prodotto_' + indx" class="w-100">
                  <b-col cols="12">
                    <b-row>
                      <b-col cols="12" xl="7">
                        <label
                          v-if="therapy.tipo"
                          for=""
                          class="text-capitalize"
                          >{{ therapy.tipo }}</label
                        >

                        <label v-else for="">In attesa di autorizzazione</label>
                      </b-col>
                      <template v-if="therapy.type >= 3">
                        <b-col>
                          <label for=""
                            >Quantità: {{ therapy.quantity }}
                          </label>
                        </b-col>
                        <b-col cols="12" xl="7">
                          <label for="">Lotto: {{ therapy.batch }}</label>
                        </b-col>
                        <b-col cols="12" xl="5">
                          <label for=""
                            >Scadenza:
                            {{
                              therapy.expiration | moment("DD-MM-YYYY")
                            }}</label
                          >
                        </b-col>
                      </template>
                    </b-row>
                  </b-col>
                </b-collapse>
              </b-row>
            </template>
            <!-- </draggable> -->
          </b-col>
        </b-row>
        <b-row v-if="plan.status !== 1" cols="1" class="mt-3 px-3">
          <!-- <b-row v-if="plan.status >= 2" cols="1" class="mt-3 px-3"> -->
          <!-- <b-row v-if="plan.status >= 3" cols="1" class="mt-3 px-3"> -->
          <h6 class="bg-secondary text-white px-3 py-1 radius_02 text-left">
            Pacchetti consegna programmati per il Piano Terapeutico
          </h6>
          <b-card
            v-for="(delivery, ind) in plan.deliveries"
            :key="ind"
            class="border-secondary general_border fw-bolder my-1"
            header-tag="header"
            header-border-variant="transparent border-0 general_border"
            body-bg-variant="white py-0 general_border"
            hide-footer
          >
            <template #header>
              <b-row
                class="justify-content-between"
                v-b-toggle="'pacchetto_' + ind"
              >
                <h6 class="mb-0 text-right">
                  <span class="text-secondary px-2">
                    <b-icon icon="chevron-down"></b-icon>
                  </span>
                  Pacchetto #{{ plan.deliveries.length - ind }}
                </h6>
                <h6 class="mb-0 text-right">
                  Stato pacchetto:
                  <span class="text-uppercase">{{ delivery.stato }}</span>
                </h6>
              </b-row>
            </template>
            <b-collapse
              :visible="delivery.status <= 3"
              :id="'pacchetto_' + ind"
            >
              <b-card-body class="px-2 py-1">
                <b-row cols="1" cols-md="2" class="d-flex">
                  <b-col class="text-left order-2 order-md-1 mt-1">
                    <h6 for="">Etichetta</h6>
                    <span class="text-prim-grad-1">{{ delivery.label }}</span>
                  </b-col>
                  <b-col
                    class="bg-light text-left order-1 order-md-2 mt-1 cell"
                    :class="emptyList ? 'border border-danger' : ''"
                  >
                    <label for="">Farmaci contenuti nel pacchetto</label>
                    <span
                      v-for="(product, index) in delivery.product_list"
                      class="text-prim-grad-1 px-3 py-1 d-flex justify-content-between initial my-1"
                      slot="footer"
                    >
                      {{
                        product.product_name
                          ? product.product_name
                          : product.therapy.product_name
                      }}
                      -
                      {{
                        product.product_aic
                          ? product.product_aic
                          : product.therapy.product_aic
                      }}
                      <span class="text-right" v-if="delivery.status >= 3">
                        qtà: {{ product.quantity }} -
                        {{ product.expiration | moment("DD/MM/YYYY") }} <br />
                        {{ product.batch }}
                      </span>
                    </span>
                  </b-col>
                </b-row>
                <b-row v-if="delivery.asl_notes">
                  <b-col class="text-left">
                    <h6 for="">Note Asl sulla consegna</h6>
                    <span class="text-prim-grad-1">{{
                      delivery.asl_notes
                    }}</span>
                  </b-col>
                </b-row>
                <b-row class="justify-content-end mt-3">
                  <CambiaStatoConsegna
                    v-if="delivery.hashid && delivery.status == 3"
                    :asAsl="false"
                    :delivery="delivery"
                    :deliveryIndex="ind"
                    @status-changed="handleDeliveryStatusChange"
                  ></CambiaStatoConsegna>
                </b-row>
              </b-card-body>
            </b-collapse>
          </b-card>
        </b-row>
        <b-row cols="1" class="mt-3 px-3">
          <h6 class="bg-secondary text-white px-3 py-1 radius_02 text-left">
            Annotazioni sul Piano Terapeutico
          </h6>
          <b-col class="text-left">
            <h6>Note aggiunte dalla Asl:</h6>
            <div
              v-if="plan.asl_notes"
              class="bg-light text-font-light-grey px-3 py-2"
            >
              {{ plan.asl_notes }}
            </div>
            <div v-else class="text-font-light-grey px-3 py-2">
              Nessuna nota aggiunta
            </div>
          </b-col>

          <b-col class="text-left mt-2">
            <b-form-group>
              <label for="">Note Farmacia:</label>
              <b-form-textarea
                name="plan_pharmacy_notes"
                v-model="plan.pharmacy_notes"
                placeholder="annotazioni..."
                class="shadow_6"
                rows="1"
                :readonly="plan.status < 0 && Math.abs(plan.status) !== 4"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
          <b-col v-if="plan.status === -4" class="text-left mt-2">
            <h6>Motivazioni invalidazione:</h6>
            <div
              v-if="plan.invalidation_note"
              class="bg-light text-font-light-grey px-3 py-2"
            >
              {{ plan.invalidation_note }}
            </div>
            <div v-else class="text-font-light-grey px-3 py-2">
              Nessuna motivazione aggiunta
            </div>
          </b-col>
        </b-row>
        <b-row
          v-if="plan.status === 1 || plan.status === 3"
          class="justify-content-end mt-3"
        >
          <b-button
            v-if="plan.subjectable"
            @click="
              $router.push({
                name: 'scheda Paziente',
                params: { hash: plan.subjectable.hashid },
                query: { panel: 'pic_plan' },
              })
            "
            type="button"
            variant="outline-primary"
            class="mx-1 fw-bolder px-4"
            >Indietro</b-button
          >
          <b-button
            v-if="plan.status === 1"
            @click="$bvModal.show('delete_plan')"
            type="button"
            variant="danger"
            class="mx-1 fw-bolder px-4"
            >Elimina</b-button
          >
          <b-button
            v-if="plan.pharmacy_notes"
            type="button"
            @click="saveNoteOnly"
            variant="primary primary_gradient text-white"
            class="mx-1 fw-bolder px-4"
            >Salva modifiche annotazioni e notifica ASL</b-button
          >
          <b-button
            v-if="plan.status === 1"
            id="real_submitter"
            type="submit"
            @click="savePiano"
            variant="primary primary_gradient text-white"
            class="mx-1 fw-bolder px-4"
            >Salva modifiche</b-button
          >
        </b-row>

        <b-row
          v-if="plan.pharmacy_notes && (plan.status === 2 || plan.status === 5)"
          class="justify-content-end mt-3"
        >
          <b-button
            type="button"
            @click="saveNoteOnly"
            variant="primary primary_gradient text-white"
            class="mx-1 fw-bolder px-4"
            >Salva modifiche annotazioni e notifica ASL</b-button
          >
        </b-row>
        <!-- </b-form> -->
      </b-overlay>
    </b-container>
    <h5 v-else>Piano non trovato</h5>
    <b-modal
      id="delivery_del_confirm"
      title="Conferma operazione"
      ok-title="Sì, elimina"
      cancel-title="No, annulla"
      ok-variant="danger fw-bolder btn-sm"
      cancel-variant="outline-danger fw-bolder btn-sm"
      footer-class="border-0"
      @ok="deleteDelivery"
      @cancel="$bvModal.hide('delivery_del_confirm')"
      centered
    >
      <div>
        <h6>Vuoi eliminare il pacchetto #{{ selectedDeliveryIndex + 1 }}</h6>
      </div>
    </b-modal>
    <b-modal
      id="confirm_invalidation"
      size="lg"
      title="Conferma operazione"
      ok-title="Sì, invalida il Piano"
      cancel-title="No, annulla"
      ok-variant="danger fw-bolder btn-sm"
      cancel-variant="outline-danger fw-bolder btn-sm"
      footer-class="border-0"
      @ok="invalidatePlan"
      @cancel="$bvModal.hide('confirm_invalidation')"
      :ok-disabled="invalidation_note == null || invalidation_note.length < 15"
      centered
    >
      <div>
        <h6>Vuoi invalidare questo Piano Terapeutico?</h6>
        <p>
          Questa operazione è irreversibile: dopo aver indicato il Piano come
          "non valido" non sarà più possibile modificarlo. Per procedere,
          dettagliare nel campo note qui sotto le motivazioni.
        </p>
        <div class="px-3">
          <b-form-group>
            <label for="">Motivazioni*:</label>
            <b-form-textarea
              name="plan_asl_notes"
              v-model="invalidation_note"
              placeholder="motivazioni dell'invalidamento..."
              class="shadow_6"
              rows="1"
              required
            ></b-form-textarea>
          </b-form-group>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="edit_validities"
      size="lg"
      title="Modifica le date di validità del piano"
      ok-title="Salva modifiche"
      cancel-title="Annulla"
      ok-variant="primary primary_gradient text-white fw-bolder"
      cancel-variant="outline-danger fw-bolder"
      footer-class="border-0"
      @ok="saveEdits"
      @cancel="$bvModal.hide('edit_validities')"
      :ok-disabled="false"
      centered
    >
      <div class="px-2">
        <p>Modifica le date di validità del Piano Terapeutico.</p>
        <h6 class="text-left pt-3">Validità del Piano Terapeutico</h6>
        <b-row class="text-left">
          <b-form-group class="mr-4">
            <label for="">Dal*</label>
            <b-form-input
              type="date"
              class="shadow_6"
              v-model="edit.valid_from"
              :state="validDates"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group class="ml-4">
            <label for="">Al*</label>
            <b-form-input
              type="date"
              class="shadow_6"
              v-model="edit.valid_until"
              :state="validDates"
              required
            ></b-form-input>
          </b-form-group>
        </b-row>
      </div>
    </b-modal>
    <b-modal
      id="delete_plan"
      size="lg"
      centered
      footer-class="border-0"
      title="Conferma Operazione"
      cancel-title="No, Annulla"
      cancel-variant="outline-danger px-3 rounded-pill font-weight-bolder"
      ok-title="Sì, elimina"
      ok-variant="danger px-3 font-weight-bolder rounded-pill"
      @ok="deletePiano"
      @cancel="$bvModal.hide('delete_plan')"
    >
      <h6>Sei sicuro di voler eliminare questo piano?</h6>
    </b-modal>
  </div>
</template>
<style>
.start > .title {
  display: none;
}

.radius_02 {
  border-radius: 0.2rem;
}

.grab {
  cursor: grab;
  cursor: -webkit-grab;
}

.cell {
  cursor: cell;
}

.initial {
  cursor: initial;
}

.pdf-viewer {
  min-width: 100%;
}

.card-header:first-child {
  border-radius: 1.5rem !important;
}

.zoomable:hover {
  cursor: zoom-in;
  position: relative;
  z-index: 90;
  transform: scale(1.8);
  /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}
</style>
<script>
import * as moment from "moment/moment";
import { mapState, mapActions } from "vuex";
import { aslService, supportService } from "@/_services";
import PDFViewer from "pdf-viewer-vue/dist/vue2-pdf-viewer";
import draggable from "vuedraggable";
import CambiaStatoConsegna from "@/components/pianoterapeutico/CambiaStatoConsegna.vue";
export default {
  name: "PianoTerapeuticoCliente",
  components: {
    PDFViewer,
    draggable,
    CambiaStatoConsegna,
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    fetchData() {
      this.loading = true;
      var self = this;
      aslService
        .getTherapeuticPlanById(this.$route.params.hash)
        .then(function (response) {
          self.plan = response.data.data;
          self.getAllegato();
          self.loading = false;
          self.edit.valid_from = response.data.data.valid_from;
          self.edit.valid_until = response.data.data.valid_until;
        })
        .catch(function (error) {
          self.errorAlert(
            "non è stato possibile prelevare le informazioni richieste"
          );
          self.loading = false;
        });
    },
    getAllegato() {
      var self = this;
      supportService
        .getAllegato(this.plan.attachment.hashid)
        .then(function (res) {
          self.attachmentLink = window.URL.createObjectURL(
            new Blob([res.data])
          );
          const contentType = res.headers?.["content-type"];
          const contentName = res.headers?.["content-disposition"];
          var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          var matches = filenameRegex.exec(contentName);
          if (matches != null && matches[1]) {
            self.fileName = matches[1].replace(/['"]/g, "");
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    handleViewerDownload(source, filename) {
      let link = document.createElement("a");
      link.href = this.attachmentLink;
      link.download = this.fileName;
      link.click();
    },
    addNewDelivery() {
      this.plan.deliveries.push({
        asl_notes: null,
        created_at: null,
        hashid: null,
        label: null,
        pharmacy_notes: null,
        product_list: [],
        stato: "in preparazione",
        status: 1,
      });
    },
    removeDelivery(ind) {
      if (this.plan.deliveries[ind].hashid) {
        this.selectedDeliveryIndex = ind;
        this.$bvModal.show("delivery_del_confirm");
      } else {
        this.plan.deliveries.splice(ind, 1);
      }
    },
    removeProductFromList(deliveryIndex, productIndex) {
      const newList = this.plan.deliveries[deliveryIndex].product_list.filter(
        (el, ind) => ind !== productIndex
      );
      this.plan.deliveries[deliveryIndex].product_list = newList;
    },
    saveNoteOnly() {
      this.saving = true;
      const form = {
        pharmacy_notes: this.plan.pharmacy_notes,
      };
      const self = this;
      aslService
        .updateTherapeuticPlanPharmacyNote(this.plan.hashid, form)
        .then(function (res) {
          self.unsavedChanges = false;
          self.saving = false;
          self.fetchData();
          self.successAlert(
            "Note salvate correttamente. La ASL riceverà una notifica di aggiornamento."
          );
          self.refreshed = true;
        })
        .catch(function (err) {
          console.log(err);
          self.saving = false;
          self.errorAlert("Non è stato possibile salvare le note");
        });
    },
    savePiano() {
      this.saving = true;
      const form = {
        included_therapies: this.plan.included_therapies,
        deliveries: this.plan.deliveries,
        pharmacy_notes: this.plan.pharmacy_notes,
      };
      const self = this;
      aslService
        .updateTherapeuticPlan(this.plan.hashid, form)
        .then(function (res) {
          self.unsavedChanges = false;
          self.saving = false;
          self.fetchData();
          self.successAlert("Modifiche salvate correttamente");
          self.refreshed = true;
        })
        .catch(function (err) {
          console.log(err);
          self.saving = false;
          self.errorAlert("Non è stato possibile salvare le modifiche");
        });
    },
    saveEdits() {
      this.saving = true;
      const form = {
        included_therapies: this.plan.included_therapies,
        valid_from: this.edit.valid_from,
        valid_until: this.edit.valid_until,
        deliveries: [],
      };
      const self = this;
      aslService
        .updateTherapeuticPlan(this.plan.hashid, form)
        .then(function (res) {
          self.unsavedChanges = false;
          self.saving = false;
          self.fetchData();
          self.successAlert("Modifiche salvate correttamente");
          self.refreshed = true;
        })
        .catch(function (err) {
          console.log(err);
          self.saving = false;
          self.errorAlert("Non è stato possibile salvare le modifiche");
        });
    },
    invalidatePlan() {
      const self = this;
      const form = { status: -4, invalidation_note: this.invalidation_note };
      aslService
        .invalidatePlan(form, this.plan.hashid)
        .then(function (res) {
          self.unsavedChanges = false;
          self.fetchData();
          self.successAlert("Piano invalidato correttamente");
        })
        .catch(function (err) {
          self.errorAlert("Non è stato possibile invalidare il Piano");
        });
    },
    deleteDelivery() {
      if (this.selectedDeliveryIndex) {
        const deliveryHash = this.plan.deliveries[this.selectedDeliveryIndex]
          .hashid;
        const self = this;
        aslService
          .deleteDelivery(deliveryHash)
          .then(function (res) {
            self.successAlert("Pacchetto eliminato correttamente");
            self.plan.deliveries.splice(self.selectedDeliveryIndex, 1);
          })
          .catch(function (err) {
            self.errorAlert("Non è stato possibile eliminare il pacchetto");
          });
      }
    },
    deletePiano() {
      this.loading = true;
      const self = this;
      aslService
        .deletePiano(this.plan.hashid)
        .then(function (res) {
          self.$bvModal.hide("delete_plan");
          self.successAlert("Piano eliminato correttamente");
          self.$router.push({
            name: "scheda Paziente",
            params: { hash: self.plan.subjectable.hashid },
            query: { panel: "pic_plan" },
          });
        })
        .catch(function (err) {
          console.log();
          self.errorAlert("Non è stato possibile eliminare il piano");
          self.loading = false;
        });
    },
    handleDeliveryStatusChange() {
      this.fetchData();
    },
    listChange(event, ind) {
      if (event.added) {
        const isSameObject = (el) => el.hashid === event.added.element.hashid;
        const checkHowManyExists = this.plan.deliveries[
          ind
        ].product_list.filter(isSameObject);
        if (checkHowManyExists.length > 1) {
          const duplicateIndex = this.plan.deliveries[
            ind
          ].product_list.findIndex(isSameObject);
          this.plan.deliveries[ind].product_list.splice(duplicateIndex, 1);
        }
      }
    },
    productIsSetted(product) {
      const hashid = product.hashid;
      if (this.plan && this.plan.deliveries.length > 0) {
        for (let x = 0; x <= this.plan.deliveries.length; x++) {
          if (this.plan.deliveries[x]) {
            var findProduct = this.plan.deliveries[x].product_list.find(
              (el) => el.hashid === hashid
            );
            if (findProduct) {
              return true;
            }
          }
        }
      }
      return false;
    },
  },
  watch: {
    plan: {
      handler(post, prev) {
        if (prev !== null && !this.refreshed) {
          this.unsavedChanges = true;
        }
        if (this.refreshed) {
          this.refreshed = false;
        }
      },
      deep: true,
    },
  },
  computed: {
    validDates: function () {
      if (this.edit.valid_from && this.edit.valid_until) {
        return moment(this.edit.valid_from).isBefore(
          moment(this.edit.valid_until)
        );
      }
      return null;
    },
    allProductsSetted: function () {
      if (this.plan && this.plan.deliveries.length > 0) {
        const totalProducts = this.plan.included_therapies.length;
        const leftToSet = this.plan.deliveries.reduce(
          (total, delivery) => total - delivery.product_list.length,
          totalProducts
        );
        return leftToSet === 0;
      }
      return false;
    },
    showNewDeliveryButton: function () {
      if (this.plan) {
        if (this.plan.deliveries.length === 0) {
          return true;
        }
        if (Math.abs(this.plan.status) === 4) {
          return false;
        }
        if (this.plan.deliveries.length === 1 && !this.allProductsSetted) {
          return true;
        }
        return this.allProductsSetted == false;
      }
      return false;
    },
    ...mapState({
      alert: (state) => state.alert,
    }),
    ...mapState("company", ["company"]),
  },
  created() {
    this.fetchData();
  },
  // beforeRouteLeave(to, from, next) {
  //   if (this.unsavedChanges && this.plan.status !== -4) {
  //     const answer = window.confirm(
  //       'Sono presenti modifiche non salvate che verranno perse se lasci la pagina. Vuoi abbandonare le modifiche? Premi "annulla" per rimanere nella pagina, "ok" per uscire dalla pagina.'
  //     );
  //     if (answer) {
  //       next();
  //     } else {
  //       next(false);
  //     }
  //   } else {
  //     next();
  //   }
  // },
  data() {
    return {
      loading: null,
      plan: null,
      attachmentLink: null,
      fileName: "allegato",
      planTypes: [
        {
          value: null,
          text: "scegli...",
          disabled: true,
          description: "ancora non definita",
        },
        { value: 1, text: "classe C", description: "non a carico SSN" },
        {
          value: 2,
          text: "autorizzato classe A",
          description: "autorizzato come rimborsabile",
        },
        {
          value: 3,
          text: "autorizzato DPC",
          description: "o autorizzato per la dispensazione per conto",
        },
        {
          value: 4,
          text: "autorizzato DD",
          description: "autorizzato per la distribuzione diretta",
        },
      ],
      edit: { valid_from: null, valid_until: null },
      list: [],
      deliveries: [],
      visiblePdf: true,
      emptyList: false,
      selectedDeliveryIndex: null,
      showPianoDetails: false,
      unsavedChanges: false,
      refreshed: false,
      saving: false,
      invalidation_note: null,
    };
  },
};
</script>
