<template>
  <!-- <h5 v-if="blockView" class="text-danger font-weight-bolder">
    Per accedere al servizio è necessario acquistare l'abbonamento
  </h5> -->
  <b-container fluid class="mt-4 w-100">
    <b-row cols="1" class="my-2" v-if="!arruolamento">
      <h6
        class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
      >
        Arruola Paziente
      </h6>
      <p class="text-left text-font-light-grey fs-5">
        Prima di registrare un nuovo paziente verifica se è già presente in
        piattaforma
      </p>

      <SearchUtente
        :type="'cliente'"
        v-on:invite-user="goToScheda($event)"
        v-on:arruola-user="doRegister($event)"
      />
      <h6
        class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
      >
        I tuoi pazienti
      </h6>

      <SearchUserByDeliveryLabel
        :type="'cliente'"
        v-on:invite-user="goToScheda($event)"
        v-on:arruola-user="doRegister($event)"
      />

      <b-col cols="12" cols-md="6" class="text-left my-3 px-0">
        <b-row cols="2">
          <b-col cols="1" class="px-0 text-right mr-1">
            <transition name="slidevert" mode="out-in">
              <h5 :key="counter">
                <span>{{ counter }}</span>
              </h5>
            </transition>
          </b-col>
          <b-col cols="10" class="px-0 ml-1">
            <b-row class="justify-content-between">
              <b-col>
                <h5>
                  <span> pazienti gestiti</span>
                </h5>
              </b-col>
              <b-col>
                <h5 v-if="counterValue > 0">
                  <span
                    class="text-info fw-bolder point"
                    @click="showList = !showList"
                  >
                    <template v-if="showList">
                      Nascondi lista pazienti
                      <b-icon icon="arrow-up-circle-fill"></b-icon>
                    </template>
                    <template v-else>
                      Mostra lista pazienti
                      <b-icon icon="arrow-down-circle-fill"></b-icon>
                    </template>
                  </span>
                </h5>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12">
        <transition name="slidevert" mode="out-in">
          <ListaClienti v-if="showList" />
        </transition>
      </b-col>
    </b-row>
    <b-row cols="1" class="my-2" v-else>
      <h6
        class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
      >
        Arruola Paziente
      </h6>
      <p class="text-left text-font-light-grey fs-5"></p>
      <SchedaArruolamento
        :params="precompiled"
        v-on:cancel-operation="undoArruolamento"
        v-on:arruolamento-completed="goToScheda($event)"
      />
    </b-row>
  </b-container>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { supportService } from "@/_services";
import SearchUtente from "@/components/utilities/SearchUtente.vue";
import SchedaArruolamento from "@/components/pazienti/SchedaArruolamento.vue";
import ListaClienti from "@/components/clienti/ListaClienti.vue";
import SearchUserByDeliveryLabel from "@/components/utilities/SearchUserByDeliveryLabel.vue";
export default {
  name: "HomeClienti",
  components: {
    SearchUtente,
    SchedaArruolamento,
    ListaClienti,
    SearchUserByDeliveryLabel,
  },
  data() {
    return {
      arruolamento: false,
      precompiled: null,
      blockView: true,
      counter: 0,
      counterValue: 0,
      timer: null,
      showList: false,
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
    ...mapState("company", ["companyLoggedIn", "company"]),
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    goToScheda(hashid) {
      //console.log(hashid);
      this.$router.push({ name: "scheda Paziente", params: { hash: hashid } });
    },
    doRegister(params) {
      this.arruolamento = true;
      this.precompiled = params;
    },
    undoArruolamento() {
      if (
        this.$route.query.arruola &&
        Object.keys(this.$route.params).length == 2
      ) {
        this.$router.go(-1);
      }
      this.arruolamento = false;
      this.precompiled = null;
    },
    fetchData() {
      var self = this;
      supportService
        .getTotalPatients()
        .then(function (res) {
          self.counterValue = res.data.data.pazienti;
          self.showList = res.data.data.pazienti > 0;
          self.counter = self.counterValue;
          // self.setCounterTimer();
        })
        .catch(function (err) {
          self.errorAlert(
            "Non è stato possibile aggiornare il totale pazienti"
          );
        });
    },
    // setCounterTimer() {
    //   this.timer = setInterval(() => {
    //     if (this.counter < this.counterValue) {
    //       this.counter++;
    //     } else {
    //       this.stopCounterTimer();
    //     }
    //   }, 50);
    // },8033837554035

    // stopCounterTimer() {
    //   clearInterval(this.timer);
    //   this.timer = null;
    // },
  },
  created() {
    this.fetchData();
    console.log(Object.keys(this.$route.params).length);
    if (
      this.$route.query.arruola &&
      Object.keys(this.$route.params).length == 2
    ) {
      this.doRegister(this.$route.params);
    }
  },
  // mounted() {
  //   document.addEventListener('keydown', function (event) {
  //     if (event.keyCode == 13 || event.keyCode == 17 || event.keyCode == 74)
  //       event.preventDefault();
  //   });
  // },
  // beforeDestroy() {
  //   document.removeEventListener('keydown', function (event) {
  //     console.log("HERE remove", event)
  //   });
  // },
};
</script>
