<template>
  <div>
    <h6
      class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
    >
      Dati di accesso
    </h6>
    <b-row cols="1" cols-md="2">
      <div v-if="canSeeProfilo">
        <div class="col-12 d-flex flex-wrap px-0 px-md-2 text-left">
          <b-form-group
            label="Username"
            label-for="email"
            class="text-left fs-6 text-font-grey px-0 px-md-2 col-12"
          >
            <b-form-input
              class="my-3 shadow_6"
              name="username"
              type="text"
              readonly
              v-model="user.username"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="mx-auto pb-4 mb-4 col-12 text-left">
          <b-button
            type="submit"
            variant="primary text-white fw-bolder spacing_1"
            @click="$refs['modal-pswchange'].show()"
            >Cambia Password</b-button
          >
          <span v-if="user.temporary_password" class="text-danger ml-4">
            <b-icon icon="exclamation-square-fill" font-scale="1.5"></b-icon>
            Modifica la password temporanea con una personale
          </span>
          <span v-if="user.expired_password" class="text-danger ml-4">
            <b-icon icon="exclamation-square-fill" font-scale="1.5"></b-icon>
            La tua password è scaduta! Aggiornala per proseguire.
          </span>
        </div>
      </div>
      <!-- <div v-if="user.type !== 2"> -->
      <div>
        <b-overlay :show="loading">
          <b-form @submit.prevent="saveNumber" class="col-12" v-if="startEdit">
            <b-form-group
              label="Numero di telefono:"
              label-for="tel_number"
              class="text-left fs-6 text-font-grey px-0 px-md-2 col-12"
            >
              <b-input-group class="">
                <b-input-group-prepend>
                  <h6 class="my-4 pt-2 pr-1 text-prim-grad-1">+39</h6>
                </b-input-group-prepend>
                <b-form-input
                  class="my-3 shadow_6"
                  name="tel_number"
                  type="text"
                  placeholder="1234..."
                  v-model="numberForm.tel_number"
                  minlength="9"
                  required
                  :state="
                    numberForm.tel_number !== null
                      ? !isNaN(numberForm.tel_number)
                      : null
                  "
                ></b-form-input>
              </b-input-group>
            </b-form-group>
            <b-button
              variant="outline-danger fw-bolder mx-1"
              type="button"
              @click="cancelEdit"
              >Annulla</b-button
            >
            <b-button
              variant="primary primary_gradient text-white fw-bolder mx-1"
              type="submit"
              >Salva modifica</b-button
            >
          </b-form>
          <div class="col-12 text-left" v-else>
            <b-form-group
              label="Numero di telefono:"
              label-for="tel_number"
              class="fs-6 text-font-grey px-0 px-md-2 col-12"
            >
              <b-input-group class="">
                <b-form-input
                  class="my-3 shadow_6"
                  name="tel_number"
                  type="text"
                  placeholder="1234..."
                  v-model="numberForm.tel_number"
                  minlength="9"
                  readonly
                ></b-form-input>
              </b-input-group> </b-form-group
            ><b-button
              @click="startEdit = true"
              variant="primary primary_gradient text-white fw-bolder ml-2"
              type="button"
              >Modifica numero</b-button
            >
          </div>
        </b-overlay>
      </div>
    </b-row>
    <div class="mx-auto" v-if="user.type !== 2">
      <h6
        class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
      >
        Termini e informative
      </h6>
      <div class="text-left">
        <p class="text-left mt-2 mt-md-4 text-font-light-grey fs-5">
          Termini e Condizioni di utilizzo della piattaforma, privacy policy
        </p>
        <p>
          <b-icon
            icon="check-circle-fill"
            variant="success"
            class="mx-1"
          ></b-icon
          >Hai accettato
          <span
            class="underlined point"
            @click="$bvModal.show(tc[user.type].url)"
          >
            termini e condizioni di utilizzo</span
          >
          del servizio
        </p>
        <p>
          <b-icon
            icon="check-circle-fill"
            variant="success"
            class="mx-1"
          ></b-icon
          >Hai accettato
          <span
            class="underlined point"
            @click="$bvModal.show(informativa[user.type].url)"
          >
            l'informativa privacy</span
          >
          della piattaforma
        </p>
        <p v-if="user.arruolato">
          <b-icon
            icon="check-circle-fill"
            variant="success"
            class="mx-1"
          ></b-icon
          >Hai accettato
          <span
            class="underlined point"
            @click="$bvModal.show('info_arruolamento')"
          >
            l'informativa per la creazione del profilo sanitario</span
          >
          della piattaforma
        </p>
      </div>
      <!-- <div class="col-12 d-flex flex-wrap px-0 px-md-2">
        <b-form-group
          label="Trattamento dati personali"
          label-for="data_consense"
          class="text-left fs-6 text-font-grey mx-auto col"
        >
          <b-form-checkbox
            class="text-font-light-grey"
            name="data_consense"
            :value="1"
            :unchecked-value="0"
            v-model="user.data_consense"
            :state="user.data_consense == true"
            disabled
          >
            Acconsento
          </b-form-checkbox>
        </b-form-group>
        <b-form-group
          label="Condivisione informazioni"
          label-for="share_consense"
          class="text-left fs-6 text-font-grey mx-auto col"
        >
          <b-form-checkbox
            class="text-font-light-grey"
            name="share_consense"
            :value="1"
            :unchecked-value="0"
            v-model="user.share_consense"
            :state="user.share_consense == true"
            disabled
          >
            Acconsento
          </b-form-checkbox>
        </b-form-group>
      </div>
      <div class="col-12 d-flex flex-wrap px-0 px-md-2">
        <b-form-group
          label="Ricezione notifiche"
          label-for="notification_consense"
          class="text-left fs-6 text-font-grey mx-auto col"
        >
          <b-form-checkbox
            class="text-font-light-grey"
            name="notification_consense"
            :value="1"
            :unchecked-value="0"
            v-model="user.notification_consense"
            :state="user.notification_consense == true"
            disabled
          >
            Acconsento
          </b-form-checkbox>
        </b-form-group>
        <b-form-group
          v-if="user.type === 0"
          label="Presa in carico"
          label-for="charge_consense"
          class="text-left fs-6 text-font-grey mx-auto col"
        >
          <b-form-checkbox
            class="text-font-light-grey"
            name="charge_consense"
            :value="1"
            :unchecked-value="0"
            v-model="user.charge_consense"
            :state="user.charge_consense == true"
            disabled
          >
            Acconsento
          </b-form-checkbox>
        </b-form-group>
      </div> -->
    </div>
    <div
      class="mx-auto mt-4"
      v-if="user.type === 0 && HTNAlwaysTransferResDataConsense"
    >
      <h6
        class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
      >
        Consensi
      </h6>
      <div class="text-left">
        <p class="text-left mt-2 mt-md-4 text-font-light-grey fs-5">
          Prestazioni HTN
        </p>
        <b-row class="my-4">
          <b-col class="col-12 px-0 mt-n3 d-flex align-items-center flex-wrap">
            <span class="d-flex flex-nowrap align-items-center mt-3">
              <b-icon
                icon="check-circle-fill"
                variant="success"
                class="mx-1"
              ></b-icon
              ><span class="mr-3"
                >Hai accettato di condividere automaticamente i dettagli delle
                prenotazioni con HTN</span
              >
            </span>
            <b-button
              size="sm"
              type="button"
              class="mt-3"
              variant="primary text-white fw-bolder spacing_1"
              @click="$bvModal.show('withdraw-htn-consense')"
              >Revoca il consenso</b-button
            >
          </b-col>
        </b-row>
      </div>
      <!-- <div class="col-12 d-flex flex-wrap px-0 px-md-2">
        <b-form-group
          label="Trattamento dati personali"
          label-for="data_consense"
          class="text-left fs-6 text-font-grey mx-auto col"
        >
          <b-form-checkbox
            class="text-font-light-grey"
            name="data_consense"
            :value="1"
            :unchecked-value="0"
            v-model="user.data_consense"
            :state="user.data_consense == true"
            disabled
          >
            Acconsento
          </b-form-checkbox>
        </b-form-group>
        <b-form-group
          label="Condivisione informazioni"
          label-for="share_consense"
          class="text-left fs-6 text-font-grey mx-auto col"
        >
          <b-form-checkbox
            class="text-font-light-grey"
            name="share_consense"
            :value="1"
            :unchecked-value="0"
            v-model="user.share_consense"
            :state="user.share_consense == true"
            disabled
          >
            Acconsento
          </b-form-checkbox>
        </b-form-group>
      </div>
      <div class="col-12 d-flex flex-wrap px-0 px-md-2">
        <b-form-group
          label="Ricezione notifiche"
          label-for="notification_consense"
          class="text-left fs-6 text-font-grey mx-auto col"
        >
          <b-form-checkbox
            class="text-font-light-grey"
            name="notification_consense"
            :value="1"
            :unchecked-value="0"
            v-model="user.notification_consense"
            :state="user.notification_consense == true"
            disabled
          >
            Acconsento
          </b-form-checkbox>
        </b-form-group>
        <b-form-group
          v-if="user.type === 0"
          label="Presa in carico"
          label-for="charge_consense"
          class="text-left fs-6 text-font-grey mx-auto col"
        >
          <b-form-checkbox
            class="text-font-light-grey"
            name="charge_consense"
            :value="1"
            :unchecked-value="0"
            v-model="user.charge_consense"
            :state="user.charge_consense == true"
            disabled
          >
            Acconsento
          </b-form-checkbox>
        </b-form-group>
      </div> -->
    </div>
    <div class="mb-4 mt-md-4" v-if="user.type === 0">
      <h6
        class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
      >
        Gestione Dati
      </h6>
      <p class="text-left mt-2 mt-md-4 text-font-light-grey fs-5">
        Elimina definitivamente la tua utenza DottorFarma. Se elimini il tuo
        profilo non potrai più accedere alla piattaforma ed usufruire dei
        servizi offerti. L'operazione è irreversibile.
      </p>
      <b-row cols="1" cols-md="2" class="my-4">
        <!-- <b-col class="text-left">
          <b-button
            size="sm"f
            type="button"
            variant="primary text-white fw-bolder spacing_1"
            >Disattiva il mio profilo</b-button
          >
          <p class="mt-2 mt-md-4 text-font-light-grey fs-5">
            Disattiva temporaneamente la tua utenza DottorFarma. <br />
            (specificare cosa accade se si disattiva)
          </p>
        </b-col> -->
        <b-col class="text-left">
          <b-button
            size="sm"
            type="button"
            variant="primary text-white fw-bolder spacing_1"
            @click="$bvModal.show('delete-confirm')"
            >Elimina il mio profilo</b-button
          >
          <!-- <p class="mt-2 mt-md-4 text-font-light-grey fs-5">
            Elimina definitivamente la tua utenza DottorFarma.
            <br />
            Se elimini il tuo profilo non potrai più accedere alla piattaforma
            ed usufruire dei servizi offerti.
          </p> -->
        </b-col>
      </b-row>
    </div>
    <!-- <div class="text-left mt-4">
        <p class="my-1">
          Per estrarre tutti i tuoi dati presenti su DottorFarma clicca su
          "Ottieni i miei dati". <br />
          Puoi anche inviare una richiesta a (specificare indirizzo email)
        </p>
        <b-button type="button" variant="primary text-white fw-bolder spacing_1"
          >Ottieni i miei dati</b-button
        >
      </div>
    </div> -->
    <b-modal
      centered
      size="lg"
      id="delete-confirm"
      title="Conferma Operazione"
      ok-title="Sì, elimina"
      ok-variant="danger fw-bolder spacing_1"
      cancel-title="No, annulla"
      cancel-variant="outline-primary fw-bolder spacing_1"
      footer-border-variant="white"
      footer-class="text-center"
      @cancel="$bvModal.hide('delete-confirm')"
      @ok="submitDelete"
    >
      <h5>Sei sicuro di voler eliminare il tuo profilo?</h5>
      <p>
        Una volta confermata l'eliminazione non potrai più tornare indietro.
      </p>
    </b-modal>
    <b-modal
      centered
      size="lg"
      id="withdraw-htn-consense"
      title="Conferma Operazione"
      ok-title="Sì, conferma"
      ok-variant="danger fw-bolder spacing_1"
      cancel-title="No, annulla"
      cancel-variant="outline-primary fw-bolder spacing_1"
      footer-border-variant="white"
      footer-class="text-center"
      @cancel="$bvModal.hide('withdraw-htn-consense')"
      @ok="submitWithdrawHTNConsense"
    >
      <h5>
        Sei sicuro di voler revocare il consenso alla condivisione dati
        prenotazione ad HTN?
      </h5>
    </b-modal>
    <b-modal
      ref="modal-pswchange"
      title="Modifica la tua  password"
      size="lg"
      hide-footer
    >
      <b-form @submit.prevent="submitPswChange">
        <div class="col-12 d-flex flex-wrap px-0 px-md-2">
          <b-form-group
            label="Password Attuale*"
            label-for="oldpsw"
            class="text-left fs-6 text-font-grey mx-auto col-12 px-0 px-md-2"
          >
            <b-form-input
              v-model="form.old_password"
              placeholder="password attuale..."
              class="my-3 shadow_6"
              name="oldpsw"
              type="password"
              required
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-12 d-flex flex-wrap px-0 px-md-2">
          <b-form-group
            label="Password*"
            label-for="password"
            class="text-left fs-6 text-font-grey mx-auto col-12 px-0 px-md-2"
          >
            <b-form-input
              class="my-3 shadow_6"
              name="password"
              type="password"
              v-model="form.password"
              placeholder="password..."
              :state="form.password == null ? null : validatePassword"
              required
            >
            </b-form-input>
          </b-form-group>
          <b-form-group
            label="Conferma password*"
            label-for="password"
            class="text-left fs-6 text-font-grey mx-auto col-12 px-0 px-md-2"
          >
            <b-form-input
              class="my-3 shadow_6"
              name="password_confirmation"
              type="password"
              v-model="form.password_confirmation"
              placeholder="conferma password..."
              :state="form.password == null ? null : validateConfirm"
              required
            ></b-form-input>
          </b-form-group>
          <p class="text-left text-medium-grey fs-6">
            La password non può includere il nome né il cognome e deve contenere
            almeno 8 caratteri, di cui almeno un numero, una maiuscola, una
            minuscola ed un
            <span
              v-b-tooltip
              title="Devi usare almeno un caratterere tra ! @ # $ % ^ & * ) ( + = . _ -"
              >carattere speciale</span
            >. La nuova password deve essere diversa da quella attuale.
          </p>
        </div>
        <div
          class="col-12 text-center d-flex justify-content-around my-3 flex-wrap"
        >
          <b-button
            type="button"
            variant="outline-danger fw-bolder mx-auto my-1"
            @click="resetForm"
            >Annulla Operazione
          </b-button>
          <b-button
            type="submit"
            variant="primary primary_gradient fw-bolder mx-auto my-1"
            :disabled="!enableSecondBtn"
            >Conferma Modifica</b-button
          >
        </div>
      </b-form>
    </b-modal>
    <b-modal
      size="xl"
      centered
      id="info_cittadino"
      title="Informativa Privacy"
      hide-footer
    >
      <InformativaRegistrazioneCittadini></InformativaRegistrazioneCittadini>
    </b-modal>
    <b-modal
      size="xl"
      centered
      id="info_farmacista"
      title="Informativa Privacy"
      hide-footer
    >
      <InformativaRegistrazioneFarmacisti></InformativaRegistrazioneFarmacisti>
    </b-modal>
    <b-modal
      size="xl"
      centered
      id="tc_cittadino"
      title="Termini e Condizioni"
      hide-footer
    >
      <TerminiCondizioniCittadini></TerminiCondizioniCittadini>
    </b-modal>
    <b-modal
      size="xl"
      centered
      id="tc_farmacista"
      title="Termini e Condizioni"
      hide-footer
    >
      <TerminiCondizioniFarmacisti></TerminiCondizioniFarmacisti>
    </b-modal>
    <b-modal
      size="xl"
      centered
      id="info_arruolamento"
      title="Informativa Profilo Sanitario"
      hide-footer
    >
      <InformativaArruolamento></InformativaArruolamento>
    </b-modal>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import InformativaRegistrazioneFarmacisti from "@/components/profile/informative/InformativaRegistrazioneFarmacisti.vue";
import InformativaRegistrazioneCittadini from "@/components/profile/informative/InformativaRegistrazioneCittadini.vue";
import TerminiCondizioniCittadini from "@/components/profile/informative/TerminiCondizioniCittadini.vue";
import TerminiCondizioniFarmacisti from "@/components/profile/informative/TerminiCondizioniFarmacisti.vue";
import InformativaArruolamento from "@/components/pazienti/InformativaArruolamento.vue";
import { cittadinoService } from "../../_services";
export default {
  name: "AccessoEPermessi",
  components: {
    InformativaRegistrazioneFarmacisti,
    InformativaRegistrazioneCittadini,
    TerminiCondizioniCittadini,
    TerminiCondizioniFarmacisti,
    InformativaArruolamento,
  },
  computed: {
    ...mapState("utente", ["status", "user"]),
    validatePassword() {
      if (this.form.password) {
        if (
          this.form.old_password &&
          this.form.old_password === this.form.password
        ) {
          return false;
        }
        this.has_minimum_lenth = this.form.password.length > 8;
        this.has_number = /\d/.test(this.form.password);
        this.has_lowercase = /[a-z]/.test(this.form.password);
        this.has_uppercase = /[A-Z]/.test(this.form.password);
        this.has_special = /[!@#\$%\^\&*\)\(+=._-]/.test(this.form.password);
        const regName = new RegExp(this.user.anagrafica.name.toLowerCase());
        const regSurname = new RegExp(
          this.user.anagrafica.surname.toLowerCase()
        );
        this.has_name = !regName.test(this.form.password.toLowerCase());
        this.has_surname = !regSurname.test(this.form.password.toLowerCase());
        return (
          this.has_minimum_lenth &&
          this.has_number &&
          this.has_lowercase &&
          this.has_uppercase &&
          this.has_name &&
          this.has_surname &&
          this.has_special
        );
      }
      return false;
    },
    validateConfirm() {
      if (this.form.password) {
        return this.form.password_confirmation === this.form.password;
      }
      return false;
    },
    enableSecondBtn() {
      if (this.form.old_password !== null) {
        if (this.validatePassword && this.validateConfirm) {
          return true;
        }
        return false;
      }
      return false;
    },
    canSeeProfilo() {
      if (this.user.type === 1 && !Array.isArray(this.user.company)) {
        if (!this.user.company.dottorfarma) {
          return true;
        }
        //Rimuovere se si vuole dare la possibilità di modificare la psw per i farmacisti ff
        return this.user.email !== this.user.company.email;
      }
      return true;
    },
  },
  data() {
    return {
      password: "password...",
      form: {
        old_password: null,
        password: null,
        password_confirmation: null,
      },
      numberForm: {
        tel_number: null,
      },
      startEdit: false,
      loading: false,
      informativa: {
        0: {
          text: " informativa cittadino",
          url: "info_cittadino",
        },
        1: {
          text: " informativa farmacista",
          url: "info_farmacista",
        },
      },
      tc: {
        0: {
          text: " tc cittadino",
          url: "tc_cittadino",
        },
        1: {
          text: " tc farmacista",
          url: "tc_farmacista",
        },
      },
      HTNAlwaysTransferResDataConsense: false,
    };
  },
  methods: {
    ...mapActions("utente", [
      "me",
      "changePassword",
      "deleteProfile",
      "setTemporaryPasswordFalse",
      "setExpiredPasswordFalse",
      "setAnagrafica",
    ]),
    ...mapActions({
      clearAlert: "alert/clear",
      infoAlert: "alert/info",
    }),
    resetForm() {
      this.$refs["modal-pswchange"].hide();
      this.form = {
        old_password: null,
        password: null,
        password_confirmation: null,
      };
    },
    submitWithdrawHTNConsense() {
      cittadinoService
        .editOwnHTNReservationConsense({
          permissions: [
            {
              data_model: "reservation",
              always: false,
            },
          ],
        })
        .then((res) => {
          this.$bvModal.hide("withdraw-htn-consense");
          this.$nextTick(() => {
            this.infoAlert(
              "Hai revocato il consenso alla condivisione dati prenotazione ad HTN"
            );
            this.HTNAlwaysTransferResDataConsense = false;
          });
        })
        .catch((err) => {
          this.errorAlert("Errore durante la revoca del consenso");
        });
    },
    submitPswChange() {
      const self = this;
      this.changePassword(this.form).then(function () {
        window.scrollTo(0, 0);
        self.resetForm();
        self.setTemporaryPasswordFalse();
        self.setExpiredPasswordFalse();
        self.me();
      });
    },
    submitDelete() {
      var self = this;
      this.deleteProfile().then(function () {
        window.scrollTo(0, 0);
        self.$bvModal["delete-confirm"].hide();
      });
    },
    saveNumber() {
      this.loading = true;
      const self = this;
      this.setAnagrafica({
        form: this.numberForm,
        isPost: false,
      })
        .then((res) => {
          self.startEdit = false;
          self.loading = false;
        })
        .catch((err) => {
          console.log("ERRR");
        });
    },
    cancelEdit() {
      this.numberForm.tel_number = null;
      if (this.user && this.user.anagrafica) {
        this.numberForm.tel_number = this.user.anagrafica.tel_number;
      }
      this.startEdit = false;
    },
  },
  watch: {},
  created() {
    this.me();
    if (this.$route.query.scope === "temporary") {
      this.$nextTick(function () {
        this.infoAlert("Devi ancora modificare la tua password temporanea");
      });
    }
    if (this.$route.query.scope === "expired") {
      this.$nextTick(function () {
        this.infoAlert("La tua password è scaduta e deve essere cambiata");
      });
    }
    if (this.user && this.user.anagrafica) {
      this.numberForm.tel_number = this.user.anagrafica.tel_number;
    }
  },
  mounted() {
    if (this.user.type === 0) {
      cittadinoService.getOwnHTNConsenses().then((res) => {
        if (res.data.data.permissions && res.data.data.permissions.length > 0) {
          this.HTNAlwaysTransferResDataConsense = res.data.data.permissions.find(
            (p) => p.data_model === "reservation"
          )?.always;
        }
      });
    }
  },
};
</script>
