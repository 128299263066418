<template>
  <div>
    <div>
      <CompactSearch
        v-on:new-selection="updatePreferenze($event)"
        :pagefilter="filterSetted.page"
        :takefilter="filterSetted.take"
        :sorting="sorting"
        :expocurrent="doExport"
        v-on:action-ended="doExport = false"
        class="px-3 py-3"
      />
    </div>

    <p
      v-if="
        !user.permessi.includes('gestire aziende propria regione') &&
        !user.permessi.includes('gestire aziende propria asl')
      "
      class="text-justify text-medium-grey fw-bolder mb-0"
    >
      Seleziona una o più righe per scaricare report, assegnare o disabilitare
      servizi.
    </p>
    <div class="d-flex justify-content-between align-items-end">
      <div :class="$root.isMobile ? 'order-2' : ''">
        <span class="text-info fw-bolder mr-1" v-if="total > 0"
          >{{ fromPage }}-{{ toPage }} di
        </span>
        <span class="text-info fw-bolder"> {{ total }}</span>
      </div>
      <div
        v-if="
          !user.permessi.includes('gestire aziende propria regione') &&
          !user.permessi.includes('gestire aziende propria asl')
        "
        :class="selectedCompanies.length > 0 ? '' : 'hide__me'"
      >
        <b-button-group size="sm">
          <!-- <b-button class="py-2">Scarica</b-button> -->
          <b-dropdown dropright text="Scarica" class="py-2">
            <b-dropdown-item
              class="text-secondary fw-bolder"
              @click="modalDownload('prestazioni')"
              ><span class="text-secondary fw-bolder">Report Prestazioni </span>
            </b-dropdown-item>
            <b-dropdown-item
              class="text-secondary fw-bolder"
              @click="modalDownload('ordini')"
              ><span class="text-secondary fw-bolder">Report Ordini </span>
            </b-dropdown-item>
            <b-dropdown-item
              class="text-secondary fw-bolder"
              @click="modalDownload('attività')"
              ><span class="text-secondary fw-bolder">Report Attività </span>
            </b-dropdown-item>
            <b-dropdown-item
              class="text-secondary fw-bolder"
              @click="doExport = true"
              ><span class="text-secondary fw-bolder">Report Selezionate </span>
            </b-dropdown-item>
          </b-dropdown>
          <!-- <b-button class="py-2">Invia</b-button> -->
          <b-dropdown dropright text="Assegna" class="py-2">
            <b-dropdown-item
              @click="modalAssegna('attività')"
              class="text-secondary fw-bolder"
            >
              <span class="text-secondary fw-bolder">Attività</span>
            </b-dropdown-item>
            <b-dropdown-item
              @click="modalAssegna('questionario')"
              class="text-secondary fw-bolder"
            >
              <span class="text-secondary fw-bolder">Questionario</span>
            </b-dropdown-item>
            <b-dropdown-item
              @click="modalAssegna('prestazioni')"
              class="text-secondary fw-bolder"
            >
              <span class="text-secondary fw-bolder">Prestazione</span>
            </b-dropdown-item>
          </b-dropdown>
          <b-dropdown dropright text="Disabilita" class="py-2">
            <b-dropdown-item
              @click="modalDisabilita('attività')"
              class="text-secondary fw-bolder"
            >
              <span class="text-secondary fw-bolder">Attività</span>
            </b-dropdown-item>
            <b-dropdown-item
              @click="modalDisabilita('questionario')"
              class="text-secondary fw-bolder"
            >
              <span class="text-secondary fw-bolder">Questionario</span>
            </b-dropdown-item>
            <b-dropdown-item
              @click="modalDisabilita('prestazioni')"
              class="text-secondary fw-bolder"
            >
              <span class="text-secondary fw-bolder">Prestazione</span>
            </b-dropdown-item>
          </b-dropdown>
          <b-dropdown dropright text="Abilita" class="py-2">
            <b-dropdown-item
              @click="modalAbilita('fds')"
              class="text-secondary fw-bolder"
            >
              <span class="text-secondary fw-bolder">Adesione FDS</span>
            </b-dropdown-item>
          </b-dropdown>
        </b-button-group>
      </div>
      <b-form-select
        :class="$root.isMobile ? 'order-1' : ''"
        class="shadow_6 col-3 ml-4 mb-0"
        name="vedi"
        v-model="filterSetted.take"
        :options="takeOptions"
      >
      </b-form-select>
    </div>
    <div>
      <b-table
        hover
        borderless
        responsive
        table-variant="white text-info text-capitalize text-left row__font"
        :fields="fields"
        :items="companies"
        :small="$root.isMobile"
        :sort-by.sync="sorting.sort"
        :sort-desc.sync="sorting.indesc"
        foot-clone
        selectable
        select-mode="multi"
        @row-selected="onRowSelected"
        selected-variant="sec-3"
        thead-class=" border-medium-grey text-left"
        tbody-class=""
        ref="companyTable"
        tbody-tr-class="text-black border-bottom border-medium-grey"
      >
        <template #head(selection)="data">
          <span class="text-info">{{ data.label }}</span>
          <b-form-checkbox
            @change="toggleSelection"
            :checked="companies && selectedCompanies.length == companies.length"
          ></b-form-checkbox>
        </template>
        <template #cell(selection)="{ rowSelected }">
          <!-- <b-form-checkbox :checked="rowSelected"></b-form-checkbox> -->
          <span>
            <b-icon
              variant="secondary"
              :icon="rowSelected ? 'check-circle-fill' : 'circle'"
            ></b-icon>
          </span>
        </template>
        <template #cell(enabled)="row">
          <p class="mb-0 text-center">
            <b-icon
              icon="circle-fill"
              :variant="row.item.enabled == 1 ? 'success' : 'danger'"
            ></b-icon>
          </p>
        </template>
        <template #cell(dottorfarma)="row">
          <p class="mb-0 text-center" v-if="row.item.dottorfarma === 1">
            <span>Sì</span>
          </p>
          <p class="mb-0 text-center" v-else>
            <span>No</span>
            <br />
            <span v-if="row.item.farmacieunite === 1">(Farmacie Unite)</span>
            <span v-else>(Altro)</span>
          </p>
        </template>
        <template #cell(updated_at)="row">
          <span>{{ row.item.updated_at | moment("DD/MM/YYYY HH:mm") }}</span>
        </template>
        <template #cell(azioni)="row">
          <div class="d-flex text-center flex-wrap">
            <b-button
              size="sm"
              variant="info info_gradient rounded-pill fw-bolder px-3 fs-6 m-1"
              @click="row.toggleDetails"
            >
              {{ row.detailsShowing ? "Chiudi" : "Scheda" }}
            </b-button>
            <router-link
              :to="{
                name: 'dettaglio azienda',
                params: { company: row.item.hashid },
              }"
              tag="b-button"
              class="primary primary_gradient fw-bolder rounded-pill text-white fs-6 btn-sm m-1"
              >Panoramica</router-link
            >
          </div>
        </template>
        <template #row-details="row">
          <b-card
            :header="'Dettaglio ' + row.item.denominazione"
            header-text-variant="secondary fw-bolder"
            header-bg-variant="white"
            header-tag="h4"
            class="text-left text-font-grey"
          >
            <b-card-text class="text-left">
              <b-row cols="2">
                <b-col>
                  <h5 class="fw-bolder text-secondary">Sede</h5>
                  <p class="mb-0">
                    {{ row.item.indirizzo }} {{ row.item.cap }},
                    {{ row.item.frazione }} {{ row.item.comune }}
                  </p>
                  <p class="mb-0">
                    {{ row.item.provincia }} {{ row.item.regione }}
                  </p>
                  <p class="">
                    <strong class="text-lowercase">codice asl: </strong>
                    {{ row.item.codice_asl }}
                    <strong class="ml-2 text-lowercase">comunale: </strong>
                    {{ row.item.comunale == 1 ? "sì" : "no" }}
                    <strong class="ml-2 text-lowercase">azienda: </strong>
                    {{ row.item.azienda == 1 ? "sì" : "no" }}
                  </p>
                  <h5 class="fw-bolder text-secondary">Titolare</h5>
                  <p class="mb-0 text-capitalize">
                    {{ row.item.nome }} {{ row.item.cognome }}
                  </p>
                  <p class="mb-0 text-uppercase">
                    <strong class="text-lowercase">codice fiscale: </strong>
                    {{ row.item.codice_fiscale }}
                    <strong class="ml-2 text-lowercase">partita iva:</strong>
                    {{ row.item.partita_iva }}
                  </p>
                  <p class="mb-0 text-uppercase">
                    <strong class="text-lowercase">codice titolare: </strong>
                    {{ row.item.codice_titolare }}
                    <strong class="ml-2 text-lowercase"
                      >codice tracciabilità:
                    </strong>
                    {{ row.item.codice_tracciabilita }}
                  </p>
                  <p class="text-uppercase">
                    <strong class="text-lowercase">codice regionale: </strong>
                    {{ row.item.codice_regionale }}
                  </p>
                  <h5 class="fw-bolder text-secondary">Contatti</h5>
                  <p class="text-uppercase">
                    <strong class="text-lowercase">email: </strong>
                    {{ row.item.email }}
                    <strong class="ml-2 text-lowercase">pec: </strong>
                    {{ row.item.emailpec }}<br />
                    <strong class="text-lowercase">telefono: </strong>
                    {{ row.item.telefono }}
                    <strong class="ml-2 text-lowercase">fax: </strong>
                    {{ row.item.fax }}
                  </p>
                </b-col>
                <b-col>
                  <h5 class="fw-bolder text-secondary">Apertura</h5>
                  <p v-if="row.item.apertura && row.item.apertura.length > 0">
                    <span v-for="(gg, index) in row.item.apertura" :key="index">
                      <strong>{{ Object.keys(gg)[0] }}: </strong>
                      <strong v-if="gg[Object.keys(gg)[0]] == 'chiuso'"
                        >Chiuso</strong
                      >
                      <strong v-else>
                        {{ gg[Object.keys(gg)[0]]["mattina"] }}
                        {{ gg[Object.keys(gg)[0]]["pomeriggio"] }}</strong
                      >
                      <br />
                    </span>
                  </p>
                  <p v-else>Non inseriti</p>
                  <div>
                    <router-link
                      :to="{
                        name: 'dettaglio azienda',
                        params: { company: row.item.hashid },
                      }"
                      tag="b-button"
                      class="primary primary_gradient fw-bolder text-white"
                      >Vai a Panoramica
                    </router-link>
                  </div>
                </b-col>
              </b-row>
            </b-card-text>
          </b-card>
        </template>
      </b-table>
      <p class="mb-0" v-if="companies !== null && companies.length == 0">
        Nessun risultato.
      </p>
      <b-pagination
        v-model="filterSetted.page"
        pills
        :total-rows="total"
        :per-page="filterSetted.take"
        align="center"
        :size="$root.isMobile ? 'sm' : ''"
      ></b-pagination>
      <!-- <p>Selezionati: {{ selectedCompanies }}</p> -->
      <!-- <div v-if="selectedCompanies.length > 0"> -->
    </div>

    <b-modal
      size="xl"
      centered
      ref="modal-scarica"
      :title="modalTitle"
      hide-footer
    >
      <CompanyReport
        :azienda="selectedCompanies"
        v-if="modalKind == 'prestazioni'"
        v-on:doclose-modal="$refs['modal-scarica'].hide()"
      />
      <div v-else-if="modalKind == 'attività'" class="px-2 py-4 text-center">
        <b-form-group
          size="sm"
          class="fw-bolder text-left"
          label="Scegli attività per il report"
        >
          <b-form-select
            name="activity"
            class="shadow_6"
            v-model="selectedActivity"
          >
            <b-form-select-option :value="null">-</b-form-select-option>
            <b-form-select-option
              v-for="(activity, hash) in activities"
              :key="hash"
              :value="hash"
              >{{ activity }}
            </b-form-select-option>
          </b-form-select>
        </b-form-group>
        <b-button
          variant="primary primary_gradient text-white fw-bolder spacing_1"
          class="mt-4"
          :disabled="selectedActivity == null"
          @click="downloadActivityReport"
          >Scarica excel</b-button
        >
      </div>
      <h5 v-else class="text-info">Funzionalità in sviluppo</h5>
    </b-modal>

    <b-modal
      size="xl"
      centered
      ref="modal-assegna"
      :title="modalTitle"
      hide-footer
    >
      <div v-if="modalKind == 'attività'" class="px-2 py-4 text-center">
        <b-form-group
          size="sm"
          class="fw-bolder text-left"
          label="Scegli attività da assegnare"
        >
          <b-form-select
            name="activity"
            class="shadow_6"
            v-model="selectedActivity"
          >
            <b-form-select-option :value="null">-</b-form-select-option>
            <b-form-select-option
              v-for="(activity, hash) in activities"
              :key="hash"
              :value="hash"
              >{{ activity }}
            </b-form-select-option>
          </b-form-select>
        </b-form-group>
        <p class="text-justify">
          L'attività scelta e i suoi questionari attivi saranno disponibili per
          le aziende selezionate.
        </p>
        <b-button
          variant="primary primary_gradient text-white fw-bolder spacing_1"
          class="mt-4"
          :disabled="selectedActivity == null"
          @click="assignActivity(true)"
          >Conferma</b-button
        >
      </div>
      <div
        v-else-if="modalKind == 'questionario'"
        class="px-2 py-4 text-center"
      >
        <b-form-group
          size="sm"
          class="fw-bolder text-left"
          label="Scegli attività del questionario da assegnare per le aziende selezionate"
        >
          <b-form-select
            name="activity"
            class="shadow_6"
            v-model="surveyFilter.activity"
            @change="fetchSurveys"
          >
            <b-form-select-option :value="null">-</b-form-select-option>
            <b-form-select-option
              v-for="(activity, hash) in activities"
              :key="hash"
              :value="activity"
              >{{ activity }}
            </b-form-select-option>
          </b-form-select>
        </b-form-group>

        <b-form-group
          v-if="surveys"
          size="sm"
          class="fw-bolder text-left my-2"
          label="Seleziona questionario da assegnare per le aziende selezionate"
        >
          <b-form-select
            name="survey"
            class="shadow_6"
            v-model="selectedSurvey"
          >
            <b-form-select-option :value="null">-</b-form-select-option>
            <b-form-select-option
              v-for="(survey, hash) in surveys"
              :key="hash"
              :value="hash"
              >{{ survey }}
            </b-form-select-option>
          </b-form-select>
        </b-form-group>
        <p class="text-justify">
          Il questionario selezionato sarà disponibile per le aziende
          selezionate.
        </p>
        <b-button
          variant="primary primary_gradient text-white fw-bolder spacing_1"
          class="mt-4"
          :disabled="selectedSurvey == null"
          @click="assignSurvey(true)"
          >Conferma</b-button
        >
      </div>
      <h5 v-else class="text-info">Funzionalità in sviluppo</h5>
    </b-modal>

    <b-modal
      size="xl"
      centered
      ref="modal-disabilita"
      :title="modalTitle"
      hide-footer
    >
      <div v-if="modalKind == 'attività'" class="px-2 py-4 text-center">
        <b-form-group
          size="sm"
          class="fw-bolder text-left"
          label="Scegli attività da disabilitare per le aziende selezionate"
        >
          <b-form-select
            name="activity"
            class="shadow_6"
            v-model="selectedActivity"
          >
            <b-form-select-option :value="null">-</b-form-select-option>
            <b-form-select-option
              v-for="(activity, hash) in activities"
              :key="hash"
              :value="hash"
              >{{ activity }}
            </b-form-select-option>
          </b-form-select>
        </b-form-group>
        <p class="text-justify">
          L'attività selezionata ed i suoi questionari non saranno più
          disponibili per le aziende selezionate.
        </p>
        <b-button
          variant="primary primary_gradient text-white fw-bolder spacing_1"
          class="mt-4"
          :disabled="selectedActivity == null"
          @click="assignActivity(false)"
          >Conferma</b-button
        >
      </div>

      <div
        v-else-if="modalKind == 'questionario'"
        class="px-2 py-4 text-center"
      >
        <b-form-group
          size="sm"
          class="fw-bolder text-left"
          label="Scegli attività del questionario da disabilitare per le aziende selezionate"
        >
          <b-form-select
            name="activity"
            class="shadow_6"
            v-model="surveyFilter.activity"
            @change="fetchSurveys"
          >
            <b-form-select-option :value="null">-</b-form-select-option>
            <b-form-select-option
              v-for="(activity, hash) in activities"
              :key="hash"
              :value="activity"
              >{{ activity }}
            </b-form-select-option>
          </b-form-select>
        </b-form-group>
        <b-form-group
          v-if="surveys"
          size="sm"
          class="fw-bolder text-left my-2"
          label="Scegli attività del questionario da disabilitare per le aziende selezionate"
        >
          <b-form-select
            name="survey"
            class="shadow_6"
            v-model="selectedSurvey"
          >
            <b-form-select-option :value="null">-</b-form-select-option>
            <b-form-select-option
              v-for="(survey, hash) in surveys"
              :key="hash"
              :value="hash"
              >{{ survey }}
            </b-form-select-option>
          </b-form-select>
        </b-form-group>
        <p class="text-justify">
          Il questionario selezionato non sarà più disponibile per le aziende
          selezionate.
        </p>
        <b-button
          variant="primary primary_gradient text-white fw-bolder spacing_1"
          class="mt-4"
          :disabled="selectedSurvey == null"
          @click="assignSurvey(false)"
          >Conferma</b-button
        >
      </div>
      <h5 v-else class="text-info">Funzionalità in sviluppo</h5>
    </b-modal>

    <b-modal
      size="xl"
      centered
      ref="modal-abilita"
      :title="modalTitle"
      hide-footer
    >
      <div v-if="modalKind == 'fds'" class="px-2 py-4 text-center">
        <b-form-group
          size="sm"
          class="fw-bolder text-left"
          label="Seleziona il servizio del gruppo 'Farmacia dei servizi' da abilitare per le aziende selezionate"
        >
          <b-form-select
            name="fdservice"
            class="shadow_6"
            v-model="selectedFdservice"
          >
            <b-form-select-option :value="null">-</b-form-select-option>
            <b-form-select-option
              v-for="(service, hash) in fdservices"
              :key="hash"
              :value="service"
              >{{ service.name }}
            </b-form-select-option>
          </b-form-select>
        </b-form-group>
        <b-form-group
          v-if="selectedFdservice && selectedFdservice.related_service_set"
          size="sm"
          class="fw-bolder text-left"
          label="Seleziona il dettaglio del servizio"
        >
          <b-form-select
            v-if="selectedFdservice.related_service_set.length > 0"
            name="fdserviceset"
            class="shadow_6"
            v-model="selectedFdset"
          >
            <b-form-select-option :value="null">-</b-form-select-option>
            <b-form-select-option
              v-for="(set, hash) in selectedFdservice.related_service_set"
              :key="hash"
              :value="set"
            >
              {{ set.attivita.name }}
              <span v-if="set.hs_name">-PREST:{{ set.hs_name }}</span>
              <span v-if="set.questionario"
                >-QUEST:{{ set.questionario.name }}</span
              >
            </b-form-select-option>
            <b-form-select-option :value="null">scegli</b-form-select-option>
          </b-form-select>
          <p v-else>Nessun set predisposto per il servizio</p>
          <p class="mt-3" v-if="selectedFdset">
            Stai abilitando per le farmacie selezionate le seguenti:
            <br />
            Attività:
            <span class="text-prim-grad-1">{{
              selectedFdset.attivita.name
            }}</span>
            <br />
            Prestazione sanitaria:
            <span class="text-prim-grad-1">{{
              selectedFdset.hs_name ? selectedFdset.hs_name : "nessuna"
            }}</span>
            <br />
            Questionario associato alla prestazione sanitaria:
            <span class="text-prim-grad-1">{{
              selectedFdset.questionario
                ? selectedFdset.questionario.name
                : "nessuno"
            }}</span>
          </p>
        </b-form-group>
        <b-button
          variant="primary primary_gradient text-white fw-bolder spacing_1"
          class="mt-4"
          :disabled="selectedFdset == null"
          @click="enableFds()"
          >Conferma</b-button
        >
      </div>
      <h5 v-else class="text-info">Funzionalità in sviluppo</h5>
    </b-modal>
  </div>
</template>
<script>
import { supportService, adminService } from "@/_services";
import { mapState, mapActions } from "vuex";
import CompactSearch from "@/components/admin/CompactSearch.vue";
import CompanyReport from "@/components/admin/CompanyReport.vue";
export default {
  name: "GestioneAziende",
  components: { CompactSearch, CompanyReport },
  data() {
    return {
      companies: null,
      fields: [
        { key: "selection", label: "" },
        // { key: "ff_username", label: "Username" },
        {
          key: "denominazione",
          label: "Ragione Sociale",
          class: "text-justify",
          sortable: true,
        },
        { key: "cognome", label: "Cognome" },
        { key: "regione", label: "Regione" },
        { key: "provincia", label: "Provincia" },
        { key: "comune", label: "Comune" },
        // { key: "codice_fiscale", label: "Codice Fiscale" },
        { key: "partita_iva", label: "Partita Iva" },
        {
          key: "codice_tracciabilita",
          label: "Codice Tracciabilità",
          sortable: true,
        },
        { key: "enabled", label: "Abilitato" },
        {
          key: "dottorfarma",
          label: "Aderente FF",
          class: "text-center",
          // formatter: function (val, col, item) {
          //   if (val === 1) {
          //     return "Sì";
          //   }
          //   return item.farmacieunite === 1
          //     ? "NO (Farmacie Unite)"
          //     : "NO (altro)";
          // },
        },
        {
          key: "valid_subscription",
          label: "Sottoscrizione",
          class: "text-center",
           formatter: function (val, col, item) {
             if (val && val.attivo) {
               return "Sì";
             }
             return "No"
           },
        },
        { key: "updated_at", label: "Ultimo login", sortable: true },
        { key: "azioni", label: "" },
      ],
      filterSetted: {
        page: 1,
        take: 10,
      },
      sorting: {
        indesc: true,
        sort: "created_at",
      },
      total: 0,
      fromPage: 0,
      toPage: 0,
      selectedCompanies: [],
      takeOptions: [10, 25, 50, 100, 150, 200, 250, 300],
      modalKind: null,
      modalTitle: "",
      activityFilter: {
        inarray: true,
      },
      surveyFilter: {
        activity: null,
        inarray: true,
      },
      activities: null,
      surveys: null,
      selectedActivity: null,
      selectedSurvey: null,
      companyArray: [],
      fdservices: null,
      selectedFdservice: null,
      selectedFdset: null,
      doExport: false,
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
    ...mapState("utente", ["status", "user"]),
  },
  // watch: {
  //   filterSetted: {
  //     handler(prev, post) {
  //       this.fetchData();
  //     },
  //     deep: true,
  //   },
  // },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    updatePreferenze(risultati) {
      //console.log(risultati);
      this.companies = risultati.data;
      this.filterSetted.page = risultati.current_page;
      this.total = risultati.total;
      this.fromPage = risultati.from;
      this.toPage = risultati.to;
      // this.me();
      // this.selectCompany(nuovaPreferita);
      // this.form.company_id = nuovaPreferita.hashid;
      // this.form.service_id = nuovaPreferita.servizi_attivi["facile"];
    },
    onRowSelected(items) {
      this.selectedCompanies = items;
    },
    toggleSelection(ev) {
      //console.log("event", ev);
      if (ev) {
        this.$refs.companyTable.selectAllRows();
      } else {
        this.$refs.companyTable.clearSelected();
      }
    },
    modalDownload(kind) {
      this.modalKind = kind;
      this.modalTitle = "Scarica report " + kind;
      this.$refs["modal-scarica"].show();
    },
    modalAssegna(kind) {
      this.modalKind = kind;
      this.modalTitle = "Assegna " + kind;
      this.$refs["modal-assegna"].show();
      var companies = [];
      this.selectedCompanies.map(function (el) {
        companies.push(el.hashid);
      });
      this.companyArray = companies;
    },
    modalDisabilita(kind) {
      this.modalKind = kind;
      this.modalTitle = "Disabilita " + kind;
      this.$refs["modal-disabilita"].show();
      var companies = [];
      this.selectedCompanies.map(function (el) {
        companies.push(el.hashid);
      });
      this.companyArray = companies;
    },
    modalAbilita(kind) {
      this.modalKind = kind;
      this.modalTitle = "Abilita servizio " + kind;
      this.$refs["modal-abilita"].show();
      var companies = [];
      this.selectedCompanies.map(function (el) {
        companies.push(el.hashid);
      });
      this.companyArray = companies;
    },
    fetchActivities() {
      var self = this;
      supportService
        .getActivities(this.activityFilter)
        .then(function (res) {
          self.activities = res.data.data;
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare le attività");
          console.log(err);
        });
      //
    },
    assignActivity(state) {
      var dataForm = {
        hash: this.selectedActivity,
        form: {
          state: state,
          companies: this.companyArray,
        },
      };
      var msg =
        state == true
          ? "Attività assegnata con successo!"
          : "Attività disabilitata con successo!";
      var self = this;
      adminService
        .enableActivityFor(dataForm)
        .then(function (res) {
          self.$refs["modal-assegna"].hide();
          self.$refs["modal-disabilita"].hide();
          window.scrollTo(0, 0);
          self.successAlert(msg);
        })
        .catch(function (err) {
          self.$refs["modal-assegna"].hide();
          self.$refs["modal-disabilita"].hide();
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile completare l'operazione");
          console.log(err);
        });
    },
    assignSurvey(state) {
      var dataForm = {
        hash: this.selectedSurvey,
        form: {
          state: state,
          companies: this.companyArray,
        },
      };
      var msg =
        state == true
          ? "Questionario assegnato con successo!"
          : "Questionario disabilitato con successo!";
      var self = this;
      adminService
        .enableSurveyFor(dataForm)
        .then(function (res) {
          self.$refs["modal-assegna"].hide();
          self.$refs["modal-disabilita"].hide();
          window.scrollTo(0, 0);
          self.successAlert(msg);
        })
        .catch(function (err) {
          self.$refs["modal-assegna"].hide();
          self.$refs["modal-disabilita"].hide();
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile assegnare i questionari");
          console.log(err);
        });
    },
    fetchSurveys() {
      var self = this;
      supportService
        .getSurveys(this.surveyFilter)
        .then(function (response) {
          self.surveys = response.data.data;
          // self.filterSetted.page = response.data.data.current_page;
          // self.total = response.data.data.total;
          // self.fromPage = response.data.data.from;
          // self.toPage = response.data.data.to;
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare le attività");
          console.log(err);
        });
    },
    downloadActivityReport() {
      //console.log("comp", this.selectedCompanies);
      //console.log("act", this.selectedActivity);
      var companies = this.selectedCompanies.map(function (a) {
        return a.hashid;
      });
      var dataToSend = {
        hash: this.selectedActivity,
        form: { company: companies },
      };
      var self = this;
      supportService
        .getActivityReportForCompanies(dataToSend)
        .then(function (response) {
          let blob = new Blob([response.data]);
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "Report_attività.xlsx";
          link.click();
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          self.errorAlert(
            "Non è stato possibile scaricare il report per l'attività"
          );
          console.log(err);
        });
    },
    getFdservices() {
      var self = this;
      adminService
        .getAllFdservices()
        .then(function (response) {
          self.fdservices = response.data.data;
        })
        .catch(function (error) {
          console.log(error);
          self.errorAlert(
            "Non è stato possibile recuperare le info di Farmacia dei servizi"
          );
        });
    },
    enableFds() {
      var companies = this.selectedCompanies.map(function (a) {
        return a.hashid;
      });
      var dataToSend = {
        hashid: this.selectedFdset.hashid,
        form: { companies: companies },
      };
      var self = this;
      adminService
        .enableFdservice(dataToSend)
        .then(function (response) {
          self.$refs["modal-abilita"].hide();
          window.scrollTo(0, 0);
          self.successAlert("Servizio abilitato per le farmacie selezionate");
        })
        .catch(function (err) {
          self.$refs["modal-abilita"].hide();
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile abilitare i servizi");
          console.log(err);
        });
    },
    // checkRow(index, selected) {
    //   this.$refs.companyTable.selectRow(index);
    //   //console.log("SELECTED", this.$refs.companyTable);
    //   if (selected) {
    //     this.$refs.companyTable.selectRow(index);
    //   } else {
    //     this.$refs.companyTable.unselectRow(index);
    //   }
    // },
  },
  mounted() {
    this.fetchActivities();
    this.getFdservices();
  },
};
</script>
<style>
.hide__me {
  visibility: hidden !important;
}

.row__font {
  font-size: 14px;
  font-weight: 500;
}

.table-white td,
.table th,
.table td {
  padding: 0.35rem 0.4rem !important;
}

label {
  font-weight: 500;
}
</style>
