<template>
  <div>
    <div class="d-flex justify-content-between">
      <router-link class="fw-bolder text-primary no__hover"
        :to="{ name: user.type == 2 ? 'attività e questionari' : 'attivita' }">
        <b-icon icon="arrow-left-circle-fill" variant="primary"></b-icon>
        Attività
      </router-link>
    </div>
    <div class="py-3">
      <h6 class="
          text-prim-grad-1
          fw-bolder
          text-left
          pb-1
          border-bottom border-line-grey
        ">
        Terapie
      </h6>
      <p class="text-justify text-medium-grey fw-bolder">
        Inserisci codice fiscale per visualizzare le terapie di un paziente.
        <span v-if="user && user.type === 1">
          Clicca su "nuova terapia" per inserire una nuova terapia
          farmacologica; per inserire una nuova terapia di altra natura clicca
          su "nuova terapia non farmacologica".</span>
      </p>
      <p class="text-primary fw-bolder point text-left">
        <a href="/assets/prof2_interazioni.pdf" target="_blank">
          <u>Consulta il documento relativo alle principali interazioni dei
            farmaci onco-ematologici con farmaci, fitoterapici ed alimenti
          </u></a>
      </p>
      <div class="d-flex mb-3" v-if="opts">
        <b-button v-for="(op, ind) in opts" :key="ind" variant="primary primary_gradient text-white fw-bolder spacing_1"
          class="m-1" @click="
            $router.push({
              name: 'somministra questionario',
              params: { quest: op.hashid },
              query: { ucf: filterSetted.cf, activity: $route.query.activity },
            })
          ">
          <b-icon icon="clipboard-plus"></b-icon>{{ op.name }}
        </b-button>
      </div>
      <div class="text-left d-flex align-items-center">
        <b-form-group label="Inserisci Codice Fiscale*:" class="col-6 fw-bolder">
          <b-form-input name="cf" type="text" maxlength="16" minlength="16" v-model="filterSetted.cf"
            class="shadow_6 text-uppercase"></b-form-input>
        </b-form-group>
        <div class="col-4">
          <b-button @click="fetchData" variant="info info_gradient fw-bolder mt-4"
            :disabled="filterSetted.cf == null || filterSetted.cf.length < 16">Vedi Terapie</b-button>
        </div>
      </div>
      <div class="mt-2" v-if="surveys">
        <h6 class="
            text-prim-grad-1
            fw-bolder
            text-left
            pb-1
            border-bottom border-line-grey
          ">
          Schema terapeutico
        </h6>
        <p class="text-left" v-if="surveys.terapia">
          <span v-if="surveys.terapia.descr_schema"> <b>Descrizione:</b> {{surveys.terapia.descr_schema}}</span>
          <span v-if="surveys.terapia.princ_att"> <br> <b>Principi attivi: </b>
            <span class="text-lowercase">{{surveys.terapia.princ_att.toString()}}</span></span>
        </p>
        <h6 class="
            text-prim-grad-1
            fw-bolder
            text-left
            pb-1
            border-bottom border-line-grey
          ">
          Allergie
        </h6>
        <p class="text-left">
          <b>A farmaci: </b>
          <span v-if="surveys.allergieFarm">
            <template v-if="Array.isArray(surveys.allergieFarm)">
              <span v-for="all,ind in surveys.allergieFarm" :key="ind">
                {{all.princ_att}},
              </span>
            </template>
            <template v-else>
              {{surveys.allergieFarm }}
            </template>
          </span>
          <span v-else> nessuna</span>
          <br />
          <b>Ad altro: </b>
          <span v-if="surveys.allergieNonFarm">
            <template v-if="Array.isArray(surveys.allergieNonFarm)">
              <span v-for="all,ind in surveys.allergieNonFarm" :key="ind">
                {{all}},
              </span>
            </template>
            <template v-else>
              {{surveys.allergieNonFarm }}
            </template>
          </span>
          <span v-else> nessuna</span>
        </p>
        <template v-if="surveys.annotazioni">
          <h6 class="
            text-prim-grad-1
            fw-bolder
            text-left
            pb-1
            border-bottom border-line-grey
          ">
            Annotazioni
          </h6>
          <p class="text-left">
            {{surveys.annotazioni}}
          </p>
        </template>
        <h6 class="
            text-prim-grad-1
            fw-bolder
            text-left
            pb-1
            border-bottom border-line-grey
          ">
          Terapie Farmacologiche
        </h6>
        <b-table borderless hover responsive table-variant="white text-info" :fields="fields" :items="surveys.farmaci"
          :small="$root.isMobile" show-empty>
          <template #cell(created_at)="row">
            <span class="text-secondary text-left text-capitalize fw-bolder">{{
            row.item.created_at | moment("DD/MM/YYYY")
            }}</span>
          </template>
          <template #cell(nome_farmaco)="row">
            <span class="text-secondary text-left fw-bolder">{{
            row.item.nome_farmaco
            }}</span>
          </template>
          <template #cell(data_riconcil)="row">
            <span v-if="row.item.data_riconcil" class="text-secondary text-left fw-bolder">{{ row.item.data_riconcil |
            moment("DD/MM/YYYY")
            }}</span>
            <span v-else class="text-secondary text-left fw-bolder"> no </span>
          </template>
          <template #cell(stato)="row">
            <span v-if="row.item.stato" class="text-secondary text-left fw-bolder">{{ row.item.stato }}</span>
            <span v-else class="text-secondary text-left fw-bolder">
              n.d.
            </span>
          </template>
          <template #cell(sent)="row">
            <span class="text-secondary text-left fw-bolder">{{
            row.item.sent == 1 ? "sì" : "no"
            }}</span>
          </template>
          <template #cell(azioni)="row" v-if="user.type == 1">
            <b-button size="sm" variant="info info_gradient fw-bolder px-3" @click="showThis(row)">Visualizza</b-button>
            <!-- <b-button
              size="sm"
              variant="info info_gradient text-white fw-bolder spacing_1 px-3"
              @click="downloadOne(row)"
              >Scarica</b-button
            > -->
          </template>
          <template #cell(elimina)="row" v-if="user.type == 1">
            <b-button size="sm" variant="danger text-white fw-bolder spacing_1 px-3" @click="deleteFarmacoIrst(row)">
              Elimina
            </b-button>
          </template>
          <template #empty="scope">
            <h6 v-if="scope" class="text-secondary fw-bolder">
              Nessuna terapia inserita
            </h6>
          </template>
        </b-table>

        <!-- <p v-if="surveys.farmaci && surveys.farmaci.length < 1">
          Nessuna terapia
        </p> -->
        <h6 class="
            text-prim-grad-1
            fw-bolder
            text-left
            pb-1
            border-bottom border-line-grey
          ">
          Altre terapie
        </h6>
        <b-table borderless hover responsive table-variant="white text-info" :fields="fieldsAltro"
          :items="surveys.altro" :small="$root.isMobile" show-empty>
          <template #cell(created_at)="row">
            <span class="text-secondary text-left text-capitalize fw-bolder">{{
            row.item.created_at | moment("DD/MM/YYYY")
            }}</span>
          </template>
          <template #cell(tipo_prodotto)="row">
            <span class="text-secondary text-left fw-bolder" v-if="row.item.tipo_prodotto">{{
            row.item.tipo_prodotto.descrizione
            }}</span>
          </template>
          <template #cell(sent)="row">
            <span class="text-secondary text-left fw-bolder">{{
            row.item.sent == 1 ? "sì" : "no"
            }}</span>
          </template>
          <template #cell(data_riconcil)="row">
            <span v-if="row.item.data_riconcil" class="text-secondary text-left fw-bolder">{{ row.item.data_riconcil |
            moment("L")
            }}</span>
            <span v-else class="text-secondary text-left fw-bolder"> no </span>
          </template>
          <template #cell(stato)="row">
            <span v-if="row.item.stato" class="text-secondary text-left fw-bolder">{{ row.item.stato }}</span>
            <span v-else class="text-secondary text-left fw-bolder">
              n.d.
            </span>
          </template>
          <template #cell(azioni)="row" v-if="user.type == 1">
            <b-button size="sm" variant="info info_gradient fw-bolder px-3" @click="showThis(row)">Visualizza</b-button>
            <!-- <b-button
              size="sm"
              variant="info info_gradient text-white fw-bolder spacing_1 px-3"
              @click="downloadOne(row)"
              >Scarica</b-button
            > -->
          </template>
          <template #cell(elimina)="row" v-if="user.type == 1">
            <b-button size="sm" variant="danger text-white fw-bolder spacing_1 px-3" @click="deleteAltroIrst(row)">
              Elimina
            </b-button>
          </template>
          <template #empty="scope">
            <h6 v-if="scope" class="text-secondary fw-bolder">
              Nessuna terapia inserita
            </h6>
          </template>
        </b-table>

        <div class="text-left" v-if="filterSetted.cf !== null && filterSetted.cf.length == 16">
          <b-button v-if="user.type === 1" @click="sendNoVarNotification"
            variant="primary primary_gradient fw-bolder text-white rounded-pill">Notifica nessuna variazione</b-button>
        </div>
        <!-- <p v-if="surveys.altro && surveys.length < 1">Nessuna terapia</p> -->
        <b-modal ref="show-filleditem" hide-footer title="Terapia" size="xl">
          <FilledIrstSurveyShower v-if="selectedItem" :response="selectedItem.filled_survey.response" />
        </b-modal>
      </div>
      <b-spinner v-else-if="searching" class="mt-4 pt-4"></b-spinner>
    </div>
  </div>
</template>
<script>
import { supportService } from "@/_services";
import { mapState, mapActions } from "vuex";
import FilledIrstSurveyShower from "@/components/attivita/FilledIrstSurveyShower.vue";
export default {
  name: "ReportIrst",
  components: { FilledIrstSurveyShower },
  data() {
    return {
      filterSetted: {
        cf: null,
      },
      surveys: null,
      fields: [
        { key: "nome_farmaco", label: "Farmaco", class: "text-left" },
        // { key: "c_minsan", label: "Aic" },
        { key: "created_at", label: "Inserita il" },
        { key: "sent", label: "Inviato" },
        { key: "data_riconcil", label: "Riconciliato" },
        { key: "stato", label: "Stato" },
        { key: "azioni", label: "" },
        { key: "elimina", label: "" },
      ],
      fieldsAltro: [
        { key: "tipo_prodotto", label: "Tipo", class: "text-left" },
        { key: "created_at", label: "Inserita il" },
        { key: "sent", label: "Inviato" },
        { key: "data_riconcil", label: "Riconciliato" },
        { key: "stato", label: "Stato" },
        { key: "azioni", label: "" },
        { key: "elimina", label: "" },
      ],
      template: null,
      selectedItem: null,
      opts: null,
      searching: false,
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
    ...mapState("utente", ["status", "user"]),
  },
  // watch: {
  //   filterSetted: {
  //     handler(prev, post) {
  //       // //console.log("changed prev", prev.status, post);
  //       // //console.log("changed post", post.status);
  //       this.fetchData();
  //     },
  //     deep: true,
  //   },
  // },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    fetchData() {
      this.searching = true;
      this.surveys = null;
      var self = this;
      supportService
        .getIrstTherapies(this.filterSetted)
        .then(function (res) {
          console.log("get therapies res", res.data.data);
          self.surveys = res.data.data;
          self.searching = false;
        })
        .catch(function (err) {
          console.log(err);
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare i report");
          self.searching = false;
        });
    },
    showThis(row) {
      this.selectedItem = row.item;
      this.$refs["show-filleditem"].show();
    },
    getReport() {
      var self = this;
      supportService
        .surveyReport(this.$route.params.quest)
        .then(function (response) {
          let blob = new Blob([response.data]);
          // let blob = new Blob([response.data], { type: "text/csv" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download =
            self.template.name + "_" + self.template.activity.name + ".pdf";
          link.click();
        })
        .catch(function (err) {
          console.log(err);
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare i report");
        });
    },
    getReportXsl() {
      var self = this;
      supportService
        .surveyXlsReport(this.$route.params.quest)
        .then(function (response) {
          let blob = new Blob([response.data]);
          // let blob = new Blob([response.data], { type: "text/csv" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download =
            self.template.name + "_" + self.template.activity.name + ".xlsx";
          link.click();
        })
        .catch(function (err) {
          console.log(err);
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare i report");
        });
    },
    downloadOne(row) {
      //console.log(row);
      var self = this;
      var selected = row;
      supportService
        .downloadFilledSurvey(selected.item.filled_survey.hashid)
        .then(function (response) {
          let blob = new Blob([response.data]);
          // let blob = new Blob([response.data], { type: "text/csv" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = self.filterSetted.cf + "_" + "terapia.pdf";
          link.click();
        })
        .catch(function (err) {
          console.log(err);
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare i report");
        });
    },
    getOptions() {
      var filters = {
        activity: this.$route.query.activity,
      };
      var self = this;
      supportService
        .getSurveys(filters)
        .then(function (response) {
          //console.log("REST", response);
          self.opts = response.data.data.data;
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare le attività");
          console.log(err);
        });
    },
    sendNoVarNotification() {
      var self = this;
      var data = { cf: this.filterSetted.cf };
      supportService
        .sendNoVarNotification(data)
        .then(function (response) {
          //console.log("REST", response);
          window.scrollTo(0, 0);
          self.successAlert("Notifica inviata");
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile inviare la notifica.");
          console.log(err);
        });
    },
    deleteFarmacoIrst(rw) {
      var self = this;
      supportService
        .deleteIrstFarmaco(rw.item.hashid)
        .then(function (response) {
          //console.log("REST", response);
          window.scrollTo(0, 0);
          self.successAlert("Farmaco eliminato");
          self.fetchData();
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile inviare la notifica.");
          console.log(err);
        });
    },
    deleteAltroIrst(rw) {
      var self = this;
      supportService
        .deleteIrstAltro(rw.item.hashid)
        .then(function (response) {
          //console.log("REST", response);
          window.scrollTo(0, 0);
          self.successAlert("Terapia eliminata");
          self.fetchData();
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile inviare la notifica.");
          console.log(err);
        });
    },
  },
  created() {
    // this.fetchData();
    if (this.$route.query.activity && this.user.type === 1) {
      this.getOptions();
    }
  },
  mounted() {
    if (this.$route.query.ucf) {
      this.filterSetted.cf = this.$route.query.ucf;
      this.fetchData();
    }
  },
};
</script>