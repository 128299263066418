<template>
  <div>
    <b-col>
      <b-form-group
        label="  "
        label-for="type"
        class="text-left fs-6 text-secondary"
      >
        <b-form-select
          name="type"
          class="mx-auto shadow_6 select__dis"
          :options="orderOptions"
          v-model="type"
          required
          @change="emitType"
        >
          <b-form-select-option :value="null">scegli tipo</b-form-select-option>
        </b-form-select>
      </b-form-group></b-col
    >
  </div>
</template>
<script>
export default {
  name: "OrderTypeSelection",
  props: ["preselected"],
  data() {
    return {
      orderOptions: ["prestazione", "prodotto", "teleconsulto"],
      type: "prestazione",
    };
  },
  methods: {
    emitType() {
      this.$emit("selected-type", this.type);
    },
  },
  created() {
    if (this.$root.prestazioniOnly) {
      let arrOption = ["prestazione"];
      this.orderOptions = arrOption;
    }
    this.type = this.preselected;
  },
};
</script>