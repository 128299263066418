var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{staticClass:"pls__btn",class:_vm.menuOpen ? ' bg-white-faded' : '',attrs:{"cols":"1"}},[_c('transition-group',{attrs:{"name":"slide","mode":"out-in"}},[(_vm.menuOpen)?_c('b-col',{key:"neworder",staticClass:" px-0"},[_c('div',{staticClass:"d-flex flex-column justify-content-end"},[_c('b-row',{staticClass:"\n            point\n            submenu__border\n            bg-white\n            shadow_6\n            fw-bolder\n            my-2\n            py-2 col-md-10 col-12 col-lg-6 px-0 px-md-2 mr-0 ml-auto\n          ",attrs:{"cols":"2","align-v":"center"},on:{"click":function($event){return _vm.$router.push({
            name: 'Nuovo Ordine',
            params: { type: 'nuova prenotazione' },
          })}}},[_c('span',{staticClass:"text-right"},[_c('strong',{staticClass:"pl-md-2 pl-1 pr-md-2 "},[_vm._v(" Nuova Richiesta")])]),_c('b-col',[_c('b-img',{attrs:{"height":"25%","left":"","src":require('@/assets/facile.svg'),"alt":"logo facile"}})],1)],1),(!_vm.$root.prestazioniOnly)?_c('b-row',{staticClass:"\n            point\n            submenu__border\n            bg-white\n            shadow_6\n            fw-bolder\n            my-2\n            py-2 col-md-10 col-12 col-lg-6 px-0 px-md-2 mr-0 ml-auto\n          ",attrs:{"cols":"2","align-v":"center"},on:{"click":function($event){return _vm.$router.push({
            name: 'Nuovo Ordine',
            params: { type: 'nuovo ordine' },
          })}}},[_c('span',{staticClass:"text-right"},[_c('strong',{staticClass:"pl-md-2 pl-1 pr-md-2 "},[_vm._v(" Nuovo Ordine ")])]),_c('b-col',[_c('b-img',{attrs:{"height":"25%","left":"","src":require('@/assets/facile.svg'),"alt":"logo facile"}})],1)],1):_vm._e(),(!_vm.$root.prestazioniOnly)?_c('b-row',{staticClass:"\n            point\n            submenu__border\n            bg-white\n            shadow_6\n            fw-bolder\n            my-2\n            py-2 col-md-10 col-12 col-lg-6 px-0 px-md-2 mr-0 ml-auto\n          ",attrs:{"cols":"2","align-v":"center"},on:{"click":function($event){return _vm.$router.push({
            name: 'Nuovo Ordine',
            params: { type: 'nuovo teleconsulto' },
          })}}},[_c('span',{staticClass:"text-right"},[_c('strong',{staticClass:"pl-md-2 pl-1 pr-md-2 "},[_vm._v(" Nuovo Teleconsulto ")])]),_c('b-col',[_c('b-img',{attrs:{"height":"25%","left":"","src":require('@/assets/connessa.svg'),"alt":"logo facile"}})],1)],1):_vm._e()],1)]):_vm._e(),_c('b-col',{key:"openbtn"},[_c('div',{staticClass:"d-flex justify-content-end mt-2"},[_c('span',{staticClass:"ml-2 point",on:{"click":function($event){_vm.menuOpen = !_vm.menuOpen}}},[_c('b-iconstack',{attrs:{"font-scale":"3"}},[_c('b-icon',{attrs:{"stacked":"","icon":"circle-fill","variant":"primary"}}),_c('b-icon',{attrs:{"stacked":"","icon":_vm.menuOpen ? 'x' : 'plus',"scale":"0.8","variant":"white"}}),_c('b-icon',{attrs:{"stacked":"","icon":"circle","variant":"primary"}})],1)],1)])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }