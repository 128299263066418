<template>
  <div class="py-3">
    <div v-if="template" class="fw-bolder">
      <b-form @submit.prevent="sendForm">
        <b-row cols="1" class="align-items-end">
          <transition-group name="list" mode="out-in" appear>
            <b-row
              v-for="(domanda, ind) in template.template.domande"
              :key="ind"
            >
              <b-col
                class="col-12 my-2"
                id="verifica_terapie_col"
                v-if="domanda.o && domanda.o === 'ricognizione'"
              >
                <h6 class="text-justify">{{ ind + 1 + ". " + domanda.q }}</h6>
                <b-row align-v="stretch">
                  <b-col
                    class="text-justify fw-bolder text-medium-grey bg-light general_border"
                  >
                    <p class="my-2 py-2 fs-6">
                      Verifica ed aggiorna le terapie del paziente cliccando su
                      "Verifica terapia paziente". Dopo aver aggiornato le
                      terapie, completa ciascuna scheda con le informazioni
                      mancanti.
                    </p>
                  </b-col>
                  <b-col class="align-self-center">
                    <b-button 
                    @click="$bvModal.show('terapie_paziente')"
                      >Verifica terapie paziente</b-button
                    ></b-col
                  >
                </b-row>
                <template v-if="schemaRicognizione">
                  <b-row
                    v-for="(therapy, index) in schemaRicognizione"
                    class="px-2 mt-2"
                  >
                    <h6
                      class="text-left bg-prim-grad-1 text-white fw-bolder p-2 w-100"
                    >
                      <span class="bg-secondary p-2"
                        >Terapia {{ index + 1 }}.</span
                      >
                      {{ therapy[0].a }}
                    </h6>
                    <b-col>
                      <b-row cols="1" cols-md="2">
                        <b-col v-for="(field, position) in therapy">
                          <b-form-group class="text-left">
                            <label>{{ field.q }}:</label>
                            <template v-if="field.t === 'select'">
                              <b-form-select
                                class="shadow_6 align-self-center"
                                v-model="schemaRicognizione[index][position].a"
                                :name="'ric_' + index"
                                required
                              >
                                <b-form-select-option
                                  v-for="opt in field.o"
                                  :key="opt"
                                  :value="opt"
                                  >{{ opt }}
                                </b-form-select-option>
                                <b-form-select-option :value="null" disabled
                                  >scegli..
                                </b-form-select-option>
                              </b-form-select>
                            </template>
                            <template v-else-if="field.t === 'datetime'">
                              <!-- <b-form-input
                                class="shadow_6"
                                type="date"
                                placeholder="GG/MM/AAAA hh:mm"
                                :name="'ric_date_' + index"
                                v-model="dateInDatetime"
                                :readonly="field.i == false"
                              ></b-form-input>
                              <b-form-input
                                class="shadow_6"
                                type="time"
                                placeholder="GG/MM/AAAA hh:mm"
                                :name="'ric_time_' + index"
                                @input="
                                  dateTimeTimeInput($event, index, position)
                                "
                                :readonly="field.i == false"
                              ></b-form-input> -->
                              <b-form-input
                                class="shadow_6"
                                type="text"
                                placeholder="GG/MM/AAAA hh:mm"
                                :name="'ric_' + index"
                                v-model="schemaRicognizione[index][position].a"
                                :readonly="field.i == false"
                                v-bind="
                                  field.r && field.i !== false
                                    ? { required: true }
                                    : {}
                                "
                              ></b-form-input>
                            </template>
                            <b-form-input
                              v-else
                              class="shadow_6"
                              :type="field.t"
                              v-model="schemaRicognizione[index][position].a"
                              :readonly="field.i == false"
                              v-bind="
                                field.r && field.i !== false
                                  ? { required: true }
                                  : {}
                              "
                              :name="'ric_' + index"
                            ></b-form-input>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </template>
                <b-spinner v-else></b-spinner>
              </b-col>
              <template v-else>
                <b-col class="text-justify col-12 col-md-6">
                  <label :for="'q_' + ind"
                    >{{ ind + 1 + ". " + domanda.q }}
                    <span v-if="domanda.h" class="mb-0 text-primary">
                      - domanda per l'operatore</span
                    >
                  </label>
                </b-col>
                <b-col class="text-justify">
                  <b-form-group :key="'fgr_' + ind" :id="'q_' + ind">
                    <div
                      v-if="domanda.o && domanda.o === 'file'"
                      class="align-self-center"
                    >
                      <SurveyUploader
                        v-on:upload-result="handleUpload($event, ind)"
                        v-on:remove-result="removeUpload(ind)"
                      />
                      <b-button
                        v-if="!template.template.domande[ind].a"
                        class="mt-3"
                        variant="secondary fw-bolder"
                        @click="
                          template.template.domande[ind].a =
                            'immagine non allegata'
                        "
                        >Salta</b-button
                      >
                    </div>
                    <b-form-checkbox-group
                      v-else-if="domanda.cb && domanda.o"
                      :name="'q_' + ind"
                      v-model="domanda.a"
                      :options="domanda.o"
                      name="flavour-1"
                    ></b-form-checkbox-group>

                    <b-form-select
                      class="shadow_6 align-self-center"
                      v-else-if="domanda.o"
                      v-model="domanda.a"
                      :name="'q_' + ind"
                      required
                    >
                      <b-form-select-option
                        v-for="opt in domanda.o"
                        :key="opt"
                        :value="opt"
                        >{{ opt }}
                      </b-form-select-option>
                      <!-- <b-form-select-option :value="null" disabled>seleziona</b-form-select-option> -->
                    </b-form-select>
                    <b-form-input
                      v-else-if="domanda.t"
                      class="shadow_6 align-self-center"
                      placeholder="..."
                      :type="domanda.t"
                      :name="'q_' + ind"
                      v-model="domanda.a"
                    ></b-form-input>
                    <b-form-input
                      v-else
                      class="shadow_6 align-self-center"
                      placeholder="..."
                      type="text"
                      :name="'q_' + ind"
                      v-model="domanda.a"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </template>
            </b-row>
            <b-col class="my-4 py-2" key="btsubmt">
              <b-spinner v-if="loading"></b-spinner>
              <b-button
                v-else
                type="submit"
                variant="primary primary_gradient text-white fw-bolder spacing_1"
              >
                Invia</b-button
              >
            </b-col>
          </transition-group>
        </b-row>
      </b-form>
    </div>
    <b-modal
      id="terapie_paziente"
      hide-footer
      centered
      scrollable
      size="xl"
      title="Aggiorna terapie paziente"
      @hide="fetchUserTherapies"
    >
      <ArmadiettoComponent
        :subject="{ type: 'user_in_app', hashid: patient.hashid }"
        :ricognizione="true"
      ></ArmadiettoComponent>
    </b-modal>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { cittadinoService } from "@/_services";
import SurveyUploader from "@/components/utilities/SurveyUploader.vue";
import ArmadiettoComponent from "@/components/profile/sanitario/ArmadiettoComponent.vue";
export default {
  name: "CompilaQuestionarioRicognizione",
  props: ["survey", "patient"],
  components: { SurveyUploader, ArmadiettoComponent },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
  },
  data() {
    return {
      template: null,
      consent: false,
      proceed: false,
      file: null,
      therapies: null,
      schemaRicognizione: null,
      loading: false,
      dateInDatetime: null,
    };
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
    }),
    dateTimeTimeInput(value, index, position) {
      console.log(value, index, position);
      if (value && value !== "" && this.dateInDatetime !== null) {
        const fulldate = this.dateInDatetime + " " + value;
        this.schemaRicognizione[index][position].a = fulldate;
      }
    },
    onFileChange(e) {
      this.file = e.target.files[0];
    },
    sendForm() {
      if (!this.validateSurvey(this.template.template)) {
        return;
      }
      this.template.template.ricognizione = this.schemaRicognizione;
      this.loading = true;
      // this.$emit("filled-form", this.template);

      this.$emit("filled-form", this.template);
    },
    handleUpload(data, ind) {
      if (this.template.template.domande[ind].a == null) {
        this.template.template.domande[ind].a = [];
      }
      this.template.template.domande[ind].a.push(data.hashid);
    },
    removeUpload(ind) {
      this.template.template.domande[ind].a = null;
    },
    findQuestionByKeyName(name) {
      return this.template.template.domande.find((d) => d.k === name);
    },
    fetchUserTherapies() {
      const self = this;
      var filters = {
        user: this.patient.hashid,
        scope: "ricognizione",
      };
      cittadinoService
        .getTherapies(filters)
        .then(function (response) {
          self.therapies = response.data.data;
          self.setupRicognizioneSchema(response.data.data);
        })
        .catch(function (error) {
          console.log("error", error);
          self.errorAlert("Non è stato possibile recuperare le terapie");
        });
    },
    setupRicognizioneSchema(terapie) {
      if (this.template.template.ricognizione) {
        this.schemaRicognizione = null;
        const self = this;
        var arraySchema = [];
        const terLen = terapie.length;
        for (let i = 0; i < terLen; i++) {
          // console.log(terapie[i].product_name);
          let schemaTemplate = [];
          Object.keys(self.template.template.ricognizione).map(function (el) {
            var answr = null;
            if (self.template.template.ricognizione[el].k) {
              answr = terapie[i][self.template.template.ricognizione[el].k];
            }
            let newObj = {
              k: self.template.template.ricognizione[el].q,
              i: self.template.template.ricognizione[el].i,
              t: self.template.template.ricognizione[el].t,
              o: self.template.template.ricognizione[el].o,
              a: answr,
              q: self.template.template.ricognizione[el].q,
              r: self.template.template.ricognizione[el].r
            };
            schemaTemplate.push(newObj);
          });
          arraySchema.push(schemaTemplate);
        }
        this.schemaRicognizione = arraySchema;
      }
    },
    validateSurvey(template) {
      for (let ind = 0; ind < template.domande.length; ind++) {
        if (
          template.domande[ind].r === true &&
          !template.domande[ind].a &&
          (template.domande[ind].o !== "ricognizione" ||
            this.schemaRicognizione === null ||
            (Array.isArray(this.schemaRicognizione) &&
              this.schemaRicognizione.length === 0))
        ) {
          const elementId = "q_" + ind;
          this.errorAlert(
            "Compila il campo obbligatorio: " + template.domande[ind].q
          );
          const element = document.getElementById(elementId);
          if (element) {
            element.scrollIntoView();
          } else if (template.domande[ind].o === "ricognizione") {
            document.getElementById("verifica_terapie_col").scrollIntoView();
          }
          this.loading = false;
          return false;
        }
      }
      for (let ind = 0; ind < template.ricognizione.length; ind++) {
        for (let pos = 0; pos < template.ricognizione[ind].length; pos++) {
          const doCheck =
            pos <= 6 || (pos > 6 && template.ricognizione[ind][6].a === "sì");
          if (
            doCheck &&
            template.ricognizione[ind][pos].r === true &&
            !template.ricognizione[ind][pos].a
          ) {
            const elementId = "ric_" + ind + "_" + pos;
            this.errorAlert(
              "Compila per la terapia " +
                (ind + 1) +
                " il campo obbligatorio: " +
                template.ricognizione[ind][pos].q
            );
            document.getElementById(elementId).scrollIntoView();
            this.loading = false;
            return false;
          }
        }
      }

      return true;
    },
    setPatientData() {
      if (this.patient && this.patient.anagrafica) {
        const ageInput = this.findQuestionByKeyName("eta_assistito");
        if (ageInput) {
          ageInput.a = this.patient.anagrafica.eta;
        }
        const tsNumberInput = this.findQuestionByKeyName("ts_num_assistito");
        if (tsNumberInput) {
          tsNumberInput.a = this.patient.anagrafica.ts_number;
        }
      }
    },
  },
  created() {
    if (this.survey) {
      this.template = this.survey;

      // console.log(this.template);
      try {
        var parsed = JSON.parse(this.survey.template);
        this.template.template = parsed;
      } catch {
        //
      }
      this.fetchUserTherapies();
    }
  },
  mounted() {
    window.scrollTo(0, 0);
    this.setPatientData();
  },
};
</script>
<style scoped>
.form-control[readonly] {
  background-color: #168bb412 !important;
}
</style>
