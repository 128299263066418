<template>
  <div v-if="detail">
    <div class="d-flex justify-content-center"></div>
    <p class="mb-0" v-if="detail">
      Note sul ritiro:
      <strong>{{ detail }} </strong>
    </p>
  </div>
</template>
<script>
export default {
  name: "DetailRitiro",
  props: ["detail", "stato"],
  data() {
    return {};
  },
};
</script>