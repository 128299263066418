<template>
  <div class="py-3">
    <div v-if="template" class="fw-bolder">
      <!-- <p class="text-justify text-medium-grey fw-bolder">
        {{ template.template.domande.length }} quesiti.
      </p> -->

      <b-form @submit.prevent="handleSubmit">
        <b-row cols="1" class="align-items-end">
          <transition-group name="list" mode="out-in" appear>
            <b-spinner v-if="calcInProgress"></b-spinner>
            <template v-else>
              <template v-if="!esito && !loading">
                <b-row
                  cols="1"
                  cols-md="2"
                  v-for="(domanda, ind) in template.template.domande"
                  :key="ind"
                >
                  <template v-if="!domanda.h">
                    <b-col class="text-justify">
                      <label :for="'q_' + ind"
                        >{{ ind + 1 + ". " + domanda.q }}
                        <span v-if="domanda.h" class="mb-0 text-primary">
                          - domanda per l'operatore</span
                        >
                      </label>
                    </b-col>
                    <b-col class="text-justify">
                      <b-form-group :key="'fgr_' + ind">
                        <div
                          v-if="domanda.o && domanda.o === 'file'"
                          class="align-self-center"
                        >
                          <SurveyUploader
                            v-on:upload-result="handleUpload($event, ind)"
                            v-on:remove-result="removeUpload(ind)"
                          />
                          <b-button
                            v-if="!template.template.domande[ind].a"
                            class="mt-3"
                            variant="secondary fw-bolder"
                            @click="
                              template.template.domande[ind].a =
                                'immagine non allegata'
                            "
                            >Salta</b-button
                          >
                        </div>
                        <template v-else-if="domanda.cb && domanda.o">
                          <b-form-checkbox-group
                            :name="'q_' + ind"
                            v-model="domanda.a"
                            :options="domanda.o"
                            name="flavour-1"
                          ></b-form-checkbox-group>
                          <b-form-input
                            placeholder="altra motivazione..."
                            type="text"
                            class="shadow_6 align-self-center border-bottom border-primary"
                            v-model="addendums[ind]"
                            v-if="domanda.a.includes('altro')"
                            :required="domanda.r"
                          ></b-form-input>
                        </template>
                        <template v-else-if="domanda.o">
                          <b-form-select
                            v-model="domanda.a"
                            class="shadow_6 align-self-center"
                            :name="'q_' + ind"
                            :required="domanda.r"
                            :disabled="
                              domanda.k && readOnlySelect.includes(domanda.k)
                                ? true
                                : false
                            "
                          >
                            <b-form-select-option
                              v-for="opt in domanda.o"
                              :key="opt"
                              :value="opt"
                              >{{ opt }}
                            </b-form-select-option>
                            <!-- <b-form-select-option :value="null" disabled>seleziona</b-form-select-option> -->
                          </b-form-select>
                          <span
                            v-if="domanda.lt && domanda.a === 'sì'"
                            class="point pt-2"
                          >
                            <a
                              :href="domanda.lt"
                              target="_blank"
                              class="text-prim-grad-1"
                            >
                              {{ domanda.lv }}
                            </a>
                          </span>
                        </template>
                        <template v-else-if="domanda.d">
                          <b-row>
                            <b-col sm="6">
                              <label :for="'q_' + ind + '_min'">Min.</label>
                              <b-form-input
                                class="shadow_6 mb-2"
                                type="number"
                                step="1"
                                max="400"
                                :name="'q_' + ind + '_min'"
                                v-model="minP[ind]"
                                :required="
                                  domanda.r !== undefined ? domanda.r : true
                                "
                                :change="setAnswer(minP[ind], maxP[ind], ind)"
                              ></b-form-input>
                            </b-col>
                            <b-col sm="6">
                              <label :for="'q_' + ind + '_max'">Max.</label>
                              <b-form-input
                                class="shadow_6 mb-2"
                                type="number"
                                step="1"
                                max="400"
                                :name="'q_' + ind + '_max'"
                                v-model="maxP[ind]"
                                :required="
                                  domanda.r !== undefined ? domanda.r : true
                                "
                                :change="setAnswer(minP[ind], maxP[ind], ind)"
                              ></b-form-input>
                            </b-col>
                          </b-row>
                        </template>
                        <template
                          v-else-if="domanda.k && domanda.k === 'bmi_altezza'"
                        >
                          <b-form-input
                            class="shadow_6 align-self-center"
                            type="number"
                            v-model="domanda.a"
                            @input="handleAltezzaInput($event, ind)"
                            placeholder="..."
                            id="bmi_altezza"
                            :min="80"
                            :max="250"
                            :step="1"
                            :state="validAltezza"
                            onkeydown="return ![69, 108, 110, 188, 190].includes(event.keyCode)"
                          />
                        </template>
                        <template
                          v-else-if="domanda.k && domanda.k === 'bmi_peso'"
                        >
                          <b-form-input
                            class="shadow_6 align-self-center"
                            type="number"
                            v-model="domanda.a"
                            @input="handlePesoInput($event, ind)"
                            selectCirconferenzaVita
                            placeholder="..."
                            id="bmi_peso"
                            :min="30"
                            :max="200"
                            :step="1"
                            :state="validPeso"
                            required
                            onkeydown="return ![69, 108, 110, 188, 190].includes(event.keyCode)"
                          />
                        </template>
                        <template
                          v-else-if="domanda.k && domanda.k === 'bmi_valore'"
                        >
                          <b-form-input
                            class="shadow_6 align-self-center"
                            type="number"
                            v-model="domanda.a"
                            :id="'bmi_valore_' + domanda.k"
                            placeholder="..."
                            readonly
                            required
                            style="border: none"
                          />
                        </template>
                        <template
                          v-else-if="domanda.k && domanda.k === 'vita_valore'"
                        >
                          <b-form-input
                            class="shadow_6 align-self-center"
                            type="number"
                            v-model="domanda.a"
                            :id="'vita_valore' + domanda.k"
                            placeholder="..."
                            @input="selectCirconferenzaVita($event, ind)"
                            :min="30"
                            :max="250"
                            :step="1"
                            required
                            :state="validVita"
                            onkeydown="return ![69, 108, 110, 188, 190].includes(event.keyCode)"
                          />
                        </template>
                        <b-form-input
                          v-else
                          class="shadow_6 align-self-center"
                          placeholder="..."
                          type="text"
                          :name="'q_' + ind"
                          v-model="domanda.a"
                          :required="domanda.r"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </template>
                </b-row>
                <!-- <b-col class="my-4 py-2" key="btsubmt" v-if="canSubmit">
              <b-spinner v-if="loading"></b-spinner>
              <b-button
                v-else
                type="submit"
                variant="primary primary_gradient text-white fw-bolder spacing_1"
              >
                Invia</b-button
              >
            </b-col>
            <b-col class="my-2 py-2" key="btn_calc" v-else> -->
                <b-col class="my-2 py-2" key="btn_calc">
                  <b-spinner v-if="loading"></b-spinner>
                  <b-button
                    v-else
                    type="submit"
                    variant="primary primary_gradient text-white fw-bolder spacing_1"
                    :disabled="canSubmit"
                  >
                    Calcola rischio</b-button
                  >
                </b-col>
              </template>
              <b-col v-else key="esito">
                <h5></h5>
                <h5 class="text-center mt-3">
                  Punteggio totalizzato: {{ esito.score }}
                </h5>
                <h6>
                  L'assistito è inquadrato nel Gruppo 2 quale soggetto a
                  rischio. Proporre di effettuare in farmacia un test
                  autodiagnostico di rilevazione della glicemia capillare.
                </h6>
                <b-row cols="1">
                  <b-col class="mt-4">
                    <p class="mb-0">Ho acquisito il</p>
                    <b-form-radio-group
                      name="q_consenso"
                      class="text-primary"
                      @change="handleConsenso"
                      :options="[
                        { text: 'Consenso', value: 'sì' },
                        { text: 'Diniego', value: 'no' },
                      ]"
                    ></b-form-radio-group>

                    <p>
                      dell'assistito a sottoporsi al test autodiagnostico di
                      rilevazione della glicemia capillare.
                    </p>
                  </b-col>
                  <b-col
                    v-if="
                      template.template.domande[
                        template.template.domande.length - 3
                      ].a === 'no'
                    "
                  >
                    <b-spinner v-if="loading"></b-spinner>
                    <b-button
                      v-else
                      type="submit"
                      variant="primary primary_gradient text-white fw-bolder spacing_1"
                    >
                      Salva e invia</b-button
                    >
                  </b-col>
                  <b-col
                    v-else-if="
                      template.template.domande[
                        template.template.domande.length - 3
                      ].a === 'sì'
                    "
                  >
                    <b-row
                      cols="1"
                      cols-md="2"
                      class="mt-4"
                      :key="
                        template.template.domande[
                          template.template.domande.length - 2
                        ].k
                      "
                    >
                      <b-col class="text-justify">
                        <label
                          :for="
                            'q_' +
                            template.template.domande[
                              template.template.domande.length - 2
                            ].k
                          "
                          >{{
                            template.template.domande[
                              template.template.domande.length - 2
                            ].q
                          }}
                          <span
                            v-if="
                              template.template.domande[
                                template.template.domande.length - 2
                              ].h
                            "
                            class="mb-0 text-primary"
                          >
                            - domanda per l'operatore</span
                          >
                        </label>
                      </b-col>

                      <b-col class="text-justify">
                        <b-form-group
                          :key="
                            'fgr_' +
                            template.template.domande[
                              template.template.domande.length - 2
                            ].k
                          "
                        >
                          <b-form-input
                            class="shadow_6 align-self-center"
                            type="number"
                            v-model="
                              template.template.domande[
                                template.template.domande.length - 2
                              ].a
                            "
                            :id="
                              'q' +
                              template.template.domande[
                                template.template.domande.length - 2
                              ].k
                            "
                            placeholder="..."
                            :min="30"
                            :max="450"
                            :step="1"
                            :state="validGlicemia"
                            required
                            onkeydown="return ![69, 108, 110, 188, 190].includes(event.keyCode)"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row
                      cols="1"
                      cols-md="2"
                      class="mt-4"
                      :key="
                        template.template.domande[
                          template.template.domande.length - 1
                        ].k
                      "
                    >
                      <b-col class="text-justify">
                        <label
                          :for="
                            'q_' +
                            template.template.domande[
                              template.template.domande.length - 1
                            ].k
                          "
                          >{{
                            template.template.domande[
                              template.template.domande.length - 1
                            ].q
                          }}
                          <span
                            v-if="
                              template.template.domande[
                                template.template.domande.length - 1
                              ].h
                            "
                            class="mb-0 text-primary"
                          >
                            - domanda per l'operatore</span
                          >
                        </label>
                      </b-col>

                      <b-col class="text-justify">
                        <b-form-group
                          :key="
                            'fgr_' +
                            template.template.domande[
                              template.template.domande.length - 1
                            ].k
                          "
                        >
                          <b-form-select
                            class="shadow_6 align-self-center"
                            type="number"
                            v-model="
                              template.template.domande[
                                template.template.domande.length - 1
                              ].a
                            "
                            :id="
                              'q' +
                              template.template.domande[
                                template.template.domande.length - 1
                              ].k
                            "
                            placeholder="..."
                            :min="30"
                            :max="450"
                            :step="1"
                            required
                          >
                            <b-form-select-option
                              v-for="opt in template.template.domande[
                                template.template.domande.length - 1
                              ].o"
                              :key="opt"
                              :value="opt"
                              >{{ opt }}
                            </b-form-select-option>
                          </b-form-select>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-spinner v-if="loading"></b-spinner>
                    <b-button
                      v-else
                      type="submit"
                      variant="primary primary_gradient text-white fw-bolder spacing_1"
                    >
                      Salva e invia</b-button
                    >
                  </b-col>
                </b-row>
              </b-col>
            </template>
          </transition-group>
        </b-row>
      </b-form>
    </div>
  </div>
</template>
<script>
import { supportService } from "@/_services";
import { mapState, mapActions } from "vuex";
import SurveyUploader from "@/components/utilities/SurveyUploader.vue";
export default {
  name: "CompilaQuestionarioScoredDiabetePuglia",
  props: ["survey", "patient"],
  components: { SurveyUploader },
  data() {
    return {
      template: null,
      consent: false,
      proceed: false,
      file: null,
      addendums: [],
      loading: false,
      minP: [],
      maxP: [],
      readOnlySelect: ["bmi_select", "circonferenza_select"],
      esito: null,
      canSubmit: null,
      calcInProgress: false,
      canContinue: false,
    };
  },
  computed: {
    validAltezza: function () {
      const altezzaInput = this.findQuestionByKeyName("bmi_altezza");
      const checkFormatOnlyDigits = /^\d+$/.test(altezzaInput.a);
      if (altezzaInput.a && !checkFormatOnlyDigits) {
        return false;
      }
      const altezzaValue = Number(altezzaInput.a);
      if (altezzaValue !== null) {
        return !Number.isNaN(altezzaValue) && Number.isInteger(altezzaValue)
          ? null
          : false;
      }
      return null;
    },
    validPeso: function () {
      const pesoInput = this.findQuestionByKeyName("bmi_peso");
      const checkFormatOnlyDigits = /^\d+$/.test(pesoInput.a);
      if (pesoInput.a && !checkFormatOnlyDigits) {
        return false;
      }
      const pesoValue = Number(pesoInput.a);
      if (pesoValue !== null) {
        return !Number.isNaN(pesoValue) && Number.isInteger(pesoValue)
          ? null
          : false;
      }
      return null;
    },
    validVita: function () {
      const vitaInput = this.findQuestionByKeyName("vita_valore");
      const checkFormatOnlyDigits = /^\d+$/.test(vitaInput.a);
      if (vitaInput.a && !checkFormatOnlyDigits) {
        return false;
      }
      const vitaValue = Number(vitaInput.a);
      if (vitaValue !== null) {
        return !Number.isNaN(vitaValue) && Number.isInteger(vitaValue)
          ? null
          : false;
      }
      return null;
    },
    validGlicemia: function () {
      const glicemiaInput = this.findQuestionByKeyName("glicemia_capillare");
      const checkFormatOnlyDigits = /^\d+$/.test(glicemiaInput.a);
      if (glicemiaInput.a && !checkFormatOnlyDigits) {
        return false;
      }
      const glicemiaValue = Number(glicemiaInput.a);
      if (glicemiaValue !== null) {
        return !Number.isNaN(glicemiaValue) && Number.isInteger(glicemiaValue)
          ? null
          : false;
      }
      return null;
    },
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    onFileChange(e) {
      this.file = e.target.files[0];
    },
    setAnswer(min, max, ind) {
      if (min > 0 && max > 0)
        this.template.template.domande[ind].a = min + "/" + max;
      else this.template.template.domande[ind].a = null;
    },
    handleSubmit() {
      if (this.canSubmit || this.canContinue) {
        this.sendForm();
        return true;
      }
      this.calcScore();
    },
    sendForm() {
      if (this.addendums.length > 0) {
        const self = this;
        this.addendums.forEach(function (element, ind) {
          if (
            self.template.template.domande[ind] &&
            self.template.template.domande[ind].a.includes("altro")
          ) {
            const altroIndex = self.template.template.domande[ind].a.indexOf(
              "altro"
            );
            self.template.template.domande[ind].a[altroIndex] =
              "altro: " + element;
          }
        });
      }
      this.loading = true;
      this.$emit("filled-form", this.template);
    },
    handleUpload(data, ind) {
      if (this.template.template.domande[ind].a == null) {
        this.template.template.domande[ind].a = [];
      }
      this.template.template.domande[ind].a.push(data.hashid);
    },
    removeUpload(ind) {
      this.template.template.domande[ind].a = null;
    },
    handleAltezzaInput(event, index) {
      // const index = this.template.template.domande.findIndex(d => d.k === 'bmi_altezza');
      // if (index !== -1) {
      this.template.template.domande[index].a = event;
      this.calculateBMI();
      // }
    },
    handlePesoInput(event, index) {
      // const index = this.template.template.domande.findIndex(
      //   (d) => d.k === "bmi_peso"
      // );
      // if (index !== -1) {
      this.template.template.domande[index].a = event;
      this.calculateBMI();
      // }
    },
    calculateBMI() {
      const altezzaDomanda = this.findQuestionByKeyName("bmi_altezza");
      const pesoDomanda = this.findQuestionByKeyName("bmi_peso");
      const bmiDomanda = this.findQuestionByKeyName("bmi_valore");
      const bmiSelect = this.findQuestionByKeyName("bmi_select");

      if (
        altezzaDomanda &&
        pesoDomanda &&
        bmiDomanda &&
        altezzaDomanda.a &&
        pesoDomanda.a
      ) {
        const altezzaInMetri = altezzaDomanda.a / 100;
        const peso = pesoDomanda.a;
        const bmi = peso / (altezzaInMetri * altezzaInMetri);
        bmiDomanda.a = bmi.toFixed(2);
        var option = bmiSelect.o[0];
        if (bmiDomanda.a > 30) {
          option = bmiSelect.o[2];
        } else if (bmiDomanda.a > 25) {
          option = bmiSelect.o[1];
        }
        bmiSelect.a = option;
      }
      this.selectAgeRange();
    },
    selectAgeRange() {
      const selectAge = this.findQuestionByKeyName("age_select");
      if (this.patient && this.patient.anagrafica) {
        const age = this.patient.anagrafica.eta;
        var option = selectAge.o[0];
        if (age > 64) {
          option = selectAge.o[3];
        } else if (age > 54) {
          option = selectAge.o[2];
        } else if (age > 44) {
          option = selectAge.o[1];
        }
        selectAge.a = option;
      }
    },
    selectCirconferenzaVita() {
      const domandaValoreVita = this.findQuestionByKeyName("vita_valore");
      const selectValoreVita = this.findQuestionByKeyName(
        "circonferenza_select"
      );
      const valoreVita = domandaValoreVita.a;
      if (this.patient && this.patient.anagrafica) {
        const gender = this.getGender();
        var option = null;
        const firstRange = gender === "maschile" ? 102 : 88;
        const secondRange = gender === "maschile" ? 93 : 79;
        option = selectValoreVita.o[0];
        if (valoreVita > firstRange) {
          option = selectValoreVita.o[2];
        } else if (valoreVita > secondRange) {
          option = selectValoreVita.o[1];
        }
        selectValoreVita.a = option;
      }
    },
    handleConsenso(value) {
      const domandaConsenso = this.findQuestionByKeyName("consenso");
      domandaConsenso.a = value;
      this.canSubmit = value === "no";
      this.canContinue = value === "sì";
    },
    findQuestionByKeyName(name) {
      return this.template.template.domande.find((d) => d.k === name);
    },
    getGender() {
      if (this.patient && this.patient.anagrafica) {
        var cf = this.patient.anagrafica.cf;
        let getDay = parseInt(cf.substr(9, 2));
        return getDay > 31 ? "femminile" : "maschile";
      }
      return null;
    },
    calcScore(form) {
      this.calcInProgress = true;
      const self = this;
      const formData = {
        response: JSON.stringify(this.template.template),
        survey_id: this.$route.params.quest,
        user_id: this.patient ? this.patient.hashid : undefined,
      };

      supportService
        .calcSurveyScore(formData)
        .then(function (res) {
          self.esito = res.data.data;
          if (res.data.data.score < 11) {
            self.sendForm();
          }
          self.calcInProgress = false;
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile calcolare il punteggio");
          self.calcInProgress = false;
          console.log(err);
        });
    },
    // keydownCheck(event) {
    //   const prohibitedChars = [69, 108, 110, 188, 190];
    //   console.log(event.keyCode);
    //   return !prohibitedChars.includes(event.keyCode);
    //   [69, 108, 110, 188, 190].includes(event.keyCode);
    // },
  },
  created() {
    if (this.survey) {
      this.template = this.survey;
      try {
        var parsed = JSON.parse(this.survey.template);
        this.template.template = parsed;
      } catch {
        //
      }
    }
    console.log(" DIABETE PUGLIA");
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
