<template>
  <div class="col-12 px-0">
    <div v-if="!hasResult">
      <transition-group name="slideside" mode="out-in">
        <b-form key="geo-search" v-if="!formSearch">
          <b-button variant="outline-primary" @click="formSearch = true" class="mb-2" block>
            <b-icon icon="arrow-bar-left"></b-icon><b> Torna alla ricerca per campi </b>
          </b-button>
          <p v-if="results && results.length == 0" class="text-danger mb-0">
            Nessun risultato con questi parametri di ricerca.
          </p>
          <p class="text-left">
            Utilizza il campo "range" per aumentare o diminuire il raggio di
            ricerca
          </p>
          <p class="text-left text-danger" v-if="!userCoordinates">
            Per utilizzare questa funzionalità devi acconsentire alla
            condivisione della tua posizione.
            <b class="point" @click="startGeolocal">Concedi consenso</b>
          </p>
          <b-form-group class="col-12 col-md-8">
            <label for="range-2" class="text-primary font-weight-bolder">Range:</label>
            <b-form-input id="range-2" v-model="searchFilters.radius" type="range" :min="50" :max="1000" step="50">
            </b-form-input>
          </b-form-group>

          <span class="my-2 col-12 col-md-3">{{ searchFilters.radius }} metri</span>
          <div class="text-center">
            <b-button variant="primary primary_gradient" @click="doGeolocalSearch">
              <span v-if="!spinSearch"> Ripeti ricerca</span>
              <b-spinner v-else></b-spinner>
            </b-button>
          </div>
        </b-form>
        <b-form key="form-search" v-else v-on:submit.prevent="submitSearch" v-on:reset.prevent="clearFilters">
          <b-button variant="outline-primary" @click="startGeolocal" class="mb-2" block><b> Cerca farmacia vicina
              utilizzando la mia posizione </b>
            <b-icon icon="geo-alt-fill"></b-icon>
          </b-button>
          <p class="text-left">
            Compila uno o più campi e premi su "cerca" per visualizzare i
            risultati della ricerca
          </p>
          <div>
            <div class="col-12 d-flex flex-wrap px-0">
              <b-form-group label="Regione" label-for="regione"
                class="text-left fs-6 text-font-grey mx-auto col-12 col-md-6">
                <b-form-select class="my-3 shadow_6 text-capitalize" :options="regioni" name="regione"
                  v-model="selected.regione" @change="getProvince(selected)" required>
                  <b-form-select-option :value="null">Seleziona Regione</b-form-select-option>
                </b-form-select>
              </b-form-group>
              <b-form-group label="Provincia" label-for="provincia"
                class="text-left fs-6 text-font-grey mx-auto col-12 col-md-6">
                <b-form-select class="my-3 shadow_6 text-capitalize" :options="province" name="provincia"
                  v-model="selected.provincia" @change="getComuni(selected)" required>
                  <b-form-select-option :value="null">Seleziona Provincia</b-form-select-option>
                </b-form-select>
              </b-form-group>
            </div>
            <div class="col-12 d-flex flex-wrap px-0">
              <b-form-group label="Comune" label-for="comune"
                class="text-left fs-6 text-font-grey mx-auto col-12 col-md-6">
                <b-form-select class="my-3 shadow_6 text-capitalize" name="comune" v-model="selected.comune" required>
                  <b-form-select-option v-for="(com, ind) in comuni" :key="ind" :value="com" @change="setData">{{
                      com.comune
                  }}</b-form-select-option>
                  <b-form-select-option :value="null">Seleziona Comune</b-form-select-option>
                </b-form-select>
              </b-form-group>
              <b-form-group label="Frazione" label-for="frazione"
                class="text-left fs-6 text-font-grey mx-auto col-12 col-md-6">
                <b-form-input class="my-3 shadow_6 text-capitalize" name="frazione" type="text"
                  placeholder="frazione..." v-model="filters.frazione"></b-form-input>
              </b-form-group>
            </div>
            <div class="col-12 d-flex flex-wrap px-0">
              <b-form-group label="Denominazione" label-for="denominazione"
                class="text-left fs-6 text-font-grey mx-auto col-12">
                <b-form-input class="my-3 shadow_6" name="denominazione" type="text" placeholder="denominazione..."
                  v-model="filters.denominazione" minlength="5"></b-form-input>
              </b-form-group>
            </div>
            <div class="col-12 d-flex flex-wrap px-0">
              <b-form-group label="Cognome" label-for="surname"
                class="text-left fs-6 text-font-grey mx-auto col-12 col-md-6">
                <b-form-input class="my-3 shadow_6" name="surname" type="text" placeholder="cognome..."
                  v-model="filters.surname"></b-form-input>
              </b-form-group>
              <b-form-group label="Cap" label-for="cap" class="text-left fs-6 text-font-grey mx-auto col-12 col-md-6">
                <b-form-input class="my-3 shadow_6" name="cap" type="text" placeholder="cap..." v-model="filters.cap">
                </b-form-input>
              </b-form-group>
            </div>
            <p v-if="results && results.length == 0" class="text-danger">
              Nessun risultato con questi parametri di ricerca
            </p>
            <div class="col-12 my-2 text-center">
              <b-button type="reset" variant="warning text-white fw-bolder spacing_1 m-2">Pulisci campi</b-button>
              <b-button :disabled="
                selected.regione == null ||
                selected.provincia == null ||
                selected.comune == null
              " type="submit" variant="primary primary_gradient text-white fw-bolder spacing_1 m-2">Cerca</b-button>
            </div>
          </div>
        </b-form>
      </transition-group>
    </div>
    <div v-else>
      <div class="col-12 pb-3">
        <span class="fw-bolder text-primary point" @click="cancelSelection">
          <b-icon icon="arrow-left-circle-fill" variant="primary"></b-icon>
          <span v-if="formSearch">Indietro</span>
          <span v-else>Aumenta il raggio di ricerca</span>
        </span>
      </div>
      <div v-if="results && results.length > 0" class="col-12 px-0">
        <p class="text-left" v-if="$route.name == 'CittadinoHome'">
          Clicca sulla farmacia che vuoi impostare come nuova preferita e
          conferma la scelta premendo su "Conferma Scelta"
        </p>
        <p class="text-left" v-else>
          Clicca sulla farmacia che vuoi selezionare e conferma la scelta
          premendo su "Conferma Scelta"
        </p>
        <div class="col-12 d-flex flex-wrap pb-4 px-0">
          <div class="col-sm-12 col-md-6 col-xl-4 my-1" v-for="(company, ind) in results" :key="ind">
            <CompactCompanyCard :azienda="company" :iselected="enlight(company)"
              v-on:company-selection="selezionaCompany($event)" class="mr-1 mt-3" />
          </div>
        </div>
        <div v-if="selectedCompany" class="col-12">
          <b-form-group v-if="$route.name !== 'CittadinoHome'" label="" label-for="preferito"
            class="text-left fs-6 text-font-grey mt-3 mx-auto col">
            <b-form-checkbox v-model="form.preferito" name="preferito" :value="true" :unchecked-value="false">
              Imposta come Preferito
            </b-form-checkbox>
          </b-form-group>
          <div class="col-12 text-center mt-4 pt-2">
            <b-button @click="cancelSelection" type="button" variant="outline-danger fw-bolder m-2">Annulla</b-button>
            <b-button @click="confirmSelection" type="button"
              variant="primary primary_gradient text-white fw-bolder spacing_1 m-2">Conferma Scelta</b-button>
          </div>
        </div>
      </div>
      <div v-else-if="results && results.length == 0" class="col-12">
        <p class="text-left">Nessun risultato per questa ricerca</p>
      </div>
    </div>
  </div>
</template>
<script>
import { supportService } from "@/_services";
import { mapState, mapActions, mapGetters } from "vuex";
import CompactCompanyCard from "@/components/prenotazioni/CompactCompanyCard.vue";
export default {
  name: "SearchCompany",
  components: { CompactCompanyCard },
  data() {
    return {
      filters: {
        comune: null,
        provincia: null,
        regione: null,
        frazione: null,
        cap: null,
        denominazione: null,
        surname: null,
      },
      selected: {
        comune: null,
        provincia: null,
        regione: null,
      },
      results: null,
      hasResult: false,
      selectedCompany: null,
      form: { preferito: true, company: null },
      formSearch: true,
      userCoordinates: null,
      searchFilters: {
        lat: null,
        long: null,
        radius: 100,
      },
      spinSearch: false,
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
    ...mapState("utility", ["searching", "comuni", "province", "regioni"]),
    ...mapState("company", ["companyLoggedIn", "company"]),
    ...mapState("utente", ["status", "user"]),
    ...mapGetters({
      getCompany: "company/getCompany",
    }),
  },
  watch: {
    selected: {
      handler(val) {
        this.setData();
      },
      deep: true,
    },
    selectedCompany: function (val) {
      if (val) {
        this.form.company = val.hashid;
      }
    },
  },
  methods: {
    ...mapActions("utility", ["getComuni", "getProvince", "getRegioni"]),
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    setData() {
      if (this.selected.comune) {
        this.filters.comune = this.selected.comune.comune;
      }
      this.filters.regione = this.selected.regione;
      this.filters.provincia = this.selected.provincia;
    },
    submitSearch() {
      if (
        this.selected.regione !== null &&
        this.selected.provincia !== null &&
        this.selected.comune !== null
      ) {
        var self = this;
        supportService
          .searchCompany(this.filters)
          .then(function (response) {
            self.results = response.data.data;
            if (response.data.data.length > 0) {
              self.hasResult = true;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    clearFilters() {
      this.filters.comune = null;
      this.filters.provincia = null;
      this.filters.regione = null;
      this.filters.frazione = null;
      this.filters.cap = null;
      this.filters.denominazione = null;
      this.filters.surname = null;
      this.results = null;
      this.selected.regione = null;
      this.selected.provincia = null;
      this.selected.comune = null;
      this.hasResult = false;
      // if (doRefresh) {
      //   this.submitSearch();
      // }
    },
    enlight(comp) {
      return this.selectedCompany && comp.hashid === this.selectedCompany.hashid
        ? true
        : false;
    },
    selezionaCompany(azienda) {
      // //console.log(azienda);
      this.selectedCompany = azienda;
    },
    confirmSelection() {
      // var form = {
      //   company: this.selectedCompany.hashid,
      //   preferito: this.preferito,
      // };
      var self = this;
      supportService
        .updateRelationshipWithCompany(this.form)
        .then(function (response) {
          self.$emit("new-selection", self.selectedCompany);
          self.clearFilters();
          window.scrollTo(0, 0);
          self.successAlert("preferenze aggiornate correttamente!");
        })
        .catch(function (error) {
          window.scrollTo(0, 0);
          self.errorAlert("non è stato possibile aggiornare le preferenze");
        });
    },
    cancelSelection() {
      this.clearFilters();
    },
    startGeolocal() {
      // this.formSearch = false;
      var self = this;
      this.$getLocation()
        .then((coordinates) => {
          // //console.log(coordinates);
          this.userCoordinates = coordinates;
          self.formSearch = false;
          this.searchFilters.lat = coordinates.lat;
          this.searchFilters.long = coordinates.lng;
        })
        .then(function () {
          self.doGeolocalSearch();
        })
        .catch((err) => {
          //console.log("err", err);
          self.formSearch = false;
        });
    },
    doGeolocalSearch() {
      this.spinSearch = true;
      // //console.log(this.searchFilters);
      var self = this;
      supportService
        .searchCompany(this.searchFilters)
        .then(function (response) {
          self.results = response.data.data;
          if (response.data.data.length > 0) {
            self.hasResult = true;
          }
          self.spinSearch = false;
        })
        .catch(function (error) {
          console.log(error);
          self.spinSearch = false;
        });
    },
  },
  created() {
    // if (!this.regioni) {
    this.getRegioni();
    // }
  },
  mounted() {
    // //console.log(this.user.anagrafica);
    if (this.user.type === 0) {
      this.selected.regione = this.user.anagrafica.regione;
      var self = this;
      this.getProvince(this.selected).then(function () {
        self.selected.provincia = self.user.anagrafica.provincia;
        self.getComuni(self.selected);
        // var innerSelf = self;
        // self.getComuni(self.selected).then(function () {
        //   //
        //   innerSelf.selected.comune = innerSelf.user.anagrafica.comune;
        // });
      });
    }
  },
};
</script>