<template>
  <div class="cittadino">
    <router-view></router-view>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "CittadinoMain",
  computed: {
    ...mapState("utente", ["status", "user"]),
  },
  created() {
    if (this.user.type !== 0 || !this.user.all_consenses) {
      this.$router.push({ name: "Home" });
    }
  },
};
</script>