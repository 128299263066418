<template>
  <div class="col-12">
    <h6
      class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
    >
      Questionari somministrati
    </h6>
    <b-row>
      <b-col class="text-left px-0 d-flex align-items-center">
        <span class="mx-2">
          <label for="month_selector" class="mr-1 mb-0">Vedi</label>
          <select
            class="form-select bg-white border border-info general_border p-1 shadow_6"
            name="vedi_tot"
            v-model="filterSetted.take"
            :options="takeOptions"
          >
            <option v-for="opt in takeOptions" :value="opt" :key="opt">
              {{ opt }}
            </option>
          </select>
        </span>
        <span class="ml-2 mr-1 fw-bolder" v-if="total > 0"
          >{{ fromPage }}-{{ toPage }} di
        </span>
        <span class="fw-bolder"> {{ total }}</span>
      </b-col>
      <b-col class="d-flex px-0 align-items-center justify-content-end">
        <span class="mx-2">
          <label for="month_selector" class="mr-1 mb-0">Mese</label>
          <select
            class="form-select bg-white border border-info general_border p-1 shadow_6"
            v-model="filterSetted.month"
            name="month_selector"
            id="month_selector"
          >
            <option
              v-for="month in historyFilterOptions.month"
              :value="month.value"
              :key="month.value"
            >
              {{ month.label }}
            </option>
            <option :value="null">Tutti</option>
          </select>
        </span>
        <label for="pickedYear" class="mr-1 mb-0">Anno</label>
        <select
          class="form-select bg-white border border-info general_border p-1 shadow_6"
          v-model="filterSetted.year"
          name="pickedYear"
          id="pickedYear"
        >
          <option
            v-for="year in yearsOptions"
            :value="year.value"
            :key="year.value"
          >
            {{ year.text }}
          </option>
        </select>
      </b-col>
    </b-row>
    <div class="mt-4">
      <b-table
        borderless
        hover
        responsive
        table-variant="white"
        :fields="fields"
        :items="surveys"
        :small="$root.isMobile"
        :busy="isBusy"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Attendere caricamento...</strong>
          </div>
        </template>
        <template #cell(created_at)="row">
          <span class="text-left">{{
            row.item.created_at | moment("DD/MM/YYYY")
          }}</span>
        </template>
        <template #cell(azioni)="row">
          <b-row align-v="center" class="d-flex justify-content-center">
            <!-- <span class="mx-2 point"> -->
            <b-button
              @click="showThis(row)"
              size="sm"
              variant="info info_gradient"
              class="rounded-pill fw-bolder px-3 py-1 mx-2"
              ><b-icon icon="eye-fill"></b-icon>
            </b-button>
            <!-- </span> -->
            <span
              class="ml-1 mr-0 point"
              v-if="user.permessi.includes('somministrare questionari')"
            >
              <b-button
                @click="somministraQuestionario(row.item)"
                size="sm"
                variant="primary primary_gradient"
                class="rounded-pill fw-bolder px-3 py-1 mr-2"
                ><b-icon icon="clipboard-plus"></b-icon>
              </b-button>
            </span>

            <b-dropdown
              size="sm"
              variant="secondary rounded-pill"
              right
              class="secondary-dropdown m-1 py-2"
            >
              <template #button-content>
                <b-icon icon="download" font-scale="1.3"></b-icon>
              </template>
              <b-dropdown-item
                class="secondary-li-hover"
                @click="downloadOne(row)"
                >PDF Standard</b-dropdown-item
              >
              <b-dropdown-item
                class="secondary-li-hover"
                @click="downloadPatientVersion(row)"
                >PDF per il paziente</b-dropdown-item
              >
            </b-dropdown>
          </b-row>
        </template>
        <template #empty="scope">
          {{ scope }}
          <p class="fw-bolder text-secondary" v-if="scope">
            Nessuna record per i parametri inseriti.
          </p>
        </template>
      </b-table>
      <p v-if="surveys && surveys.length < 1">Nessun questionario qui</p>
      <b-pagination
        v-model="filterSetted.page"
        :total-rows="total"
        :per-page="per_page"
        align="center"
        :size="$root.isMobile ? 'sm' : 'lg'"
      ></b-pagination>
      <b-modal
        ref="show-filleditem"
        centered
        hide-footer
        title="Questionario"
        size="xl"
      >
        <FilledSurveyShower
          v-if="selectedItem"
          :response="selectedItem.response"
          :score="selectedItem.score"
        />
      </b-modal>

      <b-modal
        id="confirm-delete"
        centered
        title="Confermi di voler eliminare il questionario?"
        ok-title="Sì, elimina"
        ok-variant="popup-button"
        cancel-title="No, annulla"
        cancel-variant="popup-button"
        footer-border-variant="white"
        @ok="deleteSelectedItem"
        @cancel="cancelDelete"
      >
        <h6 v-if="selectedItem" class="px-2 py-3">
          Se confermi, il questionario selezionato compilato in data
          {{ selectedItem.created_at | moment("DD/MM/YYYY") }} verrà eliminato
          definitivamente.
        </h6>
      </b-modal>
    </div>
  </div>
</template>
<script>
import { supportService, cittadinoService } from "@/_services";
import { mapState, mapActions } from "vuex";
import FilledSurveyShower from "@/components/attivita/FilledSurveyShower.vue";
import * as moment from "moment/moment";

export default {
  name: "ReportQuestionariUtente",
  props: ["subject"],
  components: { FilledSurveyShower },
  data() {
    const MONTHS = [
      { label: "Gennaio", value: 1 },
      { label: "Febbraio", value: 2 },
      { label: "Marzo", value: 3 },
      { label: "Aprile", value: 4 },
      { label: "Maggio", value: 5 },
      { label: "Giugno", value: 6 },
      { label: "Luglio", value: 7 },
      { label: "Agosto", value: 8 },
      { label: "Settembre", value: 9 },
      { label: "Ottobre", value: 10 },
      { label: "Novembre", value: 11 },
      { label: "Dicembre", value: 12 },
    ];
    return {
      filterSetted: {
        take: 10,
        page: 1,
        year: moment().year(),
        month: moment().month() + 1,
      },
      mappedMonths: {
        "01": "gennaio",
        "02": "febbraio",
        "03": "marzo",
        "04": "aprile",
        "05": "maggio",
        "06": "giugno",
        "07": "luglio",
        "08": "agosto",
        "09": "settembre",
        10: "ottobre",
        11: "novembre",
        12: "dicembre",
      },
      historyFilterOptions: {
        year: null,
        month: MONTHS,
      },
      currentYear: moment().year(),
      yearsOptions: [],
      surveys: null,
      total: 0,
      fromPage: 0,
      toPage: 0,
      per_page: 0,
      fields: [
        {
          key: "survey_name",
          label: "Nome questionario / servizio",
          tdClass: "align-middle",
        },
        {
          key: "created_at",
          label: "Data inserimento",
          tdClass: "align-middle",
        },
        // { key: "user_mail", label: "Utente" },
        // { key: "active", label: "Stato" },
        { key: "azioni", label: "", tdClass: "align-middle text-center" },
      ],
      takeOptions: [10, 25, 50, 100],
      selectedItem: null,
      isBusy: false,
    };
  },
  computed: {
    ...mapState("utente", ["status", "user"]),
    ...mapState({
      alert: (state) => state.alert,
    }),
  },
  watch: {
    filterSetted: {
      handler(prev, post) {
        if (prev.cf && prev.cf.length < 16) {
          return;
        }
        this.fetchData();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    fetchData() {
      this.isBusy = true;
      const self = this;
      supportService
        .getUserFilledSurveys(this.subject.hashid, this.filterSetted)
        .then(function (res) {
          self.surveys = res.data.data.data;
          self.filterSetted.page = res.data.data.current_page;
          self.total = res.data.data.total;
          self.per_page = res.data.data.per_page;
          self.fromPage = res.data.data.from;
          self.toPage = res.data.data.to;
          self.isBusy = false;
        })
        .catch(function (err) {
          // console.log(err);
          window.scrollTo(0, 0);
          self.errorAlert(
            "Non è stato possibile recuperare i questionari dell'assistito"
          );
          self.isBusy = false;
        });
    },
    showThis(row) {
      this.selectedItem = row.item;
      this.$refs["show-filleditem"].show();
    },
    showDeleteThis(row) {
      this.selectedItem = row.item;
      this.$bvModal.show("confirm-delete");
    },
    cancelDelete() {
      this.selectedItem = null;
      this.$bvModal.hide("confirm-delete");
    },
    downloadOne(row) {
      const self = this;
      const selected = row;
      supportService
        .downloadFilledSurvey(selected.item.hashid)
        .then(function (response) {
          let blob = new Blob([response.data]);
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = selected.item.survey_name + ".pdf";
          link.click();
        })
        .catch(function (err) {
          // console.log(err);
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile scaricare il file");
        });
    },
    downloadPatientVersion(row) {
      const self = this;
      const selected = row;
      console.log(self.subject);
      const timeStamp = moment().format("DD_MM_YYYY_hh_mm");
      supportService
        .downloadFilledSurveyPatientVersion(selected.item.hashid)
        .then(function (response) {
          let blob = new Blob([response.data]);
          // let blob = new Blob([response.data], { type: "text/csv" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download =
            "estratto_versione_paziente_" +
            timeStamp +
            "_" +
            selected.item.survey_name +
            ".pdf";
          link.click();
        })
        .catch(function (err) {
          // console.log(err);
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile scaricare il file");
        });
    },
    deleteSelectedItem() {
      const self = this;
      supportService
        .deleteFilledSurvey(this.selectedItem.hashid)
        .then(function (res) {
          self.successAlert("Questionario eliminato correttamente");
          self.fetchData();
        })
        .catch(function (err) {
          // console.log(err);
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile eliminare il questionario");
        });
    },
    somministraQuestionario(filled) {
      this.$router.push({
        name: "somministra questionario",
        params: { quest: filled.survey.hashid },
        query: { user: this.subject.hashid },
      });
    },
  },
  created() {
    this.startYear = 2021;
    for (let i = this.currentYear; i >= this.startYear; i--) {
      let y = { value: `${i}`, text: `${i}` };
      this.yearsOptions.push(y);
    }
  },
  mounted() {
    this.fetchData();
  },
};
</script>
<style></style>
