<template>
  <div>
    <h6 class="
        text-prim-grad-1
        fw-bolder
        text-left
        pb-1
        border-bottom border-line-grey
      ">
      Materiale Informativo
    </h6>
    <b-row class="flex-column text-left mt-4">
      <b-col v-for="(doc, ind) in downloadable" :key="ind" class="my-3">
        <b-row align-v="center">
          <b-col>
            <h6 class="text-primary">{{ doc.title }}</h6>
            <p class="text-font-light-grey">{{ doc.description }}</p>
          </b-col>
          <b-col>
            <a :href="doc.path" target="_blank" class="btn btn-primary rounded-pills">
              <b-icon icon="download" class="mx-2"></b-icon>
              <span class="mx-2">Scarica</span>
            </a>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <h6 class="
        text-prim-grad-1
        fw-bolder
        text-left
        pb-1
        border-bottom border-line-grey
      ">
      Videopillole
    </h6>
    <b-row cols="1" cols-md="2" align-v="center" v-for="(vid, ind) in pillole" :key="ind" class="text-left mt-4">
      <b-col>
        <h6 class="text-primary">{{ vid.title }}</h6>
        <p class="text-font-light-grey">{{ vid.description }}</p>
      </b-col>
      <b-col>
        <b-embed type="video" aspect="16by9" controls poster="/assets/dfcontesempre.png">
          <source :src="vid.path" type="video/mp4">
        </b-embed>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { supportService } from "@/_services";
import { mapState, mapActions } from "vuex";
export default {
  name: "MaterialeInformativo",
  data() {
    return {
      downloadable: [],
      pillole: []
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    fetchData() {
      var self = this;
      supportService
        .getScaricabili()
        .then(function (res) {
          self.downloadable = res.data.data;
        })
        .catch(function (err) {
          self.errorAlert(
            "Non è stato possibile recuperare i documenti scaricabili"
          );
        });

      supportService
        .getPillole()
        .then(function (res) {
          if (res.data.data[0]) {
            self.pillole = res.data.data[0];
          }
        })
        .catch(function (err) {
          self.errorAlert(
            "Non è stato possibile recuperare i documenti scaricabili"
          );
        });
    },
  },
  created() {
    this.fetchData();
  },
};
</script>