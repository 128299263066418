<template>
  <div v-if="detail">
    <h5 class="text-secondary mt-4 mb-3 ml-3 fw-bolder text-capitalize">
      Dettaglio Prestazione
    </h5>

    <div class="ml-3 mb-3">
      <p class="mb-0 text-font-grey">
        {{ detail.prestazione.name }},
        {{ detail.prestazione.description }}
      </p>

      <div class="mt-2 mb-4" v-if="tobecompleted">
        <p>Il paziente deve ancora completare il questionario</p>
      </div>
      <div class="my-2" v-else-if="survey !== null && survey.response">
        <h5 class="text-secondary fw-bolder">Questionario:</h5>
        <b-button
          variant="info info_gradient fw-bolder rounded-pill"
          class="px-4"
          @click="$refs['show-filleditem'].show()"
          >apri questionario</b-button
        >
        <b-button
          variant="secondary secondary_gradient fw-bolder rounded-pill"
          class="px-2 m-1"
          @click="downloadOne"
        >
          scarica in formato pdf</b-button
        >
      </div>
      <div class="my-2" v-else-if="detail.health_service.fds">
        <h5 class="text-secondary fw-bolder">Questionario:</h5>
        <b-button
          variant="info info_gradient fw-bolder rounded-pill"
          class="px-4"
          @click="
            $router.push({
              name: 'somministra questionario',
              params: { quest: detail.health_service.questionario.hashid },
            })
          "
          >somministra questionario</b-button
        >
      </div>

      <p class="mb-0" v-if="detail.details">
        Note sulla prestazione:
        <strong>{{ detail.details }} </strong>
      </p>
    </div>
    <div
      class="d-flex justify-content-center my-2 ml-3"
      v-if="Math.abs(stato) !== 4"
    >
      <b-button
        v-if="stato === 1 || stato === 6"
        @click="confirmRequest"
        type="button"
        variant="primary primary_gradient fw-bolder text-white ml-0 mr-auto rounded-pill"
        >Conferma Appuntamento
      </b-button>
      <b-button
        v-if="stato === 1"
        @click="setAction('reject')"
        type="button"
        variant="outline-danger fw-bolder mr-0 ml-auto rounded-pill"
        >Declina Richiesta</b-button
      >
      <b-button
        v-if="stato > 1"
        @click="setAction('cancel')"
        type="button"
        variant="outline-danger fw-bolder mr-0 ml-auto rounded-pill"
        >Annulla Appuntamento</b-button
      >
    </div>

    <b-modal
      size="xl"
      hide-footer
      centered
      ref="modal-confirm"
      title="Conferma Annullamento"
    >
      <p class="mb-0">Sei sicuro di voler annullare questo appuntamento?</p>
      <div>
        <b-form-group
          label="Allega messaggio per l'utente:"
          v-slot="{ ariaDescribedby }"
        >
          <b-form-radio
            class="my-1"
            v-model="message"
            :aria-describedby="ariaDescribedby"
            :value="1"
            >"{{ msgOptions[1] }}"</b-form-radio
          >
          <b-form-radio
            class="my-1"
            v-model="message"
            :aria-describedby="ariaDescribedby"
            :value="2"
            >"{{ msgOptions[2] }}"</b-form-radio
          >
          <b-form-radio
            class="my-1"
            v-model="message"
            :aria-describedby="ariaDescribedby"
            :value="3"
            >"{{ msgOptions[3] }}"</b-form-radio
          >
          <b-form-radio
            class="my-1"
            v-model="message"
            :aria-describedby="ariaDescribedby"
            :value="4"
            >"{{ msgOptions[4] }}"</b-form-radio
          >
          <b-form-radio
            v-model="message"
            :aria-describedby="ariaDescribedby"
            :value="6"
            >nessun messaggio</b-form-radio
          >
          <b-form-radio
            v-model="message"
            :aria-describedby="ariaDescribedby"
            :value="5"
            >scrivi messaggio</b-form-radio
          >
          <b-form-input
            v-if="message == 5"
            class="simple__input border-top-0 border-left-0 border-right-0"
            name="freeText"
            type="text"
            placeholder="scrivi messaggio..."
            v-model="freeText"
          ></b-form-input>
        </b-form-group>
      </div>
      <div class="d-flex justify-content-end">
        <b-button
          @click="$refs['modal-confirm'].hide()"
          type="button"
          variant="info fw-bolder "
          >No, chiudi</b-button
        >
        <b-button
          @click="cancelRequest"
          type="button"
          variant="outline-danger fw-bolder ml-2"
          >Sì, procedi</b-button
        >
      </div>
    </b-modal>
    <b-modal ref="show-filleditem" hide-footer title="Questionario" size="xl">
      <FilledSurveyShower
        v-if="survey"
        :response="survey.response"
        :score="survey.score"
        :identificativo_deblistering="survey.identificativo_deblistering"
      />
    </b-modal>
  </div>
</template>
<script>
import { supportService } from "@/_services";
import FilledSurveyShower from "@/components/attivita/FilledSurveyShower.vue";
export default {
  name: "DetailPrestazione",
  props: ["detail", "stato", "datainizio", "quest", "tobecompleted", "cf"],
  components: { FilledSurveyShower },
  data() {
    return {
      message: 6,
      msgOptions: {
        1: "Ci spiace comunicarLe che non possiamo gestire ulteriori richieste per la data selezionata. La preghiamo di effettuare una nuova prenotazione per la settimana successiva alla data scelta.",
        2: "Ci spiace comunicarLe che a causa di problematiche relative all'approvvigionamento non possiamo gestire la sua richiesta per la data selezionata.",
        3: "Ci spiace comunicarLe che non possiamo gestire la sua richiesta per mancanza di dati essenziali. La preghiamo di completare la sua scheda anagrafica.",
        4: "Si è verificato un imprevisto che ci costringe a disdire il suo appuntamento. Se lo desidera, ci chiami per ulteriori informazioni.",
        5: null,
        6: null,
      },
      freeText: null,
      actionName: null,
      survey: null,
    };
  },
  methods: {
    confirmRequest() {
      //console.log(this.detail.hashid);
      // this.$emit('update-request-status', true);
      var data = {
        confirmed: true,
        action: "confirm",
      };
      this.$emit("update-request-status", data);
    },
    setAction(action) {
      this.actionName = action;
      this.$refs["modal-confirm"].show();
    },
    cancelRequest() {
      var data = {
        confirmed: false,
        message: this.freeText,
        action: this.actionName,
      };
      //console.log(data);
      if (this.message !== null && this.message !== 5) {
        data = {
          confirmed: false,
          message: this.msgOptions[this.message],
          action: this.actionName,
        };
      }
      if (this.message === 6) {
        data = {
          confirmed: false,
          message: null,
          action: this.actionName,
        };
      }
      this.$emit("update-request-status", data);
      this.$refs["modal-confirm"].hide();
    },
    downloadOne() {
      var self = this;
      supportService
        .downloadFilledSurvey(this.quest.hashid)
        .then(function (response) {
          let blob = new Blob([response.data]);
          // let blob = new Blob([response.data], { type: "text/csv" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download =
            self.detail.prestazione.name + "_" + "questionario.pdf";
          link.click();
        })
        .catch(function (err) {
          console.log(err);
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare il questionario");
        });
    },
  },
  created() {
    if (this.quest) {
      this.survey = this.quest;
    }
  },
};
</script>
<style>
.simple__input {
  border-radius: 0 !important;
  font-size: 93% !important;
}

.simple__input:focus {
  box-shadow: none !important;
}
</style>
