<template>
  <div class="text-justify">
    <h4>
      PRIVACY & COOKIE POLICY
    </h4>

    <h5>
      Privacy Policy
    </h5>
    <p>
      Informativa al trattamento dei dati personali (art. 13 del GDPR – Regolamento UE n. 2016/679)
      Ai sensi dell’art. 5 del GDPR il trattamento delle informazioni personali che La riguardano sarà improntato ai
      principi di correttezza, liceità e trasparenza.
    </p>
    <h5>
      FONTE DEI DATI PERSONALI
    </h5>
    <p>
      I dati personali in possesso di Promofarma S.r.l. sono forniti direttamente dall’interessato attraverso tecniche
      di
      comunicazione a distanza (ivi incluso il sito web ed il telefono), solo dopo esplicito consenso al trattamento da
      parte dell’interessato.
    </p>
    <h5>
      FINALITA’ DEL TRATTAMENTO DEI DATI
    </h5>
    <p>
      Il trattamento dei dati da Lei forniti a Promofarma S.r.l. tramite la sezione del sito “Contatti” e tramite
      contatto
      telefonico, è finalizzato all’erogazione di informazioni da Lei richieste (dopo esplicito consenso al trattamento
      dei suoi dati).
      I dati da Lei forniti non verranno comunicati a terzi, salvo quelli strettamente necessari alla fornitura del
      servizio (ad es. società contitolare) e salvo quanto altrimenti previsto dalla legge. Inoltre, le predette
      informazioni potranno essere trattate, oltre che per il funzionamento dei citati servizi, per:
      conformarsi a prescrizioni di legge;
      adeguarsi ad un provvedimento legale, inclusa una disposizione dell’Autorità Giudiziaria o di altra autorità
      competente;
      difendersi da contestazioni di terzi che asseriscano che tali contenuti violino i loro diritti.
    </p>
    <h5>
      EVENTUALI DESTINATARI
    </h5>
    <p>
      In nessun caso i dati saranno diffusi. I dati personali non sono oggetto di trasferimento al di fuori dell’Unione
      europea. In ogni caso, resta inteso che, ove si rendesse necessario, il Titolare potrà trasferire i dati personali
      anche in Paesi extra UE, garantendo sin d’ora che il trasferimento dei dati personali extra UE avverrà nel
      rispetto
      delle disposizioni di legge ed in particolare delle previsioni di cui all’art. 46 del GDPR.
    </p>
    <h5>
      TIPI DI DATI TRATTATI
    </h5>
    <p>
      I dati personali trattati sono quelli relativi a clienti in essere o potenziali, quali: il nome, l’indirizzo, i
      dati
      di identificazione personale o dell’azienda, il codice fiscale, la partita IVA, gli estremi identificativi dei
      rapporti bancari (ABI, CAB, numero di conto corrente, ecc.). Per tali ragioni La invitiamo a non comunicare né
      tramite sito web né tramite telefono dati personali “particolari”. Nel caso tuttavia che Promofarma S.r.l. per la
      propria operatività, dovesse avere la necessità di trattare i dati di natura “particolare”, la stessa avrà cura di
      inviarLe comunicazione scritta per ottenere il consenso a trattamento.
    </p>
    <h5>
      PERIODO DI CONSERVAZIONE
    </h5>
    I Suoi dati verranno conservati per un periodo massimo di 5 anni. Successivamente verranno cancellati.
    <h5>
      DIRITTI DELL’INTERESSATO
    </h5>
    <p>
      Lei potrà far valere i Suoi diritti come espressi dall’art. 15, 16, 17, 18, 20 e 21 del D GDPR – Regolamento UE
      2016/679, rivolgendosi al Titolare ai dati di contatto sopra indicati.
      Lei ha il diritto, in qualunque momento, di chiedere al titolare del trattamento l’accesso ai dati personali e la
      rettifica (diritto di ottenere dal titolare del trattamento la rettifica dei dati personali inesatti che lo
      riguardano senza ingiustificato ritardo e ottenere l’integrazione dei dati personali incompleti, anche fornendo
      una
      dichiarazione integrativa) o la cancellazione degli stessi o la limitazione del trattamento che lo riguardano o di
      opporsi al loro trattamento, oltre al diritto alla portabilità dei dati (diritto di ricevere in un formato
      strutturato, di uso comune e leggibile da dispositivo automatico i dati personali che lo riguardano forniti e
      diritto di trasmettere tali dati a un altro titolare del trattamento senza impedimenti da parte del titolare del
      trattamento cui li ha forniti).
      Ha il diritto a revocare il consenso (ove lo avesse espresso) in qualsiasi momento senza pregiudicare la liceità
      del
      trattamento basata sul consenso prestato prima della revoca.
      Lei ha il diritto di proporre reclamo a un’autorità di controllo.

      Natura obbligatoria o facoltativa del conferimento dei dati e conseguenze di un eventuale rifiuto a fornire i dati
      Il conferimento dei dati è facoltativo, l’eventuale rifiuto a fornire tali dati comporterà la totale o parziale
      impossibilità di erogare il servizio concordato o di avviare qualsiasi collaborazione tra le parti.
    </p>
    <h5>
      DATI DI NAVIGAZIONE
    </h5>
    <p>
      Il trattamento dei dati ha, come detto in precedenza, prevalentemente il fine di erogare i servizi descritti
      nell’apposita sezione e sarà effettuato prevalentemente su supporto informatico e materiale cartaceo protetti e
      custoditi secondo la normativa vigente. I sistemi informatici e le procedure software preposte al funzionamento di
      questo sito web acquisiscono, nel corso del loro normale esercizio e per la sola durata della connessione, alcuni
      dati personali la cui trasmissione è implicita nell’uso dei protocolli di comunicazione di Internet. Si tratta di
      informazioni che non sono raccolte per essere associate a interessati identificati, ma che per loro stessa natura
      potrebbero, attraverso elaborazioni ed associazioni con dati detenuti da terzi, permettere di identificare gli
      utenti. In questa categoria di dati rientrano gli indirizzi IP o i nomi a dominio dei computer utilizzati dagli
      utenti che si connettono al sito, gli indirizzi in notazione URI (Uniform Resource Identifier) delle risorse
      richieste, l’orario della richiesta, il metodo utilizzato nel sottoporre la richiesta al server, la dimensione del
      file ottenuto in risposta, il codice numerico indicante lo stato della risposta data dal server (buon fine,
      errore,
      ecc.) ed altri parametri relativi al sistema operativo e all’ambiente informatico dell’utente. Questi dati vengono
      utilizzati al solo fine di ricavare informazioni statistiche anonime sull’uso del sito, per monitorare la corretta
      navigazione e per controllarne il corretto funzionamento, ma non per finalità di natura commerciale, né è rivolta
      in
      alcun modo all’identificazione dell’utente. I dati potrebbero essere utilizzati per l’accertamento di
      responsabilità
      in caso di ipotetici reati informatici ai danni del sito: questi dati sono registrati in appositi log e possono
      essere comunicati esclusivamente all’autorità giudiziaria.
    </p>
    <h5>
      COOKIES
    </h5>
    <p>
      I cookies sono delle informazioni scambiate tra il server web ed il Vostro personal computer. I cookies consentono
      di tenere traccia delle sessioni quando si naviga nelle aree protette del sito (Area Riservata Aziende ed Area
      Riservata Beneficiari). Il sito utilizza i cookies di sessione il cui uso è strettamente limitato alla
      trasmissione
      di identificativi di sessione (costituiti da numeri casuali generati dal server) necessari per consentire
      l’esplorazione sicura ed efficiente del sito e per fornire contenuti personalizzati o per permettere il
      completamento di transazioni e per la raccolta di dati demografici in forma anonima (es. il conteggio degli
      accessi
      al forum). Per navigare nelle aree riservate è necessario che il browser sia configurato in modo da accettare i
      cookies e che il livello di protezione sia impostato su “Medio”. I cosiddetti cookies di sessione utilizzati in
      questo sito evitano il ricorso ad altre tecniche informatiche potenzialmente pregiudizievoli per la riservatezza
      della navigazione degli utenti e non consentono l’acquisizione di dati personali identificativi dell’utente.
    </p>
    <h5>
      MODALITÀ DEL TRATTAMENTO
    </h5>
    <p>
      I dati personali sono trattati con strumenti automatizzati per il tempo strettamente necessario a conseguire gli
      scopi per cui sono stati raccolti. Specifiche misure di sicurezza sono osservate per garantire l’integrità e la
      riservatezza, prevenire la perdita dei dati, usi illeciti o non corretti ed accessi non autorizzati. I dati
      personali da Lei rilasciati verranno a conoscenza degli incaricati del trattamento informati secondo le misure
      minime della normativa vigente e sotto il controllo del titolare del trattamento o del responsabile nominato.
    </p>
    <p class="fw-bolder">

      Il Titolare del trattamento è: <br>
      Promofarma S.r.l.<br>
      Sede legale/operativa:<br></br>
      via Emanuele Filiberto, 190 - 00185 Roma<br>
      P.IVA 05317231008<br>
      (+39) 06-7726841<br>
      <br></br>
      E-mail: mail@promofarma.it<br>
      PEC: promofarma@pec.federfarma.it<br>
    </p>
    <h4>
      Cookie Policy
    </h4>
    <p>
      Informativa ai sensi dell’articolo 13 del D.lgs. n.196/2003 e ai sensi dell’art. 13 e 14 del Regolamento Europeo
      679/2016 (di seguito anche il “GDPR”).
    </p>
    <h5>
      CHE COSA SONO I COOKIE
    </h5>
    <p>
      Il cookie è un piccolo file di testo (pochi byte) scambiato tra un sito internet ed il suo browser e utilizzato
      dal
      gestore del sito per memorizzare le informazioni necessarie per il corretto funzionamento del sito (cd. cookie di
      navigazione) per il tuo riconoscimento ogni volta che accedi al sito (cd. cookie funzionali), per creare il tuo
      profilo al fine di inviare messaggi pubblicitari in linea con le preferenze da te manifestate (cd. cookie di
      profilazione). I cookie possono essere gestiti direttamente dal titolare del sito (cd. cookie di prima parte) o da
      soggetti terzi estranei al sito (cd. cookie di terza parte).
    </p>
    <h5>
      TIPOLOGIE E FINALITÀ DEI COOKIE
    </h5>
    <p>
      In base alle caratteristiche e all’utilizzo dei cookie possiamo distinguere due macro-categorie:
    </p>
    <h6>
      Cookie tecnici o di sessione
    </h6>
    <p>
      Si tratta di cookie indispensabili per il corretto funzionamento del Sito e sono utilizzati per gestire il login e
      l’accesso alle funzioni riservate del Sito. La durata dei cookie è strettamente limitata alla sessione di lavoro
      (chiuso il browser vengono cancellati), ad eccezione dei cookie di durata più lunga volti esclusivamente a
      riconoscere, per un limitato periodo, il computer/dispositivo del visitatore – attraverso un codice alfa-numerico
      generato alla prima sessione di accesso. La loro disattivazione compromette l’utilizzo dei servizi accessibili da
      login. La parte pubblica del Sito resta normalmente utilizzabile.
      Sono inclusi in questa categoria anche in c.d. “cookie analytics”, soltanto utilizzati in forma aggregata per
      raccogliere e analizzare il traffico e monitorare le prestazioni e l’utilizzo del Sito in modo anonimo. Questi
      cookie, pur senza identificare l’utente, consentono, per esempio, di rilevare se il medesimo utente torna a
      collegarsi in momenti diversi. Permettono, inoltre, di monitorare il sistema e migliorarne le prestazioni e
      l’utilizzo. La disattivazione di tali cookie può essere eseguita senza alcuna perdita di funzionalità.
      È consentito l’utilizzo di tali cookie anche in assenza del consenso dell’utente.
    </p>
    <h6>
      Cookie di profilazione e marketing
    </h6>
    <p>
      Si tratta di cookie permanenti utilizzati per identificare (in modo anonimo e non) le preferenze dell’utente e
      migliorarne la sua esperienza di navigazione, creando profili specifici e vengono utilizzati al fine di inviare
      messaggi pubblicitari in linea con le scelte manifestate dallo stesso nell’ambito della navigazione in rete.
    </p>
    <h6>
      Cookie di terze parti
    </h6>
    <p>
      Visitando un sito web si possono ricevere cookie sia dal sito visitato (“proprietari”), sia da siti gestiti da
      altre
      organizzazioni (“terze parti”). La gestione delle informazioni raccolte da “terze parti” è disciplinata dalle
      relative informative cui si prega di fare riferimento.
    </p>
    <h6>
      Durata dei cookie
    </h6>
    <p>
      Alcuni cookie (cookie di sessione) restano attivi solo fino alla chiusura del browser o all’esecuzione del comando
      di logout. Altri cookie “sopravvivono” alla chiusura del browser e sono disponibili anche in successive visite
      dell’utente. Questi cookie sono detti persistenti e la loro durata è fissata dal server al momento della loro
      creazione. In alcuni casi è fissata una scadenza, in altri casi la durata è illimitata. Ad eccezione di quei
      cookie
      le cui informazioni sono memorizzate esclusivamente per finalità tecniche, il Sito di Promofarma S.r.l.non fa uso
      di
      cookie persistenti. Tuttavia, navigando sulle pagine dei siti web, si può interagire con siti gestiti da terze
      parti
      che possono creare o modificare cookie permanenti e di profilazione.
    </p>
    <h6>
      Gestione dei cookie
    </h6>
    <p>
      L’utente può decidere se accettare o meno i cookie utilizzando le impostazioni del proprio browser. Attenzione: la
      disabilitazione totale o parziale dei cookie tecnici può compromettere l’utilizzo delle funzionalità del Sito
      riservate agli utenti registrati. Al contrario, la fruibilità dei contenuti pubblici è possibile anche
      disabilitando
      completamente i cookie. La disabilitazione dei cookie “terze parti” non pregiudica in alcun modo la navigabilità.
      L’impostazione può essere definita in modo specifico per i diversi siti e applicazioni web. Inoltre i migliori
      browser consentono di definire impostazioni diverse per i cookie “proprietari” e per quelli di “terze parti”.
      Attraverso il menu opzioni del browser in uso è possibile gestire e definire se accettare o meno i diversi tipi di
      cookie e procedere alla loro rimozione.
    </p>
    <p>
      Internet Explorer: http://windows.microsoft.com/it-it/internet-explorer/delete-manage-cookies#ie=ie-11
      Google Chrome: https://support.google.com/accounts/answer/61416?hl=it
      Apple Safari: http://support.apple.com/kb/HT1677?viewlocale=it_IT
      Mozilla Fireforx: https://support.mozilla.org/it/kb/Attivare%20e%20disattivare%20i%20cookie
      Flash Player: http://www.macromedia.com/support/documentation/it/flashplayer/help/settings_manager02.html#118539
      Ricordiamo inoltre, che disabilitando completamente i cookie nel browser, l’utente potrebbe non essere in grado di
      utilizzare tutte le funzionalità interattive del Sito.
      In base alle caratteristiche e all’utilizzo dei cookie possiamo distinguere due macro-categorie:
    </p>
    <p>
    <h5>
      FINALITÀ DEL TRATTAMENTO DATI
    </h5>
    <p>
      Esecuzione di autenticazioni informatiche
      Ottimizzazione della navigazione dell’utente
      Monitoraggio delle sessioni ed analisi statistica anonima
    </p>
    <h5>
      MODALITÀ DI TRATTAMENTO DEI DATI
    </h5>
    <p>
      Il trattamento dei dati viene effettuato con strumenti elettronici, con logiche strettamente correlate alle
      finalità
      sopra indicate e, comunque, in modo da garantire la sicurezza e la riservatezza dei dati stessi.
    </p>
    <h5>
      SOGGETTI AI QUALI I DATI POSSONO ESSERE COMUNICATI
    </h5>
    <p>
      I dati possono essere comunicati a tutti i soggetti che svolgono a favore della società attività necessaria per
      l’erogazione dei cookie come su dettagliati a tuo favore. I soggetti appartenenti alle predette categorie, ai
      quali
      i dati possono essere comunicati, utilizzeranno i dati in qualità di “titolari” in piena autonomia.
    </p>
    <h5>
      COMUNICAZIONE E DIFFUSIONE DEI DATI
    </h5>
    <p>
      I dati raccolti utilizzando i cookie potranno essere trattati soltanto da personale specificatamente incaricato e
      da
      responsabili del trattamento. Inoltre, tali dati potranno essere comunicati a terzi, soltanto se specificatamente
      nominati quali responsabili del trattamento, per l’esecuzione di alcune prestazioni collegate ai rapporti in
      essere
      (es. consulenti, aziende di servizi).
      I dati raccolti utilizzando i cookie propri non saranno diffusi e non saranno ceduti a terzi. I dati raccolti
      utilizzando i cookie di terze parti potranno essere trasferiti fuori dall’Unione Europea in particolare negli
      Stati
      Uniti d’America.
    </p>
    <h5>
      I DIRITTI DELL’UTENTE
    </h5>
    <p>
      Secondo quanto previsto dall’art. 7 del D.Lgs. 196/2003 e dall’art. 15 del Regolamento UE 679/2016 (GDPR), in ogni
      momento l’utente potrà esercitare i suoi diritti nei confronti del Titolare del trattamento ed ottenere dal
      Titolare
      la conferma dell’esistenza o meno dei propri dati personali e la comunicazione in forma intelligibile dei
      medesimi,
      di richiedere la cancellazione, la trasformazione in forma anonima o il blocco dei dati trattati in violazione di
      legge, nonché l’aggiornamento, la rettifica, l’integrazione degli stessi o di opporsi al trattamento stesso per
      motivi legittimi.

      TITOLARE E RESPONSABILE DEL TRATTAMENTO
      Titolare del trattamento è Promofarma S.r.l., con sede in via Emanuele Filiberto n. 190, IT-00185, Roma (Email:
      mail@promofarma.it, PEC: promofarma@pec.federfarma.it, centralino +39 06 7726841).
    </p>
  </div>
</template>
<script>
export default {
  name: "PrivacyPolicy"
} 
</script>