<template>
  <div class="my-2 pt-2" v-if="bots">
    <h6
      class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
    >
      Accessi
    </h6>
    <p class="text-left text-font-light-grey fs-5">
      Visualizza e gestisci gli accessi che hai effettuato dai canali di
      messaggistica. Questi accessi rimarranno attivi fino a quando non
      effettuerai il logout.
    </p>

    <b-row class="mb-3">
      <!-- <b-col class="col-12 col-md-4">
        <h6 class="text-left fw-bolder px-3">Notifiche Email</h6>
        <b-row cols="1" class="text-left pb-2">
          <b-col class="my-2 col-12">
            <b-form-checkbox
              @change="editUserConsense(false)"
              v-model="mailEnabled"
              name="mail-active"
              :value="true"
              switch
            >
              Ricezione notifiche
              {{ mailEnabled == true ? "attiva" : "disattivata" }}
            </b-form-checkbox>
          </b-col>
          <h6 class="text-left fw-bolder px-3">Notifiche Push</h6>
          <b-col class="my-2 col-12" v-if="user.push_token">
            <b-form-checkbox
              @change="editUserConsense(true)"
              v-model="pushEnabled"
              name="mail-active"
              :value="true"
              switch
            >
              Ricezione notifiche
              {{ pushEnabled == true ? "attiva" : "disattivata" }}
            </b-form-checkbox>
          </b-col>
          <b-col class="my-2" v-else>
            Concedi consenso alla ricezione di notifiche push per ricevere
            notifiche in app
          </b-col>
        </b-row>
      </b-col> -->
      <template v-if="bots">
        <b-col class="col-12 col-md-4">
          <h6 class="text-left fw-bolder px-3">Canale Telegram</h6>
          <b-row class="text-left">
            <b-col
              v-for="(bot, ind) in bots['']"
              :key="'tg' + ind"
              class="my-2 col-12"
            >
              <p class="mb-0">Username: {{ bot.bot_username }} <br /></p>
              <b-button
                class="text-center mx-auto my-2"
                variant="prim-grad-1 rounded-pill"
                size="sm"
                @click="showModalConfirm(bot, true)"
              >
                <b> Rimuovi questo accesso</b>
              </b-button>
            </b-col>
            <b-col
              v-if="!bots[''] || (bots[''] && bots[''].length < 1)"
              class="col-12"
            >
              <b-form-group>
                <label for="">Nessun accesso telegram attivo</label><br />
                <a
                  :href="tgLink"
                  target="_blank"
                  class="fw-bolder text-prim-grad-1"
                  >Accedi su telegram!</a
                >
                <p>
                  <b-img
                    style="height: 15vh"
                    :src="require('@/assets/prod_qr_tg.png')"
                  ></b-img>
                </p>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col class="col-12 col-md-4">
          <h6 class="text-left fw-bolder px-3">Canale WhatsApp</h6>
          <b-row class="text-left">
            <!-- //whastapp -->
            <b-col
              v-for="(bot, ind) in bots['WB']"
              :key="'wa' + ind"
              class="my-2 col-12"
            >
              <p class="mb-0">Numero: {{ bot.bot_username }} <br /></p>
              <b-button
                class="text-center mx-auto my-2"
                variant="prim-grad-1 rounded-pill"
                size="sm"
                @click="showModalConfirm(bot, true)"
              >
                <b> Rimuovi questo accesso</b>
              </b-button>
            </b-col>
            <b-col
              v-if="!bots['WB'] || (bots['WB'] && bots['WB'].length < 1)"
              class="col-12"
            >
              <b-form-group>
                <label for="">Nessun accesso whatsapp attivo</label> <br />
                <a
                  :href="waLink"
                  target="_blank"
                  class="fw-bolder text-prim-grad-1"
                  >Accedi su whatsapp!</a
                >
                <p>
                  <b-img
                    style="height: 15vh"
                    :src="require('@/assets/prod_qr_wa.png')"
                  ></b-img>
                </p>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </template>
    </b-row>

    <h6
      class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
    >
      Notifiche
    </h6>
    <p class="text-left text-font-light-grey fs-5">
      Visualizza e gestisci i canali da cui ricevere le notifiche dei diversi
      servizi e gli alert per le terapie.
    </p>
    <b-row>
      <b-col v-if="user.email" class="col-12 col-md-4">
        <h6 class="text-left fw-bolder px-3">Notifiche Email</h6>
        <b-row cols="1" class="text-left pb-2">
          <b-col class="my-2 col-12">
            <b-form-checkbox
              @change="editUserConsense(false)"
              v-model="mailEnabled"
              name="mail-active"
              :value="true"
              switch
            >
              Ricezione notifiche
              {{ mailEnabled == true ? "attiva" : "disattivata" }}
            </b-form-checkbox>
          </b-col>
          <!-- <h6 class="text-left fw-bolder px-3">Notifiche Push</h6>
          <b-col class="my-2 col-12" v-if="user.push_token">
            <b-form-checkbox
              @change="editUserConsense(true)"
              v-model="pushEnabled"
              name="mail-active"
              :value="true"
              switch
            >
              Ricezione notifiche
              {{ pushEnabled == true ? "attiva" : "disattivata" }}
            </b-form-checkbox>
          </b-col> -->
        </b-row>
      </b-col>
      <b-col v-if="!user.email" class="col-12 col-md-4">
        <h6 class="text-left fw-bolder px-3">Nessuna email impostata</h6>
      </b-col>
      <template v-if="bots">
        <b-col
          class="col-12 col-md-4"
          v-if="bots[''] || (bots[''] && bots[''].length > 0)"
        >
          <h6 class="text-left fw-bolder px-3">Canale Telegram</h6>
          <b-row class="text-left">
            <b-col
              v-for="(bot, ind) in bots['']"
              :key="'tg' + ind"
              class="my-2 col-12"
            >
              <b-form-group>
                <b-form-checkbox
                  @change="showModalConfirm(bot, false)"
                  v-model="bot.active"
                  :name="ind + 'active'"
                  :value="1"
                  switch
                >
                  Ricezione notifiche
                  {{ bot.active == true ? "attiva" : "disattivata" }} su
                  {{ bot.bot_username }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          class="col-12 col-md-4"
          v-if="bots['WB'] || (bots['WB'] && bots['WB'].length > 0)"
        >
          <h6 class="text-left fw-bolder px-3">Canale WhatsApp</h6>
          <b-row class="text-left">
            <!-- //whastapp -->
            <b-col
              v-for="(bot, ind) in bots['WB']"
              :key="'wa' + ind"
              class="my-2 col-12"
            >
              <b-form-group>
                <b-form-checkbox
                  @change="showModalConfirm(bot, false)"
                  v-model="bot.active"
                  :name="ind + 'active'"
                  :value="1"
                  switch
                >
                  Ricezione notifiche
                  {{ bot.active == true ? "attiva" : "disattivata" }} su
                  {{ bot.bot_username }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col
              v-if="!bots['WB'] || (bots['WB'] && bots['WB'].length < 1)"
              class="col-12"
            >
              <b-form-group>
                <label for="">Nessun accesso whatsapp attivo</label> <br />
                <a
                  :href="waLink"
                  target="_blank"
                  class="fw-bolder text-prim-grad-1"
                  >Accedi su whatsapp!</a
                >
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col class="col-12 my-2 text-left" v-if="!user.push_token">
          Concedi consenso alla ricezione di notifiche push per ricevere
          notifiche in app.
          <!-- </b-col>
        <b-col class="col-12 my-2 text-left"> -->
          Puoi ricevere le notifiche push dal browser che stai utilizzando dando
          il consenso
          <span class="point" @click="askForPush()"> <u>qui</u></span>
          .
        </b-col>
      </template>
    </b-row>
    <b-modal
      id="delete-access"
      title="Elimina accesso"
      @cancel="resetDelete"
      @hidden="fetchData"
      @ok="handleOkDelete"
      button-size="sm"
      cancel-title="No, Annulla"
      cancel-variant="secondary px-3 rounded-pill font-weight-bolder"
      ok-title="Sì, Conferma"
      ok-variant="outline-danger px-3 font-weight-bolder rounded-pill"
      footer-class="border-0"
      centered
    >
      <template v-if="selectedBot">
        <h6>
          Vuoi rimuovere l'accesso via
          {{ selectedBot.messenger == "WP" ? "whatsapp" : "telegram" }} di
          {{ selectedBot.bot_username }} ? Avrai sempre la possibilità di
          effettuare il login in seguito.
        </h6>
      </template>
    </b-modal>
    <b-modal
      id="update-access"
      title="Modifica stato ricezione notifiche"
      @cancel="resetUpdate"
      @hidden="fetchData"
      @ok="handleOkUpdate"
      button-size="sm"
      cancel-title="No, Annulla"
      cancel-variant="secondary px-3 rounded-pill font-weight-bolder"
      ok-title="Sì, Conferma"
      ok-variant="outline-danger px-3 font-weight-bolder rounded-pill"
      footer-class="border-0"
      centered
    >
      <template v-if="selectedBot">
        <h6>
          Vuoi
          {{ selectedBot.active == true ? "attivare" : "disattivare" }}
          la ricezione di notifiche via
          {{ selectedBot.messenger == "WP" ? "whatsapp" : "telegram" }} da
          {{ selectedBot.bot_username }} ? Avrai sempre la possibilità di
          {{ selectedBot.active == true ? "disattivarle" : "riattivarle" }}
          in seguito.
        </h6>
      </template>
    </b-modal>
    <b-modal
      id="update-consenses"
      title="Conferma operazione"
      @cancel="cancelUpdateConsense"
      @ok="updateConsense"
      button-size="sm"
      cancel-title="No, Annulla"
      cancel-variant="secondary px-3 rounded-pill font-weight-bolder"
      ok-title="Sì, Conferma"
      ok-variant="outline-danger px-3 font-weight-bolder rounded-pill"
      footer-class="border-0"
      centered
      no-close-on-backdrop
      no-close-on-esc
    >
      <template v-if="pushMessageAlert">
        <h6>
          Vuoi
          {{ pushEnabled == true ? "attivare" : "disattivare" }}
          la ricezione di notifiche push?
        </h6>
      </template>
      <template v-else>
        <h6>
          Vuoi
          {{ mailEnabled == true ? "attivare" : "disattivare" }}
          la ricezione di notifiche via email? Avrai sempre la possibilità di
          {{ mailEnabled == true ? "disattivarle" : "riattivarle" }} in seguito.
        </h6>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { cittadinoService } from "@/_services";
import { getAuth, onAuthStateChanged, getRedirectResult } from "firebase/auth";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
export default {
  name: "NotificationChannels",
  data() {
    return {
      bots: null,
      mock: {
        bot_username: null,
        active: false,
        deleted_at: true,
      },
      selectedBot: null,
      pushMessageAlert: false,
      pushEnabled: false,
      mailEnabled: false,
      waLink: "https://wa.me/" + process.env.VUE_APP_WA_CHANNEL,
      tgLink: "https://t.me/" + process.env.VUE_APP_TG_CHANNEL,
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
    ...mapState("utente", ["status", "user"]),
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    fetchData() {
      var self = this;
      cittadinoService
        .getUserBots()
        .then(function (res) {
          //console.log(res);
          self.bots = res.data.data;
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare gli accessi");
        });
    },
    showModalConfirm(bot, doDelete) {
      this.selectedBot = bot;
      if (doDelete) {
        this.$bvModal.show("delete-access");
      } else {
        this.$bvModal.show("update-access");
      }
    },
    editUserConsense(push) {
      this.pushMessageAlert = push;
      this.$bvModal.show("update-consenses");
    },
    resetDelete() {
      this.selectedBot = null;
      this.$bvModal.hide("delete-access");
    },
    resetUpdate() {
      this.selectedBot = null;
      this.$bvModal.hide("update-access");
    },
    cancelUpdateConsense() {
      if (this.pushMessageAlert) {
        this.pushEnabled = !this.pushEnabled;
      } else {
        this.mailEnabled = !this.mailEnabled;
      }
    },
    handleOkDelete() {
      var self = this;
      cittadinoService
        .deleteBotAccess(this.selectedBot.hashid)
        .then(function (res) {
          window.scrollTo(0, 0);
          self.$bvModal.hide("delete-access");
          self.successAlert("Accesso eliminato con successo");
          self.selectedBot = null;
        })
        .catch(function (err) {
          self.$bvModal.hide("delete-access");
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile eliminare l' accesso");
        });
    },
    handleOkUpdate() {
      var self = this;
      var form = {
        active: this.selectedBot.active,
      };
      cittadinoService
        .updateUserBots(form, this.selectedBot.hashid)
        .then(function (res) {
          window.scrollTo(0, 0);
          self.$bvModal.hide("update-access");
          self.successAlert("Modifiche effettuate con successo");
          self.selectedBot = null;
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          self.$bvModal.hide("update-access");
          self.errorAlert("Non è stato possibile effettuare la modifica");
          self.selectedBot = null;
        });
    },
    askForPush() {
      const messaging = getMessaging();
      var vapkey = process.env.VUE_APP_FBS_VAPIDKEY;
      var self = this;
      getToken(messaging, {
        vapidKey: vapkey,
      })
        .then((currentToken) => {
          if (currentToken) {
            //console.log("current TOken", currentToken);
            self.editUserConsense(true);
            self.pushEnabled = true;
            // self.noTokenMsg = false;
            self.infoAlert("Consenso alle notifiche già concesso");
            // Send the token to your server and update the UI if necessary
            // ...
          } else {
            // Show permission request UI
            console.log(
              "No registration token available. Request permission to generate one."
            );
            // ...
          }
        })
        .catch((err) => {
          // self.noTokenMsg = true;
          // self.errorAlert(
          //   "La ricezione notifiche è stata precedentemente bloccata. Riattivarla tramite le impostazioni del browser"
          // );
          alert(
            "La ricezione notifiche è stata precedentemente bloccata. Riattivarla tramite le impostazioni del browser"
          );
          //console.log("An error occurred while retrieving token. ", err);
          // ...
        });
      // onMessage(messaging, (payload) => {
      //   //console.log("Message received in onMessage component fbs. ", payload);
      //   // if (payload.data) {
      //   //   self.addMessage(payload.data);
      //   //   // // self.messages.push(payload.data);
      //   //   // self.messages.splice(0, 0, payload.data);
      //   //   // self.infoAlert("Nuova notifica");
      //   // }
      // });
    },
    updateConsense() {
      var self = this;
      var form = {
        push_consense: this.pushEnabled,
        notification_consense: this.mailEnabled,
      };
      cittadinoService
        .updateConsense(form)
        .then(function (res) {
          window.scrollTo(0, 0);
          self.$bvModal.hide("update-access");
          self.successAlert("Modifiche effettuate con successo");
          self.selectedBot = null;
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          self.$bvModal.hide("update-access");
          self.errorAlert("Non è stato possibile effettuare la modifica");
          self.selectedBot = null;
        });
    },
  },
  created() {
    this.fetchData();
  },
  mounted() {
    this.pushEnabled = this.user.push_consense == true;
    this.mailEnabled = this.user.notification_consense == true;
  },
};
</script>
