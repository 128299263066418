<template>
  <div class="pt-2 pb-4">
    <div v-if="operators" :style="$root.isMobile ? 'overflow-x:scroll;' : ''">
      <b-row cols="5" class="text-left fw-bolder pb-1 border-bottom border-line-grey">
        <b-col>Nome</b-col>
        <b-col class="text-center">Email</b-col>
        <b-col class="text-center">Stato attivazione</b-col>
        <b-col class="text-left">Ruolo</b-col>
        <b-col class="text-center"></b-col>
      </b-row>
      <b-row v-for="(operator, ind) in operators" :key="ind" cols="5" class="my-3">
        <b-col class="text-left">
          <span>{{ ind + 1 }}. </span>
          <strong class="text-capitalize fw-bolder">
            {{ operator.fullname ? operator.fullname : " -- " }}</strong>
        </b-col>
        <b-col class="text-justify">
          {{ operator.email }}
        </b-col>

        <b-col class="text-center">
          <b-icon icon="circle-fill" :variant="operator.enabled == 1 ? 'success' : 'danger'"></b-icon>
        </b-col>
        <b-col class="text-left text-primary fw-bolder">
          {{ operator.ruolo ? operator.ruolo : "--" }}
        </b-col>
        <b-col class="text-center d-flex">
          <b-button size="sm" variant="info info_gradient fw-bolder" class="rounded-pill px-3" @click="selectThis(ind)">
            {{ operator.enabled == 1 ? "disabilita" : "abilita" }}</b-button>
          <b-button size="sm" variant="primary primary_gradient fw-bolder" class="rounded-pill px-3"
            @click="selectThisForRole(ind)">ruolo</b-button>
        </b-col>
      </b-row>
      <p v-if="operators.length == 0" class="text-justify my-2">
        Nessun operatore presente. Per aggiungere un nuovo operatore scorri
        questa pagina fino a "Codice Azienda".
      </p>
    </div>
    <div>
      <h6 class="
          text-prim-grad-1
          fw-bolder
          text-left
          pb-1
          border-bottom border-line-grey
        ">
        Ruoli
      </h6>
      <p class="text-left text-medium-grey fs-5">I ruoli disponibili.</p>
      <div class="text-left">
        <h6 class="fw-bolder">Amministratore</h6>
        <p class="text-justify">
          L'amministratore può visualizzare e gestire tutte le sezioni. Può
          visualizzare il pannello impostazioni e tutte le sue sotto-sezioni,
          gestire tutti i servizi e tutte le richieste, oltre a poter scaricare
          i report dei servizi Facile e Con Te.
        </p>
        <h6 class="fw-bolder">Operatore Avanzato</h6>
        <p class="text-justify">
          L'operatore avanzato può visualizzare e gestire tutte le richieste dei servizi
          Facile e Connessa e può anche visualizzare e modificare le impostazioni
          dei servizi (sezione "Gestione Servizi" delle impostazioni). Può gestire i pazienti,
          somministrare questionari, creare e gestire attività e questionari.
          Non può visualizzare la sezione "Operatori", le Adesioni, le Sottoscrizioni né la scheda anagrafica
          dell'azienda.
        </p>
        <h6 class="fw-bolder">Operatore</h6>
        <p class="text-justify">
          L'operatore può visualizzare e gestire tutte le richieste dei servizi
          Facile e Connessa, visualizzare attività e questionari e somministrare
          questionari Con Te, gestire i pazienti. Non può creare né gestire attività e questionari,
          non può visualizzare le impostazioni né tutte le sotto-sezioni di
          "impostazioni". Questo ruolo è quello assegnato come default a tutte
          le utenze dei dipendenti registratesi tramite codice azienda.
        </p>
      </div>
    </div>
    <b-modal hide-footer title="Conferma Operazione" ref="stat-confirm" size="lg">
      <div v-if="selectedIndex !== null">
        <br />
        <h5 class="text-center">
          Sei sicuro di voler
          {{
              operators[selectedIndex].enabled == 1 ? "disattivare" : "attivare"
          }}
          l'utenza di
          {{
              operators[selectedIndex].fullname !== null
                ? operators[selectedIndex].fullname
                : operators[selectedIndex].email
          }}
          ?
        </h5>
        <br />
        <div class="d-flex justify-content-around py-3">
          <b-button variant="outline-primary fw-bolder" @click="$refs['stat-confirm'].hide()">No, annulla</b-button>

          <b-button variant="info info_gradient fw-bolder text-white" @click="submitChange">Sì, procedi</b-button>
        </div>
      </div>
    </b-modal>

    <b-modal hide-footer title="Modifica Ruolo" ref="role-change" size="lg">
      <div v-if="selectedIndex !== null">
        <br />
        <h5 class="text-left">
          Seleziona il ruolo che vuoi assegnare a

          {{
              operators[selectedIndex].fullname !== null
                ? operators[selectedIndex].fullname
                : operators[selectedIndex].email
          }}:
        </h5>
        <br />
        <div class="my-2 px-2">
          <b-form-select class="shadow_6" v-model="selectedRole" :options="roleOptions"></b-form-select>
        </div>
        <br />
        <div class="d-flex justify-content-around py-3">
          <b-button variant="outline-primary fw-bolder" @click="$refs['role-change'].hide()">Annulla</b-button>

          <b-button variant="primary primary_gradient fw-bolder text-white" @click="submitRoleChange">Procedi</b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { companyService } from "@/_services";
import { mapState, mapActions } from "vuex";
export default {
  name: "OperatorsList",
  data() {
    return {
      operators: null,
      selectedIndex: null,
      selectedRole: null,
      roleOptions: [
        { value: "operatore", text: "operatore" },
        { value: "operatore avanzato", text: "operatore avanzato" },
        { value: "amministratore", text: "amministratore" },
      ],
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    fetchData() {
      var self = this;
      companyService
        .getOperatorsList()
        .then(function (resp) {
          // //console.log(resp);
          self.operators = resp.data.data;
          self.$emit('total-count', self.operators.length)
        })
        .catch(function (err) {
          console.log(err);
          window.scrollTo(0, 0);
          self.errorAlert(
            "non è stato possibile recuperare la lista di operatori"
          );
        });
    },
    selectThis(ind) {
      this.selectedIndex = ind;
      this.$refs["stat-confirm"].show();
    },
    selectThisForRole(ind) {
      this.selectedIndex = ind;
      if (this.operators[this.selectedIndex].ruolo !== null) {
        this.selectedRole = this.operators[this.selectedIndex].ruolo;
      }
      this.$refs["role-change"].show();
    },
    submitChange() {
      var form = {
        hash: this.operators[this.selectedIndex].hashid,
        form: { enabled: !this.operators[this.selectedIndex].enabled },
      };
      var self = this;
      companyService
        .changeOperatorStatus(form)
        .then(function (res) {
          self.$refs["stat-confirm"].hide();
          window.scrollTo(0, 0);
          self.successAlert("Stato operatore aggiornato con successo!");
          self.fetchData();
        })
        .catch(function (err) {
          self.$refs["stat-confirm"].hide();
          window.scrollTo(0, 0);
          self.errorAlert(
            "non è stato possibile modificare lo stato dell'operatore"
          );
        });
    },
    submitRoleChange() {
      var form = {
        hash: this.operators[this.selectedIndex].hashid,
        ruolo: this.selectedRole,
      };
      var self = this;
      companyService
        .changeOperatorRole(form)
        .then(function (res) {
          self.$refs["role-change"].hide();
          window.scrollTo(0, 0);
          self.successAlert("Ruolo operatore aggiornato con successo!");
          self.fetchData();
        })
        .catch(function (err) {
          self.$refs["role-change"].hide();
          window.scrollTo(0, 0);
          self.errorAlert(
            "non è stato possibile modificare il ruolo dell'operatore"
          );
        });
    },
  },
  created() {
    this.fetchData();
  },
};
</script>