<template>
  <!-- <div> -->
  <!-- <b-row cols="1" style="position: absolute; bottom: 0; right: 0"> -->
  <b-row cols="1" class="pls__btn">
    <transition-group name="slide" mode="out-in">
      <b-col v-if="menuOpen" key="neworder">
        <div class="d-flex flex-column justify-content-end">
          <span
            @click="
              $router.push({
                name: 'Nuovo assistito'
              })
            "
            :class="$root.isMobile ? 'col-10' : 'col-4'"
            class="
              point
              submenu__border
              bg-white
              shadow_6
              ml-auto
              my-2
              py-2
              px-4
              fw-bolder
            "
          >
            <b-icon scale="1.3" icon="person" variant="primary"></b-icon>
            <strong class="pl-3 pr-2 fs-4"> Nuovo Assistito</strong>
          </span>
        </div>
      </b-col>
      <b-col v-if="menuOpen" key="newterapy">
        <div class="d-flex justify-content-end">
          <!-- <span
            class="point submenu__border bg-white shadow_6 ml-2 my-2 py-2 px-4 fw-bolder"
          >
            <b-icon scale="1.3" icon="bag-plus" variant="primary"></b-icon>
            <strong class="pl-3 pr-2 fs-4"> Nuova Terapia</strong>
          </span> -->
        </div>
      </b-col>
      <b-col key="openbtn">
        <div class="d-flex justify-content-end mt-2">
          <span class="ml-2 point" @click="menuOpen = !menuOpen">
            <b-iconstack font-scale="3">
              <b-icon stacked icon="circle-fill" variant="primary"></b-icon>
              <b-icon
                stacked
                :icon="menuOpen ? 'x' : 'plus'"
                scale="0.8"
                variant="white"
              ></b-icon>
              <b-icon stacked icon="circle" variant="primary"></b-icon>
            </b-iconstack>
          </span></div
      ></b-col> </transition-group
  ></b-row>
  <!-- </div> -->
</template>
<script>
export default {
  name: "NewStuff",
  data() {
    return {
      menuOpen: false,
    };
  },
  created(){
  }
};
</script>