<template>
  <div>
    <p>{{ survey.description }}</p>
    <h6 class="fw-bolder">Tipologia : {{ survey.tipo }}</h6>
    <h6 class="fw-bolder">Informativa</h6>
    <!-- <p
      v-if="survey.info"
      class="bg-sec-4 border-sec-4 min_border text-justify px-2"
    >
      {{ survey.info }}
    </p> -->
    <p
      v-if="survey.info"
      class="bg-sec-4 border-sec-4 min_border text-justify px-2"
      v-html="survey.info"
    ></p>
    <p v-else>---</p>
    <h6 class="fw-bolder mt-2">Domande</h6>
    <div v-for="(domanda, ind) in quest.domande" :key="ind">
      <h6 class="fw-bolder">{{ ind + 1 }}. {{ domanda.q }}</h6>
      <p
        class="bg-sec-4 border-sec-4 min_border text-justify px-2"
        v-if="domanda.o == 'file'"
      >
        <strong>Risposta: <br /></strong> opzione caricamento immagine
      </p>
      <p
        class="bg-sec-4 border-sec-4 min_border text-justify px-2"
        v-else-if="domanda.o == 'ricognizione'"
      >
        <strong>Risposta: <br /></strong> selezione ed aggiornamento delle
        terapie.
        <span v-if="quest.ricognizione">
          <br />
          Per ciascuna terapia:
          <span v-for="field in quest.ricognizione"> <br />{{ field.q }}</span>
        </span>
      </p>
      <p
        class="bg-sec-4 border-sec-4 min_border text-justify px-2"
        v-else-if="domanda.o"
      >
        <strong>Opzioni risposta: <br /></strong>
        <span v-for="option in domanda.o" :key="option"
          >{{ option }} <br
        /></span>
      </p>
      <p class="bg-sec-4 border-sec-4 min_border text-justify px-2" v-else>
        <strong>Risposta: <br /></strong> campo di testo libero
      </p>
    </div>
    <!-- {{ survey.template }} -->
  </div>
</template>
<script>
export default {
  name: "SurveyShower",
  props: ["survey"],
  data() {
    return {
      quest: null,
    };
  },
  created() {
    this.quest = JSON.parse(this.survey.template);
  },
};
</script>
<style>
.min_border {
  border-radius: 5px;
}
</style>
