<template>
  <b-container fluid>
    <h6 class="text-prim-grad-1 fw-bolder text-left pb-1 ml-3 mt-3">
      Prestazioni
    </h6>
    <div class="my-3">
      <b-row align-v="stretch" class="
          my-2
          col-12
          px-0
          align-items-center align-content-center
          flex-wrap
          my-2
        ">
        <template v-for="(res, ind) in reservations">
          <b-col class="col-12 col-md-4 col-lg-3 my-2" :key="ind" v-if="res.tipo_ordine == 'prestazione'">
            <div v-if="res.orderable" :class="res.status < 0 ? 'disabled__card' : ' bg-white'" class="
                h-100
                text-left
                py-1
                px-2
                py-md-2
                px-md-3
                border
                col-12
                border__2
                d-flex
                flex-column
                border-primary
              ">
              <p class="mb-0 fs-5 pt-1 text-uppercase text-primary long__txt  point" @click="showDetail(res)">
                {{ res.orderable.prestazione.name }}
              </p>
              <p class="
                  mb-0
                  mt-auto
                  pt-1
                  text-capitalize
                  font-weight-bolder
                  d-flex
                  flex-wrap
                  justify-items-between
                  align-items-center
                ">
                <span class="mr-auto">{{ formatData(res.data_inizio) }}</span>
                <b-icon icon="circle-fill" :variant="
                  res.status > 0
                    ? statusPositive[res.status]
                    : 'danger'
                "></b-icon>
                <span class="ml-auto align-self-end point" @click="showDetail(res)">
                  <b-icon icon="eye-fill" font-scale="1.5" variant="secondary"></b-icon>
                </span>
              </p>
            </div>
          </b-col>
        </template>
      </b-row>
      <h6 class="text-prim-grad-1 fw-bolder text-left pb-1 ml-3 mt-3">
        Ordini
      </h6>
      <b-row align-v="stretch" class="
          my-2
          col-12
          px-0
          align-items-center align-content-center
          flex-wrap
          my-2
        ">
        <template v-for="(res, ind) in reservations">
          <b-col class="col-12 my-2" :key="ind + 'pr'" v-if="res.tipo_ordine == 'prodotto'">
            <div :class="res.status < 0 ? 'disabled__card' : ' bg-white'" class="
                h-100
                text-left
                px-3
                col-12
                d-flex
                justify-content-between
                flex-wrap
              ">
              <span class="font-weight-bolder">
                <strong>Ordine </strong>
                <span class="mx-2 pt-1 text-uppercase text-primary">
                  #{{ res.codice_richiesta }}
                </span>
                del
                <span class="mx-2">{{ formatData(res.data_inizio) }}</span>
                <b-icon icon="circle-fill" :variant="
                  res.status > 0
                    ? statusPositive[res.status]
                    : 'danger'
                "></b-icon>
              </span>
              <strong class="text-uppercase">{{ res.stato }}</strong>

              <span class="pt-1 font-weight-bolder point">
                <span class="ml-auto" @click="showDetail(res)">
                  <b-icon icon="eye-fill" font-scale="1.5" variant="secondary"></b-icon>
                </span>
              </span>
            </div>
          </b-col>
        </template>
      </b-row>
      <h6 class="text-prim-grad-1 fw-bolder text-left pb-1 ml-3 mt-3">
        Teleconsulti
      </h6>
      <b-row align-v="stretch" class="
          my-2
          col-12
          px-0
          align-items-center align-content-center
          flex-wrap
          my-2
        ">
        <template v-for="(res, ind) in reservations">
          <b-col class="col-12 my-2" :key="ind + 'tc'" v-if="res.tipo_ordine == 'teleconsulto'">
            <div :class="res.status < 0 ? 'disabled__card' : ' bg-white'" class="
                h-100
                text-left
                px-3
                col-12
                d-flex
                justify-content-between
              ">
              <span class="font-weight-bolder">
                <strong>Teleconsulto</strong>
                <span class="mx-2 pt-1">
                  di
                  <strong class="text-primary">
                    {{ res.orderable.durata }} minuti
                  </strong>
                </span>
                per il
                <span class="mx-2">{{ formatData(res.data_inizio) }}</span>

                <b-icon icon="circle-fill" :variant="
                  res.status > 0
                    ? statusPositive[res.status]
                    : 'danger'
                "></b-icon>
              </span>
              <strong class="text-uppercase"> {{ res.stato }}</strong>

              <span class="pt-1 font-weight-bolder point">
                <span class="ml-auto" @click="showDetail(res)">
                  <b-icon icon="eye-fill" font-scale="1.5" variant="secondary"></b-icon>
                </span>
              </span>
            </div>
          </b-col>
        </template>
      </b-row>
      <b-row>
        <b-col class="text-left col">
          <div class="
              align-items-center align-content-center
              pt-4
              pb-3
              px-2
              point
              bg-white
            " @click="goToNew">
            <p title="nuova prenotazione">
              <b-iconstack font-scale="3.5" class="shadow_7 rounded-circle">
                <b-icon class="shadow_7" stacked icon="circle-fill" variant="secondary"></b-icon>
                <b-icon stacked icon="plus" scale="0.5" variant="white"></b-icon>
              </b-iconstack>
            </p>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <div class="col-12" v-if="reservations && reservations.length > 0">
          <div class="d-flex">
            <span class="text-info fw-bolder mr-1" v-if="total > 0">{{ fromPage }}-{{ toPage }} di
            </span>
            <span class="text-info fw-bolder"> {{ total }}</span>
          </div>
          <b-pagination v-model="filter.page" pills :total-rows="total" :per-page="15" align="center"
            :size="$root.isMobile ? 'sm' : 'lg'"></b-pagination>
        </div>
      </b-row>
      <b-row class="mx-0 mt-2">
        <p v-for="col in colorLegend" :key="col.label" :class="col.color">
          <b-icon icon="circle-fill" font-scale="1.5"></b-icon>
          <span class="text-secondary mx-2">{{ col.label }}</span>
        </p>
      </b-row>
      <b-modal id="modal-resdetails" hide-footer scrollable centered header-class="pb-0" size="xl"
        @hidden="selectedReservation = null">
        <div v-if="selectedReservation">
          <!-- {{ row.item[selectedDay][selectedIndex] }} -->
          <b-card :header="
            'Dettaglio richiesta #' + selectedReservation.codice_richiesta
          " header-text-variant="prim-grad-1" header-bg-variant="white" header-tag="h4"
            class="text-left text-font-grey" border-variant="white">
            <template #header>
              <b-row cols="1" cols-md="2">
                <b-col>
                  <span>Dettaglio richiesta #{{
                      selectedReservation.codice_richiesta
                  }}</span>
                  <p style="font-size: 70%" class="mb-0 text-font-grey mt-1">
                    Inoltrata il
                    <strong>{{
                        selectedReservation.created_at || moment("DD.MM.YY HH:mm")
                    }}</strong>
                  </p>
                </b-col>
                <b-col class="text-md-right text-left">
                  <span class="fs-5">
                    <!-- <strong class="ml-1 text-secondary">Richiesta: </strong> -->
                    <span class="text-prim-grad-1 fw-bolder">{{
                        selectedReservation.orderable.prestazione
                          ? selectedReservation.orderable.prestazione.name
                          : selectedReservation.tipo_ordine === "prodotto"
                            ? "ordine"
                            : selectedReservation.tipo_ordine
                    }}</span>
                  </span>
                  <p style="font-size: 70%" class="mb-0 text-font-grey mt-1">
                    <strong>{{
                        selectedReservation.status === 1
                          ? "ricevuto, da confermare"
                          : selectedReservation.stato
                    }}</strong>
                  </p>
                </b-col>
              </b-row>
            </template>
            <b-card-text>
              <!-- <p class="text-font-grey text-right mb-0">
                <strong class="text-secondary">Tipo richiesta: </strong
                >{{ selectedReservation.tipo_ordine }}
              </p>
              <h5 class="text-secondary fw-bolder mt-1">
                Stato:
                <span class="text-uppercase">{{
                  selectedReservation.stato
                }}</span>
              </h5> -->
              <p class="mb-1 fw-bolder ml-3">
                <strong class="text-secondary">Codice Richiesta: </strong>{{ selectedReservation.codice_richiesta }}
              </p>
              <p class="mb-1 fw-bolder ml-3">
                <strong class="text-secondary">Numero Richiesta: </strong>#{{
                    selectedReservation.numero
                }}
              </p>
              <p class="mb-2 text-secondary fw-bolder ml-3">
                Giorno e ora:
                <strong class="text-font-grey">{{
                    selectedReservation.data_inizio | moment("DD.MM.YY HH:mm")
                }}</strong>
                <strong v-if="selectedReservation.data_fine">
                  -
                  {{ selectedReservation.data_fine | moment("HH:mm") }}</strong>
                <UpdateReservationDate class="d-inline ml-2 ml-md-0 ml-lg-1" :hash="selectedReservation.hashid"
                  v-on:change-requested="handleUpdateRequest" />
              </p>
              <DetailTeleconsulto :tobecompleted="selectedReservation.suspended_survey"
                :dettaglio="selectedReservation.orderable" :stato="selectedReservation.status"
                :quest="selectedReservation.filled_survey" :datainizio="selectedReservation.data_inizio"
                :hash="selectedReservation.hashid" v-if="selectedReservation.tipo_ordine === 'teleconsulto'" />
              <DetailPrestazione :tobecompleted="selectedReservation.suspended_survey"
                :detail="selectedReservation.orderable" :stato="selectedReservation.status"
                :quest="selectedReservation.filled_survey" v-if="selectedReservation.tipo_ordine === 'prestazione'"
                v-on:update-request-status="
                  handleStatusUpdate(selectedReservation, $event)
                " />
              <DetailProdotto :dettaglio="selectedReservation.orderable" :stato="selectedReservation.status"
                :datainizio="selectedReservation.data_inizio" v-if="selectedReservation.tipo_ordine === 'prodotto'"
                v-on:update-request-status="
                  handleStatusUpdate(selectedReservation, $event)
                " v-on:update-request-response="
                  sendOrderResponse(selectedReservation, $event)
                " ref="dett" />
              <p class="mb-0 bg-light p-1">
                <strong> Note Operatore </strong>
                <UpdateNotesComponent v-on:note-updated="handleNotesUpdate($event)" class="d__innline"
                  :hashid="selectedReservation.hashid" :reserdate="selectedReservation.data_inizio">
                </UpdateNotesComponent>
                <template v-if="selectedReservation.company_notes"><br>
                  {{ selectedReservation.company_notes }}
                </template>
              </p>
              <p class="mb-0  p-1 bg-light" v-if="selectedReservation.user_notes">
                <strong> Note dell'utente:</strong>
                {{ selectedReservation.user_notes }}
              </p>
              <div class="d-flex flex-wrap ml-3 justify-content-between">
                <b-button variant="primary primary_gradient rounded-pill fw-bolder px-4 mt-1" @click="downloadPdf()">
                  Scarica Promemoria
                </b-button>
                <b-button v-if="
                  selectedReservation.status !== 4 &&
                  selectedReservation.status > 0
                " @click="openEvadi(selectedReservation)"
                  variant="primary primary_gradient text-white rounded-pill fw-bolder px-4">
                  Evadi
                </b-button>
              </div>
            </b-card-text>
          </b-card>
        </div>
      </b-modal>

      <b-modal size="lg" centered id="modal-evasione" title="Evadi Richiesta" hide-footer>
        <div v-if="toEvadere">
          <p class="text-left text-font-grey">
            Numero Richiesta: #{{ toEvadere.numero }} <br />
          </p>
          <p class="text-left text-font-grey">
            Codice Richiesta:
            {{ toEvadere.codice_richiesta }} <br />
          </p>

          <b-form-input name="detail" class="mx-auto shadow_6" v-model="evasione.hash" type="text" hidden>
          </b-form-input>
          <b-form-group label="Aggiungi Annotazione" label-for="detail" class="text-left fs-6 text-secondary">
            <b-form-input name="detail" class="mx-auto shadow_6" v-model="evasione.details" type="text">
            </b-form-input>
          </b-form-group>
          <div class="text-center my-2 pt-4">
            <b-button type="button" class="mx-2" variant="outline-danger" @click="$bvModal.hide('modal-evasione')">
              Annulla</b-button>
            <b-button type="button" class="mx-2" variant="primary primary_gradient text-white fw-bolder spacing_1"
              @click="evadiThis">Conferma</b-button>
          </div>
        </div>
      </b-modal>
    </div>
  </b-container>
</template>
<script>
import * as moment from "moment/moment";
import { mapState, mapActions } from "vuex";
import { reservationService } from "@/_services";
import DetailPrestazione from "@/components/prenotazioni/DetailPrestazione.vue";
import DetailTeleconsulto from "@/components/prenotazioni/DetailTeleconsulto.vue";
import DetailProdotto from "@/components/prenotazioni/DetailProdotto.vue";
import UpdateNotesComponent from "@/components/prenotazioni/UpdateNotesComponent.vue";
import UpdateReservationDate from "@/components/prenotazioni/UpdateReservationDate.vue";
export default {
  name: "PrenotazioniCliente",
  props: ["subject"],
  components: {
    DetailPrestazione,
    DetailTeleconsulto,
    DetailProdotto,
    UpdateNotesComponent,
    UpdateReservationDate
  },
  data() {
    return {
      reservations: [],
      countPrev: 0,
      count: 0,
      total: 0,
      fromPage: 1,
      toPage: 0,
      filter: { page: 1 },
      selectedReservation: null,
      evasione: {
        details: null,
        hash: null,
      },
      toEvadere: null,
      colorLegend: [
        {
          label: "Confermato",
          color: "confermato",
          val: "confermate",
        },
        {
          label: "Evaso/Concluso",
          color: "evaso",
          val: "evase",
        },
        {
          label: "Ricevuto/In attesa di risposta",
          color: "inoltrato-in-attesa",
          val: "in attesa",
        },
        {
          label: "Errore/Rifiutato/Annullato",
          color: "rifiutato-annullato",
          val: "annullate",
        },
      ],
      statusPositive: {
        1: "inoltrato-in-attesa",
        2: "confermato",
        3: "confermato",
        4: "evaso",
        5: "inoltrato-in-attesa",
        6: "inoltrato-in-attesa",
      },
      statusNegative: {
        1: "rifiutato-annullato",
        2: "rifiutato-annullato",
        3: "rifiutato-annullato",
        4: "rifiutato-annullato",
      },
      // statusPositive: {
      //   1: "warning",
      //   2: "secondary",
      //   3: "secondary",
      //   4: "ter-1",
      //   5: "warning",
      //   6: "warning",
      // },
      // statusNegative: {
      //   1: "orange",
      //   2: "orange",
      //   3: "orange",
      //   4: "orange",
      // },
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
  },
  watch: {
    filter: {
      handler(prev, post) {
        // //console.log("changed prev", prev.page, post);
        // //console.log("changed post", post.page);
        this.prenotazioni = [];
        this.fetchData();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    openEvadi(item) {
      this.toEvadere = item;
      this.evasione.hash = item.hashid;
      this.$bvModal.show("modal-evasione");
    },
    evadiThis(hash = null) {
      // //console.log("richiesta da evadere ", hash);
      hash = this.evasione.hash;
      var self = this;
      reservationService
        .closeOrder(this.evasione)
        .then(function (response) {
          self.$bvModal.hide("modal-evasione");
          self.$bvModal.hide("modal-resdetails");
          self.evasione.details = null;
          self.evasione.hash = null;
          window.scrollTo(0, 0);
          self.successAlert("prenotazione evasa con successo");
          // //console.log("response fetch prenotazioni", response);
          self.fetchData();
        })
        .catch(function (error) {
          self.$bvModal.hide("modal-evasione");
          self.$bvModal.hide("modal-resdetails");
          window.scrollTo(0, 0);
          self.errorAlert("non è stato possibile evadere la prenotazione");
          // //console.log("error fetch prenotazioni", error);
        });
    },
    sendOrderResponse(item, data) {
      // //console.log(item);
      // //console.log("data to update", data);
      var form = {
        hashid: item.hashid,
        company_notes: data.company_notes,
        data_inizio: data.newDateTime,
        order: data.detail,
        status: 5,
      };
      // //console.log(form);
      var self = this;
      reservationService
        .updateReservation(form)
        .then(function (response) {
          // //console.log(response);
          self.$bvModal.hide("modal-resdetails");
          window.scrollTo(0, 0);
          self.successAlert("risposta inviata con successo");
          self.fetchData();
        })
        .catch(function (error) {
          // console.log(error);
          self.$bvModal.hide("modal-resdetails");
          window.scrollTo(0, 0);
          self.errorAlert("non è stato possibile inviare la risposta");
        });
    },
    goToNew() {
      this.$router.push({
        name: "nuova prenotazione servizio",
        params: {
          hash: this.subject.hashid,
        },
      });
    },
    handleStatusUpdate(item, data) {
      // //console.log("item to edit", item);
      console.log("data to submit", data);
      var self = this;
      if (!data.confirmed) {
        // this.loading = true;
        var form = data.message ? { message: data.message } : {};
        reservationService
          .companyCancelOrReject(form, data.action, item.hashid)
          .then(function (re) {
            // self.$bvModal.hide("modal-evasione");
            self.$bvModal.hide("modal-resdetails");
            // self.$refs["modal-resdetails"].hide();
            window.scrollTo(0, 0);
            self.successAlert("Richiesta aggiornata con successo");
            self.fetchData();
          })
          .catch(function (error) {
            // self.$bvModal.hide("modal-evasione");
            self.$bvModal.hide("modal-resdetails");
            // self.$refs["resev-details"].hide();
            window.scrollTo(0, 0);
            self.errorAlert("non è stato possibile effettuare l'operazione");
            // self.loading = false;
          });
      } else {
        // this.loading = true;
        var form = data.message ? { message: data.message } : {};
        reservationService
          .companyConfirmOrder(item.hashid)
          .then(function (re) {
            self.$bvModal.hide("modal-evasione");
            self.$bvModal.hide("modal-resdetails");
            window.scrollTo(0, 0);
            self.successAlert("Richiesta confermata con successo");
            self.fetchData();
          })
          .catch(function (error) {
            self.$bvModal.hide("modal-evasione");
            self.$bvModal.hide("modal-resdetails");
            window.scrollTo(0, 0);
            self.errorAlert("non è stato possibile effettuare l'operazione");
            // self.loading = false;
          });
      }
      // if (data.action == "reject") {
      //   //do reject
      // }
      // if (data.action == "cancel") {
      //   //do Cancel
      // }
    },
    fetchData() {
      var self = this;
      reservationService
        .getUsersReservsInCompany(this.filter, this.subject.hashid)
        .then(function (res) {
          self.reservations = res.data.data.data;
          self.count = res.data.data.length;
          self.filter.page = res.data.data.current_page;
          self.total = res.data.data.total;
          self.fromPage = res.data.data.from;
          self.toPage = res.data.data.to;
        })
        .catch(function (error) {
          self.reservations = [];
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare le prenotazioni");
        });
    },
    formatData(data) {
      return moment(data).format("DD/MM/YYYY HH:mm");
    },
    showDetail(res) {
      // //console.log(res.orderable);
      this.selectedReservation = res;
      this.$bvModal.show("modal-resdetails");
    },
    downloadPdf() {
      const hash = this.selectedReservation.hashid;
      const fName = this.selectedReservation.codice_richiesta;
      var self = this;
      reservationService
        .scaricaPromemoria(hash)
        .then(function (response) {
          let blob = new Blob([response.data]);
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = fName + ".pdf";
          link.click();
        })
        .catch(function (error) {
          self.loading = false;
          window.scrollTo(0, 0);
          self.errorAlert("non è stato possibile scaricare il promemoria");
        });
    },
    handleNotesUpdate(data) {
      this.selectedReservation.company_notes = data.company_notes
    },
    handleUpdateRequest() {
      this.fetchData();
      this.$bvModal.hide("modal-resdetails");
      this.successAlert("richiesta inviata con successo");
    }
  },
  created() {
    // //console.log(this.subject);
    this.fetchData();
  },
};
</script>