<template>
  <div class="py-3" v-if="user.permessi.includes('somministrare questionari')">
    <div class="d-flex justify-content-between" v-if="$route.query.user">
      <router-link
        class="fw-bolder text-primary no__hover"
        :to="{
          name: 'scheda Paziente',
          params: { hash: $route.query.user },
          query: { panel: 'questionari_utente' },
        }"
      >
        <b-icon icon="arrow-left-circle-fill" variant="primary"></b-icon>
        Scheda Paziente
      </router-link>
    </div>
    <div class="d-flex justify-content-between" v-else>
      <router-link
        class="fw-bolder text-primary no__hover"
        :to="{ name: 'questionari' }"
      >
        <b-icon icon="arrow-left-circle-fill" variant="primary"></b-icon>
        Questionari
      </router-link>
    </div>
    <div v-if="survey && !complete" class="py-3">
      <h6
        class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
      >
        {{ survey.name }}
        <span v-if="survey.cap" class="text-primary"
          >Compilati {{ survey.tot_filled }}/{{ survey.cap }}</span
        >
      </h6>
      <p class="text-justify text-medium-grey fw-bolder">
        {{ survey.description }}
      </p>
      <template v-if="survey.activity.prof2 === 1">
        <CompilaQuestionarioIrst
          :survey="survey"
          v-on:filled-form="submitForm($event)"
          :key="`irdt-${reloadKey}`"
        />
      </template>
      <template v-else>
        <template v-if="needToSelectUser">
          <div v-if="!selectedUser" class="mt-4 text-left">
            <template v-if="!arruolamento">
              <!-- <h6 class="text-left">
                Seleziona il paziente a cui si intende somministrare il
                questionario:
              </h6> -->
              <SearchUtente
                class="text-left ml-0"
                :fullresponse="true"
                v-on:select-user="selectUserForSurvey($event)"
                v-on:arruola-user="doRegister($event)"
              />
            </template>
            <b-row cols="1" class="my-2" v-else>
              <h6
                class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
              >
                Arruola Paziente
              </h6>
              <p class="text-left text-font-light-grey fs-5"></p>
              <SchedaArruolamento
                :params="precompiled"
                v-on:cancel-operation="undoArruolamento"
                v-on:arruolamento-done="goOn($event, userdata)"
              />
            </b-row>
          </div>
          <template v-else-if="selectedUser">
            <div
              class="border border-secondary text-left px-3 py-2 bg-light-panel mb-4"
            >
              <h6 class="text-left">Paziente selezionato (oppure tutore legale se minore di 14 anni):</h6>
              <b-row cols="1" cols-md="2" cols-lg="3">
                <b-col>
                  <span class="mr-2">Nome e Cognome:</span>
                  <strong class="text-uppercase">
                    {{ selectedUser.anagrafica.name.toLowerCase() }}
                    {{ selectedUser.anagrafica.surname.toLowerCase() }}
                  </strong>
                </b-col>
                <b-col>
                  <span class="mr-2">Codice fiscale:</span>
                  <strong class="text-uppercase">
                    {{ selectedUser.anagrafica.cf }}
                  </strong>
                </b-col>
                <b-col>
                  <span class="mr-2">Comune:</span>
                  <strong class="text-capitalize">
                    <span v-if="selectedUser.anagrafica.frazione">
                      {{ selectedUser.anagrafica.frazione }} -
                    </span>
                    {{ selectedUser.anagrafica.comune }}
                    ({{ selectedUser.anagrafica.regione }})
                  </strong>
                </b-col>
              </b-row>
              <b-row class="mt-3">
                <b-button
                  size="sm"
                  variant="outline-danger fw-bolder spacing_1"
                  @click="selectedUser = null"
                  >Annulla selezione</b-button
                >
                <b-button
                  v-if="canEditAnagrafica"
                  size="sm"
                  variant="secondary fw-bolder spacing_1 mx-2"
                  @click="$bvModal.show('anagraphic_editor')"
                  >Modifica anagrafica
                </b-button>
              </b-row>
            </div>
            <template v-if="userIsVerified">
              <div v-if="survey.info">
                <div v-if="!proceed">
                  <h6>Informativa</h6>
                  <p class="text-justify" v-html="survey.info"></p>
                  <b-form-checkbox
                    class="text-font-light-grey"
                    name="data_consense"
                    :value="true"
                    :unchecked-value="false"
                    v-model="consent"
                    :state="consent == true"
                  >
                    Ho consegnato/comunicato l'informativa al cittadino
                  </b-form-checkbox>
                  <b-button
                    variant="primary primary_indo text-white fw-bolder spacing_1"
                    :disabled="!consent"
                    @click="proceed = true"
                    >Procedi</b-button
                  >
                </div>
                <div v-else>
                  <b-alert variant="info" show>
                    Con la somministrazione della presente prestazione,
                    l'operatore sanitario, sotto la propria esclusiva
                    responsabilità, si impegna ad arruolare e includere nella
                    sperimentazione i soli pazienti aderenti ai criteri di
                    inclusione previsti dalle delibere della Regione di
                    appartenenza. L'operatore sanitario, pertanto, è consapevole
                    che le somministrazioni ad assistiti non aderenti a tali
                    criteri e limiti previsti dalle regioni di appartenenza
                    potranno essere escluse dal rimborso previsto.
                  </b-alert>
                  <CompilaQuestionarioRicognizione
                    v-if="survey.has_ricognizione"
                    :survey="survey"
                    :patient="selectedUser"
                    v-on:filled-form="submitForm($event)"
                    :key="`ricognizione-${reloadKey}`"
                  ></CompilaQuestionarioRicognizione>
                  <CompilaQuestionarioDeblistering
                    v-else-if="survey.deblistering"
                    :survey="survey"
                    :patient="selectedUser"
                    v-on:filled-form="submitForm($event)"
                    :key="`deblistering-${reloadKey}`"
                  ></CompilaQuestionarioDeblistering>
                  <template v-else-if="survey.has_score">
                    <CompilaQuestionarioScored
                      v-if="!eccezionePuglia"
                      :survey="survey"
                      :patient="selectedUser"
                      v-on:filled-form="submitForm($event)"
                      :key="`scored-${reloadKey}`"
                    ></CompilaQuestionarioScored>
                    <CompilaQuestionarioScoredDiabetePuglia
                      v-else
                      :survey="survey"
                      :patient="selectedUser"
                      v-on:filled-form="submitForm($event)"
                      :key="`scored-${reloadKey}`"
                    ></CompilaQuestionarioScoredDiabetePuglia>
                  </template>
                  <compila-questionario
                    v-else
                    :survey="survey"
                    v-on:filled-form="submitForm($event)"
                    :key="`questionario-${reloadKey}`"
                  />
                </div>
              </div>
            </template>
            <template v-else>
              <OtpValidation
                :user="selectedUser"
                :cause="1"
                :validatable="selectedUser.hashid"
                :second="selectedUser.number_verified_at ? true : false"
                @otp-validated="handleOtpValidation($event)"
                class="text-center"
              >
                <template v-slot:instruction>
                  <h1>
                    <b-icon
                      icon="exclamation-circle-fill"
                      variant="danger"
                    ></b-icon>
                  </h1>
                  <template v-if="selectedUser.number_verified_at">
                    <h2 class="text-center">
                      Poiché il paziente non possiede un indirizzo email, è
                      necessario che venga verificato anche il numero di
                      telefono di riserva.
                    </h2>
                    <h5 class="my-3 text-center">
                      Clicca su "invia codice di verifica" per inviare il codice
                      OTP di verifica via SMS al numero di riserva del paziente
                    </h5>
                  </template>
                  <template v-else>
                    <h2 class="text-center">
                      Per proseguire è necessario che venga verificato il numero
                      del paziente selezionato.
                    </h2>
                    <h5 class="my-3 text-center">
                      Clicca su "invia codice di verifica" per inviare il codice
                      OTP di verifica via SMS
                    </h5>
                  </template>
                </template>
              </OtpValidation>
            </template>
          </template>
        </template>
        <template v-else-if="needToSelectPseudo">
          <div v-if="!selectedPseudonimo" class="text-left">
            <h6 class="text-left mb-2">Inserisci pseudonimo*:</h6>
            <b-row>
              <b-col>
                <b-form-group>
                  <b-form-input
                    class="shadow_6"
                    v-model="pseudonimo"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col class="mt-1">
                <b-button
                  v-if="pseudonimo && pseudonimo.length > 5"
                  type="button"
                  variant="primary primary__gradient"
                  @click="selectedPseudonimo = pseudonimo"
                  >Conferma</b-button
                >
              </b-col>
            </b-row>
          </div>
          <template v-else>
            <h6 class="text-left text-capitalize">
              Paziente:
              <span class="text-prim-grad-1">{{ selectedPseudonimo }}</span>
            </h6>

            <template v-if="!eccezionePuglia">
              <CompilaQuestionarioScored
                v-if="survey.has_score"
                :survey="survey"
                :patient="selectedUser"
                v-on:filled-form="submitForm($event)"
                :key="`scored-pseudo-${reloadKey}`"
              ></CompilaQuestionarioScored>
              <compila-questionario
                v-else
                :survey="survey"
                v-on:filled-form="submitForm($event)"
                :key="`questionario-pseudo-${reloadKey}`"
              />
            </template>
            <CompilaQuestionarioScoredDiabetePuglia
              v-else-if="eccezionePuglia && survey.has_score"
              :survey="survey"
              :patient="selectedUser"
              v-on:filled-form="submitForm($event)"
              :key="`scored-${reloadKey}`"
            ></CompilaQuestionarioScoredDiabetePuglia>
          </template>
        </template>
        <template v-else>
          <template v-if="!eccezionePuglia">
            <CompilaQuestionarioScored
              v-if="survey.has_score"
              :survey="survey"
              :patient="selectedUser"
              v-on:filled-form="submitForm($event)"
              :key="`scored-${reloadKey}`"
            ></CompilaQuestionarioScored>
            <compila-questionario
              v-else
              :survey="survey"
              v-on:filled-form="submitForm($event)"
              :key="`questionario-${reloadKey}`"
            />
          </template>
          <CompilaQuestionarioScoredDiabetePuglia
            v-else-if="eccezionePuglia && survey.has_score"
            :survey="survey"
            :patient="selectedUser"
            v-on:filled-form="submitForm($event)"
            :key="`scored-${reloadKey}`"
          ></CompilaQuestionarioScoredDiabetePuglia>
        </template>
      </template>
    </div>
    <div v-else-if="complete">
      <h2 class="fw-bolder">Operazione completata.</h2>
      <h5
        class="text-center mt-3"
        v-if="esito && esito.identificativo_deblistering !== undefined"
      >
        IDENTIFICATIVO CONFEZIONAMENTO: {{ esito.identificativo_deblistering }}
      </h5>
      <h5 class="text-center mt-3" v-if="esito && esito.score !== undefined">
        Punteggio totalizzato: {{ esito.score }}
      </h5>
      <div class="text-left" v-if="esito.reference">
        <template v-if="survey.type !== 0">
          <h5 class="text-ptim-grad-1">Interpretazione punteggio:</h5>
          <template v-for="(reference, ind) in esito.reference">
            <h6 class="fw-bolder">
              Totale
              <span v-if="reference.min_ref === reference.max_ref">
                {{ reference.max_ref * 1 }}</span
              >
              <span v-else>
                {{ reference.min_ref * 1 }} -
                {{ reference.max_ref * 1 }}
              </span>
              <!-- <span class="font-italic" v-if="reference.description"
                >: {{ reference.description }}</span
              > -->
              <template v-if="reference.description">
                <span
                  class="font-italic"
                  v-html="'<span>' + reference.description + '</span>'"
                ></span>
              </template>
            </h6>
          </template>
        </template>
        <template v-else>
          <h5 class="text-ptim-grad-1 mt-4 pt-4">Interpretazione punteggio:</h5>
          <template v-for="(reference, ind) in esito.reference">
            <h6
              class="fw-bolder"
              v-if="
                esito.score >= reference.min_ref &&
                esito.score <= reference.max_ref
              "
            >
              <!-- <span class="font-italic" v-if="reference.description"
                >: {{ reference.description }}</span
              > -->
              <template v-if="reference.description">
                <span
                  class="font-italic"
                  v-html="'<span>' + reference.description + '</span>'"
                ></span>
              </template>
            </h6>
          </template>
        </template>
      </div>
      <div class="text-left" v-if="esito.prevs">
        <h5 class="text-ptim-grad-1">Storico punteggi:</h5>
        <template v-for="(prevScore, ind) in esito.prevs">
          <h6 class="fw-bolder">
            Punteggio totalizzato il
            {{ prevScore.created_at | moment("DD/MM/YYYY") }}:
            {{ prevScore.value * 1 }}

            <!-- <span
              class="font-italic"
              v-if="
                prevScore.score_reference &&
                prevScore.score_reference.description
              "
              >{{ prevScore.score_reference.description }}</span
            > -->

            <!-- <template
              v-if="
                prevScore.score_reference &&
                prevScore.score_reference.description
              "
            >
              <span
                class="font-italic"
                v-html="
                  '<span>' + prevScore.score_reference.description + '</span>'
                "
              ></span>
            </template> -->
          </h6>
        </template>
      </div>
      <template v-if="needToSelectUser">
        <h6 class="text-justify py-3">
          Cliccando sul tasto "scarica pdf per il paziente" puoi scaricare il
          questionario appena compilato in versione stampabile, comprensivo di
          informativa (laddove presente) e di spazio dedicato per la firma.
          Potrai comunque scaricare il pdf per il paziente e il questionario in
          altri formati accendendo alla reportistica dedicata al questionario.
          <br />
          Ove previsto, per pianificare un follow up, vai alla scheda del
          paziente e clicca sul tasto "+" che trovi nella sezione
          "prenotazioni".
        </h6>
        <b-row class="mt-3">
          <b-col>
            <b-button
              variant="primary primary_gradient text-white fw-bolder spacing_1"
              @click="goToScheda"
              >Vai a scheda paziente</b-button
            >
          </b-col>
          <b-col>
            <b-button
              variant="primary primary_gradient text-white fw-bolder spacing_1"
              @click="downloadPatientVersion()"
            >
              <b-icon icon="download"></b-icon>
              scarica pdf per il paziente
            </b-button>
          </b-col>
        </b-row>
      </template>
    </div>
    <b-modal
      id="anagraphic_editor"
      size="xl"
      title="Modifica anagrafica paziente"
      body-class="pt-0"
      scrollable
      hide-footer
    >
      <p class="mb-0 px-1">
        Se necessario, modifica le informazioni anagrafiche del paziente
        essenziali per abilitarne l'utenza prestando particolare attenzione ai
        dati di contatto ( email e numero di telefono), per i quali è richiesta
        la validazione.
      </p>
      <PazienteAnagraficaEditor
        :user="selectedUser"
        @cancel-operation="$bvModal.hide('anagraphic_editor')"
        @edit-done="handleEditDone"
      ></PazienteAnagraficaEditor>
    </b-modal>
    <b-modal
      id="new-survey-confirmation"
      size="lg"
      v-if="survey"
      centered
      title="Avviso inserimento multiplo"
      button-size="sm"
      ok-title="Ok, Chiudi"
      ok-variant="outline-secondary px-3 font-weight-bolder rounded-pill"
      footer-class="border-0"
      @ok="handleSubmitCancellation()"
      @cancel="handleSubmitCancellation()"
      @hide="handleSubmitCancellation()"
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      ok-only
    >
      <template #default>
        <div class="p-4">
          <div class="">
            <p>
              Hai già registrato un <i>{{ survey.name }}</i> per
              <span class="text-capitalize"> {{ selectedUserName }} </span> in
              data odierna.
            </p>
            <!-- <p>Vuoi procedere ugualmente?*</p>
          </div>
          <div class="mt-auto">
            <p class="fs-4 mb-0">
              *Annullando l'invio tornerai alla selezione del paziente.
            </p> -->
          </div>
        </div>
      </template>
    </b-modal>
    <b-modal
      id="patient-survey-limit-reached"
      size="lg"
      v-if="survey"
      centered
      title="Avviso limite questionari"
      button-size="sm"
      ok-title="Ok, Chiudi"
      ok-variant="outline-secondary px-3 font-weight-bolder rounded-pill"
      footer-class="border-0"
      @ok="handleSubmitCancellation()"
      @cancel="handleSubmitCancellation()"
      @hide="handleSubmitCancellation()"
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      ok-only
    >
      <template #default>
        <div class="p-4">
          <div class="">
            <p>
              Hai raggiunto il limite massimo di questionari <i>{{ survey.name }}</i>
              somministrabili al paziente
              <span class="text-capitalize"> {{ selectedUserName }} </span>
            </p>
            <!-- <p>Vuoi procedere ugualmente?*</p>
          </div>
          <div class="mt-auto">
            <p class="fs-4 mb-0">
              *Annullando l'invio tornerai alla selezione del paziente.
            </p> -->
          </div>
        </div>
      </template>
    </b-modal>
    <b-modal
      id="warning-another-company"
      size="lg"
      v-if="survey"
      centered
      title="Avviso questionario da altra farmacia"
      button-size="sm"
      ok-title="Sì, procedi"
      ok-variant="outline-secondary px-3 font-weight-bolder rounded-pill"
      cancel-title="No, annulla"
      cancel-variant="outline-danger px-3 font-weight-bolder rounded-pill"
      footer-class="border-0"
      @ok="$bvModal.hide('warning-another-company')"
      @cancel="$bvModal.hide('warning-another-company'); handleSubmitCancellation()"
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
    >
      <template #default>
        <div class="p-4">
          <p>Attenzione!</p>

          Il questionario <i>{{ survey.name }}</i> è stato somministrato
          all'assistito
          <span class="text-capitalize"> {{ selectedUserName }} </span> in data
          <span class="text-capitalize">
            {{ filledSurveyFromAnotherCompanyInfo.data }}
          </span>
          dalla farmacia
          <span class="text-capitalize">
            {{ filledSurveyFromAnotherCompanyInfo.denominazione }}. </span
          > Vuoi procedere con la compilazione?
        </div>
      </template>
    </b-modal>
  </div>
  <div v-else>
    <h4 class="fw-bolder">Operazione non consentita.</h4>
  </div>
</template>

<script>
import { supportService, cittadinoService } from "@/_services";
import { mapState, mapActions } from "vuex";
import CompilaQuestionario from "@/components/attivita/CompilaQuestionario.vue";
import CompilaQuestionarioIrst from "@/components/attivita/CompilaQuestionarioIrst.vue";
import SearchUtente from "@/components/utilities/SearchUtente.vue";
import SchedaArruolamento from "@/components/pazienti/SchedaArruolamento.vue";
import CompilaQuestionarioScored from "@/components/attivita/CompilaQuestionarioScored.vue";
import CompilaQuestionarioRicognizione from "@/components/attivita/CompilaQuestionarioRicognizione.vue";
import CompilaQuestionarioDeblistering from "@/components/attivita/CompilaQuestionarioDeblistering.vue";
import OtpValidation from "@/components/utilities/OtpValidation.vue";
import PazienteAnagraficaEditor from "@/components/pazienti/PazienteAnagraficaEditor.vue";
import CompilaQuestionarioScoredDiabetePuglia from "@/components/attivita/CompilaQuestionarioScoredDiabetePuglia.vue";

export default {
  name: "CompilaQuestionario",
  components: {
    "compila-questionario": CompilaQuestionario,
    SearchUtente,
    CompilaQuestionarioIrst,
    SchedaArruolamento,
    CompilaQuestionarioScored,
    CompilaQuestionarioRicognizione,
    OtpValidation,
    PazienteAnagraficaEditor,
    CompilaQuestionarioDeblistering,
    CompilaQuestionarioScoredDiabetePuglia,
  },
  computed: {
    userIsVerified() {
      if (this.selectedUser) {
        if (!this.selectedUser.number_verified_at) {
          return false;
        }
        return true;
      }
      return false;
    },
    canEditAnagrafica() {
      if (this.selectedUser && this.selectedUser.is_my_patient) {
        if (
          this.selectedUser.email_verified_at ||
          this.selectedUser.number_verified_at
        ) {
          return false;
        }
        return this.selectedUser.all_consenses === false;
      }
      if (this.selectedUser && this.selectedUser.created_by_me) {
        if (
          this.selectedUser.email_verified_at ||
          this.selectedUser.number_verified_at
        ) {
          return false;
        }
        return this.selectedUser.all_consenses === false;
      }
      return false;
    },
    eccezionePuglia() {
      if (
        this.survey &&
        this.survey.hashid === this.questionarioEccezionePuglia
      )
        return this.survey.hashid === this.questionarioEccezionePuglia;
    },
    ...mapState({
      alert: (state) => state.alert,
    }),
    ...mapState("utente", ["status", "user"]),
    selectedUserName() {
      if (this.selectedUser) {
        return (
          this.selectedUser.anagrafica.name +
          " " +
          this.selectedUser.anagrafica.surname
        );
      }
      return null;
    },
    todayDate() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0");
      var yyyy = today.getFullYear();
      return dd + "/" + mm + "/" + yyyy;
    },
  },
  data() {
    return {
      hash: this.$route.params.quest,
      survey: null,
      consent: false,
      proceed: false,
      complete: false,
      surveTypes: {
        0: "anonimo",
        1: "pseudoanonimo",
        2: "in chiaro",
      },
      needToSelectUser: true,
      needToSelectPseudo: true,
      selectedUser: null,
      selectedPseudonimo: null,
      pseudonimo: null,
      arruolamento: false,
      precompiled: null,
      filledSurveyHashid: null,
      loading: false,
      esito: null,
      pendingSurvey: null,
      reloadKey: 0,
      filledSurveyFromAnotherCompanyInfo: {
        denominazione: null,
        data: null,
      },
      questionarioEccezionePuglia:
        process.env.VUE_APP_HASHID_SCREENING_DIABETE_PUGLIA,
    };
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    fetchData() {
      var self = this;
      supportService
        .getSurveyById(this.hash)
        .then(function (res) {
          self.survey = res.data.data;
          self.needToSelectUser = res.data.data.type === 2;
          self.needToSelectPseudo = res.data.data.type === 1;
          self.consent = self.survey.info == null;
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare le attività");
          console.log(err);
        });
    },
    selectUserForSurvey(user) {
      this.selectedUser = user;
      this.verificheQuestionari(user);
    },
    goToScheda() {
      this.$router.push({
        name: "scheda Paziente",
        params: { hash: this.selectedUser.hashid },
      });
    },
    doRegister(params) {
      this.arruolamento = true;
      this.precompiled = params;
    },
    undoArruolamento() {
      this.arruolamento = false;
      this.precompiled = null;
    },
    goOn(userdata) {
      this.selectedUser = userdata;
      this.fetchUser(userdata.hashid);
      this.arruolamento = false;
    },
    handleOtpValidation(hasVerified) {
      if (hasVerified) {
        return this.fetchUser(this.selectedUser.hashid);
      }
    },
    verificaQuestionarioDuplicato: function (user) {
      var self = this
      console.log("Verifica questionario duplicato...")
      return supportService
        .checkIfFilledSurveyAlreadyExists(user.hashid, this.survey.hashid)
        .then(function (res) {
          console.log(
            "Verifica questionario duplicato risultato:",
            res.data.data
          )
          if (res.data.data) {
            self.$bvModal.show("new-survey-confirmation")
            return Promise.reject("Questionario duplicato")
          }
          return false
        })
    },
    verificaLimiteRaggiunto: function () {
      var self = this
      console.log("Verifica raggiungimento limite...")
      return supportService
        .checkIfSurveyHasReachedLimit(
          this.survey.hashid,
          self.selectedUser.hashid
        )
        .then(function (res) {
          console.log(
            "Verifica raggiungimento limite risultato:",
            res.data.data
          )
          if (res.data.data) {
            self.$bvModal.show("patient-survey-limit-reached")
            return Promise.reject("Limite raggiunto")
          }
          return false
        })
     },
    verificaAltraFarmacia: function () {
      var self = this
      console.log("Verifica altra farmacia...")
      return supportService
        .checkForPastFilledSurveyFromAnotherCompany(
          this.survey.hashid,
          self.selectedUser.hashid
        )
        .then(function (res) {
          console.log("Verifica altra farmacia risultato:", res.data.data)
          if (res.data.data.exists) {
            self.filledSurveyFromAnotherCompanyInfo.denominazione =
              res.data.data.company
            self.filledSurveyFromAnotherCompanyInfo.data = res.data.data.date
            self.$bvModal.show("warning-another-company")
            return true
          }
          return false
        })
    },
    verificheQuestionari: function (user) {
      var self = this

      this.verificaQuestionarioDuplicato(user)
        .then(function (isDuplicato) {
          if (isDuplicato) {
            return Promise.reject("Questionario duplicato")
          }
          return self.verificaLimiteRaggiunto()
        })
        .then(function (limiteRaggiunto) {
          if (limiteRaggiunto) {
            return Promise.reject("Limite raggiunto")
          }
          return self.verificaAltraFarmacia()
        })
        .then(function (altraFarmacia) {
          if (!altraFarmacia) {
            console.log(
              "Tutte le verifiche passate, procedi con la compilazione"
            )
            // Qui puoi aggiungere la logica per procedere con la compilazione
            return true
          }
          return false
        })
        .catch(function (err) {
          if (err !== "Questionario duplicato" && err !== "Limite raggiunto") {
            console.log(err)
            self.errorAlert("Errore durante le verifiche del questionario.")
          }
          return false
        })
    },
    submitForm(form) {
      const self = this;

      var formData = {
        response: JSON.stringify(form.template),
        survey_id: form.hashid,
        user_id: this.selectedUser ? this.selectedUser.hashid : undefined,
        pseudonimo: this.selectedPseudonimo
          ? this.selectedPseudonimo
          : undefined,
      };

      supportService
        .storeFilledSurvey(formData)
        .then(function (res) {
          self.complete = true;
          self.filledSurveyHashid = res.data.data.hashid;
          self.esito = res.data.data;
          window.scrollTo(0, 0);
          self.successAlert("Questionario inviato correttamente.");
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile inviare il questionario");
          console.log(err);
        });
    },
    downloadPatientVersion() {
      var self = this;
      var fileName =
        "patient_" +
        self.survey.name +
        "_" +
        self.survey.activity.name +
        ".pdf";
      if (this.selectedUser && this.selectedUser.anagrafica.cf) {
        fileName = this.selectedUser.anagrafica.cf + "_patient_version.pdf";
      }
      supportService
        .downloadFilledSurveyPatientVersion(this.filledSurveyHashid)
        .then(function (response) {
          let blob = new Blob([response.data]);
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
        })
        .catch(function (err) {
          console.log(err);
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare i report");
        });
    },
    fetchUser(hashid = null) {
      var userHashid = this.$route.query.user;
      if (hashid !== null) {
        var userHashid = hashid;
      }
      const self = this;
      cittadinoService
        .getUserById(userHashid)
        .then(function (res) {
          self.selectedUser = res.data.data;
          self.loading = false;
        })
        .catch(function (err) {
          console.log(err);
          self.loading = false;
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare le informazioni.");
        });
    },
    handleEditDone() {
      this.fetchUser(this.selectedUser.hashid);
      this.$bvModal.hide("anagraphic_editor");
    },
    handleSubmitCancellation() {
      this.pendingSurvey = null;
      this.reloadKey += 1;
      this.resetComponent();
    },
    resetComponent() {
      this.reloadKey += 1;
      this.consent = false;
      this.proceed = false;
      this.selectedUser = null;
      this.selectedPseudonimo = null;
      this.pseudonimo = null;
      this.needToSelectUser = this.survey.type === 2;
      this.needToSelectPseudo = this.survey.type === 1;
      this.filledSurveyFromAnotherCompanyInfo = {
        denominazione: null,
        data: null,
      };
    },
  },
  created() {
    if (this.$route.params.quest) {
      this.hash = this.$route.params.quest;
      this.fetchData();
    }
    if (this.$route.query.user) {
      this.loading = true;
      this.fetchUser();
    }
  },
};
</script>
