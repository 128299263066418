<template>
  <b-container v-if="abilitata === true">
    <b-row class="justify-content-start">
      <b-col class="col-12 col-md-7 col-lg-8 col-xl-6">
        <b-form-group
          label="Cerca etichetta di consegna Piano Terapeutico"
          label-for="cf"
          class="text-left fs-6 text-font-grey mx-auto"
        >
          <b-form-input
            class="my-3 shadow_6 text-uppercase"
            name="deliverylabel"
            type="text"
            placeholder="prot..."
            minlength="3"
            v-model="params.label"
            :state="params.label == null ? null : params.label.length > 3"
            @input="nullLabel"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col
        class="col-12 col-md-5 col-lg-3 col-xl-4 align-self-end my-3 text-left"
      >
        <b-button
          variant="primary text-white fw-bolder spacing_1"
          :disabled="params.label == null ? true : params.label.length < 3"
          @click="searchUser"
          >Cerca etichetta</b-button
        >
      </b-col>
    </b-row>
    <b-modal size="lg" id="ptlabel-found" centered hide-footer>
      <b-row v-if="!notFound && searchResult" class="justify-content-center">
        <b-col class="col-12 text-center my-3">
          <h5>
            <span> Trovato un utente per l'etichetta di consegna </span>
            <span v-if="params.label">
              <br />
              "<strong class="text-prim-grad-1">{{ params.label }}</strong
              >"
            </span>
            <br />
            <br />
            <span class="text-capitalize font-weight-bolder"
              >{{ searchResult.anagrafica.name }}
              {{ searchResult.anagrafica.surname }}</span
            >
          </h5>
        </b-col>
        <div v-if="searchResult.is_my_patient">
          <b-col class="col-12 d-flex justify-content-center my-3">
            <b-button
              class="mx-2"
              variant="outline-danger fw-bolder"
              @click="$bvModal.hide('ptlabel-found')"
              >Annulla
            </b-button>
            <b-button
              class="mx-2"
              variant="primary text-white fw-bolder spacing_1"
              @click="sendInvite"
              >{{ btnText }}
            </b-button>
          </b-col>
        </div>
        <div v-else>
          <template v-if="!canProceed">
            <OtpValidation
              :user="searchResult"
              @otp-validated="handleOtpValidation($event)"
              class="text-center"
            >
              <template v-slot:instruction>
                <h6 class="text-justify p-3">
                  Per accedere alla scheda di questo paziente è necessario
                  ottenere il suo consenso.
                  <br />
                  Clicca su "invia codice di verifica" per inviare il codice OTP
                  di accesso via SMS al numero del paziente.
                </h6>
              </template>
            </OtpValidation>
            <div class="text-center">
              <p class="my-2">oppure</p>
              <b-button
                class="mx-3"
                variant="outline-info  fw-bolder"
                @click="$bvModal.hide('ptlabel-found')"
                >Annulla e torna indietro</b-button
              >
            </div>
          </template>
          <b-col v-else class="col-12 d-flex justify-content-center my-3">
            <b-button
              class="mx-2"
              variant="outline-danger fw-bolder"
              @click="$bvModal.hide('ptlabel-found')"
              >Annulla
            </b-button>
            <b-button
              class="mx-2"
              variant="primary text-white fw-bolder spacing_1"
              @click="sendInvite"
              >{{ btnText }}
            </b-button>
          </b-col>
        </div>
      </b-row>
      <b-row v-else class="col-12 text-center">
        <h5 class="w-100 text-center">La ricerca non ha prodotto risultati.</h5>
        <b-col class="col-12 d-flex justify-content-center my-3">
          <b-button
            class="mx-2"
            variant="outline-danger fw-bolder"
            @click="$bvModal.hide('ptlabel-found')"
            >Indietro
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </b-container>
  <div v-else-if="abilitata === null">
    <b-spinner></b-spinner>
  </div>
</template>
<script>
import { aslService, companyService } from "@/_services";
import OtpValidation from "@/components/utilities/OtpValidation.vue";
export default {
  name: "SearchUserByDeliveryLabel",
  props: ["type", "fullresponse", "custombtn"],
  components: { OtpValidation },
  data() {
    return {
      params: {
        label: null,
      },
      searchResult: null,
      notFound: false,
      canProceed: false,
      abilitata: null,
    };
  },
  computed: {
    btnText() {
      if (this.custombtn) {
        return this.custombtn;
      }
      if (this.fullresponse) {
        return "Seleziona paziente";
      }
      return "Vai a scheda paziente";
    },
  },
  methods: {
    searchUser() {
      this.notFound = false;
      this.searchResult = null;
      var self = this;
      aslService
        .searchUserByDeliveryLabel(this.params)
        .then(function (res) {
          console.log(res.data.data);
          self.searchResult = res.data.data;
          self.$bvModal.show("ptlabel-found");
        })
        .catch(function (err) {
          self.$bvModal.show("ptlabel-found");
          self.notFound = true;
        });
    },
    sendInvite() {
      if (this.searchResult) {
        this.$emit("invite-user", this.searchResult.hashid);
        this.$emit("select-user", this.searchResult);
      } else {
        this.$emit("arruola-user", this.params);
      }
      this.$bvModal.hide("ptlabel-found");
    },
    nullLabel() {
      if (this.params.label.length == 0) {
        this.params.label = null;
      }
    },
    handleOtpValidation(isValid) {
      this.canProceed = isValid;
      if (isValid) {
        const self = this;
        companyService
          .setAssistito(this.searchResult.hashid)
          .then(function (res) {
            self.sendInvite();
          })
          .catch(function (res) {
            self.sendInvite();
          });

        // this.sendInvite();
      }
    },
    verifyService() {
      const self = this;
      aslService
        .isCompanyPicEnabled()
        .then(function (res) {
          self.abilitata = res.data.data;
        })
        .catch(function (err) {
          self.abilitata = false;
        });
    },
  },
  created() {
    this.verifyService();
  },
};
</script>
