<template>
  <div v-if="response" class="my-2">
    <span class="primary fw-bolder"> Caricamento completato</span>
    <!-- <span class="text-danger fw-bolder point ml-1" @click="refreshAll"
      >Rimuovi
    </span> -->
  </div>
  <div class="d-flex align-content-center justify-content-between flex-wrap my-2" v-else>
    <span v-if="upError" class="text-danger"><strong>File non valido o di dimensioni eccessive (max8Mb)</strong></span>
    <b-form-file v-model="form.file" accept="image/*,.pdf" ref="file-up" placeholder="seleziona file..." class="
        text-primary
        custom-file-input
        mb-3 mb-md-1
        shadow_6
        border-transparent
        col-12 col-md-8
      "></b-form-file>
    <b-button @click="doUpload" :disabled="form.file == null" class="col-12 col-md-3 my-2" size="sm"
      variant="primary primary_gradient text-white fw-bolder spacing_1 rounded-pill my-1">
      <span v-if="!loading">Carica</span>
      <v-spinner v-else></v-spinner>
      </b-button>
  </div>
</template>
<script>
import { reservationService } from "@/_services";
export default {
  name: "RefertoUploader",
  props: ["dest"],
  data() {
    return {
      form: {
        file: null,
        destination: "referto",
        name: null,
        has_owner: false,
        encrypt: true,
      },
      response: null,
      upError: false,
      loading: false,
    };
  },
  methods: {
    doUpload() {
      this.upError = false;
      //console.log("form", this.form);
      var form = new FormData();
      form.append("file", this.form.file);
      form.append("destination", this.form.destination);
      form.append("name", this.form.file.name);
      form.append("has_owner", this.form.has_owner);
      form.append("encrypt", this.form.encrypt);
      var self = this;
      reservationService
        .uploadFile(form)
        .then(function (res) {
          //console.log(res);
          self.response = res.data.data;
          self.$emit("upload-result", res.data.data);
        })
        .catch(function (err) {
          console.log(err);
          self.upError = true;
        });
    },
    handleFile(e) {
      //console.log(this.file);
      //console.log(e.target.files);
    },
    refreshAll() {
      this.form.file = null;
      this.form.destination = "order";
      if (this.dest) {
        this.form.destination = this.dest;
      }
      this.form.name = null;
      this.form.has_owner = false;
      this.form.encrypt = true;
      this.response = null;
      this.$emit("remove-result");
    },
  },
  created() {
    if (this.dest) {
      this.form.destination = this.dest;
    }
    // //console.log(this.form.destination);
  },
};
</script>
<style lang="scss">
.custom-file-input:lang(en)~.custom-file-label::after,
.custom-file-input:lang(it)~.custom-file-label::after,
.custom-file-input~.custom-file-label[data-browse]::after {
  content: "Scegli" !important;
  color: $primary;
  border-radius: 0 !important;
}

.custom-file-input,
.custom-file-label {
  opacity: 1 !important;
  color: $primary !important;
  border-color: transparent !important;
  border-radius: 0 !important;
}
</style>