<template>
  <div class="col-12 py-4">
    <h6
      class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
    >
      Questionari
    </h6>
    <p class="text-justify text-medium-grey fw-bolder">
      Clicca sull'icona "occhio" per vedere il template del questionario,
      sull'icona "raccolta" per vedere l'elenco dei relativi questionari
      compilati, oppure sull'icona "cartella" per somministrare il questionario.
      Lo stato di attivazione di ciascun questionario determina la sua
      disponibilità per gli utenti (nel caso di connessa) e la possibilità di
      essere somministrato (per tutti gli altri). Per creare un nuovo
      questionario clicca su "nuovo questionario". Nota: potrai formulare nuovi
      questionari solo per le attività da te create e per il servizio
      "connessa".
    </p>
    <div :class="$root.isMobile ? 'col-12' : 'd-flex justify-content-between'">
      <b-button
        v-if="user.permessi.includes('gestire attività e questionari')"
        variant="outline-primary shadow_6 rounded-pill"
        class="ml-0 mr-auto fw-bolder"
        @click="infoAlert('Funzionalità disabilitata per questa utenza')"
      >
        <b-icon icon="plus"></b-icon> nuovo questionario
      </b-button>
      <!-- <b-button v-if="user.permessi.includes('gestire attività e questionari')"
        variant="outline-primary shadow_6 rounded-pill" class="ml-0 mr-auto fw-bolder"
        @click="$router.push({ name: 'crea questionario' })">
        <b-icon icon="plus"></b-icon> nuovo questionario
      </b-button> -->

      <b-input-group
        size="sm"
        class="mt-2 col-lg-4 col-sm-6 ml-auto mr-0 shadow_6 rounded-pill border border-primary"
      >
        <b-form-input
          class="align-items-center align-content-center"
          v-model="filterSetted.search"
          type="search"
          placeholder="Cerca questionario.."
        >
        </b-form-input>

        <b-input-group-append>
          <span class="mx-2">
            <b-icon icon="search"></b-icon>
          </span>
        </b-input-group-append>
      </b-input-group>
    </div>
    <div
      :class="$root.isMobile ? '' : 'd-flex justify-content-between '"
      class="mt-4 mb-2 align-content-center"
    >
      <div>
        <b-form-group size="sm" class="fw-bolder text-left">
          <b-form-select
            class="align-items-center align-content-center shadow_6 border border-info text-info"
            name="vedi"
            v-model="filterSetted.take"
            :options="takeOptions"
          >
          </b-form-select>
        </b-form-group>
        <span class="text-info fw-bolder mr-1" v-if="total > 0"
          >{{ fromPage }}-{{ toPage }} di
        </span>
        <span class="text-info fw-bolder"> {{ total }}</span>
      </div>
      <b-form-group size="sm" class="fw-bolder text-left">
        <b-form-select
          name="type"
          class="align-items-center align-content-center shadow_6 border border-info text-info"
          v-model="filterSetted.activity"
          required
        >
          <b-form-select-option :value="null"
            >tutte le attività</b-form-select-option
          >
          <b-form-select-option
            v-for="(activity, ind) in activities"
            :key="ind"
            :value="activity.name"
            >{{ activity.name }}</b-form-select-option
          >
        </b-form-select>
      </b-form-group>
      <b-form-group size="sm" class="fw-bolder text-left">
        <b-form-select
          name="type"
          class="align-items-center align-content-center shadow_6 border border-info text-info"
          v-model="filterSetted.position_ref"
          required
        >
          <b-form-select-option :value="null"
            >tutti i servizi</b-form-select-option
          >
          <b-form-select-option :value="1"
            >Facile Prestazioni</b-form-select-option
          >
          <b-form-select-option :value="2">Connessa</b-form-select-option>
          <b-form-select-option :value="3">Con Te</b-form-select-option>
        </b-form-select>
      </b-form-group>
    </div>

    <div class="mt-3">
      <b-table
        borderless
        hover
        responsive
        table-variant="white text-info"
        :fields="fields"
        :items="surveys"
        :small="$root.isMobile"
      >
        <template #cell(name)="row">
          <p class="mb-0 text-secondary text-left text-capitalize fw-bolder">
            {{ row.item.name }}
          </p>
        </template>

        <template #cell(description)="row">
          <p class="mb-0 text-secondary text-left fw-bolder">
            {{ row.item.description }}
          </p>
        </template>

        <template #cell(attivita)="row">
          <p class="mb-0 text-secondary text-left fw-bolder text-capitalize">
            {{ row.item.activity.name }}
          </p>
        </template>

        <template #cell(active)="row">
          <!-- <b-icon v-if="row.item.created_by == null && row.item.active != true" icon="circle-fill" variant="danger">
          </b-icon> -->
          <!-- <b-form-checkbox v-model="row.item.active" name="check-button" :state="row.item.active == 1" :value="1"
            :unchecked-value="0" switch @change="selectChangeStatus(row)"
            v-if="user.permessi.includes('gestire attività e questionari')">
          </b-form-checkbox> -->
          <b-icon
            icon="circle-fill"
            :variant="row.item.active == 1 ? 'success' : 'danger'"
          ></b-icon>
          <!-- <b-icon v-if="row.item.created_by == null && row.item.active != true" icon="circle-fill" variant="danger">
          </b-icon>
          <b-form-checkbox v-model="row.item.active" name="check-button" :state="row.item.active == 1" :value="1"
            :unchecked-value="0" switch @change="selectChangeStatus(row)"
            v-else-if="user.permessi.includes('gestire attività e questionari')">
          </b-form-checkbox>
          <b-icon v-else icon="circle-fill" :variant="row.item.active == 1 ? 'success' : 'danger'"></b-icon> -->
        </template>
        <template #cell(filled)="row">
          <span class="text-secondary text-justify fw-bolder">{{
            row.item.filled
          }}</span>
        </template>
        <template #cell(azioni)="row">
          <b-row>
            <b-col class="px-0">
              <b-button
                v-if="
                  user.permessi.includes('somministrare questionari') &&
                  row.item.activity.prof2 != 1
                "
                variant="info info_gradient fw-bolder rounded-pill px-3"
                size="sm"
                @click="selectThis(row)"
                v-b-tooltip.hover
                title="Visualizza"
              >
                <b-icon icon="eye-fill"></b-icon>
              </b-button>
            </b-col>
            <b-col class="px-0">
              <b-button
                v-if="
                  user.permessi.includes('gestire attività e questionari') &&
                  row.item.activity.prof2 != 1
                "
                variant="secondary secondary_gradient fw-bolder rounded-pill px-3"
                size="sm"
                @click="
                  $router.push({
                    name: 'report per questionario',
                    params: { quest: row.item.hashid },
                  })
                "
                v-b-tooltip.hover
                title="Report"
              >
                <b-icon icon="collection-fill"></b-icon>
              </b-button>
              <b-button
                v-else-if="
                  user.permessi.includes('gestire attività e questionari') &&
                  row.item.activity.prof2 === 1
                "
                variant="secondary secondary_gradient fw-bolder rounded-pill px-3"
                size="sm"
                @click="
                  $router.push({
                    name: 'progetto prof2',
                    query: { activity: row.item.activity.name },
                  })
                "
                v-b-tooltip.hover
                title="Report"
              >
                <b-icon icon="collection-fill"></b-icon>
              </b-button>
            </b-col>
            <b-col class="px-0">
              <template
                v-if="user.permessi.includes('somministrare questionari')"
              >
                <b-button
                  v-if="row.item.activity.prof2 == 1"
                  variant="primary primary_gradient fw-bolder rounded-pill px-3"
                  size="sm"
                  @click="
                    $router.push({
                      name: 'progetto prof2',
                      query: { activity: row.item.activity.name },
                    })
                  "
                  v-b-tooltip.hover
                  title="Nuova terapia"
                  :disabled="row.item.active === 0"
                >
                  <b-icon icon="clipboard-plus"></b-icon>
                </b-button>

                <b-button
                  v-else
                  variant="primary primary_gradient fw-bolder rounded-pill px-3"
                  size="sm"
                  @click="
                    $router.push({
                      name: 'somministra questionario',
                      params: { quest: row.item.hashid },
                    })
                  "
                  v-b-tooltip.hover
                  title="Somministra"
                  :disabled="row.item.active === 0"
                >
                  <b-icon icon="clipboard-plus"></b-icon>
                </b-button>
              </template>
            </b-col>
            <b-col class="px-0">
              <b-button
                v-if="
                  row.item.created_by &&
                  user.permessi.includes('gestire attività e questionari')
                "
                variant="secondary fw-bolder rounded-pill px-3"
                size="sm"
                @click="
                  $router.push({
                    name: 'modifica questionario',
                    params: { quest: row.item.hashid },
                  })
                "
                v-b-tooltip.hover
                title="Modifica"
              >
                <b-icon icon="pencil-square"></b-icon>
              </b-button>
            </b-col>
          </b-row>

          <!-- <router-link
            class="
              info_gradient
              btn btn-info btn-sm
              px-3
              rounded-pill
              fw-bolder
            "
            :to="{ name: 'questionari', params: { quest: row.item.hashid } }"
          >
            Dettaglio</router-link
          > -->
        </template>
      </b-table>

      <p v-if="surveys && surveys.length == 0">Nessun questionario qui</p>
      <b-pagination
        v-model="filterSetted.page"
        pills
        :total-rows="total"
        :per-page="10"
        align="center"
        :size="$root.isMobile ? 'sm' : 'lg'"
      ></b-pagination>
      <b-modal ref="modal-showsurvey" :title="modalTitle" size="xl" hide-footer>
        <SurveyShower
          v-if="selectedRow && selectedRow.item"
          :survey="selectedRow.item"
          class="px-3"
        />
      </b-modal>

      <b-modal
        ref="modal-statusconfirm"
        title="Conferma operazione"
        size="lg"
        hide-footer
        @hidden="cancelOp"
      >
        <div v-if="selectedItem !== null">
          <h5 class="fw-bolder">
            Sei sicuro di voler
            {{
              surveys[selectedItem].active === 1 ? "attivare " : "disattivare "
            }}
            "{{ surveys[selectedItem].name }}" ?
          </h5>
          <p v-if="surveys[selectedItem].active === 1">
            Attivando il questionario sarà disponibile per la compilazione.
          </p>
          <p v-else>
            Disattivando il questionario non sarà più disponibile per la
            compilazione nell'area Con Te. <br />
            Se il questionario è stato associato ad una prestazione Facile,
            rimarrà attivo solo per le prenotazioni della stessa.
          </p>
          <div class="my-4 text-center">
            <b-button variant="outline-danger m-1" @click="cancelOp"
              >No, annulla</b-button
            >
            <b-button variant="info info_gradient m-1" @click="changeStatus"
              >Sì, procedi</b-button
            >
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import { supportService } from "@/_services";
import { mapState, mapActions } from "vuex";
import SurveyShower from "@/components/attivita/SurveyShower.vue";
export default {
  name: "HomeQuestionari",
  components: { SurveyShower },
  data() {
    return {
      filterSetted: {
        search: null,
        position_ref: null,
        page: 1,
        take: 10,
        activity: null,
      },
      surveys: null,
      fields: [
        { key: "name", label: "Nome", class: "text-left" },
        { key: "description", label: "Descrizione", class: "text-left" },
        { key: "attivita", label: "Attività", class: "text-left" },
        { key: "active", label: "Stato" },
        { key: "filled", label: "Somministrati" },
        { key: "azioni", label: "" },
      ],
      takeOptions: [10, 25, 50, 100],
      activities: null,
      total: 0,
      fromPage: 0,
      toPage: 0,
      selectedRow: null,
      modalTitle: null,
      selectedItem: null,
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
    ...mapState("utente", ["status", "user"]),
  },
  watch: {
    filterSetted: {
      handler(prev, post) {
        // //console.log("changed prev", prev.status, post);
        // //console.log("changed post", post.status);
        this.fetchData();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    fetchData() {
      var self = this;
      supportService
        .getSurveys(this.filterSetted)
        .then(function (response) {
          self.surveys = response.data.data.data;
          self.filterSetted.page = response.data.data.current_page;
          self.total = response.data.data.total;
          self.fromPage = response.data.data.from;
          self.toPage = response.data.data.to;
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare le attività");
          console.log(err);
        });
    },
    fetchAttivita() {
      var self = this;
      supportService
        .getActivities(this.filterSetted)
        .then(function (res) {
          self.activities = res.data.data;
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare le attività");
          console.log(err);
        });
    },
    selectThis(row) {
      this.selectedRow = row;
      this.modalTitle = row.item.name;
      this.$refs["modal-showsurvey"].show();
    },
    changeStatus() {
      var formData = {
        hash: this.surveys[this.selectedItem].hashid,
        form: {
          active: this.surveys[this.selectedItem].active,
        },
      };
      var self = this;
      supportService
        .companyUpdateSurveyActive(formData)
        .then(function (res) {
          window.scrollTo(0, 0);
          self.successAlert("Stato questionario aggiornato con successo!");
          // self.fetchData();
          self.$refs["modal-statusconfirm"].hide();
        })
        .catch(function (err) {
          console.log(err);
          self.$refs["modal-statusconfirm"].hide();
          window.scrollTo(0, 0);
          self.errorAlert(
            "Non è stato possibile aggiornare lo stato questionario"
          );
          self.fetchData();
        });
    },
    selectChangeStatus(row) {
      this.selectedItem = row.index;
      this.$refs["modal-statusconfirm"].show();
    },
    cancelOp() {
      this.$refs["modal-statusconfirm"].hide();
      this.fetchData();
    },
  },
  created() {
    this.fetchData();
    this.fetchAttivita();
  },
};
</script>
