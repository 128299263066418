<template>
  <b-container>
    <div class="my-3">
      <h6 class="
          text-prim-grad-1
          fw-bolder
          text-left
          pb-1
          border-bottom border-line-grey
        ">
        Rilevazioni
      </h6>
      <b-row class="
          my-2
          col-12
          px-0
          align-items-center align-content-center
          flex-wrap
          my-2
        ">
        <b-col class="col-12 col-md-6 col-lg-3 my-2" v-for="(exam, ind) in measurements.misurazioni[0]" :key="ind">
          <div class="
              general_border
              shadow_6
              pt-4
              pb-3
              px-2
              point
              border border-light-panel
              bg-white
              col-12
            " @click="selectMeasurement(exam)">
            <p class="mb-0 fs-5 pt-1 fw-bolder">
              {{ exam.exam.name }}
            </p>
            <h4 class="mb-0 pt-1" v-if="exam.value != null">
              <strong class="">{{ exam.value * 1 }}</strong>
              {{ exam.exam.um && exam.exam.um }}
            </h4>
            <br v-else />
            <p class="mb-0 mt-1 fs-5 text-sec-2 text-capitalize">
              {{ formatData(exam.created_at) }}
            </p>
          </div>
        </b-col>
        <b-col class="text-left col-md-3">
          <div class="
              align-items-center align-content-center
              pt-4
              pb-3
              px-2
              point
              bg-white
            " @click="openModalNew(false, false)">
            <p>
              <b-iconstack font-scale="3.5" class="shadow_7 rounded-circle">
                <b-icon class="shadow_7" stacked icon="circle-fill" variant="secondary"></b-icon>
                <b-icon stacked icon="plus" scale="0.5" variant="white"></b-icon>
              </b-iconstack>
            </p>
          </div>
        </b-col>
      </b-row>
    </div>
    <div class="my-3 pt-1">
      <h6 class="
          text-prim-grad-1
          fw-bolder
          text-left
          pb-1
          border-bottom border-line-grey
        ">
        Esami
      </h6>
      <b-row align-v="stretch" class="my-2 col-12 px-0 align-items-center align-content-center">
        <b-col class="col-12 col-md-6 col-lg-3 my-2" v-for="(exam, ind) in measurements.misurazioni[1]" :key="ind"
          @click="selectMeasurement(exam)">
          <div class="
              general_border
              shadow_6
              pt-4
              pb-3
              px-2
              point
              border border-light-panel
              bg-white
              col-12
              h-100
            ">
            <p class="mb-0 fs-5 pt-1 fw-bolder">
              {{ exam.exam.name }}
            </p>
            <h4 class="mb-0 pt-1" v-if="exam.value != null">
              <strong class="">{{ exam.value * 1 }}</strong>
              {{ exam.exam.um && exam.exam.um }}
            </h4>
            <br v-else />
            <p class="mb-0 mt-1 fs-5 text-sec-2 text-capitalize">
              {{ formatData(exam.created_at) }}
            </p>
          </div>
        </b-col>
        <b-col class="text-left col-md-3 my-2">
          <div class="
              align-items-center align-content-center
              pt-4
              pb-3
              px-2
              point
              bg-white
              h-100
            " @click="openModalNew(true, false)">
            <p>
              <b-iconstack font-scale="3.5" class="shadow_7 rounded-circle">
                <b-icon class="shadow_7" stacked icon="circle-fill" variant="secondary"></b-icon>
                <b-icon stacked icon="plus" scale="0.5" variant="white"></b-icon>
              </b-iconstack>
            </p>
          </div>
        </b-col>
      </b-row>
    </div>
    <div class="my-3 pt-1">
      <h6 class="
          text-prim-grad-1
          fw-bolder
          text-left
          pb-1
          border-bottom border-line-grey
        ">
        Referti
      </h6>
      <b-row align-v="stretch" class="my-2 col-12 px-0 align-items-center align-content-center">
        <b-col class="col-12 col-md-6 col-lg-4 my-2" v-for="(exam, ind) in measurements.referti" :key="ind"
          @click="selectMeasurement(exam)">
          <div class="
              general_border
              py-1
              px-2
              point
              border border-primary
              bg-white
              col-12
              h-100
              d-flex
              flex-column
            ">
            <p class="mb-0 fs-5 pt-1 fw-bolder">
              {{ exam.exam.name }}
              <b-icon font-scale="1.5" class="ml-2" icon="file-earmark-fill"></b-icon>
              <!-- <b-icon font-scale="1.5" class="ml-2" icon="download"></b-icon> -->
            </p>
            <p class="mb-2 mt-auto mt-1 fs-5 text-sec-2 text-capitalize">
              {{ formatData(exam.created_at) }}
            </p>
          </div>
        </b-col>
        <b-col class="text-left col-md-3">
          <div class="
              align-items-center align-content-center
              pt-4
              pb-3
              px-2
              point
              bg-white
            " @click="openModalNew(true, true)">
            <p>
              <b-iconstack font-scale="3.5" class="shadow_7 rounded-circle">
                <b-icon class="shadow_7" stacked icon="circle-fill" variant="secondary"></b-icon>
                <b-icon stacked icon="plus" scale="0.5" variant="white"></b-icon>
              </b-iconstack>
            </p>
          </div>
        </b-col>
      </b-row>
    </div>
    <b-modal size="xl" id="new-measurement" hide-footer :title="modalTitle">
      <b-form @submit.prevent="onSubmit">
        <!-- <div class="col-12 d-flex flex-wrap justify-content-center"> -->
        <transition-group tag="div" name="list" mode="out-in" appear class="
            col-12
            pl-0
            d-flex
            flex-wrap
            justify-content-center
            align-items-stretch
          ">
          <div class="my-1 col-12 col-md-3 px-0 px-md-2" v-for="(exam, ind) in exams" :key="ind">
            <b-button block v-if="
              !selectedExam ||
              (selectedExam && selectedExam.hashid == exam.hashid)
            " :variant="
  selectedExam && selectedExam.hashid == exam.hashid
    ? 'secondary'
    : 'outline-secondary'
" class="
                m-2
                text-capitalize
                font-weight-bold
                py-4
                shadow_6
                h-100
                min__h__ril
              " @click="selectedExam = exam">{{ exam.name }}</b-button>
          </div>
          <div v-if="selectedExam" class="text-left fs-6 text-font-grey mx-auto col-12" key="forminputs">
            <b-form-group v-if="!selectedExam.has_attachment" key="noninstr" :label="selectedExam.name + '*'"
              class="text-left text-capitalize d-flex text-secondary">
              <b-input-group :append="selectedExam.um ? selectedExam.um : undefined"
                class="mb-2 mr-sm-2 mb-sm-0 text-lowercase">
                <b-form-input class="" v-model="form.value" type="number" step="0.001" required autofocus
                  placeholder="Inserisci valore*"></b-form-input>
              </b-input-group>
            </b-form-group>
            <RefertoUploader v-else v-on:upload-result="handleUploadResult($event)" />
            <b-form-group v-if="form.own && user.type === 0" label="" :description="
              '**se selezioni questa opzione soltanto tu potrai vedere ' +
              privacyDescription
            " label-for="private" class="
                text-left
                font-weight-bolder
                text-secondary
                mx-auto
                col-12
                px-0 px-md-2
                my-4
              ">
              <b-form-checkbox id="private" v-model="form.private" name="private" :value="true"
                :unchecked-value="false">
                visibile solo a me**
              </b-form-checkbox>
            </b-form-group>
            <b-form-group label="Annotazioni" label-for="details"
              class="text-left fs-6 text-font-grey mx-auto col-12 px-0 px-md-2">
              <b-form-textarea class="my-3 shadow_6" name="details" type="text" v-model="form.details">
              </b-form-textarea>
            </b-form-group>
            <div class="mx-auto pb-4 mb-4 text-right col-12 px-0 px-md-2">
              <!-- <p class="text-left pl-4">*Campi obbligatori</p> -->
              <b-button type="button" variant="outline-danger fw-bolder m-2" @click.prevent="handleAnnulla">Indietro
              </b-button>
              <b-button type="submit" :disabled="form.resource_id == null && form.value == null"
                variant="primary text-white primary_gradient fw-bolder m-2">Salva
              </b-button>
            </div>
          </div>
          <!-- </div> -->
        </transition-group>
      </b-form>
    </b-modal>
    <b-modal scrollable class="text-center" size="xl" id="detail-measurement" hide-footer centered>
      <template class="text-center" #modal-title>
        <h4 v-if="selectedMeasurement && selectedMeasurement.exam">
          {{ selectedMeasurement.exam.name }}
        </h4>
        <h4 v-else>Storico</h4>
      </template>
      <div class="text-center" v-if="selectedMeasurement && selectedMeasurement.exam">
        <div v-if="examHistory">
          <div class="my-2 py-2" v-for="(hist, ind) in examHistory" :key="ind">
            <p class="mb-0 mt-1 fs-5 text-sec-2 text-capitalize">
              {{ formatData(hist.created_at) }}
            </p>
            <b-row cols="3" class="mb-0 pt-1" v-if="hist.value != null">
              <b-col></b-col>
              <b-col class="text-center">
                <span style="font-size: 1.5rem">
                  <strong class="">{{ hist.value * 1 }}</strong>
                  {{ hist.exam.um && hist.exam.um }}
                </span>
              </b-col>
              <b-col class="text-left">
                <span class="mx-2 mr-4 point" @click="openEditModal(hist)">
                  <b-iconstack font-scale="1.7">
                    <b-icon stacked icon="pencil-fill"></b-icon>
                  </b-iconstack>
                </span>
                <span class="mx-2 point" @click="openDelModal(hist)">
                  <b-iconstack font-scale="1.7">
                    <b-icon stacked icon="x-circle-fill" variant="danger"></b-icon>
                  </b-iconstack>
                </span>
              </b-col>
            </b-row>
            <b-row cols="3" class="align-items-center" v-else-if="hist.resource">
              <!-- <b-row cols="3" class="align-items-center" v-else-if="hist.resource && hist.owner"> -->
              <b-col></b-col>
              <b-col>
                <b-button variant="secondary secondary_gradient" @click="downloadResource(hist.resource)">Scarica
                  <b-icon icon="download"></b-icon>
                </b-button>
              </b-col>

              <b-col class="text-left">
                <!-- <span class="mx-2 mr-4 point" @click="openEditModal(hist)">
                  <b-iconstack font-scale="1.5">
                    <b-icon stacked icon="pencil-fill"></b-icon>
                  </b-iconstack>
                </span> -->
                <span class="mx-2 point" @click="openDelModal(hist)">
                  <b-iconstack font-scale="2">
                    <b-icon stacked icon="x-circle-fill" variant="danger"></b-icon>
                  </b-iconstack>
                </span>
              </b-col>
            </b-row>
            <br v-else />
            <p class="mb-0 mt-1 fs-5 text-font-grey text-capitalize" v-if="hist.details">
              Note: {{ hist.details }}
            </p>
          </div>
          <div v-if="examHistory.length == 1" class="my-2 py-2">
            <p class="text-font-grey">Non ci sono altre rilevazioni</p>
          </div>
        </div>
        <b-img v-if="picLink" ref="allegato" :src="picLink" no-body fluid-grow width="100%" height="100%" />
        <p>
          <b-iconstack font-scale="3.5" class="shadow_7 rounded-circle point" @click="newFromDetail">
            <b-icon class="shadow_7" stacked icon="circle-fill" variant="secondary"></b-icon>
            <b-icon stacked icon="plus" scale="0.5" variant="white"></b-icon>
          </b-iconstack>
        </p>
      </div>
    </b-modal>
    <b-modal title="Modifica Valore" class="text-center" size="lg" id="edit-measurement" ok-title="Salva modifiche"
      ok-variant="primary primary_gradient" cancel-title="Annulla" cancel-variant="outline-danger"
      footer-class="justify-content-start" footer-border-variant="white" @ok="updateMeasurement"
      @cancel="$bvModal.hide('edit-measurement')" centered>
      <div v-if="currentMeasurement" class="font-weight-bolder">
        <b-form-group v-if="!currentMeasurement.exam.has_attachment" key="noninstr"
          :label="currentMeasurement.exam.name + '*'" class="text-left text-capitalize d-flex text-secondary">
          <b-input-group :append="
            currentMeasurement.exam.um
              ? currentMeasurement.exam.um
              : undefined
          " class="mb-2 mr-sm-2 mb-sm-0 text-lowercase">
            <b-form-input class="" v-model="valueToUpdate" type="number" step="0.001" required autofocus
              placeholder="Inserisci valore*"></b-form-input>
          </b-input-group>
        </b-form-group>

        <b-form-group v-if="currentMeasurement.is_mine && user.type === 0" label="" :description="
          '**se selezioni questa opzione soltanto tu potrai vedere ' +
          privacyDescription
        " label-for="private" class="
            text-left
            font-weight-bolder
            text-secondary
            mx-auto
            col-12
            px-0 px-md-2
            my-4
          ">
          <b-form-checkbox id="private" v-model="privacyValueToUpdate" name="private" :value="1" :unchecked-value="0">
            visibile solo a me**
          </b-form-checkbox>
        </b-form-group>
        <b-form-group label="Annotazioni" label-for="details"
          class="text-left fs-6 text-font-grey mx-auto col-12 px-0 px-md-2">
          <b-form-textarea class="my-3 shadow_6" name="details" type="text" v-model="detailValueToUpdate">
          </b-form-textarea>
        </b-form-group>
      </div>
    </b-modal>
    <b-modal title="Conferma" class="text-center" size="lg" id="del-measurement" ok-title="Sì, elimina"
      ok-variant="outline-danger btn-sm" cancel-title="No, annulla" cancel-variant="secondary btn-sm"
      footer-border-variant="white" @ok="removeMeasurement" @cancel="$bvModal.hide('del-measurement')" centered>
      <h4 v-if="currentMeasurement">
        Vuoi eliminare questo valore di
        <span class="text-lowercase">{{ currentMeasurement.exam.name }}</span> ?
      </h4>
    </b-modal>
  </b-container>
</template>
<script>
import * as moment from "moment/moment";
import { cittadinoService, utilityService } from "@/_services";
import { mapState, mapActions } from "vuex";
import RefertoUploader from "@/components/utilities/RefertoUploader.vue";
export default {
  name: "RilevazioniComponent",
  props: ["subject"],
  components: {
    RefertoUploader,
  },
  data() {
    return {
      measurements: {
        misurazioni: [[], []],
        referti: null,
      },
      filters: {
        instrumental: false,
        referto: false,
      },
      exams: null,
      selectedExam: null,
      selectedMeasurement: null,
      form: {
        exam_id: null,
        resource_id: null,
        value: null,
        details: null,
        own: true,
        private: false,
        subjectable_id: null,
        subjectable_type: null,
      },
      examHistory: null,
      picLink: null,
      currentMeasurement: null,
      valueToUpdate: null,
      privacyValueToUpdate: null,
      detailValueToUpdate: null,
    };
  },
  watch: {
    filters: {
      handler(val) {
        //console.log(val);
        this.fetchAvailableExams();
      },
      deep: true,
    },
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
    ...mapState("utente", ["user"]),
    modalTitle() {
      if (this.filters.instrumental) {
        return this.filters.referto ? "Nuovo referto" : "Nuovo esame";
      }
      return "Nuova rilevazione";
    },
    privacyDescription() {
      if (this.filters.instrumental) {
        return this.filters.referto ? "questo referto" : "questo esame";
      }
      return "questa rilevazione";
    },
    insertType() {
      if (this.filters.instrumental) {
        return this.filters.referto ? "Referto" : "Esame";
      }
      return "Rilevazione";
    },
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    openModalNew(instrumental, referto) {
      this.filters.instrumental = instrumental;
      this.filters.referto = referto;
      this.$bvModal.show("new-measurement");
    },
    fetchMeasurements() {
      if (this.subject) {
        return this.fetchUserMeasurements();
      }
      this.fetchOwnMeasurements();
    },
    fetchOwnMeasurements() {
      var self = this;
      cittadinoService
        .getMeasurements()
        .then(function (response) {
          self.measurements = response.data.data;
        })
        .catch(function (error) {
          window.scrollTo(0, 0);
          self.errorAlert("qualcosa è andata storta");
        });
    },
    fetchUserMeasurements() {
      var self = this;
      var filters = {
        user: this.subject.hashid,
      };
      cittadinoService
        .getMeasurements(filters)
        .then(function (response) {
          self.measurements = response.data.data;
        })
        .catch(function (error) {
          window.scrollTo(0, 0);
          self.errorAlert("qualcosa è andata storta");
        });
    },
    fetchAvailableExams() {
      this.clearForm();
      var self = this;
      utilityService
        .getAvailableExams(this.filters)
        .then(function (response) {
          self.exams = response.data.data;
        })
        .catch(function (error) {
          window.scrollTo(0, 0);
          self.errorAlert("qualcosa è andata storta");
        });
    },
    handleAnnulla() {
      this.clearForm();
    },
    formatData(data) {
      return moment(data).format("DD MMMM YYYY HH:mm");
    },
    onSubmit() {
      this.form.exam_id = this.selectedExam.hashid;
      var self = this;
      cittadinoService
        .postMeasurements(this.form)
        .then(function (res) {
          self.clearForm();
          self.$bvModal.hide("new-measurement");
          self.fetchMeasurements();
          window.scrollTo(0, 0);
          self.successAlert("Misurazione inserita correttamente");
          self.$bvModal.hide("detail-measurement");
        })
        .catch(function (err) {
          self.errorAlert("Non è stato possibile inserire la misurazione");
          self.$bvModal.hide("new-measurement");
          self.$bvModal.hide("detail-measurement");
        });
    },
    clearForm() {
      this.selectedExam = null;
      this.form.resource_id = null;
      this.form.value = null;
      this.form.details = null;
      this.form.private = false;
    },
    handleUploadResult(upload) {
      this.form.resource_id = upload.hashid;
    },
    selectMeasurement(meas) {
      this.picLink = null;
      this.selectedMeasurement = meas;
      this.$bvModal.show("detail-measurement");
      this.fetchStorico();
    },
    newFromDetail() {
      let instrumental = this.selectedMeasurement.exam.instrumental == true;
      let referto = this.selectedMeasurement.exam.has_attachment == true;
      this.selectedExam = this.selectedMeasurement.exam;
      this.openModalNew(instrumental, referto);
    },
    fetchStorico() {
      if (this.subject) {
        return this.fetchUserStorico();
      }
      this.fetchOwnStorico();
    },
    fetchOwnStorico() {
      var self = this;
      cittadinoService
        .getExamHistory(this.selectedMeasurement.exam.hashid)
        .then(function (response) {
          self.examHistory = response.data.data;
        })
        .catch(function (error) {
          window.scrollTo(0, 0);
          self.errorAlert("qualcosa è andata storta");
        });
    },
    fetchUserStorico() {
      var self = this;
      var filters = {
        user: this.subject.hashid,
      };
      cittadinoService
        .getExamHistory(this.selectedMeasurement.exam.hashid, filters)
        .then(function (response) {
          self.examHistory = response.data.data;
        })
        .catch(function (error) {
          window.scrollTo(0, 0);
          self.errorAlert("qualcosa è andata storta");
        });
    },
    openDelModal(hist) {
      this.currentMeasurement = hist;
      this.$bvModal.show("del-measurement");
    },
    openEditModal(hist) {
      this.currentMeasurement = hist;
      this.valueToUpdate = hist.value * 1;
      this.privacyValueToUpdate = hist.private;
      this.detailValueToUpdate = hist.details;
      this.$bvModal.show("edit-measurement");
    },
    updateMeasurement() {
      var self = this;
      var filters = {
        value: this.valueToUpdate,
        private: this.privacyValueToUpdate,
        details: this.detailValueToUpdate,
      };
      cittadinoService
        .updateMeasurement(filters, this.currentMeasurement.hashid)
        .then(function (response) {
          self.successAlert("Misurazione aggiornata correttamente");
          self.$bvModal.hide("edit-measurement");
          self.$bvModal.hide("detail-measurement");
          self.fetchMeasurements();
        })
        .catch(function (error) {
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile aggiornare la misurazione");
          self.$bvModal.hide("edit-measurement");
          self.$bvModal.hide("detail-measurement");
          self.fetchMeasurements();
        });
    },
    removeMeasurement() {
      var self = this;
      cittadinoService
        .deleteMeasurement(this.currentMeasurement.hashid)
        .then(function (response) {
          self.successAlert("Misurazione eliminata correttamente");
          self.$bvModal.hide("del-measurement");
          self.$bvModal.hide("detail-measurement");
          self.fetchMeasurements();
        })
        .catch(function (error) {
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile eliminare la misurazione");
          self.$bvModal.hide("del-measurement");
          self.$bvModal.hide("detail-measurement");
          self.fetchMeasurements();
        });
    },
    downloadResource(resource = null) {
      if (!resource) {
        resource = this.selectedMeasurement.resource;
      }
      const fName = resource.name;
      var self = this;
      cittadinoService
        .downloadResource(resource.hashid)
        .then(function (response) {
          let blob = new Blob([response.data]);
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = fName;
          link.click();
        })
        .catch(function (err) {
          self.$bvModal.hide('detail-measurement');
          var msg = "Non è stato possibile scaricare la risorsa"
          if (err.status === 404) {
            msg = "Risorsa non trovata o non accessibile.";
          }
          console.log(err);
          window.scrollTo(0, 0);
          self.errorAlert(msg);
        });
    },
  },
  created() {
    if (this.subject) {
      this.form.subjectable_id = this.subject.hashid;
      this.form.subjectable_type = this.subject.type;
      this.form.own = false;
    }
    this.fetchAvailableExams();
    this.fetchMeasurements();
  },
  mounted() {
    this.$root.$on("bv::modal::hide", (bvEvent, modalId) => {
      // //console.log("Modal is about to be shown", bvEvent, modalId);
      this.clearForm();
    });
  },
};
</script>
<style>
.min__h__ril {
  min-height: 10vh;
}
</style>