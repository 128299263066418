<template>
  <div class="verification pt-4 mt-4">
    <div v-if="$route.params.status === 'success'">
      <h5>Registazione avvenuta con successo!</h5>
      <div class="col-10 mx-auto">
        <h6 class="
            mt-3
            text-prim-grad-1
            fw-bolder
            text-left
            pb-1
            border-bottom border-line-grey
          ">
          Benvenuto
        </h6>
        <p class="text-justify fs-5 my-4 py-3 pl-1">
          Grazie per aver confermato il tuo indirizzo email. <br />
        </p>
        <b-button type="button" variant="primary text-white primary_gradient mx-2 mt-4 fw-bolder" @click="logAndHome">
          Vai alla Homepage
        </b-button>
      </div>
    </div>
    <div v-else-if="$route.params.status === 'already'">
      <h5>Email già verificata.</h5>
      <div class="col-10 mx-auto">
        <h6 class="
            mt-3
            text-prim-grad-1
            fw-bolder
            text-left
            pb-1
            border-bottom border-line-grey
          ">
          Benvenuto
        </h6>
        <p class="text-justify fs-5 my-4 py-3 pl-1">
          Hai già confermato il tuo indirizzo email. <br />
          Se non lo hai ancora fatto, procedi con la compilazione della tua
          scheda anagrafica.
        </p>
        <b-button type="button" variant="primary text-white primary_gradient mx-2 mt-4 fw-bolder"
          @click="$router.push({ name: 'Profilo' })">Scheda anagrafica
        </b-button>
      </div>
    </div>
    <div v-else>
      <h5></h5>
      <div class="col-10 mx-auto">
        <h6 class="
            mt-3
            text-prim-grad-1
            fw-bolder
            text-left
            pb-1
            border-bottom border-line-grey
          ">
          Conferma la tua email
        </h6>
        <p class="text-justify fs-5 my-4 py-3 pl-1">
          <span class="text-danger" v-if="$route.query.mailtoken">Il link utilizzato non è più valido. <br /></span>
          Se non hai ricevuto la mail di conferma controlla sotto la cartella
          spam della tua casella di posta elettronica oppure
          <span class="point fw-bolder" @click="submitResend">richiedi una nuova mail di conferma</span>
        </p>
        <b-button @click="submitResend" type="button" variant="primary text-white primary_gradient mx-2 mt-4 fw-bolder">
          Invia nuova mail
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

import { mapState, mapActions } from "vuex";
export default {
  name: "EmailVerification",
  components: {
    // HelloWorld,
  },
  computed: {
    ...mapState("utente", ["status", "user"]),
  },
  methods: {
    ...mapActions("utente", ["resend", "me"]),
    submitResend() {
      this.resend().then(function (hh) {
        //console.log("thend");
      });
    },
    logAndHome() {
      if (this.$route.query.access_token) {
        let form = {
          token: this.$route.query.access_token,
        };
        this.me(form);
      } else {
        this.$router.push({ name: "Home" });
      }
    },
  },
  mounted() { },
};
</script>
