<template>
  <div class="col-12 py-4">
    <h6
      class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
    >
      Attività
    </h6>
    <p class="text-justify text-medium-grey fw-bolder">
      Questa sezione raccoglie tutte le iniziative che comprendono la
      formulazione e la compilazione di questionari, dalle campagne di screening
      ai questionari legati ai servizi DottorFarma quali Facile - prestazioni e
      Connessa. Clicca su una riga per vederne i dettagli. Per creare una nuova
      attività clicca su "nuova attività"; potrai gestire lo stato di
      attivazione solo delle iniziative create da te e dei questionari ad esse
      associate.
    </p>
    <b-row class="justify-content-between">
      <b-col class="text-left px-0 my-1">
        <b-button
          v-if="user.permessi.includes('gestire attività e questionari')"
          variant="outline-primary shadow_6 rounded-pill"
          class="fw-bolder"
          @click="infoAlert('Funzionalità disabilitata per questa utenza')"
        >
          <b-icon icon="plus"></b-icon> nuova attività
        </b-button>
      </b-col>
      <!-- <b-col class="text-left px-0 my-1">
        <b-button v-if="user.permessi.includes('gestire attività e questionari')"
          variant="outline-primary shadow_6 rounded-pill" class="fw-bolder" @click="$refs['modal-newactivity'].show()">
          <b-icon icon="plus"></b-icon> nuova attività
        </b-button>
      </b-col> -->
      <b-col class="col-12 col-lg-4 col-sm-6 px-0 my-1">
        <b-input-group
          size="sm"
          class="mt-2 ml-auto mr-0 shadow_6 rounded-pill border border-primary"
        >
          <b-form-input
            class="align-items-center align-content-center rounded-pill"
            v-model="filterSetted.search"
            type="search"
            placeholder="Cerca attività.."
            @input="fetchData"
          >
          </b-form-input>

          <b-input-group-append>
            <span class="mx-2">
              <b-icon icon="search"></b-icon>
            </span>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>
    <div class="mt-3">
      <b-table
        borderless
        hover
        responsive
        table-variant="white text-info"
        :fields="fields"
        :items="activities"
        :small="$root.isMobile"
        selectable
        select-mode="single"
        @row-selected="onRowSelected"
        ref="actable"
      >
        <template #cell(space)="">
          <p
            class="mb-0 pl-2 text-secondary text-justify text-capitalize fw-bolder"
          ></p>
        </template>
        <template #cell(name)="row">
          <p class="mb-0 text-secondary text-justify text-capitalize fw-bolder">
            {{ row.item.name }}
          </p>
        </template>

        <template #cell(description)="row">
          <p class="mb-0 text-secondary text-justify fw-bolder">
            {{ row.item.description }}
          </p>
        </template>
        <template #cell(tot_surveys)="row">
          <span
            v-if="row.item.prof2 === 1"
            class="text-secondary text-justify fw-bolder"
            >--</span
          >
          <span v-else class="text-secondary text-justify fw-bolder"
            >Tot: {{ row.item.tot_surveys }}</span
          >
        </template>
        <template #cell(active)="row">
          <b-icon
            v-if="row.item.created_by == null"
            icon="circle-fill"
            :variant="row.item.active == 1 ? 'success' : 'danger'"
          ></b-icon>
          <b-icon
            v-else-if="
              !user.permessi.includes('gestire attività e questionari')
            "
            icon="circle-fill"
            :variant="row.item.active == 1 ? 'success' : 'danger'"
          ></b-icon>

          <b-form-checkbox
            v-else
            v-model="row.item.active"
            name="check-button"
            :state="row.item.active == 1"
            :value="1"
            :unchecked-value="0"
            switch
            @change="selectChangeStatus(row)"
          >
          </b-form-checkbox>
        </template>
        <!-- <template #cell(azioni)="row">
          <router-link
            class="
              info_gradient
              btn btn-info btn-sm
              px-3
              rounded-pill
              fw-bolder
            "
            :to="{ name: 'questionari', params: { quest: row.item.hashid } }"
          >
            Dettaglio</router-link
          >
        </template> -->
      </b-table>
      <p v-if="activities && activities.length == 0">Nessuna attività qui</p>
      <b-modal
        ref="modal-newactivity"
        title="Crea Nuova Attività"
        size="lg"
        hide-footer
      >
        <NuovaAttivita
          v-on:activity-created="handleNewActivity($event)"
          class="px-4 py-3"
        />
      </b-modal>

      <b-modal
        ref="modal-statusconfirm"
        title="Conferma operazione"
        size="lg"
        hide-footer
        @hidden="cancelOp"
      >
        <div v-if="selectedItem !== null">
          <h5 class="fw-bolder">
            Sei sicuro di voler
            {{
              activities[selectedItem].active === 1
                ? "attivare "
                : "disattivare "
            }}
            "{{ activities[selectedItem].name }}" ?
          </h5>
          <p v-if="activities[selectedItem].active === 1">
            Attivando l'attività i relativi questionari saranno disponibili per
            la compilazione.
          </p>
          <p v-else>
            Disattivando l'attività i relativi questionari non saranno
            disponibili per la compilazione.
          </p>
          <div class="my-4 text-center">
            <b-button variant="outline-danger m-1" @click="cancelOp"
              >No, annulla</b-button
            >
            <b-button variant="info info_gradient m-1" @click="changeStatus"
              >Sì, procedi</b-button
            >
          </div>
        </div>
      </b-modal>
    </div>
    <div v-if="showDetails">
      <ActivityDetails
        v-if="
          selectedActivity &&
          user.permessi.includes('gestire attività e questionari')
        "
        :activity="selectedActivity"
        :asAdmin="false"
        v-on:do-delete="deleteActivity($event)"
        v-on:do-update="updateActivity($event)"
      />
      <p v-else>Non puoi visualizzare i dettagli</p>
      <span ref="activitydetail"></span>
    </div>
  </div>
</template>
<script>
import { supportService } from "@/_services";
import { mapState, mapActions } from "vuex";
import NuovaAttivita from "@/components/attivita/NuovaAttivita.vue";
import ActivityDetails from "@/components/attivita/ActivityDetails.vue";
export default {
  name: "HomeAttivita",
  components: { NuovaAttivita, ActivityDetails },
  data() {
    return {
      filterSetted: { search: null },
      fields: [
        { key: "space", label: "" },
        { key: "name", label: "Nome", class: "text-justify" },
        { key: "description", label: "Descrizione", class: "text-justify" },
        // { key: "tot_surveys", label: "Questionari" },
        // { key: "data", label: "Data", sortable: true },
        { key: "active", label: "Stato" },
        // { key: "azioni", label: "" },
      ],
      activities: null,
      selectedActivity: null,
      showDetails: false,
      selectedItem: null,
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
    ...mapState("utente", ["status", "user"]),
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    fetchData() {
      var self = this;
      supportService
        .getActivities(this.filterSetted)
        .then(function (res) {
          self.activities = res.data.data;
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare le attività");
          console.log(err);
        });
      //
    },
    handleSearch() {
      if (this.filterSetted.search && this.filterSetted.search.length >= 3) {
        this.fetchData();
      }
    },
    handleNewActivity(resp) {
      this.$refs["modal-newactivity"].hide();
      window.scrollTo(0, 0);
      if (resp == true) {
        this.successAlert("Attività creata con successo!");
        this.fetchData();
      } else {
        this.errorAlert("Non è stato possibile creare l'attività");
      }
    },
    selectChangeStatus(row) {
      this.selectedItem = row.index;
      this.$refs["modal-statusconfirm"].show();
    },
    cancelOp() {
      this.$refs["modal-statusconfirm"].hide();
      this.fetchData();
    },
    changeStatus() {
      var formData = {
        hash: this.activities[this.selectedItem].hashid,
        form: {
          active: this.activities[this.selectedItem].active,
        },
      };
      var self = this;
      supportService
        .updateActivity(formData)
        .then(function (res) {
          window.scrollTo(0, 0);
          self.successAlert("Stato attività aggiornato con successo!");
          self.cancelOp();
        })
        .catch(function (err) {
          console.log(err);
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile aggiornare lo stato attività");
          self.cancelOp();
        });
    },
    onRowSelected(items) {
      this.selectedActivity = items[0];
      //console.log(this.selectedActivity);
      if (this.selectedActivity.prof2 === 1) {
        this.$router.push({
          name: "progetto prof2",
          query: { activity: this.selectedActivity.name },
        });
      } else {
        this.showDetails = true;
        this.$nextTick(function () {
          this.$refs["activitydetail"].scrollIntoView();
        });
      }
    },
    deleteActivity(hash) {
      //console.log("hash", hash);
      var self = this;
      supportService
        .deleteActivity(hash)
        .then(function (res) {
          window.scrollTo(0, 0);
          self.successAlert("Attività eliminata con successo!");
          self.fetchData();
          self.selectedActivity = null;
          self.showDetails = false;
        })
        .catch(function (err) {
          console.log(err);
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile eliminare l'attività");
          self.fetchData();
        });
    },
    updateActivity(data) {
      //console.log("data", data);
      var formData = {
        hash: data.hashid,
        form: data.form,
      };
      var self = this;
      supportService
        .updateActivity(formData)
        .then(function (res) {
          window.scrollTo(0, 0);
          self.successAlert("Attività aggiornata con successo!");
          self.fetchData();
        })
        .catch(function (err) {
          console.log(err);
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile aggiornare l'attività");
          self.fetchData();
        });
    },
  },
  created() {
    this.fetchData();
  },
};
</script>
