<template>
  <div v-if="contratti">
    <p class="text-justify fw-bolder text-medium-grey mt-3 mb-2">
      Di seguito trovi il contratto e le nomine sottoscritte.
    </p>
    <b-row cols="1" class="text-left">
      <h6 class="mt-3">Licenza d'uso</h6>
      <a :href="contratti.contratto" target="_blank"
        >Vedi e scarica contratto</a
      >
      <h6 class="mt-3">Nomina responsabili esterni Promofarma</h6>
      <a :href="contratti.nomina_p" target="_blank">Vedi e scarica nomina</a>
      <h6 class="mt-3">Nomina responsabili esterni Qwince</h6>
      <a :href="contratti.nomina_q" target="_blank">Vedi e scarica nomina</a>
    </b-row>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { companyService } from "@/_services";
export default {
  name: "Contratti",
  data() {
    return {
      contratti: null,
    };
  },
  computed: {
    ...mapState("utente", ["status", "user"]),
    ...mapState("company", ["companyLoggedIn", "company"]),
    ...mapState({
      alert: (state) => state.alert,
    }),
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    fetchData() {
      var self = this;
      companyService
        .getContrattiFarmacia()
        .then(function (res) {
          self.contratti = res.data.data;
        })
        .catch(function (error) {
          self.errorAlert("Si è verificato un errore inatteso.");
        });
    },
  },
  created() {
    this.fetchData();
  },
};
</script>
