<template>
  <div class="pl-3 mt-1 text-left">
    <div class="col-11 my-2 py-2">
      <h6 class="fw-bolder">Carica un nuovo allegato</h6>
      <UploadComponent dest="prestazioni" v-on:upload-result="handleUpload($event)" />
    </div>
    <div v-if="allegati && allegati.length > 0">
      <b-row class="pl-3 text-left mt-1" cols="1">
        <h6 class="fw-bolder">Allegati associati:</h6>
      </b-row>
      <b-row v-for="(allegato, ind) in allegati" :key="ind" class="pl-3 align-items-center">
        <!-- <span>{{ allegato.name }}</span> -->
        <a :href="allegato.full_path" target="__blank">
          <b-badge variant="primary" class="my-2 py-1 px-2" v-b-tooltip.hover
            :title="'apri ' + allegato.name + ' in un\'altra scheda'">{{ allegato.name }}</b-badge>
        </a>
        <span class="text-danger fw-bolder p-2 point ml-3" @click="showRemove(ind)">
          <u> Rimuovi</u></span>
      </b-row>
    </div>
    <p v-else class="text-left">
      Nessun allegato caricato per questa prestazione.
    </p>
    <b-modal ref="confirm-remove" hide-footer title="Conferma rimozione">
      <div v-if="selectedRemove !== null">
        <h5 class="text-center">
          Sei sicuro di voler rimuovere {{ allegati[selectedRemove].name }} ?
        </h5>
        <div class="text-center pt-3">
          <b-button variant="info" class="m-1" @click="$refs['confirm-remove'].hide()">No, annulla</b-button>
          <b-button variant="danger" class="m-1" @click="removeSelected">Sì, procedi</b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { supportService } from "@/_services";
import UploadComponent from "@/components/utilities/UploadComponent.vue";
export default {
  name: "AllegatiPrestazioni",
  props: ["hash"],
  components: {
    UploadComponent,
  },
  data() {
    return {
      allegati: null,
      new: {
        resource: null,
      },
      selectedRemove: null,
      doFetch: false,
    };
  },
  watch: {
    doFetch: function (val) {
      if (this.allegati == null) {
        this.fetchData();
      }
    },
  },
  methods: {
    fetchData() {
      var self = this;
      supportService
        .getHealthServiceAllegati(this.hash)
        .then(function (res) {
          // //console.log(res);
          self.allegati = res.data.data;
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    handleUpload(data) {
      // //console.log(data);
      this.new.resource = data.hashid;
      // this.fileOrder = data;
      var data = {
        hash: this.hash,
        form: this.new,
      };
      var self = this;
      supportService
        .addHealthServiceAllegato(data)
        .then(function (res) {
          // //console.log(res);
          self.fetchData();
          self.new.resource = null;
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    showRemove(index) {
      this.selectedRemove = index;
      this.$refs["confirm-remove"].show();
    },
    removeSelected() {
      this.new.resource = this.allegati[this.selectedRemove].hashid;
      // this.fileOrder = data;
      var data = {
        hash: this.hash,
        form: this.new,
      };
      var self = this;
      supportService
        .removeHealthServiceAllegato(data)
        .then(function (res) {
          // //console.log(res);
          self.$refs["confirm-remove"].hide();
          self.fetchData();
          self.selectedRemove = null;
          self.new.resource = null;
        })
        .catch(function (err) {
          console.log(err);
          self.$refs["confirm-remove"].hide();
        });
    },
  },
};
</script>