<template>
  <div v-if="company">
    <div class="d-flex justify-content-between">
      <router-link
        class="fw-bolder text-primary no__hover"
        :to="{ name: 'gestione aziende' }"
      >
        <b-icon icon="arrow-left-circle-fill" variant="primary"></b-icon>
        Gestione Aziende
      </router-link>
    </div>
    <h4>{{ company.denominazione }}</h4>
    <template v-if="user.permessi.includes('vedere sottoscrizioni')">
      <div>
        <h5
          class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
        >
          Stato Azienda
        </h5>
        <p class="text-left font-weight-bolder fs-5">
          <span v-if="company.enabled">Abilitata</span>
          <span v-else>Non abilitata</span>.
          <span
            >Ultimo login:
            {{ company.updated_at | moment("DD/MM/YYYY HH:mm") }}
          </span>
        </p>
        <SottoscrizioniFarmacia
          :hashid="company.hashid"
        ></SottoscrizioniFarmacia>
      </div>
      <div class="text-left">
        <h5
          class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
        >
          Documenti di adesione
        </h5>
        <p class="text-left text-medium-grey fs-5">
          Contratto e nomine sottoscritte all'adesione.
        </p>
        <ContrattiFarmacia :hashid="company.hashid"></ContrattiFarmacia>
      </div>
    </template>
    <div>
      <h5
        class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
      >
        Scheda anagrafica
      </h5>
      <p class="text-left text-medium-grey fs-5">Dati anagrafici aziendali .</p>
      <b-row cols="2" class="text-left">
        <b-col>
          <h6 class="fw-bolder text-secondary">Sede</h6>
          <p class="mb-0">
            {{ company.indirizzo }} {{ company.cap }}, {{ company.frazione }}
            {{ company.comune }}
          </p>
          <p class="mb-0">{{ company.provincia }} {{ company.regione }}</p>
          <p class="">
            <strong class="text-lowercase">codice asl: </strong>
            {{ company.codice_asl }}
            <strong class="ml-2 text-lowercase">comunale: </strong>
            {{ company.comunale == 1 ? "sì" : "no" }}
            <strong class="ml-2 text-lowercase">azienda: </strong>
            {{ company.azienda == 1 ? "sì" : "no" }}
          </p>
          <h6 class="fw-bolder text-secondary">Titolare</h6>
          <p class="mb-0 text-capitalize">
            {{ company.nome }} {{ company.cognome }}
          </p>
          <p class="mb-0 text-uppercase">
            <strong class="text-lowercase">codice fiscale: </strong>
            {{ company.codice_fiscale }}
            <strong class="ml-2 text-lowercase">partita iva:</strong>
            {{ company.partita_iva }}
          </p>
          <p class="text-uppercase">
            <strong class="text-lowercase">codice titolare: </strong>
            {{ company.codice_titolare }}
            <strong class="ml-2 text-lowercase">codice tracciabilità: </strong>
            {{ company.codice_tracciabilita }}
          </p>
          <h6 class="fw-bolder text-secondary">Contatti</h6>
          <p class="text-uppercase">
            <strong class="text-lowercase">email: </strong>
            {{ company.email }}
            <strong class="ml-2 text-lowercase">pec: </strong>
            {{ company.emailpec }}<br />
            <strong class="text-lowercase">telefono: </strong>
            {{ company.telefono }}
            <strong class="ml-2 text-lowercase">fax: </strong>
            {{ company.fax }}
          </p>
        </b-col>
        <b-col>
          <h6 class="fw-bolder text-secondary">Apertura</h6>
          <p v-if="company.apertura && company.apertura.length > 0">
            <span v-for="(gg, index) in company.apertura" :key="index">
              <strong>{{ Object.keys(gg)[0] }}: </strong>
              <strong v-if="gg[Object.keys(gg)[0]] == 'chiuso'">Chiuso</strong>
              <strong v-else>
                {{ gg[Object.keys(gg)[0]]["mattina"] }}
                {{ gg[Object.keys(gg)[0]]["pomeriggio"] }}</strong
              >
              <br />
            </span>
          </p>
          <p v-else>Non inseriti</p>
        </b-col>
      </b-row>
    </div>

    <div class="text-left">
      <h5
        class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
      >
        Farmacia Dei Servizi
      </h5>
      <p class="text-left text-medium-grey fs-5">
        Adesioni alle campagne di Farmacia Dei Servizi (FDS).
      </p>
      <h6 class="mb-3" v-if="company.preadesione_fds == true">
        La farmacia ha dato la sua pre-adesione il
        {{ company.preadesione_fds_il | moment("DD/MM/YYYY") }}
        <span v-if="company.adesione_fds_il">
          , adesione confermata il
          {{ company.adesione_fds_il | moment("DD/MM/YYYY") }} </span
        >.
      </h6>
      <h6 class="mb-3" v-else-if="company.preadesione_fds == false">
        La farmacia ha scelto di non aderire
      </h6>
      <h6 class="mb-3" v-else>La farmacia non ha indicato una scelta.</h6>

      <b-row class="mb-3" v-if="company.preadesione_fds == true">
        <b-col>
          <h6>Servizi FDS scelti dalla farmacia(pre-adesioni):</h6>
          <ul v-if="company.preadesioni_fds_services.length > 0">
            <li
              v-for="serv in company.preadesioni_fds_services"
              :key="serv.hashid"
            >
              {{ serv.name }}
            </li>
          </ul>
          <ul v-else>
            nessuna pre-adesione indicata
          </ul>
        </b-col>
        <b-col>
          <h6>Adesioni confermate alla farmacia:</h6>
          <ul v-if="company.adesioni_fds_services.length > 0">
            <li
              v-for="serv in company.adesioni_fds_services"
              :key="serv.hashid"
            >
              {{ serv.name }}
            </li>
          </ul>
          <ul v-else>
            nessuna adesione confermata
          </ul>
        </b-col>
      </b-row>
    </div>
    <div class="mb-4">
      <h5
        class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
      >
        Piani Terapeutici
      </h5>
      <p class="text-left text-medium-grey fs-5">
        Piani Terapeutici gestiti dalla farmacia
      </p>
      <ProgressPT :valori="company.piani_terapeutici" />
    </div>
    <div>
      <h5
        class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
      >
        Servizi DottorFarma attivi
      </h5>
      <p class="text-left text-medium-grey fs-5">Servizi attualmente attivi.</p>
      <div v-if="company.servizi_attivi" class="text-left pb-2">
        <div v-for="(servizio, ind) in company.servizi_attivi" :key="ind">
          <div v-if="company.prenotazioni.servizi[ind]" class="mb-2">
            <h6 class="text-secondary text-capitalize fw-bolder">{{ ind }}</h6>
            <p class="text-left text-medium-grey fs-5">
              Report attività {{ ind }}.
            </p>
            <ProgressAttivita :valori="company.prenotazioni.servizi[ind]" />
          </div>
        </div>
      </div>
    </div>
    <div>
      <h5
        class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
      >
        Prestazioni
      </h5>
      <p class="text-left text-medium-grey fs-5">
        Prestazioni sanitarie, stato attivazione dei servizi, orari di
        disponibilità al servizio e tool di reportistica.
      </p>
      <div v-if="company.prestazioni_sanitarie" class="text-left col-12">
        <b-row
          cols="1"
          v-for="(prestazione, ind) in company.prestazioni_sanitarie"
          :key="ind"
          class="panel__box__shadow bg-light-panel border border-light-panel general_border my-3 p-2"
        >
          <b-row cols="1" class="pl-2">
            <div class="pl-2">
              <p class="fw-bolder text-capitalize mt-2">
                {{ prestazione.name }}
                <span v-if="prestazione.active == 1">
                  <b-icon icon="check-circle" variant="success"></b-icon>
                </span>
                <span v-else>
                  <b-icon icon="x-circle" variant="danger"></b-icon>
                </span>
              </p>
            </div>
          </b-row>
          <b-row cols="3" class="pl-2 pb-2">
            <div class="pl-2">
              <p
                v-if="
                  prestazione.disponibilita &&
                  prestazione.disponibilita.length > 0
                "
              >
                <b-button
                  variant="secondary rounded-pill fw-bolder"
                  class="mb-1"
                  size="sm"
                  v-b-toggle="'collapse-' + prestazione.hashid"
                  >Disponibilità</b-button
                >
              </p>
            </div>
            <div>
              <b-button
                variant="primary rounded-pill fw-bolder"
                class="mb-1"
                size="sm"
                v-b-toggle="'attivita-' + prestazione.hashid"
                >Attività</b-button
              >
            </div>
            <div>
              <b-button
                v-if="user.permessi.includes('vedere dati cittadini')"
                variant="info rounded-pill fw-bolder"
                class="mb-1"
                size="sm"
                v-b-toggle="'report-' + prestazione.hashid"
                >Report</b-button
              >
            </div>
          </b-row>
          <b-row cols="1" class="pl-4">
            <b-collapse :id="'collapse-' + prestazione.hashid">
              <p class="text-left text-font-grey fs-5 mb-0 fw-bolder">
                Disponibilità
              </p>
              <span
                v-for="(gg, index) in prestazione.disponibilita"
                :key="index"
              >
                <strong>{{ Object.keys(gg)[0] }}: </strong>
                <strong v-if="gg[Object.keys(gg)[0]] == 'chiuso'"
                  >Chiuso</strong
                >
                <strong v-else>
                  {{ gg[Object.keys(gg)[0]]["mattina"] }}
                  {{ gg[Object.keys(gg)[0]]["pomeriggio"] }}</strong
                >
                <br />
              </span>
            </b-collapse>
            <b-collapse :id="'attivita-' + prestazione.hashid">
              <p class="text-left text-font-grey fs-5 mb-0 fw-bolder">
                Attività
              </p>
              <ProgressAttivita
                :valori="company.prenotazioni.servizi[prestazione.name]"
              />
            </b-collapse>
            <b-collapse :id="'report-' + prestazione.hashid">
              <p class="text-left text-font-grey fs-5 mb-0 fw-bolder">Report</p>
              <CompanyReport :azienda="company" :servizio="prestazione" />
            </b-collapse>
          </b-row>
        </b-row>
      </div>
      <p v-else>Nessuna Prestazione</p>
    </div>
    <div class="my-2">
      <h5
        class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
      >
        Attività e Questionari
      </h5>
      <p class="text-left text-medium-grey fs-5">
        Panoramica sulle attività ed i questionari.
      </p>
      <div v-if="company.questionari" class="text-left">
        <div class="mb-2" v-for="(att, ind) in company.questionari" :key="ind">
          <h6 class="text-secondary text-capitalize fw-bolder">{{ ind }}</h6>
          <p class="text-left text-medium-grey fs-5">Report {{ ind }}.</p>
          <ProgressQuestionari :valori="company.questionari[ind]" />
        </div>
      </div>
      <p v-else>Nessuna attività.</p>
    </div>
    <div class="my-3">
      <h5
        class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
      >
        Report Attività Prenotazioni e Ordini
      </h5>
      <p class="text-left text-medium-grey fs-5">
        Panoramica complessiva degli ordini e delle prenotazioni ricevute per
        tutti i servizi e le prenotazioni.
      </p>
      <h6 class="fw-bolder text-secondary text-left">Complessivo</h6>
      <ProgressAttivita :valori="company.prenotazioni.general" />
      <h6 class="fw-bolder text-secondary text-left mt-3">Ordini Prodotto</h6>
      <ProgressAttivita :valori="company.prenotazioni.servizi.prodotto" />
      <h6 class="fw-bolder text-secondary text-left mt-3">
        Prenotazioni Prestazione
      </h6>
      <ProgressAttivita :valori="company.prenotazioni.servizi.prestazione" />
    </div>
  </div>
</template>
<script>
import { supportService } from "@/_services";
import { mapState, mapActions, mapGetters } from "vuex";
import CompanyReport from "@/components/admin/CompanyReport.vue";
import ProgressAttivita from "@/components/admin/ProgressAttivita.vue";
import ProgressPT from "@/components/admin/ProgressPT.vue";
import ProgressQuestionari from "@/components/admin/ProgressQuestionari.vue";
import ContrattiFarmacia from "@/components/admin/ContrattiFarmacia.vue";
import SottoscrizioniFarmacia from "@/components/admin/SottoscrizioniFarmacia.vue";

export default {
  name: "DettaglioAzienda",
  components: {
    CompanyReport,
    ProgressAttivita,
    ProgressQuestionari,
    ProgressPT,
    ContrattiFarmacia,
    SottoscrizioniFarmacia,
  },
  data() {
    return {
      companyHash: this.$route.params.company,
      company: null,
    };
  },
  computed: {
    ...mapState("utente", ["status", "user"]),
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    fetchData() {
      var self = this;
      supportService
        .getCompany(this.$route.params.company)
        .then(function (response) {
          self.company = response.data.data;
        })
        .catch(function (error) {
          //
          self.errorAlert("Non trovata.");
        });
    },
  },
  created() {
    //console.log("HERE");
    // if (this.$route.params.company) {
    this.fetchData();
    // }
  },
};
</script>
