<template>
  <div>
    <NotificationMenu v-if="user" />
    <div
      class="py-1 py-md-2 py-lg-3 col-12 top_header d-flex flex-wrap align-items-center px-0"
    >
      <div class="col-md-2 col-xl-3 col-12 px-0 mx-auto order-1 pl-xl-3">
        <div :class="$root.isMobile && 'd-flex align-items-center'">
          <router-link
            :to="{ name: 'Home' }"
            class="app__logo"
            :class="$root.isMobile ? 'ml-2' : ' px-3 py-4'"
            :style="cssLogo"
          >
            <span class="p-2 my-2"> APP LOGO</span>
          </router-link>
          <div
            v-if="$root.isMobile"
            class="col-sm-5 col-md-2 px-0 ml-auto mr-1 order-1"
          >
            <b-dropdown
              :text="'Benvenuto ' + welcomeName"
              variant="font-grey-outline fw-bolder bg-transparent text-white border-transparent px-1 text-capitalize"
              v-if="user && user.username"
              id="nav_dd"
            >
              <b-dropdown-item
                v-if="user.type !== 2"
                variant="font-grey-outline"
                @click="$router.push({ name: helpPath })"
              >
                <span> <b-icon icon="book"></b-icon> FAQ </span>
              </b-dropdown-item>
              <b-dropdown-item
                v-else-if="
                  user.permessi.includes('gestire aziende propria asl') &&
                  user.anagrafica &&
                  user.anagrafica.regione === 'lombardia'
                "
                variant="font-grey-outline"
                @click="openManualeATS"
              >
                <span> <b-icon icon="book"></b-icon> Manuale ATS </span>
              </b-dropdown-item>

              <template v-if="user.type === 1">
                <b-dropdown-item
                  variant="font-grey-outline"
                  @click="$router.push({ name: 'materiale informativo' })"
                >
                  <span>
                    <b-icon icon="cloud-download"></b-icon> Materiale
                    informativo
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="canSeeImpostazioni"
                  variant="font-grey-outline"
                  @click="
                    $router.push({
                      name: 'Impostazioni',
                      query: { scope: 'operatori' }
                    })
                  "
                >
                  <span>
                    <b-icon icon="shield-lock"></b-icon> Codice Azienda
                  </span>
                </b-dropdown-item>
              </template>
              <b-dropdown-item
                variant="font-grey-outline"
                @click.prevent="onSubmit"
              >
                <span> <b-icon icon="door-open-fill"></b-icon> Esci </span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>
      <!-- <div
        v-if="!$root.menuLeft"
        :class="$root.isMobile ? 'order-3' : 'order-2'"
        class="col-sm-9 col-md-5 col-lg-6 bg-white shadow_3 px-2 d-flex justify-content-center mx-auto general_border border border-white mbl__fx"
      > -->
      <div
        v-if="!$root.menuLeft"
        :class="$root.isMobile ? 'order-3' : 'order-2'"
        class="col bg-white shadow_3 px-2 d-flex justify-content-center mx-auto general_border border border-white mbl__fx"
      >
        <router-link
          class="my-1 my-md-3 mx-auto"
          :to="{ name: 'Home' }"
          :class="this.$route.path === homePath && 'router-link-exact-active'"
        >
          <b-icon
            font-scale="1.5"
            :icon="
              this.$route.path === homePath ? 'house-door-fill' : 'house-door'
            "
          >
          </b-icon>
          <p class="mb-0 fs-5">Home</p>
        </router-link>
        <router-link
          v-if="canSeeProfilo"
          class="my-1 my-md-3 mx-auto"
          :to="{ name: 'Profilo' }"
        >
          <b-icon
            font-scale="1.5"
            :icon="this.$route.name === 'Profilo' ? 'person-fill' : 'person'"
          ></b-icon>
          <p class="mb-0 fs-5">Profilo</p>
        </router-link>
        <router-link
          v-if="canSeeImpostazioni"
          class="my-1 my-md-3 mx-auto"
          :to="{ name: 'Impostazioni' }"
        >
          <b-icon
            font-scale="1.5"
            :icon="this.$route.name === 'Impostazioni' ? 'gear-fill' : 'gear'"
          ></b-icon>
          <p class="mb-0 fs-5">Impostazioni</p>
        </router-link>
      </div>
      <div
        v-else
        class="col px-2 mx-auto mbl__fx"
        :class="$root.isMobile ? 'order-3' : 'order-2'"
      >
        <!-- <div
        v-else
        class="col-sm-9 col-md-5 col-lg-6 px-2 mx-auto mbl__fx"
        :class="$root.isMobile ? 'order-3' : 'order-2'"
      > -->
        <div v-if="user.type === 1">
          <PrenotazioniMenu
            v-if="
              $route.name === 'prenotazioni' ||
              $route.name === 'calendario prenotazioni'
            "
            class="bg-white shadow_3 px-2 d-flex justify-content-center mx-auto general_border border border-white"
          />
          <AttivitaMenu
            v-else-if="
              $route.name === 'attivita' ||
              $route.name === 'questionari' ||
              $route.name === 'statistiche'
            "
            class="bg-white shadow_3 px-2 d-flex justify-content-center mx-auto general_border border border-white"
          />
          <TeleconsultoMenu
            v-else-if="
              $route.name === 'teleconsulto' ||
              $route.name === 'videoconsulto' ||
              $route.name === 'telecalendario'
            "
            class="bg-white shadow_3 px-2 d-flex justify-content-center mx-auto general_border border border-white"
          />
          <div
            v-else
            class="bg-white shadow_3 px-2 d-flex justify-content-center mx-auto general_border border border-white"
          >
            <p class="mb-0 py-1 py-md-4 text-capitalize fw-bolder">
              {{ $route.params.type ? $route.params.type : $route.name }}
            </p>
          </div>
        </div>
        <div
          v-else
          class="bg-white shadow_3 px-2 d-flex justify-content-center mx-auto general_border border border-white"
        >
          <p
            v-if="this.$route.name === 'EmailVerification'"
            class="mb-0 py-1 py-md-4 text-capitalize fw-bolder"
          >
            Verifica Email
          </p>
          <p v-else class="mb-0 py-1 py-md-4 text-capitalize fw-bolder">
            {{ $route.params.type ? $route.params.type : $route.name }}
          </p>
        </div>
      </div>
      <div
        class="col-md-2 col-xl-3 col-12 px-0 order-2 ml-0 mr-auto"
        :class="$root.isTablet ? 'text-left' : 'text-center'"
        v-if="!$root.isMobile"
      >
        <!-- <div
        class="col-sm-5 col-md-4 col-lg-3 px-0 order-2 ml-0 mr-auto"
        :class="$root.isTablet ? 'text-left' : 'text-center'"
        v-if="!$root.isMobile"
      > -->
        <b-dropdown
          :text="'Benvenuto ' + welcomeName"
          variant="font-grey-outline fw-bolder bg-transparent text-white border-transparent px-1 text-capitalize mt-lg-4 pt-lg-4"
          v-if="user && user.username"
          id="nav_dd"
        >
          <b-dropdown-item
            variant="font-grey-outline"
            v-if="user.type !== 2"
            @click="$router.push({ name: helpPath })"
          >
            <span> <b-icon icon="book"></b-icon> FAQ </span>
          </b-dropdown-item>
          <b-dropdown-item
            v-else-if="
              user.permessi.includes('gestire aziende propria asl') &&
              user.anagrafica &&
              user.anagrafica.regione === 'lombardia'
            "
            variant="font-grey-outline"
            @click="openManualeATS"
          >
            <span> <b-icon icon="book"></b-icon> Manuale ATS </span>
          </b-dropdown-item>
          <template v-if="user.type === 1">
            <b-dropdown-item
              variant="font-grey-outline"
              @click="$router.push({ name: 'materiale informativo' })"
            >
              <span>
                <b-icon icon="cloud-download"></b-icon> Materiale informativo
              </span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="canSeeImpostazioni"
              variant="font-grey-outline"
              @click="
                $router.push({
                  name: 'Impostazioni',
                  query: { scope: 'operatori' }
                })
              "
            >
              <span> <b-icon icon="shield-lock"></b-icon> Codice Azienda </span>
            </b-dropdown-item>
          </template>
          <b-dropdown-item
            variant="font-grey-outline"
            @click.prevent="onSubmit"
          >
            <span> <b-icon icon="door-open-fill"></b-icon> Esci </span>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import PrenotazioniMenu from "./PrenotazioniMenu.vue"
import TeleconsultoMenu from "./TeleconsultoMenu.vue"
import AttivitaMenu from "./AttivitaMenu.vue"
import NotificationMenu from "./NotificationMenu.vue"
export default {
  name: "TopHeader",
  components: {
    // HelloWorld,
    PrenotazioniMenu,
    AttivitaMenu,
    TeleconsultoMenu,
    NotificationMenu
  },
  computed: {
    homePath() {
      if (this.user) {
        return "/" + this.user.i_am + "/home"
      }
    },
    welcomeName() {
      if (this.user && this.user.anagrafica) {
        return this.user.anagrafica.name
      }
      return this.user.username
    },
    canSeeImpostazioni() {
      if (this.user.type === 1) {
        return (
          this.user.permessi.includes("visualizzare anagrafica azienda") ||
          this.user.permessi.includes("gestire impostazioni dei servizi") ||
          this.user.permessi.includes("gestire operatori")
        )
      }
      return true
    },
    canSeeProfilo() {
      // if (this.user.type === 1 && !Array.isArray(this.user.company)) {
      //   //Rimuovere se si vuole dare la possibilità di modificare la psw per i farmacisti ff
      //   return this.user.email !== this.user.company.email;
      // }
      return true
    },
    helpPath() {
      if (this.user) {
        return this.user.type === 1 ? "domande frequenti" : "FAQ"
      }
      return "FAQ"
    },
    ...mapState("utente", ["status", "user"])
  },
  data() {
    return {
      cssLogo: {
        // backgroundImage:
        //   "url('" +
        //   process.env.VUE_APP_BE +
        //   "storage/logos/" +
        //   process.env.VUE_APP_SCOPE +
        //   "/logo.png')",
        backgroundPosition: "center",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundImage: `url(${require("@/assets/dottorfarmaWhite.svg")})`
        // backgroundImage: `url(${require('@/assets/path/to/your/img.jpg')})`
      }
    }
  },
  methods: {
    ...mapActions("utente", ["logout"]),
    onSubmit() {
      // console.log(this.$root.swRegistration);
      // this.$root.swRegistration.unregister().then(function (boolean) {
      //   // if boolean = true, unregister is successful
      //   console.log(boolean)
      // });
      this.logout()
    },
    checkPath() {
      if (this.user) {
        return "/" + this.user.i_am + "/home"
      }
    },
    openManualeATS() {
      window.open(
        "https://api.service.dottorfarma.it/storage/scaricabili/ManualeUtenteDottorfarmaATS_ASST_v2.1_53453463646346331111.pdf",
        "_blank"
      )
    }
  }
  // created() {
  //   //console.log("ROUTE", this.$route);
  //   // //console.log("env", process.env.VUE_APP_BE);
  // },
}
</script>
<style>
.dropdown#nav_dd > .btn:focus {
  outline: none;
  box-shadow: none;
}
</style>
