<template>
  <div class="pb-4" style="margin: 0 -30px">
    <b-row class="mb-1 mt-n2">
      <b-col class="col-12 px-0 text-right">
        <span class="mr-3">
          <b-form-select
            class="shadow_6"
            style="max-width: 150px"
            @change="onMonthYearChange"
            v-model="selectedMonth"
            size="sm"
          >
            <b-form-select-option
              v-for="month in monthMapping"
              :key="month.id"
              :value="month.id"
              id="month_selector"
              class="text-secondary"
            >
              {{ month.name }}
            </b-form-select-option>
          </b-form-select>
        </span>
        <span class="mr-3">
          <b-form-select
            class="shadow_6"
            style="max-width: 130px"
            v-model="selectedYear"
            id="year_selector"
            @change="onMonthYearChange"
            size="sm"
          >
            <b-form-select-option
              v-for="year in years"
              :key="year"
              :value="year"
              class="text-secondary"
            >
              {{ year }}
            </b-form-select-option>
          </b-form-select>
        </span>
      </b-col>
    </b-row>
    <b-row class="mx-0">
      <b-form-group class="text-left text-font-grey" style="margin-left: 10px">
        <b-form-checkbox
          id="show-all-days"
          name="show-all-days"
          :value="false"
          :unchecked-value="true"
          v-model="daysAllVisible"
          @change="setupCalendar()"
          style="font-size: 5px"
        >
          <span class="checkbox">Nascondi giorni senza prenotazioni</span>
        </b-form-checkbox>
        <b-form-checkbox
          id="show-all-hours"
          name="show-all-hours"
          :value="false"
          :unchecked-value="true"
          v-model="hoursAllVisible"
          @change="setupCalendar()"
        >
          <span class="checkbox">
            Nascondi fasce orarie senza prenotazioni
          </span>
        </b-form-checkbox>
      </b-form-group>
    </b-row>
    <div class="d-flex justify-content-between">
      <b-button
        :size="$root.isMobile ? 'sm' : ''"
        variant="outline-info"
        type="button"
        @click="setPrev"
      >
        <b-icon icon="chevron-double-left"></b-icon
        >{{ !$root.isMobile ? "Precedente" : "" }}
      </b-button>
      <h5 class="text-capitalize">
        {{ start | moment("D MMM") }} - {{ end | moment("D MMM") }}
      </h5>
      <b-button
        :size="$root.isMobile ? 'sm' : ''"
        variant="outline-info"
        type="button"
        @click="setPost"
        >{{ !$root.isMobile ? "Successiva" : "" }}
        <b-icon icon="chevron-double-right"></b-icon>
      </b-button>
    </div>
    <div>
      <b-table
        :fixed="!$root.isMobile"
        sticky-header="700px"
        small
        borderless
        responsive
        table-variant="white text-info"
        :fields="fields"
        :items="prenotazioni"
      >
        <!-- <template #cell(time)="row" class="bg-info">
          <div class="d-flex h-100 align-content-end">
            <strong class="text-medium-grey mb-0">{{ row.item.time }}</strong>
          </div>
        </template> -->

        <template #head(day0)="data">
          {{ data.label.substring(0, 5) }}
          <br />
          {{ data.label.substring(6, 9) }}
        </template>

        <template #head(day1)="data">
          {{ data.label.substring(0, 5) }}
          <br />
          {{ data.label.substring(6, 9) }}
        </template>

        <template #head(day2)="data">
          {{ data.label.substring(0, 5) }}
          <br />
          {{ data.label.substring(6, 9) }}
        </template>
        <template #head(day3)="data">
          {{ data.label.substring(0, 5) }}
          <br />
          {{ data.label.substring(6, 9) }}
        </template>
        <template #head(day4)="data">
          {{ data.label.substring(0, 5) }}
          <br />
          {{ data.label.substring(6, 9) }}
        </template>
        <template #head(day5)="data">
          {{ data.label.substring(0, 5) }}
          <br />
          {{ data.label.substring(6, 9) }}
        </template>
        <template #head(day6)="data">
          {{ data.label.substring(0, 5) }}
          <br />
          {{ data.label.substring(6, 9) }}
        </template>

        <template #cell(time)="row">
          <span id="timeElement">{{ row.item.time }}</span>
        </template>

        <template #cell(day0)="row">
          <div v-for="(app, ind) in row.item.day0" :key="'day0' + ind">
            <div
              v-for="elem in app"
              :key="elem.hashid"
              @click="showReservDetail(elem)"
              style="position: relative"
              class="single-card"
              :class="
                elem.status > 0
                  ? colorStatusPositive[elem.status]
                  : 'rifiutato-annullato'
              "
            >
              <p class="paragraph-single-card">
                <span class="span-icon">
                  <b-iconstack
                    style="
                      height: 100%;
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                    "
                    :font-scale="1.5"
                  >
                    <b-icon
                      stacked
                      icon="circle-fill"
                      variant="white"
                      scale="1"
                    ></b-icon>
                    <b-icon
                      stacked
                      :icon="elem.icona > 1 ? icons[2] : icons[elem.icona]"
                      scale="0.7"
                    ></b-icon>
                  </b-iconstack>
                </span>
                <span class="ora-inizio">
                  {{ elem.data_inizio | moment("HH:mm") }}
                </span>
              </p>
            </div>
          </div>
        </template>
        <template #cell(day1)="row">
          <div v-for="(app, ind) in row.item.day1" :key="'day1' + ind">
            <div
              v-for="elem in app"
              :key="elem.hashid"
              @click="showReservDetail(elem)"
              style="position: relative"
              class="single-card"
              :class="
                elem.status > 0
                  ? colorStatusPositive[elem.status]
                  : 'rifiutato-annullato'
              "
            >
              <p class="paragraph-single-card">
                <span class="span-icon">
                  <b-iconstack
                    style="
                      height: 100%;
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                    "
                    :font-scale="1.5"
                  >
                    <b-icon
                      stacked
                      icon="circle-fill"
                      variant="white"
                      scale="1"
                    ></b-icon>
                    <b-icon
                      stacked
                      :icon="elem.icona > 1 ? icons[2] : icons[elem.icona]"
                      scale="0.7"
                    ></b-icon>
                  </b-iconstack>
                </span>
                <span class="ora-inizio">
                  {{ elem.data_inizio | moment("HH:mm") }}
                </span>
              </p>
            </div>
          </div>
        </template>

        <template #cell(day2)="row">
          <div v-for="(app, ind) in row.item.day2" :key="'day2' + ind">
            <div
              v-for="elem in app"
              :key="elem.hashid"
              @click="showReservDetail(elem)"
              style="position: relative"
              class="single-card"
              :class="
                elem.status > 0
                  ? colorStatusPositive[elem.status]
                  : 'rifiutato-annullato'
              "
            >
              <p class="paragraph-single-card">
                <span class="span-icon">
                  <b-iconstack
                    style="
                      height: 100%;
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                    "
                    :font-scale="1.5"
                  >
                    <b-icon
                      stacked
                      icon="circle-fill"
                      variant="white"
                      scale="1"
                    ></b-icon>
                    <b-icon
                      stacked
                      :icon="elem.icona > 1 ? icons[2] : icons[elem.icona]"
                      scale="0.7"
                    ></b-icon>
                  </b-iconstack>
                </span>
                <span class="ora-inizio">
                  {{ elem.data_inizio | moment("HH:mm") }}
                </span>
              </p>
            </div>
          </div>
        </template>
        <template #cell(day3)="row">
          <div v-for="(app, ind) in row.item.day3" :key="'day3' + ind">
            <div
              v-for="elem in app"
              :key="elem.hashid"
              @click="showReservDetail(elem)"
              style="position: relative"
              class="single-card"
              :class="
                elem.status > 0
                  ? colorStatusPositive[elem.status]
                  : 'rifiutato-annullato'
              "
            >
              <p class="paragraph-single-card">
                <span class="span-icon">
                  <b-iconstack
                    style="
                      height: 100%;
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                    "
                    :font-scale="1.5"
                  >
                    <b-icon
                      stacked
                      icon="circle-fill"
                      variant="white"
                      scale="1"
                    ></b-icon>
                    <b-icon
                      stacked
                      :icon="elem.icona > 1 ? icons[2] : icons[elem.icona]"
                      scale="0.7"
                    ></b-icon>
                  </b-iconstack>
                </span>
                <span class="ora-inizio">
                  {{ elem.data_inizio | moment("HH:mm") }}
                </span>
              </p>
            </div>
          </div>
        </template>
        <template #cell(day4)="row">
          <div v-for="(app, ind) in row.item.day4" :key="'day4' + ind">
            <div
              v-for="elem in app"
              :key="elem.hashid"
              @click="showReservDetail(elem)"
              style="position: relative"
              class="single-card"
              :class="
                elem.status > 0
                  ? colorStatusPositive[elem.status]
                  : 'rifiutato-annullato'
              "
            >
              <p class="paragraph-single-card">
                <span class="span-icon">
                  <b-iconstack
                    style="
                      height: 100%;
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                    "
                    :font-scale="1.5"
                  >
                    <b-icon
                      stacked
                      icon="circle-fill"
                      variant="white"
                      scale="1"
                    ></b-icon>
                    <b-icon
                      stacked
                      :icon="elem.icona > 1 ? icons[2] : icons[elem.icona]"
                      scale="0.7"
                    ></b-icon>
                  </b-iconstack>
                </span>
                <span class="ora-inizio">
                  {{ elem.data_inizio | moment("HH:mm") }}
                </span>
              </p>
            </div>
          </div>
        </template>
        <template #cell(day5)="row">
          <div v-for="(app, ind) in row.item.day5" :key="'day5' + ind">
            <div
              v-for="elem in app"
              :key="elem.hashid"
              @click="showReservDetail(elem)"
              style="position: relative"
              class="single-card"
              :class="
                elem.status > 0
                  ? colorStatusPositive[elem.status]
                  : 'rifiutato-annullato'
              "
            >
              <p class="paragraph-single-card">
                <span class="span-icon">
                  <b-iconstack
                    style="
                      height: 100%;
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                    "
                    :font-scale="1.5"
                  >
                    <b-icon
                      stacked
                      icon="circle-fill"
                      variant="white"
                      scale="1"
                    ></b-icon>
                    <b-icon
                      stacked
                      :icon="elem.icona > 1 ? icons[2] : icons[elem.icona]"
                      scale="0.7"
                    ></b-icon>
                  </b-iconstack>
                </span>
                <span class="ora-inizio">
                  {{ elem.data_inizio | moment("HH:mm") }}
                </span>
              </p>
            </div>
          </div>
        </template>
        <template #cell(day6)="row">
          <div v-for="(app, ind) in row.item.day6" :key="'day6' + ind">
            <div
              v-for="elem in app"
              :key="elem.hashid"
              @click="showReservDetail(elem)"
              style="position: relative"
              class="single-card"
              :class="
                elem.status > 0
                  ? colorStatusPositive[elem.status]
                  : 'rifiutato-annullato'
              "
            >
              <p class="paragraph-single-card">
                <span class="span-icon">
                  <b-iconstack
                    style="
                      height: 100%;
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                    "
                    :font-scale="1.5"
                  >
                    <b-icon
                      stacked
                      icon="circle-fill"
                      variant="white"
                      scale="1"
                    ></b-icon>
                    <b-icon
                      stacked
                      :icon="elem.icona > 1 ? icons[2] : icons[elem.icona]"
                      scale="0.7"
                    ></b-icon>
                  </b-iconstack>
                </span>
                <span class="ora-inizio">
                  {{ elem.data_inizio | moment("HH:mm") }}
                </span>
              </p>
            </div>
          </div>
        </template>
      </b-table>
      <p class="mb-0" v-if="prenotazioni !== null && prenotazioni.length == 0">
        Nessuna richiesta qui.
      </p>
      <b-modal
        id="modal-resdetails"
        hide-footer
        size="xl"
        header-class="pb-0"
        scrollable
        centered
        @hidden="selectedReservation = null"
      >
        <template #modal-header="{ close }">
          <b-img
            v-if="
              selectedReservation !== null &&
              selectedReservation.tipo_ordine === 'teleconsulto'
            "
            height="21%"
            :src="require('@/assets/connessa.svg')"
            alt="logo connessa"
            class="pl-4"
          >
          </b-img>
          <b-img
            v-else
            height="21%"
            :src="require('@/assets/facile.svg')"
            alt="logo facile"
            class="pl-4"
          >
          </b-img>
          <button
            type="button"
            aria-label="Close"
            class="close"
            @click="close()"
          >
            ×
          </button>
        </template>
        <div v-if="selectedReservation !== null">
          <!-- {{ row.item[selectedDay][selectedIndex] }} -->
          <b-card
            header-text-variant="prim-grad-1"
            header-bg-variant="white px-1"
            header-tag="h4"
            class="text-left text-font-grey px-1"
            size="xl"
            border-variant="white"
            body-class="px-1"
          >
            <template #header>
              <b-row cols="1">
                <b-col>
                  <span
                    >Dettaglio richiesta #{{
                      selectedReservation.codice_richiesta
                    }}</span
                  >
                  <p style="font-size: 70%" class="mb-0 text-font-grey mt-1">
                    Inoltrata il
                    <strong>{{
                      selectedReservation.created_at || moment("DD.MM.YY HH:mm")
                    }}</strong>
                  </p>
                </b-col>
                <b-col class="text-left">
                  <span class="fs-5">
                    <!-- <strong class="ml-1 text-secondary">Richiesta: </strong> -->
                    <span
                      v-if="selectedReservation.tipo_ordine === 'prestazione'"
                      class="text-prim-grad-1 fw-bolder"
                      >{{
                        selectedReservation.orderable.health_service
                          ? selectedReservation.orderable.health_service.name
                          : "prestazione"
                      }}</span
                    >
                    <span v-else class="text-prim-grad-1 fw-bolder">{{
                      selectedReservation.tipo_ordine === "prodotto"
                        ? "ordine"
                        : selectedReservation.tipo_ordine
                    }}</span>
                  </span>
                  <p style="font-size: 70%" class="mb-0 text-font-grey mt-1">
                    <strong>{{
                      selectedReservation.status === 1
                        ? "ricevuto, da confermare"
                        : selectedReservation.stato
                    }}</strong>
                  </p>
                </b-col>
              </b-row>
            </template>
            <b-card-text>
              <b-row>
                <b-col>
                  <h5 class="text-secondary fw-bolder mt-1">Sede:</h5>
                  <p class="mb-0 text-capitalize fw-bolder">
                    {{ selectedReservation.company.ff_denominazione }}
                    ,
                    <strong>
                      {{ selectedReservation.company.indirizzo }}
                    </strong>
                    ,
                    <strong class="text-capitalize"
                      >{{ selectedReservation.company.cap }}
                      {{ selectedReservation.company.comune }}
                      ({{ selectedReservation.company.provincia }})
                      {{ selectedReservation.company.regione }}
                    </strong>
                  </p>
                </b-col>
                <b-col
                  v-if="
                    selectedReservation.requested_by.anagrafica.cf !==
                    selectedReservation.subjectable.anagrafica.cf
                  "
                >
                  <h5 class="text-secondary fw-bolder">
                    <span class="text-capitalize">{{
                      selectedReservation.tipo_ordine
                    }}</span>
                    richiesta per conto di:
                  </h5>
                  <p class="text-secondary fw-bolder">
                    <b-icon
                      icon="person-fill"
                      variant="secondary"
                      class="mr-2"
                    ></b-icon>

                    <span
                      class="text-capitalize"
                      v-if="selectedReservation.subjectable.anagrafica"
                      >{{ selectedReservation.subjectable.anagrafica.name }}
                      {{ selectedReservation.subjectable.anagrafica.surname }}
                    </span>
                    <span v-else class="text-capitalize"
                      >{{ selectedReservation.subjectable.name }}
                      {{ selectedReservation.subjectable.surname }},
                    </span>

                    <b-icon
                      icon="credit-card-fill"
                      variant="secondary"
                      class="mr-2"
                    ></b-icon>
                    <span class="text-uppercase"
                      >{{
                        selectedReservation.subjectable.anagrafica
                          ? selectedReservation.subjectable.anagrafica.cf
                          : selectedReservation.subjectable.cf
                      }}
                    </span>
                  </p>
                </b-col>
              </b-row>
              <b-row class="my-2">
                <b-col>
                  <h5 class="text-secondary fw-bolder">Giorno e orario:</h5>
                  <p class="fw-bolder">
                    <b-icon
                      icon="calendar"
                      variant="secondary"
                      class="mr-2"
                    ></b-icon>
                    <span class="mx-2">{{
                      selectedReservation.data_inizio | moment("DD.MM.YY")
                    }}</span>
                    <b-icon
                      icon="clock"
                      variant="secondary"
                      class="mr-2"
                    ></b-icon>

                    <span class="mx-2">{{
                      selectedReservation.data_inizio | moment("HH:mm")
                    }}</span>
                    <span v-if="selectedReservation.data_fine">
                      -
                      {{
                        selectedReservation.data_fine | moment("HH:mm")
                      }}</span
                    >

                    <UpdateReservationDate
                      class="d-inline ml-2 ml-md-0 ml-lg-1"
                      :hash="selectedReservation.hashid"
                      v-on:change-requested="handleUpdateRequest"
                    />
                  </p>
                </b-col>
              </b-row>
              <b-row class="pl-3">
                <UserDetailTeleconsulto
                  :detail="selectedReservation.orderable"
                  :stato="selectedReservation.status"
                  :datainizio="selectedReservation.data_inizio"
                  :quest="selectedReservation.filled_survey"
                  :hash="selectedReservation.hashid"
                  :suspended="selectedReservation.suspended_survey"
                  v-if="selectedReservation.tipo_ordine === 'teleconsulto'"
                  v-on:update-request-status="
                    handleStatusUpdate(selectedReservation, $event)
                  "
                />
                <UserDetailPrestazione
                  :detail="selectedReservation.orderable"
                  :reportReady="selectedReservation.report_ready"
                  :stato="selectedReservation.status"
                  :datainizio="selectedReservation.data_inizio"
                  :quest="selectedReservation.filled_survey"
                  :suspended="selectedReservation.suspended_survey"
                  v-if="selectedReservation.tipo_ordine === 'prestazione'"
                  v-on:update-request-status="
                    handleStatusUpdate(selectedReservation, $event)
                  "
                />
                <UserDetailProdotto
                  :dettaglio="selectedReservation.orderable"
                  :stato="selectedReservation.status"
                  :datainizio="selectedReservation.data_inizio"
                  v-if="selectedReservation.tipo_ordine === 'prodotto'"
                  v-on:update-request-status="
                    handleStatusUpdate(selectedReservation, $event)
                  "
                />
                <!-- <p class="mb-0" v-if="selectedReservation.user_notes">
                  Note dell'utente:
                  <strong>{{ selectedReservation.user_notes }}</strong>
                </p>
                <p class="mb-0" v-if="selectedReservation.company_notes">
                  Note Operatore:
                  <strong>{{ selectedReservation.company_notes }}</strong>
                </p> -->
              </b-row>
              <p class="mb-0 bg-light p-1">
                <br />
                <strong> Tue note </strong>
                <UpdateNotesComponent
                  v-on:note-updated="handleNotesUpdate($event)"
                  class="d__innline"
                  :hashid="selectedReservation.hashid"
                  :reserdate="selectedReservation.data_inizio"
                >
                </UpdateNotesComponent>
                <template v-if="selectedReservation.user_notes"
                  ><br />
                  {{ selectedReservation.user_notes }}
                </template>
              </p>
              <p
                class="mb-0 bg-light p-1"
                v-if="selectedReservation.company_notes"
              >
                <strong>Note Operatore:</strong>
                {{ selectedReservation.company_notes }}
              </p>
              <div class="d-flex justify-content-end mt-2">
                <b-button
                  variant="primary primary_gradient rounded-pill fw-bolder px-3 fs-6 ml-auto mr-1"
                  @click="downloadPdf(selectedReservation)"
                  >Scarica promemoria
                </b-button>
                <b-button
                  variant="primary primary_gradient rounded-pill fw-bolder px-3 fs-6 "
                  @click="downloadCalendar(selectedReservation)"
                  >Scarica calendar
                </b-button>
              </div>
            </b-card-text>
          </b-card>
        </div>
      </b-modal>
    </div>
    <b-row class="mx-0">
      <p class="text-secondary mr-1 d-flex">
        <b-icon
          :icon="icons[1]"
          font-scale="1.2"
          class="align-self-center"
        ></b-icon>
        <span class="text-secondary mx-2 vertical__align">Teleconsulto</span>
        <b-img
          height="21%"
          :src="require('@/assets/connessa.svg')"
          alt="logo facile"
        >
        </b-img>
      </p>
      <p class="text-secondary mr-1 d-flex">
        <b-icon
          :icon="icons[0]"
          font-scale="1.2"
          class="align-self-center"
        ></b-icon>
        <span class="text-secondary mx-2 vertical__align">Ordine</span>
        <b-img
          height="21%"
          :src="require('@/assets/facile.svg')"
          alt="logo facile"
        >
        </b-img>
      </p>
      <p class="text-secondary mr-1 d-flex">
        <b-icon
          :icon="icons[2]"
          font-scale="1.2"
          class="align-self-center"
        ></b-icon>
        <span class="text-secondary mx-2 vertical__align"
          >Prestazione sanitaria</span
        >
        <b-img
          height="21%"
          :src="require('@/assets/facile.svg')"
          alt="logo facile"
        >
        </b-img>
      </p>
    </b-row>
  </div>
</template>
<script>
import * as moment from "moment/moment";
import { mapActions } from "vuex";
import { reservationService } from "@/_services";
import UserDetailPrestazione from "@/components/prenotazioni/UserDetailPrestazione.vue";
import UserDetailTeleconsulto from "@/components/prenotazioni/UserDetailTeleconsulto.vue";
import UserDetailProdotto from "@/components/prenotazioni/UserDetailProdotto.vue";
import UpdateNotesComponent from "@/components/prenotazioni/UpdateNotesComponent.vue";
import UpdateReservationDate from "@/components/prenotazioni/UpdateReservationDate.vue";
export default {
  name: "CalendarioCittadino",
  props: ["servicerif"],
  components: {
    UserDetailTeleconsulto,
    UserDetailPrestazione,
    UserDetailProdotto,
    UpdateNotesComponent,
    UpdateReservationDate,
  },
  data() {
    return {
      daysAllVisible: false,
      hoursAllVisible: false,
      fields: [],
      prenotazioni: null,
      prenotazioniBackend: null,
      start: null,
      end: null,
      month: null,
      today: null,
      filters: {
        from: null,
        to: null,
        position_ref: this.servicerif,
      },
      selectedDay: null,
      selectedIndex: null,
      statusPositive: {
        1: "secondary",
        2: "info",
        3: "info",
        4: "primary",
        5: "ter-4",
        6: "ter-4",
      },
      statusNegative: {
        1: "danger",
        2: "ter-2",
        3: "ter-2",
        4: "dark",
      },
      icons: {
        0: "bag",
        1: "camera-video",
        2: "file-ppt",
        3: "chat-text",
        4: "screwdriver",
        5: "droplet-fill",
        6: "flower1",
        7: "emoji-smile",
        8: "exclude",
        9: "egg-fried",
        10: "gem",
        11: "heart-fill",
        12: "hourglass-split",
        13: "journal-medical",
        14: "life-preserver",
        15: "lock-fill",
        16: "moon",
        17: "node-minus-fill",
        18: "x-diamond-fill",
        19: "cart-fill",
        20: "bug-fill",
        21: "brightness-high-fill",
        22: "bell-fill",
      },
      paddingMarginIcona: {
        0: "padding-margin-ordine",
        1: "padding-margin-teleconsulto",
        2: "padding-margin-prestazione",
      },
      selectedReservation: null,
      colorStatusPositive: {
        1: "inoltrato-in-attesa",
        2: "confermato",
        3: "confermato",
        4: "evaso",
        5: "inoltrato-in-attesa",
        6: "inoltrato-in-attesa",
      },
      fasceOrarie: [
        "0-7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22-0",
      ],
      colorLegend: [
        {
          label: "Confermato",
          color: "confermato",
        },
        {
          label: "Evaso/Concluso",
          color: "evaso",
        },
        {
          label: "Inoltrato/In attesa di risposta",
          color: "inoltrato-in-attesa",
        },
        {
          label: "Errore/Rifiutato/Annullato",
          color: "rifiutato-annullato",
        },
      ],
      monthMapping: [
        { id: 0, name: "Gennaio" },
        { id: 1, name: "Febbraio" },
        { id: 2, name: "Marzo" },
        { id: 3, name: "Aprile" },
        { id: 4, name: "Maggio" },
        { id: 5, name: "Giugno" },
        { id: 6, name: "Luglio" },
        { id: 7, name: "Agosto" },
        { id: 8, name: "Settembre" },
        { id: 9, name: "Ottobre" },
        { id: 10, name: "Novembre" },
        { id: 11, name: "Dicembre" },
      ],
      years: [],
      selectedMonth: new Date().getMonth(),
      selectedYear: new Date().getFullYear(),
    };
  },
  watch: {
    "filters.to": function (newValue, oldValue) {
      if (!oldValue) return;
      const newDate = this.parseDateString(newValue);

      this.selectedMonth = newDate.getMonth();

      this.selectedYear = newDate.getFullYear();
    },
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    setupCalendar() {
      this.prenotazioni = this.prenotazioniBackend;

      if (!this.hoursAllVisible) {
        this.prenotazioni.forEach((p, i) => {
          p["time"] = this.fasceOrarie[i];
        });

        if (Object.keys(this.prenotazioni[0]) && this.prenotazioni[0]["time"])
          this.prenotazioni = this.prenotazioni.filter(
            (p) => Object.keys(p).length > 1
          );
      }

      let filledFields = {};

      if (!this.daysAllVisible) {
        filledFields = { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0 };
        this.prenotazioni.forEach((obj) => {
          Object.keys(obj).forEach((k) => {
            if (k != "time") {
              let c = k[3];
              filledFields[c] = 1;
            }
          });
        });
      }

      let fields = [];
      let newField = {
        stickyColumn: true,
        key: "time",
        label: "",
        variant: "",
        tdClass: "tdTimeColumn",
      };
      fields.push(newField);

      for (let x = 0; x <= 6; x++) {
        let startDay = moment(this.start);
        let todayDay = moment(this.today).format("DD/MM");
        let date = startDay.add(x, "days").format("DD/MM");
        let label = date;
        if (!this.daysAllVisible) {
          let weekday = moment(this.start)
            .locale("it")
            .add(x, "days")
            .format("dddd")
            .substring(0, 3);
          label += " " + weekday;
        }
        let variant = undefined;
        if (todayDay === date) {
          variant = "sec-4";
        }

        if (this.daysAllVisible || filledFields[parseInt(x)]) {
          let newField = {
            key: "day" + parseInt(x),
            label: label,
            variant: variant,
          };
          fields.push(newField);
        }
        this.fields = fields;
      }
    },
    fetchData() {
      var self = this;
      reservationService
        .fetchUserCalendar(this.filters)
        .then(function (response) {
          //console.log("response fetch prenotazioni", response.data.data);
          self.prenotazioni = response.data.data;
          self.prenotazioniBackend = self.prenotazioni;
          self.setupCalendar();
        })
        .catch(function (error) {
          self.errorAlert("non è stato possibile recuperare le prenotazioni");
          //console.log("error fetch prenotazioni", error);
        });
    },
    setPrev() {
      this.start.subtract(1, "weeks");
      this.end.subtract(1, "weeks");
      this.filters.from = moment(this.start).startOf("week").format("D/M/YY");
      this.filters.to = moment(this.end).endOf("week").format("D/M/YY");
      this.selectedDay = null;
      this.selectedIndex = null;
      this.fetchData();
    },
    setPost() {
      this.start.add(1, "weeks");
      this.end.add(1, "weeks");
      this.filters.from = moment(this.start).startOf("week").format("D/M/YY");
      this.filters.to = moment(this.end).endOf("week").format("D/M/YY");
      this.selectedDay = null;
      this.selectedIndex = null;
      this.fetchData();
    },
    setRef(ref, ind) {
      this.selectedDay = ref;
      this.selectedIndex = ind;
    },
    showReservDetail(res) {
      this.selectedReservation = res;
      this.$bvModal.show("modal-resdetails");
    },
    handleStatusUpdate(item, data) {
      //console.log("data to submit", data);
      var self = this;
      if (!data.confirmed) {
        var form = data.message ? { message: data.message } : {};
        reservationService
          .userCancelOrReject(form, data.action, item.hashid)
          .then(function (re) {
            // self.$refs["modal-dettagliorec"].hide();
            window.scrollTo(0, 0);
            self.successAlert("Richiesta aggiornata con successo");
            self.fetchData();
          })
          .catch(function (error) {
            window.scrollTo(0, 0);
            self.errorAlert("non è stato possibile effettuare l'operazione");
          });
        this.$bvModal.hide("modal-resdetails");
      } else {
        reservationService
          .userConfirmOrder(item.hashid)
          .then(function (re) {
            window.scrollTo(0, 0);
            self.successAlert("Richiesta aggiornata con successo");
            self.fetchData();
          })
          .catch(function (error) {
            self.loading = false;
            window.scrollTo(0, 0);
            self.errorAlert("non è stato possibile effettuare l'operazione");
          });
        this.$bvModal.hide("modal-resdetails");
      }
    },
    downloadPdf(res) {
      const hash = res.hashid;
      const fName = res.codice_richiesta;
      var self = this;
      reservationService
        .scaricaPromemoria(hash)
        .then(function (response) {
          let blob = new Blob([response.data]);
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = fName + ".pdf";
          link.click();
        })
        .catch(function (error) {
          self.loading = false;
          window.scrollTo(0, 0);
          self.errorAlert("non è stato possibile scaricare il promemoria");
        });
    },
    downloadCalendar(res) {
      const hash = res.hashid;
      const fName = res.codice_richiesta;
      var self = this;
      reservationService
        .scaricaCalendar(hash)
        .then(function (response) {
          let blob = new Blob([response.data]);
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = fName + ".ics";
          link.click();
        })
        .catch(function (error) {
          self.loading = false;
          window.scrollTo(0, 0);
          self.errorAlert("non è stato possibile scaricare il promemoria");
        });
    },
    handleNotesUpdate(data) {
      this.selectedReservation.user_notes = data.user_notes;
    },
    handleUpdateRequest() {
      this.$bvModal.hide("modal-resdetails");
      this.successAlert("richiesta inviata con successo");
      this.fetchData();
    },
    onMonthYearChange() {
      const firstDayOfMonth = new Date(
        this.selectedYear,
        this.selectedMonth,
        1
      );
      this.start = moment(firstDayOfMonth).startOf("week");
      this.end = moment(firstDayOfMonth).endOf("week");
      this.filters.from = moment(this.start).startOf("week").format("D/M/YY");
      this.filters.to = moment(this.end).endOf("week").format("D/M/YY");
      this.setupCalendar();
    },
    parseDateString(dateString) {
      const parts = dateString.split("/");
      let year = parseInt(parts[2], 10);

      year = year < 100 ? 2000 + year : year;

      return new Date(year, parts[1] - 1, parts[0]);
    },
  },
  created() {
    this.start = moment().startOf("week");
    this.end = moment().endOf("week");
    this.today = moment();
    this.month = moment().month();
    this.filters.from = moment().startOf("week").format("D/M/YY");
    this.filters.to = moment().endOf("week").format("D/M/YY");
    // this.filters.position_ref = this.servicerif;
    this.fetchData();

    const currentYear = moment(this.start).year();

    for (let i = 2021; i <= currentYear + 3; i++) {
      this.years.push(i);
    }
  },
};
</script>
<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

#timeElement {
  min-width: 26px !important;
  font-size: 10px;
  color: grey !important;
  font-weight: bolder;
  text-align: justify;
}

.tdTimeColumn {
  min-width: 26px !important;
  //   font-size: 10px;
  //   color: grey !important;
  //   font-weight: bolder;
  //   text-align: justify;
}

tr {
  border-bottom: 2px solid $line-grey !important;
  // border-bottom: 2px solid $font-light-grey !important;
}

tr:last-of-type {
  border-bottom: 0 solid $line-grey !important;
}

.thicker__border {
  border-width: 3px !important;
}

.thicker__border {
  border-width: 3px !important;
}

.rifiutato-annullato {
  color: rgba(253, 39, 75, 1) !important;
}

.inoltrato-in-attesa {
  color: rgba(255, 151, 18, 1) !important;
  /* color: rgba(224, 242, 111, 1) !important; */
}

.confermato {
  color: rgba(32, 80, 114, 1) !important;
  /* color: rgba(205, 224, 201, 0.45) !important; */
}

.evaso {
  color: #01c0cb !important;
  /* color: rgba(73, 67, 255, 1) !important; */
}

.checkbox {
  font-size: 12px;
}

.single-card {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 5px auto;
  border: 1px solid $primary;
  height: 3vh;
  border-radius: 5%;
  width: 55px;
  font-size: 0.7rem;
  background-color: whitesmoke;

  .paragraph-single-card {
    margin-bottom: 0;
    display: flex;
    align-items: center;
    height: 100%;
    line-height: 3vh;
    letter-spacing: 0px;

    span.ora-inizio {
      height: 100%;
      font-weight: bolder;
      margin: 0 1.5px;
      text-align: justify;
    }

    span.span-icon {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
