<template>

  <div>
    <b-modal centered size="lg" id="cookie-modal" title="Cookies" footer-border-variant="white" hide-header-close
      no-close-on-backdrop no-close-on-esc ok-only ok-title="OK" ok-variant="outline-secondary" @ok="closeCookie">
      <p class="fs-2">
        Questo sito utilizza i cookies per assicurarti la migliore esperienza di navigazione.
        I cookies utilizzati contribuiscono a rendere fruibile il sito web abilitandone funzionalità di base quali la
        navigazione sulle pagine e l'accesso alle aree protette del sito. Questi cookies sono necessari: il sito web
        non è in grado di funzionare
        correttamente senza questi cookie. <span @click="goToPP" class="fw-bolder point">Scopri
          di più...</span> <br>
      </p>
    </b-modal>
    <!-- <b-toast toaster="b-toaster-bottom-full" static solid no-close-button id="cookie-toast" variant="primary"
        title="Cookies" no-auto-hide>
        <p class="fs-2">
          Questo sito utilizza i cookies per assicurarti la migliore esperienza di navigazione.
          I cookie necessari contribuiscono a rendere fruibile il sito web abilitandone funzionalità di base quali la
          navigazione sulle pagine e l'accesso alle aree protette del sito. Il sito web non è in grado di funzionare
          correttamente senza questi cookie. <span class="fw-bolder point">Scopri di più...</span> <br>
        </p>
        <p class="text-center">
          <b-button variant="outline-primary" size="sm">Ok, procedi</b-button>
        </p>
      </b-toast> -->
  </div>
</template>
<script>
export default {
  name: "CookieBanner",
  props: ["showbanner"],
  // data() {
  //   return {
  //     showBanner: false
  //   }
  // },
  watch: {
    showbanner: function (val) {
      //console.log("doShowBanner changed")
      this.checkCookie()
    }
  },
  methods: {
    closeCookie() {
      localStorage.setItem('cookie-consent', 'accepted');
      this.$bvModal.hide('cookie-modal')
      this.$emit('cookies-done')
    },
    checkCookie() {
      var consent = localStorage.getItem('cookie-consent');
      if (!consent) {
        //console.log("if not")
        this.$bvModal.show('cookie-modal')
        // this.$bvToast.show('cookie-toast')
      } else {
        this.$emit('cookies-done')
      }
    },
    goToPP() {
      this.closeCookie()
      this.$router.push('/privacypolicy')
    }
  },
  mounted() {
    if (this.showbanner === undefined) {
      //console.log("hfuihfiu")
      this.checkCookie()
    }
  }
}

</script>