<template>
  <div class="col-12 px-0" v-if="user && user.company">
    <template v-if="subscription && hasToBuy">
      <template v-if="!hasContract">
        <div class="col-12">
          <h6
            class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
          >
            Licenza d'uso {{ subscription.nome }}
          </h6>
          <p class="text-secondary text-justify">
            Per procedere è necessario
            <span
              class="text-underlined point"
              @click="$bvModal.show('contratto_nonFF')"
            >
              <u>
                leggere e sottoscrivere la licenza d'uso della piattaforma
              </u> </span
            >.
          </p>
          <b-row class="justify-content-center">
            <b-form-checkbox
              class="text-font-light-grey mx-1"
              name="license"
              :value="false"
              :unchecked-value="false"
              v-model="contractForm.license"
              :state="false"
            >
              Non accetto
            </b-form-checkbox>
            <b-form-checkbox
              class="text-font-light-grey mx-1"
              name="license"
              :value="true"
              :unchecked-value="false"
              v-model="contractForm.license"
              :state="contractForm.license == true"
            >
              Ho letto ed accetto tutte le
              <span
                class="text-underlined point"
                @click="$bvModal.show('contratto_nonFF')"
              >
                <u> condizioni della licenza d'uso della piattaforma </u>
              </span>
            </b-form-checkbox>
          </b-row>
          <b-modal
            id="contratto_nonFF"
            title="Licenza d'uso"
            centered
            size="xl"
            hide-footer
            scrollable
          >
            <ContrattoSperimentazione
              v-if="subscription.wc_product_id === 35"
            ></ContrattoSperimentazione>
            <ContrattoStandard
              v-else-if="subscription.wc_product_id === 36"
            ></ContrattoStandard>
            <ContrattoPnrr
              v-else-if="subscription.wc_product_id === 37"
            ></ContrattoPnrr>
            <ContrattoSperimentazionePagamento
              v-else-if="subscription.wc_product_id === 38"
            ></ContrattoSperimentazionePagamento>
            <ContrattoFarmaciaNonFF v-else></ContrattoFarmaciaNonFF>
          </b-modal>
        </div>
        <div class="col-12 mt-4">
          <h6
            class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
          >
            Nomina responsabili esterni
          </h6>
          <p class="text-secondary text-justify">
            Per procedere è necessario:
            <br />
            <br />
            <span
              class="text-underlined point"
              @click="$bvModal.show('nominaQwince')"
            >
              <u>
                leggere e sottoscrivere la nomina di Qwince come responsabile
                esterno del trattamento
              </u> </span
            >.
          </p>
          <b-row class="justify-content-center">
            <b-form-checkbox
              class="text-font-light-grey mx-1"
              name="nominaQ"
              :value="false"
              :unchecked-value="false"
              v-model="contractForm.responsabileQ"
              :state="false"
            >
              Non sottoscrivo
            </b-form-checkbox>
            <b-form-checkbox
              class="text-font-light-grey mx-1"
              name="nominaQ"
              :value="true"
              :unchecked-value="false"
              v-model="contractForm.responsabileQ"
              :state="contractForm.responsabileQ == true"
            >
              Ho letto e sottoscrivo la
              <span
                class="text-underlined point"
                @click="$bvModal.show('nominaQwince')"
              >
                <u> nomina di Qwince come responsabile esterno </u>
              </span>
            </b-form-checkbox>
          </b-row>

          <b-modal
            id="nominaQwince"
            title="Nomina responsabile esterno"
            centered
            size="xl"
            hide-footer
            scrollable
          >
            <NominaQwince></NominaQwince>
          </b-modal>
          <p class="text-secondary text-justify">
            <br />
            <span
              class="text-underlined point"
              @click="$bvModal.show('nominaPromofarma')"
            >
              <u>
                leggere e sottoscrivere la nomina di Promofarma come
                responsabile esterno del trattamento
              </u> </span
            >.
          </p>
          <b-row class="justify-content-center">
            <b-form-checkbox
              class="text-font-light-grey mx-1"
              name="nominaP"
              :value="false"
              :unchecked-value="false"
              v-model="contractForm.responsabileP"
              :state="false"
            >
              Non sottoscrivo
            </b-form-checkbox>
            <b-form-checkbox
              class="text-font-light-grey mx-1"
              name="nominaP"
              :value="true"
              :unchecked-value="false"
              v-model="contractForm.responsabileP"
              :state="contractForm.responsabileP == true"
            >
              Ho letto e sottoscrivo la
              <span
                class="text-underlined point"
                @click="$bvModal.show('nominaPromofarma')"
              >
                <u> nomina di Promofarma come responsabile esterno </u>
              </span>
            </b-form-checkbox>
          </b-row>
          <b-modal
            id="nominaPromofarma"
            title="Nomina responsabile esterno"
            centered
            size="xl"
            hide-footer
            scrollable
          >
            <NominaPromofarma></NominaPromofarma>
          </b-modal>
        </div>

        <b-row class="my-2">
          <b-col>
            <b-button
              v-if="allContractOk"
              variant="primary primary_gradient"
              @click="storeContract"
            >
              <span v-if="!loading">Conferma le mie scelte</span>
              <b-spinner v-else></b-spinner>
            </b-button>
          </b-col>
        </b-row>
      </template>
      <template v-else>
        <h4>Ci siamo quasi!</h4>
        <h6>
          Completa la procedura di sottoscrizione cliccando su "vai
          all'acquisto" .
        </h6>
        <br />
        <br />
        <div class="mt-4">
          <template v-if="!user.company.dottorfarma">
            <a
              v-if="subscription.wc_product_id == 38"
              class="text-white font-decoration-none"
              :href="linkPagamenti + '4' + subscription.wc_product_id"
              target="_blank"
            >
              <b-button
                variant="info info_gradient rounded-pill mb-0 mt-auto fw-bolder spacing_1"
              >
                <span v-if="!gettingLink"> Vai all'acquisto </span>
                <b-spinner v-else></b-spinner>
              </b-button>
            </a>
            <a
              v-else
              class="text-white font-decoration-none"
              :href="linkPagamenti + subscription.wc_product_id"
              target="_blank"
            >
              <b-button
                variant="info info_gradient rounded-pill mb-0 mt-auto fw-bolder spacing_1"
              >
                <span v-if="!gettingLink"> Vai all'acquisto </span>
                <b-spinner v-else></b-spinner>
              </b-button>
            </a>
          </template>
          <b-button
            v-else
            @click="getRedirectLink(subscription.hashid)"
            variant="info info_gradient rounded-pill text-white fw-bolder spacing_1 mb-0 mt-auto"
          >
            <span v-if="!gettingLink"> Vai all'acquisto </span>
            <b-spinner v-else></b-spinner>
          </b-button>
        </div>
      </template>
    </template>
    <template v-else>
      <!-- QUESTA PARTE E? DA SOTTOPORRE SOLO ALLE NON ISCRITTE CHE NON HANNO ANCORA ACCETTATO L?INFORMATIVA -->
      <template v-if="!user.company.dottorfarma">
        <div class="col-12">
          <h6
            class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
          >
            Trattamento dati personali
          </h6>
          <p class="text-secondary text-justify">
            Per procedere è necessario
            <span
              target="_blank"
              class="point"
              @click="$bvModal.show(informativa.url)"
            >
              <u>
                leggere ed accettare l'informativa sul trattamento dei dati
                personali</u
              ></span
            >
          </p>
          <b-row class="justify-content-center mb-2">
            <b-form-checkbox
              class="text-font-light-grey ml-0 mr-auto mx-lg-1"
              name="data_consense"
              :value="false"
              :unchecked-value="false"
              v-model="form.data_consense"
              :state="false"
            >
              Non acconsento
            </b-form-checkbox>
            <b-form-checkbox
              class="text-font-light-grey ml-0 mr-auto mx-lg-1"
              name="data_consense"
              :value="true"
              :unchecked-value="false"
              v-model="form.data_consense"
              :state="form.data_consense == true"
            >
              Ho letto e do il mio consenso
            </b-form-checkbox>
          </b-row>
        </div>
        <b-row class="my-2">
          <b-col>
            <b-button
              v-if="allOk"
              variant="primary primary_gradient"
              @click="sendConsenses"
            >
              <span v-if="!loading">Conferma le mie scelte</span>
              <b-spinner v-else></b-spinner>
            </b-button>
          </b-col>
        </b-row>
      </template>
      <template v-else>
        <div class="col-12 text-center">
          <!-- <b-button
            variant="primary primary_gradient text-white"
            @click="handleUpdate"
            >Vai alla Homepage</b-button
          > -->
          <b-spinner></b-spinner>
        </div>
      </template>
    </template>
    <b-modal
      size="xl"
      centered
      id="info_farmacista"
      title="Informativa Privacy"
      hide-footer
    >
      <InformativaRegistrazioneFarmacisti></InformativaRegistrazioneFarmacisti>
    </b-modal>
    <b-modal
      size="xl"
      centered
      id="tc_farmacista"
      title="Termini e Condizioni"
      hide-footer
    >
      <TerminiCondizioniFarmacisti></TerminiCondizioniFarmacisti>
    </b-modal>
  </div>
  <b-spinner v-else></b-spinner>
</template>
<script>
import { cittadinoService, companyService } from "@/_services";
import { mapState, mapActions } from "vuex";
import ContrattoFarmaciaNonFF from "@/components/profile/informative/ContrattoFarmaciaNonFF.vue";
import NominaQwince from "@/components/profile/informative/NominaQwince.vue";
import NominaPromofarma from "@/components/profile/informative/NominaPromofarma.vue";
import InformativaRegistrazioneFarmacisti from "@/components/profile/informative/InformativaRegistrazioneFarmacisti.vue";
import TerminiCondizioniFarmacisti from "@/components/profile/informative/TerminiCondizioniFarmacisti.vue";
import ContrattoSperimentazione from "@/components/profile/informative/ContrattoSperimentazione.vue";
import ContrattoSperimentazionePagamento from "@/components/profile/informative/ContrattoSperimentazionePagamento.vue";
import ContrattoStandard from "@/components/profile/informative/ContrattoStandard.vue";
import ContrattoPnrr from "@/components/profile/informative/ContrattoPnrr.vue";
export default {
  name: "ConsenseComponentCompany",
  props: ["subscription"],
  components: {
    ContrattoFarmaciaNonFF,
    NominaQwince,
    NominaPromofarma,
    InformativaRegistrazioneFarmacisti,
    TerminiCondizioniFarmacisti,
    ContrattoSperimentazione,
    ContrattoSperimentazionePagamento,
    ContrattoStandard,
    ContrattoPnrr,
    ContrattoFarmaciaNonFF,
  },
  computed: {
    allOk: function () {
      return this.form.data_consense && this.form.share_consense;
    },
    allContractOk: function () {
      return (
        this.contractForm.license &&
        this.contractForm.responsabileQ &&
        this.contractForm.responsabileP
      );
    },
    linkPagamenti: function () {
      // var baseUrl = "https://pagamenti.federfarma.it";
      var baseUrl = this.pagamenti;
      if (this.user && this.user.company) {
        baseUrl = baseUrl + "?df_token=" + this.user.company.hashid;
        baseUrl += "&acquisto_corrente=";
      }
      return baseUrl;
    },
    ...mapState("utente", ["status", "user"]),
  },
  data() {
    return {
      form: {
        data_consense: false,
        charge_consense: false,
        share_consense: true,
        // notification_consense: false,
      },
      contractForm: {
        license: false,
        responsabileQ: false,
        responsabileP: false,
        subscription_id: this.subscription ? this.subscription.hashid : null,
        // notification_consense: false,
      },
      loading: false,
      infoLinks: {
        box1: process.env.VUE_APP_LINK_INFO_REGISTRAZIONE_BOX1,
        box2: process.env.VUE_APP_LINK_INFO_REGISTRAZIONE_BOX2,
        box3: process.env.VUE_APP_LINK_INFO_REGISTRAZIONE_BOX3,
      },
      hasContract: false,
      informativa: {
        text: " informativa farmacista",
        url: "info_farmacista",
      },
      tc: {
        text: " tc farmacista",
        url: "tc_farmacista",
      },
      hasToBuy: true,
      pagamenti: process.env.VUE_APP_PAGAMENTI_FEDERFARMA_URL,
      gettingLink: false,
    };
  },
  watch: {
    "user.company": function (val) {
      // console.log(val);
      this.hasContract = val.contract;
    },
  },
  methods: {
    ...mapActions("utente", ["unMe", "me"]),
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    sendConsenses() {
      this.loading = true;
      const formData = {
        hashid: this.user.hashid,
        data: this.form,
      };
      const self = this;
      cittadinoService
        .updateUser(formData)
        .then(function (res) {
          // //console.log(res);
          self.handleUpdate();
          // localStorage.removeItem("user");
        })
        .catch(function (err) {
          //console.log("err", err);
          self.loading = false;
          self.errorAlert("Non è stato possibile memorizzare le preferenze");
          self.resetAll();
        });
    },
    storeContract() {
      this.loading = true;
      const self = this;
      companyService
        .storeContract(this.contractForm)
        .then(function (res) {
          // //console.log(res);
          self.handleContractUpdate();
          self.$emit("contract-ok");
          self.loading = false;
          // localStorage.removeItem("user");
        })
        .catch(function (err) {
          //console.log("err", err);
          var errMsg = "Non è stato possibile memorizzare le preferenze";
          if (err.status === 403) {
            errMsg =
              "Non hai i permessi necessari per completare la sottoscrizione, operazione riservata al Titolare.";
          }
          self.loading = false;
          self.errorAlert(errMsg);
          self.resetAll();
        });
    },
    handleUpdate() {
      this.me();
      const self = this;
      setTimeout(function () {
        self.loading = false;
        self.$emit("consense-ok");
      }, 7000);
    },
    handleContractUpdate() {
      this.me();
      if (this.subscription && this.subscription.prezzo == 0) {
        this.hasToBuy = false;
        if (this.user.company.dottorfarma) {
          return this.handleUpdate();
        }
      }
      const self = this;
      setTimeout(function () {
        self.loading = false;
      }, 5000);
    },
    resetAll() {
      this.form = {
        data_consense: false,
        charge_consense: false,
        share_consense: true,
        // notification_consense: false,
      };
    },
    getRedirectLink(productHashid) {
      this.gettingLink = true;
      const self = this;
      companyService
        .getFederfarmaPaymentLink(productHashid)
        .then(function (resp) {
          if (resp.data.data.already) {
            self.infoAlert("Sottoscrizione già attiva!");
          }
          if (resp.data.data.redirect) {
            let link = document.createElement("a");
            link.href = resp.data.data.redirect;
            link.target = "_blank";
            link.click();
          }
          self.gettingLink = false;
        })
        .catch(function (error) {
          // self.$refs["spin-" + ind][0].hidden = true;
          // self.$refs["spn-" + ind][0].hidden = false;
          self.gettingLink = false;
          self.errorAlert(
            "Non è stato possibile procedere alla pagina di pagamento. Richiedere assistenza."
          );
        });
    },
  },
};
</script>
