<template>
  <div v-if="company && company.servizi_attivi['connessa']">
    <div v-if="prenotazioni">
      <div class="col-12">
        <b-button-group class="mb-3 bg-line-grey rounded-pill flex-wrap">
          <b-button class="fw-bolder text-capitalize" :size="$root.isMobile ? 'sm' : ''"
            @click="filterSetted.status = 'in attesa'" :variant="
              filterSetted.status === 'in attesa'
                ? 'primary primary_gradient text-white rounded-pill'
                : 'bg-light text-primary'
            ">in attesa</b-button>
          <b-button class="fw-bolder text-capitalize" :size="$root.isMobile ? 'sm' : ''"
            @click="filterSetted.status = 'confermate'" :variant="
              filterSetted.status === 'confermate'
                ? 'primary primary_gradient text-white rounded-pill'
                : 'bg-light text-primary'
            ">confermate</b-button>
          <b-button class="fw-bolder text-capitalize" :size="$root.isMobile ? 'sm' : ''"
            @click="filterSetted.status = 'annullate'" :variant="
              filterSetted.status === 'annullate'
                ? 'primary primary_gradient text-white rounded-pill'
                : 'bg-light text-primary'
            ">annullate</b-button>
          <b-button class="fw-bolder text-capitalize" :size="$root.isMobile ? 'sm' : ''"
            @click="filterSetted.status = 'evase'" :variant="
              filterSetted.status === 'evase'
                ? 'primary primary_gradient text-white rounded-pill'
                : 'bg-light text-primary'
            ">evase</b-button>
        </b-button-group>
      </div>
      <b-spinner variant="primary" label="loading" v-if="loading"></b-spinner>
      <div v-if="user.permessi.includes('gestire ordini e prestazioni Facile')">
        <!-- <h6
        class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
      >
        Annullamento Rapido
      </h6>
      <p class="text-left text-font-light-grey fs-5">
        Funzionalità per annullamento rapido delle prenotazioni.
      </p>
      <CancelUtility v-on:update-prenotazioni="fetchData" /> -->
        <div>
          <h5 class="
              text-prim-grad-1
              fw-bolder
              text-left
              pb-1
              border-bottom border-line-grey
            ">
            Prenotazioni
          </h5>
          <!-- <p class="text-left text-medium-grey fs-5">
            Le tue prenotazioni per Connessa.
          </p> -->

          <b-row cols="1" cols-lg="3" cols-xl="4" align-v="end">
            <b-col>
              <b-form-group class="text-left">
                <!-- <label for="searchby">Cerca prenotazione per:</label> -->
                <b-form-radio-group class="fw-bolder" v-model="searchByType" :options="searchByTypes" name="searchby">
                </b-form-radio-group>
                <b-input-group v-if="searchByType === 1" size="sm" class="
                mt-2
                ml-auto
                mr-0
                shadow_6
                rounded-pill
                border border-prim-grad-1
              ">
                  <b-form-input class="
                  align-items-center align-content-center
                  text-uppercase
                  rounded-pill
                  border-right-0
                " name="cf" v-model="filterSetted.cf" type="search" placeholder="Cerca codice fiscale..">
                  </b-form-input>

                  <b-input-group-append>
                    <span class="mx-2">
                      <b-icon icon="search"></b-icon>
                    </span>
                  </b-input-group-append>
                </b-input-group>
                <b-input-group v-else size="sm" class="
                mt-2
                ml-auto
                mr-0
                shadow_6
                rounded-pill
                border border-prim-grad-1
              ">
                  <b-form-input class="
                  align-items-center align-content-center
                  text-uppercase
                  rounded-pill
                  border-right-0
                " name="req-code" v-model="filterSetted.search" type="search" placeholder="Cerca codice richiesta..">
                  </b-form-input>

                  <b-input-group-append>
                    <span class="mx-2">
                      <b-icon icon="search"></b-icon>
                    </span>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group class="text-left mt-2 ml-auto" v-if="filterSetted.service == 'prestazione'">
                <label for="type">Prestazione:</label>
                <b-form-select name="type" size="sm" class="
                  align-items-center align-content-center
                  shadow_6
                  border border-info
                  text-info
                " v-model="filterSetted.prestazione">
                  <b-form-select-option :value="null">tutte le prestazioni</b-form-select-option>
                  <b-form-select-option v-for="(prstz, ind) in company.prestazioni_attive" :key="prstz" :value="ind">{{
                      ind
                  }}</b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group class="text-left mt-2 mx-auto">
                <label for="timef" size="sm">Mostra prenotazioni di:</label>
                <b-form-select name="timef" size="sm" class="
                  align-items-center align-content-center
                  shadow_6
                  border border-info
                  text-info
                " v-model="filterSetted.range" required>
                  <b-form-select-option value="oggi">oggi</b-form-select-option>
                  <b-form-select-option value="domani">domani</b-form-select-option>
                  <b-form-select-option value="settimana">settimana</b-form-select-option>
                  <b-form-select-option value="mese">questo mese</b-form-select-option>
                  <b-form-select-option value="30g">ultimi 30 giorni</b-form-select-option>
                  <b-form-select-option value="next30">prossimi 30 giorni</b-form-select-option>
                  <b-form-select-option value="anno">quest'anno</b-form-select-option>
                  <b-form-select-option :value="null">mostra tutte</b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col class="ml-auto">
              <b-form-group class="text-left mt-2 mx-auto">
                <label for="vedi" size="sm">N°elementi da mostrare:</label>

                <b-form-select size="sm" class="shadow_6 border border-info text-info" name="vedi"
                  v-model="filterSetted.take" :options="takeOptions">
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row cols="1" align-v="end">
            <b-col class="text-left">
              <span class="text-info fw-bolder mr-1" v-if="total > 0">{{ fromPage }}-{{ toPage }} di
              </span>
              <span class="text-info fw-bolder"> {{ total }}</span>

              <span class="text-info" v-if="updatingData">
                <b-spinner small variant="info" type="grow" label="Aggiornamento in corso" class="ml-2"></b-spinner>
                Aggiornamento in corso...
              </span>
            </b-col>
          </b-row>

          <b-table borderless hover responsive table-variant="white text-info" :fields="fields" :items="prenotazioni"
            :small="$root.isMobile" :sort-by.sync="sortBy" :sort-desc.sync="filterSetted.indesc">
            <template #cell(codice_richiesta)="row">
              <span class="text-font-grey fw-bolder">
                {{ row.item.codice_richiesta }}</span>
            </template>
            <template #cell(data)="row">
              <span class="fw-bolder text-font-grey">
                {{ row.item.data_inizio | moment("DD/MM/YY HH:mm") }}</span>
            </template>
            <template #cell(utente)="row">
              <span class="text-font-grey" v-if="!row.item.subjectable">*utenza rimossa*</span>
              <div v-else>
                <span class="fw-bolder text-uppercase">
                  {{ row.item.subjectable.anagrafica.cf }}</span><span class="text-font-grey"
                  v-if="row.item.subjectable.anagrafica.tel_number"><br />
                  tel: {{ row.item.subjectable.anagrafica.tel_number }}</span>
              </div>
            </template>
            <!-- <template #cell(contatti)="row">
            <span class="text-font-grey"> {{ row.item.subjectable.email }}</span
            ><br /><span class="text-font-grey">
              {{ row.item.subjectable.anagrafica.tel_number }}</span
            >
          </template> -->

            <template #cell(stato)="row">
              <span class="pt-1 text-uppercase fw-bolder" :class="
                row.item.status > 0
                  ? 'text-' + statusPositive[row.item.status]
                  : 'text-' + statusNegative[Math.abs(row.item.status)]
              ">{{ row.item.stato }}</span>
              <!-- <span class="fw-bolder"> </span> -->
            </template>
            <template #cell(azioni)="row">
              <div class="d-flex">
                <b-button size="sm" variant="info info_gradient rounded-pill fw-bolder px-3 fs-6 mx-1"
                  @click="showDetail(row.item)">
                  {{ row.detailsShowing ? "Chiudi" : "Visualizza" }}
                </b-button>
                <b-button v-if="row.item.status !== 4 && row.item.status > 0" @click="openEvadi(row.item)" size="sm"
                  variant="primary primary_gradient text-white rounded-pill fw-bolder px-3 fs-6 mx-1">
                  Evadi
                </b-button>
                <!-- <b-button
                v-if="row.item.status !== 4 && row.item.status > 0"
                @click="evadiThis(row.item.hashid)"
                size="sm"
                variant="primary primary_gradient text-white rounded-pill fw-bolder px-3 fs-6 mx-1"
              >
                Evadi
              </b-button> -->
              </div>
            </template>
          </b-table>
          <p class="mb-0" v-if="prenotazioni !== null && prenotazioni.length == 0">
            Nessuna richiesta qui.
          </p>
          <div class="d-flex">
            <span class="text-info fw-bolder mr-1" v-if="total > 0">{{ fromPage }}-{{ toPage }} di
            </span>
            <span class="text-info fw-bolder"> {{ total }}</span>
          </div>
          <b-pagination v-model="filterSetted.page" pills :total-rows="total" :per-page="perPage" align="center"
            @change="paginationChanged" :size="$root.isMobile ? 'sm' : 'lg'"></b-pagination>
          <h5 class="
              text-prim-grad-1
              fw-bolder
              text-left
              pb-1
              border-bottom border-line-grey
              mt-4
              pt-4
            ">
            Annullamento Rapido
          </h5>
          <p class="text-left text-medium-grey fs-5">
            Funzionalità per annullamento rapido delle prenotazioni di Connessa
            per uno specifico giorno.
          </p>
          <CancelUtility v-on:update-prenotazioni="fetchData" />
        </div>
      </div>

      <b-modal size="lg" centered ref="modal-evasione" title="Evadi Richiesta" hide-footer>
        <div v-if="toEvadere">
          <p class="text-left text-font-grey">
            Numero Richiesta: #{{ toEvadere.numero }} <br />
          </p>
          <p class="text-left text-font-grey">
            Codice Richiesta:
            {{ toEvadere.codice_richiesta }} <br />
          </p>

          <b-form-input name="detail" class="mx-auto shadow_6" v-model="evasione.hash" type="text" hidden>
          </b-form-input>
          <b-form-group label="Aggiungi Annotazione" label-for="detail" class="text-left fs-6 text-secondary">
            <b-form-input name="detail" class="mx-auto shadow_6" v-model="evasione.details" type="text">
            </b-form-input>
          </b-form-group>
          <div class="text-center my-2 pt-4">
            <!-- <b-button
            type="button"
            class="mx-2"
            variant="outline-danger"
            @click="$refs['modal-evasione'].hide()"
            >Annulla</b-button
          > -->
            <b-button type="button" class="mx-2" variant="primary primary_gradient text-white fw-bolder spacing_1"
              @click="evadiThis">Conferma</b-button>
          </div>
        </div>
      </b-modal>
      <b-modal size="xl" centered ref="resev-details" id="resev-details" header-class="pb-0" title="" hide-footer
        scrollable>
        <b-card v-if="selectedRow" header-text-variant="prim-grad-1" header-bg-variant="white" header-tag="h4"
          class="text-left text-font-grey" border-variant="white">
          <template #header>
            <b-row cols="2">
              <b-col>
                <span>Dettaglio richiesta #{{ selectedRow.codice_richiesta }}</span>
                <p style="font-size: 70%" class="mb-0 text-font-grey mt-1">
                  Inoltrata il
                  <strong>{{
                      selectedRow.created_at || moment("DD.MM.YY HH:mm")
                  }}</strong>
                </p>
              </b-col>
              <b-col class="text-right">
                <span class="fs-5">
                  <!-- <strong class="ml-1 text-secondary">Richiesta: </strong> -->
                  <span class="text-prim-grad-1 fw-bolder">{{
                      selectedRow.filled_survey
                        ? selectedRow.filled_survey.survey_name
                        : "teleconsulto"
                  }}</span>
                </span>
                <p style="font-size: 70%" class="mb-0 text-font-grey mt-1">
                  <strong>{{
                      selectedRow.status === 1
                        ? "ricevuto, da confermare"
                        : selectedRow.stato
                  }}</strong>
                </p>
              </b-col>
            </b-row>
          </template>
          <b-card-text>
            <b-container fluid class="px-0">
              <b-row class="">
                <b-col class="col-12 col-md-6 px-0 d-flex flex-column">
                  <h5 class="text-secondary fw-bolder ml-3" style="height: 24px">
                    Cliente
                  </h5>

                  <b-col style="flex: 1" class="col-12 py-2">
                    <div v-if="
                      selectedRow.requested_by &&
                      selectedRow.subjectable &&
                      selectedRow.requested_by.anagrafica.cf !==
                      selectedRow.subjectable.anagrafica.cf
                    " class="mb-0 mt-1">
                      <p class="mb-1">Prenotato da</p>
                    </div>
                    <template v-if="!selectedRow.requested_by">
                      <p>**utenza rimossa**</p>
                    </template>
                    <template v-else>
                      <p class="mb-0 d-flex flex-wrap">
                        <!-- <span class="text-secondary">Richiesto da: </span> -->
                        <span>
                          <b-icon icon="person-fill" variant="secondary" class="mr-2"></b-icon>

                          <strong class="text-capitalize mr-2">{{ selectedRow.requested_by.anagrafica.name }}
                            {{ selectedRow.requested_by.anagrafica.surname }}
                          </strong>
                        </span>

                        <span>
                          <b-icon icon="credit-card-fill" variant="secondary" class="mr-2"></b-icon>
                          <strong class="text-uppercase">{{ selectedRow.requested_by.anagrafica.cf }}
                          </strong>
                        </span>
                      </p>
                      <p class="mb-0 d-flex flex-wrap">
                        <span>
                          <b-icon variant="secondary" class="mr-2" icon="envelope-fill"></b-icon>
                          <strong class="mr-2">{{ selectedRow.requested_by.email }}
                          </strong>
                        </span>
                        <span>
                          <b-icon variant="secondary" class="mr-2" icon="telephone-fill"></b-icon>

                          <strong v-if="
                            selectedRow.requested_by.anagrafica.tel_number
                          ">{{
    selectedRow.requested_by.anagrafica.tel_number
}}
                          </strong>
                          <strong v-else> non inserito </strong>
                        </span>
                      </p>
                      <p class="mb-0">
                        <b-icon icon="house-fill" variant="secondary" class="mr-2"></b-icon>
                        <strong v-if="selectedRow.requested_by.anagrafica.address">{{
                            selectedRow.requested_by.anagrafica.address
                        }}
                        </strong>
                        <strong v-else>non inserito</strong>,
                        <strong class="text-capitalize">{{ selectedRow.requested_by.anagrafica.cap }}
                          {{ selectedRow.requested_by.anagrafica.comune }}
                          ({{ selectedRow.requested_by.anagrafica.provincia }})
                          {{ selectedRow.requested_by.anagrafica.regione }}
                        </strong>
                      </p>
                    </template>
                    <div v-if="
                      selectedRow.requested_by &&
                      selectedRow.subjectable &&
                      selectedRow.requested_by.anagrafica.cf !==
                      selectedRow.subjectable.anagrafica.cf
                    " class="mb-0 mt-3">
                      <p class="mb-1">Per conto di</p>
                      <b-icon icon="person-fill" variant="secondary" class="mr-2"></b-icon>
                      <strong class="text-capitalize" v-if="selectedRow.subjectable.anagrafica">{{
                          selectedRow.subjectable.anagrafica.name
                      }}
                        {{ selectedRow.subjectable.anagrafica.surname }}
                      </strong>
                      <strong v-else class="text-capitalize">{{ selectedRow.subjectable.name }}
                        {{ selectedRow.subjectable.surname }}
                      </strong>
                      <b-icon icon="credit-card-fill" variant="secondary" class="mx-2"></b-icon>
                      <strong class="text-uppercase">{{
                          selectedRow.subjectable.anagrafica
                            ? selectedRow.subjectable.anagrafica.cf
                            : selectedRow.subjectable.cf
                      }}
                      </strong>
                    </div>
                  </b-col>
                </b-col>
                <b-col class="col-12 col-md-6 mt-2 mt-md-0 px-0 d-flex flex-column">
                  <h5 class="text-secondary fw-bolder ml-3">Richiesta</h5>

                  <b-col style="flex: 1" class="d-flex flex-column col-11 py-2">
                    <p class="mb-1 fw-bolder">
                      <strong class="text-secondary">Codice Richiesta: </strong>{{ selectedRow.codice_richiesta }}
                    </p>
                    <p class="mb-1 fw-bolder">
                      <strong class="text-secondary">Numero Richiesta: </strong>#{{ selectedRow.numero }}
                    </p>
                    <p class="mb-1 fw-bolder">
                      <strong class="text-secondary">Giorno e ora: </strong>
                      <strong>{{
                          selectedRow.data_inizio | moment("DD.MM.YY HH:mm")
                      }}</strong>
                      <strong v-if="selectedRow.data_fine">
                        -
                        {{ selectedRow.data_fine | moment("HH:mm") }}</strong>
                      <UpdateReservationDate class="d-inline ml-2 ml-md-0 ml-lg-1" :hash="selectedRow.hashid"
                        v-on:change-requested="handleUpdateRequest" />
                    </p>
                  </b-col>
                </b-col>
              </b-row>
            </b-container>

            <DetailTeleconsulto :tobecompleted="selectedRow.suspended_survey" :dettaglio="selectedRow.orderable"
              :stato="selectedRow.status" :quest="selectedRow.filled_survey" :datainizio="selectedRow.data_inizio"
              :hash="selectedRow.hashid" v-if="selectedRow.tipo_ordine === 'teleconsulto'" v-on:update-request-status="
                handleStatusUpdate(selectedRow, $event)
              " v-on:update-request-response="
  sendOrderResponse(selectedRow, $event)
" />

            <p class="mb-0">
              Data Operazione:
              <strong>{{
                  selectedRow.created_at || moment("DD.MM.YY HH:mm")
              }}</strong>
            </p>
            <!-- <p class="mb-0" v-if="selectedRow.user_notes">
              Note dell'utente:
              <strong>{{ selectedRow.user_notes }}</strong>
            </p>
            <p class="mb-0" v-if="selectedRow.company_notes">
              Note Operatore:
              <strong>{{ selectedRow.company_notes }}</strong>
            </p> -->
            <p class="mb-0 bg-light p-1">
              <strong> Note Operatore </strong>
              <UpdateNotesComponent v-on:note-updated="handleNotesUpdate($event)" class="d__innline"
                :hashid="selectedRow.hashid" :reserdate="selectedRow.data_inizio">
              </UpdateNotesComponent>
              <template v-if="selectedRow.company_notes"><br>
                {{ selectedRow.company_notes }}
              </template>
            </p>
            <p class="mb-0  p-1 bg-light" v-if="selectedRow.user_notes">
              <strong> Note dell'utente:</strong>
              {{ selectedRow.user_notes }}
            </p>
          </b-card-text>
        </b-card>
      </b-modal>
    </div>
  </div>
  <div v-else class="my-4">
    <h4 class="fw-bolder text-danger">
      Per utilizzare le funzionalità di teleconsulto è necessario acquistare
      l'abbonamento .
    </h4>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { reservationService } from "@/_services";
import DetailTeleconsulto from "@/components/prenotazioni/DetailTeleconsulto.vue";
import Calendario from "@/components/prenotazioni/Calendario.vue";
import CancelUtility from "@/components/teleconsulto/CancelUtility.vue";
import UpdateNotesComponent from "@/components/prenotazioni/UpdateNotesComponent.vue";
import UpdateReservationDate from "@/components/prenotazioni/UpdateReservationDate.vue";
export default {
  name: "HomeTeleconsulto",
  components: {
    DetailTeleconsulto,
    Calendario,
    CancelUtility,
    UpdateNotesComponent,
    UpdateReservationDate
  },
  data() {
    return {
      prenotazioni: null,
      fields: [
        { key: "tipo", label: "" },
        { key: "codice_richiesta", label: "C.Richiesta" },
        { key: "data", label: "Data Consulto", sortable: true },
        { key: "utente", label: "Cliente" },
        // { key: "contatti", label: "Contatti" },
        { key: "stato", label: "Stato" },
        { key: "azioni", label: "" },
      ],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      statusPositive: {
        1: "secondary",
        2: "info",
        3: "info",
        4: "primary",
        5: "ter-4",
        6: "ter-4",
      },
      statusNegative: {
        1: "danger",
        2: "ter-2",
        3: "ter-2",
        4: "dark",
      },
      filterOptions: {
        status: ["confermate", "in attesa", "evase", "annullate"],
        service: ["prestazione", "ritiro", "prodotto", "tutte"],
        search: null,
      },
      filterSetted: {
        status: "in attesa",
        service: undefined,
        search: undefined,
        cf: undefined,
        position_ref: 2,
        page: 1,
        take: 10,
        range: "mese",
        indesc: true,
        prestazione: null,
      },
      sortBy: "data",
      takeOptions: [10, 25, 50, 100],
      countPrev: 0,
      count: 0,
      dataToCancel: null,
      typeToCancel: null,
      total: 0,
      fromPage: 0,
      toPage: 0,
      perPage: 0,
      timer: null,
      updatingData: false,
      loading: true,
      evasione: {
        details: null,
        hash: null,
      },
      toEvadere: null,
      titleLabels: {
        prestazione: "Prestazioni",
        ritiro: "Ritiri",
        prodotto: "Ordini",
        tutte: "Tutte",
      },
      selectedRow: null,
      searchByType: 1,
      searchByTypes: [
        { value: 1, text: "cerca per codice fiscale" },
        { value: 2, text: "cerca per codice richiesta" },
      ]
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
    ...mapState("utente", ["user"]),
    ...mapState("company", ["company"]),
  },
  watch: {
    searchByType: function (value) {
      this.filterSetted.cf = null;
      this.filterSetted.search = null;
    },
    filterSetted: {
      handler(prev, post) {
        if (prev.cf) {
          // //console.log("prev.cf", prev.cf);
          this.filterSetted.cf = prev.cf.toUpperCase();
          if (prev.cf.length < 4 && prev.cf.length !== 0) {
            return;
          }
        }
        this.fetchData();
      },
      deep: true,
    },
    $route(val) {
      // //console.log("val route change", val);
      if (
        val.query.scope &&
        this.filterOptions.service.includes(val.query.scope)
      ) {
        // //console.log("val route change", val);
        this.filterSetted.service = val.query.scope;
        this.filterSetted.status = "in attesa";
        this.filterSetted.prestazione = null;
      } else {
        this.filterSetted.service = undefined;
        this.filterSetted.status = "in attesa";
        this.filterSetted.prestazione = null;
      }
    },
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    fetchData() {
      // //console.log("fetching prenotazioni");
      this.loading = true;
      var self = this;
      reservationService
        .fetchPrenotazioni(this.filterSetted)
        .then(function (response) {
          // //console.log("response fetch prenotazioni", response);
          self.prenotazioni = response.data.data.data;
          self.filterSetted.page = response.data.data.current_page;
          self.total = response.data.data.total;
          self.fromPage = response.data.data.from;
          self.toPage = response.data.data.to;
          self.perPage = response.data.data.per_page
            ? response.data.data.per_page
            : self.filterSetted.take;
          if (
            !self.$route.query.scope &&
            self.filterSetted.status == "in attesa" &&
            self.filterSetted.range == null &&
            !self.filterSetted.search &&
            !self.filterSetted.cf
          ) {
            self.count = response.data.data.total;
            var diff = self.count - self.countPrev;
            if (self.countPrev !== 0 && self.countPrev < self.count) {
              self.infoAlert(diff + " nuove richieste");
            }
            self.countPrev = self.count;
          }
          self.updatingData = false;
          self.loading = false;
        })
        .catch(function (error) {
          self.updatingData = false;
          self.errorAlert("non è stato possibile prelevare le richieste");
          // //console.log("error fetch prenotazioni", error);
          self.loading = false;
        });
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    handleStatusUpdate(item, data) {
      // //console.log("item to edit", item);
      // //console.log("data to submit", data);
      var self = this;
      if (!data.confirmed) {
        this.loading = true;
        var form = data.message ? { message: data.message } : {};
        reservationService
          .companyCancelOrReject(form, data.action, item.hashid)
          .then(function (re) {
            self.$bvModal.hide("resev-details");
            window.scrollTo(0, 0);
            self.successAlert("Richiesta aggiornata con successo");
            self.fetchData();
          })
          .catch(function (error) {
            self.$bvModal.hide("resev-details");
            window.scrollTo(0, 0);
            self.errorAlert("non è stato possibile effettuare l'operazione");
            self.loading = false;
          });
      } else {
        this.loading = true;
        var form = data.message ? { message: data.message } : {};
        reservationService
          .companyConfirmOrder(item.hashid)
          .then(function (re) {
            self.$bvModal.hide("resev-details");
            window.scrollTo(0, 0);
            self.successAlert("Richiesta confermata con successo");
            self.fetchData();
          })
          .catch(function (error) {
            self.$bvModal.hide("resev-details");
            window.scrollTo(0, 0);
            self.errorAlert("non è stato possibile effettuare l'operazione");
            self.loading = false;
          });
      }
      // if (data.action == "reject") {
      //   //do reject
      // }
      // if (data.action == "cancel") {
      //   //do Cancel
      // }
    },
    evadiThis(hash = null) {
      // //console.log("richiesta da evadere ", hash);
      hash = this.evasione.hash;
      var self = this;
      reservationService
        .closeOrder(this.evasione)
        .then(function (response) {
          self.$refs["modal-evasione"].hide();
          self.evasione.details = null;
          self.evasione.hash = null;
          window.scrollTo(0, 0);
          self.successAlert("prenotazione evasa con successo");
          // //console.log("response fetch prenotazioni", response);
          self.fetchData();
        })
        .catch(function (error) {
          self.$refs["modal-evasione"].hide();
          window.scrollTo(0, 0);
          self.errorAlert("non è stato possibile evadere la prenotazione");
          // //console.log("error fetch prenotazioni", error);
        });
      //call evadi
      //then alert success/error
      //then fetch
    },
    openEvadi(item) {
      this.toEvadere = item;
      this.evasione.hash = item.hashid;
      this.$refs["modal-evasione"].show();
    },
    showDetail(item) {
      this.selectedRow = item;
      this.$bvModal.show("resev-details");
      // //console.log("pagination changed");
    },
    paginationChanged() { },
    sendOrderResponse(item, data) {
      // //console.log(item);
      // //console.log("data to update", data);
      var form = {
        hashid: item.hashid,
        company_notes: data.company_notes,
        data_inizio: data.newDateTime,
        order: data.detail,
        status: 5,
      };
      // //console.log(form);
      var self = this;
      reservationService
        .updateReservation(form)
        .then(function (response) {
          // //console.log(response);
          self.$bvModal.hide("resev-details");
          window.scrollTo(0, 0);
          self.successAlert("risposta inviata con successo");
          self.fetchData();
        })
        .catch(function (error) {
          // console.log(error);
          self.$bvModal.hide("resev-details");
          window.scrollTo(0, 0);
          self.errorAlert("non è stato possibile inviare la risposta");
        });
    },
    handleNotesUpdate(data) {
      this.selectedRow.company_notes = data.company_notes
    },
    handleUpdateRequest() {
      this.fetchData();
      this.$bvModal.hide("resev-details");
      this.successAlert("richiesta inviata con successo");
    }
  },
  created() {
    // //console.log("created prenotazioni", reservationService);
    if (
      this.$route.query.scope &&
      this.filterOptions.service.includes(this.$route.query.scope)
    ) {
      // //console.log("changing scope");
      this.filterSetted.service = this.$route.query.scope;
    }
    this.fetchData();
  },
  mounted() {
    this.timer = setInterval(() => {
      if (this.$refs.dett == undefined) {
        // //console.log("updating...");
        this.updatingData = true;
        this.fetchData();
      }
    }, 60000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },
};
</script>
<style>
thead {
  border-bottom: 1px solid #e0ecde !important;
}
</style>
