<template>
  <!-- <div class="fix__top__right"> -->
  <transition-group name="slideside" mode="out-in">
    <b-row key="nm-closed" v-if="!isOpen" class="fix__top__right right__1">
      <b-iconstack font-scale="2.5" class="point" @click="openNotifications">
        <b-icon stacked icon="circle-fill" variant="primary"></b-icon>
        <b-icon stacked icon="bell-fill" scale="0.6" variant="white"></b-icon>
        <b-icon stacked icon="circle" variant="white"></b-icon>
      </b-iconstack>
      <b-badge
        v-show="msgCount > 0"
        class="align-self-start notification__count"
        variant="danger"
        >{{ msgCount }}
      </b-badge>
    </b-row>
    <b-row
      v-else
      cols="1"
      class="bg__w py-2 px-4 fix__top__right right__0 align-content-start"
      key="nm-opened"
    >
      <b-col class="text-right my-2">
        <b-icon
          class="point"
          variant="secondary"
          icon="x"
          font-scale="1.7"
          @click="isOpen = false"
        ></b-icon>
      </b-col>
      <b-col
        v-for="(msg, ind) in messages"
        :key="ind"
        class="my-2 text-left bg-line-grey pt-3"
      >
        <h5 class="text-secondary">
          <strong>{{ msg.title }}</strong>
        </h5>
        <p class="text-font-grey">{{ msg.body }}</p>
      </b-col>
      <b-col
        v-for="(msg, ind) in readMessages"
        :key="'r-' + ind"
        class="my-2 text-left pt-3"
      >
        <h5 class="text-secondary">
          <strong>{{ msg.title }}</strong>
        </h5>
        <p class="text-font-grey">{{ msg.body }}</p>
      </b-col>
      <template v-if="noTokenMsg">
        <b-col key="empty-list" class="my-2 text-left pt-3">
          <h5 class="text-secondary"></h5>
          <p class="text-font-grey">
            Per ricevere notifiche è necessario autorizzare la ricezione di
            notifiche push
          </p>
        </b-col>
      </template>
      <template v-else-if="msgCount == 0 && readMessages.length == 0">
        <b-col key="empty-list" class="my-2 text-left pt-3">
          <h5 class="text-secondary"></h5>
          <p class="text-font-grey">Nessuna nuova notifica</p>
        </b-col>
      </template>
    </b-row>
  </transition-group>
  <!-- </div> -->
</template>
<script>
import { mapState, mapActions } from "vuex";
import { getAuth, onAuthStateChanged, getRedirectResult } from "firebase/auth";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { cittadinoService } from "@/_services";
export default {
  name: "NotificationMenu",
  data() {
    return {
      token: null,
      messages: [],
      readMessages: [],
      isOpen: false,
      noTokenMsg: false,
      attempts: 0,
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
    ...mapState("utente", ["status", "user"]),
    msgCount: function () {
      return this.messages.length;
    },
  },
  watch: {
    token: function (now, prev) {
      // //console.log("token changed");
      // //console.log("prev", prev);
      // //console.log("now", now);
      if (now) {
        this.storeToken();
      }
    },
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    openNotifications() {
      // //console.log(this.token);
      this.isOpen = true;
      var self = this;
      setTimeout(function () {
        // self.readMessages.push(...self.messages);
        var prev = self.readMessages;
        self.readMessages = self.messages.concat(prev);
        self.messages = [];
      }, 1000);
    },
    firebaseAuth() {
      const auth = getAuth(this.$root.firebaseApp);
      onAuthStateChanged(auth, (user) => {
        // //console.log("auth", auth);
        // //console.log("user", user);
        //console.log("onAuthStateChanged", user);
      });
    },
    storeToken() {
      const form = {
        token: this.token,
        client: "web",
      };
      const self = this;
      cittadinoService
        .storeFirebaseToken(form)
        .then(function (res) {
          //console.log("store ok");
        })
        .catch(function (err) {
          console.log(err);
          self.initPushWithTimeout();
        });
    },
    messagingInit() {
      const messaging = getMessaging();
      var vapkey = process.env.VUE_APP_FBS_VAPIDKEY;
      var self = this;
      getToken(messaging, {
        vapidKey: vapkey,
      })
        .then((currentToken) => {
          if (currentToken) {
            //console.log("current TOken", currentToken);
            self.token = currentToken;
            self.noTokenMsg = false;
            // Send the token to your server and update the UI if necessary
            // ...
          } else {
            // Show permission request UI
            console.log(
              "No registration token available. Request permission to generate one."
            );
            // ...
          }
        })
        .catch((err) => {
          self.noTokenMsg = true;
          //console.log("An error occurred while retrieving token. ", err);
          // ...
        });
      // onMessage(messaging, (payload) => {
      //   //console.log("Message received in onMessage component fbs. ", payload);
      //   // if (payload.data) {
      //   //   self.addMessage(payload.data);
      //   //   // // self.messages.push(payload.data);
      //   //   // self.messages.splice(0, 0, payload.data);
      //   //   // self.infoAlert("Nuova notifica");
      //   // }
      // });
    },
    addMessage(data) {
      this.messages.splice(0, 0, data);
      // this.infoAlert("Nuova notifica");
    },
    initPushWithTimeout() {
      if (this.attempts > 3) {
        return false;
      }
      var self = this;
      setTimeout(function () {
        self.attempt++;
        self.messagingInit();
      }, 3000);
    },
  },
  created() {
    // var self = this;
    // navigator.serviceWorker.register('/firebase-messaging-sw.js')
    //   .then((registration) => {
    //     console.log("registration", registration)
    //     self.$root.swRegistration = registration
    //     console.log("registration", self.$root.swRegistration)
    //   }).catch(err => {
    //     console.log(err)
    //   })
    // console.log(this.$root.firebaseApp);
    this.firebaseAuth();
  },
  mounted() {
    this.initPushWithTimeout();
    navigator.serviceWorker.addEventListener("message", (event) => {
      var payload = event.data;
      if (event.data.data) {
        payload = event.data.data;
      } else if (event.data.notification) {
        payload = event.data.notification;
      }
      this.addMessage(payload);
      //console.log("Push Notification Recieved message event in main", event); // refresh code goes here
    });
  },
};
</script>
<style>
.fix__top__right {
  position: absolute;
  top: 1rem;
  /* right: 1rem; */
  z-index: 999999;
}

.right__1 {
  right: 1rem;
}

.right__0 {
  right: 0;
  /* top: 0 !important; */
  /* height: calc(100% - 2rem); */
  height: 100%;
  box-shadow: -2px 10px 3px #9f9f9f36;
  overflow-y: scroll;
}

.notification__count {
  margin-left: -1rem;
}

.bg__w {
  background-color: #fffffff0;
}
</style>
