<template>
  <div v-if="familiare" class="general_border shadow_6 pt-4 pb-3 px-2 point border min__30 my-2" :class="
    isSelected ? 'border-sec-2 bg-sec-3' : 'border-light-panel bg-white'
  " @click="showDetails">
    <p>
      <b-iconstack font-scale="5" class="rounded-circle">
        <b-icon class="" stacked icon="circle-fill" variant="prim-grad-1-trans"></b-icon>
        <b-icon stacked icon="person" scale="0.5" variant="secondary"></b-icon>
        <!-- <b-icon stacked icon="circle" variant="danger"></b-icon> -->
      </b-iconstack>
      <!-- <b-icon font-scale="2.5" icon="person"></b-icon> -->
    </p>
    <p class="mb-0 fs-5 pt-1">
      <strong class="">{{ familiare.name }} {{ familiare.surname }}</strong>
    </p>
    <p class="mb-0 mt-1 fs-5 text-font-grey text-capitalize">
      {{ familiare.relationship }}
    </p>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "FamiliareCard",
  props: ["familiare"],
  computed: {
    ...mapState("utente", ["status", "user"]),
  },
  data() {
    return {
      isSelected: false,
    };
  },
  methods: {
    showDetails() {
      // //console.log(this.familiare);
      this.$emit("select-familiare", this.familiare);
    },
  },
};
</script>
<style>
.min__30 {
  min-height: 155px;
}
</style>