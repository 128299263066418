<template>
  <div v-if="detail">
    <div class="d-flex justify-content-center"></div>
    <div class="mb-0" v-if="detail">
      <br />
      <p class="mb-0" v-if="detail.nre">
        NRE:
        <strong>{{ detail.nre }} </strong>
      </p>
      <p class="mb-0" v-if="detail.rev">
        REV:
        <strong>{{ detail.rev }} </strong> <br />
        <strong class="text-uppercase">Ricorda di portare con te il pin della ricetta quando vai a ritirare
          l'ordine.</strong>
      </p>
      <p v-if="detail.prescription_required !== null">
        Prescrizione
        <strong>{{
            detail.prescription_required == 1 ? "necessaria" : "non necessaria"
        }}
        </strong>
      </p>

      <p v-if="detail.available !== null">
        I prodotti richiesti
        <strong>{{
            detail.available == 1 ? "sono disponibili" : "non sono disponibili"
        }}
        </strong>
      </p>
      <div v-if="detail.chart.length > 0">
        <h6 class="fw-bolder">Prodotti richiesti:</h6>
        <p v-for="(item, ind) in detail.chart" :key="ind">
          <strong class="text-primary">. {{ item.nome }}</strong>
          <span class="text-primary">, {{ item.quantity }} {{ item.quantity > 1 ? " confezioni " : " confezione "
          }}</span>
          <span class="text-primary" v-if="item.generic"> - generico </span>
          <br />
          <span v-if="item.prescription_required !== null">
            Prescrizione
            <strong>{{
                item.prescription_required == 1
                  ? "necessaria"
                  : "non necessaria"
            }}
            </strong>
            <br />
          </span>
          <span v-if="item.available !== null">
            Il prodotto richiesto
            <strong>{{ item.available == 1 ? "è disponibile" : "non è disponibile" }}
            </strong>
          </span>
          <span v-if="item.nre">
            NRE:
            <strong>{{ item.nre }} </strong>
          </span>
          <span v-if="item.rev">
            REV:
            <strong>{{ item.rev }} </strong>
          </span>
        </p>
      </div>
      <p v-if="detail.allegato !== null && picLink">
        <strong>Allegato</strong><br />
        <b-img ref="allegato" :src="picLink" no-body fluid-grow width="100%" height="100%" />
      </p>
      <p v-if="detail.details">
        Dettagli:
        <strong>{{ detail.details }} </strong>
      </p>
      <div class="d-flex flex-wrap my-2" v-if="Math.abs(stato) !== 4 && checkValidity">
        <template v-if="stato === 5">
          <b-button @click="confirmRequest" type="button" variant="primary primary_gradient  my-1 fw-bolder text-white"
            class="col-12 my-1 col-lg-6 my-lg-0">Conferma Ordine</b-button>
          <b-button @click="setAction('reject')" type="button" variant="outline-danger fw-bolder"
            class="col-12 my-1 col-lg-6 my-lg-0">
            Rifiuta Modifiche</b-button>
        </template>
        <b-button v-else-if="stato > 1" @click="setAction('cancel')" type="button"
          variant="outline-danger my-1 fw-bolder">
          <b-spinner variant="primary" label="loading" v-if="loading"></b-spinner>
          <span v-else> Annulla Ordine</span>
        </b-button>
      </div>
    </div>
    <b-modal size="xl" hide-footer centered ref="modal-confirm" title="Conferma Annullamento">
      <p class="mb-0">Sei sicuro di voler annullare questo appuntamento?</p>
      <div>
        <b-form-group label="Allega messaggio:" v-slot="{ ariaDescribedby }">
          <b-form-radio class="my-1" v-model="message" :aria-describedby="ariaDescribedby" :value="1">"{{ msgOptions[1]
          }}"</b-form-radio>
          <b-form-radio class="my-1" v-model="message" :aria-describedby="ariaDescribedby" :value="2">"{{ msgOptions[2]
          }}"</b-form-radio>
          <b-form-radio v-model="message" :aria-describedby="ariaDescribedby" :value="6">nessun messaggio</b-form-radio>
          <b-form-radio v-model="message" :aria-describedby="ariaDescribedby" :value="5">scrivi messaggio</b-form-radio>
          <b-form-input v-if="message == 5" class="simple__input border-top-0 border-left-0 border-right-0"
            name="freeText" type="text" placeholder="scrivi messaggio..." v-model="freeText"></b-form-input>
        </b-form-group>
      </div>
      <div class="d-flex justify-content-end">
        <b-button @click="$refs['modal-confirm'].hide()" type="button" variant="info fw-bolder ">No, chiudi</b-button>
        <b-button @click="cancelRequest" type="button" variant="outline-danger fw-bolder ml-2">Sì, procedi</b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import * as moment from "moment/moment";
import { reservationService } from "@/_services";
export default {
  name: "UserDetailProdotto",
  props: ["dettaglio", "stato", "datainizio"],
  data() {
    return {
      picLink: null,
      message: 6,
      msgOptions: {
        1: "Non sono più interessato.",
        2: "Non posso rispettare l'impegno.",
        3: "",
        4: "",
        5: null,
        6: null,
      },
      freeText: null,
      actionName: null,
      loading: false,
      detail: this.dettaglio,
      editBtn: false,
    };
  },
  computed: {
    checkValidity: function () {
      // if (this.stato !== 5) {
      var data = moment(this.datainizio);
      return moment().isBefore(data);
      // }
      // return false;
    },
  },
  watch: {
    dettaglio: {
      handler(val) {
        this.$nextTick(function () {
          this.detail = val;
          if (val.resource_id !== null) {
            this.getAllegato();
          }
        });
      },
      deep: true,
    },
    picLink(val) {
      //
    },
  },
  methods: {
    getAllegato() {
      var self = this;
      var endpoint =
        this.detail.allegato.full_path + "/" + this.detail.allegato.hashid;
      reservationService
        .getAllegato(endpoint)
        .then(function (res) {
          self.picLink = window.URL.createObjectURL(new Blob([res.data]));
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    confirmRequest() {
      //console.log(this.detail.hashid);
      var data = {
        confirmed: true,
        action: "confirm",
      };
      this.$emit("update-request-status", data);
    },
    setAction(action) {
      this.actionName = action;
      this.$refs["modal-confirm"].show();
    },
    cancelRequest() {
      this.loading = true;
      var data = {
        confirmed: false,
        message: this.freeText,
        action: this.actionName,
      };
      //console.log(data);
      if (this.message !== null && this.message !== 5) {
        data = {
          confirmed: false,
          message: this.msgOptions[this.message],
          action: this.actionName,
        };
      }
      if (this.message === 6) {
        data = {
          confirmed: false,
          message: null,
          action: this.actionName,
        };
      }
      this.$refs["modal-confirm"].hide();
      this.$emit("update-request-status", data);
    },
  },
  created() {
    //console.log("before destroy", this.dettaglio);
    if (this.detail && this.detail.allegato !== null) {
      this.getAllegato();
    }
  },
  // beforeDestroy() {
  //   //console.log("before destroy", this.$refs.allegato);
  //   this.picLink = null;
  //   this.detail = null;
  //   if (this.$refs.allegato) {
  //     this.$refs.allegato.src = null;
  //   }
  // },
};
</script>