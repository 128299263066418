<template>
  <div class="pb-4">
    <b-container class="pt-2">
      <b-row cols="1" v-if="!subject">
        <h6
          class="mt-2 text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
        >
          Seleziona farmacia
        </h6>
        <div
          v-for="(azienda, ind) in user.my_circle"
          :key="ind"
          class="my-2 col-sm-12 col-md-3"
        >
          <CompanyCard
            :azienda="azienda"
            :iselected="enlight(azienda)"
            v-on:company-selection="selectCompany($event)"
          />
        </div>
        <p class="text-danger mt-2">{{ errorMsg }}</p>
      </b-row>
      <b-row
        cols="1"
        v-if="showDisponibilita"
        class="my-2"
        ref="calendarchoice"
      >
        <h6
          class="mt-2 text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
        >
          Scegli data ed ora appuntamento
        </h6>
      </b-row>
      <b-row cols="1" v-if="showDisponibilita && calendarChoice" class="my-2">
        <b-col class="text-center">
          <div class="d-flex justify-content-between">
            <b-button
              variant="outline-info"
              :size="$root.isMobile ? 'sm' : ''"
              type="button"
              @click="setPrev"
              :disabled="checkPrecedente"
            >
              <b-icon icon="chevron-double-left"></b-icon
              >{{ !$root.isMobile ? "Precedente" : "" }}
            </b-button>
            <h5 class="text-capitalize">
              {{ start | moment("D MMM") }} - {{ end | moment("D MMM") }}
            </h5>
            <b-button
              variant="outline-info"
              type="button"
              :size="$root.isMobile ? 'sm' : ''"
              @click="setPost"
              >{{ !$root.isMobile ? "Successiva" : "" }}
              <b-icon icon="chevron-double-right"></b-icon>
            </b-button>
          </div>
          <b-table
            borderless
            responsive
            table-variant="white text-info"
            :fields="fields"
            :items="disponibilita"
            foot-clone
            small
            show-empty
          >
            <template #cell(1)="row">
              <p
                @click="selectDate(1, slot)"
                class="general_border point border border-info slot__tm"
                v-for="slot in row.item['1']"
                :key="slot"
                :class="
                  checkIfSelected(1, slot)
                    ? 'bg-info text-white'
                    : 'bg-white text-info'
                "
              >
                <strong class="">{{ slot }}</strong>
              </p>
            </template>
            <template #cell(2)="row">
              <p
                @click="selectDate(2, slot)"
                class="general_border point border border-info slot__tm"
                v-for="slot in row.item['2']"
                :key="slot"
                :class="
                  checkIfSelected(2, slot)
                    ? 'bg-info text-white'
                    : 'bg-white text-info'
                "
              >
                <strong class="">{{ slot }}</strong>
              </p>
            </template>
            <template #cell(3)="row">
              <p
                @click="selectDate(3, slot)"
                class="general_border point border border-info slot__tm"
                v-for="slot in row.item['3']"
                :key="slot"
                :class="
                  checkIfSelected(3, slot)
                    ? 'bg-info text-white'
                    : 'bg-white text-info'
                "
              >
                <strong class="">{{ slot }}</strong>
              </p>
            </template>
            <template #cell(4)="row">
              <p
                @click="selectDate(4, slot)"
                class="general_border point border border-info slot__tm"
                v-for="slot in row.item['4']"
                :key="slot"
                :class="
                  checkIfSelected(4, slot)
                    ? 'bg-info text-white'
                    : 'bg-white text-info'
                "
              >
                <strong class="">{{ slot }}</strong>
              </p>
            </template>
            <template #cell(5)="row">
              <p
                @click="selectDate(5, slot)"
                class="general_border point border border-info slot__tm"
                v-for="slot in row.item['5']"
                :key="slot"
                :class="
                  checkIfSelected(5, slot)
                    ? 'bg-info text-white'
                    : 'bg-white text-info'
                "
              >
                <strong class="">{{ slot }}</strong>
              </p>
            </template>
            <template #cell(6)="row">
              <p
                @click="selectDate(6, slot)"
                class="general_border point border border-info slot__tm"
                v-for="slot in row.item['6']"
                :key="slot"
                :class="
                  checkIfSelected(6, slot)
                    ? 'bg-info text-white'
                    : 'bg-white text-info'
                "
              >
                <strong class="">{{ slot }}</strong>
              </p>
            </template>
            <template #cell(7)="row">
              <p
                @click="selectDate(7, slot)"
                class="general_border point border border-info slot__tm"
                v-for="slot in row.item['7']"
                :key="slot"
                :class="
                  checkIfSelected(7, slot)
                    ? 'bg-info text-white'
                    : 'bg-white text-info'
                "
              >
                <strong class="">{{ slot }}</strong>
              </p>
            </template>
            <template #empty="scope">
              <p class="fw-bolder text-secondary" v-if="scope">
                Nessuna disponibilità per questa settimana. Prova a verificare
                le disponibilità per le prossime settimane.
              </p>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row cols="1" v-if="form.data_inizio" class="my-2">
        <h6
          class="mt-2 text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
        >
          Conferma
        </h6>
        <b-col>
          <div class="mt-2">
            <b-row v-if="subject">
              <b-form-group class="text-left fs-6 text-secondary">
                <label for="prescription_required">
                  <strong> {{ therapy.product_name }}</strong></label
                >
                <b-form-checkbox
                  v-model="prods.prescription_required"
                  name="prescription_required"
                  :value="true"
                  :unchecked-value="false"
                  class="mx-auto col-12 fw-bolder"
                  >Prescrizione Necessaria</b-form-checkbox
                >
              </b-form-group>
              <b-row align-v="center" class="mt-1">
                <label for="qta" class="mt-2 mx-2">
                  <strong class="d__innline"> N° confezioni:</strong></label
                >
                <b-form-group class="text-left fs-6 text-secondary">
                  <b-form-input
                    class="text-left shadow_6"
                    type="number"
                    name="qta"
                    :min="1"
                    :step="1"
                    v-model="prods.quantity"
                  >
                  </b-form-input>
                </b-form-group>
              </b-row>
            </b-row>
            <div v-else>
              <p class="text-left mb-0">
                Oggetto del ritiro:
                <br />
                <strong> {{ therapy.product_name }}</strong>
                <b-row align-v="end">
                  <b-col cols="5" md="3">
                    <b-form-group label="Quantità:">
                      <b-form-input
                        class="text-left shadow_6"
                        type="number"
                        :min="1"
                        :step="1"
                        v-model="prods.quantity"
                      >
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="3" md="2">
                    <b-form-group class="align-items-center" label="">
                      <b-form-checkbox
                        v-model="prods.generic"
                        :name="'generico' + kk"
                        :value="true"
                        :unchecked-value="false"
                      >
                        Generico
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                </b-row>
              </p>
              <p class="text-left text-capitalize mb-0">
                Presso:<strong> {{ selectedCompany.ff_denominazione }}</strong>
                <span
                  >, {{ selectedCompany.indirizzo }}
                  {{ selectedCompany.frazione }}
                  {{ selectedCompany.comune }}</span
                >
              </p>
            </div>
            <p class="text-left">
              Data ed ora selezionate:
              <strong>
                {{ form.data_inizio | moment("D MMM YYYY HH:mm") }}</strong
              >
            </p>
            <b-form-group
              v-if="!subject"
              label="Se vuoi, aggiungi una nota per l'operatore"
              label-for="subtype"
              class="text-left fs-6 text-secondary"
            >
              <b-form-input
                name="subtype"
                class="mx-auto shadow_6"
                v-model="form.user_notes"
                required
              >
              </b-form-input>
            </b-form-group>
            <b-form-group
              v-else
              label="Se vuoi, aggiungi una nota per il paziente"
              label-for="subtype"
              class="text-left fs-6 text-secondary"
            >
              <b-form-input
                name="subtype"
                class="mx-auto shadow_6"
                v-model="form.company_notes"
                required
              >
              </b-form-input>
            </b-form-group>
          </div>

          <b-col class="my-3 px-0 px-md-2" v-if="!subject">
            <p class="text-left fw-bolder">
              Per procedere alla conferma dell'ordine è necessario
              <a
                target="_blank"
                class="text-underlined"
                :href="informativa[form.type].url"
              >
                <u> leggere ed accettare l'informativa</u></a
              >.
            </p>
            <b-form-checkbox
              class="text-font-light-grey"
              name="data_no_consense"
              :value="false"
              :unchecked-value="false"
              v-model="informAccepted"
              :state="false"
            >
              Non do il mio consenso
            </b-form-checkbox>
            <b-form-checkbox
              class="text-font-light-grey"
              name="data_consense"
              :value="true"
              :unchecked-value="false"
              v-model="informAccepted"
              :state="informAccepted == true"
            >
              Ho consegnato/comunicato l'informativa al cittadino
            </b-form-checkbox>
          </b-col>
        </b-col>
        <b-col ref="orderConfirm" class="text-center">
          <b-button
            variant="outline-danger"
            class="m-2"
            @click="$emit('reset-operation')"
            >Annulla</b-button
          >
          <b-button
            variant="primary text-white primary_gradient fw-bolder"
            class="m-2"
            @click="submitRequest"
            v-if="informAccepted"
          >
            <span v-if="!sending">Conferma ordine</span>
            <b-spinner v-else small></b-spinner>
          </b-button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import * as moment from "moment/moment";
import { mapState, mapActions } from "vuex";
import { reservationService, cittadinoService } from "@/_services";
import CompanyCard from "@/components/prenotazioni/CompanyCard.vue";
import SearchCompany from "@/components/home/SearchCompany.vue";
export default {
  name: "NuovaPrenotazioneProdotto",
  props: ["subject", "therapy"],
  components: { CompanyCard, SearchCompany },
  computed: {
    checkPrecedente: function () {
      var today = moment(this.today).week();
      var start = moment(this.filters.from, "D/M/YY").week();
      return start <= today;
    },
    ...mapState({
      alert: (state) => state.alert,
    }),
    ...mapState("utente", ["status", "user"]),
    // ...mapState("company", ["company"]),
  },
  watch: {
    form: {
      handler(val) {
        if (val.data_inizio !== null) {
          this.$nextTick(function () {
            this.$refs["orderConfirm"].scrollIntoView();
          });
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      form: {
        tipologia: "ordine",
        type: "prodotto",
        subject_id: null,
        subject_type: "user_in_app",
        company_id: null,
        service_id: null,
        data_inizio: null,
        data_fine: null,
        company_notes: null,
        user_notes: null,
        order: {
          nre: null,
          resource_id: null,
          rev: null,
          products: [],
        },
      },
      prods: null,
      details: null,
      selectedCompany: null,
      start: null,
      end: null,
      month: null,
      today: null,
      fields: [],
      disponibilita: null,
      selectedSlot: null,
      selectedCol: null,
      filters: {
        from: null,
        to: null,
      },
      showDisponibilita: false,
      sending: false,
      calendarChoice: true,
      reservationConfirmed: false,
      errorMsg: null,
      informAccepted: false,
      informativa: {
        prodotto: {
          text: " informativa prodotto",
          url: process.env.VUE_APP_LINK_INFO_PRENOTAZIONE,
        },
        prestazione: {
          text: " informativa prestazione",
          url: process.env.VUE_APP_LINK_INFO_PRENOTAZIONE,
        },
        teleconsulto: {
          text: " informativa teleconsulto",
          url: process.env.VUE_APP_LINK_INFO_PRENOTAZIONE,
        },
      },
    };
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    ...mapActions("utente", ["me"]),
    enlight(comp) {
      return this.selectedCompany && comp.hashid === this.selectedCompany.hashid
        ? true
        : false;
    },
    validateService() {
      this.form.service_id = this.selectedCompany.servizi_attivi["facile"];
      if (this.selectedCompany.servizi_attivi["facile"] == undefined) {
        window.scrollTo(0, 0);
        this.errorAlert("La farmacia non ha attivato il servizio.");
      } else if (
        this.selectedCompany.servizi_attivi_orari["facile"].length == 0
      ) {
        window.scrollTo(0, 0);
        this.errorAlert(
          "La farmacia non ha impostato gli orari di disponibilità per il servizio."
        );
      }
    },
    selectCompany(data) {
      this.errorMsg = null;
      this.selectedCompany = data;
      this.showDisponibilita = false;
      this.form.data_inizio = null;
      this.selectedSlot = null;
      this.selectedCol = null;
      this.form.company_id = data.hashid;
      this.form.service_id = data.servizi_attivi["facile"];
      if (data.servizi_attivi["facile"] == undefined) {
        this.showSurvey = false;
        this.waitForInput = true;
        window.scrollTo(0, 0);
        this.errorAlert("La farmacia selezionata non ha attivato il servizio.");
      } else if (
        this.form.type === "prodotto" &&
        data.servizi_attivi_orari["facile"].length == 0
      ) {
        this.showSurvey = false;
        this.waitForInput = true;
        window.scrollTo(0, 0);
        this.errorAlert(
          "La farmacia selezionata non ha impostato gli orari di disponibilità per il servizio."
        );
      } else {
        this.setupCalendar();
      }
    },
    formatDisponibilitaService() {
      var data = {
        hash: this.form.service_id,
        company: this.form.company_id,
        params: this.filters,
      };
      var self = this;
      reservationService
        .disponibilitaServizio(data)
        .then(function (res) {
          self.disponibilita = [res.data.data];
          if (res.data.data.length == 0) {
            this.errorMsg =
              "Nessuna disponibilità per la settimana e la prestazione scelta";
          }
        })
        .catch(function (error) {
          self.disponibilita = [];
          this.errorMsg =
            "Non è stato possibile trovare disponibilità per questo servizio";
        });
    },
    setPrev() {
      this.start.subtract(1, "weeks");
      this.end.subtract(1, "weeks");
      this.filters.from = moment(this.start).startOf("week").format("D/M/YY");
      this.filters.to = moment(this.end).endOf("week").format("D/M/YY");
      this.selectedSlot = null;
      this.selectedCol = null;
      this.setupCalendar();
    },
    setPost() {
      this.start.add(1, "weeks");
      this.end.add(1, "weeks");
      this.filters.from = moment(this.start).startOf("week").format("D/M/YY");
      this.filters.to = moment(this.end).endOf("week").format("D/M/YY");
      this.selectedSlot = null;
      this.selectedCol = null;
      this.setupCalendar();
    },
    setupCalendar() {
      this.selectedSlot = null;
      this.selectedCol = null;
      var fields = [];
      for (let x = 0; x <= 6; x++) {
        let startDay = moment(this.start);
        let todayDay = moment(this.today).format("ddd DD/M");
        let date = startDay.add(x, "days").format("ddd DD/M");
        let variant = undefined;
        if (todayDay === date) {
          variant = "sec-4";
        }
        let chiave = x + 1;
        let newField = {
          key: chiave.toString(),
          label: date,
          variant: variant,
        };
        fields.push(newField);
      }
      this.fields = fields;

      this.formatDisponibilitaService();

      this.showDisponibilita = true;
    },
    selectDate(col, slot) {
      this.selectedCol = col;
      this.selectedSlot = slot;
      let startDay = moment(this.start);
      let date = startDay.add(col - 1, "days").format("ddd DD/MM/YYYY");
      date += " " + slot;
      this.form.data_inizio = moment(date, "ddd DD/MM/YYYY HH:mm");
      if (this.isTc) {
        this.getDuration();
      }
    },
    checkIfSelected(col, slot) {
      if (!this.selectedCol || !this.selectedSlot) {
        return false;
      }
      if (this.selectedCol == col && this.selectedSlot == slot) {
        return true;
      }
    },
    submitRequest() {
      // this.$emit("confirm-operation", this.form);
      this.form.order.products.push(this.prods);
      this.sending = true;
      var order = this.form;
      var dataF = this.form.data_inizio;
      order.data_inizio = dataF.format("YYYY-MM-DD HH:mm");
      var self = this;
      reservationService
        .storeReservation(order)
        .then(function (res) {
          // window.scrollTo(0, 0);
          // self.successAlert("Prenotazione inviata con successo!");
          // self.newReservation = res.data.data;
          // self.reservationConfirmed = true;
          self.sending = false;
          var resData = {
            ok: true,
            msg: "Prenotazione inviata con successo!",
          };
          self.$emit("confirm-operation", resData);
        })
        .catch(function (error) {
          var resData = {
            ok: false,
            msg: "Non è stato possibile inviare l'ordine",
          };
          self.$emit("confirm-operation", resData);
          self.sending = false;
        });
    },
    downloadPdf() {
      const hash = this.newReservation.hashid;
      const fName = this.newReservation.codice_richiesta;
      var self = this;
      reservationService
        .scaricaPromemoria(hash)
        .then(function (response) {
          let blob = new Blob([response.data]);
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = fName + ".pdf";
          link.click();
        })
        .catch(function (error) {
          self.loading = false;
          window.scrollTo(0, 0);
          self.errorAlert("non è stato possibile scaricare il promemoria");
        });
    },
    downloadCalendar() {
      const hash = this.newReservation.hashid;
      const fName = this.newReservation.codice_richiesta;
      var self = this;
      reservationService
        .scaricaCalendar(hash)
        .then(function (response) {
          let blob = new Blob([response.data]);
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = fName + ".ics";
          link.click();
        })
        .catch(function (error) {
          self.loading = false;
          window.scrollTo(0, 0);
          self.errorAlert("non è stato possibile scaricare il promemoria");
        });
    },
    getDuration() {
      var formData = {
        hash: this.form.service_id,
        filters: {
          company: this.form.company_id,
          day: this.selectedCol,
        },
      };
      var self = this;
      reservationService
        .getServiceOpeningForDay(formData)
        .then(function (res) {
          self.durata = res.data.data[0].interval;
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          self.errorAlert("Si è verificato un errore.");
        });
    },
    formatFullDate(date) {
      return moment(date).format("dddd DD MMM YYYY HH:mm");
    },
  },
  created() {
    // this.fetchUser();
    this.start = moment().startOf("week");
    this.end = moment().endOf("week");
    this.today = moment();
    this.month = moment().month();
    this.filters.from = moment().startOf("week").format("D/M/YY");
    this.filters.to = moment().endOf("week").format("D/M/YY");
    if (this.subject) {
      this.selectedCompany = this.user.company;
      this.form.company_id = this.user.company.hashid;
      this.form.subject_id = this.subject.hashid;
      this.form.subject_type = this.subject.type;
      this.informAccepted = true;
      this.selectCompany(this.user.company);
    } else if (this.user.farmacia_preferita) {
      this.selectedCompany = this.user.farmacia_preferita;
      this.form.company_id = this.user.farmacia_preferita.hashid;
      this.form.subject_id = this.user.hashid;
      this.selectCompany(this.user.farmacia_preferita);
    }
  },
  mounted() {
    if (this.therapy) {
      this.prods = {
        Aic: this.therapy.product_aic,
        Nome: this.therapy.product_name,
        Tipo: "",
        prescription_required: this.subject ? false : null,
        quantity: 1,
        generic: false,
      };
      // this.form.order.products.push({
      //   Aic: this.therapy.product_aic,
      //   Nome: this.therapy.product_name,
      //   Tipo: "",
      //   prescription_required: false,
      // });
    }
    // this.selectedCompany = this.user.company;
    // this.form.company_id = this.user.company.hashid;
    // this.validateService();
  },
};
</script>
<style lang="scss">
.select__dis:disabled {
  background-color: #fff !important;
  color: inherit !important;
}
</style>
