<template>
  <div class="col-12">
    <b-overlay :show="loading" rounded="sm">
      <h5 class="text-left text-primary">VENETO</h5>
      <b-row
        class="shadow_6 border border-secondary bg-light p-3 mb-3"
        align-v="center"
      >
        <b-col md="12">
          <h6
            class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
          >
            Esporta report rendicontazione Veneto
          </h6>
        </b-col>
        <b-form-group class="text-left mx-2">
          <label for="">Associazione</label>
          <b-form-select class="shadow_6" v-model="filterSetted.association">
            <b-form-select-option :value="null">Seleziona</b-form-select-option>
            <b-form-select-option value="federfarma"
              >Federfarma</b-form-select-option
            >
            <b-form-select-option value="farmacieunite"
              >Farmacie Unite</b-form-select-option
            >
            <b-form-select-option value="assofarm"
              >Assofarm</b-form-select-option
            >
          </b-form-select>
        </b-form-group>
        <b-form-group class="text-left mx-2">
          <label for="">ULSS</label>
          <b-form-select class="shadow_6" v-model="filterSetted.ulss">
            <b-form-select-option
              v-for="aslInfo in asl"
              :key="aslInfo"
              :value="aslInfo"
              >{{ aslInfo }}</b-form-select-option
            >
            <b-form-select-option :value="null">seleziona</b-form-select-option>
          </b-form-select>
        </b-form-group>
        <b-form-group class="text-left mx-2">
          <label for="">Compilazioni dal*</label>
          <b-form-input
            type="date"
            class="shadow_6"
            v-model="filterSetted.from"
            :state="validDates"
          ></b-form-input>
        </b-form-group>
        <b-form-group class="text-left mx-2">
          <label for="">Compilazioni al*</label>
          <b-form-input
            type="date"
            class="shadow_6"
            v-model="filterSetted.to"
            :state="validDates"
          ></b-form-input>
        </b-form-group>
        <b-col class="pr-0 text-left">
          <b-button variant="info fw-bolder mt-3" @click="resetSearch"
            >Reset</b-button
          ></b-col
        >
        <b-col class="pr-0 text-right">
          <b-button
            variant="primary primary_gradient text-white fw-bolder spacing_1 mt-3"
            @click="downloadReport"
          >
            <b-icon icon="download"></b-icon>
            Esporta</b-button
          ></b-col
        >
      </b-row>
    </b-overlay>
  </div>
</template>
<script>
import * as moment from "moment/moment";
import { supportService, adminService } from "@/_services";
import { mapState, mapActions } from "vuex";
import NuovaAttivita from "@/components/admin/attivita/NuovaAttivita.vue";
import AdminQuestionari from "@/components/admin/attivita/AdminQuestionari.vue";
import ActivityDetails from "@/components/attivita/ActivityDetails.vue";
export default {
  name: "ExportRendicontazioneVeneto",
  components: { NuovaAttivita, AdminQuestionari, ActivityDetails },
  data() {
    return {
      filterSetted: {
        search: null,
        from: null,
        to: null,
        association: null,
        ulss: null,
      },
      activities: null,
      loading: false,
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
    ...mapState("utente", ["status", "user"]),
    ...mapState("utility", ["asl"]),
    validDates: function () {
      if (this.filterSetted.from && this.filterSetted.to) {
        return moment(this.filterSetted.from).isSameOrBefore(
          moment(this.filterSetted.to)
        );
      }
      return null;
    },
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    ...mapActions("utility", ["getAsl"]),
    // fetchData() {
    //   const self = this;
    //   supportService
    //     .getActivities(this.filterSetted)
    //     .then(function (res) {
    //       self.activities = res.data.data.data;

    //       self.asl = [
    //         {
    //           value: null,
    //           text: "Seleziona",
    //         },
    //         ...res.data.data.asl.map(function (a) {
    //           return { value: a.codice_asl, text: a.denominazione };
    //         }),
    //       ];
    //     })
    //     .catch(function (err) {
    //       window.scrollTo(0, 0);
    //       self.errorAlert("Non è stato possibile recuperare le attività");
    //       console.log(err);
    //     });
    //   //
    // },
    resetSearch() {
      this.filterSetted.from = null;
      this.filterSetted.to = null;
      this.filterSetted.association = null;
      this.filterSetted.ulss = null;
    },
    downloadReport() {
      //var today = moment().format("DD/MM/YYYY");
      const self = this;
      this.loading = true;
      adminService
        .getRendicontazioneVenetoReport(this.filterSetted)
        .then(function (response) {
          self.loading = false;
          let blob = new Blob([response.data]);
          // let blob = new Blob([response.data], { type: "text/csv" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          let name = self.filterSetted.association
            ? "_" + self.filterSetted.association
            : "";
          name =
            name +
            (self.filterSetted.ulss && self.filterSetted.ulss != null
              ? "_" + self.filterSetted.ulss
              : "");
          name =
            name + (self.filterSetted.from ? "_" + self.filterSetted.from : "");
          name =
            name + (self.filterSetted.to ? "_" + self.filterSetted.to : "");
          link.download = "report_dati_rendicontazione" + name + ".xlsx";
          link.click();
        })
        .catch(function (err) {
          self.loading = false;
          window.scrollTo(0, 0);
          self.errorAlert(
            "Nessuna farmacia trovata per la selezione effettuata"
          );
          console.log(err);
        });
    },
  },
  created() {
    // this.fetchData();
    this.getAsl({ regione: "veneto", denominazione: true });
  },
};
</script>
