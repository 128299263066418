<template>
  <div class="col-12 px-0 px-md-2">
    <AnagraficaComponent />
    <template v-if="user.type === 1">
      <AccessoEPermessi class="mb-2" />
      <NotificationChannels />
    </template>
    <template v-else-if="user.type === 2">
      <AccessoEPermessi class="mb-2" />
    </template>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import AnagraficaComponent from "@/components/profile/AnagraficaComponent.vue";
import AccessoEPermessi from "@/components/profile/AccessoEPermessi.vue";
import NotificationChannels from "@/components/impostazioni/NotificationChannels.vue";
export default {
  name: "Profilo",
  components: {
    AnagraficaComponent,
    AccessoEPermessi,
    NotificationChannels,
  },
  computed: {
    ...mapState("utente", ["status", "user"]),
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions("utente", ["setAnagrafica", "me"]),
  },
  watch: {},
  created() {
    // this.me();
  },
  mounted() {},
};
</script>
