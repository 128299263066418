const state = {
  type: null,
  message: null
};

const actions = {
  success({ commit }, message) {
      commit('success', message);
      setTimeout(function(){ commit('clear');; }, 5000);
  },
  warning({ commit }, message) {
      commit('warning', message);
      setTimeout(function(){ commit('clear');; }, 5000);
  },
  error({ commit }, message) {
      commit('error', message);
      setTimeout(function(){ commit('clear');; }, 10000);
  },
  info({ commit }, message) {
      commit('info', message);
      // setTimeout(function(){ commit('clear');; }, 10000);
  },
  clear({ commit }) {
      commit('clear');
  }
};

const mutations = {
  success(state, message) {
      state.type = 'success';
      state.message = message;
  },
  warning(state, message) {
      state.type = 'warning';
      state.message = message;
  },
  error(state, message) {
      state.type = 'danger';
      state.message = message;
  },
  clear(state) {
      state.type = null;
      state.message = null;
  },
  info(state, message) {
      state.type = 'info';
      state.message = message;
  },
};

export const alert = {
  namespaced: true,
  state,
  actions,
  mutations
};