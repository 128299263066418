<template>
  <div>
    <b-spinner v-if="plan === null"></b-spinner>
    <b-container fluid v-else-if="plan">
      <b-overlay :show="saving">
        <div class="d-flex justify-content-between mb-2">
          <router-link
            class="fw-bolder text-primary no__hover"
            :to="{
              name: 'piani asl',
            }"
          >
            <b-icon icon="arrow-left-circle-fill" variant="primary"></b-icon>
            Indietro
          </router-link>
          <h5 v-if="plan">Piano terapeutico #{{ plan.codice_richiesta }}</h5>
        </div>
        <b-row
          cols="1"
          class="py-3 text-sec-1 border border-sec-1 shadow_6 px-2 bg-light mb-3 radius_02"
        >
          <b-row class="text-left justify-content-between">
            <h6>
              <span
                class="text-secondary point px-2"
                @click="showPianoDetails = !showPianoDetails"
              >
                <b-icon
                  :icon="showPianoDetails ? 'chevron-up' : 'chevron-down'"
                ></b-icon>
              </span>
              Paziente:
              <span v-if="plan.subjectable.anagrafica" class="text-capitalize">
                {{ plan.subjectable.anagrafica.name }}
                {{ plan.subjectable.anagrafica.surname }},
                <span class="text-uppercase">{{
                  plan.subjectable.anagrafica.cf
                }}</span>
              </span>
              <span v-else>*****utenza rimossa****</span>
            </h6>
            <h6>
              Validità:
              {{ plan.valid_from | moment("DD/MM/YYYY") }}
              <span v-if="plan.valid_until">
                - {{ plan.valid_until | moment("DD/MM/YYYY") }}</span
              >
            </h6>
            <h6>
              Stato:
              <span class="text-uppercase">{{ plan.stato }}</span>
            </h6>
          </b-row>
          <b-row
            cols-md="2"
            cols="1"
            v-if="showPianoDetails"
            class="text-secondary text-left mt-2 justify-content-between pt-1 bg-white"
          >
            <b-col>
              <h6 class="mb-0">Data caricamento piano</h6>
              <p class="mb-2">
                <span class="font-italic text-sec-1">
                  {{ plan.created_at }}
                </span>
              </p>
              <template v-if="plan.subjectable.anagrafica">
                <h6>Paziente</h6>
                <p class="mb-0">
                  <strong>Telefono:</strong>
                  <span class="font-italic text-sec-1">
                    {{ plan.subjectable.anagrafica.tel_number }}
                  </span>
                </p>
                <p class="mb-0">
                  <strong>Email:</strong>
                  <span class="font-italic text-sec-1">
                    {{ plan.subjectable.email }}
                  </span>
                </p>
              </template>
            </b-col>
            <b-col v-if="plan.company">
              <h6>Farmacia</h6>
              <p class="mb-0">
                <span class="font-italic text-uppercase fw-bolder text-sec-1">
                  {{ plan.company.denominazione }}
                </span>
              </p>
              <p class="mb-0" v-if="plan.company.telefono">
                <strong>Telefono:</strong>
                <span class="font-italic text-sec-1">
                  {{ plan.company.telefono }}
                </span>
              </p>
              <p class="mb-0">
                <strong>Indirizzo:</strong>
                <span class="font-italic text-capitalize text-sec-1">
                  {{ plan.company.indirizzo }},
                  <span v-if="plan.company.frazione"
                    >{{ plan.company.frazione }} -
                  </span>
                  {{ plan.company.comune }}
                </span>
              </p>
            </b-col>
          </b-row>
        </b-row>
        <p
          class="text-left transform_class"
          :class="showInfo ? 'bg-line-grey  p-2' : 'mb-0'"
        >
          <span
            @click="showInfo = !showInfo"
            class="point text-left text-secondary"
            ><b-icon icon="info-circle" font-scale="1.5"></b-icon
          ></span>
          <span class="pl-2" v-if="showInfo"
            >Definisci, per ciascun farmaco previsto dal piano terapeutico,
            l'autorizzazione e gli eventuali altri dati richiesti; potrai quindi
            creare uno o più pacchetti di consegna in cui collocare i prodotti.
            Quando vorrai notificare al paziente ed alla farmacia di riferimento
            che il farmaco è pronto per essere consegnato al paziente, clicca su
            "cambia stato" nella sezione relativa al pacchetto di riferimento e
            seleziona "pronto per la consegna al paziente".</span
          >
        </p>
        <b-row class="py-2 flex-wrap" cols="1" cols-lg="2">
          <b-col cols="12" md="5" lg="5">
            <b-button
              aria-controls="pdf_viewer"
              variant="info"
              size="sm"
              class="fw-bolder"
              @click="visiblePdf = !visiblePdf"
              block
            >
              {{ visiblePdf ? "Nascondi" : "Mostra" }} Piano Terapeutico
            </b-button>
            <b-collapse
              id="pdf_viewer"
              v-model="visiblePdf"
              class="mt-2 shadow_6 radius_02"
            >
              <template v-if="attachmentLink">
                <PDFViewer
                  v-if="plan.attachment.ext == 'pdf'"
                  :source="attachmentLink"
                  style="height: 55vh; width: 100%; min-width: 100%"
                  loading-text="Caricamento in corso..."
                  @download="handleViewerDownload"
                />
                <b-img
                  v-else
                  ref="allegato"
                  :src="attachmentLink"
                  no-body
                  fluid-grow
                  width="100%"
                  height="100%"
                  class="zoomable mt-2"
                />
              </template>
            </b-collapse>
          </b-col>
          <b-col cols="12" md="7" lg="7" class="text-left">
            <h6 class="bg-secondary text-white px-3 py-1 radius_02">
              Farmaci previsti dal Piano Terapeutico
            </h6>
            <template v-for="(therapy, indx) in plan.included_therapies">
              <b-row
                v-if="!therapy.delivered"
                class="py-3 mb-2 bg-light general_border shadow_6"
                :key="indx"
              >
                <b-col cols="12">
                  <b-row
                    class="justify-content-between"
                    v-if="!therapy.rejected"
                  >
                    <h6 class="text-prim-grad-1 fw-bolder">
                      <span
                        class="text-secondary px-2"
                        v-b-toggle="'prodotto_' + indx"
                      >
                        <b-icon icon="chevron-down"></b-icon>
                      </span>
                      <span>
                        {{
                          therapy.product_name
                            ? therapy.product_name
                            : therapy.therapy.product_name
                        }}
                      </span>
                    </h6>
                    <h6 class="text-prim-grad-1 fw-bolder">
                      <span class="text-secondary">AIC:</span>
                      {{
                        therapy.product_aic
                          ? therapy.product_aic
                          : therapy.therapy.product_aic
                      }}
                    </h6>
                  </b-row>
                  <b-row
                    style="text-decoration: line-through"
                    class="justify-content-between"
                    v-else
                  >
                    <h6 class="text- fw-bolder">
                      <span class="text-danger ml-2">
                        <b-icon icon="x-octagon-fill"></b-icon>
                      </span>
                      <span class="ml-1">
                        {{
                          therapy.product_name
                            ? therapy.product_name
                            : therapy.therapy.product_name
                        }}
                      </span>
                    </h6>
                    <h6 class="text- fw-bolder">
                      <span class="text-secondary">AIC:</span>
                      {{
                        therapy.product_aic
                          ? therapy.product_aic
                          : therapy.therapy.product_aic
                      }}
                    </h6>
                  </b-row>
                </b-col>
                <b-col> </b-col>
                <b-collapse
                  v-if="!therapy.rejected"
                  visible
                  :id="'prodotto_' + indx"
                  class="w-100"
                >
                  <b-col cols="12">
                    <b-row>
                      <b-col cols="12" xl="7">
                        <label for="" class="text-capitalize">{{
                          therapy.tipo
                        }}</label>
                      </b-col>
                      <template v-if="therapy.type >= 3">
                        <b-col>
                          <label for=""
                            >Quantità: {{ therapy.quantity }}
                          </label>
                        </b-col>
                        <b-col cols="12" xl="7">
                          <label for="">Lotto: {{ therapy.batch }}</label>
                        </b-col>
                        <b-col cols="12" xl="5">
                          <label for=""
                            >Scadenza:
                            {{
                              therapy.expiration | moment("DD-MM-YYYY")
                            }}</label
                          >
                        </b-col>
                      </template>
                    </b-row>
                  </b-col>
                </b-collapse>
              </b-row>
              <b-row v-else class="bg-grey fs-6 py-3 mb-2" :key="indx">
                <b-col cols="12">
                  <b-row class="justify-content-between">
                    <span class="text-secondary fw-bolder">
                      <span
                        class="text-secondary px-2"
                        v-b-toggle="'prodotto_' + indx"
                      >
                        <b-icon icon="chevron-down"></b-icon>
                      </span>
                      <span>
                        {{
                          therapy.product_name
                            ? therapy.product_name
                            : therapy.therapy.product_name
                        }}
                      </span>
                    </span>
                    <span class="text-secondary fw-bolder">
                      <span class="text-secondary">AIC:</span>
                      {{
                        therapy.product_aic
                          ? therapy.product_aic
                          : therapy.therapy.product_aic
                      }}
                    </span>
                  </b-row>
                </b-col>
                <b-col cols="12">
                  <p class="text-right font-italic mb-0">Consegnato</p>
                </b-col>
                <b-collapse :id="'prodotto_' + indx" class="w-100">
                  <b-col cols="12">
                    <b-row>
                      <b-col cols="12" xl="7">
                        <label
                          v-if="therapy.tipo"
                          for=""
                          class="text-capitalize"
                          >{{ therapy.tipo }}</label
                        >

                        <label v-else for="">In attesa di autorizzazione</label>
                      </b-col>
                      <template v-if="therapy.type >= 3">
                        <b-col>
                          <label for=""
                            >Quantità: {{ therapy.quantity }}
                          </label>
                        </b-col>
                        <b-col cols="12" xl="7">
                          <label for="">Lotto: {{ therapy.batch }}</label>
                        </b-col>
                        <b-col cols="12" xl="5">
                          <label for=""
                            >Scadenza:
                            {{
                              therapy.expiration | moment("DD-MM-YYYY")
                            }}</label
                          >
                        </b-col>
                      </template>
                    </b-row>
                  </b-col>
                </b-collapse>
              </b-row>
            </template>
          </b-col>
        </b-row>
        <b-row cols="1" class="mt-3 px-3">
          <h6 class="bg-secondary text-white px-3 py-1 radius_02 text-left">
            Pacchetti consegna programmati per il Piano Terapeutico
          </h6>
          <p v-if="plan.deliveries.length === 0" class="text-left">
            Ancora nessun pacchetto di consegna.
          </p>
          <b-card
            v-for="(delivery, ind) in plan.deliveries"
            :key="ind"
            class="border-secondary general_border fw-bolder my-1"
            header-tag="header"
            header-border-variant="transparent border-0 general_border"
            body-bg-variant="white py-0 general_border"
            hide-footer
          >
            <template #header>
              <b-row
                class="justify-content-between"
                v-b-toggle="'pacchetto_' + ind"
              >
                <h6 class="mb-0 text-right">
                  <span class="text-secondary px-2">
                    <b-icon icon="chevron-down"></b-icon>
                  </span>
                  Pacchetto #{{ plan.deliveries.length - ind }}
                </h6>
                <h6 class="mb-0 text-right">
                  Stato pacchetto:
                  <span class="text-uppercase">{{ delivery.stato }}</span>
                </h6>
              </b-row>
            </template>
            <b-collapse
              :visible="delivery.status <= 3"
              :id="'pacchetto_' + ind"
            >
              <b-card-body class="px-2 py-1">
                <h6 class="text-left pt-3 pb-2 fs-5 text-font-light-grey">
                  Trascina i prodotti dalla lista farmaci previsti dal Piano
                  Terapeutico nel riquadro "farmaci contenuti nel pacchetto" per
                  aggiungerli al pacchetto consegna.
                </h6>
                <b-row cols="1" cols-md="2" class="d-flex">
                  <b-col class="text-left order-2 order-md-1 mt-1">
                    <h6>Etichetta</h6>
                    <div class="bg-light text-font-light-grey px-3 py-2">
                      {{ delivery.label }}
                    </div>
                  </b-col>
                  <b-col
                    class="bg-light text-left order-1 order-md-2 mt-1 cell"
                    :class="emptyList ? 'border border-danger' : ''"
                  >
                    <label for="">Farmaci contenuti nel pacchetto</label>
                    <span
                      v-for="(product, index) in delivery.product_list"
                      class="bg-prim-grad-1 text-white px-3 py-1 d-flex justify-content-between initial my-1"
                      slot="footer"
                    >
                      {{
                        product.product_name
                          ? product.product_name
                          : product.therapy.product_name
                      }}
                      -
                      {{
                        product.product_aic
                          ? product.product_aic
                          : product.therapy.product_aic
                      }}
                      <span class="text-right">
                        qtà: {{ product.quantity }} -
                        {{ product.expiration | moment("DD/MM/YYYY") }}
                        <br />
                        {{ product.batch }}
                      </span>
                    </span>
                  </b-col>
                </b-row>
                <b-row cols="1">
                  <b-col class="text-left">
                    <h6>Note ASL sulla consegna</h6>
                    <div class="bg-light text-font-light-grey px-3 py-2">
                      {{ delivery.asl_notes }}
                    </div>
                  </b-col>
                  <b-col class="text-left mt-2" v-if="delivery.pharmacy_notes">
                    <h6>Note farmacia sulla consegna</h6>
                    <div class="bg-light text-font-light-grey px-3 py-2">
                      {{ delivery.pharmacy_notes }}
                    </div>
                  </b-col>
                </b-row>
                <b-row class="justify-content-end mt-3">
                  <p class="text-left mr-2" v-if="delivery.delivered_at">
                    Consegnato il
                    {{ delivery.delivered_at | moment("DD/MM/YYYY HH:mm") }}
                  </p>
                  <p class="text-left mr-2" v-if="delivery.rejected_at">
                    Reso il
                    {{ delivery.rejected_at | moment("DD/MM/YYYY HH:mm") }}
                  </p>
                </b-row>
              </b-card-body>
            </b-collapse>
          </b-card>
        </b-row>
        <b-row cols="1" class="mt-3 px-3">
          <h6 class="bg-secondary text-white px-3 py-1 radius_02 text-left">
            Annotazioni sul Piano Terapeutico
          </h6>
          <b-col class="text-left">
            <h6>Note aggiunte dalla farmacia:</h6>
            <div
              v-if="plan.pharmacy_notes"
              class="bg-light text-font-light-grey px-3 py-2"
            >
              {{ plan.pharmacy_notes }}
            </div>
            <div v-else class="text-font-light-grey px-3 py-2">
              Nessuna nota aggiunta
            </div>
          </b-col>
          <b-col class="text-left">
            <h6>Note aggiunte dalla ASL:</h6>
            <div
              v-if="plan.asl_notes"
              class="bg-light text-font-light-grey px-3 py-2"
            >
              {{ plan.asl_notes }}
            </div>
            <div v-else class="text-font-light-grey px-3 py-2">
              Nessuna nota aggiunta
            </div>
          </b-col>

          <b-col v-if="plan.status === -4" class="text-left mt-2">
            <h6>Motivazioni invalidazione:</h6>
            <div
              v-if="plan.invalidation_note"
              class="bg-light text-font-light-grey px-3 py-2"
            >
              {{ plan.invalidation_note }}
            </div>
            <div v-else class="text-font-light-grey px-3 py-2">
              Nessuna motivazione aggiunta
            </div>
          </b-col>
        </b-row>
        <b-row class="justify-content-end mt-3">
          <b-button
            @click="
              $router.push({
                name: 'piani asl',
              })
            "
            type="button"
            variant="outline-primary"
            class="ml-0 mr-auto justify-self-start fw-bolder px-4"
            >Indietro</b-button
          >
        </b-row>
      </b-overlay>
    </b-container>
    <h5 v-else>Piano non trovato</h5>
  </div>
</template>
<style>
.start > .title {
  display: none;
}

.radius_02 {
  border-radius: 0.2rem;
}

.grab {
  cursor: grab;
  cursor: -webkit-grab;
}

.cell {
  cursor: cell;
}

.initial {
  cursor: initial;
}

.pdf-viewer {
  min-width: 100%;
}

.card-header:first-child {
  border-radius: 1.5rem !important;
}

.zoomable:hover {
  cursor: zoom-in;
  position: relative;
  z-index: 90;
  transform: scale(1.8);
}
</style>
<script>
import * as moment from "moment/moment";
import { mapState, mapActions } from "vuex";
import { aslService, supportService } from "@/_services";
import PDFViewer from "pdf-viewer-vue/dist/vue2-pdf-viewer";
export default {
  name: "PianoTerapeuticoDetail",
  components: {
    PDFViewer,
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    fetchData() {
      this.loading = true;
      var self = this;
      aslService
        .getTherapeuticPlanById(this.$route.params.hash)
        .then(function (response) {
          self.plan = response.data.data;
          self.getAllegato();
          self.loading = false;
          self.edit.valid_from = response.data.data.valid_from;
          self.edit.valid_until = response.data.data.valid_until;
        })
        .catch(function (error) {
          self.errorAlert(
            "non è stato possibile prelevare le informazioni richieste"
          );
          self.loading = false;
        });
    },
    getAllegato() {
      var self = this;
      supportService
        .getAllegato(this.plan.attachment.hashid)
        .then(function (res) {
          self.attachmentLink = window.URL.createObjectURL(
            new Blob([res.data])
          );
          const contentType = res.headers?.["content-type"];
          const contentName = res.headers?.["content-disposition"];
          var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          var matches = filenameRegex.exec(contentName);
          if (matches != null && matches[1]) {
            self.fileName = matches[1].replace(/['"]/g, "");
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    handleViewerDownload(source, filename) {
      let link = document.createElement("a");
      link.href = this.attachmentLink;
      link.download = this.fileName;
      link.click();
    },
  },
  watch: {
    plan: {
      handler(post, prev) {
        if (prev !== null && !this.refreshed) {
          this.unsavedChanges = true;
        }
        if (this.refreshed) {
          this.refreshed = false;
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
  },
  created() {
    this.fetchData();
  },
  data() {
    return {
      loading: null,
      plan: null,
      attachmentLink: null,
      fileName: "allegato",
      edit: { valid_from: null, valid_until: null },
      list: [],
      deliveries: [],
      visiblePdf: true,
      emptyList: false,
      selectedDeliveryIndex: null,
      showPianoDetails: false,
      unsavedChanges: false,
      refreshed: false,
      saving: false,
      invalidation_note: null,
      showInfo: false,
    };
  },
};
</script>
