<template>
  <div class="anagrafica pb-2">
    <!-- <h1 class="py-4"></h1> -->
    <b-form @submit.prevent="onSubmit">
      <div class="mx-auto">
        <h6
          class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
        >
          Dati anagrafici
        </h6>
        <div class="col-12 px-0 px-md-2 d-flex flex-wrap">
          <b-col class="d-flex flex-wrap align-items-center">
            <b-form-group
              label="Titolo"
              label-for="titolo"
              label-class="mb-3"
              class="text-left fs-6 text-font-grey mx-auto col-12 col-md-4 px-0 mb-4"
            >
              <b-form-select
                id="titolo"
                class="shadow_6 text-capitalize"
                :options="titleOptions"
                v-model="anagrafica.title"
                :disabled="noEdit"
              >
                <b-form-select-option disabled :value="null"
                  >Titolo</b-form-select-option
                >
              </b-form-select>
            </b-form-group>
            <b-form-group
              label="Nome*"
              label-for="name"
              label-class="mb-3"
              class="text-left fs-6 text-font-grey mx-auto col-12 col-md-8 mb-4 pr-md-0"
            >
              <b-form-input
                class="shadow_6 text-capitalize"
                id="name"
                name="name"
                type="text"
                placeholder="nome..."
                v-model="anagrafica.name"
                :readonly="noEdit"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-form-group
            label="Cognome*"
            label-for="surname"
            label-class="mb-3"
            class="text-left fs-6 text-font-grey mx-auto col-12 col-md-6 mb-4"
          >
            <b-form-input
              class="shadow_6 text-capitalize"
              id="surname"
              name="surname"
              type="text"
              placeholder="cognome..."
              v-model="anagrafica.surname"
              :readonly="noEdit"
            ></b-form-input>
          </b-form-group>
        </div>
        <template v-if="user.type === 0">
          <div class="col-12 px-0 px-md-2 d-flex flex-wrap">
            <b-form-group
              label="Codice fiscale*"
              label-for="cf"
              class="text-left fs-6 text-font-grey mx-auto col-12 col-md-6"
            >
              <b-form-input
                class="my-3 shadow_6 text-uppercase"
                name="cf"
                type="text"
                placeholder="codice fiscale..."
                maxlength="16"
                minlength="16"
                v-model="anagrafica.cf"
                :readonly="noEdit"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label="Numero Tessera Sanitaria"
              label-for="ts_number"
              class="text-left fs-6 text-font-grey mx-auto col-12 col-md-6"
            >
              <b-form-input
                class="my-3 shadow_6"
                name="ts_number"
                type="text"
                placeholder="numero tessera..."
                v-model="anagrafica.ts_number"
                maxlength="20"
                minlength="20"
                :readonly="noEdit"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-12 px-0 px-md-2 d-flex flex-wrap">
            <b-form-group
              label="Data di nascita*"
              label-for="birthday"
              class="text-left fs-6 text-font-grey mx-auto col-12 col-md-6"
            >
              <b-form-input
                class="my-3 shadow_6"
                name="birthday"
                type="date"
                v-model="anagrafica.birthday"
                :readonly="noEdit"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label="Genere"
              label-for="gender"
              class="text-left fs-6 text-font-grey mx-auto col-12 col-md-6"
            >
              <b-form-radio-group
                v-model="anagrafica.gender"
                name="gender-options"
                class="d-flex"
              >
                <b-form-radio value="maschile">uomo</b-form-radio>
                <b-form-radio value="femminile">donna</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </div>
          <div class="col-12 px-0 px-md-2 d-flex flex-wrap">
            <b-form-group
              label="Medico di base"
              label-for="medico_base"
              class="text-left fs-6 text-font-grey mx-auto col-12 col-md-6"
            >
              <b-form-input
                class="my-3 shadow_6 text-capitalize"
                name="medico_base"
                type="text"
                placeholder="Medico di base..."
                v-model="anagrafica.medico_base"
                :readonly="noEdit"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label="Asl/Asp/Ats di riferimento"
              label-for="denominazione_asl"
              class="text-left fs-6 text-font-grey mx-auto col-12 col-md-6"
            >
              <b-form-select
                class="my-3 shadow_6 text-capitalize"
                name="denominazione_asl"
                v-model="anagrafica.denominazione_asl"
                :disabled="noEdit"
              >
                <b-form-select-option
                  v-for="aslOption in asl"
                  :key="aslOption"
                  :value="aslOption"
                  >{{ aslOption }}</b-form-select-option
                >
                <b-form-select-option :value="null"
                  >Scegli azienda sanitaria locale</b-form-select-option
                >
              </b-form-select>
            </b-form-group>
          </div>
        </template>
      </div>
      <div class="mx-auto" v-if="user.type === 0">
        <h6
          class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
        >
          Contatti
        </h6>

        <div class="col-12 px-0 px-md-2 d-flex flex-wrap">
          <b-form-group
            label="Numero di telefono domicilio"
            label-for="home_number"
            class="text-left fs-6 text-font-grey mx-auto col-12 col-md-6"
          >
            <b-form-input
              class="my-3 shadow_6"
              name="tel_number"
              type="text"
              placeholder="telefono domicilio..."
              v-model="anagrafica.home_number"
              minlength="9"
              :readonly="noEdit"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            label="Numero di telefono*"
            label-for="tel_number"
            class="text-left fs-6 text-font-grey mx-auto col-12 col-md-6"
          >
            <b-form-input
              class="my-3 shadow_6"
              name="tel_number"
              type="text"
              placeholder="telefono..."
              v-model="anagrafica.tel_number"
              minlength="9"
              :readonly="noEdit"
              :state="anagrafica.tel_number ? validNumber : null"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-12 col-md-6 px-0 px-md-2 d-flex flex-wrap">
          <b-form-group
            label="Numero di telefono di riserva"
            label-for="tel_number_secondary"
            description="Il numero di riserva può essere utilizzato in caso di impossibilità di accesso al numero principale. Questo campo richiede validazione tramite invio di OTP al numero indicato."
            class="text-left fs-6 text-font-grey mx-auto col-12"
          >
            <b-form-input
              class="my-3 shadow_6"
              name="tel_number_secondary"
              type="text"
              placeholder="telefono di riserva..."
              v-model="anagrafica.tel_number_secondary"
              minlength="9"
              :readonly="noEdit"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-12 px-0 px-md-2 d-flex flex-wrap">
          <b-form-group
            label="Nazione di nascita*"
            label-for="nazione_nascita"
            class="text-left fs-6 text-font-grey mx-auto col-12 col-md-6"
          >
            <b-form-select
              id="nazione_nascita"
              class="my-3 shadow_6 text-capitalize"
              :options="nazioniOptions"
              name="nazione_nascita"
              v-model="anagrafica.nazione_nascita"
              :disabled="noEdit"
            >
            </b-form-select>
          </b-form-group>
          <b-form-group
            label="Regione*"
            label-for="regione"
            class="text-left fs-6 text-font-grey mx-auto col-12 col-md-6"
          >
            <b-form-select
              class="my-3 shadow_6 text-capitalize"
              :options="regioni"
              name="regione"
              v-model="selected.regione"
              @change="getProvince(selected)"
              :disabled="noEdit"
            >
              <b-form-select-option :value="null"
                >Seleziona Regione</b-form-select-option
              >
            </b-form-select>
          </b-form-group>
        </div>
        <div class="col-12 px-0 px-md-2 d-flex flex-wrap">
          <b-form-group
            label="Provincia*"
            label-for="provincia"
            class="text-left fs-6 text-font-grey mx-auto col-12 col-md-6"
          >
            <b-form-select
              class="my-3 shadow_6 text-capitalize"
              :options="province"
              name="provincia"
              v-model="selected.provincia"
              @change="getComuni(selected)"
              :disabled="noEdit"
            >
              <b-form-select-option :value="null"
                >Seleziona Provincia</b-form-select-option
              >
            </b-form-select>
          </b-form-group>

          <b-form-group
            label="Comune*"
            label-for="comune"
            class="text-left fs-6 text-font-grey mx-auto col-12 col-md-6"
          >
            <b-form-select
              class="my-3 shadow_6 text-capitalize"
              name="comune"
              v-model="selected.comune"
              :disabled="noEdit"
            >
              <b-form-select-option
                v-for="(com, ind) in comuni"
                :key="ind"
                :value="com"
                @change="setDomicilioData"
                :selected="findComune(com)"
                >{{ com.comune }}</b-form-select-option
              >
              <b-form-select-option :value="null"
                >Seleziona Comune</b-form-select-option
              >
            </b-form-select>
          </b-form-group>
        </div>
        <div class="col-12 px-0 px-md-2 d-flex flex-wrap">
          <b-form-group
            label="Indirizzo e numero civico"
            label-for="address"
            class="text-left fs-6 text-font-grey mx-auto col-12 col-md-6"
          >
            <b-form-input
              class="my-3 shadow_6 text-capitalize"
              name="address"
              type="text"
              placeholder="indirizzo..."
              v-model="anagrafica.address"
              :readonly="noEdit"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label="Cap"
            label-for="cap"
            class="text-left fs-6 text-font-grey mx-auto col-12 col-md-6"
          >
            <b-form-input
              class="my-3 shadow_6"
              name="cap"
              type="text"
              placeholder="cap..."
              v-model="anagrafica.cap"
              minlength="5"
              maxlength="5"
              :readonly="noEdit"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>
      <div
        class="mx-auto pb-4 mb-4 text-left"
        :class="$root.isMobile ? 'text-center' : 'text-left'"
        v-if="!noEdit"
      >
        <b-button
          type="button"
          variant="outline-danger fw-bolder m-2"
          @click.prevent="handleAnnulla"
          >Annulla</b-button
        >
        <b-button
          type="submit"
          variant="primary text-white primary_gradient fw-bolder m-2"
          :disabled="!enableBtn"
          >Salva
        </b-button>
      </div>
      <div
        class="mx-auto pb-4 mb-4 col-12"
        :class="$root.isMobile ? 'text-center' : 'text-left'"
        v-else
      >
        <b-button
          type="submit"
          variant="primary text-white fw-bolder spacing_1"
          @click="noEdit = !noEdit"
          >Modifica Dati
        </b-button>
      </div>
    </b-form>
    <!-- <div class="col-10 mx-auto">
      <h6
        class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
      >
        Campi Personalizzati
      </h6>
    </div> -->
  </div>
</template>

<script>
import * as moment from "moment/moment";
import { mapState, mapActions } from "vuex";
import { utilityService } from "@/_services";

export default {
  name: "AnagraficaComponent",
  computed: {
    validNumber() {
      if (this.anagrafica.tel_number) {
        const prefix = this.anagrafica.tel_number.slice(0, 2);
        const hasPrefix =
          prefix === "00" || this.anagrafica.tel_number.indexOf("+") >= 0;
        return !isNaN(this.anagrafica.tel_number) && !hasPrefix;
      }
      return false;
    },
    ...mapState("utente", ["status", "user"]),
    ...mapState("utility", [
      "searching",
      "comuni",
      "province",
      "regioni",
      "asl",
    ]),
    nazioniOptions() {
      if (this.nazioni && this.nazioni.length > 0) {
        return [
          { value: null, text: "Seleziona Nazione" },
          { value: "italia", text: "Italia" },
        ].concat(
          this.nazioni.map((nazione) => {
            return {
              value: nazione.nazione,
              text: nazione.nazione
                .toLowerCase()
                .split(" ")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" "),
            };
          })
        );
      } else
        return [
          {
            value: null,
            text: "Seleziona Nazione",
          },
        ];
    },
  },
  data() {
    return {
      selected: {
        regione: null,
        provincia: null,
        comune: null,
      },
      anagrafica: {
        regione: null,
        provincia: null,
        comune: null,
        cap: null,
        address: null,
        name: null,
        surname: null,
        cf: null,
        gender: null,
        birthday: null,
        ts_number: null,
        tel_number: null,
        medico_base: null,
        denominazione_asl: null,
        title: null,
        nazione_nascita: null,
      },
      titleOptions: ["Sig.", "Sig.ra", "Dott.", "Dott.ssa"],
      enableBtn: false,
      isPost: true,
      noEdit: false,
      nazioni: [],
    };
  },
  methods: {
    ...mapActions("utility", [
      "getComuni",
      "getProvince",
      "getRegioni",
      "getAsl",
    ]),
    ...mapActions("utente", ["setAnagrafica", "me"]),
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),

    setDomicilioData() {
      // //console.log("on change", this.selected.comune);
      if (this.selected.comune) {
        this.anagrafica.comune = this.selected.comune.comune;
        this.anagrafica.provincia = this.selected.comune.provincia;
        this.anagrafica.regione = this.selected.comune.regione;
      }
    },
    onSubmit() {
      // //console.log("submit", this.anagrafica);
      if (this.anagrafica.tel_number && !this.validNumber) {
        this.errorAlert("Numero di telefono non valido");
        return false;
      }
      var self = this;
      this.setAnagrafica({ form: this.anagrafica, isPost: this.isPost })
        .then((res) => {
          //console.log("RESS");
          //console.log(res);
          window.scrollTo(0, 0);
          self.noEdit = !self.noEdit;
          self.me();
          // self.anagrafica = self.user.anagrafica;
        })
        .catch((err) => {
          //console.log("ERRR");
          console.log(err);
          window.scrollTo(0, 0);
          // this.noEdit = err;
          self.me();
        });
    },
    setupPrevs() {
      if (this.user.anagrafica.regione) {
        this.selected.regione = this.user.anagrafica.regione;
        this.getProvince(this.selected);
      }
      if (this.user.anagrafica.provincia) {
        this.selected.provincia = this.user.anagrafica.provincia;
        this.getComuni(this.selected);
      }
    },
    findComune(comune) {
      if (this.user.anagrafica && this.user.anagrafica.comune) {
        if (comune.comune === this.user.anagrafica.comune) {
          this.selected.comune = comune;
          return true;
        }
      }
      return false;
    },
    handleAnnulla() {
      if (this.isPost) {
        $router.go(-1);
      }
      this.me();
      this.noEdit = !this.noEdit;
    },
    getNazioni() {
      var self = this;
      utilityService
        .nazioni()
        .then((res) => {
          self.nazioni = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  watch: {
    anagrafica: {
      handler(prev, post) {
        this.enableBtn = true;
        // console.log(prev);
      },
      deep: true,
    },
    selected: {
      handler(val) {
        if (val.comune !== null) {
          this.anagrafica.comune = this.selected.comune.comune;
          this.anagrafica.provincia = this.selected.comune.provincia;
          this.anagrafica.regione = this.selected.comune.regione;
        }
      },
      deep: true,
    },
  },
  created() {
    this.me();
    this.getNazioni();
    this.getRegioni();
    this.getAsl({ denominazione: true });
    if (this.user.anagrafica !== null) {
      this.isPost = false;
      this.noEdit = true;
      this.anagrafica = this.user.anagrafica;
      this.enableBtn = false;
      this.setupPrevs();
    }
  },
  mounted() {
    if (this.user.anagrafica !== null) {
      this.setupPrevs();
    }
  },
};
</script>
