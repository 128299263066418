<template>
  <div v-if="activity">
    <h6
      class="text-prim-grad-1 fw-bolder text-left mt-2 pb-1 border-bottom border-line-grey"
    >
      {{ activity.name }}
    </h6>
    <p class="text-left text-medium-grey fw-bolder mb-0">
      {{ activity.description }}
    </p>
    <b-row
      class="text-left mb-3"
      v-if="
        (!user.permessi.includes('gestire aziende propria regione') &&
          !user.permessi.includes('gestire aziende propria asl')) ||
        activity.created_by !== null
      "
    >
      <b-col>
        <!-- <b-button size="sm" variant="outline-primary" class="mr-2 my-2" @click="openUpdate"> -->
        <b-button
          size="sm"
          variant="outline-primary"
          class="mr-2 my-2"
          @click="infoAlert('funzionalità non disponibile')"
        >
          <b-icon icon="pencil"></b-icon> Modifica attività
        </b-button>
        <!-- <b-button size="sm" variant="outline-danger" @click="openDelete()"> -->
        <b-button
          size="sm"
          variant="outline-danger"
          @click="infoAlert('funzionalità non disponibile')"
        >
          <b-icon icon="bucket"></b-icon> Elimina attività
        </b-button>
        <b-button
          v-if="!user.permessi.includes('gestire aziende propria regione')"
          variant="primary"
          size="sm"
          class="ml-2 my-2"
          @click="
            $router.push({
              name: 'nuovo questionario',
              query: { activity: activity.hashid },
            })
          "
        >
          <b-icon icon="plus-circle-fill"></b-icon>
          <span> Nuovo Questionario</span>
        </b-button>

        <b-button
          v-b-toggle.collapse-quest-list
          variant="info info_gradient"
          class="ml-2 my-2"
          size="sm"
        >
          <b-icon icon="list"></b-icon>
          <span>Elenco questionari dell'attività</span>
        </b-button>
      </b-col>
    </b-row>
    <b-collapse id="collapse-quest-list" class="mt-2">
      <h6 class="text-prim-grad-1 fw-bolder text-left pb-1">
        Questionari inclusi nell'attività:
      </h6>
      <b-table
        id="quests"
        :items="surveys"
        :fields="fields"
        small
        borderless
        responsive
        table-variant="white text-info text-capitalize text-left row__font"
        thead-class=" border-medium-grey text-left"
        tbody-class=""
        ref="quests"
        tbody-tr-class="text-black border-bottom border-medium-grey"
        hover
      >
        <template #cell(enabled)="row">
          <span>
            <b-icon
              :variant="row.item.enabled === 1 ? 'success' : 'danger'"
              icon="circle-fill"
            ></b-icon>
          </span>
        </template>
        <template #cell(azioni)="row">
          <b-button
            v-if="activity.prof2 !== 1"
            variant="info info_gradient fw-bolder rounded-pill px-3"
            size="sm"
            @click="selectThis(row.item)"
            v-b-tooltip.hover.left="'Visualizza'"
          >
            <b-icon icon="eye-fill"></b-icon>
          </b-button>
          <b-button
            variant="secondary secondary_gradient fw-bolder rounded-pill px-3"
            size="sm"
            @click="
              $router.push({
                name: 'report questionario',
                params: { quest: row.item.hashid },
              })
            "
            v-b-tooltip.hover.right="'Report'"
          >
            <b-icon icon="collection-fill"></b-icon>
          </b-button>
        </template>
      </b-table>
    </b-collapse>
    <!-- <b-row :cols="$root.isMobile ? '1' : '2'" v-if="surveys && surveys.length > 0">
      <b-col class="my-2">
        <b-button variant="secondary secondary_gradient text-white fw-bolder spacing_1" class="m-1"
          @click="downloadReport">Scarica report attività in formato pdf</b-button>
        <b-button variant="secondary secondary_gradient text-white fw-bolder spacing_1" class="m-1"
          @click="downloadXlsReport">Scarica report attività in formato xlsx</b-button>
      </b-col>
    </b-row> -->

    <b-modal ref="modal-showsurvey" :title="modalTitle" size="xl" hide-footer>
      <SurveyShower v-if="selectedRow" :survey="selectedRow" class="px-3" />
    </b-modal>
    <b-modal
      id="del-confirm"
      centered
      title="Conferma operazione"
      ok-title="Sì, conferma"
      cancel-title="No, annulla"
      ok-variant="danger"
      cancel-variant="outline-primary"
      footer-border-variant="white"
      button-size="sm"
      @cancel="$bvModal.hide('del-confirm')"
      @ok="doDelete"
    >
      <h5>Vuoi eliminare l'attività "{{ activity.name }}"?</h5>
      <p>
        L'azione comporta l'eliminazione di tutti i questionari dell'attività e
        gli eventuali relativi questionari compilati.
      </p>
    </b-modal>
    <b-modal
      centered
      id="edit-ativity"
      title="Modifica Attività"
      size="lg"
      ok-title="Salva modifiche"
      cancel-title="Annulla"
      ok-variant="primary primary_gradient"
      cancel-variant="outline-danger"
      footer-border-variant="white"
      @cancel="$bvModal.hide('edit-ativity')"
      @ok="doUpdate"
    >
      <b-form class="text-left fw-bolder">
        <b-form-group id="name" label="Nome*:" label-for="name">
          <b-form-input
            id="name"
            v-model="editForm.name"
            type="text"
            placeholder="Nome attività.."
            class="shadow_6"
            required
            :state="validName"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="description"
          label="Descrizione:"
          label-for="description"
        >
          <b-form-input
            id="description"
            v-model="editForm.description"
            type="text"
            placeholder="Descrizione attività.."
            class="shadow_6"
          ></b-form-input>
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>
<script>
import { supportService } from "@/_services";
import { mapState, mapActions } from "vuex";
import SurveyShower from "@/components/attivita/SurveyShower.vue";
export default {
  name: "ActivityDetails",
  props: ["activity", "asAdmin"],
  components: { SurveyShower },
  data() {
    return {
      surveys: null,
      filterSetted: {
        search: null,
        position_ref: null,
        page: 1,
        take: 50,
        activity: null,
      },
      selectedRow: null,
      modalTitle: null,
      editForm: {
        name: null,
        description: null,
      },
      fields: [
        { key: "name", label: "Nome" },
        { key: "description", label: "Descrizione" },
        { key: "tipo", label: "Tipo" },
        { key: "tot_filled", label: "Tot Somm." },
        { key: "enabled", label: "Stato" },
        { key: "azioni", label: "" },
      ],
    };
  },
  computed: {
    validName: function () {
      var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
      if (this.activity.name !== null && this.activity.name.length > 0) {
        return format.test(this.activity.name) ? false : true;
      }
      return false;
    },
    ...mapState({
      alert: (state) => state.alert,
    }),
    ...mapState("utente", ["status", "user"]),
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    fetchSurveys() {
      var self = this;
      supportService
        .getSurveys(this.filterSetted)
        .then(function (response) {
          self.surveys = response.data.data.data;
          self.$emit("survey-list", response.data.data.data);
          // self.filterSetted.page = response.data.data.current_page;
          // self.total = response.data.data.total;
          // self.fromPage = response.data.data.from;
          // self.toPage = response.data.data.to;
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          self.errorAlert(
            "Non è stato possibile recuperare le i questionari dell'attività"
          );
          console.log(err);
        });
    },
    downloadReport() {
      var self = this;
      supportService
        .reportActivityPdf(this.activity.hashid)
        .then(function (response) {
          let blob = new Blob([response.data]);
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "Report_" + self.activity.name + ".pdf";
          link.click();
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          self.errorAlert(
            "Non è stato possibile scaricare il report l'attività"
          );
          console.log(err);
        });
    },
    downloadXlsReport() {
      var self = this;
      supportService
        .reportActivityXls(this.activity.hashid)
        .then(function (response) {
          let blob = new Blob([response.data]);
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "Report_" + self.activity.name + ".xlsx";
          link.click();
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          self.errorAlert(
            "Non è stato possibile scaricare il report l'attività"
          );
          console.log(err);
        });
    },
    selectThis(row) {
      this.selectedRow = row;
      this.modalTitle = row.name;
      this.$refs["modal-showsurvey"].show();
    },
    openDelete(item) {
      this.selectedItem = item;
      this.$bvModal.show("del-confirm");
    },
    doDelete() {
      this.$bvModal.hide("del-confirm");
      this.$emit("do-delete", this.activity.hashid);
    },
    openUpdate() {
      this.editForm = {
        name: this.activity.name,
        description: this.activity.description,
      };
      this.$bvModal.show("edit-ativity");
    },
    doUpdate() {
      this.$bvModal.hide("edit-ativity");
      let data = {
        form: this.editForm,
        hashid: this.activity.hashid,
      };
      this.$emit("do-update", data);
    },
  },
  // watch: {
  //   activity(val) {
  //     this.filterSetted.activity = val.name;
  //     this.filterSetted.take = val.tot_surveys + 1;
  //     this.fetchSurveys();
  //   },
  // },
  created() {
    if (this.activity) {
      this.filterSetted.activity = this.activity.name;
      this.filterSetted.take = this.activity.tot_surveys + 1;
      this.fetchSurveys();
    }
    // //console.log(this.activity);
  },
};
</script>
