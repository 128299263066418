<template>
  <div v-if="azienda" class="h-100">
    <div
      class="general_border shadow_6 pb-1 px-2 point border h-100 text-justify"
      :class="
        iselected ? 'border-sec-2 bg-sec-3' : 'border-light-panel bg-white'
      "
      @click="selectThis"
    >
      <p class="mb-0 fs-5 pt-2">
        <span
          class="ml-0 mb-0 mr-1"
          v-if="
            user.farmacia_preferita &&
            azienda.hashid === user.farmacia_preferita.hashid
          "
        >
          <b-icon icon="heart-fill"></b-icon>
        </span>
        <strong class="">{{ azienda.ff_denominazione }}</strong>
      </p>
      <p class="mb-0 mt-1 fs-5 text-font-grey text-capitalize">
        {{ azienda.indirizzo }}, {{ azienda.frazione }} {{ azienda.comune }}
      </p>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "CompactCompanyCard",
  props: ["azienda", "iselected"],
  computed: {
    ...mapState("utente", ["status", "user"]),
  },
  data() {
    return {
      isSelected: false,
    };
  },
  methods: {
    selectThis() {
      this.$emit("company-selection", this.azienda);
    },
  },
};
</script>
<style>
.min__30 {
  min-height: 155px;
}
</style>