<template>
  <div class="text-justify">
    <h6 class="text-center">
      TERMINI E CONDIZIONI DI UTILIZZO DELLA PIATTAFORMA "DOTTORFARMA"
    </h6>
    <h6 class="text-center">IDENTIFICAZIONE DEL FORNITORE</h6>
    <p>
      La licenza d’uso della piattaforma Dottorfarma e i servizi correlati sono
      forniti da Promofarma S.r.l., con sede in Roma, via Emanuele Filiberto n.
      190, codice fiscale e P.IVA n. 05317231008, in persona del legale
      rappresentante pro tempore, di seguito indicata anche come «Fornitore».
    </p>
    <h6 class="text-center">DEFINIZIONI</h6>
    <p>
      a. Con l’espressione “Dottorfarma” o la “Piattaforma” si intende la
      piattaforma certificata anche come dispositivo medico di proprietà di
      Promofarma S.r.l., che permette di organizzare e gestire i servizi resi
      dalla farmacia in favore dei pazienti, in particolare dei pazienti
      cronici;<br />
      b. Con l’espressione “Licenziatario” si intende la società o l’impresa
      individuale titolare di farmacie, associata a Federfarma, che sottoscrive
      il presente contratto al fine di dotare la farmacia del supporto della
      Piattaforma; <br />c. Con l’espressione “Servizi” si intendono i servizi
      offerti dalla Piattaforma ed accessibili tramite la dashboard;<br />
      d. Con l’espressione “Cittadino” o “Paziente” si intende il cittadino
      cliente della farmacia che si registra alla Piattaforma ed usufruisce dei
      servizi messi a disposizione in autonomia o con il supporto del
      farmacista. <br />
    </p>
    <h6 class="text-center">1. TERMINI E CONDIZIONI DI UTILIZZO</h6>
    <p>
      1.1. I presenti Termini e Condizioni indicano, in un linguaggio chiaro e
      di facile comprensione, i termini e le condizioni da applicarsi
      all’utilizzo dei Servizi fruibili tramite Dottorfarma da parte dei
      Cittadini che vi accedono e si registrano. <br />
      1.2. Tutti i Cittadini, prima di registrarsi e di fruire dei Servizi, sono
      invitati a leggere con attenzione i presenti Termini e Condizioni di
      Utilizzo della Piattaforma. Con la registrazione all’applicazione e
      l’utilizzo dei Servizi, il Cittadino dichiara di aver letto ed
      espressamente accettato i presenti Termini e Condizioni di Utilizzo. In
      ogni caso, tali comportamenti da parte del Cittadino comportano
      l’accettazione tacita dei Termini e Condizioni di Utilizzo e dei
      successivi aggiornamenti.
    </p>
    <h6 class="text-center">2. VALIDITA’ E MODIFICA</h6>
    <p>
      2.1. Promofarma S.r.l. si riserva il diritto di modificare,
      unilateralmente e in qualsiasi momento, i Termini e Condizioni d’uso
      dell’applicazione, comunicando ai Cittadini registrati le modifiche
      effettuate.
      <br />
      2.2. Tale comunicazione può essere effettuata, a discrezione del
      Fornitore, mediante avviso visualizzabile tramite la Piattaforma ovvero
      con qualsiasi modalità che garantisca la conoscibilità della comunicazione
      stessa. I Cittadini sono tenuti a verificare periodicamente eventuali
      modifiche apportate alle presenti Condizioni.
      <br />
      2.3. Promofarma S.r.l. si riserva altresì il diritto di modificare,
      migliorare e/o eliminare in qualsiasi momento e unilateralmente le
      funzionalità della Piattaforma, senza che ciò determini il diritto dei
      Cittadini a un indennizzo.
      <br />
    </p>
    <h6 class="text-center">3. TITOLARITA’ DELLA PIATTAFORMA</h6>
    <p>
      3.1. Promofarma S.r.l. è titolare esclusivo dei contenuti della
      Piattaforma, ivi compresi, a titolo esemplificativo e non esaustivo,
      l’architettura della stessa, i codici sorgente, i testi, i documenti, le
      immagini, i loghi, le fotografie, il layout delle pagine, il design, il
      know-how e le interfacce grafiche.
      <br />
      3.2. Promofarma S.r.l. è titolare esclusivo dei diritti di proprietà
      intellettuale ed industriale del dispositivo medico Dottorfarma, mentre
      Qwince s.r.l. ne è il fabbricante.
      <br />
      3.3. Il Cittadino non può utilizzare, copiare, riprodurre, distribuire,
      pubblicare o in qualsivoglia modo sfruttare qualsiasi caratteristica,
      funzionalità, strumento o contenuto della Piattaforma, in qualsiasi forma
      o con qualsiasi mezzo, salvo quanto espressamente previsto dai presenti
      Termini e Condizioni d’Utilizzo.
      <br />
    </p>
    <h6 class="text-center">4. CONDIZIONI PER L’UTILIZZO DELLA PIATTAFORMA</h6>
    <p>
      4.1. Per accedere alla Piattaforma ed usufruire dei servizi, il Cittadino
      deve essere in possesso di una connessione Internet attiva su un
      dispositivo proprio o messo a disposizione dalla struttura sanitaria (i
      costi connessi con l’uso di Internet dipendono dalle tariffe vigenti con
      il proprio fornitore di connessione Internet).
      <br />
      La registrazione alla Piattaforma e la fruizione dei Servizi sono
      consentiti esclusivamente ai Cittadini
      <u
        >persone fisiche che si siano registrati alla Piattaforma previa
        compilazione del form di registrazione</u
      >, abbiano accettato i Termini e Condizioni d’Uso ed abbiano preso visione
      e accettato l’informativa sul trattamento dei dati personali per la
      finalità di registrazione.
      <br />
    </p>
    <h6 class="text-center">5. LA REGISTRAZIONE ALLA PIATTAFORMA</h6>
    <p>
      5.1. La registrazione alla Piattaforma consente ai Cittadini di creare un
      Account e di accedere ai Servizi messi a disposizione dei Cittadini da
      Dottorfarma.
      <br />
      5.2. La registrazione è condizione essenziale: i Cittadini che non
      effettuano la registrazione non possono accedere ai servizi offerti.
      <br />
      5.3. Tramite la Piattaforma il Cittadino ha la possibilità di accedere ai
      Servizi messi a disposizione e selezionare come preferita una determinata
      farmacia; per la prenotazione di un farmaco il Cittadino non deve
      necessariamente selezionare una farmacia come preferita.
      <br />
      5.4. Al momento della registrazione, viene chiesto al Cittadino
      l’accettazione dei presenti Termini e condizioni di utilizzo nonché la
      conferma della presa visione dell’Informativa privacy.
      <br />
      5.5. Ultimato il login, il Cittadino si impegna a conservare la password
      di accesso e a non comunicarla a soggetti terzi: le credenziali di accesso
      sono infatti personali, relative al Cittadino e non cedibili, nemmeno a
      titolo gratuito. È responsabilità specifica del Cittadino impiegare ogni
      misura idonea a garantire la riservatezza delle proprie credenziali di
      accesso.
      <br />
      5.6. Ciascun Cittadino è direttamente responsabile per ogni attività posta
      in essere utilizzando l’account o le proprie credenziali di accesso e si
      impegna a comunicare a Promofarma S.r.l. qualsivoglia uso non autorizzato
      o comunque abusivo del proprio Account e/o delle proprie credenziali di
      accesso. Promofarma S.r.l. non potrà essere ritenuta in alcun modo
      responsabile per eventuali danni di qualunque natura patiti dal Cittadino
      in conseguenza dell’utilizzo non autorizzato o comunque abusivo
      dell’Account (e/o delle credenziali di accesso del Cittadino) posto in
      essere da parte di soggetti terzi.
      <br />
      In caso di problemi relativi all’Account o alla password, il Cittadino può
      contattare Promofarma S.r.l. inviando una e-mail all’indirizzo
      assistenza@dottorfarma.it.
      <br />
    </p>
    <h6 class="text-center">6. DIRITTI ED OBBLIGHI DEL CITTADINO</h6>
    <p>
      6.1. Mediante l’accettazione dei Termini e Condizioni d’uso, il Cittadino:
    </p>
    <ul>
      <li>
        conferma sotto la propria responsabilità di avere un’età maggiore di 18
        anni;
      </li>
      <li>
        si impegna ad usare la Piattaforma unicamente per gli scopi per cui è
        stata creata, vale a dire permettere di accedere ai Servizi offerti;
      </li>
      <li>
        si astiene dal porre in essere le seguenti attività:
        <ul>
          <li>
            modificare la Piattaforma e/o creare opere derivate basate sulla
            Piattaforma stessa;
          </li>
          <li>
            aggirare i sistemi informatici usati da Promofarma S.r.l. per
            proteggere il contenuto accessibile tramite gli stessi;
          </li>
          <li>
            copiare, in qualunque modo, qualunque contenuto fornito da
            Promofarma S.r.l. tramite la Piattaforma o nel corso dell’erogazione
            dei Servizi;
          </li>
          <li>
            violare i diritti alla protezione dei dati personali di qualsiasi
            soggetto.
          </li>
        </ul>
      </li>
    </ul>
    <p>
      6.2. Ciascun Cittadino è responsabile dell’uso che fa della Piattaforma,
      delle informazioni e dei dati che inserisce, delle scelte relative alla
      condivisione dei singoli dati inseriti.
      <br />
      6.3. In caso di contestazioni da parte di terzi riguardo a qualsiasi dato,
      contenuto o condotta posta in essere dal Cittadino mediante l’utilizzo
      della Piattaforma, il Cittadino se ne assume la piena responsabilità, sia
      nei confronti di terzi sia nei confronti di Promofarma S.r.l..
      <br />
      6.4. I Cittadini avranno diritto di segnalare a Promofarma S.r.l.
      eventuali problematiche inerenti al funzionamento o l’uso della
      Piattaforma inviando una mail all’indirizzo assistenza@dottorfarma.it.
      <br />
    </p>
    <h6 class="text-center">7. DISCLAIMER</h6>
    <p>
      7.1. Promofarma S.r.l. non può essere ritenuta responsabile per qualsiasi
      errore (inclusi errori materiali) e/o interruzione del funzionamento della
      Piattaforma e ad essa non imputabile.
      <br />
      7.2. Promofarma S.r.l. non rilascia alcuna garanzia e declina ogni
      responsabilità, nei limiti previsti dalla legge, per eventuali danni,
      diretti o indiretti che possano derivare al Cittadino dall’uso o
      dall’impossibilità di utilizzo della Piattaforma per cause ad essa non
      imputabili.
      <br />
      7.3. Promofarma S.r.l. non è altresì responsabile nei confronti dei
      Cittadini per qualsiasi danno derivante dal mancato rispetto da parte
      degli operatori di servizi di comunicazione elettronica, con cui i
      Cittadini hanno rapporti contrattuali, delle disposizioni di legge e
      contrattuali applicabili alla trasmissione tramite i servizi di qualsiasi
      segnale da o verso i Cittadini.
      <br />
      7.4. Eventuali responsabilità inderogabili per legge saranno comunque
      limitate alla fruizione della Piattaforma, intendendosi espressamente
      esclusa qualsiasi responsabilità per eventuali danni diretti e/o
      indiretti, che siano conseguenza della condotta dei Cittadini ovvero
      dell’accesso ed utilizzo abusivo dei dati da parte di terzi.
      <br />
      7.5. Promofarma S.r.l. non è responsabile per l’eventuale impossibilità di
      accesso e/o utilizzo della Piattaforma che dovesse dipendere da problemi
      di connessione ad Internet e/o da eventuali malfunzionamenti tecnici della
      stessa.
      <br />
      7.6. Promofarma S.r.l. non assume alcuna responsabilità per danni, pretese
      o perdite, dirette o indirette, derivanti al Cittadino per il mancato e/o
      difettoso funzionamento delle apparecchiature elettroniche o di terzi, di
      collegamenti telefonici e/o telematici non gestiti direttamente dallo
      stesso o dai suoi fornitori.
      <br />
    </p>
    <h6 class="text-center">8. LIMITAZIONI NELL’EROGAZIONE DEL SERVIZIO</h6>
    <p>
      8.1. Promofarma S.r.l. si riserva il diritto di modificare, sospendere o
      interrompere, in tutto o in parte, il Servizio in qualsiasi momento, anche
      senza preavviso.
      <br />
      8.2. A titolo esemplificativo, ma non esaustivo, ciò potrà avvenire per la
      mancanza di connessione Internet del dispositivo del Cittadino, per il
      malfunzionamento dei server o dei servizi resi da terze parti fornitrici
      ed altri dispositivi elettronici anche non facenti parte integrante della
      rete Internet, per il malfunzionamento dei software installati, virus
      informatici, nonché da azioni di hacker o altri Utenti aventi accesso alla
      rete.
      <br />
      8.3. Promofarma S.r.l. manterrà informati i Cittadini di eventuali
      sospensioni e/o interruzioni del Servizio attraverso comunicazioni
      all’e-mail utilizzata nella fase di registrazione.
      <br />
    </p>
    <h6 class="text-center">9. DURATA</h6>
    <p>
      9.1 Il Cittadino può disattivare il proprio account e terminare la
      registrazione in qualsiasi momento, per qualsiasi motivo inviando una
      e-mail a assistenza@dottorfarma.it.
      <br />
      9.2 A seguito dell’esercizio del diritto di recesso Promofarma S.r.l.
      provvederà a disattivare l’account personale del Cittadino ed a cancellare
      tutti i dati personali registrati ad eccezione dei dati che è tenuta a
      conservare in ottemperanza ad obblighi di legge.
      <br />
    </p>
    <h6 class="text-center">
      10. RESPONSABILITA’ PER LA PRESTAZIONE SANITARIA
    </h6>
    <p>
      10.1. Promofarma S.r.l. non può ritenersi in alcun modo responsabile o
      corresponsabile in relazione ai dati e alle informazioni caricate
      dall’Acquirente, dall’ Utente e/o dal Paziente nonché in relazione alla
      responsabilità professionale contestuale e/o conseguente all’erogazione
      della prestazione sanitaria effettuata dall’Acquirente utilizzando i
      Servizi messi a disposizione dalla Piattaforma. Ai sensi delle norme di
      legge attualmente in vigore, la responsabilità professionale della
      prestazione sanitaria erogata anche a distanza risulta in capo ai
      professionisti abilitati, in presenza ed in telemedicina; quindi, nessuna
      richiesta potrà essere promossa nei confronti di Promofarma S.r.l..
      <br />
      10.2. Promofarma S.r.l. potrà essere eventualmente responsabile di
      eventuali disservizi tecnici e/o organizzativi della piattaforma, fermo
      restando quanto esplicitato al paragrafo n. 8 dei presenti termini e
      condizioni.
      <br />
    </p>
    <h6 class="text-center">11. TRATTAMENTO DEI DATI PERSONALI</h6>
    <p>
      11.1. La Piattaforma è conforme all’attuale normativa in materia di
      trattamento dei dati personali. I dati forniti dal Cittadino sono trattati
      in osservanza della normativa prevista dal GDPR (Regolamento Generale
      sulla Protezione dei Dati, Reg. (UE) 2016/67) e dal decreto legislativo n.
      196/2003 (“Codice della privacy”), come modificato dal decreto legislativo
      101/2018.
      <br />
      11.2. Mediante la selezione tramite “flag” in fase di registrazione alla
      Piattaforma, il Cittadino acconsente al trattamento dei suoi dati
      personali per le finalità e secondo le modalità specificate
      nell'informativa Privacy.
      <br />
      11.3. I dati del Cittadino saranno utilizzati da Promofarma S.r.l. per i
      soli scopi previsti dall’Informativa Privacy e dalle specifiche
      autorizzazioni concesse dal Cittadino stesso.
      <br />
    </p>
    <h6 class="text-center">12. NULLITA’ PARZIALE</h6>
    <p>
      12.1. Nel caso in cui una clausola o parte delle Condizioni generali
      dovesse essere ritenuta nulla, illecita o illegittima, non andrà ad
      intaccare il resto del contratto e la sua validità ed efficacia.
    </p>
    <h6 class="text-center">13. FORO COMPETENTE E LEGGE APPLICABILE</h6>
    <p>
      13.1. I rapporti tra Promofarma S.r.l. e i Cittadini sono regolati dalla
      legge dello Stato dove il Cittadino risiede abitualmente. Per qualsiasi
      controversia inerente ai Termini e Condizioni d’Uso, stante la natura di
      consumatore del Cittadino, troverà applicazione quanto disposto dal D.lgs.
      206/2005 e, per l’effetto, sarà competente il foro della località indicata
      come propria residenza o domicilio dal Cittadino.
      <br />
      13.2. Per la risoluzione stragiudiziale delle controversie sorte in
      relazione ai Termini e Condizioni d’Uso disciplinati dalle disposizioni
      delle Sezioni da I a IV del Capo I del Titolo III della parte III del
      Codice del Consumo ed inerenti ai Termini e Condizioni d’Uso, il Cittadino
      potrà ricorrere alla procedure di mediazione di cui al D.lgs. n. 28/2010,
      ovvero potrà ricorrere agli organismi di ADR mediante la piattaforma ODR
      di risoluzione online delle controversie, istituita e gestita dalla
      Commissione Europea, e accessibile tramite il seguente link
      http://ec.europa.eu/consumers/odr/. Per ogni eventuale informazione sulle
      modalità di utilizzo della piattaforma ODR, il Cittadino potrà contattare
      la Società al seguente indirizzo di posta elettronica
      <br />
    </p>
  </div>
</template>

<script>
export default {
  name: "TerminiCondizioniCittadini",
};
</script>
