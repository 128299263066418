<template>
  <div class="d-inline">
    <span
      class="mx-1 point a__effect"
      @click="$bvModal.show('changelog__modal')"
    >
      Note di rilascio
    </span>
    <b-modal
      scrollable
      id="changelog__modal"
      hide-footer
      size="xl"
      title="Note di rilascio"
    >
      <b-row v-for="(log, ind) in logs" :key="ind" class="my-2 fs-6">
        <b-col cols="4" md="3" lg="2">
          <h4 class="text-center mb-0">
            <b-badge class="px-3 py-2 w-100" :variant="log.versionVariant">{{
              log.version
            }}</b-badge>
          </h4>
        </b-col>
        <b-col>
          <b-row align-v="center">
            <h4 class="mt-1">
              {{ log.releaseDate }}
              <b-icon
                v-if="ind === 0"
                icon="award-fill"
                animation="throb"
                :variant="log.versionVariant"
              ></b-icon>
            </h4>
          </b-row>
          <b-row v-if="log.new.show">
            <b-col cols="5" md="4" lg="3">
              <h6>
                <b-badge class="px-3 py-2 w-100" variant="ter-1"
                  >NOVITA'</b-badge
                >
              </h6>
            </b-col>
            <b-col>
              <p class="text-justify" v-html="log.new.text"></p>
            </b-col>
          </b-row>
          <b-row v-if="log.improved.show">
            <b-col cols="5" md="4" lg="3">
              <h6>
                <b-badge class="px-3 py-2 w-100" variant="ter-2"
                  >MIGLIORAMENTI</b-badge
                >
              </h6>
            </b-col>
            <b-col>
              <p class="text-justify" v-html="log.improved.text"></p>
            </b-col>
          </b-row>
          <b-row v-if="log.fixed.show">
            <b-col cols="5" md="4" lg="3">
              <h6>
                <b-badge class="px-3 py-2 w-100" variant="sec-2"
                  >CORREZIONI</b-badge
                >
              </h6>
            </b-col>
            <b-col>
              <p class="text-justify" v-html="log.fixed.text"></p>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: "ChangeLogComponent",
  data() {
    return {
      logs: [
        {
          version: "DF01.01.11",
          versionVariant: "prim-grad-1",
          releaseDate: "31 Maggio 2024",
          new: {
            show: false,
            text: "",
          },
          improved: {
            show: true,
            text: "Miglioramenti in area Con Te per il farmacista.",
          },
          fixed: {
            show: false,
            text: "",
          },
        },
        {
          version: "DF01.01.10",
          versionVariant: "secondary",
          releaseDate: "26 Marzo 2024",
          new: {
            show: false,
            text: "",
          },
          improved: {
            show: true,
            text: "Miglioramenti sulla visualizzazione dei Piani Terapeutici.",
          },
          fixed: {
            show: false,
            text: "",
          },
        },
        {
          version: "DF01.01.09",
          versionVariant: "secondary",
          releaseDate: "21 Febbraio 2024",
          new: {
            show: false,
            text: "",
          },
          improved: {
            show: true,
            text:
              "Validazione del numero di telefono di riserva per escludere i numeri di rete fissa. ",
          },
          fixed: {
            show: true,
            text:
              "Rimosso blocco alla scheda del paziente con numero di riserva non verificato.",
          },
        },
        {
          version: "DF01.01.08",
          versionVariant: "secondary",
          releaseDate: "29 Gennaio 2024",
          new: {
            show: false,
            text: "",
          },
          improved: {
            show: true,
            text:
              "Miglioramenti sulla gestione dei codici fiscali di <b>soggetti nati all'estero</b>. Introdotti selettori per anno e mese nell'agenda per velocizzarne lo scorrimento.",
          },
          fixed: {
            show: false,
            text: "",
          },
        },
        {
          version: "PF01.01.07",
          versionVariant: "secondary",
          releaseDate: "05 Dicembre 2023",
          new: {
            show: true,
            text:
              "<b>Sottoscrizioni e contratti</b>: introdotti i nuovi contratti e le nuove sottoscrizioni per le farmacie. Introdotta la sezione 'Sottoscrizioni' tra le impostazioni della farmacia. <b>Integrazione con Federfarma Lombardia</b>: accesso agevolato per farmacie ed ATS della regione Lombardia.",
          },
          improved: {
            show: true,
            text:
              "<b>Più opzioni per le notifiche di promemoria</b>: inserite più opzioni per l'invio notifiche di promemoria, per i monitoraggi a lungo termine. <b>Aggiornamento anagrafica paziente</b>: introdotto i campi facoltativi dedicati al medico di base e all'azienda sanitaria locale del paziente. <b> Nuove funzionalità </b> per la gestione da admin di attività e questionari. <b>Miglioramenti </b>sulla gestione delle notifiche push da più accessi.",
          },
          fixed: {
            show: true,
            text:
              "<b>Validazione numero di telefono</b>: verifica sulla formattazione del numero di telefono in caso di inserimento di prefisso. <b>Occasionale duplicazione alert</b>: corretto il problema che generava occasionalmente la duplicazione degli alert.",
          },
        },
        {
          version: "PF01.01.06",
          versionVariant: "secondary",
          releaseDate: "03 Agosto 2023",
          new: {
            show: true,
            text:
              "<b>Nuove misure di sicurezza</b>: obbligatorio adesso l'inserimento e la validazione del numero di telefono per pazienti e farmacisti. <b>Recupero password tramite codice fiscale</b>: in caso di impossibilità di accesso alla propria casella email, sarà possibile recuperare la propria password tramite codice fiscale e numero di telefono. <b>Sezione Social & Web:</b> la farmacia può adesso inserire i collegamenti ai propri canali social ed al proprio sito web, che saranno visualizzabili da tutti i cittadini.",
          },
          improved: {
            show: true,
            text:
              "<b>Controllo sulle visualizzazioni del profilo sanitario</b>: grazie all'autorizzazione via OTP il paziente ha pieno controllo sulle visualizzazioni del proprio profilo sanitario. <b>Arruolamento senza email</b>: è adesso possibile arruolare in piattaforma un paziente sprovvisto di casella email. <b>Ottimizzazioni sui Piani Terapeutici</b>: aggiornamento dei campi note con inoltro di notifica, per migliorare le comunicazioni con le Asl. <b>Autocompilazione campi arruolamento</b>: per un arruolamento più veloce.",
          },
          fixed: {
            show: false,
            text:
              "<b>Correzzioni grafiche</b>: spostato più in basso il bottone per la chat di supporto per una migliore lettura.",
          },
        },
        {
          version: "PF01.01.05",
          versionVariant: "secondary",
          releaseDate: "28 Febbraio 2023",
          new: {
            show: true,
            text:
              "<b>Gestione dei Piani Terapeutici</b>: gestione dei Piani Terapeutici per i pazienti cronici in assistenza farmaceutica diretta.",
          },
          improved: {
            show: false,
            text: "<b>Ottimizzazioni</b>.",
          },
          fixed: {
            show: false,
            text:
              "<b>Corretto il problema che impediva di prenotare un farmaco ed una prestazione nella stessa fascia oraria</b>: l'operatore sanitario potrà, tramite le impostazioni, decidere se consentire o meno la sovrapposizione di più appuntamenti su servizi diversi per la stessa fascia oraria.",
          },
        },
        {
          version: "PF01.01.04",
          versionVariant: "secondary",
          releaseDate: "20 Gennaio 2023",
          new: {
            show: false,
            text:
              "<b>Nuovi strumenti per l'operatore sanitario</b>: evadi più richieste contemporaneamente accedendo alla vista giornaliera del calendario e selezionando una o più richieste da evadere, oppure tramite lo strumento dedicato in area Facile. <b>I tuoi assistiti a portata di mano:</b> adesso puoi visualizzare i tuoi assistiti nell'area pazienti ed accedere velocemente alla scheda del paziente tramite la ricerca in lista per codice fiscale o per cognome.<br/><b>Rischedula gli appuntamenti:</b> adesso è possibile richiedere la modifica di data e/o ora per le richieste di tutti i servizi.",
          },
          improved: {
            show: true,
            text: "<b>Ottimizzazioni e miglioramenti</b>.",
          },
          fixed: {
            show: false,
            text:
              "<b>Corretto il problema che impediva di prenotare un farmaco ed una prestazione nella stessa fascia oraria</b>: l'operatore sanitario potrà, tramite le impostazioni, decidere se consentire o meno la sovrapposizione di più appuntamenti su servizi diversi per la stessa fascia oraria.",
          },
        },
        {
          version: "PF01.01.03",
          versionVariant: "secondary",
          releaseDate: "25 Novembre 2022",
          new: {
            show: true,
            text:
              "<b>Nuovi strumenti per l'operatore sanitario</b>: evadi più richieste contemporaneamente accedendo alla vista giornaliera del calendario e selezionando una o più richieste da evadere, oppure tramite lo strumento dedicato in area Facile. <b>I tuoi assistiti a portata di mano:</b> adesso puoi visualizzare i tuoi assistiti nell'area pazienti ed accedere velocemente alla scheda del paziente tramite la ricerca in lista per codice fiscale o per cognome.<br/><b>Rischedula gli appuntamenti:</b> adesso è possibile richiedere la modifica di data e/o ora per le richieste di tutti i servizi.",
          },
          improved: {
            show: true,
            text:
              "<b>Miglioramenti sull'agenda dell'operatore sanitario</b>: inserito il campo di ricerca per cognome e il tasto di inserimento di una nuova prenotazione. <br/><b>Revisione dei report di prenotazioni</b>, l'operatore potrà adesso visualizzare in tutti i report di prenotazioni anche i dati di contatto dei pazienti.",
          },
          fixed: {
            show: true,
            text:
              "<b>Corretto il problema che impediva di prenotare un farmaco ed una prestazione nella stessa fascia oraria</b>: l'operatore sanitario potrà, tramite le impostazioni, decidere se consentire o meno la sovrapposizione di più appuntamenti su servizi diversi per la stessa fascia oraria.",
          },
        },
        {
          version: "PF01.01.02",
          versionVariant: "secondary",
          releaseDate: "27 Ottobre 2022",
          new: {
            show: true,
            text:
              "<b>Impostazione di date indisponibilità</b> a tutti i servizi: inserita una funzionalità che consente agli operatori sanitari di aggiungere delle date, festive e non, per le quali il sistema non consentirà la prenotazione indipendentemente dalla programmazione settimanale. <br/><b>Aggiornamento note prenotazione</b>, grazie alle quali cittadini ed operatori potranno notificare alla controparte comunicazioni rilevanti per la prenotazione.",
          },
          improved: {
            show: true,
            text:
              "<b>Miglioramenti sull'agenda dell'operatore sanitario</b>: migliorata la visibilità delle prestazioni sanitarie per le quali è possibile associare una sigla da mostrare insieme all'icona ed inserite le funzionalità di ricerca per codice fiscale ed estrazione di report settimanali. <br/><b>Miglioramenti sugli ordini di prodotto</b>: è adesso possibile specificare le quantità per prodotto, segnalare eventuale preferenza del farmaco in versione equivalente/generico e prenotare il ritiro di prodotti fuori-lista inserendoli manualmente.",
          },
          fixed: {
            show: false,
            text: "",
          },
        },
        {
          version: "PF01.01.01",
          versionVariant: "secondary",
          releaseDate: "25 Agosto 2022",
          new: {
            show: true,
            // text: "<b>E' arrivato il nuovo DottorFARMA!</b><br/> Una nuova grafica, pensata per essere intuitiva e di facile uso, e tante nuove funzionalità. Oltre ai farmaci"
            text:
              "Rilasciati nella prima versione: <ul><li><b>Modulo core</b>, che garantisce una sicura gestione delle utenze e dei pazienti</li><li><b>Modulo</b> dedicato al <b>teleconsulto</b>, per tenere in contatto operatori sanitari e pazienti anche da remoto</li><li><b>Modulo prenotazioni</b>, che consente ai cittadini di prenotare ordini e prestazioni sanitarie in autonomia secondo le indicazioni di disponibilità degli operatori </li><li><b>Modulo alert</b>, che vuole contribuire a facilitare il monitoraggio e l'aderenza alla terapia per i pazienti</li></ul>",
          },
          improved: {
            show: false,
            text: "",
          },
          fixed: {
            show: false,
            text: "",
          },
        },
      ],
    };
  },
};
</script>
