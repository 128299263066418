<template>
  <b-container>
    <b-row cols="1" class="text-left mb-3">
      <router-link class="fw-bolder text-primary no__hover" :to="{ name: 'familiari' }">
        <b-icon icon="arrow-left-circle-fill" variant="primary"></b-icon>
        Familiari
      </router-link>
    </b-row>
    <div>
      <h6 class="
          text-prim-grad-1
          fw-bolder
          text-left
          pb-1
          border-bottom border-line-grey
        ">
        Seleziona il tipo di inserimento
      </h6>
      <b-row class="text-left my-4">
        <b-col class="col-12 col-md-5 px-0 px-md-2">
          <b-form-group>
            <b-form-radio class="font-weight-bold" v-model="selectedOption" aria-describedby="secondOption"
              name="some-radios" :value="1">Inserimento semplice</b-form-radio>
            <small id="secondOption" class="ml-4 form-text text-muted">Inserisci manualmente un utente non presente nel
              sistema</small>
          </b-form-group>
        </b-col>
        <b-col class="col-12 col-md-5 px-0 px-md-2">
          <b-form-group>
            <b-form-radio class="font-weight-bold" v-model="selectedOption" aria-describedby="firstOption"
              name="some-radios" :value="0">Utente registrato</b-form-radio>
            <small id="firstOption" class="ml-4 form-text text-muted">Se l'utente è presente nella piattaforma puoi
              inserirlo tramite
              codice utente</small>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <div v-if="selectedOption === 1">
      <h6 class="
          text-prim-grad-1
          fw-bolder
          text-left
          pb-1
          border-bottom border-line-grey
        ">
        Nuovo familiare
      </h6>
      <p class="text-left text-font-light-grey fs-5">
        Crea un nuovo familiare.
      </p>
      <div>
        <b-form @submit.prevent="onSubmit">
          <div class="col-12 d-flex flex-wrap px-0 px-md-2">
            <b-form-group label="Nome*" label-for="name" class="
                text-left
                fs-6
                text-font-grey
                mx-auto
                col-12 col-md-6
                px-0 px-md-2
              ">
              <b-form-input class="my-3 shadow_6 text-capitalize" name="name" type="text" placeholder="nome..."
                v-model="newMember.name" required></b-form-input>
            </b-form-group>

            <b-form-group label="Cognome*" label-for="surname" class="
                text-left
                fs-6
                text-font-grey
                mx-auto
                col-12 col-md-6
                px-0 px-md-2
              ">
              <b-form-input class="my-3 shadow_6 text-capitalize" name="surname" type="text" placeholder="cognome..."
                v-model="newMember.surname" required></b-form-input>
            </b-form-group>
            <b-form-group label="Codice fiscale*" label-for="cf" class="
                text-left
                fs-6
                text-font-grey
                mx-auto
                col-12 col-md-6
                px-0 px-md-2
              ">
              <b-form-input class="my-3 shadow_6 text-uppercase" name="cf" type="text" placeholder="codice fiscale..."
                maxlength="16" minlength="16" v-model="newMember.cf" required></b-form-input>
            </b-form-group>

            <b-form-group label="Numero Tessera Sanitaria" label-for="ts_number" class="
                text-left
                fs-6
                text-font-grey
                mx-auto
                col-12 col-md-6
                px-0 px-md-2
              ">
              <b-form-input class="my-3 shadow_6" name="ts_number" type="text" placeholder="numero tessera..."
                v-model="newMember.ts_number" maxlength="20" minlength="20"></b-form-input>
            </b-form-group>
            <b-form-group label="Fascia d'età*" label-for="type" class="
                text-left
                fs-6
                text-font-grey
                mx-auto
                col-12 col-md-6
                px-0 px-md-2
              ">
              <b-form-select class="my-3 shadow_6 text-capitalize" :options="memberTypes" name="type"
                v-model="newMember.type" required>
                <b-form-select-option :value="null">Seleziona fascia d'età</b-form-select-option>
              </b-form-select>
            </b-form-group>

            <b-form-group label="Relazione*" label-for="relazione" class="
                text-left
                fs-6
                text-font-grey
                mx-auto
                col-12 col-md-6
                px-0 px-md-2
              ">
              <b-form-select class="my-3 shadow_6 text-capitalize" name="relazione" v-model="newMember.relationship">
                <b-form-select-option v-for="rel in relationships" :key="rel" :value="rel">{{ rel }}
                </b-form-select-option>
                <b-form-select-option :value="null">Seleziona relazione</b-form-select-option>
              </b-form-select>
            </b-form-group>
            <b-form-group label="Genere*" label-for="gender"
              class="text-left fs-6 text-font-grey mx-auto col-12 px-0 px-md-2">
              <b-form-radio-group v-model="newMember.gender" name="gender-options" class="d-flex">
                <b-form-radio value="maschile">maschile</b-form-radio>
                <b-form-radio value="femminile">femminile</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </div>
          <div class="mx-auto pb-4 mb-4 text-right">
            <p class="text-left pl-4">*Campi obbligatori</p>
            <b-button type="button" variant="outline-danger fw-bolder m-2"
              @click.prevent="$router.push({ name: 'familiari' })">Annulla</b-button>
            <b-button type="submit" variant="primary text-white primary_gradient fw-bolder m-2" :disabled="
              newMember.relationship == null && newMember.type == null
            ">Salva
            </b-button>
          </div>
        </b-form>
      </div>
    </div>
    <div v-else>
      <h6 class="
          text-prim-grad-1
          fw-bolder
          text-left
          pb-1
          border-bottom border-line-grey
        ">
        Utente registrato
      </h6>
      <p class="text-left text-font-light-grey fs-5">
        Ricerca un tuo parente o un tuo assistito tramite codice utente -
        visualizzabile nella sezione "Impostazioni", sotto "Codice utente
        personale" - ed invitalo a far parte della tua famiglia.
        <span>
          L'utente invitato riceverà una email tramite la quale potrà accettare
          il tuo invito e designarti come proprio caregiver.
        </span>
      </p>
      <SearchUserByCode v-on:invite-user="sendInvite($event)" />
    </div>
  </b-container>
</template>
<script>
import { cittadinoService } from "@/_services";
import { mapState, mapActions } from "vuex";
import SearchUserByCode from "@/components/utilities/SearchUserByCode.vue";
export default {
  name: "NuovoFamiliare",
  components: { SearchUserByCode },
  data() {
    return {
      selectedOption: 1,
      params: {
        cf: null,
        email: null,
      },
      newMember: {
        name: null,
        surname: null,
        cf: null,
        ts_number: null,
        type: null,
        gender: "maschile",
        relationship: null,
      },
      memberTypes: ["età pediatrica", "adulto", "anziano"],
      genders: ["femminile", "maschile"],
      relationships: [
        "figlio",
        "genitore",
        "coniuge",
        "convivente",
        "nipote",
        "altro(parente)",
        "altro(non parente)",
      ],
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    onSubmit() {
      var self = this;
      cittadinoService
        .storeFamilyMembers(this.newMember)
        .then(function (res) {
          window.scrollTo(0, 0);
          self.successAlert("Nuovo assistito creato con successo!");
          self.clearForm();
          // self.memberList = res.data.data;
        })
        .catch(function (err) {
          console.log(err);
          var msg = "Non è stato possibile salvare le informazioni.";
          if (err.data.errors && err.data.errors.cf) {
            msg = err.data.errors.cf[0];
          }
          window.scrollTo(0, 0);
          self.errorAlert(msg);
        });
    },
    clearForm() {
      this.newMember = {
        name: null,
        surname: null,
        cf: null,
        ts_number: null,
        type: null,
        gender: "maschile",
        relationship: null,
      };
    },
    sendInvite(data) {
      //console.log(data);
      var form = {
        user: data,
      };
      var self = this;
      cittadinoService
        .inviteCaregiver(form)
        .then(function (res) {
          window.scrollTo(0, 0);
          self.successAlert("Invito inviato con successo!");
          self.clearForm();
          // self.memberList = res.data.data;
        })
        .catch(function (err) {
          console.log(err);
          var msg = "Non è stato possibile inviare l'invito.";
          window.scrollTo(0, 0);
          self.errorAlert(msg);
        });
    },
  },
  created() { },
};
</script>
