<template>
  <div>
    <b-row cols="2" cols-md="3" cols-lg="4" v-if="prohibidates">
      <b-col v-for="date,ind in prohibidates" :key="ind" class="text-left">
        <b-form-checkbox v-model="date.active" :name="'date'+ind" :value="1" :unchecked-value="0"
          @change="updateDateStatus(ind)">
          {{date.day}} {{months[date.month]}} <span class="text-font-grey">{{date.description}}</span>
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="my-2">
        <div class="d-flex align-items-center point" @click="$bvModal.show('new-prob')">
          <span class="">
            <b-iconstack font-scale="2.5">
              <b-icon stacked icon="circle-fill" variant="primary"></b-icon>
              <b-icon stacked icon="plus" scale="0.8" variant="white"></b-icon>
              <b-icon stacked icon="circle" variant="primary"></b-icon>
            </b-iconstack>
          </span>
          <span class="text-primary ml-2 fw-bolder">Aggiungi</span>
        </div>
      </b-col>
    </b-row>
    <b-modal id="new-prob" hide-footer title="Aggiungi nuova data di indisponibilità" size="lg">
      <b-form @submit.prevent="saveNew" @reset="resetForm">
        <b-row>
          <b-col cols="5">
            <b-form-group label="Giorno:*">
              <b-form-input focused class="shadow_6" type="number" v-model="form.day" :step="1" :min="1" :max="31"
                placeholder="giorno.." required></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Mese:*">
              <b-form-select class="shadow_6" v-model="form.month" placeholder="mese">
                <b-form-select-option v-for="month,ind in months" :value="ind" required>{{month}}</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-form-group label="Nome:">
          <b-form-input class="shadow_6" type="text" v-model="form.description" placeholder="occorrenza..">
          </b-form-input>
        </b-form-group>
        <p>*parametri obbligatori</p>
        <div class="text-center">
          <b-button type="reset" variant="outline-danger" class="m-1">Annulla</b-button>
          <b-button type="submit" variant="primary primary_gradient text-white" class="m-1">Salva</b-button>
        </div>
      </b-form>

    </b-modal>
  </div>
</template>

<script>
import * as moment from "moment/moment";
import { mapState, mapActions } from "vuex";
import { companyService } from "@/_services";
export default {
  name: "ProhibidatesComponent",
  props: [],
  computed: {
    ...mapState("utente", ["status", "user"]),
    ...mapState({
      alert: (state) => state.alert,
    }),
  },
  data() {
    return {
      prohibidates: null,
      form: {
        day: 1,
        month: 1,
        active: true,
        description: null
      },
      months: {
        1: "Gennaio",
        2: "Febbraio",
        3: "Marzo",
        4: "Aprile",
        5: "Maggio",
        6: "Giugno",
        7: "Luglio",
        8: "Agosto",
        9: "Settembre",
        10: "Ottobre",
        11: "Novembre",
        12: "Dicembre"
      }
    };
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    fetchData() {
      var self = this;
      companyService
        .getOwnProhibidates()
        .then(function (resp) {
          // //console.log(resp);
          self.prohibidates = resp.data.data;
        })
        .catch(function (error) {
          self.errorAlert("Non è stato possibile recuperare le sottoscrizioni");
        });
    },
    updateDateStatus(ind) {
      var self = this;
      var formData = this.prohibidates[ind];
      companyService.updateProhibidate(formData).then(function (res) {
        console.log(res);
        self.fetchData()
        window.scrollTo(0, 0)
        self.successAlert("modifiche salvate correttamente")
      }).catch(function (err) {
        console.log(err)
        window.scrollTo(0, 0)
        self.errorAlert("Non è stato possibile salvare le modifiche")
      })
    },
    resetForm() {
      this.form = {
        day: 1,
        month: 1,
        active: true,
        description: null
      }
      this.$bvModal.hide('new-prob')
    },
    saveNew() {
      var self = this;
      companyService.storeNewProhibidate(this.form).then(function (res) {
        console.log(res);
        self.fetchData()
        self.$bvModal.hide('new-prob')
        window.scrollTo(0, 0)
        self.successAlert("Nuova data salvata correttamente")
        self.resetForm()
      }).catch(function (err) {
        console.log(err)
        window.scrollTo(0, 0)
        self.$bvModal.hide('new-prob')
        self.errorAlert("Non è stato possibile salvare la data")
        self.resetForm()
      })
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>