<template>
  <div>
    <b-form @submit.prevent="sendForm" class="text-left fw-bolder">
      <b-form-group id="name" label="Nome*:" label-for="name">
        <b-form-input id="name" v-model="form.name" type="text" placeholder="Nome attività.." class="shadow_6" required>
        </b-form-input>
      </b-form-group>
      <b-form-group id="description" label="Descrizione:" label-for="description">
        <b-form-input id="description" v-model="form.description" type="text" placeholder="Descrizione attività.."
          class="shadow_6"></b-form-input>
      </b-form-group>

      <b-form-group id="service" label="Servizio*:" label-for="description">
        <b-form-select name="service" class="shadow_6 text-capitalize" v-model="form.position_ref" required>
          <b-form-select-option :value="1">facile prestazioni</b-form-select-option>
          <b-form-select-option :value="2">connessa</b-form-select-option>
          <b-form-select-option :value="3">con te</b-form-select-option>
        </b-form-select>
      </b-form-group>
      <b-form-group label="Attiva:"
        description="*La nuova attività sarà disponibile solo per le aziende a cui deciderai di assegnarla.">
        <b-form-checkbox id="enabled" v-model="form.enabled" name="enabled" :checked-value="true">
          Attività abilitata
        </b-form-checkbox>
      </b-form-group>
      <div class="text-center">
        <b-button type="submit" variant="primary primary_gradient text-white fw-bolder spacing_1">Crea attività
        </b-button>
      </div>
    </b-form>
  </div>
</template>
<script>
import { supportService } from "@/_services";
import { mapState, mapActions } from "vuex";
export default {
  name: "NuovaAttivita",
  props: ["position"],
  data() {
    return {
      form: {
        name: null,
        description: null,
        enabled: true,
        position_ref: 3,
      },
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    sendForm(e) {
      e.preventDefault();
      var self = this;
      supportService
        .storeActivity(this.form)
        .then(function (res) {
          // //console.log(res);
          self.$emit("activity-created", true);
        })
        .catch(function (err) {
          console.log(err);

          self.$emit("activity-created", false);
        });
    },
  },
  created() {
    if (this.position) {
      this.form.position_ref = this.position;
    }
  },
};
</script>