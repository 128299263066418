<template>
  <div>
    <div class="d-flex justify-content-between">
      <router-link class="fw-bolder text-primary no__hover" :to="{ name: 'gestione servizi' }">
        <b-icon icon="arrow-left-circle-fill" variant="primary"></b-icon>
        Gestione servizi
      </router-link>
    </div>
    <div class="py-4">
      <!-- <h6
        class="
          text-prim-grad-1
          fw-bolder
          text-left
          pb-1
          border-bottom border-line-grey
        "
      >
        Prestazioni
      </h6> -->
      <p>IN SVILUPPO</p>
    </div>
  </div>
</template>
<script>
import { adminService } from "@/_services";
export default {
  name: "GestioneConTe",
  data() {
    return {
      prestazioni: null,
    };
  },
  methods: {
    getPrestazioni() {
      var self = this;
      adminService
        .getPrestazioni()
        .then(function (response) {
          //console.log(response);
          self.prestazioni = response.data.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
  created() {
    // this.getPrestazioni();
  },
};
</script>