<template>
  <div>
    <router-link
      class="my-1 my-md-3 mx-auto"
      :to="homePath + '/prenotazioni'"
      :class="
        this.$route.fullPath === homePath + '/prenotazioni' &&
        'router-link-exact-active'
      "
    >
      <b-icon
        font-scale="1.5"
        :icon="
          this.$route.fullPath === homePath + '/prenotazioni'
            ? 'list-nested'
            : 'list'
        "
      ></b-icon>
      <p class="mb-0 fs-5">Tutte</p></router-link
    >
    <!-- disattivate per il solo vaccini e tamponi -->
    <router-link
      class="my-1 my-md-3 mx-auto"
      :to="homePath + '/prenotazioni?scope=prestazione'"
      :class="
        this.$route.path === homePath + '/prenotazioni?scope=prestazione' &&
        'router-link-exact-active'
      "
    >
      <b-icon font-scale="1.5" icon="card-list"></b-icon>
      <p class="mb-0 fs-5">Prestazioni</p></router-link
    >
    <router-link
      v-if="user.type == 1"
      class="my-1 my-md-3 mx-auto"
      :to="homePath + '/prenotazioni?scope=prodotto'"
      :class="
        this.$route.path === homePath + '/prenotazioni?scope=prodotto' &&
        'router-link-exact-active'
      "
    >
      <b-icon font-scale="1.5" icon="list-check"></b-icon>
      <p class="mb-0 fs-5">Ordini</p></router-link
    >
    <router-link
      v-if="user.type == 1"
      class="my-1 my-md-3 mx-auto"
      :to="homePath + '/prenotazioni/calendario'"
      :class="
        this.$route.path === homePath + '/prenotazioni/calendario' &&
        'router-link-exact-active'
      "
    >
      <b-icon
        font-scale="1.5"
        :icon="
          this.$route.path === homePath + '/prenotazioni/calendario'
            ? 'calendar-week-fill'
            : 'calendar-week'
        "
      ></b-icon>
      <p class="mb-0 fs-5">Calendario</p></router-link
    >
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "PrenotazioniMenu",
  computed: {
    homePath() {
      if (this.user) {
        return "/" + this.user.i_am;
      }
    },
    ...mapState("utente", ["status", "user"]),
  },
  data() {
    return {};
  },
};
</script>