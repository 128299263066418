<template>
  <!-- <div> -->
  <!-- <b-row cols="1" style="position: absolute; bottom: 0; right: 0"> -->
  <b-row cols="1" class="pls__btn" :class="menuOpen ? ' bg-white-faded' : ''">
    <transition-group name="slide" mode="out-in">
      <!-- <b-col key="openbtn">
        <b-button @click="menuOpen = !menuOpen" size="sm" class="px-4 px-md-3 px-lg-4 mx-auto"
          variant="sec-2 text-white rounded-pill text-uppercase fw-bolder">
          prenota
        </b-button>
      </b-col> -->

      <!-- <b-col v-if="menuOpen" key="neworder" style="position: absolute; bottom: 40px; right: 0" class=" px-0"> -->
      <b-col v-if="menuOpen" key="neworder" class=" px-0">
        <div class="d-flex flex-column justify-content-end">
          <b-row cols="2" align-v="center" @click="
            $router.push({
              name: 'Nuovo Ordine',
              params: { type: 'nuova prenotazione' },
            })
          " class="
              point
              submenu__border
              bg-white
              shadow_6
              fw-bolder
              my-2
              py-2 col-md-10 col-12 col-lg-6 px-0 px-md-2 mr-0 ml-auto
            ">
            <span class="text-right">
              <!-- <b-icon scale="1.3" icon="file-ppt" variant="white">
              </b-icon> -->
              <strong class="pl-md-2 pl-1 pr-md-2 "> Nuova Richiesta</strong>
            </span>
            <b-col>
              <b-img height="25%" left :src="require('@/assets/facile.svg')" alt="logo facile">
              </b-img>
            </b-col>
          </b-row>
          <b-row cols="2" align-v="center" v-if="!$root.prestazioniOnly" @click="
            $router.push({
              name: 'Nuovo Ordine',
              params: { type: 'nuovo ordine' },
            })
          " class="
              point
              submenu__border
              bg-white
              shadow_6
              fw-bolder
              my-2
              py-2 col-md-10 col-12 col-lg-6 px-0 px-md-2 mr-0 ml-auto
            ">
            <span class="text-right">
              <!-- <b-icon scale="1.3" icon="file-ppt" variant="white">
              </b-icon> -->
              <strong class="pl-md-2 pl-1 pr-md-2 "> Nuovo Ordine </strong>
            </span>
            <b-col>
              <b-img height="25%" left :src="require('@/assets/facile.svg')" alt="logo facile">
              </b-img>
            </b-col>
          </b-row>
          <b-row cols="2" align-v="center" v-if="!$root.prestazioniOnly" @click="
            $router.push({
              name: 'Nuovo Ordine',
              params: { type: 'nuovo teleconsulto' },
            })
          " class="
              point
              submenu__border
              bg-white
              shadow_6
              fw-bolder
              my-2
              py-2 col-md-10 col-12 col-lg-6 px-0 px-md-2 mr-0 ml-auto
            ">
            <span class="text-right">
              <!-- <b-icon scale="1.3" icon="file-ppt" variant="white">
              </b-icon> -->
              <strong class="pl-md-2 pl-1 pr-md-2 "> Nuovo Teleconsulto </strong>
            </span>
            <b-col>
              <b-img height="25%" left :src="require('@/assets/connessa.svg')" alt="logo facile">
              </b-img>
            </b-col>
          </b-row>

          <!-- <span @click="
            $router.push({
              name: 'Nuovo Ordine',
              params: { type: 'nuova prenotazione' },
            })
          " :class="$root.isMobile ? 'col-10' : 'col-4'" class="
              point
              submenu__border
              bg-white
              shadow_6
              ml-auto
              my-2
              py-2
              px-4
              fw-bolder
            ">
            <b-icon scale="1.3" icon="file-ppt" variant="primary"></b-icon>
            <strong class="pl-3 pr-2 fs-4"> Nuova Richiesta</strong>
          </span> -->

          <!-- <span v-if="!$root.prestazioniOnly" @click="
            $router.push({
              name: 'Nuovo Ordine',
              params: { type: 'nuovo ordine' },
            })
          " :class="$root.isMobile ? 'col-10' : 'col-4'" class="
              point
              submenu__border
              bg-white
              shadow_6
              ml-auto
              my-2
              py-2
              px-4
              fw-bolder
            ">
            <b-icon scale="1.3" icon="bag" variant="primary"></b-icon>
            <strong class="pl-3 pr-2 fs-4"> Nuovo Ordine </strong>
          </span> -->
          <!-- 
          <span v-if="!$root.prestazioniOnly" @click="
            $router.push({
              name: 'Nuovo Ordine',
              params: { type: 'nuovo teleconsulto' },
            })
          " :class="$root.isMobile ? 'col-10' : 'col-4'" class="
              point
              submenu__border
              bg-white
              shadow_6
              ml-auto
              my-2
              py-2
              px-4
              fw-bolder
            ">
            <b-icon scale="1.3" icon="camera-video" variant="primary"></b-icon>
            <strong class="pl-3 pr-2 fs-4"> Nuovo Teleconsulto </strong>
          </span> -->
        </div>
      </b-col>
      <!-- <b-col v-if="menuOpen" key="newterapy">
        <div class="d-flex justify-content-end">
          <span
            class="point submenu__border bg-white shadow_6 ml-2 my-2 py-2 px-4 fw-bolder"
          >
            <b-icon scale="1.3" icon="bag-plus" variant="primary"></b-icon>
            <strong class="pl-3 pr-2 fs-4"> Nuova Terapia</strong>
          </span>
        </div>
      </b-col> -->
      <b-col key="openbtn">
        <div class="d-flex justify-content-end mt-2">
          <span class="ml-2 point" @click="menuOpen = !menuOpen">
            <b-iconstack font-scale="3">
              <b-icon stacked icon="circle-fill" variant="primary"></b-icon>
              <b-icon stacked :icon="menuOpen ? 'x' : 'plus'" scale="0.8" variant="white"></b-icon>
              <b-icon stacked icon="circle" variant="primary"></b-icon>
            </b-iconstack>
          </span>
        </div>
      </b-col>
    </transition-group>
  </b-row>
  <!-- </div> -->
</template>
<script>
export default {
  name: "NewStuff",
  data() {
    return {
      menuOpen: false,
    };
  },
  created() { },
};
</script>
<style>
.bg-white-faded {
  background-color: rgba(255, 255, 255, 0.459);
  padding-top: 1rem;
}
</style>