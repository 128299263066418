<template>
  <div class="text-left">
    <h6 class="mb-3">Di sequito l'elenco delle domande più richieste:</h6>
            <!-- <a class="text-prim-grad-1 ml-2" href="#1">Sono una Farmacia. Posso accedere al servizio?</a> -->
          <section class="pb-4 ml-2 col-md-8 ">

              <div class="d-flex border collapse-title border-primary point align-items-center justify-content-between pr-4 mt-2"
              @click="showPazienti = !showPazienti"
              >
              <h6 class=" p-2 mb-0"  variant="primary">
                Area Pazienti</h6>
                <b-icon :icon="showPazienti ? 'chevron-up' : 'chevron-down'"></b-icon>
              </div>

  <b-collapse v-model="showPazienti">
    <section class="d-flex flex-column my-2">
            <a class="text-prim-grad-1 ml-2" href="#2">Come inserisco un paziente nuovo?</a>
      <a class="text-prim-grad-1 ml-2" href="#3">Quali informazioni visualizzo nella scheda paziente?</a>
      <a class="text-prim-grad-1 ml-2" href="#4">Come cercare un paziente e accedere alla sua scheda?</a>
      <a class="text-prim-grad-1 ml-2" href="#5">Come modifico i dati di un paziente esistente?</a>
      <a class="text-prim-grad-1 ml-2" href="#6">Come inserisco una nuova terapia?</a>
      <a class="text-prim-grad-1 ml-2" href="#7">Come modifico la terapia di un paziente esistente?</a>
      <a class="text-prim-grad-1 ml-2" href="#8">Posso cancellare i dati di un paziente esistente?</a>
      <a class="text-prim-grad-1 ml-2" href="#9">Perché in alcuni casi non vedo tutte le voci della scheda paziente?</a>
    </section>
  </b-collapse>

   <div class="d-flex border collapse-title border-primary point align-items-center justify-content-between pr-4 mt-2"
              @click="showFacile = !showFacile"
              >
              <h6 class=" p-2 mb-0"  variant="primary">
                Area Facile</h6>
                <b-icon :icon="showFacile ? 'chevron-up' : 'chevron-down'"></b-icon>
              </div>

  <b-collapse v-model="showFacile">
    <section class="d-flex flex-column my-2">
                  <a class="text-prim-grad-1 ml-2" href="#10">Come funziona il servizio Facile?</a>
      <a class="text-prim-grad-1 ml-2" href="#11">Dove posso visualizzare le prenotazioni ricevute?</a>
      <a class="text-prim-grad-1 ml-2" href="#12">Dove posso visualizzare lo stato delle prenotazioni che ho ricevuto?</a>
      <a class="text-prim-grad-1 ml-2" href="#13">Che differenza c'è tra prenotazioni in attesa, in attesa di risposta, e ricevute?</a>
      <a class="text-prim-grad-1 ml-2" href="#14">Come posso cercare una prenotazione?</a>
      <a class="text-prim-grad-1 ml-2" href="#15">Come posso annullare le prenotazioni di una giornata?</a>
      <a class="text-prim-grad-1 ml-2" href="#16">Posso scaricare un file con la programmazione delle prenotazioni?</a>
      <a class="text-prim-grad-1 ml-2" href="#17">Posso vedere il dettaglio della tipologia di prenotazione?</a>
      <a class="text-prim-grad-1 ml-2" href="#18">Posso avere una visualizzazione a calendario?</a>
    </section>
  </b-collapse>


 <div class="d-flex border collapse-title border-primary point align-items-center justify-content-between pr-4 mt-2"
              @click="showConnessa = !showConnessa"
              >
              <h6 class=" p-2 mb-0"  variant="primary">
                Area Connessa</h6>
                <b-icon :icon="showConnessa ? 'chevron-up' : 'chevron-down'"></b-icon>
              </div>

  <b-collapse v-model="showConnessa">
    <section class="d-flex flex-column mt-2">
                 <a class="text-prim-grad-1 ml-2" href="#19">Come funziona il servizio di Connessa?</a>
      <a class="text-prim-grad-1 ml-2" href="#20">Dove posso visualizzare lo stato delle prenotazioni che ho ricevuto?</a>
      <a class="text-prim-grad-1 ml-2" href="#21">Dove posso inserire le varie fasce orarie disponibili per erogare il servizio Connessa?</a>
      <a class="text-prim-grad-1 ml-2" href="#22">Come posso modificare o annullare una prenotazione?</a>

      <a class="text-prim-grad-1 ml-2" href="#23">In cosa consiste il servizio Con Te?</a>
      <!-- <a class="text-prim-grad-1 ml-2" href="#24">Come posso creare una nuova attività?</a> -->
      <!-- <a class="text-prim-grad-1 ml-2" href="#25">Come posso modificare o eliminare un'attività?</a> -->
      <!-- <a class="text-prim-grad-1 ml-2" href="#26">Come posso creare un nuovo questionario?</a>
      <a class="text-prim-grad-1 ml-2" href="#27">Come posso modificare un questionario?</a> -->
    </section>
  </b-collapse>


 <div class="d-flex border collapse-title border-primary point align-items-center justify-content-between pr-4 mt-2"
              @click="showFacilePiu = !showFacilePiu"
              >
              <h6 class=" p-2 mb-0"  variant="primary">
                Area Facile+</h6>
                <b-icon :icon="showFacilePiu ? 'chevron-up' : 'chevron-down'"></b-icon>
              </div>

   <b-collapse class="" v-model="showFacilePiu">
    <section class="d-flex flex-column my-2">
              <a class="text-prim-grad-1 ml-2" href="#24">In cosa consiste il servizio Facile+?</a>
      <a class="text-prim-grad-1 ml-2" href="#25">Come faccio per creare un nuovo alert?</a>
      <a class="text-prim-grad-1 ml-2" href="#26">Come posso modificare un alert?</a>
      <a class="text-prim-grad-1 ml-2" href="#27">Come riceve le notifiche il mio paziente?</a>
    
    </section>
  </b-collapse>

 <div class="d-flex border collapse-title border-primary point align-items-center justify-content-between pr-4 mt-2"
              @click="showImpostazioni = !showImpostazioni"
              >
              <h6 class=" p-2 mb-0"  variant="primary">
                Impostazioni e altro </h6>
                <b-icon :icon="showImpostazioni ? 'chevron-up' : 'chevron-down'"></b-icon>
              </div>

   <b-collapse class="" v-model="showImpostazioni">
    <section class="d-flex flex-column my-2">
        <a class="text-prim-grad-1 ml-2" href="#28">Quali operazioni posso effettuare nell'area Profilo?</a>
      <a class="text-prim-grad-1 ml-2" href="#29">Quali operazioni posso effettuare nell'area “Gestione Servizi”?</a>
      <a class="text-prim-grad-1 ml-2" href="#30">Come creo una nuova prestazione della mia farmacia?</a>
      <a class="text-prim-grad-1 ml-2" href="#31">Cosa si gestisce nella sezione "altri Servizi"?</a>
      <a class="text-prim-grad-1 ml-2" href="#32">Cosa si gestisce nella sezione "Orari d'Apertura"?</a>

      <a class="text-prim-grad-1 ml-2" href="#33">Quali tipi di utenze possono avere gli operatori? Dove posso crearle?</a>
      <a class="text-prim-grad-1 ml-2" href="#34">Come posso autorizzare un nuovo collaboratore?</a>
      <a class="text-prim-grad-1 ml-2" href="#35">Come posso disabilitare un collaboratore?</a>
      <a class="text-prim-grad-1 ml-2" href="#36">Come può registrarsi il collaboratore?</a>

      <a class="text-prim-grad-1 ml-2" href="#37">Quali operazioni posso effettuare nell'area Anagrafica?</a>
      <a class="text-prim-grad-1 ml-2" href="#38">Cosa gestisco nella sezione Sottoscrizioni?</a>
    
    </section>
  </b-collapse>
          </section>
     
      

<article class="text-justify">
      <h6 id="2" :class="titleStyle">
      Come inserisco un paziente nuovo?
    </h6>
    <p>
      Per aggiungere un nuovo paziente dalla sezione Home cliccare su
      <router-link  :to="{ name: 'Pazienti' }">Pazienti</router-link> e verificare se il paziente da registrare è già presente nel database. Quindi scrivere il codice fiscale o l’indirizzo email associato al paziente e, se il sistema non trova nessun risultato, proseguire cliccando su “Arruola Paziente”. Compilare i campi inerenti i dati anagrafici del paziente e cliccare su Arruola.
    </p>
    <p>
      Una volta arruolato il paziente e inserite le necessarie informazioni, verrà visualizzata la scheda paziente. Da questa sezione si potranno inserire tutte le prestazioni legate al paziente cliccando sul tasto <b>"+”</b> nella voce <b>“prenotazioni”</b>
    </p>
    <p class="mb-0">Inserire o modificare una terapia cliccando il tasto <b>+</b> nella voce <b>“terapie”</b> </p>
    <p class="mb-0">Inserire o modificare una patologia cliccando il tasto <b>+</b> nella voce <b>patologia</b> </p>
    <p class="mb-2">Inserire o modificare una rilevazione cliccando il tasto <b>+</b> nella voce <b>rilevazioni</b> </p>
    <b-img fluid src="/assets/help/operatore/2.png"></b-img> <br><br><br>
</article>

<article class="text-justify">
      <h6 id="3" :class="titleStyle">
Quali informazioni visualizzo nella scheda paziente?
    </h6>
    <p>Sulla sinistra della schermata è possibile consultare le informazioni anagrafiche inserite e viene visualizzato l’indice di Aderenza Terapeutica. <br>
L’algoritmo implementato per la valutazione dell’aderenza è il Medical Possession Rate (indicato dall’AIFA). L’algoritmo è ampiamente documentato e validato in studi clinici.
</p>
<p>
  L'indice di aderenza generico che si trova sulla scheda paziente è una media degli indici di aderenza di tutte le terapie croniche del paziente. Nel dettaglio della terapia cronica invece è consultabile l'indice di aderenza calcolato sulla singola terapia.
</p>
    <b-img fluid src="/assets/help/operatore/3.png"></b-img> <br><br><br>
</article>

<article class="text-justify">
      <h6 id="4" :class="titleStyle">
        Come cercare un paziente e accedere alla sua scheda?
    </h6>
    <p>Dalla sezione Home, cliccare su
      <router-link  :to="{ name: 'Pazienti' }">Pazienti</router-link> ed inserire il codice fiscale o l’indirizzo email con cui è registrato sul portale.</p>
    <b-img fluid src="/assets/help/operatore/4.png"></b-img> <br><br><br>
</article>

<article class="text-justify">
      <h6 id="5" :class="titleStyle">
Come modifico i dati di un paziente esistente? 
    </h6>
    <p>I dati del paziente non possono essere modificati se non dal paziente. Nel caso di errori sui dati chiave (Codice Fiscale e mail) contattare il supporto.</p>
    <br><br><br>
</article>

<article class="text-justify">
      <h6 id="6" :class="titleStyle">
Come inserisco una nuova terapia?
    </h6>
    <p>
      Dalla sezione Home, cliccare su  cliccare su
      <router-link  :to="{ name: 'Pazienti' }">Pazienti</router-link> e inserire il codice fiscale o l’indirizzo e-mail del paziente. Se registrato cliccare su “Vai a Scheda Paziente” e accedere ai dati anagrafici del paziente. 
    </p>
    <b-img fluid src="/assets/help/operatore/5.png"></b-img> <br><br><br>
    <p>Nelle pagine Prenotazioni - Teleconsulto - Terapie - Rilevazioni
è possibile inserire tramite il tasto + nuove prenotazioni, nuove terapie, nuove patologie e rilevazioni.
Sarà inoltre possibile modificare, sospendere e/o eliminare qualsiasi inserimento nella scheda paziente e inserire alert per ricordare al paziente l’approvvigionamento dei farmaci o la prenotazione di una prestazione.
</p>
    <b-img fluid src="/assets/help/operatore/6.png"></b-img> <br><br><br>

</article>

<article class="text-justify">
      <h6 id="7" :class="titleStyle">
        Come modifico la terapia di un paziente esistente?
    </h6>
    <p>Per modificare la terapia di un paziente, selezionare la terapia e cliccare sull'icona per visualizzarla</p>
    <b-img fluid src="/assets/help/operatore/7.png"></b-img> <br><br><br>
    <p>A questo punto, cliccare sul tasto "modifica" o "elimina" in base all'azione che si vuole compiere.</p>
    <b-img fluid src="/assets/help/operatore/8.png"></b-img> 
    <br><br><br>
    <p>Cliccando sul tasto "modifica" si potrà modificare la terapia.</p>
    <b-img fluid src="/assets/help/operatore/9.png"></b-img> 
    <br><br><br>
</article>

<article class="text-justify">
      <h6 id="8" :class="titleStyle">
        Posso cancellare i dati di un paziente esistente?
    </h6>
    <p>
      Non è possibile cancellare un paziente, contattare l’assistenza per informazioni aggiuntive
    </p>
     <br><br><br>
</article>

<article class="text-justify">
      <h6 id="9" :class="titleStyle">
        Perché in alcuni casi non vedo tutte le voci della scheda paziente?
    </h6>
    <p>Nel caso in cui vengano visualizzati solo i tasti delle prenotazioni e delle terapie, significa che il paziente è associato a un’altra farmacia predefinita. In questo caso, potranno essere aggiornati i dati delle terapie, ma non potranno essere inserite prestazioni della farmacia o nuove patologie.</p>
     <br><br><br>
</article>

<article class="text-justify">
      <h6 id="10" :class="titleStyle">
        Come funziona il servizio Facile?
    </h6>
    <p>Facile è il servizio di prenotazione di DottorFarma. Il paziente può prenotare una prestazione sanitaria disponibile in farmacia oppure ordinare un farmaco.</p>
    
    <p>  
N.B. Le prestazioni sanitare disponibili in farmacia, gli orari di disponibilità, le modalità di ritiro in farmacia dei farmaci devono essere impostati nella sezione
<span v-if="!canSeeImpostazioni">Impostazioni</span>
      <router-link v-else :to="{ name: 'Impostazioni' }">Impostazioni</router-link>
       dell'operatore
</p> <br><br><br>
</article>

<article class="text-justify">
      <h6 id="11" :class="titleStyle">
        Dove posso visualizzare le prenotazioni ricevute?
    </h6>
    <p>Per visualizzare tutte le prenotazioni ricevute basta cliccare sull’icona in home page “Facile”.</p>
    <p>Dove si potranno visualizzare le prenotazioni così suddivise nelle icone:</p>

    <ul>
      <li><i>Tutte:</i> sono elencate tutte le prenotazioni e gli ordini di farmaci</li>
      <li><i>Prestazioni:</i> sono elencate solo le prenotazioni da erogare in farmacia.</li>
      <li><i>Ordini:</i> sono elencati gli ordini ricevuti di farmaci</li>
      <li><i>Calendario:</i> la vista calendario aiuta una visualizzazione complessiva in ordine cronologico e settimanale delle prestazioni e degli ordini</li>
    </ul>
    <b-img fluid src="/assets/help/operatore/10.png"></b-img> <br><br><br>
</article>

<article class="text-justify">
      <h6 id="12" :class="titleStyle">
        Dove posso visualizzare lo stato delle prenotazioni che ho ricevuto?
    </h6>
    <p>
      Cliccando su
      <router-link class="underlined" :to="{ name: 'prenotazioni' }">Facile</router-link> dalla sezione Home, è possibile visionare le prenotazioni distinte per stati:
      <ul>
        <li><i>In Attesa:</i> sono le prenotazioni che necessitano di conferma per poter proseguire nel processo di gestione
        </li>
        <li><i>Confermate:</i> sono le prenotazioni che hanno già ricevuto conferma</li>
        <li><i>Annullate:</i> sono le prenotazioni che sono state annullate</li>
        <li><i>Evase:</i> sono le prenotazioni che sono state effettuate e quindi evase</li>
      </ul>
    </p>
    <p>Ogni prenotazione può essere visualizzata cliccando sul tasto “Visualizza” e di conseguenza confermare e declinare la richiesta, cliccando sugli appositi bottoni.</p>
    <b-img fluid src="/assets/help/operatore/11.png"></b-img> <br><br><br>
</article>

<article class="text-justify">
      <h6 id="13" :class="titleStyle">
        Che differenza c'è tra prenotazioni in attesa, in attesa di risposta, e ricevute?
    </h6>

    <p>Una prenotazione <b>ricevuta</b> è una prenotazione che necessita di una conferma dell’operatore <b>a seguito dell’inoltro della prenotazione.</b></p>

    <p>Una prenotazione <b>in attesa di risposta</b> è una prenotazione che necessita di una conferma <b>a seguito di una modifica </b> apportata prenotazione.</p>

    <p>
      Le prenotazioni <b>ricevute</b> e <b>in attesa di risposta</b> sono <b>entrambe categorizzate sotto la voce <b>in attesa</b></b>.
    </p>

    <p>N.B. Mentre gli ordini hanno sempre necessità di essere confermati, una prestazione può essere impostata come confermata automaticamente all’inoltro (nella sezione
<span v-if="!canSeeImpostazioni">Impostazioni</span>
      <router-link v-else :to="{ name: 'Impostazioni' }">Impostazioni</router-link> dell’operatore).
Le prenotazioni delle prestazioni così impostate non avranno necessità di essere confermate a seguito dell’inoltro, ma andranno automaticamente nello stato “confermato”.
</p>
    <b-img fluid src="/assets/help/operatore/11bis.png"></b-img> <br><br><br>
</article>

<article class="text-justify">
      <h6 id="14" :class="titleStyle">
        Come posso cercare una prenotazione?
    </h6>
    <p>Sia per gli ordini che per le prestazioni sanitarie è possibile ricercare una specifica prenotazione applicando un filtro apposito. Dalla Home, cliccando su <router-link :to="{ name: 'prenotazioni' }">Facile</router-link> e su <i>Tutte</i> le prenotazioni o la singola categoria (Ordine/Prestazione) si può ricercare una prenotazione sul campo filtro per Codice fiscale oppure inserendo il codice richiesta.</p>
    <br><br><br>
</article>

<article class="text-justify">
      <h6 id="15" :class="titleStyle">
        Come posso annullare le prenotazioni di una giornata?
    </h6>
    <p>Cliccando su "Facile" in basso è presente la funzione di annullamento rapido per un particolare giorno (o intervallo di date)</p>
    <b-img fluid src="/assets/help/operatore/12.png"></b-img> <br><br><br>
</article>

<article class="text-justify">
      <h6 id="16" :class="titleStyle">
        Posso scaricare un file con la programmazione delle prenotazioni?
    </h6>
    <p>Si, dalla pagina del servizio Facile in basso è presente la funzionalità “Download Report” scegliendo le tipologie di prenotazioni e prestazioni, il formato e il periodo di tempo.</p>
    <b-img fluid src="/assets/help/operatore/13.png"></b-img> <br><br><br>
</article>

<article class="text-justify">
      <h6 id="17" :class="titleStyle">
        Posso vedere il dettaglio della tipologia di prenotazione?
    </h6>
    <p>Si, in alto sulla pagina Facile è possibile selezionare o solo le prestazioni o solo gli ordini. Cliccando sull’icona con il simbolo delle informazioni è possibile visualizzare il dettaglio della prenotazione.</p>
    <br><br><br>
</article>

<article class="text-justify">
      <h6 id="18" :class="titleStyle">
        Posso avere una visualizzazione a calendario?
    </h6>
    <p>Si, in alto sulla pagina Facile è possibile selezionare il formato calendario e filtrare le prenotazioni per tipologia.</p>
    <b-img fluid src="/assets/help/operatore/14.png"></b-img> <br><br><br>
</article>

<article class="text-justify">
      <h6 id="19" :class="titleStyle">
        Come funziona il servizio di Connessa?
    </h6>
    <p>Tramite il portale di Dottorfarma le farmacie hanno la possibilità di effettuare teleconsulti con i propri pazienti accedendo alla sezione “Connessa”. <br>
Per teleconsulto si intende una connessione sicura in video tra la farmacia e il paziente. La video comunicazione non viene registrata ed è possibile prevedere delle annotazioni. I pazienti potranno richiedere dei teleconsulti prenotando in relazione alla disponibilità indicata nella sezione
<span v-if="!canSeeImpostazioni">Impostazioni</span>
      <router-link v-else :to="{ name: 'Impostazioni' }">Impostazioni</router-link>.
Inserendo gli orari in cui i pazienti possono prenotare un teleconsulto (dalla sezione
<span v-if="!canSeeImpostazioni">Impostazioni</span>
      <router-link v-else :to="{ name: 'Impostazioni' }">Impostazioni</router-link> → Gestione Servizi) le farmacie visualizzeranno le prenotazioni potendo cercare la prenotazione per codice fiscale e per codice richiesta.
</p>
    <br><br><br>
</article>

<article class="text-justify">
      <h6 id="20" :class="titleStyle">
        Dove posso visualizzare lo stato delle prenotazioni che ho ricevuto?
    </h6>
    <p>Cliccando su 
<router-link :to="{ name: 'teleconsulto' }"> Connessa</router-link>
      
       dalla sezione Home, è possibile visionare le prenotazioni di teleconsulti da erogare distinti per stati:</p>
    <ul>
        <li><i>In Attesa:</i> sono le prenotazioni che necessitano di conferma per poter proseguire nel processo di gestione
        </li>
        <li><i>Confermate:</i> sono le prenotazioni che hanno già ricevuto conferma</li>
        <li><i>Annullate:</i> sono le prenotazioni che sono state annullate</li>
        <li><i>Evase:</i> sono le prenotazioni che sono state effettuate e quindi evase</li>
      </ul>
    <b-img fluid src="/assets/help/operatore/15.png"></b-img> <br><br><br>
</article>

<article class="text-justify">
      <h6 id="21" :class="titleStyle">
        Dove posso inserire le varie fasce orarie disponibili per erogare il servizio Connessa?
    </h6>
    <p>In <span v-if="!canSeeImpostazioni">Impostazioni</span>
      <router-link v-else :to="{ name: 'Impostazioni' }">Impostazioni</router-link> alla voce “gestione servizi” cliccare su “altri servizi” si può attivare il servizio connessa indicando tutte le disponibilità.</p>
    <b-img fluid src="/assets/help/operatore/16.png"></b-img> <br><br><br>
</article>

<article class="text-justify">
      <h6 id="22" :class="titleStyle">
        Come posso modificare o annullare una prenotazione?
    </h6>
    <p>Se la prenotazione è già stata confermata non è possibile modificarla, ma bisognerà rifiutarla e chiedere al cliente di effettuarne una nuova. Se la prenotazione è solo stata ricevuta (e necessita quindi di essere confermata) è possibile effettuare modifiche dal dettaglio della prenotazione (facendo click su "Visualizza" nella schermata di riepilogo prenotazioni, apportando le modifiche desiderate, facendo click su "Invia risposta").</p>
   <br><br><br>
</article>

<article class="text-justify">
      <h6 id="23" :class="titleStyle">
        In cosa consiste il servizio Con Te?
    </h6>
    <p><router-link :to="{ name: 'attivita' }"> Con Te</router-link> è il servizio per la somministrazione e creazione di questionari da somministrare al paziente. In relazione alle politiche della piattaforma, è possibile utilizzare un modello già presente, selezionare questionari preconfigurati dall’amministratore del sistema o inserirne di nuovi legati ai servizi che la farmacia offre al paziente.
    <br>
      I questionari sono classificati in “Attività”, ossia raggruppamenti che fanno riferimento a una specifica campagna o a una specifica iniziativa (ad es. Prevenzione BPCO, Questionari per la Farmacia dei Servizi…)
    </p>
    <b-img fluid src="/assets/help/operatore/17.png"></b-img> <br><br><br>
</article>

<article class="text-justify">
      <h6 id="24" :class="titleStyle">
        In cosa consiste il servizio Facile+?
    </h6>
    <p><router-link :to="{ name: 'pazienti' }"> Facile+</router-link> è il servizio di alerting per ricordare al paziente i suoi appuntamenti con la propria farmacia per specifiche prestazioni o l’approvvigionamento dei suoi farmaci con l’obiettivo di essere sempre più aderente alla terapia.</p>
    <br><br><br>
</article>

<article class="text-justify">
      <h6 id="25" :class="titleStyle">
        Come faccio per creare un nuovo alert?
    </h6>
    <p>
      Basta cliccare su <router-link :to="{ name: 'pazienti' }"> Facile+</router-link>, ricercare tramite codice fiscale il paziente per visualizzare le terapie esistenti, modificarle e/o crearne di nuove. <br>
      L’operatore potrà inserire nuove terapie e impostare gli alert (tramite mail, telegram e what app) secondo le esigenze e richieste del paziente.
    </p>
    <b-img fluid src="/assets/help/operatore/23.png"></b-img> <br><br><br>
</article>

<article class="text-justify">
      <h6 id="26" :class="titleStyle">
        Come posso modificare un alert?
    </h6>
    <p>
      Nella scheda paziente posso modificare gli alert relativi alle terapie esistenti  cliccando sul tasto “modifica piano”.
    </p>
    <b-img fluid src="/assets/help/operatore/24.png"></b-img> <br><br><br>
</article>

<article class="text-justify">
      <h6 id="27" :class="titleStyle">
        Come riceve le notifiche il mio paziente?
    </h6>
    <p>Il paziente riceve le notifiche in almeno uno dei seguenti canali da lui prescelto</p>
    <ul>
      <li>email</li>
      <li>telegram/whatsapp</li>
    </ul>
     <br><br><br>
</article>

<article class="text-justify">
      <h6 id="28" :class="titleStyle">
        Quali operazioni posso effettuare nell'area Profilo?
    </h6>
    <p>
      Nell’area
              <router-link :to="{ name: 'Profilo' }">
          Profilo</router-link>
       della farmacia, puoi modificare nome e cognome dell’operatore, effettuare il cambio password, gestire le opzioni di ricezione notifiche e gli accessi ai canali Whatsapp e Telegram.
    </p>
    <b-img fluid src="/assets/help/operatore/25.png"></b-img> <br><br><br>
</article>

<article class="text-justify">
      <h6 id="29" :class="titleStyle">
        Quali operazioni posso effettuare nell'area "Gestione Servizi"?
    </h6>
    <p>In quest’area alla voce “Prestazioni” puoi visualizzare, attivare e disattivare tutte le prestazioni che eroga la tua farmacia e crearne di nuove personalizzate.</p>
    <br><br><br>
</article>

<article class="text-justify">
      <h6 id="30" :class="titleStyle">
        Come creo una nuova prestazione della mia farmacia?
    </h6>
    <p>Cliccare sul tasto “+” per inserire una nuova prestazione.</p>
    <p>Inserisci il nome della prestazione, la descrizione, scegli l’icona corrispondente (che visualizzerai nel calendario), decidi se renderla pubblica e automatizza o meno la conferma delle richieste.</p>
    <b-img fluid src="/assets/help/operatore/26.png"></b-img> <br><br><br>
</article>

<article class="text-justify">
      <h6 id="31" :class="titleStyle">
        Come si gestisce nella sezione "altri Servizi"?
    </h6>
    <p>Puoi gestire la disponibilità e gli orari dei tuoi servizi (es. Connessa, Facile, Facile+ etc)</p>
    <b-img fluid src="/assets/help/operatore/27.png"></b-img> <br><br><br>
</article>

<article class="text-justify">
      <h6 id="32" :class="titleStyle">
        Cosa si gestisce nella sezione "Orari d'Apertura"?
    </h6>
    <p>Puoi gestire (inserire e modificare) gli orari d'apertura e chiusura della tua farmacia</p>
    <b-img fluid src="/assets/help/operatore/28.png"></b-img> <br><br><br>
</article>

<article class="text-justify">
      <h6 id="33" :class="titleStyle">
        Quali tipi di utenze possono avere gli operatori? Dove posso crearle?
    </h6>
    <p>I tipi di utenza disponibili nella piattaforma sono Amministratore, Operatore Avanzato, Operatore. <br>
    Si possono creare nella sezione operatori.</p>
    <br><br><br>
</article>

<article class="text-justify">
      <h6 id="34" :class="titleStyle">
        Come posso autorizzare un nuovo collaboratore?
    </h6>
    <p>In basso, basta inserire la mail del collaboratore a cui assegnare il “ruolo” per inviare il codice invito per registrarsi al portale.</p>
    <b-img fluid src="/assets/help/operatore/29.png"></b-img> 
    <p>E successivamente inserire i dati richiesti e confermare la e-mail</p>
    <b-img fluid src="/assets/help/operatore/30.png"></b-img> <br><br><br>    
</article>

<article class="text-justify">
      <h6 id="35" :class="titleStyle">
        Come posso disabilitare un collaboratore?
    </h6>
    <p>Per disabilitare cliccare sul bottone “disabilita” in corrispondenza del nome del collaboratore</p>
    <b-img fluid src="/assets/help/operatore/31.png"></b-img> <br><br><br>
</article>

<article class="text-justify">
      <h6 id="36" :class="titleStyle">
        Come può registrarsi il collaboratore?
    </h6>
    <p>Il collaboratore riceverà la mail con un link per registrarsi al portale e dovrà selezionare la voce “operatore sanitario”</p>

    <b-img fluid src="/assets/help/operatore/32.png"></b-img> <br>

    <p>E successivamente inserire i dati richiesti e confermare la e-mail</p>
    <b-img fluid src="/assets/help/operatore/33.png"></b-img> <br><br><br>
    
</article>



<article class="text-justify">
      <h6 id="37" :class="titleStyle">
        Quali operazioni posso effettuare nell'area Anagrafica?
    </h6>
    <p>In quest’area si possono visualizzare i dati relativi alla mia farmacia.
Nel caso di farmacia aderente a Federfarma che avrà fatto accesso e aderito tramite il sito di Federfarma, i dati non potranno essere modificati.
</p>
    <b-img fluid src="/assets/help/operatore/34.png"></b-img> <br><br><br>
</article>

<article class="text-justify">
      <h6 id="38" :class="titleStyle">
        Cosa gestisco nella sezione Sottoscrizioni?
    </h6>
    <p>
      Alla voce “Sottoscrizioni” troverai i servizi a pagamento presenti in DottorFARMA con la possibilità di procedere all’acquisto.<br>
Nella fase iniziale (primi sei mesi), tutte le farmacie saranno abilitate a tutti i servizi.
    </p>
    <b-img fluid src="/assets/help/operatore/35.png"></b-img> <br><br><br>
</article>
 
  </div>
</template>
        
<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "GuidaOperatore",
  data() {
    return {
      titleStyle: `text-prim-grad-1
        fw-bolder
        text-left
        pb-1
        border-bottom border-line-grey`,
        showPazienti: true, 
        showFacile: false,
        showConnessa: false,
        showFacilePiu: false,
        showImpostazioni: false,
    }
  },
  computed: {
    canSeeImpostazioni() {
      if (this.user.type === 1) {
        return (
          this.user.permessi.includes("visualizzare anagrafica azienda") ||
          this.user.permessi.includes("gestire impostazioni dei servizi") ||
          this.user.permessi.includes("gestire operatori")
        );
      }
      return true;
    },
    ...mapState("utente", ["status", "user"]),

  }
};
</script>

<style lang="scss" scoped>
a {
  text-decoration: underline;
}

.collapse-title {
  border-radius: 10px;
}
</style>
