<template>
  <div id="app" class="primary_bg_gradient">
    <div id="nav" v-if="user">
      <TopHeader />
      <CookieBanner></CookieBanner>
    </div>
    <div v-else class="text-center pt-2 pt-md-4">
      <router-link
        :to="{ name: 'LoginPage' }"
        class="app__logo mx-auto ml-md-4 mr-md-auto logo__only"
        :style="cssLogo"
      >
        <span class="p-2 my-2"> APP LOGO</span>
      </router-link>
    </div>
    <b-alert
      :variant="alert.type"
      dismissible
      fade
      :show="alert.message !== null"
      @dismissed="clearAlert"
      @dismiss-count-down="clearAlert"
      class="col-12 mr-0 ml-auto fixed__alert"
    >
      {{ alert.message }}
    </b-alert>
    <b-container
      fluid
      class="mt-2 mt-lg-4 mx-0 bg-white pt-3 pb-4 custom__content__df"
    >
      <b-row align-v="start">
        <template v-if="$root.menuLeft">
          <LeftHeader v-if="user && userIsVerified && hasSubscription" />
        </template>
        <b-col
          align-self="start"
          class="px-0 mx-auto"
          :class="$root.menuLeft ? 'col-md-10 col-11' : 'col-12'"
        >
          <router-view />
        </b-col>
      </b-row>
    </b-container>
    <b-row class="py-2 py-md-4 align-items-center" align-v="center">
      <b-col class="col-12 col-lg-2 order-3 order-md-1">
        <b-row>
          <a
            href="https://www.facebook.com/dottorfarma"
            target="__blank"
            class="mx-auto"
          >
            <b-icon icon="facebook" variant="white" font-scale="1.5"></b-icon>
          </a>
          <a
            href="https://twitter.com/dottorfarma"
            target="__blank"
            class="mx-auto"
          >
            <b-icon icon="twitter" variant="white" font-scale="1.5"></b-icon>
          </a>
          <a
            href="https://www.instagram.com/dottorfarma/"
            target="__blank"
            class="mx-auto"
          >
            <b-icon icon="instagram" variant="white" font-scale="1.5"></b-icon>
          </a>
          <a
            href="https://www.youtube.com/channel/UCb5H9T7fBbVPSi0NgzBxMSw"
            target="__blank"
            class="mx-auto"
          >
            <b-icon icon="youtube" variant="white" font-scale="1.5"></b-icon>
          </a>
        </b-row>
      </b-col>
      <b-col class="col order-2 order-md-2">
        <div
          id="page__bottom"
          class="text-white fw-bolder spacing_1 text-center mt-1 mx-auto"
        >
          <span class="mx-1">
            Copyright © - Promofarma s.r.l. Partita IVA: 05317231008
          </span>
          <p>
            <span class="mx-1 point a__effect">
              <a href="https://qwince.com/" target="_blank" class="text-white"
                >Powered by QWINCE</a
              >
              -<span @click="$bvModal.show('about-modal')">
                Basato su tecnologia Puntofarma</span
              >
            </span>
            <ChangeLogComponent />
          </p>
        </div>
      </b-col>
      <b-col
        class="col-12 col-lg-2 order-1 order-md-3"
        :class="$root.isMobile ? 'order-1' : 'order-3 mt-1'"
      >
        <b-row
          class="justify-content-evenly"
          style="justify-content: space-evenly"
        >
          <div class="" v-if="showScrollBtn">
            <span class="point mr-0" @click="scrollUp">
              <b-iconstack font-scale="3">
                <b-icon stacked icon="circle-fill" variant="primary"></b-icon>
                <b-icon
                  stacked
                  icon="arrow-up"
                  scale="0.6"
                  variant="white"
                ></b-icon>
                <b-icon stacked icon="circle" variant="primary"></b-icon>
              </b-iconstack>
            </span>
          </div>
          <div v-if="showChat && user" id="callus__btn__container">
            <call-us
              style="
                position: fixed;
                bottom: 20px;
                right: 20px;
                font-family: Arial;
                z-index: 99999;
                --call-us-form-header-background: #3867b4;
                --call-us-main-button-background: #3867b4;
                --call-us-client-text-color: #d4d4d4;
                --call-us-agent-text-color: #3867b4;
                --call-us-form-height: 330px;
              "
              id="wp-live-chat-by-3CX"
              channel-url="https://3cx.qwince.com"
              files-url="https://3cx.qwince.com"
              minimized="true"
              animation-style="slideUp"
              :party="chatParty"
              minimized-style="BubbleRight"
              allow-call="false"
              allow-video="false"
              allow-soundnotifications="true"
              enable-mute="true"
              enable-onmobile="true"
              offline-enabled="true"
              :enable="chatEnabled"
              ignore-queueownership="false"
              authentication="none"
              authentication-message="La chat è attiva dal lunedì al venerdì tra le 9 e le 13. Oltre questi orari, lascia un messaggio e verrai ricontattato via email."
              show-typing-indicator="true"
              auto-focus="true"
              operator-name="Supporto DottorFarma"
              show-operator-actual-name="false"
              channel="phone"
              aknowledge-received="true"
              gdpr-enabled="false"
              gdpr-message="I agree that my personal data to be processed and for the use of cookies in order to engage in a chat processed by COMPANY, for the purpose of Chat/Support for the time of  30 day(s) as per the GDPR."
              message-userinfo-format="name"
              message-dateformat="both"
              start-chat-button-text="Chat"
              window-title="Supporto DottorFarma"
              :window-icon="chatBackgroundImage"
              button-icon-type="Default"
              invite-message="Ciao %NAME%, come possiamo aiutarti?"
              unavailable-message="La chat non è al momento disponibile, riprova tra qualche minuto."
              offline-finish-message="Abbiamo ricevuto il tuo messaggio, ti ricontatteremo appena possibile"
              ending-message="la tua sessione è terminata. Se vuoi, potrai sempre ricontattarci!"
              greeting-visibility="both"
              greeting-message="Ciao! Posso esserti utile?"
              greeting-offline-visibility="both"
              greeting-offline-message="Ciao!"
              chat-delay="2000"
              offline-name-message="Puoi darci il tuo nome completo?"
              offline-email-message="Puoi darci il tuo indirizzo email?"
              offline-form-invalid-name="Il nome indicato non è valido."
              offline-form-maximum-characters-reached="Numero di caratteri massimo raggiunto"
              offline-form-invalid-email="L'email indicata non è valida"
              enable-direct-call="false"
              enable-ga="false"
              enable-poweredby="false"
              :visitor-email="user.email"
              :visitor-name="chatInfoName"
            >
            </call-us>
          </div>
          <div v-else-if="user && user.type === 0">
            <a
              class="mx-lg-1"
              href="https://play.google.com/store/apps/details?id=com.qwince.dottorfarma&amp;hl=it&amp;gl=US"
              ><img
                loading="lazy"
                src="https://www.dottorfarma.it/wp-content/uploads/2020/07/badge_app_android.png"
                alt=""
                width="108"
                height="31" /></a
            ><a
              href="https://apps.apple.com/it/app/dottorfarma/id1513116783"
              class="mx-lg-1"
              ><img
                loading="lazy"
                src="https://www.dottorfarma.it/wp-content/uploads/2020/07/badge_app_ios.png"
                alt=""
                width="105"
                height="30"
            /></a>
          </div>
        </b-row>
      </b-col>
    </b-row>
    <b-modal
      id="about-modal"
      centered
      hide-footer
      content-class="about-modal-content"
      header-class="pb-0 pt-2"
      body-class="pt-1"
    >
      <template #default>
        <div class="about-image-container">
          <img
            @click="
              $bvModal.hide('about-modal')
              $router.push('/help')
            "
            src="@/assets/df-etichetta.png"
            class="about-modal-image point"
            alt="About image"
          />
        </div>
        <div class="px-2 col-12">
          <div class="pt-3 fs-5 fw-bolder">
            <a href="https://puntofarma.online/" target="_blank"
              >... scopri di più</a
            >
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  // background-image: linear-gradient(to bottom, $prim-grad-2, $prim-grad-1);
  // background-clip: padding-box;
  // background-position-x: 0%;
  // background-position-y: 0%;
  // background-size: initial;
  // background-repeat: no-repeat;
  // background-attachment: initial;
  // background-origin: padding-box;
  // background-clip: padding-box;
  // border-color: transparent;
}

#page__bottom {
  // position: absolute;
  // bottom: -1vh;
  min-height: 100%;
}

#lower-iso-card {
  display: flex;
  justify-content: space-between;
  background-color: whitesmoke;
  font-size: 13px;
  font-weight: 550;
  z-index: 100;
  position: relative;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  margin-top: -7px;
}

.custom__content__df {
  min-height: 70vh;
}

.fixed__alert {
  position: fixed !important;
  z-index: 9999999 !important;
}

.a__effect:hover {
  color: #d9d9d9 !important;
  text-decoration: underline;
}

.about-modal-content {
  padding: 0;
}

.about-image-container {
  max-width: 466px;
  max-height: 366px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.about-modal-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
</style>
<script>
// @ is an alias to /src
import { mapState, mapActions } from "vuex"
import TopHeader from "@/components/layout/TopHeader.vue"
import LeftHeader from "@/components/layout/LeftHeader.vue"
import CookieBanner from "@/components/utilities/CookieBanner.vue"
import ChangeLogComponent from "@/components/layout/ChangeLogComponent.vue"
import VueBarcode from "vue-barcode"

export default {
  components: {
    TopHeader,
    LeftHeader,
    CookieBanner,
    ChangeLogComponent,
    VueBarcode
  },
  data() {
    return {
      showScrollBtn: false,
      cssLogo: {
        // backgroundImage:
        //   "url('" +
        //   process.env.VUE_APP_BE +
        //   "storage/logos/" +
        //   process.env.VUE_APP_SCOPE +
        //   "/logo.png')",
        backgroundPosition: "center",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundImage: `url(${require("@/assets/dottorfarmaWhite.svg")})`
      },
      showCookieBanner: false,
      showChat: false,
      chatBackgroundImage: `url(${require("@/assets/dottorfarmaWhite.svg")})`,
      currentPfVersion: process.env.VUE_APP_PF_VERSION,
      chatParty: process.env.VUE_APP_3CX_PARTY,
      chatEnabled: process.env.VUE_APP_3CX_ENABLED
    }
  },
  computed: {
    chatInfoName: function () {
      if (this.user.type !== 1) {
        return null
      }
      if (this.user.company) {
        return (
          this.user.company.denominazione +
          " - " +
          this.user.company.codice_tracciabilita
        )
      }
      return this.user.username
    },
    userIsVerified: function () {
      if (this.user) {
        if (this.user.email) {
          return (
            this.user.email_verified_at !== null &&
            this.user.number_verified_at !== null
          )
        }
        return this.user.number_verified_at !== null
      }
      return false
    },
    hasSubscription: function () {
      if (this.user) {
        if (this.user.type === 1) {
          return (
            this.user.company &&
            this.user.company.contract &&
            this.user.company.valid_subscription
          )
        }
        return true
      }
      return false
    },
    ...mapState({
      alert: (state) => state.alert
    }),
    ...mapState("utente", ["status", "user"])
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear"
    }),
    ...mapActions("utente", ["me"]),
    ...mapActions("company", ["logUserCompanyIn"]),
    scrollUp() {
      window.scrollTo(0, 0)
    },
    showScrollUp() {
      var bottom = document.querySelector("#page__bottom")
      if (!bottom) {
        return false
      }
      var bounding = bottom.getBoundingClientRect()
      if (
        bounding.bottom >
        (window.innerHeight || document.documentElement.clientHeight)
      ) {
        return true
      }
      return false
    },
    loadChatScript() {
      console.log("****************************loading script")
      let chatScript = document.createElement("script")
      chatScript.setAttribute(
        "src",
        "https://cdn.3cx.com/livechat/v1/callus.js"
      )
      chatScript.setAttribute("charset", "utf-8")
      document.head.appendChild(chatScript)
      var self = this
      setTimeout(() => {
        self.showChat = true
      }, 3000)
      //       this.showChat = true;
    }
  },
  watch: {
    $route(to, from) {
      // clear alert on location change
      this.clearAlert()
      this.showScrollBtn = this.showScrollUp()
    },
    user(to, from) {
      if (to === null) {
        var chatBtn = document.getElementById("callus__btn__container")
        if (chatBtn) {
          chatBtn.remove()
        }
      } else if (
        from === null &&
        to !== null &&
        to.type === 1 &&
        !this.showChat
      ) {
        this.loadChatScript()
      }
    }
  },
  created() {
    if (this.user) {
      let data = null
      // console.log(this.$route.query.access_token)
      if (this.$route.query.access_token) {
        data = { token: this.$route.query.access_token }
      }
      this.me(data)
      // console.log("Me created APP vue");
      this.showCookieBanner = true
      if (this.user.type === 1) {
        this.logUserCompanyIn()
        if (!this.showChat) {
          this.loadChatScript()
        }
      }
    }
    const mainPages = [
      "/cittadino/home",
      "/operatore/home",
      "/admin/home",
      "/impostazioni",
      "/profilo",
      "/orari"
    ]
    // //console.log(this.$route);
    if (!mainPages.includes(this.$route.path)) {
      this.$root.menuLeft = true
    }
  },
  updated() {
    // //console.log("APP HAS UPDATED", this.$root.isMobile);
    this.showScrollBtn = this.showScrollUp()
  }
}
</script>
