<template>
  <b-container>
    <b-row cols="1" class="text-left mb-3">
      <router-link
        class="fw-bolder text-primary no__hover"
        :to="{ name: 'CittadinoHome' }"
      >
        <b-icon icon="arrow-left-circle-fill" variant="primary"></b-icon>
        Homepage
      </router-link>
    </b-row>
    <div>
      <h6
        class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
      >
        I tuoi familiari
      </h6>
      <p class="text-left text-font-light-grey fs-5">
        Le persone non registrate in piattaforma che hai inserito come tuoi
        familiari. Clicca sul "+" per creare nuovi membri: una volta aggiunti
        potrai prenotare farmaci e servizi per loro.
      </p>
      <FamiliariComponent />
    </div>
    <div v-if="associated.given" class="pb-3">
      <h6
        class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
      >
        I tuoi assistiti
      </h6>
      <p class="text-left text-font-light-grey fs-5">
        Le persone che ti hanno scelto come loro caregiver. Queste persone ti
        hanno dato il permesso di prenotare farmaci e servizi per conto loro.
      </p>
      <b-row v-for="(given, ind) in associated.given" :key="ind">
        <b-col class="col-12 col-md-4">
          <div
            v-if="given.pivot.enabled == 1"
            class="general_border shadow_6 pt-4 pb-3 px-2 point min__30 my-3"
          >
            <p @click="openInfoModal(given)">
              <b-iconstack font-scale="5" class="rounded-circle">
                <b-icon
                  class=""
                  stacked
                  icon="circle-fill"
                  variant="prim-grad-1-trans"
                ></b-icon>
                <b-icon
                  stacked
                  icon="person"
                  scale="0.5"
                  variant="secondary"
                ></b-icon>
              </b-iconstack>
            </p>
            <p class="mb-0 fs-5 pt-1" @click="openInfoModal(given)">
              <strong class=""
                >{{ given.anagrafica.name }}
                {{ given.anagrafica.surname }}</strong
              >
            </p>
            <p class="mb-0 mt-1 fs-5 text-font-grey text-capitalize"></p>
          </div>
          <div
            v-else
            class="general_border shadow_6 pt-4 pb-3 px-2 min__30 my-3"
          >
            <p>
              <b-iconstack font-scale="5" class="shadow_7 rounded-circle">
                <b-icon
                  class="shadow_7"
                  stacked
                  icon="circle-fill"
                  variant="warning"
                ></b-icon>
                <b-icon
                  stacked
                  icon="person-x"
                  scale="0.5"
                  variant="secondary"
                ></b-icon>
              </b-iconstack>
            </p>
            <p class="mb-0 fs-5 pt-1">
              <strong class=""
                >{{ given.anagrafica.name }}
                {{ given.anagrafica.surname }}</strong
              >
            </p>
            <p class="mb-0 mt-1 fs-5 text-font-grey text-capitalize"></p>
          </div>
        </b-col>
      </b-row>
    </div>
    <div v-if="associated.givers" class="pb-3">
      <h6
        class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
      >
        I tuoi caregiver
      </h6>
      <p class="text-left text-font-light-grey fs-5">
        Le persone che si occupano di te. I soggetti di seguito elencati possono
        prenotare farmaci e servizi per conto tuo.
      </p>
      <b-row>
        <b-col
          class="col-12 col-md-4"
          v-for="(givers, ind) in associated.givers"
          :key="ind"
        >
          <div
            v-if="givers.pivot.enabled == 1"
            class="general_border shadow_6 pt-4 pb-3 px-2 point min__30 my-3"
          >
            <p @click="openInfoModal(givers)">
              <b-iconstack font-scale="5" class="rounded-circle">
                <b-icon
                  class=""
                  stacked
                  icon="circle-fill"
                  variant="prim-grad-1-trans"
                ></b-icon>
                <b-icon
                  stacked
                  icon="person"
                  scale="0.5"
                  variant="secondary"
                ></b-icon>
              </b-iconstack>
            </p>
            <p class="mb-0 fs-5 pt-1" @click="openInfoModal(givers)">
              <strong class=""
                >{{ givers.anagrafica.name }}
                {{ givers.anagrafica.surname }}</strong
              >
            </p>
            <p class="mb-0 mt-1 fs-5 text-font-grey text-capitalize">
              <b-button
                class="btn mb-1 text-white rounded-pill fw-bolder btn-warning btn-sm collapsed w-50"
                @click="openConfirmModal(givers, false)"
                >Disabilita</b-button
              >
            </p>
          </div>
          <div
            v-else
            class="general_border shadow_6 pt-4 pb-3 px-2 min__30 my-3"
          >
            <p>
              <b-iconstack font-scale="5" class="shadow_7 rounded-circle">
                <b-icon
                  class="shadow_7"
                  stacked
                  icon="circle-fill"
                  variant="warning"
                ></b-icon>
                <b-icon
                  stacked
                  icon="person-x"
                  scale="0.5"
                  variant="secondary"
                ></b-icon>
              </b-iconstack>
            </p>
            <p class="mb-0 fs-5 pt-1">
              <strong class=""
                >{{ givers.anagrafica.name }}
                {{ givers.anagrafica.surname }}</strong
              >
            </p>
            <p class="mb-0 mt-1 fs-5 text-font-grey text-capitalize">
              <b-button
                class="btn mb-1 text-white rounded-pill fw-bolder btn-sec-2 btn-sm collapsed px-2 w-50"
                @click="openConfirmModal(givers, true)"
                >Abilita</b-button
              >
            </p>
          </div>
        </b-col>
      </b-row>
    </div>
    <div class="py-4">
      <NewStuff />
    </div>
    <b-modal centered size="lg" id="confirm-op" hide-footer :title="modalTitle">
      <div v-if="selectedUser" class="text-center">
        <h3>
          Vuoi {{ enabling ? "abilitare" : "disabilitare" }}
          {{ selectedUser.anagrafica.name }}
          {{ selectedUser.anagrafica.surname }} come tuo caregiver?
        </h3>
        <template v-if="enabling">
          <div class="my-2 px-0 px-md-2">
            <p class="text-center my-3 fw-bolder">
              Per procedere alla conferma è necessario leggere ed accettare
              l'informativa.
            </p>
            <div
              class="bg-light-panel border border-grey shadow_6 py-1 px-2 my-4"
              style="max-height: 50vh; overflow-y: scroll"
            >
              <InformativaCaregivers></InformativaCaregivers>
            </div>

            <b-form-checkbox
              class="text-font-light-grey"
              name="data_consense"
              :value="true"
              :unchecked-value="false"
              v-model="informRead"
              :state="informRead == true"
            >
              Ho letto e compreso l’informativa privacy
            </b-form-checkbox>
            <b-form-checkbox
              class="text-font-light-grey"
              name="data_consense"
              :value="true"
              :unchecked-value="false"
              v-model="informAccepted"
              :state="informAccepted == true"
            >
              Acconsento al trattamento dei miei dati personali per le finalità
              indicate nell’informativa
            </b-form-checkbox>
          </div>
          <b-row class="justify-content-center mb-4 mt-3 pt-3">
            <b-button
              variant="outline-danger font-weight-bold"
              class="m-2"
              @click="handleCancel"
            >
              No, Annulla
            </b-button>
            <b-button
              variant="primary primary_gradient font-weight-bold"
              class="m-2"
              :disabled="informAccepted == false || informRead == false"
              @click="handleConfirm"
            >
              Sì, Conferma
            </b-button>
          </b-row>
        </template>
        <b-row v-else class="justify-content-center my-4 pt-3">
          <b-button
            variant="outline-danger font-weight-bold"
            class="m-2"
            @click="handleCancel"
          >
            No, Annulla
          </b-button>
          <b-button
            variant="primary primary_gradient font-weight-bold"
            class="m-2"
            @click="handleConfirm"
          >
            Sì, Conferma
          </b-button>
        </b-row>
      </div>
      <h5 v-else>Nessun utente selezionato</h5>
    </b-modal>

    <b-modal
      centered
      size="lg"
      id="show-info"
      hide-footer
      :title="modalInfoTitle"
      @hidden="selectedUser = null"
    >
      <div v-if="selectedUser">
        <b-row cols="2" class="px-3">
          <b-col class="my-2 px-0 px-md-2">
            <strong>Nome </strong><br />
            <span
              >{{ selectedUser.anagrafica.name }}
              {{ selectedUser.anagrafica.surname }}
            </span>
          </b-col>
          <b-col class="my-2 px-0 px-md-2">
            <strong>Genere </strong><br />
            <span>{{ selectedUser.anagrafica.gender }} </span>
          </b-col>
          <b-col class="my-2 px-0 px-md-2">
            <strong>Data di nascita </strong><br />
            <span v-if="selectedUser.anagrafica.birthday"
              >{{ selectedUser.anagrafica.birthday | moment("DD/MM/YYYY") }}
            </span>
            <span v-else>Non indicata</span>
          </b-col>
          <b-col class="my-2 px-0 px-md-2">
            <strong>Codice fiscale </strong><br />
            <span>{{ selectedUser.anagrafica.cf }} </span>
          </b-col>
        </b-row>
      </div>
      <h5 v-else>Nessun utente selezionato</h5>
    </b-modal>
  </b-container>
</template>
<script>
import { cittadinoService } from "@/_services";
import { mapState, mapActions } from "vuex";
import FamiliariComponent from "@/components/familiari/FamiliariComponent.vue";
import InformativaCaregivers from "@/components/familiari/InformativaCaregivers.vue";
import NewStuff from "@/components/familiari/NewStuff.vue";

export default {
  name: "FamiliariView",
  components: {
    FamiliariComponent,
    NewStuff,
    InformativaCaregivers,
  },
  data() {
    return {
      associated: {
        givers: null,
        given: null,
      },
      enabling: false,
      selectedUser: null,
      informAccepted: false,
      informRead: false,
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
    modalTitle: function () {
      return this.enabling ? "Abilita caregiver" : "Disabilita caregiver";
    },
    modalInfoTitle: function () {
      if (this.selectedUser) {
        return (
          this.selectedUser.anagrafica.name +
          " " +
          this.selectedUser.anagrafica.surname
        );
      }
      return "";
    },
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    getCaregivers() {
      var self = this;
      cittadinoService
        .getCaregivers()
        .then(function (res) {
          self.associated = res.data.data;
        })
        .catch(function (err) {
          console.log(err);
          var msg = "Non è stato possibile recuperare le informazioni.";
          window.scrollTo(0, 0);
          self.errorAlert(msg);
        });
    },
    openConfirmModal(selected, enabling) {
      this.selectedUser = selected;
      this.enabling = enabling;
      this.$bvModal.show("confirm-op");
    },
    openInfoModal(selected) {
      this.selectedUser = selected;
      this.$bvModal.show("show-info");
    },
    handleCancel() {
      this.selectedUser = null;
      this.enabling = false;
      this.$bvModal.hide("confirm-op");
    },
    handleConfirm() {
      if (this.enabling) {
        if (!this.informAccepted || !this.informRead) {
          window.scrollTo(0, 0);
          this.errorAlert(
            "Per procedere è necessario leggere ed acconsentire all'informativa."
          );
          return false;
        }
        this.enableUser();
      } else {
        this.disableUser();
      }
      this.$bvModal.hide("confirm-op");
    },
    enableUser() {
      var form = {
        user: this.selectedUser.hashid,
      };
      var self = this;
      cittadinoService
        .approveCaregiver(form)
        .then(function (res) {
          self.getCaregivers();
          window.scrollTo(0, 0);
          self.successAlert("Caregiver abilitato");
        })
        .catch(function (err) {
          console.log(err);
          var msg = "Non è stato possibile effettuare l'operazione.";
          window.scrollTo(0, 0);
          self.errorAlert(msg);
        });
    },
    disableUser() {
      var form = {
        user: this.selectedUser.hashid,
      };
      var self = this;
      cittadinoService
        .disapproveCaregiver(form)
        .then(function (res) {
          self.getCaregivers();
          window.scrollTo(0, 0);
          self.successAlert("Caregiver disabilitato");
        })
        .catch(function (err) {
          console.log(err);
          var msg = "Non è stato possibile effettuare l'operazione.";
          window.scrollTo(0, 0);
          self.errorAlert(msg);
        });
    },
  },
  created() {
    this.getCaregivers();
  },
};
</script>
