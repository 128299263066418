import config from "../_config/config";
import { authHeader, postHeader } from "../_helpers";
import axios from "axios";

axios.defaults.baseURL = config.apiUrl;
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.post["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.headers.put["Content-Type"] = "application/json";
axios.defaults.headers.put["X-Requested-With"] = "XMLHttpRequest";

export const aslService = {
  storeNewTherapeuticPlan,
  updateTherapeuticPlan,
  getTherapeuticPlanById,
  // getUserTherapeuticPlan,
  getAllTherapeuticPlan,
  deleteDelivery,
  updateDeliveryStatus,
  getUserTherapeuticPlans,
  isCompanyPicEnabled,
  deletePiano,
  invalidatePlan,
  setFilledSurveyInPlan,
  renewPlan,
  updateTherapeuticPlanAslNote,
  updateTherapeuticPlanPharmacyNote,
  searchUserByDeliveryLabel,
  exportPlanAsXls
};

function storeNewTherapeuticPlan(data) {
  const sett = { headers: authHeader() };
  return axios
    .post("therapeuticplans", data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function updateTherapeuticPlan(hashid, data) {
  const sett = { headers: authHeader() };
  var endpoint = "therapeuticplans/" + hashid;
  return axios
    .put(endpoint, data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getAllTherapeuticPlan(filters) {
  const sett = { headers: authHeader(), params: filters };
  return axios
    .get("therapeuticplans", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

// function getUserTherapeuticPlan(filters) {
//   const sett = { headers: authHeader(), params: filters };
//   return axios
//     .get("therapeuticplans/", sett)
//     .then((response) => {
//       return Promise.resolve(response);
//     })
//     .catch((err) => {
//       var error = err.response;
//       return Promise.reject(error);
//     });
// }

function getTherapeuticPlanById(hashid) {
  const sett = { headers: authHeader() };
  var endpoint = "therapeuticplans/" + hashid;
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function deleteDelivery(hashid) {
  const sett = { headers: authHeader() };
  var endpoint = "therapeuticplans/delivery/" + hashid;
  return axios
    .delete(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function updateDeliveryStatus(data, hashid) {
  const sett = { headers: authHeader() };
  var endpoint = "therapeuticplans/delivery/" + hashid;
  return axios
    .patch(endpoint, data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getUserTherapeuticPlans(filters, hashid) {
  const sett = { headers: authHeader(), params: filters };
  var endpoint = "therapeuticplans/user/" + hashid;
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function isCompanyPicEnabled() {
  const sett = { headers: authHeader() };
  var endpoint = "companies/own/pic/enabled/";
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function deletePiano(hashid) {
  const sett = { headers: authHeader() };
  var endpoint = "therapeuticplans/" + hashid;
  return axios
    .delete(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function invalidatePlan(data, hashid) {
  const sett = { headers: authHeader() };
  var endpoint = "therapeuticplans/status/" + hashid;
  return axios
    .put(endpoint, data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function renewPlan(data, hashid) {
  const sett = { headers: authHeader() };
  var endpoint = "therapeuticplans/renew/" + hashid;
  return axios
    .put(endpoint, data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function setFilledSurveyInPlan(hashid, data) {
  const sett = { headers: authHeader() };
  var endpoint = "therapeuticplans/" + hashid + "/filledsurvey";
  return axios
    .patch(endpoint, data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function updateTherapeuticPlanAslNote(hashid, data) {
  const sett = { headers: authHeader() };
  var endpoint = "therapeuticplans/aslnotes/" + hashid;
  return axios
    .patch(endpoint, data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function updateTherapeuticPlanPharmacyNote(hashid, data) {
  const sett = { headers: authHeader() };
  var endpoint = "therapeuticplans/pharmacynotes/" + hashid;
  return axios
    .patch(endpoint, data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function searchUserByDeliveryLabel(filters){

  const sett = { headers: authHeader(), params: filters };
  return axios
    .get("therapeuticplans/label/search", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function exportPlanAsXls(filters){
  const sett = { headers: authHeader(), params: filters , responseType: "blob" };
  return axios
    .get("therapeuticplans/export/xls", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}