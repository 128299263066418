<template>
  <div>
    <div class="d-flex justify-content-between">
      <router-link
        class="fw-bolder text-primary no__hover"
        :to="{ name: 'questionari' }"
      >
        <b-icon icon="arrow-left-circle-fill" variant="primary"></b-icon>
        Questionari
      </router-link>
    </div>
    <div class="py-3" v-if="template">
      <h6
        class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
      >
        Questionari {{ template.name }} ({{ template.activity.name }})
        somministrati
      </h6>
      <p class="text-justify text-medium-grey fw-bolder">
        Clicca su una "vedi" per visualizzare l'esito del questionario. Per
        scaricare l'elenco completo in formato pdf, clicca su "scarica tutto in
        formato pdf".
        <span v-if="template.type > 1 && template.report_view">
          Per scaricare la scheda di sintesi dell'intero percorso da stampare e
          far firmare al paziente inserire il suo codice fiscale nel campo di
          ricerca "codice fiscale" e cliccare sul tasto "scarica scheda di
          sintesi".</span
        >
      </p>
      <b-row
        cols="3"
        class="mb-3 flex-wrap align-items-center align-content-center px-0"
      >
        <b-col class="col-12 col-md-7 col-lg-8 text-left my-2">
          <b-button
            variant="primary primary_gradient text-white fw-bolder spacing_1"
            class="m-1"
            @click="getReport"
            :disabled="total == 0"
            >Scarica tutto in formato pdf</b-button
          >
          <b-button
            variant="primary primary_gradient text-white fw-bolder spacing_1"
            class="m-1"
            @click="getReportXsl"
            :disabled="total == 0"
            >Scarica tutto in formato excel</b-button
          >
        </b-col>
        <b-col class="col-12 col-md-5 col-lg-4 text-right my-2 mr-0 ml-auto">
          <template v-if="template.type > 1">
            <b-form-input
              class="shadow_6 text-uppercase"
              placeholder="codice fiscale..."
              maxlength="16"
              minlength="16"
              type="search"
              name="cfsearch"
              v-model="filterSetted.cf"
              :disabled="total == 0 && filterSetted.cf == null"
            ></b-form-input>
            <template v-if="template.report_view">
              <b-button
                variant="primary primary_gradient"
                class="text-white fw-bolder spacing_1 mt-3"
                :disabled="total == 0 || filterSetted.cf == null"
                @click="downloadSchedaSintesi"
              >
                <b-icon icon="download"></b-icon> scheda di sintesi
              </b-button>
            </template>
          </template>
        </b-col>
      </b-row>
      <div class="d-flex align-items-center">
        <b-form-group size="sm" class="fw-bolder text-left mr-3">
          <b-form-select
            class="align-items-center align-content-center shadow_6 border border-info text-info"
            name="vedi"
            v-model="filterSetted.take"
            :options="takeOptions"
          >
          </b-form-select>
        </b-form-group>
        <span class="text-info fw-bolder mr-1" v-if="total > 0"
          >{{ fromPage }}-{{ toPage }} di
        </span>
        <span class="text-info fw-bolder"> {{ total }}</span>
      </div>
      <div class="mt-2">
        <b-table
          borderless
          hover
          responsive
          table-variant="white text-info"
          :fields="fields"
          :items="surveys"
          :small="$root.isMobile"
        >
          <template #cell(created_at)="row">
            <span class="text-secondary text-left text-capitalize fw-bolder">{{
              row.item.created_at | moment("DD/MM/YYYY")
            }}</span>
          </template>
          <template #cell(user_mail)="row">
            <span
              class="text-secondary text-left fw-bolder"
              v-if="row.item.user_cf"
            >
              {{ row.item.user_cf }}
              <br
            /></span>
            <span class="text-secondary text-left fw-bolder">{{
              row.item.user_mail ? row.item.user_mail : "---"
            }}</span>
          </template>
          <template #cell(azioni)="row">
            <b-button
              size="sm"
              variant="info info_gradient fw-bolder rounded-pill px-3"
              @click="showThis(row)"
            >
              <b-icon icon="eye-fill"></b-icon> Vedi
            </b-button>
            <b-button
              size="sm"
              variant="primary primary_gradient text-white fw-bolder spacing_1 rounded-pill px-3"
              @click="downloadOne(row)"
            >
              <b-icon icon="download"></b-icon> Completo
            </b-button>
            <b-button
              v-if="row.item.user_cf"
              size="sm"
              variant="primary primary_gradient text-white fw-bolder spacing_1 rounded-pill px-3"
              @click="downloadPatientVersion(row)"
            >
              <b-icon icon="download"></b-icon>
              pdf per il paziente
            </b-button>
          </template>
        </b-table>
        <p v-if="surveys && surveys.length < 1">Nessun questionario qui</p>
        <b-pagination
          v-model="filterSetted.page"
          pills
          :total-rows="total"
          :per-page="perPage"
          align="center"
          :size="$root.isMobile ? 'sm' : 'lg'"
        ></b-pagination>
        <b-modal
          ref="show-filleditem"
          hide-footer
          title="Questionario"
          size="xl"
          scrollable
        >
          <FilledSurveyShower
            v-if="selectedItem"
            :response="selectedItem.response"
            :score="selectedItem.score"
            :identificativo_deblistering="
              selectedItem.identificativo_deblistering
            "
          />
        </b-modal>
      </div>
    </div>
  </div>
</template>
<script>
import { supportService } from "@/_services";
import { mapState, mapActions } from "vuex";
import FilledSurveyShower from "@/components/attivita/FilledSurveyShower.vue";
export default {
  name: "ReportQuestionariCompilati",
  components: { FilledSurveyShower },
  data() {
    return {
      filterSetted: {
        take: 10,
        page: 1,
        cf: null,
      },
      surveys: null,
      total: 0,
      fromPage: 0,
      toPage: 0,
      perPage: 10,
      fields: [
        { key: "created_at", label: "Data" },
        { key: "user_mail", label: "Utente" },
        // { key: "active", label: "Stato" },
        { key: "azioni", label: "" },
      ],
      takeOptions: [10, 25, 50, 100],
      template: null,
      selectedItem: null,
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
  },
  watch: {
    filterSetted: {
      handler(prev, post) {
        // //console.log("changed prev", prev);
        // //console.log("changed post", post);
        this.fetchData();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    fetchData() {
      var self = this;
      var dat = {
        hash: this.$route.params.quest,
        filters: this.filterSetted,
      };
      supportService
        .getFilledForSurvey(dat)
        .then(function (res) {
          //console.log(res);
          self.surveys = res.data.data.data.data;
          self.filterSetted.page = res.data.data.data.current_page;
          self.total = res.data.data.data.total;
          self.fromPage = res.data.data.data.from;
          self.toPage = res.data.data.data.to;
          self.perPage = res.data.data.data.per_page;
          self.template = res.data.data.survey;
        })
        .catch(function (err) {
          console.log(err);
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare i report");
        });
    },
    showThis(row) {
      this.selectedItem = row.item;
      this.$refs["show-filleditem"].show();
    },
    getReport() {
      var self = this;
      supportService
        .surveyReport(this.$route.params.quest)
        .then(function (response) {
          let blob = new Blob([response.data]);
          // let blob = new Blob([response.data], { type: "text/csv" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download =
            self.template.name + "_" + self.template.activity.name + ".pdf";
          link.click();
        })
        .catch(function (err) {
          console.log(err);
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare i report");
        });
    },
    getReportXsl() {
      var self = this;
      supportService
        .surveyXlsReport(this.$route.params.quest)
        .then(function (response) {
          let blob = new Blob([response.data]);
          // let blob = new Blob([response.data], { type: "text/csv" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download =
            self.template.name + "_" + self.template.activity.name + ".xlsx";
          link.click();
        })
        .catch(function (err) {
          console.log(err);
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare i report");
        });
    },
    downloadOne(row) {
      var self = this;
      var selected = row;
      supportService
        .downloadFilledSurvey(selected.item.hashid)
        .then(function (response) {
          let blob = new Blob([response.data]);
          // let blob = new Blob([response.data], { type: "text/csv" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          var fileName =
            self.template.name + "_" + self.template.activity.name + ".pdf";
          if (selected.item.user_cf) {
            fileName = selected.item.user_cf + ".pdf";
          }
          link.download = fileName;
          link.click();
        })
        .catch(function (err) {
          console.log(err);
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare i report");
        });
    },
    downloadPatientVersion(row) {
      var self = this;
      var selected = row;
      supportService
        .downloadFilledSurveyPatientVersion(selected.item.hashid)
        .then(function (response) {
          let blob = new Blob([response.data]);
          // let blob = new Blob([response.data], { type: "text/csv" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          var fileName =
            "patient_" +
            self.template.name +
            "_" +
            self.template.activity.name +
            ".pdf";
          if (selected.item.user_cf) {
            fileName = selected.item.user_cf + "_patient_version" + ".pdf";
          }
          link.download = fileName;
          link.click();
        })
        .catch(function (err) {
          console.log(err);
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare i report");
        });
    },
    downloadSchedaSintesi() {
      const self = this;
      const requestParams = {
        cf: this.filterSetted.cf,
        take: 10,
        page: 1,
      };
      //
      supportService
        .downloadFDSPatientReport(
          this.template.report_view.hashid,
          requestParams
        )
        .then(function (response) {
          let blob = new Blob([response.data]);
          // let blob = new Blob([response.data], { type: "text/csv" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          var fileName =
            "scheda_sintesi_" +
            self.template.name +
            "_" +
            requestParams.cf +
            ".pdf";
          link.download = fileName;
          link.click();
        })
        .catch(function (err) {
          console.log(err);
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare i report");
        });
    },
  },
  created() {
    this.fetchData();
  },
};
</script>
