<template>
  <div>
    <div>
      <div>
        <h5
          class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
        >
          Piani Terapeutici
        </h5>
        <b-row cols="1" cols-lg="3" cols-xl="4" align-v="end">
          <b-col>
            <b-form-group class="text-left">
              <!-- <label for="searchby">Cerca prenotazione per:</label> -->
              <b-form-radio-group
                class="fw-bolder"
                v-model="searchByType"
                :options="searchByTypes"
                name="searchby"
              >
              </b-form-radio-group>
              <b-input-group
                v-if="searchByType === 1"
                size="sm"
                class="mt-2 ml-auto mr-0 shadow_6 rounded-pill border border-prim-grad-1"
              >
                <b-form-input
                  class="align-items-center align-content-center text-uppercase rounded-pill border-right-0"
                  name="cf"
                  v-model="filterSetted.cf"
                  type="search"
                  placeholder="Cerca codice fiscale.."
                >
                </b-form-input>

                <b-input-group-append>
                  <span class="mx-2">
                    <b-icon icon="search"></b-icon>
                  </span>
                </b-input-group-append>
              </b-input-group>
              <b-input-group
                v-else-if="searchByType === 3"
                size="sm"
                class="mt-2 ml-auto mr-0 shadow_6 rounded-pill border border-prim-grad-1"
              >
                <b-form-input
                  class="align-items-center align-content-center text-uppercase rounded-pill border-right-0"
                  name="req-code"
                  v-model="filterSetted.search"
                  type="search"
                  placeholder="Cerca identificativo.."
                >
                </b-form-input>

                <b-input-group-append>
                  <span class="mx-2">
                    <b-icon icon="search"></b-icon>
                  </span>
                </b-input-group-append>
              </b-input-group>
              <b-input-group
                v-else-if="searchByType === 4"
                size="sm"
                class="mt-2 ml-auto mr-0 shadow_6 rounded-pill border border-prim-grad-1"
              >
                <b-form-input
                  class="align-items-center align-content-center text-uppercase rounded-pill border-right-0"
                  name="req-code"
                  v-model="filterSetted.pat"
                  type="search"
                  placeholder="Cerca per patologia.."
                >
                </b-form-input>

                <b-input-group-append>
                  <span class="mx-2">
                    <b-icon icon="search"></b-icon>
                  </span>
                </b-input-group-append>
              </b-input-group>
              <b-input-group
                v-else-if="searchByType === 6"
                size="sm"
                class="mt-2 ml-auto mr-0 shadow_6 rounded-pill border border-prim-grad-1"
              >
                <b-form-input
                  class="align-items-center align-content-center text-uppercase rounded-pill border-right-0"
                  name="req-code"
                  v-model="filterSetted.label"
                  type="search"
                  placeholder="Etichetta consegna.."
                >
                </b-form-input>

                <b-input-group-append>
                  <span class="mx-2">
                    <b-icon icon="search"></b-icon>
                  </span>
                </b-input-group-append>
              </b-input-group>
              <b-input-group
                v-else
                size="sm"
                class="mt-2 ml-auto mr-0 shadow_6 rounded-pill border border-prim-grad-1"
              >
                <b-form-input
                  class="align-items-center align-content-center text-uppercase rounded-pill border-right-0"
                  name="req-code"
                  v-model="filterSetted.surname"
                  type="search"
                  placeholder="Cerca cognome.."
                >
                </b-form-input>

                <b-input-group-append>
                  <span class="mx-2">
                    <b-icon icon="search"></b-icon>
                  </span>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group class="text-left mt-2 ml-auto">
              <label for="type">Stato:</label>
              <b-form-select
                name="type"
                size="sm"
                class="align-items-center align-content-center shadow_6 border border-info text-info"
                v-model="filterSetted.status"
              >
                <b-form-select-option :value="null">tutti</b-form-select-option>
                <b-form-select-option
                  v-for="stat in filterOptions.status"
                  :key="stat"
                  :value="stat"
                  >{{ stat }}</b-form-select-option
                >
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group class="text-left mt-2 mx-auto">
              <label for="timef" size="sm"
                >Piani con ultimo aggiornamento:</label
              >
              <b-form-select
                name="timef"
                size="sm"
                class="align-items-center align-content-center shadow_6 border border-info text-info"
                v-model="filterSetted.range"
                required
              >
                <b-form-select-option value="oggi">oggi</b-form-select-option>
                <b-form-select-option value="settimana"
                  >settimana</b-form-select-option
                >
                <b-form-select-option value="mese"
                  >questo mese</b-form-select-option
                >
                <b-form-select-option value="30g"
                  >ultimi 30 giorni</b-form-select-option
                >
                <b-form-select-option value="anno"
                  >quest'anno</b-form-select-option
                >
                <b-form-select-option :value="null"
                  >mostra tutti</b-form-select-option
                >
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col class="ml-auto">
            <b-form-group class="text-left mt-2 mx-auto">
              <label for="vedi" size="sm">N°elementi da mostrare:</label>

              <b-form-select
                size="sm"
                class="shadow_6 border border-info text-info"
                name="vedi"
                v-model="filterSetted.take"
                :options="takeOptions"
              >
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row cols="2" align-v="end">
          <b-col class="text-left">
            <span class="text-info fw-bolder mr-1" v-if="total > 0"
              >{{ fromPage }}-{{ toPage }} di
            </span>
            <span class="text-info fw-bolder"> {{ total }}</span>
          </b-col>
          <b-col class="text-right">
            <b-button
              v-if="!loading"
              variant="primary text-white fw-bolder spacing_1"
              @click="exportListAsXls"
              >Export selezione</b-button
            >
            <b-button
              v-else
              variant="primary fw-bolder"
              @click="exportListAsXls"
            >
              <b-spinner></b-spinner
            ></b-button>
          </b-col>
        </b-row>
        <b-table
          borderless
          hover
          responsive
          table-variant="white text-info text-left row__font"
          :fields="fields"
          :items="prenotazioni"
          :small="$root.isMobile"
          :sort-by.sync="sortBy"
          :sort-desc.sync="filterSetted.indesc"
          :busy="loading"
          thead-class=" border-medium-grey text-left text-capitalize"
          tbody-class=""
          ref="theraplansTable"
          tbody-tr-class="text-black border-bottom border-light"
        >
          <template #table-busy>
            <div class="text-center text-info my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Caricamento in corso...</strong>
            </div>
          </template>
          <template #cell(stato)="row">
            <span
              class="pt-1 text-uppercase fw-bolder"
              :class="'text-' + planStatus[row.item.status]"
              >{{ row.item.stato }}</span
            >
          </template>
          <template #cell(valid_from)="row">
            <span v-if="row.item.valid_from">{{
              row.item.valid_from | moment("DD/MM/YYYY")
            }}</span>
          </template>
          <template #cell(valid_until)="row">
            <span v-if="row.item.valid_until">{{
              row.item.valid_until | moment("DD/MM/YYYY")
            }}</span>
          </template>
          <template #cell(last_renewal_request)="row">
            <span v-if="row.item.last_renewal_request">{{
              row.item.last_renewal_request | moment("DD/MM/YYYY")
            }}</span>
          </template>
          <template #cell(azioni)="row">
            <div class="d-flex">
              <b-button
                size="sm"
                variant="info info_gradient rounded-pill fw-bolder px-3 fs-6 mx-1"
                @click="row.toggleDetails"
                v-b-toggle="'detail_' + row.item.hashid"
              >
                <b-icon
                  font-scale="1.2"
                  :icon="row.detailsShowing ? 'arrow-up' : 'arrow-down'"
                ></b-icon>
              </b-button>
              <b-button
                @click="
                  $router.push({
                    name: 'piano terapeutico',
                    params: { hash: row.item.hashid },
                  })
                "
                size="sm"
                variant="primary rounded-pill fw-bolder px-3 fs-6 mx-1"
              >
                Vedi Piano
              </b-button>
            </div>
          </template>
          <template #row-details="row">
            <b-collapse :id="'detail_' + row.item.hashid">
              <b-row
                v-if="row.item.deliveries && row.item.deliveries.length > 0"
                cols="1"
                class="py-2 bg-light border-bottom border-prim-grad-1"
              >
                <b-col>
                  <b-row cols="5">
                    <b-col cols="2" class="px-1">
                      <h6>N°Consegna</h6>
                    </b-col>
                    <b-col cols="2">
                      <h6>Etichetta</h6>
                    </b-col>
                    <b-col cols="3">
                      <h6>Contenuto</h6>
                    </b-col>
                    <b-col cols="3">
                      <h6>Stato</h6>
                    </b-col>
                    <b-col cols="2">
                      <h6></h6>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col
                  v-for="(delivery, ind) in row.item.deliveries"
                  :key="delivery.hashid"
                >
                  <b-row cols="5" class="text-secondary bg-white my-1 pt-1">
                    <b-col cols="2" class="px-1">
                      <p class="mb-0">
                        Pacchetto #{{ row.item.deliveries.length - ind }}
                      </p>
                    </b-col>
                    <b-col cols="2">
                      <p class="mb-0">{{ delivery.label }}</p>
                    </b-col>
                    <b-col cols="3">
                      <span
                        v-for="product in delivery.product_list"
                        class="text-prim-grad-1"
                      >
                        <span v-if="product.product_name">
                          {{ product.product_name.toLowerCase() }} - Qtà:
                          {{ product.quantity }}
                        </span>
                        <span v-else>
                          {{ product.therapy.product_name.toLowerCase() }} -
                          Qtà: {{ product.quantity }}
                        </span>
                        <br />
                        {{ product.batch }} -
                        {{ product.expiration | moment("DD/MM/YYYY") }}
                      </span>
                    </b-col>
                    <b-col cols="3">
                      <p class="text-uppercase mb-0">
                        <span
                          class="pt-1 text-uppercase fw-bolder"
                          :class="
                            delivery.status > 0
                              ? 'text-' + statusPositive[delivery.status]
                              : 'text-' +
                                statusNegative[Math.abs(delivery.status)]
                          "
                          >{{ delivery.stato }}</span
                        >
                      </p>
                    </b-col>
                    <b-col cols="2" class="text-right pb-1 px-0">
                      <CambiaStatoConsegna
                        class="d-inline ml-auto mr-0"
                        v-if="
                          delivery.hashid &&
                          delivery.status < 2 &&
                          delivery.status > 0
                        "
                        :asAsl="true"
                        :delivery="delivery"
                        :deliveryIndex="ind"
                        @status-changed="handleDeliveryStatusChange"
                      ></CambiaStatoConsegna>
                      <p class="text-left mr-2" v-if="delivery.delivered_at">
                        Consegnato il
                        {{ delivery.delivered_at | moment("DD/MM/YYYY HH:mm") }}
                      </p>
                      <p class="text-left mr-2" v-if="delivery.rejected_at">
                        Reso il
                        {{ delivery.rejected_at | moment("DD/MM/YYYY HH:mm") }}
                      </p>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row
                v-else
                cols="1"
                class="py-2 bg-light border-bottom border-prim-grad-1"
              >
                <p class="text-left mb-0" v-if="row.item.status !== -4">
                  Ancora nessun pacchetto previsto per questo piano.
                </p>
                <p class="text-left mb-0" v-else>
                  Nessun pacchetto previsto per questo piano in quanto risultato
                  non valido.
                  <span v-if="row.item.invalidation_note"
                    >({{ row.item.invalidation_note }})</span
                  >
                </p>
              </b-row>
            </b-collapse>
          </template>
        </b-table>
        <div class="d-flex">
          <span class="text-info fw-bolder mr-1" v-if="total > 0"
            >{{ fromPage }}-{{ toPage }} di
          </span>
          <span class="text-info fw-bolder"> {{ total }}</span>
        </div>
        <b-pagination
          v-model="filterSetted.page"
          pills
          :total-rows="total"
          :per-page="perPage"
          align="center"
          @change="paginationChanged"
          :size="$root.isMobile ? 'sm' : 'lg'"
        ></b-pagination>
      </div>
    </div>
    <b-spinner variant="primary" label="loading" v-if="loading"></b-spinner>
  </div>
</template>
<script>
import * as moment from "moment/moment";
import { mapState, mapActions } from "vuex";
import { aslService } from "@/_services";
import CambiaStatoConsegna from "@/components/pianoterapeutico/CambiaStatoConsegna.vue";
export default {
  name: "HomePianiTerapeutici",
  components: { CambiaStatoConsegna },
  data() {
    return {
      prenotazioni: null,
      piani: null,
      fields: [
        { key: "codice_richiesta", label: "Identificativo" },
        { key: "created_at", label: "Data Caricamento", sortable: false },
        { key: "codice_fiscale", label: "Codice Fiscale" },
        { key: "valid_from", label: "Validità dal" },
        { key: "valid_until", label: "Validità al" },
        { key: "last_renewal_request", label: "Ultimo rinnovo" },
        { key: "stato", label: "Stato" },
        { key: "azioni", label: "" },
      ],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      planStatus: {
        1: "secondary",
        2: "info",
        3: "primary",
        4: "dark",
        5: "info",
      },
      statusPositive: {
        1: "info",
        2: "prim-grad-1",
        3: "primary",
        4: "dark",
        5: "info",
      },
      statusNegative: {
        1: "danger",
        2: "danger",
        3: "danger",
        4: "danger",
        5: "danger",
      },
      filterOptions: {
        status: [
          "nuovo",
          "in elaborazione",
          "in consegna",
          "completato",
          "invalidato",
          "rinnovo fornitura richiesto",
          "rinnovo fornitura rifiutato",
        ],
        search: null,
      },
      filterSetted: {
        status: null,
        service: undefined,
        search: undefined,
        cf: undefined,
        pat: undefined,
        label: undefined,
        surname: undefined,
        page: 1,
        take: 10,
        range: "30g",
        indesc: true,
      },
      sortBy: "created_at",
      takeOptions: [10, 25, 50, 100],
      countPrev: 0,
      count: 0,
      total: 0,
      fromPage: 0,
      toPage: 0,
      perPage: 0,
      loading: true,
      searchByType: 2,
      searchByTypes: [
        { value: 2, text: "cerca per cognome" },
        { value: 6, text: "cerca per etichetta" },
        { value: 4, text: "cerca per patologia" },
        { value: 1, text: "cerca per codice fiscale" },
        { value: 3, text: "cerca per identificativo" },
      ],
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
    ...mapState("company", ["company"]),
  },
  watch: {
    searchByType: function (value) {
      this.filterSetted.cf = undefined;
      this.filterSetted.search = undefined;
      this.filterSetted.pat = undefined;
      this.filterSetted.surname = undefined;
      this.filterSetted.label = undefined;
    },
    filterSetted: {
      handler(prev, post) {
        if (prev.cf) {
          this.filterSetted.cf = prev.cf.toUpperCase();
          if (prev.cf.length < 4 && prev.cf.length !== 0) {
            return;
          }
        }
        if (prev.surname) {
          if (prev.surname.length < 4 && prev.surname.length !== 0) {
            return;
          }
        }
        if (prev.search) {
          if (prev.search.length < 6 && prev.search.length !== 0) {
            return;
          }
        }
        if (prev.pat) {
          if (prev.pat.length < 4 && prev.pat.length !== 0) {
            return;
          }
        }
        if (prev.label) {
          if (prev.label.length < 5 && prev.label.length !== 0) {
            return;
          }
        }
        this.fetchData();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    fetchData() {
      // //console.log("fetching prenotazioni");
      this.loading = true;
      const self = this;
      aslService
        .getAllTherapeuticPlan(this.filterSetted)
        .then(function (response) {
          console.log("response fetch prenotazioni", response);
          self.prenotazioni = response.data.data.data;
          self.filterSetted.page = response.data.data.current_page;
          self.total = response.data.data.total;
          self.fromPage = response.data.data.from;
          self.toPage = response.data.data.to;
          self.perPage = response.data.data.per_page
            ? response.data.data.per_page
            : self.filterSetted.take;
          self.count = response.data.data.total;
          self.loading = false;
        })
        .catch(function (error) {
          self.errorAlert("non è stato possibile prelevare le richieste");
          // //console.log("error fetch prenotazioni", error);
          self.loading = false;
        });
    },
    exportListAsXls() {
      this.loading = true;
      const self = this;
      aslService
        .exportPlanAsXls(this.filterSetted)
        .then(function (response) {
          self.loading = false;
          let blob = new Blob([response.data]);
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "report_piani_terapeutici.xlsx";
          link.click();
        })
        .catch(function (error) {
          self.errorAlert("non è stato possibile completare l'export");
          self.loading = false;
        });
    },
    handleDeliveryStatusChange() {
      this.fetchData();
    },
    paginationChanged() {},
  },
  created() {
    this.fetchData();
  },
};
</script>
<style>
.table.b-table > tbody > tr.b-table-details > td {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
</style>
