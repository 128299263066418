<template>
  <div v-if="!showAbbError">
    <div v-if="next">
      <div class="col-sm-10 col-lg-8 mx-auto py-3" v-if="!hasStarted">
        <b-row
          :cols="$root.isMobile ? '1' : '2'"
          class="align-items-centerr bg-white shadow_7 general_border justify-content-center py-4"
        >
          <b-col
            class="d-flex flex-column align-content-center"
            :order="$root.isMobile ? '2' : '1'"
          >
            <p class="fw-bolder text-capitalize m-auto">
              {{ next.company.ff_denominazione }}
            </p>
            <div class="m-auto">
              <b-button
                v-if="canStart"
                variant="primary primary_gradient text-white fw-bolder spacing_1"
                @click="startTeleconsulto"
                >Avvia</b-button
              >
            </div>
          </b-col>
          <!-- <b-col cols="1" class="px-0 border-left border-sec-4 py-3"></b-col> -->
          <b-col
            :class="$root.isMobile ? '' : 'border-left border-sec-4'"
            class="pl-2"
            :order="$root.isMobile ? '1' : '2'"
          >
            <p class="text-prim-grad-1">
              Consulto
              <strong v-if="next.filled_survey">{{
                next.filled_survey.survey_name
              }}</strong>
            </p>
            <p class="fw-bolder">
              {{ next.data_inizio | moment("DD/MM/YYYY H:mm") }}
            </p>
            <p
              v-if="next.filled_survey"
              class="text-info point"
              @click="showThis(next)"
            >
              <u>Il tuo questionario</u>
            </p>
          </b-col>
        </b-row>
      </div>
      <div v-else class="d-flex flex-column justify-content-between">
        <div class="text-white" style="min-height: 50vh">
          <VideoCaller
            v-if="showVCaller"
            :tcchannel="next.orderable.channel"
            v-on:tc-end="handleEndTc($event)"
            v-on:tc-start="handleStartTc($event)"
          />
        </div>
        <div class="col-12 mx-auto py-2">
          <b-row
            :cols="$root.isMobile ? '2' : '3'"
            class="align-items-centerr bg-white shadow_7 general_border justify-content-center py-2"
          >
            <b-col class="d-flex flex-column justify-content-center">
              <p class="fw-bolder text-capitalize">
                {{ next.company.ff_denominazione }}
              </p>
              <!-- <div class="m-auto"> -->
              <p
                v-if="next.filled_survey"
                class="text-info point"
                @click="showThis(next)"
              >
                <u>Il tuo questionario</u>
              </p>
              <!-- </div> -->
            </b-col>
            <b-col class="pr-0 border-left border-sec-4 align-self-center">
              <p class="text-prim-grad-1 text-left">
                <strong v-if="next.filled_survey">{{
                  next.filled_survey.survey_name
                }}</strong>
              </p>
              <p class="text-left">
                <strong v-if="duration !== null"
                  >Durata: {{ duration | moment("HH:mm:ss") }}</strong
                >
              </p>
            </b-col>
            <b-col
              class="align-self-center"
              :class="$root.isMobile ? 'text-center col-12' : ''"
            >
              <b-button
                variant="danger fw-bolder text-white rounded-pill"
                @click="endTeleconsulto"
                >{{ hasEnded ? "Chiudi" : "Termina" }}</b-button
              >
            </b-col>
          </b-row>
        </div>
      </div>
      <p class="fw-bolder mt-2" v-if="hasEnded">Consulto concluso.</p>
    </div>
    <div v-if="prenotazioni && !hasStarted">
      <h6
        class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
      >
        Consulti previsti per oggi
      </h6>
      <p v-for="(pren, ind) in prenotazioni" :key="ind" class="text-left">
        <strong>Ore {{ pren.data_inizio | moment("H:mm") }}</strong>
        <strong> {{ pren.filled_survey.survey_name }} </strong> con
        <span class="text-capitalize text-prim-grad-1 fw-bolder">
          {{ pren.subjectable.anagrafica.name }}
          {{ pren.subjectable.anagrafica.surname }}
        </span>
        durata prevista {{ pren.orderable.durata }} minuti
      </p>
      <p v-if="prenotazioni.length == 0" class="fw-bolder">
        Nessun consulto previsto per oggi
      </p>
    </div>
    <b-modal ref="show-filleditem" hide-footer title="Questionario" size="xl">
      <FilledSurveyShower
        v-if="selectedSurvey"
        :response="selectedSurvey.response"
        :score="selectedSurvey.score"
        :identificativo_deblistering="
          selectedSurvey.identificativo_deblistering
        "
      />
    </b-modal>
  </div>
  <div v-else>
    <h4 class="fw-bolder text-danger">
      Purtroppo non è possibile effettuare il consulto: la farmacia selezionata
      non ha più l'abbonamento attivo per il servizio.
    </h4>
  </div>
</template>
<script>
import * as moment from "moment/moment";
import { mapState, mapActions } from "vuex";
import { reservationService } from "@/_services";
import FilledSurveyShower from "@/components/attivita/FilledSurveyShower.vue";
import VideoCaller from "@/components/teleconsulto/VideoCaller.vue";
export default {
  name: "PreviewConsulto",
  components: { FilledSurveyShower, VideoCaller },
  data() {
    return {
      prenotazioni: null,
      filterSetted: {
        status: "in corso",
        service: undefined,
        search: undefined,
        cf: undefined,
        position_ref: 2,
        page: 1,
        take: 10,
        range: "oggi",
        indesc: true,
        prestazione: null,
      },
      next: null,
      selectedSurvey: null,
      background: null,
      hasStarted: false,
      showVCaller: false,
      timer: null,
      duration: null,
      canStart: false,
      hasEnded: false,
      showAbbError: false,
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
    ...mapState("utente", ["user"]),
    ...mapState("company", ["company"]),
    timeD: function () {
      if (this.duration !== null) {
        return this.duration.format("HH:mm:ss");
      }
      return "00,00:00";
    },
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    fetchNext() {
      var self = this;
      reservationService
        .getReservationDetails(this.$route.params.reserv)
        .then(function (response) {
          if (!response.data.data.error) {
            self.next = response.data.data;
            self.valiDate(response.data.data);
          } else {
            self.showAbbError = true;
          }
        })
        .catch(function (error) {
          // self.updatingData = false;
          self.errorAlert("non è stato possibile prelevare le richieste");
          // //console.log("error fetch prenotazioni", error);
          // self.loading = false;
        });
    },
    fetchData() {
      // this.loading = true;
      var self = this;
      reservationService
        .fetchPrenotazioni(this.filterSetted)
        .then(function (response) {
          self.prenotazioni = response.data.data.data;
        })
        .catch(function (error) {
          // self.updatingData = false;
          self.errorAlert("non è stato possibile prelevare le richieste");
          // //console.log("error fetch prenotazioni", error);
          // self.loading = false;
        });
    },
    valiDate(reserv) {
      var startDate = moment(reserv.data_inizio);
      var endDate = moment(reserv.data_inizio);
      var end = moment(endDate).add(reserv.orderable.durata + 5, "minutes");
      var start = moment(startDate).subtract(5, "minutes");
      var now = moment();
      this.canStart = now.isAfter(start) && now.isBefore(end);
      // this.canStart = true;
    },
    showThis(selected) {
      this.selectedSurvey = selected.filled_survey;
      this.$refs["show-filleditem"].show();
    },
    addBlackBg() {
      this.background.classList.remove("bg-white");
      this.background.classList.add("bg-black");
    },
    removeBlackBg() {
      this.background.classList.add("bg-white");
      this.background.classList.remove("bg-black");
    },
    startTeleconsulto() {
      this.hasStarted = true;
      this.hasEnded = false;
      this.showVCaller = true;
      this.addBlackBg();
    },
    endTeleconsulto() {
      this.hasStarted = false;
      this.removeBlackBg();
    },
    handleEndTc(data) {
      //console.log("TC ENDED", data);
      clearInterval(this.timer);
      this.timer = null;
      // var newDuration = this.duration.minutes();
      // var formToSend = {
      //   hash: this.next.hashid,
      //   form: {
      //     // status: 4,
      //     durata_effettiva: newDuration,
      //   },
      // };
      // reservationService
      //   .updateTcDuration(formToSend)
      //   .then(function (response) {
      //     self.hasEnded = true;
      //   })
      //   .catch(function (error) {
      //     console.log(error);
      //   });

      this.hasEnded = true;
    },
    handleStartTc(data) {
      //console.log("TC STARTED", data);
      this.startTimer();
    },
    startTimer() {
      var mom = moment().hour(0).minute(0).second(0);
      this.duration = mom;
      this.timer = setInterval(() => {
        var dur = moment(this.duration).add(1, "second");
        this.duration = dur;
      }, 1000);
    },
  },
  created() {
    // this.fetchData();
    if (this.$route.params.reserv) {
      this.fetchNext();
    } else {
      this.fetchData();
    }
  },
  mounted() {
    var container = document.getElementsByClassName("custom__content__df");
    this.background = container.length > 0 ? container[0] : null;
    var lib = document.createElement("script");
    lib.setAttribute("id", "ls_lib");
    lib.setAttribute("type", "text/javascript");
    lib.setAttribute(
      "src",
      "https://cdn.jsdelivr.net/npm/fm.liveswitch@latest/fm.liveswitch.js"
    );
    document.head.appendChild(lib);
  },
  beforeDestroy() {
    this.removeBlackBg();
    var lib = document.getElementById("ls_lib");
    document.head.removeChild(lib);
  },
};
</script>
<style scoped>
.custom__content__df {
  background-color: #000 !important;
}
</style>
