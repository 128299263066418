<template>
  <div v-if="subs" class="pb-3">
    <h5
      class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
    >
      Sottoscrizioni
    </h5>
    <div v-if="subs.sottoscrizioni">
      <b-row
        cols="1"
        cols-md="2"
        cols-xl="3"
        style="align-items: flex-end !important"
      >
        <b-col
          v-for="(sott, ind) in subs.sottoscrizioni"
          :key="ind"
          :class="sott.attivo == 1 ? 'border-primary' : 'border-danger'"
          class="my-2 py-2 text-justify border-left border-primary bg-light-panel ml-1"
          style="align-items: flex-end !important; border-width: 5px !important"
        >
          <b-col>
            <p class="fw-bolder text-primary mb-0">
              {{ sott.abbonamento.nome }}
            </p>
            <p class="mb-0 text-justify">
              <i>{{ sott.abbonamento.descrizione }}</i>
            </p>
          </b-col>
          <b-col class="text-left">
            <p class="mb-0 fw-bolder text-primary" v-if="sott.attivo == 1">
              Abbonamento attivo
              <b-icon icon="circle-fill" variant="success"></b-icon>
            </p>
            <p class="mb-0 fw-bolder text-danger" v-else>
              Abbonamento non attivo
              <b-icon icon="circle-fill" variant="danger"></b-icon>
            </p>
            <p class="mb-0" v-if="sott.payment && sott.attivo == 1">
              Data pagamento:
              <span>{{ sott.payment.date_paid | moment("DD/MM/YYYY") }}</span>
            </p>
            <p class="mb-0">
              Data attivazione:
              <span>{{ sott.data_inizio | moment("DD/MM/YYYY") }}</span>
              <br />Scadenza:
              <span>{{ sott.data_fine | moment("DD/MM/YYYY") }}</span>
            </p>
            <!-- <p
              class="mb-0 fw-bolder text-primary"
              v-if="
                sott.attivo == 1 && sott.abbonamento.service.position_ref == 2
              "
            >
              {{ subs.connessa }} minuti Connessa spesi questo mese
            </p>
            <p v-else class="mb-0 text-light-panel">-</p> -->
          </b-col>
        </b-col>
        <!-- <b-col
          v-for="(sott, ind) in subs.sottoscrizioni"
          :key="ind"
          class="my-2 py-2"
          style="align-items: flex-end !important"
        >
          <b-col>
            <img :src="sott.abbonamento.service.logo" alt="service logo" />
            <p class="fw-bolder text-primary mb-0">
              {{ sott.abbonamento.nome }}
            </p>
            <p class="mb-0">{{ sott.abbonamento.descrizione }}</p>
          </b-col>
          <b-col class="text-left">
            <p class="mb-0 fw-bolder text-primary" v-if="sott.attivo == 1">
              Abbonamento attivo
              <b-icon icon="circle-fill" variant="success"></b-icon>
            </p>
            <p class="mb-0 fw-bolder text-danger" v-else>
              Abbonamento scaduto
              <b-icon icon="circle-fill" variant="danger"></b-icon>
            </p>
            <p class="mb-0">
              Sottoscritto il
              <strong>{{ sott.data_inizio | moment("DD/MM/YYYY") }}</strong
              >, scadenza il
              <strong>{{ sott.data_fine | moment("DD/MM/YYYY") }}</strong>
            </p>
            <p
              class="mb-0 fw-bolder text-primary"
              v-if="
                sott.attivo == 1 && sott.abbonamento.service.position_ref == 2
              "
            >
              {{ subs.connessa }} minuti spesi questo mese
            </p>
          </b-col>
        </b-col> -->
      </b-row>
      <p class="fw-bolder text-left" v-if="subs.sottoscrizioni.length == 0">
        Nessuna sottoscrizione attiva.
        <!-- <template v-if="user.permessi.includes('attivare sottoscrizioni')">
          <b-button
            variant="primary primary_gradient fw-bolder spacing_1 mx-1"
            @click="$bvModal.show('attiva_sottoscrizione')"
            >Attiva sottoscrizione</b-button
          >
        </template> -->
      </p>
    </div>
    <b-modal
      id="attiva_sottoscrizione"
      title="Attiva una sottoscrizione per la farmacia"
      size="lg"
      @hide="selectedOption = null"
      @close="selectedOption = null"
      centered
      hide-footer
    >
      <div>
        <p class="text-left text-medium-grey fs-5">
          Seleziona la sottoscrizione da attivare per la farmacia.
        </p>
        <b-form id="subs_activation" @submit.prevent="enableSubscription">
          <b-form-group
            label="Sottoscrizione"
            label-for="subscription"
            class="text-left mx-auto col mb-1"
          >
            <b-form-select
              name="subscription"
              class="my-1 shadow_6"
              v-model="selectedOption"
            >
              <b-form-select-option :value="null">--</b-form-select-option>
              <b-form-select-option
                v-for="(opt, ind) in subs.opzioni"
                :key="ind"
                :value="opt"
                >{{ opt.nome }}</b-form-select-option
              >
            </b-form-select>
          </b-form-group>
          <b-row
            cols="1"
            v-if="selectedOption"
            class="px-3 py-2 my-3 bg-light-panel general_border"
          >
            <h6 class="pt-3">Dettagli sottoscrizione selezionata</h6>
            <p class="fw-bolder text-primary mb-0">{{ selectedOption.nome }}</p>
            <p class="mb-0">{{ selectedOption.descrizione }}</p>
            <p class="mb-0 fw-bolder">
              Durata validità: {{ selectedOption.durata }} mesi
            </p>
            <p class="mb-0 fw-bolder">
              Costo: {{ selectedOption.prezzo }}€ iva esclusa
            </p>
          </b-row>
          <b-row class="justify-content-end mt-4">
            <b-button
              type="button"
              variant="outline-danger"
              class="mx-2"
              @click="$bvModal.hide('attiva_sottoscrizione')"
              >Annulla</b-button
            >
            <b-button
              type="submit"
              variant="primary"
              class="mx-2"
              :disabled="!selectedOption"
              >Attiva sottoscrizione</b-button
            >
          </b-row>
        </b-form>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { adminService } from "@/_services";
export default {
  name: "SottoscrizioniFarmacia",
  props: ["hashid"],
  computed: {
    ...mapState("utente", ["status", "user"]),
    ...mapState({
      alert: (state) => state.alert,
    }),
  },
  data() {
    return {
      subs: null,
      loading: false,
      vetrinaLink: null,
      form: {
        company_id: this.hashid,
        subscription_id: null,
      },
      selectedOption: null,
    };
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    fetchData() {
      const self = this;
      const params = { client: 0 };
      adminService
        .getCompanySubscriptions(params, this.hashid)
        .then(function (resp) {
          self.subs = resp.data.data;
        })
        .catch(function (error) {
          self.errorAlert("Non è stato possibile recuperare le sottoscrizioni");
        });
    },
    enableSubscription() {
      this.form.subscription_id = this.selectedOption.hashid;
      const self = this;
      adminService
        .setCompanySubscription(this.form)
        .then(function (resp) {
          self.$bvModal.hide("attiva_sottoscrizione");
          self.infoAlert("Sottoscrizione attivata!");
        })
        .catch(function (error) {
          self.errorAlert("Non è stato possibile attivare la sottoscrizione");
        });
    },
    setAcquistoPost(ind) {
      var hash = this.subs.opzioni[ind].hashid;
      this.$refs["spin-" + ind][0].hidden = false;
      this.$refs["spn-" + ind][0].hidden = true;
      var self = this;
      companyService
        .gotToBuy(hash)
        .then(function (resp) {
          if (resp.data.data.already) {
            window.scrollTo(0, 0);
            self.infoAlert("Sottoscrizione già attiva!");
            self.$refs["spin-" + ind][0].hidden = true;
            self.$refs["spn-" + ind][0].hidden = false;
          }
          if (resp.data.data.redirect) {
            window.location.href = resp.data.data.redirect;
          }
        })
        .catch(function (error) {
          self.$refs["spin-" + ind][0].hidden = true;
          self.$refs["spn-" + ind][0].hidden = false;
          self.errorAlert("Non è stato possibile recuperare le sottoscrizioni");
        });
    },
  },
  created() {
    this.fetchData();
  },
};
</script>
