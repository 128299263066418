<template>
  <div v-if="adesioni">
    <p class="text-justify fw-bolder text-medium-grey my-3">
      Al fine di ottemperare a quanto previsto per la sperimentazione dei Nuovi
      Servizi nella Farmacia di Comunità, ai sensi dell’Accordo stipulato in
      sede di Conferenza Governo, Regioni e Province Autonome di Trento e
      Bolzano, di cui all’art. 1, commi 403 e 406 della legge 205/2017, a
      partire da Luglio 2022 si avviano in diverse regioni le attività di
      raccolta dati, monitoraggio e screening qui denominate "Farmacia dei
      servizi".
      <br />
    </p>
    <div v-if="canChoose">
      <div v-if="adesioni.preadesione === null">
        <h5>Vorresti partecipare al progetto "Farmacia dei servizi"?</h5>
        <b-col>
          <b-form @submit.prevent="setPreadesioneChoice">
            <b-form-group label="" v-slot="{ ariaDescribedby }">
              <b-form-radio-group id="radio-group-2" v-model="form.preadesione" :aria-describedby="ariaDescribedby"
                name="adesione-sub-component" class="font-weight-bolder">
                <b-form-radio :value="true">Sì, voglio partecipare</b-form-radio>
                <b-form-radio :value="false">No, non voglio partecipare</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
            <b-col v-if="form.preadesione !== null">
              <template v-if="!sending">
                <b-button @click="form.preadesione = null" type="button" variant="outline-danger spacing_1 fw-bolder"
                  class="m-2">Annulla</b-button>
                <b-button type="submit" variant="primary primary_gradient spacing_1 fw-bolder" class="m-2">Invia
                </b-button>
              </template>
              <b-spinner v-else></b-spinner>
            </b-col>
          </b-form>
        </b-col>
      </div>
      <div v-else-if="adesioni.preaderito">
        <h5 class="mb-4 text-left">
          Hai dato disponibilità per l'adesione al progetto "Farmacia dei
          servizi".
        </h5>
        <div v-if="adesioni.servizi">
          <h6 class="
              text-prim-grad-1
              fw-bolder
              text-left
              pb-1
              border-bottom border-line-grey
            ">
            Servizi cognitivi
          </h6>
          <p class="text-justify fw-bolder text-medium-grey my-3">
            Indica le tue preferenze
          </p>
          <b-row class="text-left">
            <b-form-group>
              <!-- <b-form-checkbox-group
                id="fds-servs-check"
                v-model="selectedFds"
                name="selectedFds"
                class="font-weight-bold"
              > -->
              <template v-for="(serv, ind) in adesioni.servizi">
                <b-form-checkbox :name="serv.hashid" :key="ind" :checked="serv.preaderito" :unchecked-value="false"
                  :checked-value="true" @change="handleChange(ind)">{{ serv.name }}</b-form-checkbox>
              </template>
              <!-- </b-form-checkbox-group> -->
            </b-form-group>
          </b-row>
          <b-row v-if="selectedFds.length > 0">
            <b-button type="button" variant="primary primary_gradient spacing_1 fw-bolder" class="mx-auto"
              @click="sendPreadesioniServizi">
              <span v-if="!sending">Salva preferenze</span>
              <b-spinner v-else></b-spinner>
            </b-button>
          </b-row>
        </div>
      </div>
      <div v-else-if="adesioni.preaderito === false">
        <h5 class="mb-4 text-left">
          Hai scelto di non partecipare al progetto "Farmacia dei servizi".
        </h5>
      </div>
    </div>
    <div v-else>
      <h5 class="mb-4 text-left">
        "Farmacia dei servizi" non è ancora stato attivato per la tua regione.
      </h5>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { companyService } from "@/_services";
export default {
  name: "Adesioni",
  data() {
    return {
      adesioni: null,
      form: {
        preadesione: null,
      },
      sending: false,
      selectedFds: [],
      canChoose: false
    };
  },
  computed: {
    ...mapState("utente", ["status", "user"]),
    ...mapState("company", ["companyLoggedIn", "company"]),
    ...mapState({
      alert: (state) => state.alert,
    }),
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    fetchData() {
      var self = this;
      companyService
        .getAdesioniStatus()
        .then(function (res) {
          self.adesioni = res.data.data;
          self.canChoose = Array.isArray(res.data.data.servizi) && res.data.data.servizi.length > 0;

        })
        .catch(function (error) {
          self.errorAlert("Si è verificato un errore inatteso.");
        });
    },
    setPreadesioneChoice() {
      this.sending = true;
      var self = this;
      companyService
        .setPreadesione(this.form)
        .then(function (res) {
          self.adesioni = res.data.data;
          self.sending = false;
        })
        .catch(function (error) {
          self.sending = false;
          self.adesioni.preadesione = null;
          self.errorAlert("Non è stato possibile memorizzare la scelta");
        });
    },
    handleChange(indx) {
      this.adesioni.servizi[indx].preaderito =
        !this.adesioni.servizi[indx].preaderito;
      var formData = {
        hashid: this.adesioni.servizi[indx].hashid,
        data: {
          preadesione: this.adesioni.servizi[indx].preaderito,
        },
      };
      this.sendPreadesioniServizi(formData);
    },
    sendPreadesioniServizi(formData) {
      var self = this;
      this.sending = true;
      companyService
        .setPreadesioniServizi(formData)
        .then(function (res) {
          // self.adesioni = res.data.data;
          self.sending = false;
          self.successAlert("Preferenza memorizzata");
        })
        .catch(function (error) {
          self.sending = false;
          self.fetchData();
          self.errorAlert("Non è stato possibile memorizzare la preferenza");
        });
    },
  },
  created() {
    this.fetchData();
  },
};
</script>