<template>
  <div class="pb-4">
    <div v-if="user.type === 0" class="col-12">
      <AccessoEPermessi />
      <ShowUserCode />
      <NotificationChannels />
    </div>
    <b-row cols="2" v-else-if="user.type === 1">
      <b-col v-if="!isLevelAsl" class="col-12 col-md-2 mb-3">
        <!-- <b-button-group class="flex-column"> -->
        <b-button
          v-if="user.permessi.includes('gestire impostazioni dei servizi')"
          class="fw-bolder text-capitalize"
          :size="$root.isMobile ? 'sm' : ''"
          @click="activePanel = 'gestioneservs'"
          :variant="
            activePanel === 'gestioneservs'
              ? 'primary primary_gradient text-white'
              : 'bg-light text-primary'
          "
          >gestione servizi</b-button
        >
        <b-button
          v-if="user.permessi.includes('gestire operatori')"
          class="fw-bolder text-capitalize"
          :size="$root.isMobile ? 'sm' : ''"
          @click="activePanel = 'operatori'"
          :variant="
            activePanel === 'operatori'
              ? 'primary primary_gradient text-white'
              : 'bg-light text-primary'
          "
          >operatori</b-button
        >
        <b-button
          v-if="user.permessi.includes('visualizzare anagrafica azienda')"
          class="fw-bolder text-capitalize"
          :size="$root.isMobile ? 'sm' : ''"
          @click="activePanel = 'anagrafica'"
          :variant="
            activePanel === 'anagrafica'
              ? 'primary primary_gradient text-white'
              : 'bg-light text-primary'
          "
          >anagrafica</b-button
        >
        <b-button
          v-if="user.permessi.includes('visualizzare anagrafica azienda')"
          class="fw-bolder text-capitalize"
          :class="$root.isMobile ? 'text__control' : ''"
          :size="$root.isMobile ? 'sm' : ''"
          @click="activePanel = 'adesioni'"
          :variant="
            activePanel === 'adesioni'
              ? 'primary primary_gradient text-white'
              : 'bg-light text-primary'
          "
          >adesioni</b-button
        >
        <b-button
          v-if="user.permessi.includes('visualizzare anagrafica azienda')"
          class="fw-bolder text-capitalize"
          :class="$root.isMobile ? 'text__control' : ''"
          :size="$root.isMobile ? 'sm' : ''"
          @click="activePanel = 'social'"
          :variant="
            activePanel === 'social'
              ? 'primary primary_gradient text-white'
              : 'bg-light text-primary'
          "
          >Social&Web</b-button
        >
        <b-button
          v-if="user.permessi.includes('visualizzare anagrafica azienda')"
          class="fw-bolder text-capitalize"
          :class="$root.isMobile ? 'text__control' : ''"
          :size="$root.isMobile ? 'sm' : ''"
          @click="activePanel = 'sottoscrizioni'"
          :variant="
            activePanel === 'sottoscrizioni'
              ? 'primary primary_gradient text-white'
              : 'bg-light text-primary'
          "
          >sottoscrizioni</b-button
        >
      </b-col>
      <b-col v-else class="col-12 col-md-2 mb-3">
        <b-button
          v-if="user.permessi.includes('gestire operatori')"
          class="fw-bolder text-capitalize"
          :size="$root.isMobile ? 'sm' : ''"
          @click="activePanel = 'operatori'"
          :variant="
            activePanel === 'operatori'
              ? 'primary primary_gradient text-white'
              : 'bg-light text-primary'
          "
          >operatori</b-button
        >
        <b-button
          v-if="user.permessi.includes('visualizzare anagrafica azienda')"
          class="fw-bolder text-capitalize"
          :size="$root.isMobile ? 'sm' : ''"
          @click="activePanel = 'anagrafica'"
          :variant="
            activePanel === 'anagrafica'
              ? 'primary primary_gradient text-white'
              : 'bg-light text-primary'
          "
          >anagrafica</b-button
        >
      </b-col>
      <b-col :cols="$root.isMobile ? '12' : '10'" class="mx-auto">
        <transition-group name="list" mode="out-in" appear>
          <div
            v-if="
              activePanel === 'gestioneservs' &&
              user.permessi.includes('gestire impostazioni dei servizi')
            "
            key="gestioneservs"
          >
            <!-- <h6 class="text-justify fw-bolder text-medium-grey mt-3">
              Nella sezione
              <strong class="text-info">"Gestione Servizi" </strong> puoi
              configurare i tuoi orari di apertura al pubblico e di
              disponibilità ai servizi DottorFarma, oltre a gestirne lo stato di
              attivazione.
            </h6> -->
            <OrariCompany />
          </div>
          <div
            v-if="
              activePanel === 'anagrafica' &&
              user.permessi.includes('visualizzare anagrafica azienda')
            "
            key="anagrafica"
          >
            <h6
              class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
            >
              Anagrafica Azienda
            </h6>
            <template v-if="company.dottorfarma == 1">
              <p class="text-left text-medium-grey fs-5">
                Scheda Anagrafica dell'azienda. Le informazioni qui presenti
                possono essere modificate accedendo al sito
                <a href="https://www.federfarma.it/">Federfarma</a>.
              </p>
              <AnagraficaCompanyDF />
            </template>
            <AnagraficaCompany v-else />
          </div>
          <div
            v-if="
              activePanel === 'operatori' &&
              user.permessi.includes('gestire operatori')
            "
            key="operatori"
          >
            <h6
              class="text-justify fw-bolder text-medium-grey my-3 bg-light py-2 px-3 general_border"
            >
              Nella sezione <strong class="text-info">"Operatori"</strong> puoi
              creare nuove utenze di accesso alla piattaforma per i tuoi
              dipendenti, visualizzare il tuo codice azienda e gestire i tuoi
              operatori. L'operatore potrà registrare la propria utenza sul
              portale DottorFarma ed accedere in autonomia tramite il login con
              le sue credenziali; la sua libertà di azione sui servizi della
              Farmacia in DottorFarma sarà limitata dal ruolo che gli verrà
              assegnato (vedi sezione
              <strong class="text-info">"Ruoli"</strong> per maggiori dettagli).
            </h6>
            <template
              v-if="
                operatorCount !== null &&
                (!company.operators_limit ||
                  (company.operators_limit > 0 &&
                    operatorCount < company.operators_limit))
              "
            >
              <h6
                class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
              >
                Codice Azienda
              </h6>
              <p class="text-left text-medium-grey fs-5">
                Clicca "mostra codice azienda" per visualizzare il tuo codice
                aziendale. Il codice azienda andrà inserito dall'operatore che
                intende registrarsi autononamente nel portale in qualità di
                operatore nella tua Azienda. Se invece preferisci inviare il
                codice via email, clicca su "invia codice azienda" ed inserisci
                l'indirizzo email del destinatario nell'apposito campo.
              </p>
              <ShowCode class="mb-4" />
            </template>
            <h6
              class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
            >
              Operatori Azienda
            </h6>
            <p class="text-left text-medium-grey fs-5">
              L'elenco dei tuoi operatori. Per abilitare una nuova utenza alla
              gestione dei tuoi servizi DottorFarma puoi inviare un invito via
              email contenente il codice azienda necessario per la sua
              registrazione.
            </p>
            <OperatorsList @total-count="handleOperatorsCount($event)" />
          </div>
          <div
            class="mx-auto"
            v-if="
              activePanel === 'sottoscrizioni' &&
              user.permessi.includes('visualizzare anagrafica azienda')
            "
            key="sottoscrizioni"
          >
            <Sottoscrizioni />
            <template v-if="company.contract">
              <h6
                class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey mt-4"
              >
                Contratto di adesione a DottorFARMA e nomine
              </h6>
              <Contratti />
            </template>
          </div>
          <div class="mx-auto" v-if="activePanel === 'adesioni'" key="adesioni">
            <h6
              class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
            >
              Farmacia dei servizi
            </h6>
            <Adesioni />
          </div>
          <div class="mx-auto" v-if="activePanel === 'social'" key="social">
            <h6
              class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
            >
              Canali Social
            </h6>
            <CanaliSocial />
            <h6
              class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
            >
              Sito Web
            </h6>
            <SitoWeb />
          </div>
        </transition-group>
      </b-col>
    </b-row>
    <div v-else>
      <h5>Nessuna impostazione per questa utenza</h5>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import SettingOrari from "@/components/impostazioni/SettingOrari.vue";
import ServiceStatus from "@/components/impostazioni/ServiceStatus.vue";
import SettingServiceOrari from "@/components/impostazioni/SettingServiceOrari.vue";
import AnagraficaCompany from "@/components/impostazioni/AnagraficaCompany.vue";
import Sottoscrizioni from "@/components/impostazioni/Sottoscrizioni.vue";
import Adesioni from "@/components/impostazioni/Adesioni.vue";
import Contratti from "@/components/impostazioni/Contratti.vue";
import CanaliSocial from "@/components/impostazioni/CanaliSocial.vue";
import SitoWeb from "@/components/impostazioni/SitoWeb.vue";
import AnagraficaCompanyDF from "@/components/impostazioni/AnagraficaCompanyDF.vue";
import PrestazioniSanitarie from "@/components/impostazioni/PrestazioniSanitarie.vue";
import AccessoEPermessi from "@/components/profile/AccessoEPermessi.vue";
import ShowCode from "@/components/impostazioni/ShowCode.vue";
import OrariCompany from "@/components/impostazioni/OrariCompany.vue";
import OperatorsList from "@/components/impostazioni/OperatorsList.vue";
import ShowUserCode from "@/components/impostazioni/ShowUserCode.vue";
import NotificationChannels from "@/components/impostazioni/NotificationChannels.vue";

export default {
  name: "Impostazioni",
  components: {
    AccessoEPermessi,
    SettingOrari,
    SettingServiceOrari,
    ServiceStatus,
    AnagraficaCompany,
    AnagraficaCompanyDF,
    PrestazioniSanitarie,
    ShowCode,
    OrariCompany,
    OperatorsList,
    Sottoscrizioni,
    ShowUserCode,
    NotificationChannels,
    Adesioni,
    CanaliSocial,
    SitoWeb,
    Contratti,
  },
  computed: {
    showOrari() {
      return this.company && this.company.company_openings ? true : false;
    },
    showServizi() {
      return this.company && this.company.servizi_attivi_orari ? true : false;
    },
    showStati() {
      return this.company && this.company.stato_servizi ? false : true;
    },
    isLevelAsl() {
      if (this.company && this.company.level === 3) {
        return true;
      }
      return false;
      // return this.company && this.company.level === 3 ? true : false;
    },
    ...mapState("utente", ["status", "user"]),
    ...mapState("company", ["companyLoggedIn", "company"]),
    // ...mapGetters("company", ["getCompany", "getStatoServizi"]),
    ...mapGetters({
      getStatoServizi: "company/getStatoServizi",
      getServiceOpenings: "company/getServiceOpenings",
      getCompany: "company/getCompany",
    }),
  },
  data() {
    return {
      serviceStatus: null,
      serviceToUpdate: null,
      // activePanel: "operatori",
      activePanel: "gestioneservs",
      operatorCount: null,
    };
  },
  watch: {
    activePanel: function (value) {
      if (value !== "sottoscrizioni") {
        this.logUserCompanyIn();
      }
    },
  },
  methods: {
    ...mapActions("utente", ["me"]),
    ...mapActions("company", [
      "storeOpenings",
      "storeServiceOpenings",
      "updateServiceStatus",
      "logUserCompanyIn",
    ]),
    submitOpenings(dataToSubmit) {
      this.storeOpenings(dataToSubmit).then(function () {
        window.scrollTo(0, 0);
      });
    },
    submitServiceOpenings(dataToSubmit) {
      // //console.log("service data to submit", dataToSubmit);
      var serviceHash = this.company.servizi_attivi[dataToSubmit.service_name];
      dataToSubmit.service_hash = serviceHash;
      this.storeServiceOpenings(dataToSubmit).then(function () {
        window.scrollTo(0, 0);
      });
    },
    submitStatusChange(dataToSubmit) {
      // //console.log("service status data to submit", dataToSubmit);
      var serviceHash = this.company.servizi_attivi[dataToSubmit.service_name];
      dataToSubmit.service_hash = serviceHash;
      var self = this;
      this.updateServiceStatus(dataToSubmit).then(function () {
        window.scrollTo(0, 0);
        self.logUserCompanyIn();
        self.company.servizi_attivi_orari = self.getServiceOpenings;
      });
    },
    submitAnagrafica(dataToSubmit) {
      // //console.log("anagrafica data to submit", dataToSubmit);
      // var serviceHash = this.company.servizi_attivi[dataToSubmit.service_name];
      // dataToSubmit.service_hash = serviceHash;
      // this.storeServiceOpenings(dataToSubmit).then(function () {
      //   window.scrollTo(0, 0);
      // });
    },
    handleOperatorsCount(count) {
      this.operatorCount = count;
    },
  },
  created() {
    this.me();
    if (
      this.$route.query.scope == "sottoscrizioni" &&
      this.user.permessi.includes("visualizzare anagrafica azienda")
    ) {
      this.activePanel = "sottoscrizioni";
    } else if (
      this.$route.query.scope == "operatori" &&
      this.user.permessi.includes("visualizzare anagrafica azienda")
    ) {
      this.activePanel = "operatori";
    }
  },
  mounted() {
    //console.log("mounted, company", this.company);
    if (this.isLevelAsl) this.activePanel = "operatori";
  },
  beforeRouteLeave(to, from, next) {
    this.me();
    next();
  },
};
</script>
<style>
.text__control {
  white-space: initial;
  word-wrap: break-word;
  overflow-x: auto;
}

.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

#sottoscrizioni-button {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  margin-left: 1rem !important;
}
</style>
