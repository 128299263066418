<template>
  <b-container>
    <b-row cols="1" class="text-left mb-4">
      <router-link
        class="fw-bolder text-primary no__hover"
        :to="{ name: 'profilo sanitario' }"
      >
        <b-icon icon="arrow-left-circle-fill" variant="primary"></b-icon>
        Profilo sanitario</router-link
      >
    </b-row>
    <div v-if="therapy" class="text-left">
      <h3 class="font-weight-bolder text-center">Riepilogo</h3>
      <RecapTherapy :therapy="therapy" class="fs-5" />
      <div class="text-center">
        <b-button
          @click="downloadRecap"
          variant="secondary text-white secondary_gradient mx-2 font-weight-bolder"
          >Scarica promemoria
        </b-button>
      </div>
    </div>
    <NewTherapy v-else v-on:save-therapy="showRecap($event)" />
  </b-container>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { cittadinoService } from "@/_services";
import NewTherapy from "@/components/profile/sanitario/NewTherapy.vue";
import RecapTherapy from "@/components/profile/sanitario/RecapTherapy.vue";
export default {
  name: "NuovaTerapia",
  components: { NewTherapy, RecapTherapy },
  data() {
    return {
      activePanel: "rilevazioni",
      subject: null,
      therapy: null,
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
    ...mapState("utente", ["status", "user"]),
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    ...mapActions("utente", ["me"]),
    fetchUser() {
      // get user by id, then subjectId = user fetched hashid
      // subject:{
      //   hashid:user.hashid,
      //   type:"familiare"
      // }
    },
    showRecap(therapy) {
      this.therapy = therapy;
    },
    downloadRecap() {
      var self = this;
      cittadinoService
        .therapyRecap(this.therapy.hashid)
        .then(function (response) {
          let blob = new Blob([response.data]);
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "promemoria_terapia.pdf";
          link.click();
        })
        .catch(function (error) {
          self.errorAlert("Non è stato possibile scaricare il promemoria");
        });
    },
  },
  created() {
    if (this.$route.params.subject) {
      this.fetchUser();
    }
  },
};
</script>