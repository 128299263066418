<template>
  <div v-if="detail">
    <h5 class="text-secondary mb-0 fw-bolder text-capitalize">
      {{ detail.prestazione.name }}
    </h5>
    <p class="mb-0 text-font-grey">
      {{ detail.prestazione.description }}
    </p>
    <p
      v-if="
        reportReady &&
        (detail.htn_ios_link || detail.htn_android_link || detail.htn_web_link)
      "
      class="text-left ml-n3"
    >
      <b-dropdown
        class="d-flex justify-content-center my-2 ml-3"
        variant="primary text-white fw-bolder mr-0 rounded-pill"
        text="Visualizza Referto"
        toggle-class="text-primary"
      >
        <b-dropdown-item
          target="_blank"
          v-if="detail.htn_web_link"
          :href="detail.htn_web_link"
          >Apri HTN su Web</b-dropdown-item
        >
        <b-dropdown-item
          v-if="detail.htn_ios_link"
          :href="detail.htn_ios_link"
          target="_blank"
          >Apri App HTN iOS</b-dropdown-item
        >
        <b-dropdown-item
          target="_blank"
          v-if="detail.htn_android_link"
          :href="detail.htn_android_link"
          >Apri App HTN Android</b-dropdown-item
        >
      </b-dropdown>
    </p>
    <div class="my-2" v-if="allegati && allegati.length > 0">
      <h5 class="text-secondary fw-bolder">Allegati prestazione:</h5>
      <p class="mb-0 text-font-grey">clicca su un allegato per aprirlo.</p>
      <a
        v-for="(allegato, ind) in allegati"
        :key="ind"
        :href="allegato.full_path"
        target="__blank"
        class="point"
      >
        <b-badge
          variant="primary"
          class="my-2 mx-1 py-1 px-2"
          v-b-tooltip.hover
          :title="'apri ' + allegato.name + ' in un\'altra scheda'"
        >
          {{ allegato.name }}
        </b-badge>
      </a>
    </div>
    <div class="mt-2 mb-4" v-if="suspended">
      <h5 class="text-secondary fw-bolder text-danger">
        Questionario da completare !
      </h5>
      <b-button
        size="sm"
        variant="ter-1 rounded-pill fw-bolder"
        @click="
          $router.push({
            name: 'completa questionario',
            params: { quest: suspended.hashid },
          })
        "
        ><span class="mx-1 text-white">Completa questionario</span>
        <b-icon icon="patch-exclamation-fill" variant="white"></b-icon>
      </b-button>
    </div>
    <div class="mt-2 mb-4" v-if="survey !== null && survey.response">
      <h5 class="text-secondary fw-bolder">Questionario</h5>
      <b-button
        variant="info info_gradient fw-bolder rounded-pill"
        size="sm"
        class="px-2 m-1"
        @click="$refs['show-filleditem'].show()"
        >apri questionario</b-button
      >
      <b-button
        variant="secondary secondary_gradient fw-bolder rounded-pill"
        size="sm"
        class="px-2 m-1"
        @click="downloadOne"
        >scarica in formato pdf</b-button
      >
    </div>
    <div
      class="d-flex flex-wrap my-2"
      v-if="Math.abs(stato) !== 4 && checkValidity"
    >
      <template v-if="stato === 5">
        <b-button
          @click="confirmRequest"
          type="button"
          variant="primary primary_gradient fw-bolder text-white"
          class="col-12 my-1 col-lg-6 my-lg-0"
          >Conferma Prenotazione</b-button
        >
        <b-button
          @click="setAction('reject')"
          type="button"
          variant="outline-danger fw-bolder"
          class="col-12 my-1 col-lg-6 my-lg-0"
        >
          Rifiuta Modifiche</b-button
        >
      </template>
      <b-button
        v-else-if="stato > 1"
        @click="setAction('cancel')"
        type="button"
        variant="outline-danger fw-bolder"
      >
        <b-spinner variant="primary" label="loading" v-if="loading"></b-spinner>
        <span v-else> Annulla Appuntamento</span>
      </b-button>
    </div>
    <p class="mb-0" v-if="detail.details">
      Note sulla prestazione:
      <strong>{{ detail.details }} </strong>
    </p>

    <b-modal
      size="xl"
      hide-footer
      centered
      ref="modal-confirm"
      title="Conferma Annullamento"
    >
      <p class="mb-0">Sei sicuro di voler annullare questo appuntamento?</p>
      <div>
        <b-form-group label="Allega messaggio:" v-slot="{ ariaDescribedby }">
          <b-form-radio
            class="my-1"
            v-model="message"
            :aria-describedby="ariaDescribedby"
            :value="1"
            >"{{ msgOptions[1] }}"</b-form-radio
          >
          <b-form-radio
            class="my-1"
            v-model="message"
            :aria-describedby="ariaDescribedby"
            :value="2"
            >"{{ msgOptions[2] }}"</b-form-radio
          >
          <!-- <b-form-radio
            class="my-1"
            v-model="message"
            :aria-describedby="ariaDescribedby"
            :value="3"
            >"{{ msgOptions[3] }}"</b-form-radio
          >
          <b-form-radio
            class="my-1"
            v-model="message"
            :aria-describedby="ariaDescribedby"
            :value="4"
            >"{{ msgOptions[4] }}"</b-form-radio
          > -->
          <b-form-radio
            v-model="message"
            :aria-describedby="ariaDescribedby"
            :value="6"
            >nessun messaggio</b-form-radio
          >
          <b-form-radio
            v-model="message"
            :aria-describedby="ariaDescribedby"
            :value="5"
            >scrivi messaggio</b-form-radio
          >
          <b-form-input
            v-if="message == 5"
            class="simple__input border-top-0 border-left-0 border-right-0"
            name="freeText"
            type="text"
            placeholder="scrivi messaggio..."
            v-model="freeText"
          ></b-form-input>
        </b-form-group>
      </div>
      <div class="d-flex justify-content-end">
        <b-button
          @click="$refs['modal-confirm'].hide()"
          type="button"
          variant="info fw-bolder "
          >No, chiudi</b-button
        >
        <b-button
          @click="cancelRequest"
          type="button"
          variant="outline-danger fw-bolder ml-2"
          >Sì, procedi</b-button
        >
      </div>
    </b-modal>
    <b-modal ref="show-filleditem" hide-footer title="Questionario" size="xl">
      <FilledSurveyShower
        v-if="survey"
        :response="survey.response"
        :score="survey.score"
        :identificativo_deblistering="survey.identificativo_deblistering"
      />
    </b-modal>
  </div>
</template>
<script>
import * as moment from "moment/moment";
import { supportService } from "@/_services";
import { mapState, mapActions } from "vuex";
import FilledSurveyShower from "@/components/attivita/FilledSurveyShower.vue";
export default {
  name: "UserDetailPrestazione",
  props: ["detail", "stato", "datainizio", "quest", "suspended", "reportReady"],
  components: { FilledSurveyShower },
  data() {
    return {
      message: 6,
      msgOptions: {
        1: "Non sono più interessato.",
        2: "Non posso rispettare l'impegno.",
        3: "",
        4: "",
        5: null,
        6: null,
      },
      freeText: null,
      actionName: null,
      loading: false,
      allegati: null,
      survey: null,
    };
  },
  computed: {
    checkValidity: function () {
      // if (this.stato !== 5) {
      var data = moment(this.datainizio);
      return moment().isBefore(data);
      // }
      // return false;
    },
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    confirmRequest() {
      //console.log(this.detail.hashid);
      var data = {
        confirmed: true,
        action: "confirm",
      };
      this.$emit("update-request-status", data);
      // this.$emit("update-request-status", true);
    },
    setAction(action) {
      this.actionName = action;
      this.$refs["modal-confirm"].show();
    },
    cancelRequest() {
      this.loading = true;
      var data = {
        confirmed: false,
        message: this.freeText,
        action: this.actionName,
      };
      //console.log(data);
      if (this.message !== null && this.message !== 5) {
        data = {
          confirmed: false,
          message: this.msgOptions[this.message],
          action: this.actionName,
        };
      }
      if (this.message === 6) {
        data = {
          confirmed: false,
          message: null,
          action: this.actionName,
        };
      }
      this.$refs["modal-confirm"].hide();
      this.$emit("update-request-status", data);
    },
    fetchAllegati() {
      var self = this;
      supportService
        .getHealthServiceAllegati(this.detail.health_service.hashid)
        .then(function (res) {
          self.allegati = res.data.data;
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    downloadOne() {
      var self = this;
      supportService
        .downloadFilledSurvey(this.quest.hashid)
        .then(function (response) {
          let blob = new Blob([response.data]);
          // let blob = new Blob([response.data], { type: "text/csv" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download =
            self.detail.prestazione.name + "_" + "questionario.pdf";
          link.click();
        })
        .catch(function (err) {
          console.log(err);
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare il questionario");
        });
    },
  },
  watch: {
    quest(val) {
      if (val) {
        this.survey = val;
      } else {
        this.survey = null;
      }
    },
  },
  created() {
    this.fetchAllegati();
    //console.log(this.suspended);
    if (this.quest) {
      this.survey = this.quest;
    }
  },
};
</script>
<style>
.simple__input {
  border-radius: 0 !important;
  font-size: 93% !important;
}

.simple__input:focus {
  box-shadow: none !important;
}
</style>
