<template>
  <div v-if="prenotazioni">
    <!-- {{ prenotazioni }} -->
    <div
      v-for="(order, ind) in prenotazioni"
      :key="ind"
      class="text-left pl-2 py-2 my-2 bg-white shadow_3"
      @click="selectedIndex = ind"
    >
      <p class="text-info fw-bolder mb-0">#{{ order.codice_richiesta }}</p>
      <p>
        <strong class="text-capitalize">{{
          order.tipo_ordine == "prestazione"
            ? order.orderable.prestazione.name
            : order.tipo_ordine
        }}</strong>
        presso <strong> {{ order.company.ff_denominazione }}</strong>
        alle ore
        <strong>{{ formatData(order.data_inizio) }}</strong>
        <strong
          class="pt-1 ml-2 text-uppercase"
          :class="
            order.status > 0
              ? 'text-' + statusPositive[order.status]
              : 'text-' + statusNegative[Math.abs(order.status)]
          "
          >{{ order.stato }}</strong
        >
        <span> - </span>
        <strong
          v-if="order.report_ready"
          class="point text-uppercase"
          @click="$refs['modal-dettagliorec'].show()"
        >
          Referto Disponibile
        </strong>
      </p>
      <b-button
        size="sm"
        variant="info info_gradient rounded-pill fw-bolder px-3 fs-6 mx-2 mt-1"
        @click="$refs['modal-dettagliorec'].show()"
        >Vedi dettagli
      </b-button>
      <b-button
        size="sm"
        variant="primary primary_gradient rounded-pill fw-bolder px-3 fs-6 mx-2 mt-1"
        @click="downloadPdf(ind)"
        >Scarica promemoria
      </b-button>
      <b-button
        size="sm"
        variant="primary primary_gradient rounded-pill fw-bolder px-3 fs-6 mx-2 mt-1"
        @click="downloadCalendar(ind)"
        >Scarica calendar
      </b-button>
      <b-button
        v-if="order.suspended_survey"
        size="sm"
        variant="ter-1 rounded-pill fw-bolder px-3 fs-6 mx-2 mt-1"
        @click="
          $router.push({
            name: 'completa questionario',
            params: { quest: order.suspended_survey.hashid }
          })
        "
        ><span class="mx-1 text-white">Completa questionario</span>
        <b-icon icon="patch-exclamation-fill" variant="white"></b-icon>
      </b-button>
    </div>
    <b-modal
      ref="modal-dettagliorec"
      header-class="pb-0"
      hide-footer
      size="xl"
      scrollable
      centered
      @hidden="selectedIndex = null"
    >
      <template #modal-header="{ close }">
        <b-img
          v-if="
            selectedIndex !== null &&
            prenotazioni[selectedIndex].tipo_ordine === 'teleconsulto'
          "
          height="21%"
          :src="require('@/assets/connessa.svg')"
          alt="logo connessa"
          class="pl-4"
        >
        </b-img>
        <b-img
          v-else
          height="23%"
          :src="require('@/assets/facile.svg')"
          alt="logo facile"
          class="pl-4"
        >
        </b-img>
        <button type="button" aria-label="Close" class="close" @click="close()">
          ×
        </button>
      </template>
      <div v-if="selectedIndex !== null">
        <b-card
          header-text-variant="prim-grad-1"
          header-bg-variant="white px-1"
          header-tag="h4"
          class="text-left text-font-grey px-1"
          size="xl"
          border-variant="white"
          body-class="px-1"
        >
          <template #header>
            <b-row cols="2">
              <b-col>
                <span
                  >Dettaglio richiesta #{{
                    prenotazioni[selectedIndex].codice_richiesta
                  }}</span
                >
                <p style="font-size: 70%" class="mb-0 text-font-grey mt-1">
                  Inoltrata il
                  <strong>{{
                    prenotazioni[selectedIndex].created_at ||
                    moment("DD.MM.YY HH:mm")
                  }}</strong>
                </p>
              </b-col>
              <b-col class="text-right">
                <span class="fs-5">
                  <!-- <strong class="ml-1 text-secondary">Richiesta: </strong> -->
                  <span
                    v-if="
                      prenotazioni[selectedIndex].tipo_ordine === 'prestazione'
                    "
                    class="text-prim-grad-1 fw-bolder"
                    >{{
                      prenotazioni[selectedIndex].orderable.health_service
                        ? prenotazioni[selectedIndex].orderable.health_service
                            .name
                        : "prestazione"
                    }}</span
                  >
                  <span v-else class="text-prim-grad-1 fw-bolder">{{
                    prenotazioni[selectedIndex].tipo_ordine === "prodotto"
                      ? "ordine"
                      : prenotazioni[selectedIndex].tipo_ordine
                  }}</span>
                </span>
                <p style="font-size: 70%" class="mb-0 text-font-grey mt-1">
                  <strong>{{
                    prenotazioni[selectedIndex].status === 1
                      ? "ricevuto, da confermare"
                      : prenotazioni[selectedIndex].stato
                  }}</strong>
                </p>
              </b-col>
            </b-row>
          </template>
          <b-card-text>
            <b-row>
              <b-col>
                <h5 class="text-secondary fw-bolder mt-1">Sede:</h5>
                <p class="mb-0 text-capitalize fw-bolder">
                  {{ prenotazioni[selectedIndex].company.ff_denominazione }}
                  ,
                  <strong>
                    {{ prenotazioni[selectedIndex].company.indirizzo }}
                  </strong>
                  ,
                  <strong class="text-capitalize"
                    >{{ prenotazioni[selectedIndex].company.cap }}
                    {{ prenotazioni[selectedIndex].company.comune }}
                    ({{ prenotazioni[selectedIndex].company.provincia }})
                    {{ prenotazioni[selectedIndex].company.regione }}
                  </strong>
                </p>
              </b-col>
              <b-col
                v-if="
                  prenotazioni[selectedIndex].requested_by.anagrafica.cf !==
                  prenotazioni[selectedIndex].subjectable.anagrafica.cf
                "
              >
                <h5 class="text-secondary fw-bolder">
                  <span class="text-capitalize">{{
                    prenotazioni[selectedIndex].tipo_ordine
                  }}</span>
                  richiesta per conto di:
                </h5>
                <p class="text-secondary fw-bolder">
                  <b-icon
                    icon="person-fill"
                    variant="secondary"
                    class="mr-2"
                  ></b-icon>

                  <span
                    class="text-capitalize"
                    v-if="prenotazioni[selectedIndex].subjectable.anagrafica"
                    >{{
                      prenotazioni[selectedIndex].subjectable.anagrafica.name
                    }}
                    {{
                      prenotazioni[selectedIndex].subjectable.anagrafica.surname
                    }}
                  </span>
                  <span v-else class="text-capitalize"
                    >{{ prenotazioni[selectedIndex].subjectable.name }}
                    {{ prenotazioni[selectedIndex].subjectable.surname }},
                  </span>

                  <b-icon
                    icon="credit-card-fill"
                    variant="secondary"
                    class="mr-2"
                  ></b-icon>
                  <span class="text-uppercase"
                    >{{
                      prenotazioni[selectedIndex].subjectable.anagrafica
                        ? prenotazioni[selectedIndex].subjectable.anagrafica.cf
                        : prenotazioni[selectedIndex].subjectable.cf
                    }}
                  </span>
                </p>
              </b-col>
            </b-row>
            <b-row class="my-2">
              <b-col>
                <h5 class="text-secondary fw-bolder">Giorno e orario:</h5>
                <p class="fw-bolder">
                  <b-icon
                    icon="calendar"
                    variant="secondary"
                    class="mr-2"
                  ></b-icon>
                  <span class="mx-2">{{
                    prenotazioni[selectedIndex].data_inizio | moment("DD.MM.YY")
                  }}</span>
                  <b-icon
                    icon="clock"
                    variant="secondary"
                    class="mr-2"
                  ></b-icon>

                  <span class="mx-2">{{
                    prenotazioni[selectedIndex].data_inizio | moment("HH:mm")
                  }}</span>
                  <span v-if="prenotazioni[selectedIndex].data_fine">
                    -
                    {{
                      prenotazioni[selectedIndex].data_fine | moment("HH:mm")
                    }}</span
                  >
                  <UpdateReservationDate
                    class="d-inline ml-2 ml-md-0 ml-lg-1"
                    :hash="prenotazioni[selectedIndex].hashid"
                    v-on:change-requested="handleUpdateRequest"
                  />
                </p>
              </b-col>
            </b-row>
            <b-row class="pl-3">
              <UserDetailTeleconsulto
                :detail="prenotazioni[selectedIndex].orderable"
                :stato="prenotazioni[selectedIndex].status"
                :datainizio="prenotazioni[selectedIndex].data_inizio"
                :quest="prenotazioni[selectedIndex].filled_survey"
                :suspended="prenotazioni[selectedIndex].suspended_survey"
                :hash="prenotazioni[selectedIndex].hashid"
                v-if="
                  prenotazioni[selectedIndex].tipo_ordine === 'teleconsulto'
                "
                v-on:update-request-status="
                  handleStatusUpdate(prenotazioni[selectedIndex], $event)
                "
              />
              <UserDetailPrestazione
                :detail="prenotazioni[selectedIndex].orderable"
                :reportReady="prenotazioni[selectedIndex].report_ready"
                :stato="prenotazioni[selectedIndex].status"
                :datainizio="prenotazioni[selectedIndex].data_inizio"
                :quest="prenotazioni[selectedIndex].filled_survey"
                :suspended="prenotazioni[selectedIndex].suspended_survey"
                v-if="prenotazioni[selectedIndex].tipo_ordine === 'prestazione'"
                v-on:update-request-status="
                  handleStatusUpdate(prenotazioni[selectedIndex], $event)
                "
              />
              <UserDetailProdotto
                :dettaglio="prenotazioni[selectedIndex].orderable"
                :stato="prenotazioni[selectedIndex].status"
                :datainizio="prenotazioni[selectedIndex].data_inizio"
                v-if="prenotazioni[selectedIndex].tipo_ordine === 'prodotto'"
                v-on:update-request-status="
                  handleStatusUpdate(prenotazioni[selectedIndex], $event)
                "
              />
              <!-- <p class="mb-0" v-if="prenotazioni[selectedIndex].user_notes">
                Note dell'utente:
                <strong>{{ prenotazioni[selectedIndex].user_notes }}</strong>
              </p>
              <p class="mb-0" v-if="prenotazioni[selectedIndex].company_notes">
                Note Operatore:
                <strong>{{ prenotazioni[selectedIndex].company_notes }}</strong>
              </p> -->
            </b-row>

            <p class="mb-0 bg-light p-1">
              <br />
              <strong> Tue note </strong>
              <UpdateNotesComponent
                v-on:note-updated="handleNotesUpdate($event)"
                class="d__innline"
                :hashid="prenotazioni[selectedIndex].hashid"
                :reserdate="prenotazioni[selectedIndex].data_inizio"
              >
              </UpdateNotesComponent>
              <template v-if="prenotazioni[selectedIndex].user_notes"
                ><br />
                {{ prenotazioni[selectedIndex].user_notes }}
              </template>
            </p>
            <p
              class="mb-0 bg-light p-1"
              v-if="prenotazioni[selectedIndex].company_notes"
            >
              <strong>Note Operatore:</strong>
              {{ prenotazioni[selectedIndex].company_notes }}
            </p>
          </b-card-text>
        </b-card>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { reservationService } from "@/_services"
import { mapActions } from "vuex"
import * as moment from "moment/moment"
import UserDetailPrestazione from "@/components/prenotazioni/UserDetailPrestazione.vue"
import UserDetailTeleconsulto from "@/components/prenotazioni/UserDetailTeleconsulto.vue"
import UserDetailProdotto from "@/components/prenotazioni/UserDetailProdotto.vue"
import UpdateNotesComponent from "@/components/prenotazioni/UpdateNotesComponent.vue"
import UpdateReservationDate from "@/components/prenotazioni/UpdateReservationDate.vue"
export default {
  name: "UserLatests",
  components: {
    UserDetailTeleconsulto,
    UserDetailPrestazione,
    UserDetailProdotto,
    UpdateNotesComponent,
    UpdateReservationDate
  },
  data() {
    return {
      prenotazioni: null,
      selectedIndex: null,
      statusPositive: {
        1: "secondary",
        2: "info",
        3: "info",
        4: "primary",
        5: "ter-4",
        6: "ter-4"
      },
      statusNegative: {
        1: "danger",
        2: "ter-2",
        3: "ter-2",
        4: "dark"
      },
      loading: false
    }
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    fetchData() {
      this.loading = true
      var self = this
      reservationService
        .latestReservations()
        .then(function (response) {
          self.prenotazioni = response.data.data
          self.loading = false
        })
        .catch(function (error) {
          self.errorAlert(
            "non è stato possibile recuperare le prenotazioni recenti"
          )
          self.loading = false
          //console.log("error fetch prenotazioni", error);
        })
    },
    formatData(data) {
      return moment(data).format(" HH:mm dddd DD MMM YYYY")
    },
    handleStatusUpdate(item, data) {
      this.loading = true
      var self = this
      if (!data.confirmed) {
        var form = data.message ? { message: data.message } : {}
        reservationService
          .userCancelOrReject(form, data.action, item.hashid)
          .then(function (re) {
            window.scrollTo(0, 0)
            self.successAlert("Richiesta aggiornata con successo")
            self.fetchData()
          })
          .catch(function (error) {
            self.loading = false
            window.scrollTo(0, 0)
            self.errorAlert("non è stato possibile effettuare l'operazione")
          })
        this.$refs["modal-dettagliorec"].hide()
      } else {
        reservationService
          .userConfirmOrder(item.hashid)
          .then(function (re) {
            window.scrollTo(0, 0)
            self.successAlert("Richiesta aggiornata con successo")
            self.fetchData()
          })
          .catch(function (error) {
            self.loading = false
            window.scrollTo(0, 0)
            self.errorAlert("non è stato possibile effettuare l'operazione")
          })
        this.$refs["modal-dettagliorec"].hide()
      }
    },
    downloadPdf(ind) {
      const hash = this.prenotazioni[ind].hashid
      const fName = this.prenotazioni[ind].codice_richiesta
      var self = this
      reservationService
        .scaricaPromemoria(hash)
        .then(function (response) {
          let blob = new Blob([response.data])
          let link = document.createElement("a")
          link.href = window.URL.createObjectURL(blob)
          link.download = fName + ".pdf"
          link.click()
        })
        .catch(function (error) {
          self.loading = false
          window.scrollTo(0, 0)
          self.errorAlert("non è stato possibile scaricare il promemoria")
        })
    },
    downloadCalendar(ind) {
      const hash = this.prenotazioni[ind].hashid
      const fName = this.prenotazioni[ind].codice_richiesta
      var self = this
      reservationService
        .scaricaCalendar(hash)
        .then(function (response) {
          let blob = new Blob([response.data])
          let link = document.createElement("a")
          link.href = window.URL.createObjectURL(blob)
          link.download = fName + ".ics"
          link.click()
        })
        .catch(function (error) {
          self.loading = false
          window.scrollTo(0, 0)
          self.errorAlert("non è stato possibile scaricare il promemoria")
        })
    },
    handleNotesUpdate(data) {
      this.prenotazioni[this.selectedIndex].user_notes = data.user_notes
    },
    handleUpdateRequest() {
      this.$refs["modal-dettagliorec"].hide()
      this.successAlert("richiesta inviata con successo")
      this.fetchData()
    }
  },
  created() {
    this.fetchData()
  }
}
</script>
