<template>
  <div v-if="detail" class="mb-3">
    <h5 class="font-weight-bolder text-secondary mt-4 ml-3">
      Dettagli Teleconsulto
    </h5>
    <div class="p-3 mb-3">
      <b-row cols="1">
        <b-col class="px-0">
          <span class="mx-2 text-left">
            <span class="text-secondary"> Durata prevista: </span>
            <strong>{{ detail.durata }} minuti</strong>
          </span>
          <span class="mx-2 text-left" v-if="stato === 4">
            <span class="text-secondary">Durata effettiva: </span>
            <strong>{{ detail.durata_effettiva }} minuti</strong>
          </span>
        </b-col>
        <b-col class="px-0 my-3">
          <b-button
            v-if="stato === 2 || stato === 3"
            type="button"
            variant="primary primary_gradient fw-bolder text-white rounded-pill"
            @click="
              $router.push({ name: 'videoconsulto', params: { reserv: hash } })
            "
            >Vai al Teleconsulto</b-button
          >
        </b-col>
      </b-row>
      <div class="mt-2 mb-4" v-if="tobecompleted">
        <p class="mb-0">Il paziente deve ancora completare il questionario.</p>
      </div>
      <div class="mt-2 mb-4" v-else-if="survey !== null && survey.response">
        <p class="text-secondary fw-bolder">Questionario:</p>
        <b-button
          variant="info info_gradient fw-bolder rounded-pill"
          class="px-4 m-1"
          @click="$refs['show-filleditem'].show()"
          >apri questionario</b-button
        >
      </div>
      <p class="mb-0" v-if="detail.operator_notes">
        Note sul teleconsulto:
        <strong>{{ detail.operator_notes }} </strong>
      </p>

      <!-- <div class="mt-2 mb-4" v-if="
        Math.abs(stato) !== 4 &&
        Math.abs(stato) !== 3 &&
        Math.abs(stato) !== 2
      ">
        <h6 class="fw-bolder text-secondary">Modifica giorno ed ora</h6>
        <b-row cols="2" class="align-items-center fw-bolder flex-wrap">
          <b-col class="d-flex align-items-center pl-0 col-12 col-md-10 col-lg-8">
            <b-form-datepicker class="general_border" placeholder="Imposta data" locale="it" today-button reset-button
              close-button label-today-button="Oggi" label-close-button="Chiudi" label-reset-button="Scegli giorno"
              start-weekday="1" close-button-variant="outline-danger" reset-button-variant="danger"
              today-button-variant="primary" hide-header v-model="newDate" :state="checkValidity"></b-form-datepicker>
          </b-col>
          <b-col class="ml-0 pl-0 col-4 col-md-2 col-lg-4">
            <b-form-input class="h-100" type="time" v-model="newTime" :state="checkValidity"></b-form-input>
          </b-col>
        </b-row>
        <b-row cols="1">
          <b-col class="pl-0">
            <h6 class="fw-bolder text-secondary mt-4">Nota per il cliente</h6>

            <b-form-group>
              <b-form-input v-model="company_notes" type="text" class="border border-grey">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      </div> -->
      <p v-if="detail.details">
        Dettagli:
        <strong>{{ detail.details }} </strong>
      </p>
    </div>
    <div
      class="d-flex justify-content-center my-2"
      v-if="Math.abs(stato) !== 4"
    >
      <b-button
        v-if="editBtn"
        @click="updateRequest"
        type="button"
        variant="info info_gradient fw-bolder text-white ml-0 mr-auto rounded-pill"
        >Invia Risposta</b-button
      >
      <b-button
        v-else-if="stato === 1 || stato === 6"
        @click="confirmRequest"
        type="button"
        variant="primary primary_gradient fw-bolder text-white ml-0 mr-auto rounded-pill"
        >Conferma Ordine</b-button
      >
      <b-button
        v-if="stato === 1"
        @click="setAction('reject')"
        type="button"
        variant="outline-danger fw-bolder mr-0 ml-auto rounded-pill"
        >Declina Ordine</b-button
      >
      <b-button
        v-if="stato > 1"
        @click="setAction('cancel')"
        type="button"
        variant="outline-danger fw-bolder mr-0 ml-auto rounded-pill"
        >Annulla Appuntamento</b-button
      >
    </div>

    <b-modal
      size="xl"
      hide-footer
      centered
      ref="modal-confirm"
      title="Conferma Annullamento"
    >
      <p class="mb-0">Sei sicuro di voler annullare questo appuntamento?</p>
      <div>
        <b-form-group label="Allega messaggio:" v-slot="{ ariaDescribedby }">
          <b-form-radio
            class="my-1"
            v-model="message"
            :aria-describedby="ariaDescribedby"
            :value="1"
            >"{{ msgOptions[1] }}"</b-form-radio
          >
          <b-form-radio
            class="my-1"
            v-model="message"
            :aria-describedby="ariaDescribedby"
            :value="2"
            >"{{ msgOptions[2] }}"</b-form-radio
          >
          <b-form-radio
            class="my-1"
            v-model="message"
            :aria-describedby="ariaDescribedby"
            :value="3"
            >"{{ msgOptions[3] }}"</b-form-radio
          >
          <b-form-radio
            class="my-1"
            v-model="message"
            :aria-describedby="ariaDescribedby"
            :value="4"
            >"{{ msgOptions[4] }}"</b-form-radio
          >
          <b-form-radio
            v-model="message"
            :aria-describedby="ariaDescribedby"
            :value="6"
            >nessun messaggio</b-form-radio
          >
          <b-form-radio
            v-model="message"
            :aria-describedby="ariaDescribedby"
            :value="5"
            >scrivi messaggio</b-form-radio
          >
          <b-form-input
            v-if="message == 5"
            class="simple__input border-top-0 border-left-0 border-right-0"
            name="freeText"
            type="text"
            placeholder="scrivi messaggio..."
            v-model="freeText"
          ></b-form-input>
        </b-form-group>
      </div>
      <div class="d-flex justify-content-end">
        <b-button
          @click="$refs['modal-confirm'].hide()"
          type="button"
          variant="info fw-bolder "
          >No, chiudi</b-button
        >
        <b-button
          @click="cancelRequest"
          type="button"
          variant="outline-danger fw-bolder ml-2"
          >Sì, procedi</b-button
        >
      </div>
    </b-modal>

    <b-modal ref="show-filleditem" hide-footer title="Questionario" size="xl">
      <FilledSurveyShower
        v-if="survey"
        :response="survey.response"
        :score="survey.score"
        :identificativo_deblistering="survey.identificativo_deblistering"
      />
    </b-modal>
  </div>
</template>
<script>
import * as moment from "moment/moment";
import { reservationService } from "@/_services";
import FilledSurveyShower from "@/components/attivita/FilledSurveyShower.vue";
export default {
  name: "DetailTeleconsulto",
  props: ["dettaglio", "stato", "datainizio", "quest", "hash", "tobecompleted"],
  components: { FilledSurveyShower },
  data() {
    return {
      message: 6,
      msgOptions: {
        1: "Ci spiace comunicarLe che non possiamo gestire ulteriori richieste per la data selezionata. La preghiamo di effettuare una nuova prenotazione per la settimana successiva alla data scelta.",
        2: "Ci spiace comunicarLe che a causa di problematiche relative all'approvvigionamento non possiamo gestire la sua richiesta per la data selezionata.",
        3: "Ci spiace comunicarLe che non possiamo gestire la sua richiesta per mancanza di dati essenziali. La preghiamo di completare la sua scheda anagrafica.",
        4: "Si è verificato un imprevisto che ci costringe a disdire il suo appuntamento. Se lo desidera, ci chiami per ulteriori informazioni.",
        5: null,
        6: null,
      },
      freeText: null,
      actionName: null,
      loading: false,
      detail: this.dettaglio,
      editBtn: false,
      newDate: this.datainizio,
      newTime: moment(this.datainizio).format("HH:mm"),
      company_notes: null,
      survey: null,
    };
  },
  computed: {
    checkValidity: function () {
      var data = moment(this.datainizio);
      return moment().isBefore(data);
    },
  },
  watch: {
    dettaglio: {
      handler(val) {
        this.$nextTick(function () {
          this.detail = val;
          if (val.resource_id !== null) {
            this.getAllegato();
          }
        });
      },
      deep: true,
    },
    detail: {
      handler(val) {
        // this.editBtn = true;
        this.editBtn =
          Math.abs(this.stato) !== 4 &&
          Math.abs(this.stato) !== 3 &&
          Math.abs(this.stato) !== 2;
      },
      deep: true,
    },
    newDate(val) {
      this.editBtn = true;
    },
    newTime(val) {
      this.editBtn = true;
    },
    company_notes(val) {
      this.editBtn = true;
    },
    quest(val) {
      if (val) {
        this.survey = val;
      } else {
        this.survey = null;
      }
    },
  },
  methods: {
    getAllegato() {
      var self = this;
      var endpoint =
        this.dettaglio.allegato.full_path +
        "/" +
        this.dettaglio.allegato.hashid;
      reservationService
        .getAllegato(endpoint)
        .then(function (res) {
          self.picLink = window.URL.createObjectURL(new Blob([res.data]));
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    confirmRequest() {
      //console.log(this.detail.hashid);
      var data = {
        confirmed: true,
        action: "confirm",
      };
      this.$emit("update-request-status", data);
    },
    updateRequest() {
      //console.log(this.detail.hashid);
      var daTe = moment(this.newDate);
      var tiMe = moment(this.newTime, "HH:mm");
      daTe.hours(tiMe.hours());
      daTe.minutes(tiMe.minutes());
      var datetime = daTe.format("YYYY-MM-DD HH:mm");
      //console.log(daTe.format("YYYY-MM-DD HH:mm"));
      var dataToSend = {
        detail: this.detail,
        newDateTime: datetime,
        company_notes: this.company_notes,
      };
      this.$emit("update-request-response", dataToSend);
    },
    setAction(action) {
      this.actionName = action;
      this.$refs["modal-confirm"].show();
    },
    cancelRequest() {
      this.loading = true;
      var data = {
        confirmed: false,
        message: this.freeText,
        action: this.actionName,
      };
      //console.log(data);
      if (this.message !== null && this.message !== 5) {
        data = {
          confirmed: false,
          message: this.msgOptions[this.message],
          action: this.actionName,
        };
      }
      if (this.message === 6) {
        data = {
          confirmed: false,
          message: null,
          action: this.actionName,
        };
      }
      this.$refs["modal-confirm"].hide();
      this.$emit("update-request-status", data);
    },
  },
  created() {
    if (this.quest) {
      this.survey = this.quest;
    }
  },
};
</script>
