<template>
  <div>
    <b-row cols="1" class="text-justify mb-4">
      <h6>Di sequito trovi l'elenco delle domande più richieste:</h6>
      <a class="text-prim-grad-1 ml-2" href="#1"> Cosa è DottorFarma per i cittadini?</a>
      <a class="text-prim-grad-1 ml-2" href="#2"> Come posso prenotare un servizio?</a>
      <a class="text-prim-grad-1 ml-2" href="#3"> Cosa è il profilo sanitario?</a>
      <a class="text-prim-grad-1 ml-2" href="#4"> Dove posso visualizzare le mie terapie?</a>
      <a class="text-prim-grad-1 ml-2" href="#5"> Posso sospendere una terapia?</a>
      <a class="text-prim-grad-1 ml-2" href="#6"> A cosa serve la sezione “I miei Familiari”?</a>
      <a class="text-prim-grad-1 ml-2" href="#7"> Chi sono gli assistiti?</a>
      <a class="text-prim-grad-1 ml-2" href="#8"> Chi sono i caregiver?</a>
      <a class="text-prim-grad-1 ml-2" href="#9"> Posso aggiungere nuovi familiari o caregiver?</a>
      <a class="text-prim-grad-1 ml-2" href="#10"> Cosa è la sezione “il mio Circolo di cura”?</a>
      <a class="text-prim-grad-1 ml-2" href="#11"> Cosa trovo in “La mia Agenda”?</a>
      <a class="text-prim-grad-1 ml-2" href="#12"> Profilo</a>
      <a class="text-prim-grad-1 ml-2" href="#13"> Dove posso cambiare la password di accesso?</a>
      <a class="text-prim-grad-1 ml-2" href="#14"> Posso eliminare il mio profilo?</a>
      <a class="text-prim-grad-1 ml-2" href="#15"> Cosa è il codice utente?</a>
      <a class="text-prim-grad-1 ml-2" href="#16"> Come gestisco le notifiche?</a>
      <a class="text-prim-grad-1 ml-2" href="#17"> Da dove posso visualizzare gli accessi alla piattaforma?</a>
    </b-row>
    <h6 id="1" class="
        text-prim-grad-1
        fw-bolder
        text-left
        pb-1
        border-bottom border-line-grey
      ">
      Cosa è DottorFarma per i cittadini?
    </h6>
    <p class="text-justify">
      Dottorfarma per i cittadini è uno spazio in cui ogni utente della piattaforma ha la possibilità di registrarsi e
      di usufruire dei servizi offerti dalla propria farmacia predefinita volti ad accompagnare il paziente, il
      caregiver e i familiari nel percorso di cura (mediante promemoria, possibilità di prenotare farmaci e servizi…).
      <br />
      L’Homepage del paziente presenta 5 funzionalità distinte: <br /> <br />
    <ul>
      <li> <b> prenota</b>: pulsante in alto a dx. In questa pagina si possono inserire (con il tasto +) la/e farmacia/e
        che si desidera impostare come predefinite per usufruire sei servizi di prenotazione delle prestazioni dei
        servizi offerti e dei farmaci.</li>
      <li> <b> il mio profilo sanitario</b>:dove si possono registrare lo stato di salute e l’armadietto farmacologico,
        ossia la lista dei farmaci che il paziente assume.</li>
      <li> <b> i miei familiari</b>:la lista dei familiari e dei caregiver che possono utilizzare il servizio per conto
        del paziente.</li>
      <li> <b> il mio circolo di cura</b>: dove si possono impostare le farmacie di riferimento del paziente o i
        professionisti abilitati a visualizzare i dati.</li>
      <li> <b> la mia agenda</b>: che contiene tutti gli appuntamenti delle prestazioni o degli ordini in farmacia.
      </li>
    </ul>
    <br>
    <b-img fluid src="/assets/help/cittadino/1.png"></b-img> <br>
    </p>
    <h6 id="2" class="
        text-prim-grad-1
        fw-bolder
        text-left
        pb-1
        border-bottom border-line-grey
      ">
      Come posso prenotare un servizio?
    </h6>
    <p class="text-justify mb-0">
      1. Dalla Homepage cliccare su Prenota e selezionare la farmacia desiderata tra quelle associate all’account.
      Premendo il tasto “+” è possibile aggiungere una nuova farmacia, ricercandola tra quelle aderenti a Dottorfarma.
    </p>
    <b-img fluid src="/assets/help/cittadino/2.png"></b-img>
    <p class="text-justify mb-0">
      2. Una volta scelta la farmacia, selezionare se si vuole fare la prenotazione per sè stessi, per un familiare o
      per un assistito e cliccare su Avanti.</p>
    <b-img fluid src="/assets/help/cittadino/3.png"></b-img>
    <p class="text-justify mb-0">
      3. Selezionare la prestazione, tra quelle offerte dalla farmacia, che si desidera prenotare.</p>
    <b-img fluid src="/assets/help/cittadino/4.png"></b-img>
    <p class="text-justify mb-0">
      4. Impostare la data e l’orario in cui si vuole prenotare la prestazione (tra quelle impostate dalla farmacia
      preferita) e procedere con la conferma.</p>
    <b-img fluid src="/assets/help/cittadino/5.png"></b-img>
    <p class="text-justify mb-0">
      Il tuo farmacista confermerà la prenotazione e ti contatterà per eventuali informazioni aggiuntive.</p>
    <br>
    <h6 id="3" class="
        text-prim-grad-1
        fw-bolder
        text-left
        pb-1
        border-bottom border-line-grey
      ">
      Cosa è il profilo sanitario?
    </h6>
    <p class="text-justify">
      Il profilo sanitario è la scheda del paziente (alimentata dal paziente, dal caregiver o dal farmacista di fiducia)
      in cui è possibile archiviare:
    <ul>
      <li> le rilevazioni dei parametri fisiologici (ad es. pressione, temperatura, peso, saturazione…); </li>
      <li> le patologie rilevanti annotate;</li>
      <li> la terapia da seguire, e le medicine che di norma assume il paziente, ossia l’armadietto farmaceutico.</li>
    </ul>
    Nelle Rilevazioni è possibile archiviare anche esami e i referti.
    <br>
    <b-img fluid src="/assets/help/cittadino/6.png"></b-img>
    Nel Profilo Sanitario sono, quindi, registrate anche le patologie e l’armadietto farmaceutico (le medicine che di
    norma assume il paziente) con l’indicazione delle terapie in atto e quelle passate.
    <br>
    <b-img fluid src="/assets/help/cittadino/7.png"></b-img>
    <br>
    <b-img fluid src="/assets/help/cittadino/8.png"></b-img>
    </p>
    <br>
    <h6 id="4" class="
        text-prim-grad-1
        fw-bolder
        text-left
        pb-1
        border-bottom border-line-grey
      ">
      Dove posso visualizzare le mie terapie?
    </h6>
    <p class="text-justify mb-0">Dopo che la farmacia di riferimento ha aggiunto la terapia alla scheda del paziente o
      il paziente stesso ha apportato aggiornamenti, si può visionare dall’Homepage cliccando su Il mio profilo
      sanitario → Armadietto.
      Si potranno visualizzare le terapie in atto e quelle passate, apportare modifiche o aggiungere terapie.
      <br>
    </p><br>
    <h6 id="5" class="
        text-prim-grad-1
        fw-bolder
        text-left
        pb-1
        border-bottom border-line-grey
      ">
      Posso sospendere una terapia?
    </h6>
    <p class="text-justify mb-0">Certo. Da Homepage → Il mio profilo sanitario → Armadietto cliccare su una terapia e
      successivamente sul pulsante sospendi la terapia.
      <br>
    </p><br>
    <h6 id="6" class="
        text-prim-grad-1
        fw-bolder
        text-left
        pb-1
        border-bottom border-line-grey
      ">
      A cosa serve la sezione “I miei Familiari”?
    </h6>
    <p class="text-justify mb-0">Dottorfarma dà la possibilità a tutti i pazienti di poter far gestire le prenotazioni
      per conto di altri familiari (figli, nipoti) o da caregiver del paziente.
      Infatti si possono inserire: i familiari, gli assistiti e i caregiver.
      <br>
      <b-img fluid src="/assets/help/cittadino/9.png"></b-img>
      <br>
    </p><br>
    <h6 id="7" class="
        text-prim-grad-1
        fw-bolder
        text-left
        pb-1
        border-bottom border-line-grey
      ">
      Chi sono gli assistiti?
    </h6>
    <p class="text-justify mb-0">
      Gli assistiti sono le persone che hanno scelto l’utente registrato come loro caregiver. Le persone indicate,
      quindi, hanno dato il permesso di prenotare farmaci e servizi per conto loro.
    </p>
    <h6 id="8" class="
        text-prim-grad-1
        fw-bolder
        text-left
        pb-1
        border-bottom border-line-grey
      ">
      Chi sono i caregiver?
    </h6>
    <p class="text-justify mb-0">I caregiver sono quelle persone che si occupano dell’utente registrato e quindi possono
      prenotare farmaci e servizi per conto suo.
      <br>
    </p><br>
    <h6 id="9" class="
        text-prim-grad-1
        fw-bolder
        text-left
        pb-1
        border-bottom border-line-grey
      ">
      Posso aggiungere nuovi familiari o caregiver?
    </h6>
    <p class="text-justify mb-0">Si, dall’Homepage cliccare su “I miei familiari” e in basso a destra è presente un
      simbolo “+” per aggiungere il nuovo membro della famiglia o il caregiver che potrà gestire i servizi di
      Dottorfarma per conto dell’utente registrato.
      <br>
      <b-img fluid src="/assets/help/cittadino/10.png"></b-img>
      <br>Un nuovo utente si può aggiungere tramite un “inserimento semplice” o inserendo il codice utente (che si trova
      nella sezione “impostazioni” del proprio account) per ogni utente già registrato in piattaforma.
      <b-img fluid src="/assets/help/cittadino/11.png"></b-img>
      <br>
    </p><br>
    <br>
    <h6 id="10" class="
        text-prim-grad-1
        fw-bolder
        text-left
        pb-1
        border-bottom border-line-grey
      ">
      Cosa è la sezione “il mio Circolo di cura”?
    </h6>
    <p class="text-justify mb-0">In questa sezione è possibile aggiungere e tenere memoria delle farmacie che fanno
      parte del circolo di cura, con le informazioni sulle sedi, i contatti e gli orari di apertura.
      Cliccando sulla singola farmacia è possibile effettuare una prenotazione, aggiungerla come preferita in modo da
      averla sempre in homepage, o rimuoverla dal circolo di cura.
      <br>
      <b-img fluid src="/assets/help/cittadino/12.png"></b-img>
      <br>
    </p>
    <br>
    <h6 id="11" class="
        text-prim-grad-1
        fw-bolder
        text-left
        pb-1
        border-bottom border-line-grey
      ">
      Cosa trovo in “La mia Agenda”?
    </h6>
    <p class="text-justify mb-0">In agenda l’utente può trovare l’elenco delle richieste di prenotazione o di ordini.
      Sono presenti 3 elenchi di visualizzazione differenti: recenti, calendario e storico.
      <br>
    <ul>
      <li><b> Recenti</b>: si visualizzano le richieste ricevute e confermate. Ad ogni voce si può aprire il dettaglio.
      </li>
    </ul>
    <b-img fluid src="/assets/help/cittadino/13.png"></b-img>
    <br>
    <ul>
      <li><b> Calendario</b>: si possono visualizzare le richieste direttamente sul calendario settimanale.
      </li>
    </ul>
    <b-img fluid src="/assets/help/cittadino/14.png"></b-img>
    <br>
    <ul>
      <li><b> Storico</b>: si visualizzano le richieste passate.
      </li>
    </ul>
    </p>
    <br>
    <h6 id="12" class="
        text-prim-grad-1
        fw-bolder
        text-left
        pb-1
        border-bottom border-line-grey
      ">
      Profilo
    </h6>
    <p class="text-justify mb-0">Nella sezione profilo vengono visualizzati i dati anagrafici che l’utente ha inserito
      in fase di registrazione ed ha la possibilità di modificarli con il pulsante posizionato in basso “Modifica Dati”.
      <br>
      <b-img fluid src="/assets/help/cittadino/15.png"></b-img>
      <br>
      Qualora si cambiasse il numero di telefono, che è un campo sottoposto a verifica tramite codice OTP, sarà necessario rieseguire la verifica con il nuovo numero. Nel caso di utente arruolato dalla farmacia senza un indirizzo email, sarà obbligatorio verificare anche il numero di telefono di riserva.
    </p>
    <br>
    <h6 id="13" class="
        text-prim-grad-1
        fw-bolder
        text-left
        pb-1
        border-bottom border-line-grey
      ">
      Dove posso cambiare la password di accesso?
    </h6>
    <p class="text-justify mb-0">Dalle Impostazioni si possono gestire i dati di accesso modificando la propria
      password.
      <br>
      <b-img fluid src="/assets/help/cittadino/16.png"></b-img>
      <br>
    </p>
    <br>
    <h6 id="14" class="
        text-prim-grad-1
        fw-bolder
        text-left
        pb-1
        border-bottom border-line-grey
      ">
      Posso eliminare il mio profilo?
    </h6>
    <p class="text-justify mb-0">Si, dalle Impostazioni è possibile eliminare il proprio profilo, cliccando su “Elimina
      il mio profilo”.
      <br>
    </p>
    <br>
    <h6 id="15" class="
        text-prim-grad-1
        fw-bolder
        text-left
        pb-1
        border-bottom border-line-grey
      ">
      Cosa è il codice utente?
    </h6>
    <p class="text-justify mb-0">Quando un utente si registra alla piattaforma gli viene associato un numero in codice
      che è visibile dalle Impostazioni. Si tratta di un codice momentaneo con una scadenza. Quando si vuole aggiungere
      un nuovo caregiver l’utente da associare dovrà fornire questo codice utente, per poter garantire adeguati livelli
      di sicurezza.
      <br>
      <b-img fluid src="/assets/help/cittadino/17.png"></b-img>
      <br>
    </p>
    <br>
    <h6 id="16" class="
        text-prim-grad-1
        fw-bolder
        text-left
        pb-1
        border-bottom border-line-grey
      ">
      Come gestisco le notifiche?
    </h6>
    <p class="text-justify mb-0">In basso alla pagina Impostazioni si possono gestire le notifiche.
      Le notifiche sono gli avvisi che possono essere ricevuti via email, via push sul computer se attivato dal browser
      e dai canali di messaggistica. Da questa sezione si possono attivare o disattivare la ricezione delle notifiche.
      Anche se disattivate si avrà la possibilità di attivarle in qualsiasi momento.
      <br>
      <b-img fluid src="/assets/help/cittadino/18.png"></b-img>
      <br>
    </p>
    <br>
    <h6 id="17" class="
        text-prim-grad-1
        fw-bolder
        text-left
        pb-1
        border-bottom border-line-grey
      ">
      Da dove posso visualizzare gli accessi alla piattaforma?
    </h6>
    <p class="text-justify mb-0">Dalle Impostazioni è prevista una sezione dedicata agli accessi effettuati tramite Bot
      di WhatsApp e di Telegram.
      Una volta effettuato l’accesso e quindi il login rimarrà attivo il sistema memorizzerà l’account ogni volta che lo
      utilizzerai. Si può effettuare il logout dalle Impostazioni selezionando il canale di messaggistica desiderato e
      cliccando su “Rimuovi questo accesso”.
      <br>
      <b-img fluid src="/assets/help/cittadino/19.png"></b-img>
      <br>
    </p>
  </div>
</template>

<script>
export default {
  name: "GuidaCittadino",
};
</script>

<style>
</style>
