<template>
  <div class="pt-2 pb-4">
    <p class="text-justify fw-bolder text-medium-grey">
      Inserisci i collegamenti ai canali social della tua farmacia
    </p>
    <template v-if="loading">
      <b-spinner class="mt-2" variant="primary" label="Loading..."></b-spinner>
    </template>
    <template v-else>
      <b-row class="text-left fw-bolder pb-1 border-bottom border-line-grey">
        <b-col class="col-md-3 col-lg-2">Social</b-col>
        <b-col class="text-left col-md-8 col-lg-9">Link al profilo</b-col>
      </b-row>
      <b-row v-for="(social, ind) in socials" :key="ind" class="my-3">
        <b-col class="text-left col-md-3 col-lg-2 d-flex align-items-center">
          <b-icon
            class="mr-3 point"
            :icon="social.icon"
            font-scale="1.5"
            variant="primary"
            v-if="social.name !== 'telegram'"
          />
          <img
            v-else
            src="/assets/telegram-logo.svg"
            alt="Telegram Logo"
            width="28px"
            height="28px"
            class="point mr-3"
          />
          <strong class="text-capitalize fw-bolder"> {{ social.name }} </strong>
        </b-col>
        <b-col class="text-justify col-md-6 col-lg-8">
          <b-form-input v-model="social.value" class="shadow_6"></b-form-input>
        </b-col>
        <b-col class="text-center d-flex">
          <b-button
            v-if="social.value"
            size="sm"
            variant="outline-danger fw-bolder"
            class="rounded-pill px-3"
            @click="social.value = null"
          >
            Rimuovi
          </b-button>
        </b-col>
      </b-row>
      <div
        class="mt-5 px-4"
        :class="
          !allFieldsAreValidUrl
            ? 'd-flex justify-content-between align-items-center'
            : 'text-right'
        "
      >
        <div class="d-col" v-if="!allFieldsAreValidUrl">
          <p class="text-danger text-left">
            Tutti i campi compilati devono essere URL validi
          </p>
          <p class="mb-0">
            Un URL valido è ad esempio: https://www.facebook.com/username
          </p>
        </div>
        <b-button
          variant="outline-primary"
          v-if="hasEdited"
          :disabled="!allFieldsAreValidUrl"
          class="point"
          @click="saveEdits()"
          >Salva modifiche</b-button
        >
      </div>
    </template>
  </div>
</template>
<script>
import { companyService } from "@/_services"
import { mapState, mapActions } from "vuex"
export default {
  name: "CanaliSocial",
  data() {
    return {
      loading: false,
      socials: [
        { name: "facebook", icon: "facebook", color: "primary", value: null },
        { name: "instagram", icon: "instagram", color: "danger", value: null },
        { name: "telegram", icon: "telegram", color: "info", value: null }
      ]
    }
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert
    }),
    ...mapState("utente", ["status", "user"]),
    hasEdited() {
      return (
        this.socials.find((s) => s.name === "facebook").value !==
          this.user.company.social_fb ||
        this.socials.find((s) => s.name === "instagram").value !==
          this.user.company.social_ig ||
        this.socials.find((s) => s.name === "telegram").value !==
          this.user.company.social_tg
      )
    },
    allFieldsAreValidUrl() {
      return this.socials.every((s) => {
        if (!s.value) return true
        try {
          new URL(s.value)
          return (
            s.value.includes("http://") ||
            s.value.includes("https://") ||
            s.value.includes("www.")
          )
        } catch (e) {
          return false
        }
      })
    }
  },
  watch: {
    socials: {
      handler: function (newVal) {
        for (let i = 0; i < newVal.length; i++) {
          if (newVal[i].value === "") {
            newVal[i].value = null
          }
        }
      },
      deep: true
    }
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    ...mapActions("utente", ["unMe", "me"]),
    saveEdits() {
      this.loading = true
      var form = {
        facebook: this.socials.find((s) => s.name === "facebook").value,
        instagram: this.socials.find((s) => s.name === "instagram").value,
        telegram: this.socials.find((s) => s.name === "telegram").value
      }
      var self = this
      companyService
        .updateCompanySocials(form)
        .then(function (res) {
          self.successAlert("Modifiche salvate con successo")
          self.me()
        })
        .catch(function (err) {
          window.scrollTo(0, 0)
          self.errorAlert("non è stato possibile aggiornare i canali social")
        })
        .finally(function () {
          self.loading = false
        })
    }
  },
  created() {
    this.socials.find(
      (el) => el.name === "facebook"
    ).value = this.user.company.social_fb
    this.socials.find(
      (el) => el.name === "instagram"
    ).value = this.user.company.social_ig
    this.socials.find(
      (el) => el.name === "telegram"
    ).value = this.user.company.social_tg
  }
}
</script>
