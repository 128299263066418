var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('router-link',{staticClass:"my-1 my-md-3 mx-auto",class:this.$route.fullPath === _vm.homePath + '/prenotazioni' &&
      'router-link-exact-active',attrs:{"to":_vm.homePath + '/prenotazioni'}},[_c('b-icon',{attrs:{"font-scale":"1.5","icon":this.$route.fullPath === _vm.homePath + '/prenotazioni'
          ? 'list-nested'
          : 'list'}}),_c('p',{staticClass:"mb-0 fs-5"},[_vm._v("Tutte")])],1),_c('router-link',{staticClass:"my-1 my-md-3 mx-auto",class:this.$route.path === _vm.homePath + '/prenotazioni?scope=prestazione' &&
      'router-link-exact-active',attrs:{"to":_vm.homePath + '/prenotazioni?scope=prestazione'}},[_c('b-icon',{attrs:{"font-scale":"1.5","icon":"card-list"}}),_c('p',{staticClass:"mb-0 fs-5"},[_vm._v("Prestazioni")])],1),(_vm.user.type == 1)?_c('router-link',{staticClass:"my-1 my-md-3 mx-auto",class:this.$route.path === _vm.homePath + '/prenotazioni?scope=prodotto' &&
      'router-link-exact-active',attrs:{"to":_vm.homePath + '/prenotazioni?scope=prodotto'}},[_c('b-icon',{attrs:{"font-scale":"1.5","icon":"list-check"}}),_c('p',{staticClass:"mb-0 fs-5"},[_vm._v("Ordini")])],1):_vm._e(),(_vm.user.type == 1)?_c('router-link',{staticClass:"my-1 my-md-3 mx-auto",class:this.$route.path === _vm.homePath + '/prenotazioni/calendario' &&
      'router-link-exact-active',attrs:{"to":_vm.homePath + '/prenotazioni/calendario'}},[_c('b-icon',{attrs:{"font-scale":"1.5","icon":this.$route.path === _vm.homePath + '/prenotazioni/calendario'
          ? 'calendar-week-fill'
          : 'calendar-week'}}),_c('p',{staticClass:"mb-0 fs-5"},[_vm._v("Calendario")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }