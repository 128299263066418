<template>
  <div>
    <div class="d-flex justify-content-between">
      <router-link
        class="fw-bolder text-primary no__hover"
        :to="{ name: 'attività e questionari' }"
      >
        <b-icon icon="arrow-left-circle-fill" variant="primary"></b-icon>
        Attività
      </router-link>
    </div>
    <div class="py-3" v-if="template">
      <h6
        class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
      >
        Questionari {{ template.name }} ({{ template.activity.name }})
        somministrati
      </h6>
      <p class="text-justify text-medium-grey fw-bolder">
        Clicca su una "vedi" per visualizzare l'esito del questionario. Per
        scaricare l'elenco completo in formato pdf, clicca su "scarica tutto in
        formato pdf".
      </p>
      <div class="d-flex mb-3">
        <b-button
          variant="primary primary_gradient text-white fw-bolder spacing_1"
          class="m-1"
          @click="getReport"
          :disabled="total == 0"
          >Scarica tutto in formato pdf</b-button
        >
        <b-button
          variant="primary primary_gradient text-white fw-bolder spacing_1"
          class="m-1"
          @click="getReportXsl"
          :disabled="total == 0"
          >Scarica tutto in formato excel</b-button
        >
      </div>
      <div class="d-flex align-items-center">
        <b-form-group size="sm" class="fw-bolder text-left mr-3">
          <b-form-select
            class="align-items-center align-content-center shadow_6 border border-info text-info"
            name="vedi"
            v-model="filterSetted.take"
            :options="takeOptions"
          >
          </b-form-select>
        </b-form-group>
        <span class="text-info fw-bolder mr-1" v-if="total > 0"
          >{{ fromPage }}-{{ toPage }} di
        </span>
        <span class="text-info fw-bolder"> {{ total }}</span>
      </div>
      <div class="mt-2">
        <b-table
          borderless
          hover
          responsive
          table-variant="white text-info"
          :fields="fields"
          :items="surveys"
          :small="$root.isMobile"
        >
          <template #cell(company_denominazione)="row">
            <span class="text-secondary text-left text-capitalize fw-bolder">{{
              row.item.company_denominazione
            }}</span>
          </template>
          <template #cell(created_at)="row">
            <span class="text-secondary text-left text-capitalize fw-bolder">{{
              row.item.created_at | moment("DD/MM/YYYY")
            }}</span>
          </template>
          <template #cell(user_mail)="row">
            <span
              class="text-secondary text-left fw-bolder"
              v-if="row.item.user_cf"
            >
              {{ row.item.user_cf }}
              <br
            /></span>
            <span class="text-secondary text-left fw-bolder">{{
              row.item.user_mail ? row.item.user_mail : "---"
            }}</span>
          </template>
          <template #cell(azioni)="row">
            <b-button
              size="sm"
              variant="info info_gradient fw-bolder rounded-pill px-3"
              @click="showThis(row)"
              >Vedi
            </b-button>
            <b-button
              size="sm"
              variant="primary primary_gradient text-white fw-bolder spacing_1 rounded-pill px-3"
              @click="downloadOne(row)"
              >Scarica</b-button
            >
            <b-button
              v-if="row.item.user_cf"
              size="sm"
              variant="primary primary_gradient text-white fw-bolder spacing_1 rounded-pill px-3"
              @click="downloadPatientVersion(row)"
            >
              <b-icon icon="download"></b-icon>
              pdf per il paziente
            </b-button>
          </template>
        </b-table>
        <p v-if="surveys && surveys.length < 1">Nessun questionario qui</p>
        <b-pagination
          v-model="filterSetted.page"
          pills
          :total-rows="total"
          :per-page="perPage"
          align="center"
          :size="$root.isMobile ? 'sm' : 'lg'"
        ></b-pagination>
        <b-modal
          ref="show-filleditem"
          hide-footer
          title="Questionario"
          size="xl"
          scrollable
        >
          <FilledSurveyShower
            v-if="selectedItem"
            :response="selectedItem.response"
            :score="selectedItem.score"
            :identificativo_deblistering="
              selectedItem.identificativo_deblistering
            "
          />
        </b-modal>
      </div>
    </div>
  </div>
</template>
<script>
import { supportService } from "@/_services";
import { mapState, mapActions } from "vuex";
import FilledSurveyShower from "@/components/attivita/FilledSurveyShower.vue";
export default {
  name: "QuestionariCompilati",
  components: { FilledSurveyShower },
  data() {
    return {
      filterSetted: {
        take: 10,
        page: 1,
      },
      surveys: null,
      total: 0,
      fromPage: 0,
      toPage: 0,
      perPage: 0,
      fields: [
        { key: "company_denominazione", label: "Azienda" },
        { key: "created_at", label: "Data" },
        { key: "user_mail", label: "Utente" },
        // { key: "active", label: "Stato" },
        { key: "azioni", label: "" },
      ],
      takeOptions: [10, 25, 50, 100],
      template: null,
      selectedItem: null,
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
  },
  watch: {
    filterSetted: {
      handler(prev, post) {
        // //console.log("changed prev", prev.status, post);
        // //console.log("changed post", post.status);
        this.fetchData();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    fetchData() {
      var self = this;
      var dat = {
        hash: this.$route.params.quest,
        filters: this.filterSetted,
      };
      supportService
        .getFilledForSurvey(dat)
        .then(function (res) {
          //console.log(res);
          self.surveys = res.data.data.data.data;
          self.filterSetted.page = res.data.data.data.current_page;
          self.total = res.data.data.data.total;
          self.fromPage = res.data.data.data.from;
          self.toPage = res.data.data.data.to;
          self.perPage = res.data.data.data.per_page;
          self.template = res.data.data.survey;
        })
        .catch(function (err) {
          console.log(err);
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare i report");
        });
    },
    showThis(row) {
      this.selectedItem = row.item;
      this.$refs["show-filleditem"].show();
    },
    getReport() {
      var self = this;
      supportService
        .surveyReport(this.$route.params.quest)
        .then(function (response) {
          let blob = new Blob([response.data]);
          // let blob = new Blob([response.data], { type: "text/csv" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download =
            self.template.name + "_" + self.template.activity.name + ".pdf";
          link.click();
        })
        .catch(function (err) {
          console.log(err);
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare i report");
        });
    },
    getReportXsl() {
      var self = this;
      supportService
        .surveyXlsReport(this.$route.params.quest)
        .then(function (response) {
          let blob = new Blob([response.data]);
          // let blob = new Blob([response.data], { type: "text/csv" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download =
            self.template.name + "_" + self.template.activity.name + ".xlsx";
          link.click();
        })
        .catch(function (err) {
          console.log(err);
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare i report");
        });
    },
    downloadOne(row) {
      var self = this;
      var selected = row;
      supportService
        .downloadFilledSurvey(selected.item.hashid)
        .then(function (response) {
          let blob = new Blob([response.data]);
          // let blob = new Blob([response.data], { type: "text/csv" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download =
            self.template.name + "_" + self.template.activity.name + ".pdf";
          link.click();
        })
        .catch(function (err) {
          console.log(err);
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare i report");
        });
    },
    downloadPatientVersion(row) {
      const self = this;
      var selected = row;
      supportService
        .downloadFilledSurveyPatientVersion(selected.item.hashid)
        .then(function (response) {
          let blob = new Blob([response.data]);
          // let blob = new Blob([response.data], { type: "text/csv" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          var fileName =
            "patient_" +
            self.template.name +
            "_" +
            self.template.activity.name +
            ".pdf";
          if (selected.item.user_cf) {
            fileName = selected.item.user_cf + "_patient_version" + ".pdf";
          }
          link.download = fileName;
          link.click();
        })
        .catch(function (err) {
          console.log(err);
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare i report");
        });
    },
  },
  created() {
    this.fetchData();
  },
};
</script>
