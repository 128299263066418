<template>
  <div>
    <div>
      <h6
        class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
      >
        Log
      </h6>
      <b-row class="text-left" cols="2" cols-lg="3" cols-xl="4">
        <b-col>
          <b-form-group
            label="Dal"
            label-for="fromDate"
            class="text-left fs-6 text-secondary mx-auto"
          >
            <b-form-input
              class="shadow_6"
              name="fromDate"
              type="date"
              :max="dateLimit"
              locale="it"
              v-model="filters.fromDate"
              required
              @change="fetchData"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Al"
            label-for="toDate"
            class="text-left fs-6 text-secondary mx-auto"
          >
            <b-form-input
              class="shadow_6"
              name="toDate"
              type="date"
              :max="dateLimit"
              locale="it"
              v-model="filters.toDate"
              required
              @change="fetchData"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Evento"
            label-for="event"
            class="text-left fs-6 text-secondary mx-auto"
          >
            <b-form-select
              :options="eventTypes"
              class="shadow_6"
              name="event"
              v-model="filters.eventType"
              @change="fetchData"
              required
            >
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Soggetto"
            label-for="subject"
            class="text-left fs-6 text-secondary mx-auto"
          >
            <b-form-select
              :options="eventSubjects"
              class="shadow_6"
              name="subject"
              v-model="filters.eventSubject"
              @change="fetchData"
              required
            >
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Id soggetto"
            label-for="triggeredOn"
            class="text-left fs-6 text-secondary mx-auto"
          >
            <b-form-input
              class="shadow_6"
              placeholder="id..."
              name="triggeredOn"
              type="text"
              v-model="filters.triggeredOn"
              @input="fetchData"
              required
            >
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Scatenato da"
            label-for="triggeredBy"
            class="text-left fs-6 text-secondary mx-auto"
          >
            <b-form-input
              class="shadow_6"
              placeholder="id..."
              name="triggeredBy"
              type="text"
              v-model="filters.triggeredBy"
              required
              @input="fetchData"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row align-v="center" class="mt-2">
        <b-col class="text-left">
          <span class="text-info fw-bolder mr-1" v-if="total > 0"
            >{{ fromPage }}-{{ toPage }} di
          </span>
          <span class="text-info fw-bolder"> {{ total }}</span>
        </b-col>
        <b-col class="text-left">
          <b-form-group
            label=""
            label-for="takes"
            class="text-left fs-6 text-secondary mx-auto"
          >
            <b-form-select
              size="sm"
              :options="takeOptions"
              class="shadow_6"
              name="takes"
              v-model="filters.take"
              required
              @change="fetchData"
            >
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-table
        borderless
        hover
        responsive
        table-variant="white text-info text-left row__font"
        :fields="fields"
        :items="logs"
        :current-page="currentPage"
        selectable
        selected-variant="sec-3"
        thead-class=" border-medium-grey text-left"
        tbody-class=""
        ref="logTable"
        tbody-tr-class="text-black border-bottom border-medium-grey"
      >
        mappedEvent
        <template #cell(description)="row">
          <div class="d-flex text-center">
            {{ mappedEvent(row.item.description) }}
          </div>
        </template>
        <template #cell(azioni)="row">
          <div class="d-flex text-center">
            <b-button
              size="sm"
              variant="info info_gradient rounded-pill fw-bolder px-3 fs-6 mx-1"
              @click="row.toggleDetails"
            >
              {{ row.detailsShowing ? "Nascondi" : "Mostra" }} Dettagli
            </b-button>
          </div>
        </template>

        <template #row-details="row">
          <b-row cols="2" v-if="row.item.properties">
            <b-col v-if="row.item.properties.old">
              <h6 class="text-primary">Stato precedente</h6>
              <b-row cols="2" cols-xl="3">
                <template v-for="(val, key) in row.item.properties.old">
                  <b-col v-if="key !== 'password' && key !== 'push_token'">
                    <span> {{ key }}: {{ val }}</span>
                  </b-col>
                </template>
              </b-row>
            </b-col>
            <b-col v-if="row.item.properties.attributes">
              <h6 class="text-primary">Stato attuale</h6>
              <b-row cols="2" cols-xl="3">
                <template v-for="(val, key) in row.item.properties.attributes">
                  <b-col v-if="key !== 'password' && key !== 'push_token'">
                    <span>{{ key }}: {{ val }}</span>
                  </b-col>
                </template>
              </b-row>
            </b-col>
            <b-col v-if="row.item.properties.response">
              <h6 class="text-primary">Response</h6>
              <b-row cols="2" cols-xl="3">
                <template v-for="(val, key) in row.item.properties.response">
                  <b-col v-if="key !== 'password' && key !== 'push_token'">
                    <span>{{ key }}: {{ val }}</span>
                  </b-col>
                </template>
              </b-row>
            </b-col>
          </b-row>
          <p v-if="row.item.description">{{ row.item.description }}</p>
        </template>
      </b-table>
      <div>
        <b-pagination
          v-model="filters.page"
          pills
          :total-rows="total"
          :per-page="filters.take"
          align="center"
        >
        </b-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import * as moment from "moment/moment";
import { adminService } from "@/_services";
export default {
  name: "LogSistema",
  data() {
    var today = moment().format("YYYY-MM-DD");
    console.log(today);
    return {
      dateLimit: today,
      logs: null,
      filters: {
        fromDate: today,
        toDate: today,
        eventType: null,
        eventSubject: null,
        triggeredBy: null,
        triggeredOn: null,
        page: 1,
        take: 10,
      },
      total: 0,
      fromPage: 0,
      toPage: 0,
      currentPage: 1,
      eventTypes: [
        { text: "scegli evento", value: null },
        { text: "creazione", value: "created" },
        { text: "aggiornamento", value: "updated" },
        { text: "cancellazione", value: "deleted" },
        { text: "accesso scheda paziente", value: "accesso anagrafica utente" },
        { text: "chatbot login", value: "User logged in via chabot" },
        { text: "chatbot logout", value: "User Logged Put Via Chabot" },
        {
          text: "check paziente visualizzato",
          value: "check paziente visualizzato",
        },
        {
          text: "check paziente completato",
          value: "check paziente completato",
        },
      ],
      eventSubjects: [
        { text: "scegli soggetto", value: null },
        { text: "utente", value: "utente" },
        { text: "company", value: "company" },
        { text: "prenotazione", value: "prenotazione" },
        { text: "terapia", value: "terapia" },
        { text: "questionario", value: "questionario" },
        { text: "bot", value: "bot" },
        { text: "alert", value: "alert" },
        { text: "attività", value: "attività" },
        { text: "patologia", value: "patologia" },
        { text: "misurazione", value: "misurazione" },
        { text: "frequenza score", value: "frequenza score" },
        { text: "frequenza esame", value: "frequenza esame" },
        { text: "flista pazienti CR", value: "flista pazienti CR" },
        { text: "Piano Terapeutico", value: "Piano Terapeutico" },
        { text: "terapia PT", value: "terapia PT" },
        { text: "consegna farmaci PT", value: "consegna farmaci PT" },
        { text: "evento alert", value: "evento alert" },
        {
          text: "contatto emergenza utente",
          value: "contatto emergenza utente",
        },
        { text: "frequenza esame utente", value: "frequenza esame utente" },
        { text: "frequenza score utente", value: "frequenza score utente" },
        { text: "soglie esame utente", value: "soglie esame utente" },
        { text: "soglie score", value: "soglie score" },
        { text: "score utente", value: "score utente" },
        { text: "score", value: "score" },
        { text: "validazione OTP", value: "validazione OTP" },
        { text: "protocollo utente", value: "protocollo utente" },
      ],
      colors: ["info", "prim-grad-1", "prim-grad-2", "success"],
      takeOptions: [
        { text: "mostra 10 elementi", value: 10 },
        { text: "mostra 25 elementi", value: 25 },
        { text: "mostra 50 elementi", value: 50 },
        { text: "mostra 75 elementi", value: 75 },
        { text: "mostra 100 elementi", value: 100 },
        { text: "mostra 200 elementi", value: 200 },
        { text: "mostra 300 elementi", value: 300 },
      ],
      fields: [
        { label: "evento", key: "description" },
        { label: "soggetto", key: "subject_type" },
        // { label: "ID soggetto", key: "subject_id" },
        // { label: "scatenato da ID", key: "causer_id" },
        { label: "data", key: "created_at" },
        { label: "", key: "azioni" },
      ],
      selectedline: null,
    };
  },
  watch: {
    // filters: {
    //   handler(prev, post) {
    //     console.log("prev", prev.page);
    //     console.log("post", post.page);
    //     if (prev.page !== post.page) {
    //       this.fetchData();
    //     }
    //     // this.fetchData();
    //   },
    //   deep: true,
    // },
    "filters.page": function (post, prev) {
      console.log("prev", prev);
      console.log("post", post);
      if (post !== prev) {
        this.fetchData();
      }
    },
  },
  methods: {
    fetchData() {
      var self = this;
      adminService
        .getActivityLog(this.filters)
        .then(function (resp) {
          self.logs = resp.data.data.data;
          // self.filters.page = resp.data.current_page;
          self.total = resp.data.data.total;
          console.log(resp.data.data);
          self.fromPage = resp.data.data.from;
          self.toPage = resp.data.data.to;
          self.currentPage = resp.data.current_page;
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    mappedEvent(val) {
      var filtered = this.eventTypes.filter((el) => el.value == val);
      return filtered[0] ? filtered[0].text : "generico";
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>
