import config from "../_config/config";
import { authHeader, postHeader } from "../_helpers";
import axios from "axios";

axios.defaults.baseURL = config.apiUrl;
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.post["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.headers.put["Content-Type"] = "application/json";
axios.defaults.headers.put["X-Requested-With"] = "XMLHttpRequest";

export const companyService = {
  logUserCompanyIn,
  loginCompany,
  storeOpenings,
  storeServiceOpenings,
  updateServiceStatus,
  updateAnagrafica,
  storePrestazione,
  storeOrariPrestazione,
  updatePrestazione,
  fetchPrenotazioni,
  getCode,
  sendCode,
  getOperatorsList,
  changeOperatorStatus,
  changeOperatorRole,
  getSubscriptions,
  gotToBuy,
  getAdesioniStatus,
  setPreadesione,
  getOwnHealthServices,
  setPreadesioniServizi,
  getOwnProhibidates,
  storeNewProhibidate,
  updateProhibidate,
  changeOverlapReservationsSetting,
  updateCompanySocials,
  updateCompanySitoWeb,
  getVetrinaRedirectLink,
  setAssistito,
  storeContract,
  getContrattiFarmacia,
  getFederfarmaPaymentLink,
  verifyDottorfarmaSubscription
};

function logUserCompanyIn() {
  const sett = { headers: authHeader() };
  return axios
    .get("companies/login/auth", sett)
    .then((response) => {
      if (response.data.data) {
        // //console.log(response.data.data);
        localStorage.setItem("company", JSON.stringify(response.data.data));
      }
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function loginCompany(token) {
  const sett = { params: { token: token } };
  return axios
    .get("farmacia/auth", sett)
    .then((response) => {
      if (response.data.data) {
        localStorage.setItem("company", JSON.stringify(response.data.data));
      }
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function storeOpenings(form) {
  const sett = { headers: authHeader() };
  // //console.log("sending form", form);
  return axios
    .post("companies/own/openings", JSON.stringify(form), sett)
    .then((response) => {
      if (response.data.data) {
        let old = JSON.parse(localStorage.getItem("company"));
        old.company_openings = response.data.data;
        localStorage.setItem("company", JSON.stringify(old));
      }
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function storeServiceOpenings(data) {
  const sett = { headers: authHeader() };
  // //console.log("sending form", data.form);
  var endpoint = "services/own/openings/" + data.service_hash;
  var serviceName = data.service_name;
  return axios
    .post(endpoint, JSON.stringify(data.form), sett)
    .then((response) => {
      if (response.data.data) {
        let old = JSON.parse(localStorage.getItem("company"));
        old.servizi_attivi_orari[serviceName] = response.data.data;
        localStorage.setItem("company", JSON.stringify(old));
      }
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function updateServiceStatus(data) {
  const sett = { headers: authHeader() };
  var endpoint = "services/own/servicestatus/" + data.service_hash;
  const form = data.form;
  var serviceName = data.service_name;
  const serviceStatus = form.status;
  // //console.log("updating status to", serviceStatus);
  return axios
    .patch(endpoint, form, sett)
    .then((response) => {
      if (response.data.data) {
        let old = JSON.parse(localStorage.getItem("company"));
        old.stato_servizi[serviceName] = serviceStatus;
        localStorage.setItem("company", JSON.stringify(old));
      }
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function updateAnagrafica(form) {
  const sett = { headers: authHeader() };
  return axios
    .put("companies/own/anagrafica", form, sett)
    .then((response) => {
      if (response.data.data) {
        localStorage.setItem("company", JSON.stringify(response.data.data));
      }
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function storePrestazione(form) {
  const sett = { headers: authHeader() };
  return axios
    .post("healthservices", form, sett)
    .then((response) => {
      if (response.data.data) {
        let old = JSON.parse(localStorage.getItem("company"));
        old.prestazioni_sanitarie.push(response.data.data);
        old.prestazioni_attive[response.data.data.name] =
          response.data.data.hashid;
        localStorage.setItem("company", JSON.stringify(old));
      }
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function storeOrariPrestazione(data) {
  const sett = { headers: authHeader() };
  // //console.log("sending form", data.form);
  var endpoint = "healthservices/own/openings/" + data.service_hash;
  var serviceName = data.service_name;
  return axios
    .post(endpoint, JSON.stringify(data.form), sett)
    .then((response) => {
      if (response.data.data) {
        let old = JSON.parse(localStorage.getItem("company"));
        old.servizi_attivi_orari[serviceName] = response.data.data;
        localStorage.setItem("company", JSON.stringify(old));
      }
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}
function updatePrestazione(data) {
  const sett = { headers: authHeader() };
  var endpoint = "healthservices/" + data.service_hash;
  const form = data.form;
  return axios
    .put(endpoint, form, sett)
    .then((response) => {
      if (response.data.data) {
        // let old = JSON.parse(localStorage.getItem('company'));
        // old.stato_servizi[serviceName]=serviceStatus;
        // localStorage.setItem('company',JSON.stringify(old));
      }
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function fetchPrenotazioni(filters) {
  var sett = { headers: authHeader() };
  if (filters) {
    sett = { headers: authHeader(), params: filters };
  }
  // var endpoint = "companies/own/reservations";
  return axios
    .get("companies/own/reservations", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getCode(hash) {
  const sett = { headers: authHeader() };
  var endpoint = "companies/code/" + hash;
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function sendCode(data) {
  const sett = { headers: authHeader() };
  var endpoint = "companies/code/send/" + data.hash;
  return axios
    .post(endpoint, data.form, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getOperatorsList() {
  const sett = { headers: authHeader() };
  return axios
    .get("companies/operators/list", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function changeOperatorStatus(data) {
  const sett = { headers: authHeader() };
  var endpoint = "users/change/status/" + data.hash;
  return axios
    .post(endpoint, data.form, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function changeOperatorRole(data) {
  const sett = { headers: authHeader() };
  var endpoint = "companies/operators/role/update";
  return axios
    .post(endpoint, data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getSubscriptions(params = null) {
  const sett = { headers: authHeader(), params: params };
  return axios
    .get("companies/own/subscriptions", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function gotToBuy(hash) {
  const sett = { headers: authHeader() };
  var endpoint = "companies/own/subscriptions/buy/" + hash;
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getAdesioniStatus() {
  const sett = { headers: authHeader() };
  var endpoint = "fdsservices/own/adesioni";
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function setPreadesione(data) {
  const sett = { headers: authHeader() };
  var endpoint = "fdsservices/own/preadesione";
  return axios
    .post(endpoint, data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getOwnHealthServices(filters) {
  const sett = { headers: authHeader(), params: filters };
  return axios
    .get("companies/own/healthservices", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function setPreadesioniServizi(form) {
  const sett = { headers: authHeader() };
  var endpoint = "fdsservices/own/fdservice/preadesione/" + form.hashid;
  return axios
    .post(endpoint, form.data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getOwnProhibidates() {
  const sett = { headers: authHeader() };
  return axios
    .get("prohibidates", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function storeNewProhibidate(form) {
  const sett = { headers: authHeader() };
  return axios
    .post("prohibidates", form, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function updateProhibidate(form) {
  const sett = { headers: authHeader() };
  const endpoint = "prohibidates/" + form.hashid;
  return axios
    .put(endpoint, form, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function changeOverlapReservationsSetting(form) {
  const sett = { headers: authHeader() };
  const endpoint = "companies/own/option/overlap";
  return axios
    .patch(endpoint, form, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function updateCompanySocials(form) {
  const sett = { headers: authHeader() };
  const endpoint = "companies/own/socials";
  return axios
    .put(endpoint, form, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function updateCompanySitoWeb(form) {
  const sett = { headers: authHeader() };
  const endpoint = "companies/own/sitoweb";
  return axios
    .put(endpoint, form, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getVetrinaRedirectLink() {
  const sett = { headers: authHeader() };
  return axios
    .get("widget/redirectlink", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err.response);
    });
}
function setAssistito(hashid) {
  const sett = { headers: authHeader() };
  const endpoint = "companies/own/assistito/" + hashid;
  return axios
    .patch(endpoint, {}, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function storeContract(form) {
  const sett = { headers: authHeader() };
  const endpoint = "companies/own/contracts";
  return axios
    .post(endpoint, form, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getContrattiFarmacia() {
  const sett = { headers: authHeader() };
  return axios
    .get("companies/own/contracts", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getFederfarmaPaymentLink(hashid){

  const sett = { headers: authHeader() };
  const endpoint ="subscriptions/federfarma/link/"+hashid;
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function verifyDottorfarmaSubscription(){
  const sett = { headers: authHeader() };
  const endpoint ="companies/dottorfarma/subscription/verify";
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}