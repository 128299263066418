<template>
  <div>
    <div class="d-flex justify-content-between">
      <b-button 
            v-if="!user.permessi.includes('gestire aziende propria regione') && !user.permessi.includes('gestire aziende propria asl')" variant="outline-primary shadow_6 rounded-pill border-white" class="ml-0 mr-auto fw-bolder"
        @click="$router.push({ name: 'nuovo questionario' })">
        <b-icon icon="plus"></b-icon> nuovo questionario
      </b-button>

      <b-input-group size="sm" class="mt-2 col-lg-4 col-sm-6 ml-auto mr-0 shadow_3 rounded-pill">
        <b-form-input class="align-items-center align-content-center" v-model="filterSetted.search" type="search"
          placeholder="Cerca questionario..">
        </b-form-input>

        <b-input-group-append>
          <span class="mx-2">
            <b-icon icon="search"></b-icon>
          </span>
        </b-input-group-append>
      </b-input-group>
    </div>
    <div class="d-flex justify-content-between mt-4 mb-2 align-content-center">
      <div>
        <b-form-group size="sm" class="fw-bolder text-left">
          <b-form-select class="
              align-items-center align-content-center
              shadow_6
              border border-info
              text-info
            " name="vedi" v-model="filterSetted.take" :options="takeOptions">
          </b-form-select>
        </b-form-group>
        <span class="text-info fw-bolder mr-1" v-if="total > 0">{{ fromPage }}-{{ toPage }} di
        </span>
        <span class="text-info fw-bolder"> {{ total }}</span>
      </div>
      <b-form-group size="sm" class="fw-bolder text-left">
        <b-form-select name="type" class="
            align-items-center align-content-center
            shadow_6
            border border-info
            text-info
          " v-model="filterSetted.activity" required>
          <b-form-select-option :value="null">tutte le attività</b-form-select-option>
          <b-form-select-option v-for="(activity, ind) in activities" :key="ind" :value="activity.name">{{ activity.name
          }}</b-form-select-option>
        </b-form-select>
      </b-form-group>
      <b-form-group size="sm" class="fw-bolder text-left">
        <b-form-select name="type" class="
            align-items-center align-content-center
            shadow_6
            border border-info
            text-info
          " v-model="filterSetted.position_ref" required>
          <b-form-select-option :value="null">tutti i servizi</b-form-select-option>
          <b-form-select-option :value="1">Facile Prestazioni</b-form-select-option>
          <b-form-select-option :value="2">Connessa</b-form-select-option>
          <b-form-select-option :value="3">Con Te</b-form-select-option>
        </b-form-select>
      </b-form-group>
    </div>

    <div class="mt-3">
      <b-table borderless hover responsive table-variant="white text-info" :fields="fields" :items="surveys" :busy="isBusy"
        :small="$root.isMobile">
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Attendere caricamento...</strong>
              </div>
            </template>
        <template #cell(name)="row">
          <span class="text-secondary text-left text-capitalize fw-bolder">{{
              row.item.name
          }}</span>
        </template>

        <template #cell(description)="row">
          <span class="text-secondary text-justify fw-bolder">{{
              row.item.description
          }}</span>
        </template>

        <template #cell(attivita)="row">
          <span class="text-secondary text-justify fw-bolder">{{
              row.item.activity.name
          }}</span>
        </template>

        <template #cell(active)="row">
          <b-form-checkbox v-model="row.item.enabled" name="check-button" :state="row.item.enabled == 1" :value="1"
            :unchecked-value="0" switch @change="changeStatus(row)">{{ row.active }}
          </b-form-checkbox>
        </template>

        <template #cell(tot_filled)="row">
          <span v-if="!user.permessi.includes('gestire aziende propria asl')" class="text-secondary text-justify fw-bolder">{{
              row.item.tot_filled
          }}</span>
            <span v-else class="text-secondary text-justify fw-bolder">-</span>
        </template>
        <template #cell(azioni)="row">
          <b-button variant="info info_gradient fw-bolder rounded-pill px-3" size="sm" @click="selectThis(row)"
            v-b-tooltip.hover title="Visualizza">
            <b-icon icon="eye-fill"></b-icon>
          </b-button>
          <b-button variant="secondary secondary_gradient fw-bolder rounded-pill px-3" size="sm" @click="
            $router.push({
              name: 'report questionario',
              params: { quest: row.item.hashid },
            })
          " v-b-tooltip.hover title="Report">
            <b-icon icon="collection-fill"></b-icon>
          </b-button>
          <b-button v-if="!row.item.created_by && !user.permessi.includes('gestire aziende propria regione')&& !user.permessi.includes('gestire aziende propria asl')" variant="secondary fw-bolder rounded-pill px-3" size="sm" @click="
            $router.push({
              name: 'aggiorna questionario',
              params: { quest: row.item.hashid },
            })
          " v-b-tooltip.hover title="Modifica">
            <b-icon icon="pencil-square"></b-icon>
          </b-button>
          <!-- <router-link
            class="
              info_gradient
              btn btn-info btn-sm
              px-3
              rounded-pill
              fw-bolder
            "
            :to="{ name: 'questionari', params: { quest: row.item.hashid } }"
          >
            Dettaglio</router-link
          > -->
        </template>
      </b-table>

      <p v-if="surveys && surveys.length == 0">Nessun questionario qui</p>
      <b-pagination v-model="filterSetted.page" pills :total-rows="total" :per-page="10" align="center"
        :size="$root.isMobile ? 'sm' : 'lg'"></b-pagination>
      <b-modal ref="modal-showsurvey" :title="modalTitle" size="xl" hide-footer>
        <SurveyShower v-if="selectedRow && selectedRow.item" :survey="selectedRow.item" class="px-3" />
      </b-modal>
    </div>
  </div>
</template>
<script>
import { supportService, adminService } from "@/_services";
import { mapState, mapActions } from "vuex";
import SurveyShower from "@/components/admin/attivita/SurveyShower.vue";
export default {
  name: "AdminQuestionari",
  components: { SurveyShower },
  data() {
    return {
      filterSetted: {
        search: null,
        position_ref: null,
        page: 1,
        take: 10,
        activity: null,
      },
      surveys: null,
      fields: [
        { key: "name", label: "Nome" },
        { key: "description", label: "Descrizione" },
        { key: "attivita", label: "Attività" },
        { key: "active", label: "Stato" },
        { key: "tot_filled", label: "Somministrati" },
        { key: "azioni", label: "" },
      ],
      takeOptions: [10, 25, 50, 100],
      activities: null,
      total: 0,
      fromPage: 0,
      toPage: 0,
      selectedRow: null,
      modalTitle: null,
      isBusy: true
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
    ...mapState("utente", ["status", "user"]),
  },
  watch: {
    filterSetted: {
      handler(prev, post) {
        // //console.log("changed prev", prev.status, post);
        // //console.log("changed post", post.status);
        this.fetchData();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    fetchData() {
      this.isBusy = true
      var self = this;
      supportService
        .getSurveys(this.filterSetted)
        .then(function (response) {
          self.surveys = response.data.data.data;
          self.filterSetted.page = response.data.data.current_page;
          self.total = response.data.data.total;
          self.fromPage = response.data.data.from;
          self.toPage = response.data.data.to;
          self.isBusy = false
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare le attività");
          console.log(err);
          self.isBusy = false
        });
    },
    fetchAttivita() {
      var self = this;
      supportService
        .getActivities(this.filterSetted)
        .then(function (res) {
          self.activities = res.data.data;
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare le attività");
          console.log(err);
        });
    },
    selectThis(row) {
      this.selectedRow = row;
      this.modalTitle = row.item.name;
      this.$refs["modal-showsurvey"].show();
    },
    changeStatus(row) {
      var formData = {
        hash: row.item.hashid,
        form: {
          enabled: row.item.enabled,
        },
      };
      var self = this;
      adminService
        .updateSurveyStatus(formData)
        .then(function (res) {
          window.scrollTo(0, 0);
          self.successAlert("Stato questionario aggiornato con successo!");
          // self.fetchData();
        })
        .catch(function (err) {
          console.log(err);
          window.scrollTo(0, 0);
          self.errorAlert(
            "Non è stato possibile aggiornare lo stato del questionario"
          );
          self.fetchData();
        });
    },
  },
  created() {
    this.fetchData();
    this.fetchAttivita();
  },
};
</script>