<template>
  <div>
    <b-row class="justify-content-between">
      <b-col></b-col>
      <transition-group class="text-right">
        <b-button key="fg" v-if="!showCheckbox" size="sm" variant="prim-grad-1 fw-bolder a__spacing"
          @click="showCheckbox = true">
          Evadi una o più richieste</b-button>
        <div key="sg" v-else>
          <b-button v-if="selectedReservations.length > 0" variant="primary primary_gradient mx-2 fw-bolder" size="sm"
            @click="$refs['modal-confirm'].show()">
            Evadi selezionate</b-button>
          <b-button variant="outline-danger fw-bolder" size="sm" @click="cancelSelection">Annulla</b-button>
        </div>
      </transition-group>
    </b-row>
    <b-row class="mx-0 my-4">
      <b-col cols="12" class="text-left border-bottom border-medium-grey" v-for="(res, ind) in reservations" :key="ind">
        <b-row>
          <b-col class="col-2 col-md-1">
            <strong class="text-medium-grey">{{ res.time }}</strong>
          </b-col>
          <b-col class="px-1">
            <b-row v-for="(el, ind) in res[day]" :key="ind">
              <b-row :cols="$root.isMobile ? '1' : '4'" v-for="(element, k) in Object.values(el)" :key="k + k" :class="
                element.status > 0
                  ? colors[element.status]
                  : 'rifiutato-annullato disabled__card'
              " class="point my-1 d-flex justify-content-between align-items-center col-12"
                @click="showDetails(element)">
                <!-- <div class=""> -->
                <span class="text-left">
                  <b-icon class="mr-2" font-scale="1.2" :icon="icone[element.icona]"></b-icon>
                  <b-icon icon="circle-fill"></b-icon>
                  <strong class="mx-1">
                    {{ element.data_inizio | moment("HH:mm") }}
                  </strong>
                  <strong class="text-uppercase" v-if="element.etichetta">
                    {{ element.etichetta }}
                  </strong>
                </span>
                <!-- </div> -->
                <template v-if="!element.subjectable">
                  <span class="text-font-grey">**utenza rimossa**</span>
                </template>
                <template v-else>
                  <span :class="$root.isMobile ? 'text-left' : 'text-center'">
                    <strong class="text-capitalize text-font-grey">{{ element.subjectable.anagrafica.name }}
                      {{ element.subjectable.anagrafica.surname }}</strong>
                    <strong class="text-uppercase text-font-grey">
                      {{ element.subjectable.anagrafica.cf }}</strong>
                  </span>
                  <span :class="$root.isMobile ? 'text-left' : 'text-right'">
                    <strong class="text-font-grey" v-if="element.subjectable.anagrafica.tel_number">
                      tel.
                      {{ element.subjectable.anagrafica.tel_number }}</strong>
                  </span>
                </template>
                <span class="text-left text-lg-right point justify-self-end">
                  <span class="d-flex justify-content-end flex-wrap">
                    <strong class="mr-2">
                      #{{ element.codice_richiesta }}
                    </strong>
                    <b-iconstack font-scale="1.5" v-if="!showCheckbox">
                      <b-icon stacked icon="circle-fill" variant="info"></b-icon>
                      <b-icon stacked icon="info" variant="white" scale="1"></b-icon>
                    </b-iconstack>
                    <b-form-checkbox v-else-if="element.status && element.status > 0 && element.status !== 4" size="lg"
                      v-model="selectedReservations" :value="element.hashid" name="check-button">
                    </b-form-checkbox>
                    <b-iconstack font-scale="1.3" v-else>
                      <b-icon stacked icon="circle-fill" variant="transparent"></b-icon>
                      <b-icon stacked icon="transparent" variant="white" scale="1"></b-icon>
                    </b-iconstack>
                  </span>
                </span>
              </b-row>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-modal size="xl" hide-footer centered ref="modal-confirm" title="Conferma operazione">
      <p class="mb-0">Sei sicuro di voler evadere le prenotazioni selezionate?</p>
      <div>
        <b-form-group label="Allega una nota:" v-slot="{ ariaDescribedby }">
          <b-form-radio v-model="message" :aria-describedby="ariaDescribedby" :value="6">nessuna nota
          </b-form-radio>
          <b-form-radio v-model="message" :aria-describedby="ariaDescribedby" :value="5">aggiungi nota
          </b-form-radio>
          <b-form-input v-if="message == 5" class="simple__input border-top-0 border-left-0 border-right-0"
            name="freeText" type="text" placeholder="nota..." autofocus v-model="freeText"></b-form-input>
        </b-form-group>
      </div>
      <div class="d-flex justify-content-end">
        <b-button @click="$refs['modal-confirm'].hide()" type="button" variant="outline-prim-grad-1 fw-bolder ">No,
          chiudi
        </b-button>
        <b-button type="submit" variant="prim-grad-1 fw-bolder ml-2" @click="submitEvadi">
          <b-spinner small v-if="submitting"></b-spinner><span v-else>Sì, procedi</span>
        </b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import * as moment from "moment/moment";
import { reservationService } from "@/_services";
import { mapState, mapActions } from "vuex";
export default {
  name: "CalendarDayDetail",
  props: ["reservations", "day", "colorStatus", "icons"],
  data() {
    return {
      colors: this.colorStatus,
      icone: this.icons,
      showCheckbox: false,
      selectedReservations: [],
      message: 5,
      msgOptions: {
        // 1: "Ci spiace comunicarLe che non possiamo gestire ulteriori richieste per la data selezionata. La preghiamo di effettuare una nuova prenotazione per la settimana successiva alla data scelta.",
        // 2: "Ci spiace comunicarLe che a causa di problematiche relative all'approvvigionamento non possiamo gestire la sua richiesta per la data selezionata.",
        // 3: "Ci spiace comunicarLe che non possiamo gestire la sua richiesta per mancanza di dati essenziali. La preghiamo di completare la sua scheda anagrafica.",
        // 4: "Si è verificato un imprevisto che ci costringe a disdire il suo appuntamento. Se lo desidera, ci chiami per ulteriori informazioni.",
        5: null,
        6: null,
      },
      freeText: null,
      submitting: false,
    };
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    showDetails(element) {
      if (!this.showCheckbox) {
        this.$emit("detail-toshow", element);
      }
    },
    cancelSelection() {
      this.showCheckbox = false;
      this.selectedReservations = [];
    },
    submitEvadi() {
      //console.log("submitting");
      var formData = {
        reservations: this.selectedReservations,
        message: null
      };
      this.submitting = true;
      formData.message = this.freeText;
      // if (this.message !== null && this.message !== 5) {
      //  formData.message = this.msgOptions[this.message];
      // }
      if (this.message === 6) {
        formData.message = null;
      }
      var self = this;
      reservationService
        .massiveEvadiByIds(formData)
        .then(function (response) {
          self.$refs["modal-confirm"].hide();
          window.scrollTo(0, 0);
          self.successAlert(
            "Prenotazioni evase correttamente"
          );
          self.$emit("update-prenotazioni");
          self.submitting = false;
          self.showCheckbox = false;
          self.selectedReservations = false;
          self.message = 5;
          self.freeText = null;
        })
        .catch(function (error) {
          self.$refs["modal-confirm"].hide();
          self.submitting = false;
          window.scrollTo(0, 0);
          self.infoAlert("Nessuna prenotazione da evadere");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
tr {
  border-bottom: 2px solid $line-grey !important;
  // border-bottom: 2px solid $font-light-grey !important;
}

tr:last-of-type {
  border-bottom: 0 solid $line-grey !important;
}

td[role="cell"] {
  max-width: 30px !important;
  background-color: blue !important;
}

.thicker__border {
  border-width: 2px !important;
}

.rifiutato-annullato {
  color: rgba(253, 39, 75, 1) !important;
  border-radius: 3px;
}

.inoltrato-in-attesa {
  color: rgba(255, 151, 18, 1) !important;
}

.confermato {
  color: rgba(32, 80, 114, 1) !important;
}

.evaso {
  color: rgba(15, 148, 180, 1) !important;
}

.nostat {
  color: #ffffff !important;
}
</style>
