<template>
  <b-container>
    <div class="my-3">
      <h6 class="
          text-prim-grad-1
          fw-bolder
          text-left
          pb-1
          border-bottom border-line-grey
        ">
        Terapie in atto
      </h6>
      <b-row align-v="stretch" class="
          my-2
          col-12
          px-0
          align-items-center align-content-center
          flex-wrap
          my-2
        ">
        <b-col class="col-12 col-md-6 col-lg-4 my-2" v-for="(ter, ind) in therapies.active" :key="ind">
          <div class="
              text-left
              py-2
              px-3
              border
              col-12
              border__2
              h-100
              d-flex
              flex-column
            " :class="ter.suspended ? 'border-warning' : 'border-success'">
            <h5 class="
                mb-0
                pt-1
                font-weight-bolder
                text-uppercase
                long__txt
                point
              " @click="selectTherapy(ter)">
              {{ ter.product_name }}
            </h5>
            <h5 v-if="ter.pathology" class="mb-0 pt-1 text-capitalize long__txt">
              {{ ter.pathology.name }}
            </h5>
            <br v-else />
            <p class="
                mb-0
                mt-auto
                pt-1
                font-weight-bolder
                text-font-grey text-capitalize 
              ">
              <span class="mr-auto">{{ ter.tipo }}</span>
            </p>
            <p class="
                mb-0
                mt-auto
                pt-1
                font-weight-bolder
                d-flex
                justify-items-between
                text-font-grey
              ">
              <span v-if="ter.suspended" class="mr-auto text-sec-1">Sospesa</span>
              <span v-else-if="ter.end_at" class="mr-auto text-prim-grad-1">Fino al {{ formatData(ter.end_at) }}</span>
              <span v-else-if="ter.start_at" class="mr-auto text-prim-grad-1">Dal {{ formatData(ter.start_at) }}</span>
              <span v-else class="mr-auto text-white">-</span>
              <span class="ml-auto point">
                <!-- <b-iconstack
                  @click="showDelModal(ter)"
                  v-if="ter.own"
                  font-scale="1.5"
                  class="mr-4"
                  title="elimina"
                >
                  <b-icon stacked icon="circle-fill" variant="danger"></b-icon>
                  <b-icon
                    stacked
                    icon="x"
                    variant="white"
                    font-scale="1.5"
                  ></b-icon>
                </b-iconstack> -->
                <b-icon @click="selectTherapy(ter)" icon="eye-fill" font-scale="1.5" variant="secondary" title="vedi">
                </b-icon>
              </span>
            </p>
          </div>
        </b-col>
        <b-col class="text-left col-md-3">
          <div class="
              align-items-center align-content-center
              pt-4
              pb-3
              px-2
              point
              bg-white
            " @click="goToNew">
            <p>
              <b-iconstack font-scale="3.5" class="shadow_7 rounded-circle">
                <b-icon class="shadow_7" stacked icon="circle-fill" variant="secondary"></b-icon>
                <b-icon stacked icon="plus" scale="0.5" variant="white"></b-icon>
              </b-iconstack>
            </p>
          </div>
        </b-col>
      </b-row>
    </div>
    <div>
      <h6 class="
          text-prim-grad-1
          fw-bolder
          text-left
          pb-1
          border-bottom border-line-grey
        ">
        Storico terapie
      </h6>
      <b-row align-v="stretch" class="
          my-2
          col-12
          px-0
          align-items-center align-content-center
          flex-wrap
          my-2
        ">
        <b-col class="col-12 col-md-6 col-lg-4 my-2" v-for="(ter, ind) in therapies.ended" :key="ind">
          <div class="
              text-left
              py-2
              px-3
              border
              col-12
              border__2
              h-100
              d-flex
              flex-column
            " :class="ter.suspended ? 'border-warning' : 'border-font-grey'">
            <h5 class="
                mb-0
                pt-1
                font-weight-bolder
                text-uppercase
                long__txt
                point
              " @click="selectTherapy(ter)">
              {{ ter.product_name }}
            </h5>
            <h5 v-if="ter.pathology" class="mb-0 pt-1 text-capitalize long__txt">
              {{ ter.pathology.name }}
            </h5>
            <br v-else />
            <p class="
                mb-0
                mt-auto
                pt-1
                font-weight-bolder
                text-font-grey text-capitalize
              ">
              <span class="mr-auto">{{ ter.tipo }}</span>
            </p>
            <p class="
                mb-0
                mt-auto
                pt-1
                font-weight-bolder
                d-flex
                justify-items-between
                text-font-grey
              ">
              <span v-if="ter.suspended" class="mr-auto text-sec-1">Sospesa</span>
              <span v-else-if="ter.end_at" class="mr-auto">Terminata il {{ formatData(ter.end_at) }}</span>
              <span v-else class="mr-auto text-white">-</span>
              <span class="ml-auto point">
                <!-- <b-iconstack
                  @click="showDelModal(ter)"
                  v-if="ter.own"
                  font-scale="1.5"
                  class="mr-4"
                  title="elimina"
                >
                  <b-icon stacked icon="circle-fill" variant="danger"></b-icon>
                  <b-icon
                    stacked
                    icon="x"
                    variant="white"
                    font-scale="1.5"
                  ></b-icon>
                </b-iconstack> -->
                <b-icon @click="selectTherapy(ter)" icon="eye-fill" font-scale="1.5" variant="secondary" title="vedi">
                </b-icon>
              </span>
            </p>
          </div>
        </b-col>
      </b-row>
      <p class="text-left" v-if="therapies.ended.length === 0">
        Nessun elemento nello storico
      </p>
    </div>
    <div class="text-left pb-2">
      <h6 class="
          text-prim-grad-1
          fw-bolder
          text-left
          pb-1
          border-bottom border-line-grey
        ">
        Impostazione alert
      </h6>
      <b-button variant="outline-prim-grad-1 font-weight-bolder rounded-pills" @click="
        $router.push({
          name: 'impostazione alerts',
          params: { hash: $route.params.hash },
          query: { from: 'clients' },
        })
      ">
        Vai alla pianificazione <b-icon icon="reply-fill"></b-icon>
      </b-button>
    </div>
    <b-modal size="xl" id="edit-therapy" hide-footer :title="modalTitle" header-class="ml-3">
      <template #modal-header="{ close }">
        <b-row v-if="selectedTherapy" align-v="center" class="justify-content-between col-12 px-0">
          <b-col>
            <h3 class="pl-3 mb-0 mt-1 text-prim-grad-1 font-weight-bolder" v-if="selectedTherapy.pathology">
              Terapia per {{ selectedTherapy.pathology.name }} -
              {{ selectedTherapy.pathology.tipo }}
            </h3>
            <h3 class="pl-3 mb-0 mt-1 text-prim-grad-1 font-weight-bolder" v-else>
              Terapia per patologia non indicata
            </h3>
            <h6 class="pl-3 mb-0 mt-1 font-weight-bolder">
              <span> </span>
              Inserita il {{ formatData(selectedTherapy.created_at) }}
            </h6>
          </b-col>
          <b-col class="text-right col-1">
            <span class="point">
              <b-icon icon="x" font-scale="1.8" @click="close" variant="prim-grad-1"></b-icon>
            </span>
          </b-col>
        </b-row>
        <div v-else>
          <b-row v-if="selectedTherapy" align-v="center" class="justify-content-between col-12">
            <b-col>
              <h6 class="pl-3 mb-0 mt-1 text-prim-grad-1 font-weight-bolder">
                Terapia
              </h6>
            </b-col>
            <b-col class="text-right col-1">
              <span>
                <b-icon icon="x" font-scale="1.8" @click="close" variant="prim-grad-1"></b-icon>
              </span>
            </b-col>
          </b-row>
        </div>
      </template>
      <div v-if="selectedTherapy">
        <div v-if="editing">
          <EditTherapy :therapy="selectedTherapy" v-on:cancel-operation="$bvModal.hide('edit-therapy')"
            v-on:save-therapy="$bvModal.hide('edit-therapy')" />
        </div>
        <div v-else-if="booking">
          <b-row cols="1" class="my-3">
            <NuovaPrenotazioneProdotto :therapy="selectedTherapy" :subject="subject"
              v-on:reset-operation="booking = false" v-on:confirm-operation="sendPrenotazioneRequest($event)" />
          </b-row>
        </div>
        <div v-else>
          <RecapTherapy :therapy="selectedTherapy" />

          <b-button-toolbar key-nav aria-label="azioni su terapia">
            <b-button-group class="mx-auto general_border py-2 text-center">
              <b-button variant="outline-prim-grad-1 fw-bolder" v-if="selectedTherapy.own" @click="editing = true">
                <b-icon icon="pencil"></b-icon> Modifica
              </b-button>
              <b-button variant="outline-prim-grad-1 fw-bolder"
                v-if="selectedTherapy.suspended && !selectedTherapy.terminata" @click="openSuspModal(false)">
                <b-icon icon="play-btn"></b-icon> Riprendi
              </b-button>
              <b-button variant="outline-prim-grad-1 fw-bolder" v-else-if="!selectedTherapy.terminata"
                @click="openSuspModal(true)">
                <b-icon icon="pause-btn"></b-icon> Sospendi
              </b-button>
              <b-button v-if="selectedTherapy.own" variant="outline-prim-grad-1 fw-bolder"
                @click="showDelModal(selectedTherapy)">
                <b-icon icon="bucket"></b-icon> Elimina
              </b-button>
            </b-button-group>
            <b-button-group class="mx-auto general_border py-2 text-center"
              v-if="!selectedTherapy.suspended && !selectedTherapy.terminata">
              <b-button variant="outline-prim-grad-1 fw-bolder" @click="downloadRecap">
                <b-icon icon="download"></b-icon> Scarica promemoria
              </b-button>
              <b-button variant="outline-prim-grad-1 fw-bolder" @click="booking = true">
                <b-icon icon="bag"></b-icon> Prenota Farmaco
              </b-button>
            </b-button-group>
          </b-button-toolbar>
        </div>
      </div>
    </b-modal>
    <b-modal id="susp-therapy" size="lg" centered title="Conferma " button-size="sm" cancel-title="No, Annulla"
      cancel-variant="secondary px-3 rounded-pill font-weight-bolder" ok-title="Sì, Conferma"
      ok-variant="outline-danger px-3 font-weight-bolder rounded-pill" footer-class="border-0" @ok="doSuspend"
      @cancel="$bvModal.hide('edit-therapy')">
      Confermi di voler {{ isSuspending ? "sospendere" : "riprendere" }} la
      terapia?
    </b-modal>
    <b-modal id="del-therapy" size="lg" centered title="Conferma eliminazione" button-size="sm"
      cancel-title="No, Annulla" cancel-variant="secondary px-3 rounded-pill font-weight-bolder" ok-title="Sì, Conferma"
      ok-variant="outline-danger px-3 font-weight-bolder rounded-pill" footer-class="border-0" @ok="doDelete"
      @cancel="$bvModal.hide('edit-therapy')">
      Confermi di voler eliminare la terapia?
    </b-modal>
  </b-container>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { cittadinoService } from "@/_services";
import * as moment from "moment/moment";
import RecapTherapy from "@/components/profile/sanitario/RecapTherapy.vue";
import EditTherapy from "@/components/profile/sanitario/EditTherapy.vue";
import NuovaPrenotazioneProdotto from "@/components/profile/sanitario/NuovaPrenotazioneProdotto.vue";
export default {
  name: "TerapieComponent",
  props: ["subject"],
  components: { RecapTherapy, EditTherapy, NuovaPrenotazioneProdotto },
  data() {
    return {
      therapies: { active: [], ended: [] },
      typesColors: ["ter-4", "ter-1", "ter-2"],
      selectedTherapy: null,
      editing: false,
      isSuspending: false,
      booking: false,
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
    modalTitle: function () {
      if (this.selectedTherapy && this.selectedTherapy.pathology) {
        return "Terapia per " + this.selectedTherapy.pathology.name;
      }
      return "Terapia";
    },
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    sendPrenotazioneRequest(data) {
      this.$bvModal.hide("edit-therapy");
      if (data.ok) {
        this.successAlert(data.msg);
      } else {
        this.errorAlert(data.msg);
      }
    },
    goToNew() {
      var destination = {
        name: "nuova terapia",
      };
      if (this.subject) {
        destination = {
          name: "nuova terapia Paziente",
          query: {
            sub: this.subject.hashid,
            type: this.subject.type,
          },
        };
      }
      this.$router.push(destination);
    },
    showDelModal(ter) {
      this.selectedTherapy = ter;
      this.$bvModal.show("del-therapy");
    },
    selectTherapy(ter) {
      this.selectedTherapy = ter;
      this.$bvModal.show("edit-therapy");
    },
    fetchTherapies() {
      if (this.subject) {
        return this.fetchUserTherapies();
      }
      this.fetchOwnTherapies();
    },
    fetchOwnTherapies() {
      var self = this;
      cittadinoService
        .getTherapies()
        .then(function (response) {
          self.therapies = response.data.data;
        })
        .catch(function (error) {
          self.errorAlert("Non è stato possibile recuperare le terapie");
        });
    },
    fetchUserTherapies() {
      var self = this;
      var filters = {
        user: this.subject.hashid,
      };
      cittadinoService
        .getTherapies(filters)
        .then(function (response) {
          self.therapies = response.data.data;
        })
        .catch(function (error) {
          self.errorAlert("Non è stato possibile recuperare le terapie");
        });
    },
    openSuspModal(isSuspending) {
      this.$bvModal.show("susp-therapy");
      this.isSuspending = isSuspending;
    },
    doSuspend() {
      var self = this;
      var form = this.selectedTherapy;
      form.suspended = this.isSuspending;
      var term = this.isSuspending ? "sospesa" : "ripresa";
      cittadinoService
        .updateTherapy(form)
        .then(function (response) {
          self.$bvModal.hide("edit-therapy");
          self.successAlert("Terapia " + term);
          self.fetchTherapies();
        })
        .catch(function (error) {
          self.$bvModal.hide("edit-therapy");
          self.errorAlert("Non è stato possibile eseguire l'operazione");
        });
    },
    doDelete() {
      var self = this;
      var form = { hashid: this.selectedTherapy.hashid };
      cittadinoService
        .deleteTherapy(form)
        .then(function (response) {
          self.$bvModal.hide("edit-therapy");
          self.successAlert("Terapia eliminata!");
          self.fetchTherapies();
        })
        .catch(function (error) {
          self.$bvModal.hide("edit-therapy");
          self.errorAlert("Non è stato possibile eliminare la terapia");
        });
    },
    formatData(data) {
      return moment(data).format("DD/MM/YYYY");
    },
    downloadRecap() {
      var self = this;
      cittadinoService
        .therapyRecap(this.selectedTherapy.hashid)
        .then(function (response) {
          self.$bvModal.hide("edit-therapy");
          let blob = new Blob([response.data]);
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "promemoria_terapia.pdf";
          link.click();
        })
        .catch(function (error) {
          self.$bvModal.hide("edit-therapy");
          self.errorAlert("Non è stato possibile scaricare il promemoria");
        });
    },
  },
  created() {
    this.fetchTherapies();
  },
  mounted() {
    this.$root.$on("bv::modal::hide", (bvEvent, modalId) => {
      this.selectedTherapy = null;
      this.editing = false;
    });
  },
};
</script>