<template>
  <div class="pb-4">
    <p class="text-left text-medium-grey fs-5">
      Clicca su un giorno del calendario per visualizzare la lista di
      appuntamenti del singolo giorno.<br />L'opzione "visualizza tutti gli
      appuntamenti", quanto attivata, consente di visualizzare i dettagli di
      tutte le fasce orarie; è comunque possibile aprire ciascun dettaglio
      singolarmente cliccando sulla freccia.
    </p>
    <b-row class="mx-0">
      <b-form-group class="text-left fs-6 text-font-grey mx-auto col pt-2">
        <b-form-checkbox
          id="hide-canceled"
          name="hide-canceled"
          :value="true"
          :unchecked-value="false"
          @change="fetchData"
          v-model="filters.notCanceled"
        >
          Escludi appuntamenti annullati
        </b-form-checkbox>
      </b-form-group>
      <b-form-group
        v-if="!onlyOneDay"
        class="text-left fs-6 text-font-grey mx-auto col pt-2"
      >
        <b-form-checkbox
          id="show-all"
          name="show-all"
          :value="true"
          :unchecked-value="false"
          v-model="allVisible"
        >
          Visualizza tutti gli appuntamenti nelle fasce orarie
        </b-form-checkbox>
      </b-form-group>
      <b-col cols="12" md="6" lg="3">
        <b-input-group
          size="sm"
          class="mt-2 ml-auto mr-0 shadow_6 rounded-pill border border-prim-grad-1"
        >
          <b-input-group-prepend>
            <span class="mx-2">
              <b-icon icon="credit-card"></b-icon>
            </span>
          </b-input-group-prepend>
          <b-form-input
            class="align-items-center align-content-center text-uppercase rounded-pill border-right-0"
            name="cf"
            v-model="cfSearch"
            type="search"
            placeholder="Cerca codice fiscale.."
          >
          </b-form-input>

          <b-input-group-append>
            <span class="mx-2">
              <b-icon icon="search"></b-icon>
            </span>
          </b-input-group-append>
        </b-input-group>
        <b-input-group
          size="sm"
          class="mt-2 ml-auto mr-0 shadow_6 rounded-pill border border-prim-grad-1"
        >
          <b-input-group-prepend>
            <span class="mx-2">
              <b-icon icon="person"></b-icon>
            </span>
          </b-input-group-prepend>
          <b-form-input
            class="align-items-center align-content-center text-uppercase rounded-pill border-right-0"
            name="surname"
            v-model="surnameSearch"
            type="search"
            placeholder="Cerca Cognome.."
          >
          </b-form-input>
          <b-input-group-append>
            <span class="mx-2">
              <b-icon icon="search"></b-icon>
            </span>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>
    <b-row class="mx-0 mb-3" align-v="end">
      <b-form-group class="text-left fs-6 col pt-2 col-12 col-md-2">
        <label for="">
          <span class="text-secondary"></span>
          Filtra per stato:
          <span :class="statusIcon">
            <b-icon font-scale="1.2" icon="circle-fill"></b-icon> </span
        ></label>
        <b-form-select
          class="shadow_6"
          v-model="filters.status"
          @change="fetchData"
        >
          <b-form-select-option :value="undefined">tutti</b-form-select-option>
          <b-form-select-option
            v-for="(stat, ind) in filterOptions.status"
            :key="ind"
            :value="stat"
            >{{ stat }}
          </b-form-select-option>
        </b-form-select>
      </b-form-group>
      <b-form-group
        v-if="servicerif !== 2"
        class="text-left fs-6 text-secondary col pt-2 col-12 col-md-3"
      >
        <label for=""
          >Filtra per servizio:
          <b-icon font-scale="1.2" :icon="servIcon"></b-icon>
        </label>
        <b-form-select
          class="shadow_6"
          v-model="filters.service"
          @change="fetchData"
        >
          <b-form-select-option :value="undefined">tutti</b-form-select-option>
          <b-form-select-option
            v-for="(service, ind) in filterOptions.service"
            :key="ind"
            :value="service.value"
            >{{ service.label }}</b-form-select-option
          >
        </b-form-select>
      </b-form-group>
      <b-form-group
        v-if="filters.service == 'prestazione'"
        class="text-left fs-6 text-secondary col pt-2 col-12 col-md-4"
      >
        <label for=""
          >Filtra per prestazione:
          <b-icon
            font-scale="1.2"
            :icon="selectedPrestazione ? icons[selectedPrestazione.icon] : ''"
          ></b-icon>
        </label>
        <b-form-select
          class="shadow_6"
          v-model="selectedPrestazione"
          @change="searchPrestazione"
        >
          <b-form-select-option :value="null">tutte</b-form-select-option>
          <b-form-select-option
            v-for="(prestazione, ind) in company.prestazioni_sanitarie"
            :key="ind"
            :value="prestazione"
            >{{ prestazione.name }}
            <b-icon :icon="icons[prestazione.icon]"></b-icon>
          </b-form-select-option>
        </b-form-select>
      </b-form-group>
      <b-col class="text-right">
        <b-dropdown
          id="dropdown-downloader"
          text="Estrai questa selezione"
          variant="primary primary_gradient text-white fw-bolder spacing_1"
          class="m-md-2"
        >
          <b-dropdown-item @click="estraiCalendario('pdf')"
            >in formato PDF</b-dropdown-item
          >
          <b-dropdown-item @click="estraiCalendario('xlsx')"
            >in formato xlsx</b-dropdown-item
          >
          <b-dropdown-item @click="estraiCalendario('csv')"
            >in formato csv</b-dropdown-item
          >
        </b-dropdown>
      </b-col>
    </b-row>
    <b-row class="mb-3 mt-n2" v-if="!onlyOneDay">
      <b-col class="col-12 px-0 text-right">
        <span class="mr-3">
          <label for="month_selector" class="mr-1 mb-0">Mese</label>
          <b-form-select
            class="shadow_6"
            style="max-width: 200px;"
            @change="onMonthYearChange"
            v-model="selectedMonth"
          >
            <b-form-select-option
              v-for="month in monthMapping"
              :key="month.id"
              :value="month.id"
              id="month_selector"
              class="text-secondary"
            >
              {{ month.name }}
            </b-form-select-option>
          </b-form-select>
        </span>
        <span>
          <label for="year_selector" class="mr-1 mb-0">Anno</label>
          <b-form-select
            class="shadow_6"
            style="max-width: 160px;"
            v-model="selectedYear"
            id="year_selector"
            @change="onMonthYearChange"
          >
            <b-form-select-option
              v-for="year in years"
              :key="year"
              :value="year"
              class="text-secondary"
            >
              {{ year }}
            </b-form-select-option>
          </b-form-select>
        </span>
      </b-col>
    </b-row>
    <div v-if="!onlyOneDay" class="d-flex justify-content-between">
      <b-button
        variant="outline-info"
        :size="$root.isMobile ? 'sm' : ''"
        type="button"
        @click="setPrev"
      >
        <b-icon icon="chevron-double-left"></b-icon
        >{{ !$root.isMobile ? "Precedente" : "" }}
      </b-button>
      <h5 class="text-capitalize">
        {{ start | moment("D MMM") }} - {{ end | moment("D MMM") }}
      </h5>
      <b-button
        variant="outline-info"
        :size="$root.isMobile ? 'sm' : ''"
        type="button"
        @click="setPost"
        >{{ !$root.isMobile ? "Successiva" : "" }}
        <b-icon icon="chevron-double-right"></b-icon>
      </b-button>
    </div>
    <div
      v-else
      class="d-flex flex-wrap justify-content-between align-items-center"
    >
      <b-col class="col-12 col-md-4 mb-1">
        <span
          class="px-3 py-1 font-weight-bolder point"
          @click="onlyOneDay = false"
        >
          <b-icon icon="arrow-left"></b-icon>Torna alla vista settimanale
        </span>
      </b-col>
      <b-col class="col-12 col-md-5 mb-1">
        <h4 class="text-capitalize text-info font-weight-bolder">
          {{ singleDayLabel }}
        </h4>
      </b-col>
      <b-col class="col-12 col-md-3"></b-col>
    </div>
    <transition-group tag="div" name="slidefade" mode="out-in" appear>
      <b-table
        key="wee-view"
        v-if="!onlyOneDay"
        class="mt-3"
        borderless
        small
        responsive
        table-variant="white text-info"
        thead-tr-class="light__effect"
        :fixed="!$root.isMobile"
        :fields="fields"
        :items="prenotazioni"
        foot-clone
        :busy="tableBusy"
      >
        <template #table-busy>
          <div class="text-center bg-primary text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
        <template #head(day0)="data">
          <p
            :title="'mostra solo lunedì ' + data.label"
            class="point light__effect mb-0"
            @click="showDayDetail(0)"
          >
            {{ data.label }}
          </p>
        </template>
        <template #head(day1)="data">
          <p
            :title="'mostra solo martedì ' + data.label"
            class="point light__effect mb-0"
            @click="showDayDetail(1)"
          >
            {{ data.label }}
          </p>
        </template>
        <template #head(day2)="data">
          <p
            :title="'mostra solo mercoledì ' + data.label"
            class="point light__effect mb-0"
            @click="showDayDetail(2)"
          >
            {{ data.label }}
          </p>
        </template>
        <template #head(day3)="data">
          <p
            :title="'mostra solo giovedì ' + data.label"
            class="point light__effect mb-0"
            @click="showDayDetail(3)"
          >
            {{ data.label }}
          </p>
        </template>
        <template #head(day4)="data">
          <p
            :title="'mostra solo venerdì ' + data.label"
            class="point light__effect mb-0"
            @click="showDayDetail(4)"
          >
            {{ data.label }}
          </p>
        </template>
        <template #head(day5)="data">
          <p
            :title="'mostra solo sabato ' + data.label"
            class="point light__effect mb-0"
            @click="showDayDetail(5)"
          >
            {{ data.label }}
          </p>
        </template>
        <template #head(day6)="data">
          <p
            :title="'mostra solo domenica ' + data.label"
            class="point light__effect mb-0"
            @click="showDayDetail(6)"
          >
            {{ data.label }}
          </p>
        </template>

        <template #cell(day0)="row">
          <div
            class="my-1 py-1 border border-sec-2"
            v-for="(app, key) in row.item.day0"
            :key="'day0' + key"
            :class="
              calcResidueInCard(app) === 0
                ? 'disabled__card'
                : ' thicker__border'
            "
          >
            <p
              class="text-secondary mb-0"
              v-b-toggle="'day0-' + row.item.time + key"
            >
              <span class="mr-2">
                <b-icon font-scale="1.3rem" :icon="icons[key]"></b-icon>
                <span
                  v-if="app[0] && app[0].etichetta"
                  class="text-uppercase text-info"
                >
                  {{ app[0].etichetta }}
                </span>
                <strong class="mr-2" style="font-size: 1.3rem">
                  {{ Object.values(app).length }}
                </strong>
                <!-- <strong class="ml-1 mr-3" style="font-size: 1.3rem">
                    {{ calcResidueInCard(app) }} / {{Object.values(app).length}}
                  </strong> -->
              </span>
              <b-icon
                font-scale="1.3rem"
                icon="arrow-down-circle-fill"
              ></b-icon>
            </p>
            <b-collapse
              :id="'day0-' + row.item.time + key"
              class="mt-3 px-1"
              :visible="allVisible"
            >
              <p
                class="border-0 my-2 mb-0 point py-1"
                v-for="(element, ind) in app"
                :key="ind"
                :class="
                  element.status > 0
                    ? colorStatusPositive[element.status]
                    : 'rifiutato-annullato  disabled__card'
                "
                @click="setCurrentReserv(element)"
              >
                <b-icon icon="circle-fill"></b-icon>
                <strong class="mx-1">
                  {{ element.data_inizio | moment("HH:mm") }}
                </strong>
                <b-iconstack font-scale="1.5">
                  <b-icon stacked icon="circle-fill" variant="info"></b-icon>
                  <b-icon
                    stacked
                    icon="info"
                    variant="white"
                    scale="1"
                  ></b-icon>
                </b-iconstack>
              </p>
            </b-collapse>
          </div>
        </template>

        <template #cell(day1)="row">
          <div
            class="my-1 py-1 border border-sec-2"
            v-for="(app, key) in row.item.day1"
            :key="'day1' + key"
            :class="
              calcResidueInCard(app) === 0
                ? 'disabled__card'
                : 'thicker__border'
            "
          >
            <p
              class="text-secondary mb-0"
              v-b-toggle="'day1-' + row.item.time + key"
            >
              <span class="mr-2">
                <b-icon font-scale="1.3rem" :icon="icons[key]"></b-icon>
                <span
                  v-if="app[0] && app[0].etichetta"
                  class="text-uppercase text-info"
                >
                  {{ app[0].etichetta }}
                </span>
                <strong class="mr-2" style="font-size: 1.3rem">
                  {{ Object.values(app).length }}
                </strong>
                <!-- <strong class="ml-1 mr-3" style="font-size: 1.3rem">
                    {{ calcResidueInCard(app) }} / {{Object.values(app).length}}
                  </strong> -->
              </span>
              <b-icon
                font-scale="1.3rem"
                icon="arrow-down-circle-fill"
              ></b-icon>
            </p>
            <b-collapse
              :id="'day1-' + row.item.time + key"
              class="mt-3 px-1"
              :visible="allVisible"
            >
              <p
                class="border-0 my-2 mb-0 point py-1"
                v-for="(element, ind) in app"
                :key="ind"
                :class="
                  element.status > 0
                    ? colorStatusPositive[element.status]
                    : 'rifiutato-annullato  disabled__card'
                "
                @click="setCurrentReserv(element)"
              >
                <b-icon icon="circle-fill"></b-icon>
                <strong class="mx-1">
                  {{ element.data_inizio | moment("HH:mm") }}
                </strong>
                <b-iconstack font-scale="1.5">
                  <b-icon stacked icon="circle-fill" variant="info"></b-icon>
                  <b-icon
                    stacked
                    icon="info"
                    variant="white"
                    scale="1"
                  ></b-icon>
                </b-iconstack>
              </p>
            </b-collapse>
          </div>
        </template>

        <template #cell(day2)="row">
          <div
            class="my-1 py-1 border border-sec-2"
            v-for="(app, key) in row.item.day2"
            :key="'day2' + key"
            :class="
              calcResidueInCard(app) === 0
                ? 'disabled__card'
                : 'thicker__border'
            "
          >
            <p
              class="text-secondary mb-0"
              v-b-toggle="'day2-' + row.item.time + key"
            >
              <span class="mr-2">
                <b-icon font-scale="1.3rem" :icon="icons[key]"></b-icon>
                <span
                  v-if="app[0] && app[0].etichetta"
                  class="text-uppercase text-uppercase"
                >
                  {{ app[0].etichetta }}
                </span>
                <strong class="mr-2" style="font-size: 1.3rem">
                  {{ Object.values(app).length }}
                </strong>
                <!-- <strong class="ml-1 mr-3" style="font-size: 1.3rem">
                    {{ calcResidueInCard(app) }} / {{Object.values(app).length}}
                  </strong> -->
              </span>
              <b-icon
                font-scale="1.3rem"
                icon="arrow-down-circle-fill"
              ></b-icon>
            </p>
            <b-collapse
              :id="'day2-' + row.item.time + key"
              class="mt-3 px-1"
              :visible="allVisible"
            >
              <p
                class="border-0 my-2 mb-0 point py-1"
                v-for="(element, ind) in app"
                :key="ind"
                :class="
                  element.status > 0
                    ? colorStatusPositive[element.status]
                    : 'rifiutato-annullato  disabled__card'
                "
                @click="setCurrentReserv(element)"
              >
                <b-icon icon="circle-fill"></b-icon>
                <strong class="mx-1">
                  {{ element.data_inizio | moment("HH:mm") }}
                </strong>
                <b-iconstack font-scale="1.5">
                  <b-icon stacked icon="circle-fill" variant="info"></b-icon>
                  <b-icon
                    stacked
                    icon="info"
                    variant="white"
                    scale="1"
                  ></b-icon>
                </b-iconstack>
              </p>
            </b-collapse>
          </div>
        </template>

        <template #cell(day3)="row">
          <template v-for="(app, key) in row.item.day3">
            <div
              class="my-1 py-1 border border-sec-2"
              :key="'day3' + key"
              :class="
                calcResidueInCard(app) === 0
                  ? 'disabled__card'
                  : 'thicker__border'
              "
            >
              <p
                class="text-secondary mb-0"
                v-b-toggle="'day3-' + row.item.time + key"
              >
                <span class="mr-2">
                  <b-icon font-scale="1.3rem" :icon="icons[key]"></b-icon>
                  <span
                    v-if="app[0] && app[0].etichetta"
                    class="text-uppercase text-info"
                  >
                    {{ app[0].etichetta }}
                  </span>
                  <strong class="mr-2" style="font-size: 1.3rem">
                    {{ Object.values(app).length }}
                  </strong>
                  <!-- <strong class="ml-1 mr-3" style="font-size: 1.3rem">
                    {{ calcResidueInCard(app) }} / {{Object.values(app).length}}
                  </strong> -->
                </span>
                <b-icon
                  font-scale="1.3rem"
                  icon="arrow-down-circle-fill"
                ></b-icon>
              </p>
              <b-collapse
                :id="'day3-' + row.item.time + key"
                class="mt-3 px-1"
                :visible="allVisible"
              >
                <p
                  class="border-0 my-2 mb-0 point py-1"
                  v-for="(element, ind) in app"
                  :key="ind"
                  :class="
                    element.status > 0
                      ? colorStatusPositive[element.status]
                      : 'rifiutato-annullato  disabled__card'
                  "
                  @click="setCurrentReserv(element)"
                >
                  <b-icon icon="circle-fill"></b-icon>
                  <strong class="mx-1">
                    {{ element.data_inizio | moment("HH:mm") }}
                  </strong>
                  <b-iconstack font-scale="1.5">
                    <b-icon stacked icon="circle-fill" variant="info"></b-icon>
                    <b-icon
                      stacked
                      icon="info"
                      variant="white"
                      scale="1"
                    ></b-icon>
                  </b-iconstack>
                </p>
              </b-collapse>
            </div>
          </template>
        </template>

        <template #cell(day4)="row">
          <div
            class="my-1 py-1 border border-sec-2"
            v-for="(app, key) in row.item.day4"
            :key="'day4' + key"
            :class="
              calcResidueInCard(app) === 0
                ? 'disabled__card'
                : 'thicker__border'
            "
          >
            <p
              class="text-secondary mb-0"
              v-b-toggle="'day4-' + row.item.time + key"
            >
              <span class="mr-2">
                <b-icon font-scale="1.3rem" :icon="icons[key]"></b-icon>
                <span
                  v-if="app[0] && app[0].etichetta"
                  class="text-uppercase text-info"
                >
                  {{ app[0].etichetta }}
                </span>
                <strong class="mr-2" style="font-size: 1.3rem">
                  {{ Object.values(app).length }}
                </strong>
                <!-- <strong class="ml-1 mr-3" style="font-size: 1.3rem">
                    {{ calcResidueInCard(app) }} / {{Object.values(app).length}}
                  </strong> -->
              </span>
              <b-icon
                font-scale="1.3rem"
                icon="arrow-down-circle-fill"
              ></b-icon>
            </p>
            <b-collapse
              :id="'day4-' + row.item.time + key"
              class="mt-3 px-1"
              :visible="allVisible"
            >
              <p
                class="border-0 my-2 mb-0 point py-1"
                v-for="(element, ind) in app"
                :key="ind"
                :class="
                  element.status > 0
                    ? colorStatusPositive[element.status]
                    : 'rifiutato-annullato  disabled__card'
                "
                @click="setCurrentReserv(element)"
              >
                <b-icon icon="circle-fill"></b-icon>
                <strong class="mx-1">
                  {{ element.data_inizio | moment("HH:mm") }}
                </strong>
                <b-iconstack font-scale="1.5">
                  <b-icon stacked icon="circle-fill" variant="info"></b-icon>
                  <b-icon
                    stacked
                    icon="info"
                    variant="white"
                    scale="1"
                  ></b-icon>
                </b-iconstack>
              </p>
            </b-collapse>
          </div>
        </template>

        <template #cell(day5)="row">
          <div
            class="my-1 py-1 border border-sec-2"
            v-for="(app, key) in row.item.day5"
            :key="'day5' + key"
            :class="
              calcResidueInCard(app) === 0
                ? 'disabled__card'
                : 'thicker__border'
            "
          >
            <p
              class="text-secondary mb-0"
              v-b-toggle="'day5-' + row.item.time + key"
            >
              <span class="mr-2">
                <b-icon font-scale="1.3rem" :icon="icons[key]"></b-icon>
                <span
                  v-if="app[0] && app[0].etichetta"
                  class="text-uppercase text-info"
                >
                  {{ app[0].etichetta }}
                </span>
                <strong class="mr-2" style="font-size: 1.3rem">
                  {{ Object.values(app).length }}
                </strong>
                <!-- <strong class="ml-1 mr-3" style="font-size: 1.3rem">
                    {{ calcResidueInCard(app) }} / {{Object.values(app).length}}
                  </strong> -->
              </span>
              <b-icon
                font-scale="1.3rem"
                icon="arrow-down-circle-fill"
              ></b-icon>
            </p>
            <b-collapse
              :id="'day5-' + row.item.time + key"
              class="mt-3 px-1"
              :visible="allVisible"
            >
              <p
                class="border-0 my-2 mb-0 point py-1"
                v-for="(element, ind) in app"
                :key="ind"
                :class="
                  element.status > 0
                    ? colorStatusPositive[element.status]
                    : 'rifiutato-annullato  disabled__card'
                "
                @click="setCurrentReserv(element)"
              >
                <b-icon icon="circle-fill"></b-icon>
                <strong class="mx-1">
                  {{ element.data_inizio | moment("HH:mm") }}
                </strong>
                <b-iconstack font-scale="1.5">
                  <b-icon stacked icon="circle-fill" variant="info"></b-icon>
                  <b-icon
                    stacked
                    icon="info"
                    variant="white"
                    scale="1"
                  ></b-icon>
                </b-iconstack>
              </p>
            </b-collapse>
          </div>
        </template>

        <template #cell(day6)="row">
          <div
            class="my-1 py-1 border border-sec-2"
            v-for="(app, key) in row.item.day6"
            :key="'day6' + key"
            :class="
              calcResidueInCard(app) === 0
                ? 'disabled__card'
                : 'thicker__border'
            "
          >
            <p
              class="text-secondary mb-0"
              v-b-toggle="'day6-' + row.item.time + key"
            >
              <span class="mr-2">
                <b-icon font-scale="1.3rem" :icon="icons[key]"></b-icon>
                <span
                  v-if="app[0] && app[0].etichetta"
                  class="text-uppercase text-info"
                >
                  {{ app[0].etichetta }}
                </span>
                <strong class="mr-2" style="font-size: 1.3rem">
                  {{ Object.values(app).length }}
                </strong>
                <!-- <strong class="ml-1 mr-3" style="font-size: 1.3rem">
                    {{ calcResidueInCard(app) }} / {{Object.values(app).length}}
                  </strong> -->
              </span>
              <b-icon
                font-scale="1.3rem"
                icon="arrow-down-circle-fill"
              ></b-icon>
            </p>
            <b-collapse
              :id="'day6-' + row.item.time + key"
              class="mt-3 px-1"
              :visible="allVisible"
            >
              <p
                class="border-0 my-2 mb-0 point py-1"
                v-for="(element, ind) in app"
                :key="ind"
                :class="
                  element.status > 0
                    ? colorStatusPositive[element.status]
                    : 'rifiutato-annullato  disabled__card'
                "
                @click="setCurrentReserv(element)"
              >
                <b-icon icon="circle-fill"></b-icon>
                <strong class="mx-1">
                  {{ element.data_inizio | moment("HH:mm") }}
                </strong>
                <b-iconstack font-scale="1.5">
                  <b-icon stacked icon="circle-fill" variant="info"></b-icon>
                  <b-icon
                    stacked
                    icon="info"
                    variant="white"
                    scale="1"
                  ></b-icon>
                </b-iconstack>
              </p>
            </b-collapse>
          </div>
        </template>
      </b-table>

      <CalendarDayDetail
        key="daily-view"
        v-else
        :reservations="prenotazioni"
        :day="selectedDay"
        :colorStatus="colorStatusPositive"
        :icons="icons"
        v-on:detail-toshow="setCurrentReserv($event)"
        v-on:update-prenotazioni="fetchData"
      />

      <p class="mb-0" v-if="prenotazioni !== null && prenotazioni.length == 0">
        Nessuna richiesta qui.
      </p>
    </transition-group>
    <b-modal
      id="modal-resdetails"
      header-class="pb-0"
      hide-footer
      scrollable
      centered
      size="xl"
      @hidden="currentReserv = null"
    >
      <div v-if="currentReserv !== null">
        <b-card
          header-text-variant="prim-grad-1"
          header-bg-variant="white"
          header-tag="h4"
          class="text-left text-font-grey"
          border-variant="white"
        >
          <template #header>
            <b-row cols="2">
              <b-col>
                <span
                  >Dettaglio richiesta #{{
                    currentReserv.codice_richiesta
                  }}</span
                >
                <p style="font-size: 70%" class="mb-0 text-font-grey mt-1">
                  Inoltrata il
                  <strong>{{
                    currentReserv.created_at || moment("DD.MM.YY HH:mm")
                  }}</strong>
                </p>
              </b-col>
              <b-col class="text-right">
                <span class="fs-5">
                  <!-- <strong class="ml-1 text-secondary">Richiesta: </strong> -->
                  <span class="text-prim-grad-1 fw-bolder">{{
                    currentReserv.orderable.prestazione
                      ? currentReserv.orderable.prestazione.name
                      : currentReserv.tipo_ordine === "prodotto"
                      ? "ordine"
                      : currentReserv.tipo_ordine
                  }}</span>
                </span>
                <p style="font-size: 70%" class="mb-0 text-font-grey mt-1">
                  <strong>{{
                    currentReserv.status === 1
                      ? "ricevuto, da confermare"
                      : currentReserv.stato
                  }}</strong>
                  <strong v-if="currentReserv.evasa_at">
                    il
                    {{
                      currentReserv.evasa_at | moment("DD/MM/YYYY HH:mm")
                    }}</strong
                  >
                </p>
              </b-col>
            </b-row>
          </template>
          <b-card-text>
            <b-container fluid class="px-0">
              <b-row class="">
                <b-col class="col-12 col-md-6 px-0 d-flex flex-column">
                  <h5
                    class="text-secondary fw-bolder ml-3"
                    style="height: 24px"
                  >
                    Cliente
                  </h5>

                  <b-col style="flex: 1" class="col-11 py-2">
                    <div
                      v-if="
                        currentReserv.requested_by &&
                        currentReserv.subjectable &&
                        currentReserv.requested_by.anagrafica.cf !==
                          currentReserv.subjectable.anagrafica.cf
                      "
                      class="mb-0 mt-1"
                    >
                      <p class="mb-1">Prenotato da</p>
                    </div>

                    <template v-if="!currentReserv.requested_by">
                      <p>**utenza rimossa**</p>
                    </template>
                    <template v-else>
                      <p class="mb-0 d-flex flex-wrap">
                        <!-- <span class="text-secondary">Richiesto da: </span> -->
                        <span>
                          <b-icon
                            icon="person-fill"
                            variant="secondary"
                            class="mr-2"
                          ></b-icon>

                          <strong class="text-capitalize mr-2"
                            >{{ currentReserv.requested_by.anagrafica.name }}
                            {{ currentReserv.requested_by.anagrafica.surname }}
                          </strong>
                        </span>

                        <span>
                          <b-icon
                            icon="credit-card-fill"
                            variant="secondary"
                            class="mr-2"
                          ></b-icon>
                          <strong class="text-uppercase"
                            >{{ currentReserv.requested_by.anagrafica.cf }}
                          </strong>
                        </span>
                      </p>
                      <p class="mb-0 d-flex flex-wrap">
                        <span>
                          <b-icon
                            variant="secondary"
                            class="mr-2"
                            icon="envelope-fill"
                          ></b-icon>
                          <strong class="mr-2"
                            >{{ currentReserv.requested_by.email }}
                          </strong>
                        </span>
                        <span>
                          <b-icon
                            variant="secondary"
                            class="mr-2"
                            icon="telephone-fill"
                          ></b-icon>

                          <strong
                            v-if="
                              currentReserv.requested_by.anagrafica.tel_number
                            "
                            >{{
                              currentReserv.requested_by.anagrafica.tel_number
                            }}
                          </strong>
                          <strong v-else> non inserito </strong>
                        </span>
                      </p>
                      <p class="mb-0">
                        <b-icon
                          icon="house"
                          variant="secondary"
                          class="mr-2"
                        ></b-icon>
                        <strong
                          v-if="currentReserv.requested_by.anagrafica.address"
                          >{{ currentReserv.requested_by.anagrafica.address }}
                        </strong>
                        <strong v-else>non inserito</strong>,
                        <strong class="text-capitalize"
                          >{{ currentReserv.requested_by.anagrafica.cap }}
                          {{ currentReserv.requested_by.anagrafica.comune }}
                          ({{
                            currentReserv.requested_by.anagrafica.provincia
                          }})
                          {{ currentReserv.requested_by.anagrafica.regione }}
                        </strong>
                      </p>
                    </template>
                    <div
                      v-if="
                        currentReserv.requested_by &&
                        currentReserv.requested_by.anagrafica.cf !==
                          currentReserv.subjectable.anagrafica.cf
                      "
                      class="mb-0 mt-3"
                    >
                      <p class="mb-1">Per conto di</p>
                      <b-icon
                        icon="person"
                        variant="secondary"
                        class="mr-2"
                      ></b-icon>
                      <strong
                        class="text-capitalize"
                        v-if="currentReserv.subjectable.anagrafica"
                        >{{ currentReserv.subjectable.anagrafica.name }}
                        {{ currentReserv.subjectable.anagrafica.surname }}
                      </strong>
                      <strong v-else class="text-capitalize"
                        >{{ currentReserv.subjectable.name }}
                        {{ currentReserv.subjectable.surname }}
                      </strong>
                      <b-icon
                        icon="credit-card"
                        variant="secondary"
                        class="mx-2"
                      ></b-icon>
                      <strong class="text-uppercase"
                        >{{
                          currentReserv.subjectable.anagrafica
                            ? currentReserv.subjectable.anagrafica.cf
                            : currentReserv.subjectable.cf
                        }}
                      </strong>
                    </div>
                  </b-col>
                </b-col>
                <b-col
                  class="col-12 col-md-6 mt-2 mt-md-0 px-0 d-flex flex-column"
                >
                  <h5 class="text-secondary fw-bolder ml-3">Richiesta</h5>

                  <b-col style="flex: 1" class="d-flex flex-column col-12 py-2">
                    <p class="mb-1 fw-bolder">
                      <strong class="text-secondary">Codice Richiesta: </strong
                      >{{ currentReserv.codice_richiesta }}
                    </p>
                    <p class="mb-1 fw-bolder">
                      <strong class="text-secondary">Numero Richiesta: </strong
                      >#{{ currentReserv.numero }}
                    </p>
                    <p class="mb-1 fw-bolder">
                      <strong class="text-secondary">Giorno e ora: </strong>
                      <strong>{{
                        currentReserv.data_inizio | moment("DD.MM.YY HH:mm")
                      }}</strong>
                      <strong v-if="currentReserv.data_fine">
                        -
                        {{ currentReserv.data_fine | moment("HH:mm") }}</strong
                      >
                      <UpdateReservationDate
                        class="d-inline ml-2 ml-md-0 ml-lg-1"
                        :hash="currentReserv.hashid"
                        v-on:change-requested="handleUpdateRequest"
                      />
                    </p>
                  </b-col>
                </b-col>
              </b-row>
            </b-container>

            <DetailTeleconsulto
              :tobecompleted="currentReserv.suspended_survey"
              :dettaglio="currentReserv.orderable"
              :stato="currentReserv.status"
              :quest="currentReserv.filled_survey"
              :datainizio="currentReserv.data_inizio"
              :hash="currentReserv.hashid"
              v-if="currentReserv.tipo_ordine === 'teleconsulto'"
              v-on:update-request-status="
                handleStatusUpdate(currentReserv, $event)
              "
              v-on:update-request-response="
                sendOrderResponse(currentReserv, $event)
              "
            />

            <DetailPrestazione
              :tobecompleted="currentReserv.suspended_survey"
              :detail="currentReserv.orderable"
              :stato="currentReserv.status"
              :quest="currentReserv.filled_survey"
              v-if="currentReserv.tipo_ordine === 'prestazione'"
              v-on:update-request-status="
                handleStatusUpdate(currentReserv, $event)
              "
              v-on:update-request-response="
                sendOrderResponse(currentReserv, $event)
              "
            />
            <DetailProdotto
              :dettaglio="currentReserv.orderable"
              :stato="currentReserv.status"
              :datainizio="currentReserv.data_inizio"
              v-if="currentReserv.tipo_ordine === 'prodotto'"
              v-on:update-request-status="
                handleStatusUpdate(currentReserv, $event)
              "
              v-on:update-request-response="
                sendOrderResponse(currentReserv, $event)
              "
              ref="dett"
            />
            <p class="mb-0 bg-light p-1">
              <strong> Note Operatore </strong>
              <UpdateNotesComponent
                v-on:note-updated="handleNotesUpdate($event)"
                class="d__innline"
                :hashid="currentReserv.hashid"
                :reserdate="currentReserv.data_inizio"
              >
              </UpdateNotesComponent>
              <template v-if="currentReserv.company_notes"
                ><br />
                {{ currentReserv.company_notes }}
              </template>
            </p>
            <p class="mb-0 p-1 bg-light" v-if="currentReserv.user_notes">
              <strong> Note dell'utente:</strong>
              {{ currentReserv.user_notes }}
            </p>
            <div class="d-flex justify-content-end">
              <b-button
                v-if="currentReserv.status !== 4 && currentReserv.status > 0"
                @click="openEvadi(currentReserv)"
                variant="primary primary_gradient text-white rounded-pill fw-bolder px-4"
              >
                Evadi
              </b-button>
            </div>
          </b-card-text>
        </b-card>
      </div>
    </b-modal>

    <b-modal
      size="lg"
      centered
      ref="modal-evasione"
      title="Evadi Richiesta"
      hide-footer
    >
      <div v-if="toEvadere">
        <p class="text-left text-font-grey">
          Numero Richiesta: #{{ toEvadere.numero }} <br />
        </p>
        <p class="text-left text-font-grey">
          Codice Richiesta:
          {{ toEvadere.codice_richiesta }} <br />
        </p>

        <b-form-input
          name="detail"
          class="mx-auto shadow_6"
          v-model="evasione.hash"
          type="text"
          hidden
        >
        </b-form-input>
        <b-form-group
          label="Aggiungi Annotazione"
          label-for="detail"
          class="text-left fs-6 text-secondary"
        >
          <b-form-input
            name="detail"
            class="mx-auto shadow_6"
            v-model="evasione.details"
            type="text"
          >
          </b-form-input>
        </b-form-group>
        <div class="text-center my-2 pt-4">
          <b-button
            type="button"
            class="mx-2"
            variant="outline-danger"
            @click="$refs['modal-evasione'].hide()"
            >Annulla
          </b-button>
          <b-button
            type="button"
            class="mx-2"
            variant="primary primary_gradient text-white fw-bolder spacing_1"
            @click="evadiThis"
            >Conferma</b-button
          >
        </div>
      </div>
    </b-modal>
    <b-row class="mx-0">
      <p v-for="col in colorLegend" :key="col.label" :class="col.color">
        <b-icon icon="circle-fill" font-scale="1.5"></b-icon>
        <span class="text-secondary mx-2">{{ col.label }}</span>
      </p>
    </b-row>
    <b-row class="mx-0">
      <p class="text-secondary" v-if="!servicerif || servicerif == 2">
        <b-icon :icon="icons[1]" font-scale="1.2"></b-icon>
        <span class="text-secondary mx-2">Teleconsulto</span>
      </p>
      <p class="text-secondary" v-if="!servicerif || servicerif == 1">
        <b-icon :icon="icons[0]" font-scale="1.2"></b-icon>
        <span class="text-secondary mx-2">Ordine</span>
      </p>
      <p class="text-secondary" v-if="!servicerif || servicerif == 1">
        <b-icon :icon="icons[2]" font-scale="1.2"></b-icon>
        <span class="text-secondary mx-2">Prestazione sanitaria</span>
      </p>
    </b-row>
    <br />
    <CalendarAddButton></CalendarAddButton>
  </div>
</template>
<script>
import * as moment from "moment/moment"
import { mapActions, mapState } from "vuex"
import { reservationService } from "@/_services"
import DetailPrestazione from "@/components/prenotazioni/DetailPrestazione.vue"
import DetailTeleconsulto from "@/components/prenotazioni/DetailTeleconsulto.vue"
import DetailRitiro from "@/components/prenotazioni/DetailRitiro.vue"
import DetailProdotto from "@/components/prenotazioni/DetailProdotto.vue"
import CalendarDayDetail from "@/components/prenotazioni/CalendarDayDetail.vue"
import UpdateNotesComponent from "@/components/prenotazioni/UpdateNotesComponent.vue"
import UpdateReservationDate from "@/components/prenotazioni/UpdateReservationDate.vue"
import CalendarAddButton from "@/components/prenotazioni/CalendarAddButton.vue"

export default {
  name: "Calendar",
  props: ["servicerif"],
  components: {
    DetailTeleconsulto,
    DetailPrestazione,
    DetailRitiro,
    DetailProdotto,
    CalendarDayDetail,
    UpdateNotesComponent,
    UpdateReservationDate,
    CalendarAddButton
  },
  data() {
    return {
      fields: [],
      prenotazioni: null,
      start: null,
      end: null,
      month: null,
      today: null,
      filters: {
        from: null,
        to: null,
        position_ref: this.servicerif,
        status: undefined,
        service: undefined,
        search: undefined,
        cf: undefined,
        surname: undefined,
        prestazione: undefined,
        notCanceled: false
      },
      filterOptions: {
        status: ["confermate", "in attesa", "evase", "annullate"],
        service: [
          { label: "prestazione", value: "prestazione" },
          { label: "prodotto", value: "prodotto" },
          { label: "teleconsulto", value: "teleconsulto" }
        ],
        search: null
      },
      statusPositive: {
        1: "secondary",
        2: "info",
        3: "info",
        4: "primary",
        5: "ter-4",
        6: "ter-4"
      },
      statusNegative: {
        1: "danger",
        2: "ter-2",
        3: "ter-2",
        4: "dark"
      },
      colorStatusPositive: {
        1: "inoltrato-in-attesa",
        2: "confermato",
        3: "confermato",
        4: "evaso",
        5: "inoltrato-in-attesa",
        6: "inoltrato-in-attesa"
      },
      icons: {
        0: "bag",
        1: "camera-video",
        2: "file-ppt",
        3: "chat-text",
        4: "screwdriver",
        5: "droplet-fill",
        6: "flower1",
        7: "emoji-smile",
        8: "exclude",
        9: "egg-fried",
        10: "gem",
        11: "heart-fill",
        12: "hourglass-split",
        13: "journal-medical",
        14: "life-preserver",
        15: "lock-fill",
        16: "moon",
        17: "node-minus-fill",
        18: "x-diamond-fill",
        19: "cart-fill",
        20: "bug-fill",
        21: "brightness-high-fill",
        22: "bell-fill"
      },
      evasione: {
        details: null,
        hash: null
      },
      toEvadere: null,
      currentReserv: null,
      colorLegend: [
        {
          label: "Confermato",
          color: "confermato",
          val: "confermate"
        },
        {
          label: "Evaso/Concluso",
          color: "evaso",
          val: "evase"
        },
        {
          label: "Ricevuto/In attesa di risposta",
          color: "inoltrato-in-attesa",
          val: "in attesa"
        },
        {
          label: "Errore/Rifiutato/Annullato",
          color: "rifiutato-annullato",
          val: "annullate"
        }
      ],
      allVisible: false,
      selectedPrestazione: null,
      onlyOneDay: false,
      selectedDay: null,
      cfSearch: null,
      surnameSearch: null,
      tableBusy: false,
      monthMapping: [
        { id: 0, name: "Gennaio" },
        { id: 1, name: "Febbraio" },
        { id: 2, name: "Marzo" },
        { id: 3, name: "Aprile" },
        { id: 4, name: "Maggio" },
        { id: 5, name: "Giugno" },
        { id: 6, name: "Luglio" },
        { id: 7, name: "Agosto" },
        { id: 8, name: "Settembre" },
        { id: 9, name: "Ottobre" },
        { id: 10, name: "Novembre" },
        { id: 11, name: "Dicembre" }
      ],
      years: [],
      selectedMonth: new Date().getMonth(),
      selectedYear: new Date().getFullYear()
      // refsToUpdate: []
    }
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert
    }),
    ...mapState("company", ["company"]),
    statusIcon: function () {
      switch (this.filters.status) {
        case "confermate":
          return "confermato"
        case "evase":
          return "evaso"
        case "in attesa":
          return "inoltrato-in-attesa"
        case "annullate":
          return "rifiutato-annullato"
        default:
          return "nostat"
      }
    },
    servIcon: function () {
      switch (this.filters.service) {
        case "prestazione":
          return "file-ppt"
        case "prodotto":
          return "bag"
        case "teleconsulto":
          return "camera-video"
        default:
          return ""
      }
    },
    singleDayLabel: function () {
      let date = moment(this.filters.from, "D/M/YY")
      return moment(date).format("dddd DD MMMM YYYY")
    }
  },
  watch: {
    filters: {
      handler(value) {
        if (value.service !== "prestazione") {
          this.selectedPrestazione = null
          this.filters.prestazione = undefined
        }
        if (value.notCanceled == true) {
          this.filters.status = undefined
        }
        if (value.cf) {
          if (value.cf.length == 0 || value.cf.length == 16) {
            this.fetchData()
          }
        }
        if (value.surname) {
          if (value.surname.length == 0 || value.surname.length >= 3) {
            this.fetchData()
          }
        }
      },
      deep: true
    },
    onlyOneDay: function (val) {
      if (val === false) {
        this.filters.from = moment(this.start).startOf("week").format("D/M/YY")
        this.filters.to = moment(this.end).endOf("week").format("D/M/YY")
        this.setupCalendar()
      }
    },
    cfSearch: function (val) {
      if (val.length === 16) {
        this.filters.cf = val.toUpperCase()
        // this.fetchData()
      }
      if (this.filters.cf && !val) {
        this.filters.cf = undefined
        this.fetchData()
      }
    },
    surnameSearch: function (val) {
      if (val.length >= 3) {
        this.filters.surname = val
        // this.fetchData()
      }
      if (this.filters.surname && !val) {
        this.filters.surname = undefined
        this.fetchData()
      }
    },
    "filters.to": function (newValue, oldValue) {
      if (!oldValue) return
      const newDate = this.parseDateString(newValue)

      this.selectedMonth = newDate.getMonth()

      this.selectedYear = newDate.getFullYear()
    }
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    calcResidueInCard(obj) {
      const notCanceled = Object.values(obj).filter(function (el) {
        return el.status > 0
      })
      const residual = Object.values(notCanceled).length
      // if (ref && residual === 0) {
      //   console.log("ref", ref)
      //   this.refsToUpdate.push(ref);
      // }
      return residual
    },
    setCurrentReserv(obj) {
      this.currentReserv = obj
      this.$bvModal.show("modal-resdetails")
    },
    parseDateString(dateString) {
      const parts = dateString.split("/")
      let year = parseInt(parts[2], 10)

      year = year < 100 ? 2000 + year : year

      return new Date(year, parts[1] - 1, parts[0])
    },
    showDayDetail(day) {
      let date = moment(this.start)
      let formattedDate = date.add(day, "days").format("D/M/YY")
      this.filters.from = formattedDate
      this.filters.to = formattedDate
      this.fetchData()
      this.selectedDay = "day" + day
      this.onlyOneDay = true
    },
    setupCalendar() {
      var fields = []
      fields.push({
        key: "time",
        label: " ",
        tdClass: "text-medium-grey mb-0 font-weight-bolder"
      })
      for (let x = 0; x <= 6; x++) {
        let startDay = moment(this.start)
        let todayDay = moment(this.today).format("DD/M")
        let date = startDay.add(x, "days").format("DD/M")
        let variant = undefined
        if (todayDay === date) {
          variant = "sec-4"
        }
        let newField = {
          key: "day" + parseInt(x),
          label: date,
          variant: variant,
          trClass: "b-table-rows"
        }
        fields.push(newField)
      }
      this.fields = fields
      this.fetchData()
    },
    onMonthYearChange() {
      const firstDayOfMonth = new Date(this.selectedYear, this.selectedMonth, 1)
      this.start = moment(firstDayOfMonth).startOf("week")
      this.end = moment(firstDayOfMonth).endOf("week")
      this.filters.from = moment(this.start).startOf("week").format("D/M/YY")
      this.filters.to = moment(this.end).endOf("week").format("D/M/YY")
      this.setupCalendar()
    },
    fetchData() {
      this.tableBusy = true
      var self = this
      reservationService
        .fetchCalendar(this.filters)
        .then(function (response) {
          self.prenotazioni = response.data.data
          self.tableBusy = false
        })
        .catch(function (error) {
          self.errorAlert("non è stato possibile caricare il calendario")
          self.tableBusy = false
          //console.log("error fetch prenotazioni", error);
        })
    },
    setPrev() {
      this.start.subtract(1, "weeks")
      this.end.subtract(1, "weeks")
      this.filters.from = moment(this.start).startOf("week").format("D/M/YY")
      this.filters.to = moment(this.end).endOf("week").format("D/M/YY")
      this.currentReserv = null
      this.setupCalendar()
    },
    setPost() {
      this.start.add(1, "weeks")
      this.end.add(1, "weeks")
      this.filters.from = moment(this.start).startOf("week").format("D/M/YY")
      this.filters.to = moment(this.end).endOf("week").format("D/M/YY")
      this.currentReserv = null
      this.setupCalendar()
    },
    evadiThis(hash = null) {
      //console.log("richiesta da evadere ", hash);
      hash = this.evasione.hash
      var self = this
      reservationService
        .closeOrder(this.evasione)
        .then(function (response) {
          self.$refs["modal-evasione"].hide()
          self.$bvModal.hide("modal-resdetails")
          self.evasione.details = null
          self.evasione.hash = null
          window.scrollTo(0, 0)
          self.successAlert("prenotazione evasa con successo")
          //console.log("response fetch prenotazioni", response);
          self.fetchData()
        })
        .catch(function (error) {
          self.$refs["modal-evasione"].hide()
          window.scrollTo(0, 0)
          self.errorAlert("non è stato possibile evadere la prenotazione")
          //console.log("error fetch prenotazioni", error);
        })
    },
    openEvadi(item) {
      this.toEvadere = item
      this.evasione.hash = item.hashid
      this.$refs["modal-evasione"].show()
    },
    searchPrestazione() {
      this.filters.prestazione = this.selectedPrestazione
        ? this.selectedPrestazione.name
        : undefined
      this.fetchData()
    },
    handleStatusUpdate(item, data) {
      //console.log("data to submit", data);
      var self = this
      if (!data.confirmed) {
        this.loading = true
        var form = data.message ? { message: data.message } : {}
        reservationService
          .companyCancelOrReject(form, data.action, item.hashid)
          .then(function (re) {
            self.$bvModal.hide("modal-resdetails")
            window.scrollTo(0, 0)
            self.successAlert("Richiesta aggiornata con successo")
            self.fetchData()
          })
          .catch(function (error) {
            self.$bvModal.hide("modal-resdetails")
            window.scrollTo(0, 0)
            self.errorAlert("non è stato possibile effettuare l'operazione")
            self.loading = false
          })
      } else {
        reservationService
          .companyConfirmOrder(item.hashid)
          .then(function (re) {
            window.scrollTo(0, 0)
            self.successAlert("Richiesta aggiornata con successo")
            self.fetchData()
          })
          .catch(function (error) {
            window.scrollTo(0, 0)
            self.errorAlert("non è stato possibile effettuare l'operazione")
            self.loading = false
          })
      }
    },
    sendOrderResponse(item, data) {
      // //console.log(item);
      //console.log("data to update", data);
      var form = {
        hashid: item.hashid,
        company_notes: data.company_notes,
        data_inizio: data.newDateTime,
        order: data.detail,
        status: 5
      }
      // //console.log(form);
      var self = this
      reservationService
        .updateReservation(form)
        .then(function (response) {
          // //console.log(response);
          self.$bvModal.hide("modal-resdetails")
          window.scrollTo(0, 0)
          self.successAlert("risposta inviata con successo")
          self.fetchData()
        })
        .catch(function (error) {
          // console.log(error);
          self.$bvModal.hide("modal-resdetails")
          window.scrollTo(0, 0)
          self.errorAlert("non è stato possibile inviare la risposta")
        })
    },
    handleNotesUpdate(data) {
      this.currentReserv.company_notes = data.company_notes
    },
    estraiCalendario(format) {
      var self = this
      var orderType = "tutte"
      if (this.filters.service != undefined) {
        orderType = this.filters.service
      }
      var selStati = "tutti"
      if (this.filters.status != undefined) {
        selStati = this.filters.status
      }
      var subtype_hash = undefined
      if (this.filters.prestazione) {
        subtype_hash = this.selectedPrestazione.hashid
      }
      var from = moment(this.start)
      var to = moment(this.end)
      var filtered = {
        status: selStati,
        data_from: from.format("YYYY-MM-DD"),
        data_to: to.format("YYYY-MM-DD"),
        order_type: orderType,
        subtype_hash: subtype_hash,
        format: format,
        notCanceled: this.filters.notCanceled,
        cf: this.filters.cf
      }
      console.log("filtered", filtered)
      var dal = moment(filtered.data_from).format("DD/MM/YYYY")
      var al = moment(filtered.data_to).format("DD/MM/YYYY")
      var self = this
      reservationService
        .massiveReport(filtered)
        .then(function (response) {
          let blob = new Blob([response.data])
          // let blob = new Blob([response.data], { type: "text/csv" });
          let link = document.createElement("a")
          link.href = window.URL.createObjectURL(blob)
          link.download = "settimana_dal" + dal + "_al" + al + "." + format
          link.click()
        })
        .catch(function (error) {
          if (error.status === 413) {
            window.scrollTo(0, 0)
            self.infoAlert(
              "Il report verrà inviato via mail ad elaborazione completata"
            )
          } else {
            window.scrollTo(0, 0)
            self.errorAlert("Nessuna prenotazione da scaricare")
          }
        })
    },
    handleSearch() {
      console.log(this.filters.cf)
      var cf = this.filters.cf
      if (cf && cf.length > 4) {
        this.filters.cf = cf.toUpperCase()
        this.fetchData()
      } else {
        this.filters.cf = undefined
        this.fetchData()
      }
    },
    handleUpdateRequest() {
      this.$bvModal.hide("modal-resdetails")
      this.successAlert("richiesta inviata con successo")
      this.fetchData()
    }
  },
  created() {
    this.start = moment().startOf("week")
    this.end = moment().endOf("week")
    this.today = moment()
    this.month = moment().month()
    this.filters.from = moment().startOf("week").format("D/M/YY")
    this.filters.to = moment().endOf("week").format("D/M/YY")
    this.setupCalendar()

    const currentYear = moment(this.start).year()

    for (let i = 2021; i <= currentYear + 3; i++) {
      this.years.push(i)
    }
  },
  mounted() {
    if (this.servicerif) {
      this.allVisible = true
      if (this.servicerif == 1) {
        this.filterOptions.service = [
          { label: "prestazione", value: "prestazione" },
          { label: "prodotto", value: "prodotto" }
        ]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
tr {
  border-bottom: 2px solid $line-grey !important;
  // border-bottom: 2px solid $font-light-grey !important;
}

tr:last-of-type {
  border-bottom: 0 solid $line-grey !important;
}

td[role="cell"] {
  max-width: 30px !important;
  background-color: blue !important;
}

.thicker__border {
  border-width: 2px !important;
}

.rifiutato-annullato {
  color: rgba(253, 39, 75, 1) !important;
  border-radius: 3px;
}

.inoltrato-in-attesa {
  color: rgba(255, 151, 18, 1) !important;
}

.confermato {
  color: rgba(32, 80, 114, 1) !important;
}

.evaso {
  color: rgba(15, 148, 180, 1) !important;
}

.nostat {
  color: #ffffff !important;
}

.slidefade-enter-active {
  transition: all 0.3s ease;
}

.slidefade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}

.slidefade-enter,
.slidefade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>
