<template>
  <div>
    <Calendario :servicerif="serviceRif" />
  </div>
</template>
<script>
import Calendario from "@/components/prenotazioni/Calendario.vue";
export default {
  name: "CalendarioPrenotazioni",
  components: {
    Calendario,
  },
  data() {
    return {
      serviceRif: 1,
    };
  },
  created() {
    //console.log(this.$route.query);
    if (this.$route.query.rif) {
      this.serviceRif = parseInt(this.$route.query.rif);
    }
  },
};
</script>