<template>
  <div class="pt-3">
    <div class="d-flex justify-content-between">
      <router-link class="fw-bolder text-primary no__hover" :to="{ name: 'attivita' }">
        <b-icon icon="arrow-left-circle-fill" variant="primary"></b-icon>
        Attività
      </router-link>
    </div>
    <div v-if="!showOk">
      <div class="col-12 text-center" :class="$root.isMobile ? 'px-0' : ''">
        <b-form-group size="sm" class="fw-bolder text-left mx-auto" :class="$root.isMobile ? 'col-12 px-0' : 'col-10 '"
          label="Seleziona l'attività per cui vuoi creare il nuovo questionario">
          <b-form-select name="type" class="
              align-items-center align-content-center
              shadow_6
              border border-info
              text-info text-capitalize
            " v-model="selectedActivity" required>
            <b-form-select-option :value="null"></b-form-select-option>
            <b-form-select-option v-for="(activity, ind) in activities" :key="ind" :value="ind">{{ activity }}
            </b-form-select-option>
          </b-form-select>
        </b-form-group>
        <p v-if="noActivities" class="text-justify">
          <strong> Non hai ancora creato nessuna attività.</strong> Crea una
          nuova attività a cui associare il questionario che intendi creare.
        </p>
      </div>
      <NuovoQuestionario v-if="selectedActivity" :activityhash="selectedActivity"
        v-on:send-questionario="storeNew($event)" />
    </div>
    <div v-else>
      <h2 class="fw-bolder">Operazione completata.</h2>
    </div>
  </div>
</template>
<script>
import { supportService } from "@/_services";
import { mapState, mapActions } from "vuex";
import NuovoQuestionario from "@/components/attivita/NuovoQuestionario.vue";
export default {
  name: "CreaNuovoQuestionario",
  components: { NuovoQuestionario },
  data() {
    return {
      activities: null,
      selectedActivity: null,
      showOk: false,
      filterSetted: {
        inarray: true,
      },
      noActivities: false,
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    fetchAttivita() {
      var self = this;
      supportService
        .getActivities(this.filterSetted)
        .then(function (res) {
          self.activities = res.data.data;
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          // self.errorAlert("Non è stato possibile recuperare le attività");
          self.noActivities = true;
          console.log(err);
        });
    },
    storeNew(data) {
      var self = this;
      supportService
        .newQuestionario(data)
        .then(function (res) {
          //console.log(res);
          self.successAlert("Questionario creato correttamente!");
          self.showOk = true;
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile creare il nuovo questionario");
          console.log(err);
        });
    },
  },
  created() {
    this.fetchAttivita();
    if (this.$route.query.activity) {
      window.scrollTo(0, 0);
      this.selectedActivity = this.$route.query.activity;
    }
  },
};
</script>