<template>
  <div class="pb-4">
    <div class="d-flex justify-content-between">
      <router-link
        class="fw-bolder text-primary no__hover"
        :to="{ name: 'CittadinoHome' }"
      >
        <b-icon icon="arrow-left-circle-fill" variant="primary"></b-icon>
        Homepage
      </router-link>
    </div>
    <div class="col-12 px-0 px-md-2 text-center my-4">
      <b-nav class="text-prim-grad-1 justify-content-between">
        <b-nav-item
          disabled
          :active="step == 1"
          :class="step > 1 && step < 6 ? 'done' : ''"
          >Presso</b-nav-item
        >
        <b-nav-item
          disabled
          :active="step == 2"
          :class="step > 2 && step < 6 ? 'done' : ''"
          >Per</b-nav-item
        >
        <b-nav-item
          disabled
          :active="step == 3"
          :class="step > 3 && step < 6 ? 'done' : ''"
          >Oggetto</b-nav-item
        >
        <b-nav-item
          disabled
          :active="step == 4"
          :class="step > 4 && step < 6 ? 'done' : ''"
          >Data</b-nav-item
        >
        <b-nav-item
          disabled
          :active="step == 5"
          :class="step > 5 && step < 6 ? 'done' : ''"
          >Conferma</b-nav-item
        >
        <b-nav-item disabled :active="step == 6" :class="step > 6 ? 'done' : ''"
          >Riepilogo
          <b-icon
            v-if="reservationConfirmed && newReservation"
            icon="check2-circle"
          ></b-icon>
        </b-nav-item>
      </b-nav>
    </div>

    <div class="col-12 px-0 px-md-2 text-center">
      <transition-group name="slideside">
        <div v-if="step == 1" key="step1">
          <b-row cols="1" class="my-1">
            <h6
              class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
            >
              Scegli presso chi effettuare la prenotazione
            </h6>
            <b-row align-v="stretch" class="d-flex flex-wrap">
              <div
                v-for="(azienda, ind) in user.my_circle"
                :key="ind"
                class="my-2 col-sm-12 col-md-3"
              >
                <CompanyCard
                  :azienda="azienda"
                  :iselected="enlight(azienda)"
                  v-on:company-selection="selectCompany($event)"
                />
              </div>
              <p
                class="d-flex align-items-center text-right text-primary point align-self-end"
                @click="$refs['modal-preferenze'].show()"
              >
                <b-iconstack font-scale="3">
                  <b-icon stacked icon="circle-fill" variant="primary"></b-icon>
                  <b-icon
                    stacked
                    icon="plus"
                    scale="0.8"
                    variant="white"
                  ></b-icon>
                  <b-icon stacked icon="circle" variant="primary"></b-icon>
                </b-iconstack>
                <span class="ml-2 fw-bolder" v-if="$root.isMobile">Cerca</span>
              </p>
            </b-row>
          </b-row>
        </div>
        <div v-if="step == 2" key="step2">
          <b-row cols="1" class="my-1 py-2">
            <h6
              class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
            >
              Scegli per chi effettuare la prenotazione
            </h6>
            <SubjectSelection
              v-on:selected-subject="setRequestSubject($event)"
            />
            <span id="moveOn"></span>
          </b-row>
        </div>
        <div v-if="step == 3" key="step3">
          <b-row cols="1" class="mt-4 mb-2">
            <h6
              class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
            >
              <span v-if="form.type === 'prestazione'">
                Nuova richiesta per prestazione
              </span>
              <span v-else-if="form.type === 'prodotto'"> Nuovo ordine </span>
              <span v-else> Nuova richiesta di {{ form.type }} </span>
            </h6>
          </b-row>
          <ProductSelection
            v-if="form.type === 'prodotto' && !block"
            :preselected="productOrder"
            v-on:selected-product="setRequestProduct($event)"
            v-on:uploaded-order="setFileOrder($event)"
          />
          <PrestazioneSelection
            v-if="form.type === 'prestazione'"
            :company="selectedCompany"
            v-on:selected-prestazione="setRequestPrestazione($event)"
            :preselected="selectedPrestazione"
          />
          <TeleconsultoSurveySelection
            v-if="form.type === 'teleconsulto'"
            :company="selectedCompany"
            :selectable="!selectTcOptionDisabled"
            v-on:selected-tcsurvey="setRequestTcSurvey($event)"
          />
        </div>
        <div v-if="step == 4" key="step4">
          <b-row
            cols="1"
            v-if="showDisponibilita"
            class="my-2"
            ref="calendarchoice"
          >
            <h6
              class="mt-2 text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
            >
              Scegli data e ora tra le disponibilità
            </h6>
            <b-col class="px-0 px-md-2">
              <div class="d-flex justify-content-between">
                <b-button
                  variant="outline-info"
                  :size="$root.isMobile ? 'sm' : ''"
                  type="button"
                  @click="setPrev"
                  :disabled="checkPrecedente"
                >
                  <b-icon icon="chevron-double-left"></b-icon
                  >{{ !$root.isMobile ? "Precedente" : "" }}
                </b-button>
                <h5 class="text-capitalize">
                  {{ start | moment("D MMM") }} - {{ end | moment("D MMM") }}
                </h5>
                <b-button
                  variant="outline-info"
                  type="button"
                  :size="$root.isMobile ? 'sm' : ''"
                  @click="setPost"
                  >{{ !$root.isMobile ? "Successiva" : "" }}
                  <b-icon icon="chevron-double-right"></b-icon>
                </b-button>
              </div>
              <b-table
                borderless
                responsive
                table-variant="white text-info"
                table-class="table__scrollable"
                :fields="fields"
                :items="disponibilita"
                foot-clone
                small
                show-empty
              >
                <template #cell(1)="row">
                  <p
                    @click="selectDate(1, slot)"
                    class="general_border point border border-info slot__tm"
                    v-for="slot in row.item['1']"
                    :key="slot"
                    :class="
                      checkIfSelected(1, slot)
                        ? 'bg-info text-white'
                        : 'bg-white text-info'
                    "
                  >
                    <strong class="">{{ slot }}</strong>
                  </p>
                </template>
                <template #cell(2)="row">
                  <p
                    @click="selectDate(2, slot)"
                    class="general_border point border border-info slot__tm"
                    v-for="slot in row.item['2']"
                    :key="slot"
                    :class="
                      checkIfSelected(2, slot)
                        ? 'bg-info text-white'
                        : 'bg-white text-info'
                    "
                  >
                    <strong class="">{{ slot }}</strong>
                  </p>
                </template>
                <template #cell(3)="row">
                  <p
                    @click="selectDate(3, slot)"
                    class="general_border point border border-info slot__tm"
                    v-for="slot in row.item['3']"
                    :key="slot"
                    :class="
                      checkIfSelected(3, slot)
                        ? 'bg-info text-white'
                        : 'bg-white text-info'
                    "
                  >
                    <strong class="">{{ slot }}</strong>
                  </p>
                </template>
                <template #cell(4)="row">
                  <p
                    @click="selectDate(4, slot)"
                    class="general_border point border border-info slot__tm"
                    v-for="slot in row.item['4']"
                    :key="slot"
                    :class="
                      checkIfSelected(4, slot)
                        ? 'bg-info text-white'
                        : 'bg-white text-info'
                    "
                  >
                    <strong class="">{{ slot }}</strong>
                  </p>
                </template>
                <template #cell(5)="row">
                  <p
                    @click="selectDate(5, slot)"
                    class="general_border point border border-info slot__tm"
                    v-for="slot in row.item['5']"
                    :key="slot"
                    :class="
                      checkIfSelected(5, slot)
                        ? 'bg-info text-white'
                        : 'bg-white text-info'
                    "
                  >
                    <strong class="">{{ slot }}</strong>
                  </p>
                </template>
                <template #cell(6)="row">
                  <p
                    @click="selectDate(6, slot)"
                    class="general_border point border border-info slot__tm"
                    v-for="slot in row.item['6']"
                    :key="slot"
                    :class="
                      checkIfSelected(6, slot)
                        ? 'bg-info text-white'
                        : 'bg-white text-info'
                    "
                  >
                    <strong class="">{{ slot }}</strong>
                  </p>
                </template>
                <template #cell(7)="row">
                  <p
                    @click="selectDate(7, slot)"
                    class="general_border point border border-info slot__tm"
                    v-for="slot in row.item['7']"
                    :key="slot"
                    :class="
                      checkIfSelected(7, slot)
                        ? 'bg-info text-white'
                        : 'bg-white text-info'
                    "
                  >
                    <strong class="">{{ slot }}</strong>
                  </p>
                </template>
                <template #empty="scope">
                  <p class="fw-bolder text-secondary" v-if="scope">
                    Nessuna disponibilità per questa settimana. Prova a
                    verificare le disponibilità per le prossime settimane.
                  </p>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </div>
        <div v-if="step == 5" key="step5">
          <b-row
            cols="1"
            v-if="showSurvey && surveyToFill"
            class="my-2"
            ref="surveystep"
          >
            <h6
              class="mt-2 text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
            >
              Compila il questionario
            </h6>
            <b-col>
              <FillSurvey
                :survey="surveyToFill.template"
                v-on:end-quest="handleFilledSurvey($event)"
              />
            </b-col>
          </b-row>
          <b-row cols="1" v-else-if="form.data_inizio" class="my-2">
            <h6
              class="mt-2 text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
            >
              Conferma
            </h6>
            <b-col class="px-0 px-md-2">
              <div class="mt-2">
                <p class="text-left text-capitalize">
                  Tipo ordine: {{ form.type }}
                  <strong v-if="selectedPrestazione">
                    {{ selectedPrestazione.name }}</strong
                  >
                  <b-badge
                    variant="info"
                    class="px-2 py-2 text-lowercase"
                    v-else-if="fileOrder"
                  >
                    {{ fileOrder.name }}</b-badge
                  >
                </p>
                <template v-if="productOrder.products">
                  <p
                    class="text-left text-primary fw-bolder"
                    v-for="(prod, ind) in productOrder.products"
                    :key="ind"
                  >
                    <b>N°{{ prod.quantity }}</b>
                    <span>
                      {{ prod.quantity > 1 ? "confezioni" : "confezione" }}
                    </span>
                    <span> {{ prod.Nome }} </span>
                    <b v-if="prod.generic"> - generico</b>
                  </p>
                </template>
                <p v-if="productOrder.nre" class="text-left">
                  <strong>NRE:</strong> {{ productOrder.nre }}
                </p>
                <p v-if="productOrder.rev" class="text-left">
                  <strong>REV:</strong> {{ productOrder.rev }} <br />
                  <strong class="text-uppercase"
                    >Ricorda di portare con te il pin della ricetta quando vai a
                    ritirare l'ordine.</strong
                  >
                </p>
                <p class="text-left text-capitalize">
                  Presso:
                  <strong> {{ selectedCompany.ff_denominazione }}</strong>
                  <span
                    >, {{ selectedCompany.indirizzo }}
                    {{ selectedCompany.frazione }}
                    {{ selectedCompany.comune }}</span
                  >
                </p>
                <p class="text-left">
                  Data ed ora selezionate:
                  <strong>
                    {{ form.data_inizio | moment("D MMM YYYY HH:mm") }}</strong
                  >
                </p>
                <b-form-group
                  label="Se vuoi, aggiungi una nota per l'operatore"
                  label-for="subtype"
                  class="text-left fs-6 text-secondary"
                >
                  <b-form-input
                    name="subtype"
                    class="mx-auto shadow_6"
                    v-model="form.user_notes"
                    required
                  >
                  </b-form-input>
                </b-form-group>
              </div>
            </b-col>
            <b-col class="my-3 px-0 px-md-2">
              <p class="text-left fw-bolder">
                Per procedere alla conferma dell'ordine è necessario
                <span
                  target="_blank"
                  class="point"
                  @click="$bvModal.show(informativa[form.type].url)"
                >
                  <u> leggere ed accettare l'informativa</u></span
                >.
              </p>
              <b-form-checkbox
                class="text-font-light-grey"
                name="data_no_consense"
                :value="true"
                :unchecked-value="false"
                v-model="informRead"
                :state="informRead == true"
              >
                Ho letto e compreso
                <span
                  target="_blank"
                  class="text-underlined"
                  @click="$bvModal.show(informativa[form.type].url)"
                >
                  <u>l'informativa privacy</u></span
                >
              </b-form-checkbox>
              <b-form-checkbox
                class="text-font-light-grey"
                name="data_consense"
                :value="true"
                :unchecked-value="false"
                v-model="informAccepted"
                :state="informAccepted == true"
              >
                Acconsento al trattamento dei miei dati personali per le
                finalità indicate nell’informativa
              </b-form-checkbox>
            </b-col>
            <b-col ref="orderConfirm" class="text-center px-0 px-md-2">
              <b-button variant="outline-danger" class="m-2" @click="resetAll"
                >Annulla</b-button
              >
              <b-button
                variant="primary text-white primary_gradient fw-bolder"
                class="m-2"
                @click="submitRequest"
                :disabled="!informAccepted || !informRead"
              >
                <span v-if="!sending">Procedi</span>
                <b-spinner v-else small></b-spinner>
              </b-button>
            </b-col>
          </b-row>
        </div>
        <div v-if="step == 6" key="step6">
          <div v-if="reservationConfirmed && newReservation">
            <h2>Invio Nuovo Ordine Confermato</h2>
            <div>
              <h6
                class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
              >
                Riepilogo
              </h6>
              <div>
                <div class="mt-2">
                  <p class="text-left text-capitalize">
                    Tipo ordine: {{ form.type }}
                    <strong v-if="selectedPrestazione">
                      {{ selectedPrestazione.name }}</strong
                    >
                    <b-badge
                      variant="info"
                      class="px-2 py-2 text-lowercase"
                      v-else-if="fileOrder"
                    >
                      {{ fileOrder.name }}</b-badge
                    >
                  </p>
                  <template v-if="productOrder.products">
                    <p
                      class="text-left text-primary fw-bolder"
                      v-for="(prod, ind) in productOrder.products"
                      :key="ind"
                    >
                      <b>N°{{ prod.quantity }}</b>
                      <span>
                        {{ prod.quantity > 1 ? "confezioni" : "confezione" }}
                      </span>
                      <span> {{ prod.Nome }} </span>
                      <b v-if="prod.generic"> - generico</b>
                    </p>
                  </template>
                  <p v-if="productOrder.nre" class="text-left">
                    <strong>NRE:</strong> {{ productOrder.nre }}
                  </p>
                  <p v-if="productOrder.rev" class="text-left">
                    <strong>REV:</strong> {{ productOrder.rev }} <br />
                    <strong class="text-uppercase"
                      >Ricorda di portare con te il pin della ricetta quando vai
                      a ritirare l'ordine.</strong
                    >
                  </p>
                  <p class="text-left text-capitalize">
                    Presso:
                    <strong> {{ selectedCompany.ff_denominazione }}</strong>
                    <span
                      >, {{ selectedCompany.indirizzo }}
                      {{ selectedCompany.frazione }}
                      {{ selectedCompany.comune }}</span
                    >
                  </p>
                  <p class="text-left">
                    Data selezionata:
                    <strong>
                      {{ form.data_inizio | moment("DD/MM/YY HH:mm") }}</strong
                    >
                  </p>
                </div>
              </div>
              <div
                class="pb-3 text-left"
                v-if="
                  newReservation.resources &&
                  newReservation.resources.length > 0
                "
              >
                <h6
                  class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
                >
                  Documenti Scaricabili
                </h6>
                <p class="text-left">
                  Sono presenti documenti correlati a questo ordine, puoi
                  visualizzarli cliccando su ciascuno di essi. I documenti
                  contenenti campi da compilare vanno scaricati, stampati,
                  compilati e presentati in sede di appuntamento.
                </p>
                <a
                  v-for="(allegato, ind) in newReservation.resources"
                  :key="ind"
                  :href="allegato.full_path"
                  target="__blank"
                  class="mr-1"
                >
                  <b-badge
                    variant="primary"
                    class="my-2 py-2 px-3 py-md-1 px-md-2"
                    v-b-tooltip.hover
                    :title="'apri ' + allegato.name + ' in un\'altra scheda'"
                    >{{ allegato.name }}</b-badge
                  >
                </a>
              </div>
              <div class="col-12 text-center">
                <router-link :to="{ name: 'agenda' }">
                  <b-button
                    variant="info info_gradient text-white fw-bolder spacing_1 m-2"
                  >
                    Vai all'agenda
                  </b-button>
                </router-link>
                <b-button
                  variant="primary primary_gradient fw-bolder m-2"
                  @click="downloadCalendar"
                  >Scarica calendar
                </b-button>
                <b-button
                  variant="primary primary_gradient fw-bolder m-2"
                  @click="downloadPdf"
                  >Scarica promemoria
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </transition-group>

      <div>
        <StepperButtons
          :first="step == 1 || step == 6"
          :islast="step > 4"
          :waiting="waitForInput"
          v-on:counter-update="step = step + $event"
        />
      </div>
    </div>

    <b-modal
      size="xl"
      centered
      ref="modal-preferenze"
      title="Cerca Farmacia"
      hide-footer
    >
      <SearchCompany
        v-on:new-selection="updatePreferenze($event)"
        class="px-3 py-3"
      />
    </b-modal>
    <b-modal size="xl" centered id="info_tc" title="Informativa" hide-footer>
      <TeleconsultoPerFamiliare
        :company="selectedCompany"
      ></TeleconsultoPerFamiliare>
    </b-modal>
    <b-modal size="xl" centered id="info_prest" title="Informativa" hide-footer>
      <PrestazionePerFamiliare
        :company="selectedCompany"
      ></PrestazionePerFamiliare>
    </b-modal>
    <b-modal size="xl" centered id="info_ord" title="Informativa" hide-footer>
      <OrdinePerFamiliare :company="selectedCompany"></OrdinePerFamiliare>
    </b-modal>

    <b-modal
      size="xl"
      centered
      no-close-on-backdrop
      id="htn-consenses-modal"
      ref="htn-consenses-modal"
      body-class="px-4"
      hide-footer
      title="Consensi al trasferimento dei dati ad HTN"
    >
      <template v-if="!htn.oldPermissions.anagraphic">
        <p>
          Sei consapevole che proseguendo nel percorso tu acconsenta DOTTORFARMA
          a trasferire ad HTN i seguenti dati personali:
        </p>
        <ul>
          <li>ID Paziente DOTTORFARMA</li>
          <li>Nome</li>
          <li>Cognome</li>
          <li>Data di nascita</li>
          <li>Sesso</li>
          <li>Città di nascita</li>
          <li>Codice Fiscale</li>
          <li>Email</li>
          <li>Numero Cellulare</li>
          <li>Prefisso Numero Cellulare</li>
        </ul>

        <p>
          I dati anagrafici saranno utilizzati per la creazione di una e-mail di
          richiesta di attivazione del profilo sia per l’APP HTN, che per il
          sito HTN al fine di poter consultare il proprio dossier sanitario. Al
          ricevimento dell’e-mail l’utente potrà scegliere se continuare o se
          cancellare i propri dati, nell’eventualità che continuasse sarà
          richiesta l’accettazione dei seguenti documenti: <br />
          - Condizioni generali di contratto (per utilizzo APP) <br />
          - App informativa privacy <br />
          - Informativa privacy.<br />
          L’utente potrà cancellare il suo profilo sia sul sito che nell’app in
          qualsiasi momento.
        </p>

        <p class="d-flex justify-content-center">
          <b-form-checkbox
            class="text-font-light-grey mr-4"
            name="data_consense"
            :value="false"
            :unchecked-value="false"
            :state="htn.newPermissions.anagraphic === false ? false : null"
            v-model="htn.newPermissions.anagraphic"
          >
            Non acconsento
          </b-form-checkbox>
          <b-form-checkbox
            class="text-font-light-grey mx-1"
            name="data_consense"
            :value="true"
            :unchecked-value="false"
            :state="htn.newPermissions.anagraphic === true ? true : false"
            v-model="htn.newPermissions.anagraphic"
          >
            Acconsento al trasferimento dei dati anagrafici
          </b-form-checkbox>
        </p>
      </template>
      <template v-if="!htn.oldPermissions.reservation.always">
        <p>
          Sei consapevole che proseguendo nel percorso tu acconsenta DOTTORFARMA
          a trasferire ad HTN i seguenti dati:
        </p>

        <ul>
          <li>P.IVA Farmacia</li>
          <li>Data/ora Prenotazione</li>
          <li>Tipo Prestazione</li>
          <li>Id Prenotazione</li>
        </ul>
        <p class="text-justified">
          I dati prenotazione saranno utilizzati da HTN tutte le volte che
          l’utente prenota un servizio offerto da HTN tramite l’APP DOTTORFARMA.
          I dati prenotazione saranno utilizzati da HTN a seguito dell’avvenuta
          prestazione ed effettuazione della prestazione, per generare una
          notifica sull’App DOTTORFARMA di avviso di disponibilità del referto
          richiesto. L’utente dovrà necessariamente accedere all’APP HTN o al
          Dossier sanitario per visionare il referto.
        </p>
        <p class="d-flex justify-content-center">
          <b-form-checkbox
            class="text-font-light-grey mr-4"
            name="data_consense"
            :value="false"
            :unchecked-value="false"
            :disabled="!htn.newPermissions.anagraphic"
            :state="
              htn.newPermissions.reservation.accepted === false ? false : null
            "
            v-model="htn.newPermissions.reservation.accepted"
          >
            Non acconsento
          </b-form-checkbox>
          <b-form-checkbox
            class="text-font-light-grey mx-1"
            name="data_consense"
            :disabled="!htn.newPermissions.anagraphic"
            :value="true"
            :unchecked-value="false"
            :state="
              htn.newPermissions.reservation.accepted === true ? true : false
            "
            v-model="htn.newPermissions.reservation.accepted"
          >
            Acconsento al trasferimento dei dati prenotazione
          </b-form-checkbox>
        </p>
        <p
          v-if="!htn.newPermissions.anagraphic"
          class="text-danger text-center"
        >
          Per trasferire i dati della prenotazione occorre anche acconsentire al
          trasferimento dei propri dati anagrafici
        </p>
        <p v-if="htn.newPermissions.reservation.accepted" class="text-center">
          <b-form-checkbox
            class="text-font-light-grey mx-1"
            name="data_consense"
            :value="true"
            :unchecked-value="false"
            :state="
              htn.newPermissions.reservation.always === false ? null : true
            "
            v-model="htn.newPermissions.reservation.always"
          >
            Consenti sempre
          </b-form-checkbox>
        </p>
        <p class="text-center">
          <b-button
            variant="primary text-white primary_gradient fw-bolder"
            @click="submitHTNConsenses"
            >Salva preferenze</b-button
          >
        </p>
      </template>
    </b-modal>
  </div>
</template>
<script>
import * as moment from "moment/moment";
import { mapState, mapActions } from "vuex";
import { reservationService } from "@/_services";
import { cittadinoService } from "@/_services";
import CompanyCard from "@/components/prenotazioni/CompanyCard.vue";
import SearchCompany from "@/components/home/SearchCompany.vue";
import FillSurvey from "@/components/utilities/FillSurvey.vue";
// import FamiliareCard from "@/components/familiari/FamiliareCard.vue";
import SubjectSelection from "@/components/prenotazioni/form/SubjectSelection.vue";
import OrderTypeSelection from "@/components/prenotazioni/form/OrderTypeSelection.vue";
import ProductSelection from "@/components/prenotazioni/form/ProductSelection.vue";
import PrestazioneSelection from "@/components/prenotazioni/form/PrestazioneSelection.vue";
import TeleconsultoSurveySelection from "@/components/prenotazioni/form/TeleconsultoSurveySelection.vue";
import StepperButtons from "@/components/prenotazioni/form/StepperButtons.vue";
import OrdinePerFamiliare from "@/components/prenotazioni/informative/OrdinePerFamiliare.vue";
import PrestazionePerFamiliare from "@/components/prenotazioni/informative/PrestazionePerFamiliare.vue";
import TeleconsultoPerFamiliare from "@/components/prenotazioni/informative/TeleconsultoPerFamiliare.vue";

export default {
  name: "NuovoOrdine",
  components: {
    CompanyCard,
    SearchCompany,
    FillSurvey,
    // FamiliareCard,
    SubjectSelection,
    OrderTypeSelection,
    ProductSelection,
    PrestazioneSelection,
    TeleconsultoSurveySelection,
    StepperButtons,
    OrdinePerFamiliare,
    PrestazionePerFamiliare,
    TeleconsultoPerFamiliare,
  },
  computed: {
    checkPrecedente: function () {
      var today = moment(this.today).week();
      var start = moment(this.filters.from, "D/M/YY").week();
      return start <= today;
    },
    ...mapState({
      alert: (state) => state.alert,
    }),
    ...mapState("utente", ["status", "user"]),
  },
  watch: {
    form: {
      handler(val) {
        // if (val.data_inizio !== null) {
        //   // if (this.showSurvey == true) {
        //   //   this.$nextTick(function () {
        //   //     //console.log(this.$refs);
        //   //     // this.$refs["moveOn"].scrollIntoView();
        //   //     // document.getElementById("moveOn").scrollIntoView();
        //   //   });
        //   // } else {
        //   //   this.$nextTick(function () {
        //   //     //console.log(this.$refs);
        //   //     // this.$refs["moveOn"].scrollIntoView();
        //   //     // document.getElementById("moveOn").scrollIntoView();
        //   //   });
        //   // }
        // }
        if (val.type === "prodotto") {
          if (this.selectedCompany.stato_servizi["facile"] == undefined) {
            this.block = true;
            this.waitForInput = true;
            window.scrollTo(0, 0);
            this.errorAlert(
              "La farmacia selezionata non ha attivato il servizio."
            );
          } else if (this.selectedCompany.stato_servizi["facile"] == 0) {
            this.block = true;
            this.waitForInput = true;
            window.scrollTo(0, 0);
            this.errorAlert(
              "La farmacia selezionata non ha impostato gli orari di disponibilità per il servizio."
            );
          } else {
            this.block = false;
          }
        } else if (val.type === "teleconsulto") {
          if (this.selectedCompany.stato_servizi["connessa"] == undefined) {
            this.block = true;
            this.waitForInput = true;
            window.scrollTo(0, 0);
            this.errorAlert(
              "La farmacia selezionata non ha attivato il servizio."
            );
          } else if (this.selectedCompany.stato_servizi["connessa"] == 0) {
            this.block = true;
            this.waitForInput = true;
            window.scrollTo(0, 0);
            this.errorAlert(
              "La farmacia selezionata non ha impostato gli orari di disponibilità per il servizio."
            );
          } else {
            this.block = false;
          }
        } else {
          this.block = false;
        }
      },
      deep: true,
    },
    selectedCompany: function (val) {
      //console.log("SELECTED COMPANY WATCHER", val);
      this.selectCompany(val);
    },
    proceed: function (val) {
      if (val === true) {
        this.$nextTick(function () {
          //console.log(this.$refs);
          // this.$refs["moveOn"].scrollIntoView();
          // document.getElementById("moveOn").scrollIntoView();
        });
      }
    },
    step: function (val) {
      if (val == 3) {
        this.waitForInput = !this.objectSetted;
      }
      if (val == 4) {
        this.waitForInput = !this.form.data_inizio;
      }
      if (val == 6) {
        if (
          this.isHTN &&
          (this.form.subject_id === null ||
            this.form.subject_id === this.user.hashid) &&
          (!this.htn.oldPermissions.anagraphic ||
            !this.htn.oldPermissions.reservation.always)
        ) {
          this.$refs["htn-consenses-modal"].show();
        }
      }
    },
  },
  data() {
    return {
      form: {
        company_id: null,
        service_id: null,
        data_inizio: null,
        data_fine: null,
        tipologia: "ordine",
        type: "prestazione",
        user_notes: null,
        filled_survey_id: null,
        order: null,
        subject_id: null,
        subject_type: "user_in_app",
      },
      durata: 15,
      health_service_id: null,
      details: null,
      selectedCompany: null,
      selectedPrestazione: null,
      selectedProduct: null,
      selectedProducts: [],
      start: null,
      end: null,
      month: null,
      today: null,
      fields: [],
      disponibilita: null,
      selectedSlot: null,
      selectedCol: null,
      filters: {
        from: null,
        to: null,
      },
      showDisponibilita: false,
      reservationConfirmed: false,
      sending: false,
      newReservation: null,
      productOrder: {
        nre: null,
        rev: null,
        resource_id: null,
        products: null,
      },
      proceed: false,
      informAccepted: false,
      informRead: false,
      // informativa: {
      //   prodotto: {
      //     text: " informativa prodotto",
      //     url: process.env.VUE_APP_LINK_INFO_PRENOTAZIONE,
      //   },
      //   prestazione: {
      //     text: " informativa prestazione",
      //     url: process.env.VUE_APP_LINK_INFO_PRENOTAZIONE,
      //   },
      //   teleconsulto: {
      //     text: " informativa teleconsulto",
      //     url: process.env.VUE_APP_LINK_INFO_PRENOTAZIONE,
      //   },
      // },
      informativa: {
        prodotto: {
          text: " informativa prodotto",
          url: "info_ord",
        },
        prestazione: {
          text: " informativa prestazione",
          url: "info_prest",
        },
        teleconsulto: {
          text: " informativa teleconsulto",
          url: "info_tc",
        },
      },
      showSurvey: false,
      isTc: false,
      selectTcOptionDisabled: false,
      assisted: [],
      fileOrder: null,
      surveyToFill: null,
      block: false,
      step: 1,
      waitForInput: false,
      objectSetted: false,
      htn: {
        oldPermissions: {
          anagraphic: false,
          reservation: {
            always: false,
          },
        },
        newPermissions: {
          anagraphic: false,
          reservation: {
            accepted: false,
            always: false,
          },
        },
      },
      isHTN: false,
    };
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    ...mapActions("utente", ["me"]),
    enlight(comp) {
      return this.selectedCompany && comp.hashid === this.selectedCompany.hashid
        ? true
        : false;
    },
    selectCompany(data) {
      this.selectedCompany = data;
      this.selectedPrestazione = null;
      this.surveyToFill = null;
      this.showDisponibilita = false;
      this.form.data_inizio = null;
      this.selectedSlot = null;
      this.selectedCol = null;
      this.form.company_id = data.hashid;
      this.waitForInput = false;
      if (!this.isTc) {
        this.form.service_id = data.servizi_attivi["facile"];
        if (data.servizi_attivi["facile"] == undefined) {
          this.showSurvey = false;
          this.waitForInput = true;
          window.scrollTo(0, 0);
          this.errorAlert(
            "La farmacia selezionata non ha attivato il servizio."
          );
        } else if (
          this.form.type === "prodotto" &&
          data.servizi_attivi_orari["facile"].length == 0
        ) {
          this.showSurvey = false;
          this.waitForInput = true;
          window.scrollTo(0, 0);
          this.errorAlert(
            "La farmacia selezionata non ha impostato gli orari di disponibilità per il servizio."
          );
        }
      } else {
        this.form.service_id = data.servizi_attivi["connessa"];
        this.selectTcOptionDisabled = false;
        if (
          data.servizi_attivi["connessa"] == undefined ||
          data.stato_servizi["connessa"] == undefined ||
          data.stato_servizi["connessa"] == 0
        ) {
          this.showSurvey = false;
          this.waitForInput = true;
          window.scrollTo(0, 0);
          this.errorAlert("La farmacia non ha attivato il servizio.");
          this.selectTcOptionDisabled = true;
        } else if (data.servizi_attivi_orari["connessa"].length == 0) {
          this.showSurvey = false;
          this.waitForInput = true;
          window.scrollTo(0, 0);
          this.errorAlert(
            "La farmacia non ha impostato gli orari di disponibilità per il servizio."
          );
          this.selectTcOptionDisabled = true;
        }
      }
    },
    submitHTNConsenses() {
      //Se non ha accettato entrambi i consensi non si procede al salvataggio
      if (
        this.htn.newPermissions.anagraphic === true &&
        this.htn.newPermissions.reservation.accepted === true
      ) {
        const newPermissions = {
          permissions: [
            {
              data_model: "reservation",
              always: this.htn.newPermissions.reservation.always,
            },
          ],
          reservation_hash: this.newReservation.hashid,
        };

        //invia l'anagraphic solo se non è mai stata inviata
        if (!this.htn.oldPermissions.anagraphic) {
          newPermissions.permissions.push({
            data_model: "anagraphic",
            always: false,
          });
        }

        cittadinoService
          .storeHTNPermissions(newPermissions)
          .then((res) => {
            this.$refs["htn-consenses-modal"].hide();
          })
          .catch((err) => {
            this.errorAlert(
              "Non è stato possibile salvare i consensi per la prenotazione HTN"
            );
          })
          .finally(() => {
            this.resetHTNData();
          });
      }
      this.$refs["htn-consenses-modal"].hide();
    },
    formatDisponibilita() {
      var data = {
        hash: this.selectedPrestazione.hashid,
        params: this.filters,
      };
      var self = this;
      reservationService
        .disponibilitaPrestazione(data)
        .then(function (res) {
          self.disponibilita = [res.data.data];
          if (res.data.data.length == 0) {
            window.scrollTo(0, 0);
            self.infoAlert(
              "La prestazione scelta non ha nessuna disponibilità per la settimana corrente"
            );
          }
        })
        .catch(function (error) {
          self.disponibilita = [];
          window.scrollTo(0, 0);
          self.errorAlert(
            "Non è stato possibile trovare disponibilità per questo servizio"
          );
        });
    },
    formatDisponibilitaService() {
      var data = {
        hash: this.form.service_id,
        company: this.form.company_id,
        params: this.filters,
      };
      var self = this;
      reservationService
        .disponibilitaServizio(data)
        .then(function (res) {
          self.disponibilita = [res.data.data];
          if (res.data.data.length == 0) {
            window.scrollTo(0, 0);
            self.errorAlert(
              "Nessuna disponibilità per la settimana e la prestazione scelta"
            );
          }
        })
        .catch(function (error) {
          self.disponibilita = [];
          window.scrollTo(0, 0);
          self.errorAlert(
            "Non è stato possibile trovare disponibilità per questo servizio"
          );
        });
    },
    setPrev() {
      this.start.subtract(1, "weeks");
      this.end.subtract(1, "weeks");
      this.filters.from = moment(this.start).startOf("week").format("D/M/YY");
      this.filters.to = moment(this.end).endOf("week").format("D/M/YY");
      this.selectedSlot = null;
      this.selectedCol = null;
      this.setupCalendar();
    },
    setPost() {
      this.start.add(1, "weeks");
      this.end.add(1, "weeks");
      this.filters.from = moment(this.start).startOf("week").format("D/M/YY");
      this.filters.to = moment(this.end).endOf("week").format("D/M/YY");
      this.selectedSlot = null;
      this.selectedCol = null;
      this.setupCalendar();
    },
    setupCalendar() {
      this.selectedSlot = null;
      this.selectedCol = null;
      var fields = [];
      for (let x = 0; x <= 6; x++) {
        let startDay = moment(this.start);
        let todayDay = moment(this.today).format("ddd DD/M");
        let date = startDay.add(x, "days").format("ddd DD/M");
        let variant = undefined;
        if (todayDay === date) {
          variant = "sec-4";
        }
        let chiave = x + 1;
        let newField = {
          key: chiave.toString(),
          label: date,
          variant: variant,
        };
        fields.push(newField);
      }
      this.fields = fields;
      if (this.form.type === "prestazione") {
        this.formatDisponibilita();
      } else {
        this.formatDisponibilitaService();
      }
      this.showDisponibilita = true;
    },
    selectDate(col, slot) {
      this.selectedCol = col;
      this.selectedSlot = slot;
      let startDay = moment(this.start);
      let date = startDay.add(col - 1, "days").format("ddd DD/MM/YYYY");
      date += " " + slot;
      this.form.data_inizio = moment(date, "ddd DD/MM/YYYY HH:mm");
      this.waitForInput = false;
      if (this.isTc) {
        this.getDuration();
      }
    },
    checkIfSelected(col, slot) {
      if (!this.selectedCol || !this.selectedSlot) {
        return false;
      }
      if (this.selectedCol == col && this.selectedSlot == slot) {
        return true;
      }
    },
    resetAll() {
      switch (this.$route.params.type) {
        case "nuovo ordine":
          this.form.type = "prodotto";
          break;
        case "nuova prenotazione":
          this.form.type = "prestazione";
          break;
        case "nuovo teleconsulto":
          this.form.type = "teleconsulto";
          break;
      }
      this.setDetail();
      // this.form = {
      //   company_id: null,
      //   service_id: null,
      //   data_inizio: null,
      //   data_fine: null,
      //   tipologia: "ordine",
      //   type:
      //     this.$route.params.type == "nuovo ordine"
      //       ? "prodotto"
      //       : "prestazione",
      //   user_notes: null,
      //   order: null,
      // };
      // this.showDisponibilita = false;
      // this.fields = [];
      // this.disponibilita = null;
      // this.selectedSlot = null;
      // this.selectedCol = null;
      // this.health_service_id = null;
      // this.selectedCompany = null;
      // this.selectedPrestazione = null;
    },
    resetHTNData() {
      this.htn.newPermissions = {
        anagraphic: false,
        reservation: {
          accepted: false,
          always: false,
        },
      };
    },
    submitRequest() {
      if (!this.informAccepted || !this.informRead) {
        window.scrollTo(0, 0);
        this.errorAlert(
          "Per procedere è necessario leggere ed acconsentire all'informativa."
        );
        return false;
      }
      this.sending = true;
      var order = this.form;
      if (this.form.type === "prestazione") {
        order.order = { health_service_id: this.health_service_id };
      } else if (this.form.type === "prodotto") {
        order.order = this.productOrder;
      } else if (this.form.type === "teleconsulto") {
        order.order = { durata: this.durata };
      }
      var dataF = this.form.data_inizio;
      order.data_inizio = dataF.format("YYYY-MM-DD HH:mm");
      // this.form.order = { health_service_id: this.health_service_id };
      // var dataF = this.form.data_inizio;
      // this.form.data_inizio = dataF.format("YYYY-MM-DD HH:mm");
      var self = this;
      reservationService
        .storeReservation(order)
        .then(function (res) {
          window.scrollTo(0, 0);
          self.successAlert("Prenotazione inviata con successo!");
          self.newReservation = res.data.data;
          self.reservationConfirmed = true;
          self.sending = false;
          self.step = 6;
        })
        .catch(function (error) {
          window.scrollTo(0, 0);
          var msg = "Non è stato possibile inviare la richiesta";
          if (error.data.errors && error.data.errors.duplicate) {
            msg = error.data.errors.duplicate;
            self.setupCalendar();
          }
          self.errorAlert(msg);
          self.sending = false;
        });
    },
    updatePreferenze(nuovaPreferita) {
      this.me();
      this.$refs["modal-preferenze"].hide();
      this.selectCompany(nuovaPreferita);
      this.form.company_id = nuovaPreferita.hashid;
      this.form.service_id = this.isTc
        ? nuovaPreferita.servizi_attivi["connessa"]
        : nuovaPreferita.servizi_attivi["facile"];
    },
    downloadPdf() {
      const hash = this.newReservation.hashid;
      const fName = this.newReservation.codice_richiesta;
      var self = this;
      reservationService
        .scaricaPromemoria(hash)
        .then(function (response) {
          let blob = new Blob([response.data]);
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = fName + ".pdf";
          link.click();
        })
        .catch(function (error) {
          self.loading = false;
          window.scrollTo(0, 0);
          self.errorAlert("non è stato possibile scaricare il promemoria");
        });
    },
    downloadCalendar() {
      const hash = this.newReservation.hashid;
      const fName = this.newReservation.codice_richiesta;
      var self = this;
      reservationService
        .scaricaCalendar(hash)
        .then(function (response) {
          let blob = new Blob([response.data]);
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = fName + ".ics";
          link.click();
        })
        .catch(function (error) {
          self.loading = false;
          window.scrollTo(0, 0);
          self.errorAlert("non è stato possibile scaricare il promemoria");
        });
    },
    handleFilledSurvey(filled) {
      this.showSurvey = false;
      var temp = {
        info: this.surveyToFill.info,
        nome: this.surveyToFill.name,
        domande: filled,
      };
      var formData = {
        response: JSON.stringify(temp),
        survey_id: this.surveyToFill.hashid,
        company_id: this.form.company_id,
      };
      var self = this;
      reservationService
        .storeFilledSurvey(formData)
        .then(function (res) {
          //console.log(res);
          self.form.filled_survey_id = res.data.data.hashid;
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile inviare il questionario");
          console.log(err);
        });
    },
    getDuration() {
      var formData = {
        hash: this.form.service_id,
        filters: {
          company: this.form.company_id,
          day: this.selectedCol,
        },
      };
      var self = this;
      reservationService
        .getServiceOpeningForDay(formData)
        .then(function (res) {
          //console.log(res);
          self.durata = res.data.data[0].interval;
          // window.scrollTo(0, 0);
          // self.successAlert("Questionario inviato correttamente.");
          // self.survey = res.data.data;
          // self.consent = self.survey.info == null;
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile inviare il questionario");
          console.log(err);
        });
    },
    setDetail() {
      this.form.data_inizio = null;
      this.form.data_fine = null;
      this.informAccepted = false;
      this.selectedSlot = null;
      this.selectedCol = null;
      this.showDisponibilita = false;
      this.showSurvey = false;
      this.surveyToFill = null;
      this.productOrder = {
        nre: null,
        rev: null,
        resource_id: null,
        products: null,
      };
      this.proceed = false;
      this.fileOrder = null;
      this.selectedPrestazione = null;
      this.health_service_id = null;
      this.form.filled_survey_id = null;
    },
    setRequestSubject(subject) {
      this.form.subject_id = subject.subject_id;
      this.form.subject_type = subject.subject_type;
      this.waitForInput = subject.subject_id === null;
    },
    setRequestType(type) {
      this.form.type = type;
      this.setDetail();
    },
    setRequestProduct(products) {
      //console.log(products);
      this.waitForInput = false;
      this.objectSetted = true;
      this.productOrder = products;
      this.proceed = true;
      this.setupCalendar();
    },
    setFileOrder(fileOrder) {
      this.fileOrder = fileOrder;
    },
    setRequestPrestazione(prestazione) {
      if (prestazione.htn) {
        this.isHTN = true;
        this.getOwnHTNConsenses();
      }
      this.objectSetted = true;
      this.waitForInput = false;
      this.selectedPrestazione = prestazione;
      this.health_service_id = prestazione.hashid;
      this.form.data_inizio = null;
      this.showSurvey = prestazione.questionario !== null;
      this.surveyToFill = prestazione.questionario;
      this.setupCalendar();
    },
    setRequestTcSurvey(survey) {
      //console.log(survey);
      this.waitForInput = false;
      this.objectSetted = true;
      this.surveyToFill = survey;
      this.showSurvey = true;
      this.setupCalendar();
    },
    getOwnHTNConsenses() {
      cittadinoService.getOwnHTNConsenses().then((res) => {
        if (res.data.data.permissions && res.data.data.permissions.length > 0) {
          const anagraphicPermission = res.data.data.permissions.find(
            (permission) => permission.data_model === "anagraphic"
          );
          const reservationPermission = res.data.data.permissions.find(
            (permission) => permission.data_model === "reservation"
          );

          if (anagraphicPermission) {
            this.htn.oldPermissions.anagraphic = true;
            this.htn.newPermissions.anagraphic = true;
          }

          this.htn.oldPermissions.reservation.always =
            reservationPermission.always;
          this.htn.newPermissions.reservation.always =
            reservationPermission.always;
        }
      });
      // .catch((err) => {
      //   this.errorAlert("Non è stato possibile recuperare i consensi HTN")
      // })
    },
  },
  created() {
    if (this.user.farmacia_preferita) {
      this.selectedCompany = this.user.farmacia_preferita;
      this.form.company_id = this.user.farmacia_preferita.hashid;
      this.form.service_id =
        this.$route.params.type == "nuovo teleconsulto"
          ? this.user.farmacia_preferita.servizi_attivi["connessa"]
          : this.user.farmacia_preferita.servizi_attivi["facile"];
    }
    this.start = moment().startOf("week");
    this.end = moment().endOf("week");
    this.today = moment();
    this.month = moment().month();
    this.filters.from = moment().startOf("week").format("D/M/YY");
    this.filters.to = moment().endOf("week").format("D/M/YY");
    if (this.$route.params.type == "nuovo ordine") {
      this.form.type = "prodotto";
    } else if (this.$route.params.type == "nuovo teleconsulto") {
      this.isTc = true;
      this.form.type = "teleconsulto";
    }
  },
  mounted() {
    // this.$bvModal.show("htn-consenses-modal");
    if (this.$route.params.type == "nuovo teleconsulto") {
      this.form.type = "teleconsulto";
      this.setDetail();
    }
    if (this.$route.query.cindex != null) {
      let indx = parseInt(this.$route.query.cindex);
      this.selectCompany(this.user.my_circle[indx]);
    }
  },
};
</script>
<style lang="scss">
.select__dis:disabled {
  background-color: #fff !important;
  color: inherit !important;
}

.nav-link {
  color: $prim-grad-2;
  // color: $font-grey;
  font-weight: 600;
}

.nav-link.disabled {
  color: $sec-4 !important;
}

.done > .nav-link.disabled {
  color: $prim-grad-2 !important;
}

.nav-link.active {
  color: $prim-grad-2 !important;
  font-weight: 800;
  border-bottom: 1px solid $prim-grad-2;
}

.nav-link.active.done {
  color: $prim-grad-2 !important;
  font-weight: 800;
}

.nav-item a {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.table__scrollable {
  max-height: 20vh !important;
  overflow-y: scroll;
}
</style>
