<template>
  <div></div>
  <!-- <b-row
    cols="1"
    class="justify-content-center align-items-center"
    :style="bgStyle"
  >
    <b-col class="col-12 text-center">
      <b-col class="col-6 col-sm-4 col-md-3 col-lg-2 mx-auto">
        <b-img
          :src="require('@/assets/concura-logo.svg')"
          fluid-grow
          alt="logo concura"
        ></b-img>
      </b-col>
    </b-col>
    <b-col class="mx-auto col-12 col-md-10 mt-2">
      <b-container>
        <div class="puntofarmaCard col-sm-12 col-12 col-md-10 col-lg-8 mx-auto">
          <div class="py-5 px-4 whitesmoke">
            <b-spinner v-if="loading"></b-spinner>
            <div v-else class="text-left">
              <h4 class="pb-1 mt-1 mb-4">Manuali e Guide</h4>

              <div class="mb-5">
                <h5 class="pb-1 mt-1 mb-4">Manuale d'uso per il cittadino</h5>
                <b-row>
                  <b-col>
                    <h5>
                      <a
                        :href="manualeUrlCittadino"
                        target="_blank"
                        class="text-secondary point"
                      >
                        <u>Scarica la versione aggiornata</u>
                      </a>
                    </h5>
                  </b-col>
                </b-row>
              </div>

              <div v-if="scaricabili.manuali" class="mb-5">
                <h4 class="pb-1 mt-1 mb-4">Manuali d'uso per operatori</h4>
                <b-row
                  v-for="manuale in scaricabili.manuali"
                  :key="manuale.title"
                  class="mb-3"
                >
                  <b-col>
                    <h5>
                      <a
                        :href="manuale.path"
                        target="_blank"
                        class="text-secondary point"
                      >
                        <u>{{ manuale.title }}</u>
                      </a>
                    </h5>
                    <p>{{ manuale.description }}</p>
                  </b-col>
                </b-row>
              </div>

              <div v-if="scaricabili.video">
                <h4 class="pb-1 mt-1 mb-4">Video Tutorial</h4>
                <b-row
                  v-for="video in scaricabili.video"
                  :key="video.title"
                  class="mb-4"
                >
                  <b-col>
                    <h5 class="text-secondary">
                      {{ video.title }}
                    </h5>
                    <p>{{ video.description }}</p>
                    <b-col cols="12" md="10" lg="8" xl="7" class="px-0">
                      <b-embed
                        type="video"
                        controls
                        poster="/assets/concura-logo.svg"
                      >
                        <source :src="video.path" type="video/mp4" />
                      </b-embed>
                    </b-col>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </div>
      </b-container>
    </b-col>
  </b-row> -->
</template>

<script>
// import { utilityService } from "@/_services"

export default {
  name: "ManualiEGuide",
  //   data() {
  // return {
  //   manualeUrlCittadino: process.env.VUE_APP_URL_MANUALE_PAZIENTE,
  //   scaricabili: {
  //     manuali: null,
  //     video: null
  //   },
  //   loading: true,
  //   bgStyle: {
  //     backgroundImage: `url(${require("@/assets/concura_bg_logo.svg")})`,
  //     backgroundPosition: "3vw bottom",
  //     backgroundSize: "80vh",
  //     backgroundRepeat: "no-repeat",
  //     minHeight: "90vh"
  //   }
  // }
  //   },
  methods: {
    // fetchData() {
    //   utilityService
    //     .getPublicScaricabili()
    //     .then((res) => {
    //       this.scaricabili = res.data.data
    //       console.log("scaricabili", this.scaricabili)
    //       this.loading = false
    //     })
    //     .catch((err) => {
    //       console.log(err)
    //       this.loading = false
    //     })
    // }
  },
  created() {
    const loggedIn = localStorage.getItem("user")
    if (loggedIn) {
      const user = JSON.parse(localStorage.getItem("user"))
      if (user.type === 0) {
        this.$router.push("/cittadino/help")
      } else {
        this.$router.push("/operatore/scaricabili")
      }
    }
  }
}
</script>
