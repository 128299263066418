<template>
  <div v-if="patientList">
    <b-form @submit.prevent="fetchData" name="listsearch">
      <b-row align-v="center" class="bg-light py-3 border border-info flex-wrap">
        <b-col class="px-0" cols="12" md="2" lg="1">
          <h6 class="mt-2">
            <span class="mx-2">
              <b-icon icon="search"></b-icon>
            </span>
            Cerca
          </h6>
        </b-col>
        <b-col>
          <b-input-group size="sm" class="shadow_6">
            <!-- <b-input-group-prepend>
            <span class="mx-2">
              <b-icon icon="search"></b-icon>
            </span>
          </b-input-group-prepend> -->
            <b-form-input class="align-items-center align-content-center border-right-0 text-uppercase" name="cfsearch"
              v-model="filters.cf" type="search" placeholder="Codice Fiscale.." maxlength="16"
              @input="filters.surname = undefined">
            </b-form-input>
          </b-input-group>
        </b-col>
        <b-col>
          <b-input-group size="sm" class="shadow_6">
            <!-- <b-input-group-prepend>
            <span class="mx-2">
              <b-icon icon="search"></b-icon>
            </span>
          </b-input-group-prepend> -->
            <b-form-input class="align-items-center align-content-center border-right-0 text-capitalize"
              name="surnamesearch" v-model="filters.surname" type="search" placeholder="Cognome.."
              @input="filters.cf = undefined">
            </b-form-input>
          </b-input-group>
        </b-col>
        <b-col cols="12" md="2">
          <b-button type="submit" variant="info" size="sm" class="fw-bolder px-4">Cerca</b-button>
        </b-col>
      </b-row>
    </b-form>
    <b-table hover borderless responsive table-variant="white text-info text-uppercase text-left row__font"
      :fields="fields" :items="patientList" :small="$root.isMobile" selectable select-mode="multi"
      selected-variant="sec-3" :busy="busyTable" thead-class=" border-medium-grey text-left" tbody-class=""
      ref="companyTable" tbody-tr-class="text-black border-bottom border-medium-grey">
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle" variant="info"></b-spinner>
        </div>
      </template>
      <template #cell(index)="row">
        <span> {{ row.index + 1 }}</span>
      </template>
      <template #cell(fullname)="row">
        <span v-if="row.item.anagrafica && row.item.anagrafica.name">{{
            row.item.anagrafica.name
        }}</span>
        <span v-if="row.item.anagrafica && row.item.anagrafica.surname">
          {{ row.item.anagrafica.surname }}</span>
      </template>
      <template #cell(azioni)="row">
        <span>
          <router-link class="fw-bolder text-primary no__hover text-right col-7 px-0 col-md-6" :to="{
            name: 'scheda Paziente',
            params: { hash: row.item.hashid }
          }">
            vai alla Scheda Paziente
            <b-icon icon="arrow-right-circle-fill" variant="primary"></b-icon>
          </router-link>
        </span>
      </template>
    </b-table>
    <b-pagination @change="handlePagination" v-model="filters.page" pills :total-rows="total" :per-page="filters.take"
      align="center">
    </b-pagination>
  </div>
</template>
<script>
import * as moment from "moment/moment";
import { mapState, mapActions } from "vuex";
import { supportService } from "@/_services";
import { format } from "path";
export default {
  name: "ListaClienti",
  data() {
    return {
      patientList: null,
      filters: {
        page: 1,
        take: 20,
        cf: undefined,
        surname: undefined,
        number: undefined,
      },
      fields: [
        { key: "index", label: "" },
        {
          key: "fullname",
          label: "Nome e Cognome",
          class: "text-justify text-uppercase",
        },
        {
          key: "anagrafica.cf",
          label: "Codice Fiscale",
          class: "text-uppercase",
        },
        {
          key: "anagrafica.birthday",
          label: "Data Nascita",
          formatter(value, key, item) {
            if (!value) {
              return "";
            }
            return moment(value).format("DD-MM-YYYY");
          },
        },
        { key: "anagrafica.tel_number", label: "Telefono" },
        { key: "email", label: "Email" },
        { key: "azioni", label: "" },
      ],
      total: 0,
      fromPage: 0,
      toPage: 0,
      busyTable: false
    };
  },
  watch: {
    filters: {
      handler(value) {
        if (value.cf === "") {
          this.filters.cf = undefined;
          this.fetchData()
        }
        if (value.surname === "") {
          this.filters.surname = undefined;
          this.fetchData()
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
    ...mapState("company", ["companyLoggedIn", "company"]),
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    goToScheda(hashid) {
      //console.log(hashid);
      this.$router.push({ name: "scheda Paziente", params: { hash: hashid } });
    },
    fetchData() {
      this.busyTable = true
      var self = this;
      supportService
        .getPatientsList(this.filters)
        .then(function (res) {
          if (Array.isArray(res.data.data.data)) {
            self.patientList = res.data.data.data;
          } else {
            self.patientList = Object.values(res.data.data.data)
          }
          self.filters.page = res.data.data.current_page;
          self.total = res.data.data.total;
          self.fromPage = res.data.data.from;
          self.toPage = res.data.data.to;
          self.busyTable = false
          // self.setCounterTimer();
        })
        .catch(function (err) {
          self.errorAlert("Non è stato possibile prelevare la lista pazienti");
          console.log(err)
          self.busyTable = false
        });
    },
    handlePagination(page) {
      this.filters.page = page;
      this.fetchData();
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>
