<template>
  <div class="pb-4">
    <div class="d-flex justify-content-between">
      <b-row cols="1" class="text-left mb-4">
        <router-link class="fw-bolder text-primary no__hover" :to="{
          name: 'scheda Paziente',
          params: { hash: $route.params.hash },
        }">
          <b-icon icon="arrow-left-circle-fill" variant="primary"></b-icon>
          Scheda paziente
        </router-link>
      </b-row>
    </div>

    <div v-if="reservationConfirmed && newReservation">
      <h2>Prenotazione conclusa</h2>
      <div>
        <h6 class="
            text-prim-grad-1
            fw-bolder
            text-left
            pb-1
            border-bottom border-line-grey
          ">
          Riepilogo
        </h6>
        <div>
          <div class="mt-2">
            <p class="text-left text-capitalize">
              Tipo ordine: {{ form.type }}
              <strong v-if="selectedPrestazione">
                {{ selectedPrestazione.name }}</strong>
            </p>
            <p class="text-left">
              Data selezionata:
              <strong>
                {{ form.data_inizio | moment("DD/MM/YY HH:mm") }}</strong>
            </p>
          </div>
        </div>
        <div class="pb-3 text-left" v-if="newReservation.resources && newReservation.resources.length > 0">
          <h6 class="
              text-prim-grad-1
              fw-bolder
              text-left
              pb-1
              border-bottom border-line-grey
            ">
            Documenti Scaricabili
          </h6>
          <p class="text-left">
            Sono presenti documenti correlati a questo ordine, puoi
            visualizzarli cliccando su ciascuno di essi. I documenti contenenti
            campi da compilare vanno scaricati, stampati, compilati e presentati
            in sede di appuntamento.
          </p>
          <a v-for="(allegato, ind) in newReservation.resources" :key="ind" :href="allegato.full_path" target="__blank"
            class="mr-1">
            <b-badge variant="primary" class="my-2 py-1 px-2" v-b-tooltip.hover
              :title="'apri ' + allegato.name + ' in un\'altra scheda'">{{ allegato.name }}</b-badge>
          </a>
        </div>
        <div class="col-12 text-center">
          <b-button variant="primary primary_gradient fw-bolder m-2" @click="downloadCalendar">Scarica calendar
          </b-button>
          <b-button variant="primary primary_gradient fw-bolder m-2" @click="downloadPdf">Scarica promemoria
          </b-button>
        </div>
      </div>
    </div>
    <b-container class="pt-2" v-else-if="subject">
      <b-row cols="1" class="my-1 py-2">
        <h6 class="
            text-prim-grad-1
            fw-bolder
            text-left
            pb-1
            border-bottom border-line-grey
          ">
          Prenota servizio
          {{
              subject.anagrafica
                ? "per " +
                subject.anagrafica.name +
                " " +
                subject.anagrafica.surname
                : "il paziente"
          }}
        </h6>
        <b-row cols="2" class="mt-2 text-left">
          <b-col>
            <b-form-group>
              <b-form-radio class="font-weight-bold" v-model="type" aria-describedby="prestazione" name="res-type"
                value="prestazione">prestazione sanitaria</b-form-radio>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <b-form-radio class="font-weight-bold" v-model="type" aria-describedby="teleconsulto" name="res-type"
                value="teleconsulto" :disabled="selectTcOptionDisabled">teleconsulto</b-form-radio>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row cols="1" class="mt-4 mb-2 mx-0">
          <PrestazioneSelection v-if="form.type === 'prestazione'" :company="selectedCompany"
            v-on:selected-prestazione="setRequestPrestazione($event)" :preselected="selectedPrestazione" />
        </b-row>
      </b-row>
      <b-row cols="1" v-if="showDisponibilita" class="my-2" ref="calendarchoice">
        <h6 class="
            mt-2
            text-prim-grad-1
            fw-bolder
            text-left
            pb-1
            border-bottom border-line-grey
          ">
          Data ed ora appuntamento
        </h6>
        <b-col class="text-left">
          <b-form-group label="">
            <b-form-radio class="font-weight-bold" v-model="form.data_inizio" aria-describedby="estemporaneo"
              name="este-time" :value="nowDate" @change="calendarChoice = !calendarChoice">Imposta appuntamento per oggi
              {{ formatFullDate(nowDate) }}</b-form-radio>
          </b-form-group>
        </b-col>
        <b-col class="text-left">
          <b-form-group label="">
            <b-form-radio class="font-weight-bold" v-model="calendarChoice" aria-describedby="estemporaneo"
              name="este-time" :value="true">Scegli tra date di disponibilità in calendario</b-form-radio>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row cols="1" v-if="showDisponibilita && calendarChoice" class="my-2">
        <h6 class="
            mt-2
            text-prim-grad-1
            fw-bolder
            text-left
            pb-1
            border-bottom border-line-grey
          ">
          Disponibilità
        </h6>
        <b-col>
          <div class="d-flex justify-content-between">
            <b-button variant="outline-info" :size="$root.isMobile ? 'sm' : ''" type="button" @click="setPrev"
              :disabled="checkPrecedente">
              <b-icon icon="chevron-double-left"></b-icon>{{ !$root.isMobile ? "Precedente" : "" }}
            </b-button>
            <h5 class="text-capitalize">
              {{ start | moment("D MMM") }} - {{ end | moment("D MMM") }}
            </h5>
            <b-button variant="outline-info" type="button" :size="$root.isMobile ? 'sm' : ''" @click="setPost">{{
                !$root.isMobile ? "Successiva" : ""
            }}
              <b-icon icon="chevron-double-right"></b-icon>
            </b-button>
          </div>
          <b-table borderless responsive sticky-header table-variant="white text-info" :fields="fields" :items="disponibilita"
            foot-clone small show-empty>
            <template #cell(1)="row">
              <p @click="selectDate(1, slot)" class="general_border point border border-info slot__tm"
                v-for="slot in row.item['1']" :key="slot" :class="
                  checkIfSelected(1, slot)
                    ? 'bg-info text-white'
                    : 'bg-white text-info'
                ">
                <strong class="">{{ slot }}</strong>
              </p>
            </template>
            <template #cell(2)="row">
              <p @click="selectDate(2, slot)" class="general_border point border border-info slot__tm"
                v-for="slot in row.item['2']" :key="slot" :class="
                  checkIfSelected(2, slot)
                    ? 'bg-info text-white'
                    : 'bg-white text-info'
                ">
                <strong class="">{{ slot }}</strong>
              </p>
            </template>
            <template #cell(3)="row">
              <p @click="selectDate(3, slot)" class="general_border point border border-info slot__tm"
                v-for="slot in row.item['3']" :key="slot" :class="
                  checkIfSelected(3, slot)
                    ? 'bg-info text-white'
                    : 'bg-white text-info'
                ">
                <strong class="">{{ slot }}</strong>
              </p>
            </template>
            <template #cell(4)="row">
              <p @click="selectDate(4, slot)" class="general_border point border border-info slot__tm"
                v-for="slot in row.item['4']" :key="slot" :class="
                  checkIfSelected(4, slot)
                    ? 'bg-info text-white'
                    : 'bg-white text-info'
                ">
                <strong class="">{{ slot }}</strong>
              </p>
            </template>
            <template #cell(5)="row">
              <p @click="selectDate(5, slot)" class="general_border point border border-info slot__tm"
                v-for="slot in row.item['5']" :key="slot" :class="
                  checkIfSelected(5, slot)
                    ? 'bg-info text-white'
                    : 'bg-white text-info'
                ">
                <strong class="">{{ slot }}</strong>
              </p>
            </template>
            <template #cell(6)="row">
              <p @click="selectDate(6, slot)" class="general_border point border border-info slot__tm"
                v-for="slot in row.item['6']" :key="slot" :class="
                  checkIfSelected(6, slot)
                    ? 'bg-info text-white'
                    : 'bg-white text-info'
                ">
                <strong class="">{{ slot }}</strong>
              </p>
            </template>
            <template #cell(7)="row">
              <p @click="selectDate(7, slot)" class="general_border point border border-info slot__tm"
                v-for="slot in row.item['7']" :key="slot" :class="
                  checkIfSelected(7, slot)
                    ? 'bg-info text-white'
                    : 'bg-white text-info'
                ">
                <strong class="">{{ slot }}</strong>
              </p>
            </template>
            <template #empty="scope">
              <p class="fw-bolder text-secondary" v-if="scope">
                Nessuna disponibilità per questa settimana. Prova a verificare
                le disponibilità per le prossime settimane.
              </p>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row cols="1" v-if="form.data_inizio" class="my-2">
        <h6 class="
            mt-2
            text-prim-grad-1
            fw-bolder
            text-left
            pb-1
            border-bottom border-line-grey
          ">
          Conferma
        </h6>
        <b-col>
          <div class="mt-2">
            <p class="text-left text-capitalize">
              Tipo ordine: {{ form.type }}
              <strong v-if="selectedPrestazione">
                {{ selectedPrestazione.name }}</strong>
            </p>
            <p class="text-left">
              Data ed ora selezionate:
              <strong>
                {{ form.data_inizio | moment("D MMM YYYY HH:mm") }}</strong>
            </p>
            <b-form-group label="Se vuoi, aggiungi una nota per il paziente" label-for="subtype"
              class="text-left fs-6 text-secondary">
              <b-form-input name="subtype" class="mx-auto shadow_6" v-model="form.company_notes" required>
              </b-form-input>
            </b-form-group>
          </div>
        </b-col>
        <b-col ref="orderConfirm" class="text-center">
          <b-button variant="outline-danger" class="m-2" @click="resetAll">Annulla</b-button>
          <b-button variant="primary text-white primary_gradient fw-bolder" class="m-2" @click="submitRequest">
            <span v-if="!sending">Procedi</span>
            <b-spinner v-else small></b-spinner>
          </b-button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import * as moment from "moment/moment";
import { mapState, mapActions } from "vuex";
import { reservationService, cittadinoService } from "@/_services";
import FillSurvey from "@/components/utilities/FillSurvey.vue";
import SubjectSelection from "@/components/prenotazioni/form/SubjectSelection.vue";
import PrestazioneSelection from "@/components/prenotazioni/form/PrestazioneSelection.vue";
import TeleconsultoSurveySelection from "@/components/prenotazioni/form/TeleconsultoSurveySelection.vue";
export default {
  name: "NuovaPrenotazioneCliente",
  components: {
    FillSurvey,
    SubjectSelection,
    PrestazioneSelection,
    TeleconsultoSurveySelection,
  },
  computed: {
    checkPrecedente: function () {
      var today = moment(this.today).week();
      var start = moment(this.filters.from, "D/M/YY").week();
      return start <= today;
    },
    ...mapState({
      alert: (state) => state.alert,
    }),
    ...mapState("utente", ["status", "user"]),
    // ...mapState("company", ["company"]),
  },
  watch: {
    form: {
      handler(val) {
        if (val.data_inizio !== null) {
          this.$nextTick(function () {
            this.$refs["orderConfirm"].scrollIntoView();
          });
        }
      },
      deep: true,
    },
    type: function (val) {
      this.form.type = val;
      this.calendarChoice = true;
      this.setDetail();
      if (val == "teleconsulto") {
        this.isTc = true;
        this.form.service_id = this.selectedCompany.servizi_attivi["connessa"];
        this.setupCalendar();
      } else {
        this.form.service_id = this.selectedCompany.servizi_attivi["facile"];
        this.isTc = false;
      }
      this.validateService();
    },
  },
  data() {
    return {
      subject: null,
      type: "prestazione",
      form: {
        company_id: null,
        service_id: null,
        data_inizio: null,
        data_fine: null,
        tipologia: "ordine",
        type: "prestazione",
        company_notes: null,
        filled_survey_id: null,
        order: null,
        subject_id: null,
        subject_type: "user_in_app",
      },
      durata: 15,
      health_service_id: null,
      details: null,
      selectedCompany: null,
      selectedPrestazione: null,
      start: null,
      end: null,
      month: null,
      today: null,
      fields: [],
      disponibilita: null,
      selectedSlot: null,
      selectedCol: null,
      filters: {
        from: null,
        to: null,
      },
      showDisponibilita: false,
      reservationConfirmed: false,
      sending: false,
      newReservation: null,
      isTc: false,
      selectTcOptionDisabled: false,
      surveyToFill: null,
      nowDate: null,
      calendarChoice: true,
    };
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    ...mapActions("utente", ["me"]),
    validateService() {
      if (!this.isTc) {
        this.form.service_id = this.selectedCompany.servizi_attivi["facile"];
        if (
          this.selectedCompany.servizi_attivi["facile"] == undefined
        ) {
          window.scrollTo(0, 0);
          this.errorAlert(
            "Servizio non attivo per la tua farmacia."
          );
        } else if (
          this.form.type === "prodotto" &&
          this.selectedCompany.servizi_attivi_orari["facile"].length == 0
        ) {
          window.scrollTo(0, 0);
          this.errorAlert(
            "Non ha impostato gli orari di disponibilità per il servizio."
          );
        }
      } else {
        this.form.service_id = this.selectedCompany.servizi_attivi["connessa"];
        this.selectTcOptionDisabled = false;
        if (
          this.selectedCompany.servizi_attivi["connessa"] == undefined ||
          this.selectedCompany.stato_servizi["connessa"] == undefined ||
          this.selectedCompany.stato_servizi["connessa"] == 0
        ) {
          window.scrollTo(0, 0);
          this.errorAlert(
            "Servizio non attivo per la tua farmacia."
          );
          this.selectTcOptionDisabled = true;
        } else if (
          this.selectedCompany.servizi_attivi_orari["connessa"].length == 0
        ) {
          window.scrollTo(0, 0);
          this.errorAlert(
            "Non ha impostato gli orari di disponibilità per il servizio."
          );
          this.selectTcOptionDisabled = true;
        }
        // if (
        //   this.selectedCompany.servizi_attivi["connessa"] == undefined ||
        //   this.selectedCompany.servizi_attivi_orari["connessa"].length == 0
        // ) {
        //   window.scrollTo(0, 0);
        //   this.errorAlert(
        //     "La farmacia non ha impostato gli orari per il servizio o non ha attivato il servizio."
        //   );
        //   this.selectTcOptionDisabled = true;
        // }
      }
    },
    formatDisponibilita() {
      var data = {
        hash: this.selectedPrestazione.hashid,
        params: this.filters,
      };
      var self = this;
      reservationService
        .disponibilitaPrestazione(data)
        .then(function (res) {
          self.disponibilita = [res.data.data];
          if (res.data.data.length == 0) {
            window.scrollTo(0, 0);
            self.errorAlert(
              "Nessuna disponibilità per la settimana e la prestazione scelta"
            );
          }
        })
        .catch(function (error) {
          self.disponibilita = [];
          window.scrollTo(0, 0);
          self.errorAlert(
            "Non è stato possibile trovare disponibilità per questo servizio"
          );
        });
    },
    formatDisponibilitaService() {
      var data = {
        hash: this.form.service_id,
        company: this.form.company_id,
        params: this.filters,
      };
      var self = this;
      reservationService
        .disponibilitaServizio(data)
        .then(function (res) {
          self.disponibilita = [res.data.data];
          if (res.data.data.length == 0) {
            window.scrollTo(0, 0);
            self.errorAlert(
              "Nessuna disponibilità per la settimana e la prestazione scelta"
            );
          }
        })
        .catch(function (error) {
          self.disponibilita = [];
          window.scrollTo(0, 0);
          self.errorAlert(
            "Non è stato possibile trovare disponibilità per questo servizio"
          );
        });
    },
    setPrev() {
      this.start.subtract(1, "weeks");
      this.end.subtract(1, "weeks");
      this.filters.from = moment(this.start).startOf("week").format("D/M/YY");
      this.filters.to = moment(this.end).endOf("week").format("D/M/YY");
      this.selectedSlot = null;
      this.selectedCol = null;
      this.setupCalendar();
    },
    setPost() {
      this.start.add(1, "weeks");
      this.end.add(1, "weeks");
      this.filters.from = moment(this.start).startOf("week").format("D/M/YY");
      this.filters.to = moment(this.end).endOf("week").format("D/M/YY");
      this.selectedSlot = null;
      this.selectedCol = null;
      this.setupCalendar();
    },
    setupCalendar() {
      this.selectedSlot = null;
      this.selectedCol = null;
      var fields = [];
      for (let x = 0; x <= 6; x++) {
        let startDay = moment(this.start);
        let todayDay = moment(this.today).format("ddd DD/M");
        let date = startDay.add(x, "days").format("ddd DD/M");
        let variant = undefined;
        if (todayDay === date) {
          variant = "sec-4";
        }
        let chiave = x + 1;
        let newField = {
          key: chiave.toString(),
          label: date,
          variant: variant,
        };
        fields.push(newField);
      }
      this.fields = fields;
      if (this.form.type === "prestazione") {
        this.formatDisponibilita();
      } else {
        this.formatDisponibilitaService();
      }
      this.showDisponibilita = true;
    },
    selectDate(col, slot) {
      //console.log("select date", this.isTc);
      this.selectedCol = col;
      this.selectedSlot = slot;
      let startDay = moment(this.start);
      let date = startDay.add(col - 1, "days").format("ddd DD/MM/YYYY");
      date += " " + slot;
      this.form.data_inizio = moment(date, "ddd DD/MM/YYYY HH:mm");
      if (this.isTc) {
        this.getDuration();
      }
    },
    checkIfSelected(col, slot) {
      if (!this.selectedCol || !this.selectedSlot) {
        return false;
      }
      if (this.selectedCol == col && this.selectedSlot == slot) {
        return true;
      }
    },
    resetAll() {
      switch (this.$route.params.type) {
        case "nuovo ordine":
          this.form.type = "prodotto";
          break;
        case "nuova prenotazione":
          this.form.type = "prestazione";
          break;
        case "nuovo teleconsulto":
          this.form.type = "teleconsulto";
          break;
      }
      this.setDetail();
    },
    submitRequest() {
      this.sending = true;
      var order = this.form;
      if (this.form.type === "prestazione") {
        order.order = { health_service_id: this.health_service_id };
      } else if (this.form.type === "teleconsulto") {
        order.order = { durata: this.durata };
      }
      var dataF = this.form.data_inizio;
      order.data_inizio = dataF.format("YYYY-MM-DD HH:mm");
      var self = this;
      reservationService
        .storeReservation(order)
        .then(function (res) {
          window.scrollTo(0, 0);
          self.successAlert("Prenotazione inviata con successo!");
          self.newReservation = res.data.data;
          self.reservationConfirmed = true;
          self.sending = false;
        })
        .catch(function (error) {
          window.scrollTo(0, 0);
          var msg = "Non è stato possibile inviare la richiesta";
          if (error.data.errors && error.data.errors.duplicate) {
            msg = error.data.errors.duplicate;
            self.setupCalendar();
          }
          self.errorAlert(msg);
          self.sending = false;
        });
    },
    downloadPdf() {
      const hash = this.newReservation.hashid;
      const fName = this.newReservation.codice_richiesta;
      var self = this;
      reservationService
        .scaricaPromemoria(hash)
        .then(function (response) {
          let blob = new Blob([response.data]);
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = fName + ".pdf";
          link.click();
        })
        .catch(function (error) {
          self.loading = false;
          window.scrollTo(0, 0);
          self.errorAlert("non è stato possibile scaricare il promemoria");
        });
    },
    downloadCalendar() {
      const hash = this.newReservation.hashid;
      const fName = this.newReservation.codice_richiesta;
      var self = this;
      reservationService
        .scaricaCalendar(hash)
        .then(function (response) {
          let blob = new Blob([response.data]);
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = fName + ".ics";
          link.click();
        })
        .catch(function (error) {
          self.loading = false;
          window.scrollTo(0, 0);
          self.errorAlert("non è stato possibile scaricare il promemoria");
        });
    },
    getDuration() {
      var formData = {
        hash: this.form.service_id,
        filters: {
          company: this.form.company_id,
          day: this.selectedCol,
        },
      };
      var self = this;
      reservationService
        .getServiceOpeningForDay(formData)
        .then(function (res) {
          //console.log(res);
          self.durata = res.data.data[0].interval;
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          self.errorAlert("Si è verificato un errore.");
          console.log(err);
        });
    },
    setDetail() {
      this.form.data_inizio = null;
      this.form.data_fine = null;
      this.selectedSlot = null;
      this.selectedCol = null;
      this.showDisponibilita = false;
      this.selectedPrestazione = null;
      this.health_service_id = null;
      this.form.filled_survey_id = null;
    },
    setRequestSubject(subject) {
      this.form.subject_id = subject.subject_id;
      this.form.subject_type = subject.subject_type;
    },
    setRequestType(type) {
      this.form.type = type;
      this.setDetail();
    },
    setRequestPrestazione(prestazione) {
      this.selectedPrestazione = prestazione;
      this.health_service_id = prestazione.hashid;
      this.form.data_inizio = null;
      this.setupCalendar();
    },
    setRequestTcSurvey(survey) {
      //console.log(survey);
      this.surveyToFill = survey;
      this.setupCalendar();
    },
    fetchUser() {
      var self = this;
      cittadinoService
        .getUserById(this.$route.params.hash)
        .then(function (res) {
          self.subject = res.data.data;
          self.setRequestSubject({
            subject_id: res.data.data.hashid,
            subject_type: "user_in_app",
          });
        })
        .catch(function (err) {
          console.log(err);
          self.notFound = true;
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare le informazioni.");
        });
    },
    formatFullDate(date) {
      return moment(date).format("dddd DD MMM YYYY HH:mm");
    },
  },
  created() {
    this.fetchUser();
    this.start = moment().startOf("week");
    this.end = moment().endOf("week");
    this.today = moment();
    this.month = moment().month();
    this.filters.from = moment().startOf("week").format("D/M/YY");
    this.filters.to = moment().endOf("week").format("D/M/YY");
  },
  mounted() {
    this.selectedCompany = this.user.company;
    this.form.company_id = this.user.company.hashid;
    this.validateService();
    this.nowDate = moment().add(5, "minutes");
    this.selectTcOptionDisabled =
      this.user.company.stato_servizi["connessa"] === 0;
  },
};
</script>
<style lang="scss">
.select__dis:disabled {
  background-color: #fff !important;
  color: inherit !important;
}
</style>