<template>
  <div v-if="detail" class="mb-3">
    <h5 class="font-weight-bolder text-secondary mt-4 ml-3">
      Dettagli dell'ordine
    </h5>
    <div class="mb-0">
      <div class="">
        <div v-if="detail.chart.length > 0">
          <h6 class="fw-bolder text-secondary mt-4 mt-1 ml-3">
            Prodotti richiesti
          </h6>
          <div class="p-3 general_border">
            <div class="fw-bolder" v-for="(item, ind) in detail.chart" :key="ind">

              <span> N° {{ item.quantity }} {{ item.quantity > 1 ? "confezioni " : "confezione " }}</span>
              <strong class="underlined">{{ item.nome }}</strong>
              <span v-if="item.aic"> - AIC: {{ item.aic }}</span>
              <span v-if="item.generic"> - <b> GENERICO</b></span>
              <br />
              <b-row cols="2" class="ml-2 my-2">
                <b-form-group class="d-flex fw-bolder">
                  <b-form-checkbox v-model="detail.chart[ind].prescription_required"
                    :name="'prescription_required' + ind" :value="1" :unchecked-value="null" class="mx-auto col-12"
                    :disabled="
                      Math.abs(stato) === 4 ||
                      Math.abs(stato) === 3 ||
                      Math.abs(stato) === 2
                    ">Prescrizione Necessaria</b-form-checkbox>
                </b-form-group>
                <b-form-group class="d-flex fw-bolder">
                  <b-form-checkbox v-model="detail.chart[ind].available" :name="'available' + ind" :value="0"
                    :unchecked-value="null" class="mx-auto col-12" :disabled="
                      Math.abs(stato) === 4 ||
                      Math.abs(stato) === 3 ||
                      Math.abs(stato) === 2
                    ">Prodotto non disponibile</b-form-checkbox>
                </b-form-group>
              </b-row>
              <b-row cols="2" class="ml-2">
                <span v-if="item.nre">
                  <strong class="text-secondary"> NRE: </strong>
                  <BarcodeShower tipo="nre" :code="detail.nre" />
                </span>
                <span v-if="item.rev">
                  <strong class="text-secondary"> REV: </strong>
                  <BarcodeShower tipo="rev" :code="detail.rev" />
                </span>
              </b-row>
            </div>
          </div>
        </div>
        <!-- <div v-else> -->
        <p v-if="detail.nre">
          <strong class="text-secondary"> NRE: </strong>
          <BarcodeShower tipo="nre" :code="detail.nre" />
        </p>
        <p v-if="detail.rev">
          <strong class="text-secondary"> REV: </strong>
          <BarcodeShower tipo="rev" :code="detail.rev" />
        </p>
        <p v-if="detail.allegato !== null && picLink">
          <strong>Allegato</strong><br />
          <b-img ref="allegato" :src="picLink" no-body fluid-grow width="100%" height="100%" />
        </p>

        <div>
          <b-row cols="2" class="ml-2" v-if="detail.nre || detail.rev">
            <b-form-group class="d-flex fw-bolder">
              <b-form-checkbox v-model="detail.available" name="availabled" :value="0" :unchecked-value="null"
                class="mx-auto col-12" :disabled="
                  Math.abs(stato) === 4 ||
                  Math.abs(stato) === 3 ||
                  Math.abs(stato) === 2
                ">Prodotti non disponibili</b-form-checkbox>
            </b-form-group>
          </b-row>
        </div>

        <!-- <div class="mt-2 mb-4" v-if="
          Math.abs(stato) !== 4 &&
          Math.abs(stato) !== 3 &&
          Math.abs(stato) !== 2
        ">
          <h6 class="fw-bolder text-secondary mt-4 ml-3">
            Modifica giorno ed ora
          </h6>
          <b-row cols="1" cols-lg="2" class="align-items-center fw-bolder flex-wrap">
            <b-col class="
                d-flex
                align-items-center
                pl-0
                
                my-1
              ">
              <b-form-datepicker class="shadow_6" placeholder="Imposta data" locale="it" today-button reset-button
                close-button label-today-button="Oggi" label-close-button="Chiudi" label-reset-button="Scegli giorno"
                start-weekday="1" close-button-variant="outline-danger" reset-button-variant="danger"
                today-button-variant="primary" hide-header :min="min" v-model="newDate" :state="checkValidity">
              </b-form-datepicker>
            </b-col>
            <b-col class="ml-0 pl-0 col-6 col-md-5 col-lg-4 col-xl-3 my-1">
              <b-form-input class="h-100 shadow_6" type="time" v-model="newTime" :state="checkValidity"></b-form-input>
            </b-col>
          </b-row>

          <b-row cols="1">
            <b-col class="pl-0">
              <b-form-group class="">
                <label class="fw-bolder text-secondary mt-4 ml-3" for=" ">
                  Nota per il cliente:
                </label>
                <b-form-input v-model="company_notes" class="shadow_6" type="text">
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </div> -->

        <p v-if="detail.details">
          Dettagli:
          <strong>{{ detail.details }} </strong>
        </p>
      </div>
      <div class="d-flex flex-wrap justify-content-center my-2" v-if="Math.abs(stato) !== 4">
        <b-button v-if="editBtn && checkValidity" @click="updateRequest" type="button"
          variant="info info_gradient fw-bolder text-white my-1 ml-0 mr-auto ml-md-0 mr-md-auto rounded-pill">Invia
          Risposta</b-button>
        <b-button v-else-if="stato === 1 || stato === 6" @click="confirmRequest" type="button"
          variant="primary primary_gradient fw-bolder text-white my-1 ml-0 mr-auto ml-md-0 mr-md-auto rounded-pill">
          Conferma Ordine</b-button>
        <b-button v-if="stato === 1" @click="setAction('reject')" type="button"
          variant="outline-danger fw-bolder mr-md-0 ml-md-auto my-1 ml-0 mr-auto rounded-pill">Declina Ordine</b-button>
        <b-button v-if="stato > 1" @click="setAction('cancel')" type="button"
          variant="outline-danger fw-bolder mr-md-0 ml-md-auto my-1 ml-0 mr-auto rounded-pill">Annulla Ordine</b-button>
      </div>
    </div>
    <b-modal size="xl" hide-footer centered ref="modal-confirm" title="Conferma Annullamento">
      <p class="mb-0">Sei sicuro di voler annullare questo appuntamento?</p>
      <div>
        <b-form-group label="Allega messaggio:" v-slot="{ ariaDescribedby }">
          <b-form-radio class="my-1" v-model="message" :aria-describedby="ariaDescribedby" :value="1">"{{ msgOptions[1]
          }}"</b-form-radio>
          <b-form-radio class="my-1" v-model="message" :aria-describedby="ariaDescribedby" :value="2">"{{ msgOptions[2]
          }}"</b-form-radio>
          <b-form-radio class="my-1" v-model="message" :aria-describedby="ariaDescribedby" :value="3">"{{ msgOptions[3]
          }}"</b-form-radio>
          <b-form-radio class="my-1" v-model="message" :aria-describedby="ariaDescribedby" :value="4">"{{ msgOptions[4]
          }}"</b-form-radio>
          <b-form-radio v-model="message" :aria-describedby="ariaDescribedby" :value="6">nessun messaggio</b-form-radio>
          <b-form-radio v-model="message" :aria-describedby="ariaDescribedby" :value="5">scrivi messaggio</b-form-radio>
          <b-form-input v-if="message == 5" class="simple__input border-top-0 border-left-0 border-right-0"
            name="freeText" type="text" placeholder="scrivi messaggio..." v-model="freeText"></b-form-input>
        </b-form-group>
      </div>
      <div class="d-flex justify-content-end">
        <b-button @click="$refs['modal-confirm'].hide()" type="button" variant="info fw-bolder ">No, chiudi</b-button>
        <b-button @click="cancelRequest" type="button" variant="outline-danger fw-bolder ml-2">Sì, procedi</b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import * as moment from "moment/moment";
import { reservationService } from "@/_services";
import BarcodeShower from "@/components/utilities/BarcodeShower.vue";
export default {
  name: "DetailProdotto",
  props: ["dettaglio", "stato", "datainizio"],
  components: { BarcodeShower },
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const minDate = new Date(today);
    return {
      picLink: null,
      message: 6,
      msgOptions: {
        1: "Ci spiace comunicarLe che non possiamo gestire ulteriori richieste per la data selezionata. La preghiamo di effettuare una nuova prenotazione per la settimana successiva alla data scelta.",
        2: "Ci spiace comunicarLe che a causa di problematiche relative all'approvvigionamento non possiamo gestire la sua richiesta per la data selezionata.",
        3: "Ci spiace comunicarLe che non possiamo gestire la sua richiesta per mancanza di dati essenziali. La preghiamo di completare la sua scheda anagrafica.",
        4: "Si è verificato un imprevisto che ci costringe a disdire il suo appuntamento. Se lo desidera, ci chiami per ulteriori informazioni.",
        5: null,
        6: null,
      },
      freeText: null,
      actionName: null,
      loading: false,
      detail: this.dettaglio,
      editBtn: false,
      newDate: this.datainizio,
      newTime: moment(this.datainizio).format("HH:mm"),
      company_notes: null,
      min: minDate,
    };
  },
  computed: {
    checkValidity: function () {
      var mow = moment();
      var daTe = moment(this.newDate);
      var tiMe = moment(this.newTime, "HH:mm");
      daTe.hours(tiMe.hours());
      daTe.minutes(tiMe.minutes());
      return moment(mow).isBefore(daTe);
    },
  },
  watch: {
    dettaglio: {
      handler(val) {
        this.$nextTick(function () {
          this.detail = val;
          if (val.resource_id !== null) {
            this.getAllegato();
          }
        });
      },
      deep: true,
    },
    detail: {
      handler(val) {
        var showBtn = false;
        if (this.stato > 4) {
          this.editBtn = true;
        } else {
          Object.keys(val.chart).forEach(function (el) {
            if (
              val.chart[el].prescription_required !==
              val.chart_items[el].prescription_required ||
              val.chart[el].available !== val.chart_items[el].available
            ) {
              showBtn = true;
            }
          });
          if (val.available !== null || val.prescription_required !== null) {
            showBtn = true;
          }
          this.editBtn = showBtn;
        }
        // this.editBtn = true;
      },
      deep: true,
    },
    newDate(val) {
      this.editBtn = true;
    },
    newTime(val) {
      this.editBtn = true;
    },
    company_notes(val) {
      this.editBtn = true;
    },
  },
  methods: {
    getAllegato() {
      var self = this;
      var endpoint =
        this.detail.allegato.full_path + "/" + this.detail.allegato.hashid;
      reservationService
        .getAllegato(endpoint)
        .then(function (res) {
          self.picLink = window.URL.createObjectURL(new Blob([res.data]));
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    confirmRequest() {
      // //console.log(this.detail.hashid);
      var data = {
        confirmed: true,
        action: "confirm",
      };
      this.$emit("update-request-status", data);
    },
    updateRequest() {
      // //console.log(this.detail.hashid);
      var daTe = moment(this.newDate);
      var tiMe = moment(this.newTime, "HH:mm");
      daTe.hours(tiMe.hours());
      daTe.minutes(tiMe.minutes());
      var datetime = daTe.format("YYYY-MM-DD HH:mm");
      // //console.log(daTe.format("YYYY-MM-DD HH:mm"));
      var dataToSend = {
        detail: this.detail,
        newDateTime: datetime,
        company_notes: this.company_notes,
      };
      this.$emit("update-request-response", dataToSend);
    },
    setAction(action) {
      this.actionName = action;
      this.$refs["modal-confirm"].show();
    },
    cancelRequest() {
      this.loading = true;
      var data = {
        confirmed: false,
        message: this.freeText,
        action: this.actionName,
      };
      // //console.log(data);
      if (this.message !== null && this.message !== 5) {
        data = {
          confirmed: false,
          message: this.msgOptions[this.message],
          action: this.actionName,
        };
      }
      if (this.message === 6) {
        data = {
          confirmed: false,
          message: null,
          action: this.actionName,
        };
      }
      this.$refs["modal-confirm"].hide();
      this.$emit("update-request-status", data);
    },
  },
  created() {
    if (this.detail && this.detail.allegato !== null) {
      this.getAllegato();
    }
    // this.newTime = moment(this.datainizio).format("HH:mm");
  },
};
</script>
