<template>
  <div>
    <h6 class="
        text-prim-grad-1
        fw-bolder
        text-left
        pb-1
        border-bottom border-line-grey
        mt-3
      ">
      Notifiche per terapie
    </h6>
    <div class="col-12 text-left px-0 px-md-2">
      <p class="text-medium-grey text-justify font-weight-bolder">
        Clicca su un giorno per visualizzarne e gestirne lo stato delle
        notifiche. Clicca su "elimina piano" per eliminare tutte le notifiche
        previste per il piano terapeutico, "modifica piano" per modificare le
        informazioni relative a scorta e data inizio terapia.
      </p>
    </div>
    <b-row class="mx-0" cols="1" v-for="(alertGroup, ind) in therapyAlerts" :key="ind">
      <TherapyAlert :alertGroup="alertGroup" :therapyName="ind" v-on:do-update="fetchData" />
    </b-row>
    <b-row cols="1" class="mt-3">
      <b-col>
        <p class="">
          <b-iconstack font-scale="3.5" class="shadow_7 rounded-circle point" @click="
            $router.push({
              name: 'nuova notifica paziente',
              params: { hash: $route.params.hash },
            })
          ">
            <b-icon class="shadow_7" stacked icon="circle-fill" variant="secondary"></b-icon>
            <b-icon stacked icon="plus" scale="0.5" variant="white"></b-icon>
          </b-iconstack>
        </p>
      </b-col>
    </b-row>
    <b-row cols="1" class="my-2">
      <p>
        <span v-for="(stat, ind) in statusColors" :key="stat" class="mx-1">
          <b-icon font-scale="1.2" class="" icon="circle-fill" :variant="stat"></b-icon>
          <strong> {{ status[ind] }}</strong>
        </span>
      </p>
    </b-row>
  </div>
</template>
<script>
import { supportService } from "@/_services";
import { mapState, mapActions } from "vuex";
import TherapyAlert from "@/components/profile/sanitario/TherapyAlert";
export default {
  name: "SettedAlerts",
  props: ["subject"],
  components: { TherapyAlert },
  data() {
    return {
      therapyAlerts: null,
      status: {
        0: "programmata",
        1: "invio in corso",
        2: "inviata",
        3: "annullata",
        4: "errore",
      },
      statusColors: {
        0: "secondary",
        1: "primary",
        2: "success",
        3: "warning",
        4: "danger",
      },
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    fetchData() {
      //console.log("Fetching..");
      var self = this;
      supportService
        .getUserAlerts(this.subject.hashid)
        .then(function (response) {
          self.therapyAlerts = response.data.data.therapy;
        })
        .catch(function (error) {
          self.errorAlert("Non è stato possibile recuperare le notifiche");
        });
    },
  },
  created() {
    this.fetchData();
  },
};
</script>