<template>
  <div class="py-2">
    <p class="text-left text-font-grey fs-5 mb-0">
      Scarica report delle prenotazioni
      {{ servizio ? "per " + servizio.name : "" }} in formato csv.
    </p>
    <b-form method="post" @submit.prevent="submitRequest">
      <div class="my-2 d-flex align-items-center flex-wrap">
        <b-form-group label="Report prenotazioni con stato" label-for="type"
          class="col-lg-4 col-sm-5 text-left fs-6 text-secondary">
          <b-form-select name="type" class="mx-auto shadow_6" :options="filterOptions.status" v-model="form.status"
            required>
            <b-form-select-option :value="null">scegli tipo</b-form-select-option>
          </b-form-select>
        </b-form-group>
        <b-form-group label="Formato download" label-for="type" class="col-lg-4 col-sm-5 text-left fs-6 text-secondary">
          <b-form-select name="type" class="mx-auto shadow_6" v-model="form.format" required>
            <b-form-select-option value="csv">csv</b-form-select-option>
            <b-form-select-option value="xlsx">xlsx</b-form-select-option>
            <b-form-select-option value="pdf">pdf</b-form-select-option>
          </b-form-select>
        </b-form-group>
      </div>
      <div class="my-2 d-flex align-items-center flex-wrap">
        <!--   <b-form-group
          label="Di tipo"
          label-for="type"
          class="col-lg-4 col-sm-5 text-left fs-6 text-secondary"
        >
          <b-form-select
            name="type"
            class="mx-auto shadow_6 bg-white"
            :options="filterOptions.service"
            v-model="form.order_type"
            required
            disabled
          >
            <b-form-select-option :value="null"
              >scegli tipo</b-form-select-option
            ></b-form-select
          >
        </b-form-group>-->
        <b-form-group v-if="
          form.order_type === 'prestazione' && !form.subtype_hash && servizio
        " label="Tipo Prestazione" label-for="subtype" class="col-lg-4 col-sm-5 text-left fs-6 text-secondary">
          <b-form-select name="subtype" class="mx-auto shadow_6" v-model="form.subtype_hash">
            <b-form-select-option v-for="(hash, name) in azienda.prestazioni_attive" :key="name" :value="hash">{{ name
            }}</b-form-select-option>
            <b-form-select-option :value="null">tutte</b-form-select-option>
          </b-form-select>
        </b-form-group>
      </div>
      <div class="my-2 d-flex align-items-center flex-wrap">
        <b-form-group label="Per i giorni dal" label-for="data_from"
          class="col-lg-5 col-sm-6 text-left fs-6 text-secondary">
          <b-form-input name="data_from" class="mx-auto shadow_6" type="date" v-model="form.data_from"
            :state="validateDate" required></b-form-input>
        </b-form-group>
        <b-form-group label="Al" label-for="data_to" class="col-lg-5 col-sm-6 text-left fs-6 text-secondary">
          <b-form-input name="data_to" class="mx-auto shadow_6" type="date" v-model="form.data_to" :state="validateDate"
            required></b-form-input>
        </b-form-group>
      </div>
      <div class="ml-4">
        <b-button size="sm" :class="$root.isMobile ? 'mx-auto text-center' : ''"
          variant="primary text-white primary_gradient fw-bolder rounded-pill" type="submit">Conferma</b-button>
      </div>
    </b-form>
  </div>
</template>
<script>
import * as moment from "moment/moment";
import { adminService } from "@/_services";
import { mapState, mapActions } from "vuex";
export default {
  name: "CompanyReport",
  props: ["azienda", "servizio", "tipo"],
  data() {
    return {
      form: {
        status: "ricevute",
        data_from: null,
        data_to: null,
        order_type: "prestazione",
        subtype_hash: null,
        company: this.azienda.hashid,
        format: "csv",
      },
      filterOptions: {
        status: ["ricevute", "confermate", "evase"],
        service: ["prestazione"],
        // service: ["prestazione", "prodotto", "tutte"],
      },
    };
  },
  computed: {
    validateDate: function () {
      if (this.form.data_from) {
        if (!this.form.data_to) {
          return null;
        }
        var from = moment(this.form.data_from);
        var to = moment(this.form.data_to);
        return moment(from).isSameOrBefore(to);
      }
      return null;
    },
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    // validateDate() {
    //   if (this.form.data_from) {
    //     var from = moment(this.form.data_from);
    //     var to = moment(this.form.data_to);
    //     //console.log("from", from);
    //     //console.log("to", to);
    //     return true;
    //     return moment(to).isSameOrBefore(from);
    //   }
    //   return null;
    // },
    submitRequest(e) {
      // //console.log("this.data", this.form);
      var servName = this.servizio ? this.servizio.name : this.form.order_type;
      var dal = moment(this.form.data_from).format("DD/MM/YYYY");
      var al = moment(this.form.data_to).format("DD/MM/YYYY");
      var self = this;
      adminService
        .massiveReport(this.form)
        .then(function (response) {
          // //console.log(response);
          let blob = new Blob([response.data]);
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download =
            self.form.status +
            "_" +
            servName +
            "_dal" +
            dal +
            "_al" +
            al +
            "." +
            self.form.format;
          link.click();
          self.form.data_from = null;
          self.form.data_to = null;
          self.form.status = "ricevute";
          self.form.format = "csv";
          // self.form = {
          //   status: "ricevute",
          //   data_from: null,
          //   data_to: null,
          //   order_type: "prestazione",
          //   subtype_hash: null,
          // };
        })
        .catch(function (error) {
          if (error.status === 413) {
            window.scrollTo(0, 0);
            self.infoAlert(
              "Il report verrà inviato via mail ad elaborazione completata"
            );
            self.form.data_from = null;
            self.form.data_to = null;
            self.form.status = "ricevute";
            self.form.format = "csv";
            self.$emit("doclose-modal");
          } else {
            window.scrollTo(0, 0);
            self.errorAlert("Nessuna prenotazione da scaricare");
          }
        });
    },
  },
  watch: {
    azienda: function (val) {
      // //console.log("azienda val changed", val);
    },
  },
  mounted() {
    // //console.log("AZIENDA", this.azienda);
    // //console.log(" len", this.azienda.length);
    if (this.servizio) {
      this.form.subtype_hash = this.servizio.hashid;
    }
    if (this.tipo) {
      this.form.order_type = this.tipo;
    }
    if (this.azienda.length) {
      this.form.company = this.azienda.map(function (a) {
        // //console.log(a.hashid);
        return a.hashid;
      });
    }
    // //console.log("Company", this.form.company);
    // if (
    //   this.$route.query.scope &&
    //   this.filterOptions.service.includes(this.$route.query.scope)
    // ) {
    //   this.form.order_type = this.$route.query.scope;
    // } else {
    //   this.form.order_type = "tutte";
    // }
  },
};
</script>