var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',[_c('b-row',{staticClass:"text-left mb-3",attrs:{"cols":"1"}},[_c('router-link',{staticClass:"fw-bolder text-primary no__hover",attrs:{"to":{ name: 'CittadinoHome' }}},[_c('b-icon',{attrs:{"icon":"arrow-left-circle-fill","variant":"primary"}}),_vm._v(" Homepage")],1)],1),_c('b-row',{staticClass:"col-12 justify-content-center"},[_c('b-button-group',{staticClass:"mb-3 bg-line-grey rounded-pill"},[_c('b-button',{staticClass:"fw-bolder text-capitalize",attrs:{"size":_vm.$root.isMobile ? 'sm' : '',"variant":_vm.activePanel === 'rilevazioni'
            ? 'primary primary_gradient text-white rounded-pill'
            : 'bg-light text-primary'},on:{"click":function($event){_vm.activePanel = 'rilevazioni'}}},[_vm._v("rilevazioni")]),_c('b-button',{staticClass:"fw-bolder text-capitalize",attrs:{"size":_vm.$root.isMobile ? 'sm' : '',"variant":_vm.activePanel === 'patologie'
            ? 'primary primary_gradient text-white rounded-pill'
            : 'bg-light text-primary'},on:{"click":function($event){_vm.activePanel = 'patologie'}}},[_vm._v("patologie")]),_c('b-button',{staticClass:"fw-bolder text-capitalize",attrs:{"size":_vm.$root.isMobile ? 'sm' : '',"variant":_vm.activePanel === 'therapies'
            ? 'primary primary_gradient text-white rounded-pill'
            : 'bg-light text-primary'},on:{"click":function($event){_vm.activePanel = 'therapies'}}},[_vm._v("armadietto")]),_c('b-button',{staticClass:"fw-bolder text-capitalize",attrs:{"size":_vm.$root.isMobile ? 'sm' : '',"variant":_vm.activePanel === 'pic_plan'
            ? 'primary primary_gradient text-white rounded-pill'
            : 'bg-light text-primary'},on:{"click":function($event){_vm.activePanel = 'pic_plan'}}},[_vm._v("piani terapeutici")])],1)],1),_c('transition-group',{attrs:{"name":"list","mode":"out-in","appear":""}},[(_vm.activePanel === 'rilevazioni')?_c('b-row',{key:"rilevazioni"},[_c('RilevazioniComponent',{attrs:{"subject":_vm.subject}})],1):_vm._e(),(_vm.activePanel === 'patologie')?_c('b-row',{key:"patologie"},[_c('h6',{staticClass:"text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey ml-3 mt-3"},[_vm._v(" Patologie ")]),_c('PatologieComponent',{attrs:{"subject":_vm.subject}})],1):_vm._e(),(_vm.activePanel === 'therapies')?_c('b-row',{key:"therapies"},[_c('ArmadiettoComponent',{attrs:{"subject":_vm.subject}})],1):_vm._e(),(_vm.activePanel === 'pic_plan')?_c('b-row',{key:"pic_plan"},[_c('PianiTerapeutici')],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }