<template>
  <b-row cols="1" class="my-1">
    <b-col v-if="company && list">
      <b-form-group
        label="Scegli prestazione"
        label-for="subtype"
        class="text-left fs-6 text-secondary"
        :description="
          company.prestazioni_sanitarie.length > 0
            ? ''
            : 'Nessuna prestazione disponibile presso la sede selezionata'
        "
      >
        <b-form-select
          name="subtype"
          class="mx-auto shadow_6"
          v-model="prestazione"
          required
          @change="emitPrestazione"
        >
          <template v-for="(prestazione, name) in list">
            <b-form-select-option
              :key="name"
              v-if="prestazione.active === 1"
              :value="prestazione"
              :disabled="
                !prestazione.disponibilita ||
                prestazione.disponibilita.length < 1
              "
              >{{ prestazione.name }}
              {{
                !prestazione.disponibilita ||
                prestazione.disponibilita.length < 1
                  ? "-non disponibile"
                  : ""
              }}</b-form-select-option
            >
          </template>
          <b-form-select-option :value="null" disabled></b-form-select-option>
        </b-form-select>
      </b-form-group>
    </b-col>
    <b-col v-else-if="company">
      <b-form-group
        label="Scegli prestazione"
        label-for="subtype"
        class="text-left fs-6 text-secondary"
        :description="
          company.prestazioni_sanitarie.length > 0
            ? ''
            : 'Nessuna prestazione disponibile presso la sede selezionata'
        "
      >
        <b-form-select
          name="subtype"
          class="mx-auto shadow_6"
          v-model="prestazione"
          required
          @change="emitPrestazione"
        >
          <template
            v-for="(prestazione, name) in company.prestazioni_sanitarie"
          >
            <b-form-select-option
              :key="name"
              v-if="prestazione.active === 1"
              :value="prestazione"
              :disabled="
                !prestazione.disponibilita ||
                prestazione.disponibilita.length < 1
              "
              >{{ prestazione.name }}
              {{
                !prestazione.disponibilita ||
                prestazione.disponibilita.length < 1
                  ? "-non disponibile"
                  : ""
              }}</b-form-select-option
            >
          </template>
          <b-form-select-option :value="null" disabled></b-form-select-option>
        </b-form-select>
      </b-form-group>
    </b-col>
  </b-row>
</template>
<script>
import { supportService } from "@/_services"
export default {
  name: "PrestazioneSelection",
  props: ["company", "preselected"],
  data() {
    return {
      prestazione: null,
      list: null
    }
  },
  watch: {
    preselected: {
      handler(value) {
        this.prestazione = value
      },
      deep: true
    }
  },
  methods: {
    emitPrestazione() {
      this.$emit("selected-prestazione", this.prestazione)
    },
    getFreshData() {
      var self = this
      supportService
        .getCompanyHealthServices(this.company.hashid)
        .then(function (res) {
          self.list = res.data.data
          // console.log(res.data.data)
        })
        .catch(function (err) {
          console.log(err)
        })
    }
  },
  created() {
    //TO DO call companyService.getOwnHealthServices(this.filters)
    this.prestazione = this.preselected
    this.getFreshData()
  }
}
</script>
