<template>
  <div class="py-4">
    <div v-if="user.type === 1">
      <div>
        <b-button-group class="mb-3 bg-line-grey rounded-pill">
          <b-button
            class="fw-bolder text-capitalize"
            :size="$root.isMobile ? 'sm' : ''"
            @click="activePanel = 'prestazioni'"
            :variant="
              activePanel === 'prestazioni'
                ? 'info info_gradient text-white rounded-pill'
                : 'bg-light text-info'
            "
            >prestazioni</b-button
          >
          <!-- disattivato per il solo prestazioni -->
          <b-button
            v-if="!$root.prestazioniOnly"
            class="fw-bolder text-capitalize"
            :size="$root.isMobile ? 'sm' : ''"
            @click="activePanel = 'servizi'"
            :variant="
              activePanel === 'servizi'
                ? 'info info_gradient text-white rounded-pill'
                : 'bg-light text-info'
            "
            >altri servizi</b-button
          >
          <b-button
            class="fw-bolder text-capitalize"
            :size="$root.isMobile ? 'sm' : ''"
            @click="activePanel = 'apertura'"
            :variant="
              activePanel === 'apertura'
                ? 'info info_gradient text-white rounded-pill'
                : 'bg-light text-info'
            "
            >orari apertura</b-button
          >
          <b-button
            class="fw-bolder text-capitalize"
            :size="$root.isMobile ? 'sm' : ''"
            @click="activePanel = 'prohibidates'"
            :variant="
              activePanel === 'prohibidates'
                ? 'info info_gradient text-white rounded-pill'
                : 'bg-light text-info'
            "
            >indisponibilità</b-button
          >
          <b-button
            class="fw-bolder text-capitalize"
            :size="$root.isMobile ? 'sm' : ''"
            @click="activePanel = 'others'"
            :variant="
              activePanel === 'others'
                ? 'info info_gradient text-white rounded-pill'
                : 'bg-light text-info'
            "
            >altro</b-button
          >
        </b-button-group>
      </div>
      <div class="mx-auto">
        <transition-group name="list" mode="in-out">
          <div v-if="activePanel === 'apertura' && showOrari" key="apertura">
            <h6
              class="text-justify fw-bolder text-medium-grey my-3 bg-light py-2 px-3 general_border"
            >
              Su <strong class="text-info">"Orari Apertura"</strong> puoi
              inserire le informazioni relative ai tuoi orari di apertura al
              pubblico, che saranno visibili agli utenti a titolo puramente
              informativo.
            </h6>
            <h6
              class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
            >
              Orari di Apertura
            </h6>
            <p class="text-left text-medium-grey fs-5">
              Orari di apertura al pubblico.
            </p>
            <SettingOrari
              :orari="company.company_openings"
              v-on:store-openings="submitOpenings($event)"
            />
          </div>
          <div v-if="activePanel === 'servizi' && showServizi" key="servizi">
            <h6
              class="text-justify fw-bolder text-medium-grey my-3 bg-light py-2 px-3 general_border"
            >
              Nella sezione
              <strong class="text-info">"Altri Servizi"</strong> puoi gestire
              stato attivazione ed orari di disponibilità per tutti gli altri
              servizi, quali ad esempio gli ordini di farmaci e prodotti
              ("Facile - Ordini") e le attività per il teleconsulto.
            </h6>
            <div
              class="my-2"
              v-for="(aperture, servizio) in company.servizi_attivi_orari"
              :key="servizio"
            >
              <div v-if="company.servizi_attivi[servizio]">
                <h5
                  class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
                >
                  Orari
                  <span class="text-capitalize">{{
                    servizio === "facile" ? "facile - ordini" : servizio
                  }}</span>
                </h5>
                <p
                  class="text-left text-medium-grey fs-5"
                  v-if="servizio === 'facile'"
                >
                  Orari, giorni e intervalli di disponibilità per gli ordini di
                  farmaci attraverso il servizio facile - ordini.
                </p>
                <p class="text-left text-medium-grey fs-5" v-else>
                  Orari, giorni e intervalli di ricevimento per le attività di
                  {{ servizio }}.
                </p>
                <!-- v-if="servizio !== 'facile'" -->
                <template
                  v-if="servizio === 'connessa' && !company.tc_available"
                >
                  <h6>
                    Servizio temporaneamente sospeso per il raggiungimento della
                    soglia limite di 50 minuti di utilizzo al mese.
                  </h6>
                </template>
                <template v-else>
                  <ServiceStatus
                    :statoservizio="
                      company.stato_servizi[servizio] == true ? 1 : 0
                    "
                    :servicename="servizio"
                    v-on:store-service-status="submitStatusChange($event)"
                  />
                  <div v-if="company.stato_servizi[servizio] == true">
                    <transition name="fade" mode="out-in" appear>
                      <SettingServiceOrari
                        :orari="company.servizi_attivi_orari[servizio]"
                        :servicename="servizio"
                        v-on:store-service-openings="
                          submitServiceOpenings($event)
                        "
                      />
                    </transition>
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div
            class="mx-auto"
            v-if="activePanel === 'prohibidates'"
            key="prohibidates"
          >
            <h6
              class="text-justify fw-bolder text-medium-grey my-3 bg-light py-2 px-3 general_border"
            >
              Nella sezione
              <strong class="text-info">"Indisponibilità"</strong> puoi
              impostare delle date di interruzione dei servizi, ad esempio per i
              giorni festivi, per le quali non sarà possibile prenotare nessun
              servizio.
            </h6>
            <h6
              class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
            >
              Date di indisponibilità a tutti i servizi
            </h6>
            <ProhibidatesComponent />
          </div>
          <div class="mx-auto" v-if="activePanel === 'others'" key="others">
            <h6 class="text-justify fw-bolder text-medium-grey my-3">
              Altre impostazioni sui servizi DottorFARMA
            </h6>
            <OtherSettings />
          </div>
          <div
            class="mx-auto"
            v-if="activePanel === 'prestazioni'"
            key="prestazioni"
          >
            <h6
              class="text-justify fw-bolder text-medium-grey my-3 bg-light py-2 px-3 general_border"
            >
              Nella sezione
              <strong class="text-info">"Prestazioni"</strong> puoi creare nuove
              prestazioni personalizzate, impostare gli orari di disponibilità
              ed attivare o disattivare ciascuna prestazione in qualsiasi
              momento.
            </h6>
            <h6
              class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
            >
              Facile - Prestazioni Sanitarie
            </h6>
            <p class="text-justify text-medium-grey fs-5">
              La sezione orari per "prestazioni" consente di gestire attività
              quali appuntamenti fisici per prestazioni sanitarie. Tali
              impostazioni consentono di selezionare, oltre a giorni e fasce
              orarie, anche un limite giornaliero di appuntamenti ed una durata
              in minuti per appuntamento. Clicca su "gestisci orari" per
              impostare giorni ed orari di disponibilità per ciascuna
              prestazione. Puoi inoltre caricare e gestire moduli associati a
              ciascuna prestazione cliccando su "allegati": i moduli caricati
              saranno visualizzabili e scaricabili dagli utenti in ciascuna
              prenotazione per la rispettiva prenotazione. Se invece vuoi
              associare ad una prestazione un questionario da te creato per
              Facile - prestazioni, clicca su "questionario", seleziona la tua
              scelta e conferma.
            </p>
            <PrestazioniSanitarie />
          </div>
        </transition-group>
      </div>
      <!-- versione full -->
      <!-- <div class="mx-auto mt-2">
        <p class="text-left text-secondary">
          <span class="mr-1"
            ><b-icon icon="info-circle" font-scale="1.5"> </b-icon></span
          >La sezione orari è suddivisa in tre macro categorie: su "apertura" è
          possibile impostare gli orari di apertura aziendali, su "servizi" si
          possono invece impostare le fasce orarie e lo stato di attività
          relativi ai servizi di teleconsulto e di ricezione ordini. L'area
          "prestazioni" consente di gestire attività quali appuntamenti fisici
          per prestazioni sanitarie. Le impostazioni di servizi e prestazioni
          consentono di selezionare, oltre a giorni e fasce orarie, anche un
          limite giornaliero di appuntamenti ed una durata in minuti per
          appuntamento.
        </p>
      </div> -->
      <div class="mx-auto mt-2">
        <!-- <p class="text-left text-secondary">
          <span class="mr-1"
            ><b-icon icon="info-circle" font-scale="1.5"> </b-icon></span
          >La sezione orari per "prestazioni" consente di gestire attività quali
          appuntamenti fisici per prestazioni sanitarie. Tali impostazioni
          consentono di selezionare, oltre a giorni e fasce orarie, anche un
          limite giornaliero di appuntamenti ed una durata in minuti per
          appuntamento.
        </p> -->
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import SettingOrari from "@/components/impostazioni/SettingOrari.vue";
import ServiceStatus from "@/components/impostazioni/ServiceStatus.vue";
import SettingServiceOrari from "@/components/impostazioni/SettingServiceOrari.vue";
import AnagraficaCompany from "@/components/impostazioni/AnagraficaCompany.vue";
import AnagraficaCompanyDF from "@/components/impostazioni/AnagraficaCompanyDF.vue";
import PrestazioniSanitarie from "@/components/impostazioni/PrestazioniSanitarie.vue";
import ProhibidatesComponent from "@/components/impostazioni/ProhibidatesComponent.vue";
import OtherSettings from "@/components/impostazioni/OtherSettings.vue";
import AccessoEPermessi from "@/components/profile/AccessoEPermessi.vue";

export default {
  name: "OrariCompany",
  components: {
    AccessoEPermessi,
    SettingOrari,
    SettingServiceOrari,
    ServiceStatus,
    AnagraficaCompany,
    AnagraficaCompanyDF,
    PrestazioniSanitarie,
    ProhibidatesComponent,
    OtherSettings,
  },
  computed: {
    showOrari() {
      return this.company && this.company.company_openings ? true : false;
    },
    showServizi() {
      return this.company && this.company.servizi_attivi_orari ? true : false;
    },
    showStati() {
      return this.company && this.company.stato_servizi ? false : true;
    },
    ...mapState("utente", ["status", "user"]),
    ...mapState("company", ["companyLoggedIn", "company"]),
    // ...mapGetters("company", ["getCompany", "getStatoServizi"]),
    ...mapGetters({
      getStatoServizi: "company/getStatoServizi",
      getServiceOpenings: "company/getServiceOpenings",
      getCompany: "company/getCompany",
    }),
  },
  data() {
    return {
      serviceStatus: null,
      serviceToUpdate: null,
      activePanel: "prestazioni",
    };
  },
  watch: {
    activePanel: function (value) {
      // //console.log("active panel", value);
      // if (value === "servizi" || value === "prestazioni") {
      this.logUserCompanyIn();
      // this.company.servizi_attivi_orari = this.getServiceOpenings;
      // }
    },
  },
  methods: {
    ...mapActions("utente", ["me"]),
    ...mapActions("company", [
      "storeOpenings",
      "storeServiceOpenings",
      "updateServiceStatus",
      "logUserCompanyIn",
    ]),
    submitOpenings(dataToSubmit) {
      this.storeOpenings(dataToSubmit).then(function () {
        window.scrollTo(0, 0);
      });
    },
    submitServiceOpenings(dataToSubmit) {
      // //console.log("service data to submit", dataToSubmit);
      var serviceHash = this.company.servizi_attivi[dataToSubmit.service_name];
      dataToSubmit.service_hash = serviceHash;
      this.storeServiceOpenings(dataToSubmit).then(function () {
        window.scrollTo(0, 0);
      });
    },
    submitStatusChange(dataToSubmit) {
      // //console.log("service status data to submit", dataToSubmit);
      var serviceHash = this.company.servizi_attivi[dataToSubmit.service_name];
      dataToSubmit.service_hash = serviceHash;
      var self = this;
      this.updateServiceStatus(dataToSubmit).then(function () {
        window.scrollTo(0, 0);
        self.logUserCompanyIn();
        // self.company.servizi_attivi_orari = self.getServiceOpenings;
      });
      // .then(function () {
      //   window.scrollTo(0, 0);
      //   // self.company.servizi_attivi_orari = self.getServiceOpenings;
      // });
    },
    submitAnagrafica(dataToSubmit) {
      // //console.log("anagrafica data to submit", dataToSubmit);
      // var serviceHash = this.company.servizi_attivi[dataToSubmit.service_name];
      // dataToSubmit.service_hash = serviceHash;
      // this.storeServiceOpenings(dataToSubmit).then(function () {
      //   window.scrollTo(0, 0);
      // });
    },
  },
  created() {
    this.me();
  },
  // mounted() {
  //   //console.log("mounted, company", this.company);
  // },
};
</script>
