<template>
  <div v-if="response && !readnre" class="my-2">
    <span class="primary fw-bolder">File </span>
    <b-badge variant="info" class="mx-2 py-1 px-2">{{ response.name }}</b-badge>
    <span class="primary fw-bolder"> caricato correttamente!</span>

    <span class="text-primary fw-bolder point ml-1" v-if="!encr" @click="refreshAll">Carica nuovo file</span>
  </div>
  <div v-else-if="response && readnre" class="my-2">
    <span v-if="response.nre" class="primary fw-bolder">Codice rilevato correttamente!
    </span>
    <span v-else class="text-danger">Non è stato possibile rilevare il codice dalla ricetta caricata. Si prega
      di provare a scannerizzare il codice tramite videocamera o inserire
      manualmente il codice nell'apposito campo.</span>
  </div>
  <div class="d-flex align-content-center justify-content-between flex-wrap my-2" v-else>
    <b-form-file v-model="form.file" :accept="encr ? '.pdf' : null" ref="file-up" placeholder="seleziona file..."
      :class="$root.isMobile ? 'col-12 mb-2' : 'col-8'"
      class="text-primary custom-file-input my-1 shadow_6 border-transparent"></b-form-file>
    <b-button @click="doUpload" :disabled="form.file == null" class="col-3"
      :class="$root.isMobile ? 'col-12 mt-2' : 'col-3'" size="sm"
      variant="primary primary_gradient text-white fw-bolder spacing_1 rounded-pill my-1">Carica</b-button>
  </div>
</template>
<script>
import { reservationService } from "@/_services";
export default {
  name: "UploadComponent",
  props: ["encr", "dest", "readnre"],
  data() {
    return {
      form: {
        file: null,
        destination: "order",
        name: null,
        has_owner: false,
        encrypt: false,
      },
      response: null,
    };
  },
  methods: {
    doUpload() {
      //console.log("form", this.form);
      var form = new FormData();
      form.append("file", this.form.file);
      form.append("destination", this.form.destination);
      form.append("name", this.form.file.name);
      form.append("has_owner", this.form.has_owner);
      form.append("encrypt", this.form.encrypt);
      if (this.readnre) {
        form.append("read_nre", true);
      }
      var self = this;
      reservationService
        .uploadFile(form)
        .then(function (res) {
          //console.log(res);
          self.response = res.data.data;
          self.$emit("upload-result", res.data.data);
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    handleFile(e) {
      //console.log(this.file);
      //console.log(e.target.files);
    },
    refreshAll() {
      this.form.file = null;
      this.form.destination = "order";
      if (this.dest) {
        this.form.destination = this.dest;
      }
      this.form.name = null;
      this.form.has_owner = false;
      this.form.encrypt = false;
      if (this.encr == true) {
        this.form.encrypt = this.encr;
      }
      this.response = null;
    },
  },
  created() {
    if (this.encr == true) {
      this.form.encrypt = this.encr;
    }
    if (this.dest) {
      this.form.destination = this.dest;
    }
    // //console.log(this.form.destination);
  },
};
</script>
<style lang="scss">
.custom-file-input:lang(en)~.custom-file-label::after,
.custom-file-input:lang(it)~.custom-file-label::after,
.custom-file-input~.custom-file-label[data-browse]::after {
  content: "Scegli" !important;
  color: $primary;
  border-radius: 0 !important;
}

.custom-file-input,
.custom-file-label {
  opacity: 1 !important;
  color: $primary  !important;
  border-color: transparent !important;
  border-radius: 0 !important;
}
</style>