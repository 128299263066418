<template>
  <div>
    <b-row cols="2" class="text-left">
      <b-col>
        <b-button
          v-b-toggle.collapse-companycode
          variant="primary primary_gradient fw-bolder text-white"
          >{{ codeVisible ? "Nascondi" : "Mostra" }} codice
          {{ btnLabel }}</b-button
        >
      </b-col>
      <b-col>
        <b-button
          v-b-toggle.collapse-csendcode
          variant="primary primary_gradient fw-bolder text-white"
          >{{ csendcodeVisible ? "Chiudi" : "Invia codice " + btnLabel }}
        </b-button>
      </b-col>
    </b-row>
    <b-row cols="1">
      <b-collapse id="collapse-companycode" class="my-2">
        <div
          class="panel__box__shadow bg-light-panel border border-light-panel general_border py-2 px-3"
        >
          <h5 class="fw-bolder">Il tuo codice azienda:</h5>
          <p class="card-text fw-bolder text-primary" style="font-size: 1.9rem">
            {{ code }}
          </p>
          <p class="text-justify mb-0">
            Inserendo questo codice in fase di registrazione, i tuoi operatori
            potranno registrarsi nella piattaforma in totale autonomia.
          </p>
          <p class="text-justify">
            Nota: questo codice sarà valido fino alla mezzanotte di oggi.
          </p>
        </div>
      </b-collapse>

      <b-collapse
        id="collapse-csendcode"
        class="mt-2"
        v-model="csendcodeVisible"
      >
        <div
          class="panel__box__shadow bg-light-panel border border-light-panel general_border py-2 px-3"
        >
          <h5 class="fw-bolder">Invia codice azienda</h5>
          <b-form-group
            label="Inserisci email destinatario a cui inviare il codice:"
            class="text-left"
          >
            <b-form-input
              type="email"
              placeholder="inserisci email.. "
              name="email"
              :state="email == null ? null : validEmail"
              v-model="email"
            ></b-form-input>
          </b-form-group>
          <b-button
            variant="sec-2 px-3 text-white fw-bolder spacing_1 rounded-pill"
            :disabled="email == null ? true : !validEmail"
            @click="sendCode"
          >
            <span v-if="!sendingMail">Invia codice</span>
            <b-spinner v-else></b-spinner>
          </b-button>
        </div>
      </b-collapse>
    </b-row>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { companyService } from "@/_services";
export default {
  name: "ShowCode",
  data() {
    return {
      codeVisible: false,
      btnLabel: "utente",
      code: null,
      email: null,
      csendcodeVisible: false,
      sendingMail: false,
    };
  },
  computed: {
    ...mapState("utente", ["status", "user"]),
    ...mapState({
      alert: (state) => state.alert,
    }),
    validEmail() {
      var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      return this.email.match(validRegex) ? true : false;
    },
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    getCode() {
      var hash = this.user.company.hashid;
      var self = this;
      companyService
        .getCode(hash)
        .then(function (res) {
          self.code = res.data.data.code;
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          self.errorAlert("non è stato possibile recuperare il codice");
        });
    },
    sendCode() {
      this.sendingMail = true;
      var form = {
        hash: this.user.company.hashid,
        form: { email: this.email },
      };
      var self = this;
      companyService
        .sendCode(form)
        .then(function (res) {
          window.scrollTo(0, 0);
          self.successAlert(
            "Se l'indirizzo indicato è valido verrà inoltrato il codice azienda via email"
          );
          // self.successAlert("Codice inviato correttamente!");
          self.csendcodeVisible = false;
          self.sendingMail = false;
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          self.errorAlert("non è stato possibile inoltrare il codice");
          self.sendingMail = false;
        });
    },
  },
  created() {
    if (this.user && this.user.type === 1) {
      this.btnLabel = "azienda";
    }
  },
  mounted() {
    this.getCode();
    this.$root.$on("bv::collapse::state", (collapseId, isJustShown) => {
      // //console.log("collapseId:", collapseId);
      // //console.log("isJustShown:", isJustShown);
      if (collapseId == "collapse-companycode") {
        this.codeVisible = isJustShown;
      }
    });
  },
};
</script>
