var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('b-alert',{staticClass:"col-lg-8 col-sm-12 mr-0 ml-auto point",attrs:{"variant":"danger","show":!_vm.user}},[_c('span',[_vm._v("Effettua il login ")])]),_c('h1'),_c('transition-group',{attrs:{"tag":"div","name":"slidevert"}},[_c('div',{key:"bordered_menu_container",staticClass:"col-lg-10 col-sm-11 col-11 mx-auto py-4 mb-2 shadow_7 general_border d-flex flex-wrap bg-white mx-auto"},[_c('b-row',{key:"top_menu_container",staticClass:"w-100"},[_c('farmacia-preferita',{key:"fav_farma",staticClass:"ml-md-4"}),_c('div',{key:"btn_container",staticClass:"col mt-3 mt-md-0"},[_c('b-button',{staticClass:"px-4 px-md-3 px-lg-4 mx-auto",attrs:{"size":"sm","variant":"sec-2 text-white rounded-pill text-uppercase fw-bolder"},on:{"click":function($event){_vm.menuOpen = !_vm.menuOpen}}},[_vm._v(" prenota i servizi ")])],1)],1),_c('b-row',{key:"menu_container",staticClass:"w-100",attrs:{"cols":"1"}},[_c('transition-group',{attrs:{"tag":"div","name":"fade"}},[(_vm.menuOpen)?_c('b-col',{key:"neworder",staticClass:"px-0"},[_c('div',{staticClass:"d-flex flex-column justify-content-end"},[_c('b-row',{staticClass:"point submenu__border bg-white shadow_6 fw-bolder my-2 py-2 col-md-10 col-12 col-lg-6 px-0 px-md-2 ml-auto",attrs:{"align-v":"center"},on:{"click":function($event){return _vm.$router.push({
                  name: 'Nuovo Ordine',
                  params: { type: 'nuova prenotazione' },
                })}}},[_c('span',{staticClass:"text-right col-6 col-lg-8"},[_c('strong',{staticClass:"pl-md-2 pl-1 pr-md-2"},[_vm._v(" Nuova Richiesta")])]),_c('b-col',{staticClass:"text-sm-left text-lg-right"},[_c('b-img',{attrs:{"height":"25%","src":require('@/assets/facile.svg'),"alt":"logo facile"}})],1)],1),(!_vm.$root.prestazioniOnly)?_c('b-row',{staticClass:"point submenu__border bg-white shadow_6 fw-bolder my-2 py-2 col-md-10 col-12 col-lg-6 px-0 px-md-2 ml-auto",attrs:{"align-v":"center"},on:{"click":function($event){return _vm.$router.push({
                  name: 'Nuovo Ordine',
                  params: { type: 'nuovo ordine' },
                })}}},[_c('span',{staticClass:"text-right col-6 col-lg-8"},[_c('strong',{staticClass:"pl-md-2 pl-1 pr-md-2"},[_vm._v(" Nuovo Ordine ")])]),_c('b-col',{staticClass:"text-sm-left text-lg-right"},[_c('b-img',{attrs:{"height":"25%","src":require('@/assets/facile.svg'),"alt":"logo facile"}})],1)],1):_vm._e(),(!_vm.$root.prestazioniOnly)?_c('b-row',{staticClass:"point submenu__border bg-white shadow_6 fw-bolder my-2 py-2 col-md-10 col-12 col-lg-6 px-0 px-md-2 ml-auto",attrs:{"align-v":"center"},on:{"click":function($event){return _vm.$router.push({
                  name: 'Nuovo Ordine',
                  params: { type: 'nuovo teleconsulto' },
                })}}},[_c('span',{staticClass:"text-right col-6 col-lg-8"},[_c('strong',{staticClass:"pl-md-2 pl-1 pr-md-2"},[_vm._v(" Nuovo Teleconsulto ")])]),_c('b-col',{staticClass:"text-sm-left text-lg-right"},[_c('b-img',{attrs:{"height":"25%","src":require('@/assets/connessa.svg'),"alt":"logo facile"}})],1)],1):_vm._e()],1)]):_vm._e()],1)],1)],1)]),(_vm.$route.name == 'CittadinoHome')?_c('b-container',{staticClass:"mb-3"},[_c('b-row',{attrs:{"align-v":"stretch"}},_vm._l((_vm.services),function(service,ind){return _c('b-col',{key:ind,staticClass:"col-12 col-md-6 col-lg-4 my-3"},[_c('div',{staticClass:"bg-white shadow_7 general_border pb-md-3 pb-0 h-100"},[_c('div',{staticClass:"col-6 mx-auto"},[(service.logo)?_c('p',{staticClass:"h1 mb-2 pt-4 pb-1"},[_c('img',{staticClass:"home__card",attrs:{"src":service.logo,"alt":service.name + '_logo'}})]):_c('p',{staticClass:"h1 mt-2 py-4"},[_c('b-icon',{attrs:{"font-scale":"1.4","icon":"calendar2-check","variant":"prim-grad-1"}})],1)]),_c('p',{staticClass:"mb-0 mt-1 fw-bolder text-secondary"},[_vm._v(_vm._s(service.name))]),_c('div',{staticClass:"col-lg-11 col-12 px-0 mx-auto"},[_c('p',{staticClass:"fs-6 text-medium-grey"},[_vm._v(_vm._s(service.description))])]),_c('div',{staticClass:"col-12 pb-3"},[_c('router-link',{staticClass:"primary_gradient btn btn-primary py-1 rounded-pill fw-bolder",attrs:{"tag":"button","disabled":service.disabled,"to":_vm.positions[service.position_ref]}},[_c('span',{staticClass:"fs-5 spacing_1",class:service.disabled ? 'text-secondary' : 'text-white'},[_vm._v(_vm._s(service.btnText))])])],1)])])}),1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }