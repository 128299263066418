<template>
  <div>
    <b-button
      size="sm"
      variant="primary primary_gradient text-white"
      class="mx-1 fw-bolder px-4"
      @click="$bvModal.show('edit_delivery_status_' + delivery.label)"
      >{{ asAsl ? "Cambia" : "Aggiorna" }} stato</b-button
    >
    <b-modal
      :id="'edit_delivery_status_' + delivery.label"
      title="Aggiorna stato pacchetto consegna"
      ok-title="Conferma"
      cancel-title="Annulla"
      ok-variant="primary primary_gradient text-white fw-bolder btn-sm"
      cancel-variant="outline-danger fw-bolder btn-sm"
      footer-class="border-0"
      @ok="updateDeliveryStatus"
      @cancel="$bvModal.hide('edit_delivery_status_' + delivery.label)"
      centered
    >
      <div v-if="deliveryIndex !== null">
        <h6>Seleziona il nuovo stato del pacchetto #{{ deliveryIndex + 1 }}</h6>
        <b-form-select
          class="shadow_6"
          :options="deliveryStatusOptions"
          v-model="status"
        >
        </b-form-select>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { aslService } from "@/_services";
export default {
  name: "CambiaStatoConsegna",
  props: ["delivery", "asAsl", "deliveryIndex"],
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
  },
  data() {
    return {
      status: this.delivery.status,
      deliveryStatusOptions: [
        { value: 1, text: "in preparazione" },
        { value: 2, text: "pronto per il ritiro" },
        // { value: 3, text: "pronto per la consegna al paziente" },
      ],
    };
  },
  mounted() {
    if (!this.asAsl) {
      if (this.delivery.status === 2) {
        this.deliveryStatusOptions = [
          { value: 3, text: "ritirato, pronto per la consegna al paziente" },
        ];
      } else {
        this.deliveryStatusOptions = [
          // { value: 3, text: "pronto per la consegna al paziente" },
          { value: 4, text: "consegnato al paziente" },
          { value: -4, text: "reso dal paziente" },
        ];
      }
    }
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    updateDeliveryStatus() {
      if (this.deliveryIndex !== null) {
        const data = {
          status: this.status,
        };
        const self = this;
        aslService
          .updateDeliveryStatus(data, this.delivery.hashid)
          .then(function (res) {
            self.successAlert("Stato pacchetto modificato correttamente");
            self.$emit("status-changed");
          })
          .catch(function (err) {
            self.errorAlert(
              "Non è stato possibile modificare lo stato del pacchetto"
            );
          });
      }
    },
  },
};
</script>
