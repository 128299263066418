<template>
  <div class="py-3">
    <div>
      <div class="mx-auto fw-bolder" :class="$root.isMobile ? 'col-12 px-0' : 'col-10'">
        <b-form @submit="onSubmit">
          <b-form-group label="Nome questionario*:" label-for="name" description="" class="text-left">
            <b-form-input v-model="form.name" type="text" id="name" placeholder="inserisci nome questionario..."
              class="my-2 shadow_6" required></b-form-input>
          </b-form-group>
          <b-form-group label="Descrizione:" label-for="description" description="" class="text-left">
            <b-form-input v-model="form.description" type="text" id="description"
              placeholder="inserisci descrizione questionario..." class="my-2 shadow_6"></b-form-input>
          </b-form-group>
          <b-form-group label="Informativa:" label-for="info"
            description="Informativa/disclaimer per il questionario che dovrà essere accettata prima della compilazione. Lasciare vuoto questo campo se non necessario."
            class="text-left">
            <b-form-textarea v-model="form.info" type="text" id="info" placeholder="inserisci informativa questionario"
              class="my-2 shadow_6" rows="4"></b-form-textarea>
          </b-form-group>
          <p class="fw-bolder text-left">Tipologia*:</p>
          <b-form-radio-group label="Tipologia" class="text-left" v-model="form.type" @change="setTypeDescription">
            <b-form-radio :value="0">Anonimo</b-form-radio>
            <b-form-radio :value="1">Pseudo-anonimo</b-form-radio>
            <b-form-radio :value="2">In chiaro</b-form-radio>
          </b-form-radio-group>
          <p class="fs-6 text-muted text-left mt-1">
            {{ typeDescriptions[form.type] }}
          </p>
          <p class="fw-bolder text-left">Domande*:</p>
          <p class="fs-6 text-muted text-left mt-1">
            {{ typeMemo[form.type] }} <br> Ove possibile, si consiglia di impostare le domande con
            "opzioni
            multiple" per facilitarne la compilazione da parte dell'utente e minimizzare le difformità nelle risposte.
          </p>
          <b-overlay :show="loading" rounded="sm">
            <draggable v-model="form.template">
              <b-card :title="'Domanda ' + (ind + 1)" v-for="(domanda, ind) in form.template" :key="ind" class="
                  p-1
                  panel__box__shadow
                  bg-light-panel
                  border border-light-panel
                  general_border
                  text-left
                  my-3
                " style="cursor: move">
                <b-form-group>
                  <b-form-input v-model="domanda.q" type="text" placeholder="inserisci domanda..." class="my-2"
                    required></b-form-input>
                  <b-form-radio-group v-model="domanda.o" :name="'domanda' + (ind + 1)" :id="'domanda' + (ind + 1)"
                    class="mt-2" required>
                    <b-form-radio :value="null">Testo libero</b-form-radio>
                    <b-form-radio :value="Array.isArray(domanda.o) ? domanda.o : []">Opzioni multiple</b-form-radio>
                    <b-form-radio :value="'file'">Caricamento Immagine</b-form-radio>
                  </b-form-radio-group>
                  <b-form-tags v-if="domanda.o !== null && Array.isArray(domanda.o)" v-model="domanda.o" class="my-2"
                    placeholder="aggiungi opzioni risposta" remove-on-delete tag-variant="primary px-3 fw-bolder"
                    add-button-text="aggiungi"></b-form-tags>
                </b-form-group>
                <div class="d-flex justify-content-end">
                  <b-button variant="danger font-weight-bold" size="sm" @click="removeItem(ind)">Elimina domanda
                  </b-button>
                </div>
              </b-card>
            </draggable>
          </b-overlay>
          <div class="d-flex mx-auto justify-content-center my-2 pb-3 pt-1">
            <b-button type="button" variant="info info_gradient fw-bolder" @click="addNew" class="mx-auto">
              <b-icon icon="plus"></b-icon> Aggiungi domanda
            </b-button>
            <!-- <b-button
              type="button"
              variant="primary"
              @click="addCompilate"
              class="mx-auto"
              >Aggiungi domande finali</b-button
            > -->

            <b-button type="submit" variant="primary primary_gradient text-white fw-bolder spacing_1" class="mx-auto">
              Salva Questionario</b-button>
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>
<script>
import draggable from "vuedraggable";
export default {
  name: "NuovoQuestionario",
  props: ["activityhash"],
  components: {
    draggable,
  },
  data() {
    return {
      loading: false,
      opzione: null,
      precompilate: [
        {
          q: "Stai prendendo dei farmaci per questo problema?",
          o: ["si", "no"],
          a: "",
          //order: 1,
        },
        {
          q: "Se sì: quali?",
          o: null,
          a: "",
          //order: 2,
        },
        {
          q: "Stai prendendo altri dei farmaci non correlati al problema?",
          o: ["si", "no"],
          a: "",
          //order: 3,
        },
        {
          q: "Se sì: quali?",
          o: null,
          a: "",
          //order: 4,
        },
        {
          q: "Sei allergico a qualche farmaco?",
          o: ["si", "no"],
          a: "",
          // order: 5,
        },
        {
          q: "Se sì: Quali?",
          o: null,
          a: "",
          //order: 6,
        },
        {
          q: "Vuoi aggiungere qualcosa?",
          o: null,
          a: "",
          // order: 7,
        },
      ],
      typeDescriptions: {
        0: "*il questionario non prevede alcun dato personale",
        1: "*il questionario prevede un'associazione ad un soggetto sottoforma di pseudonimo",
        2: "*il questionario contiene dati personali e/o prevede un'associazione ad un soggetto",
      },
      typeMemo: {
        0: "Ricorda di non inserire domande relative a dati anagrafici personali.",
        1: "Ricorda di non inserire domande relative a dati anagrafici personali.",
        2: "Se il questionario non viene compilato direttamente dall'utente, sarà necessario selezionare un utente o arruolarlo se non presente in piattaforma.",
      },
      form: {
        name: null,
        description: null,
        info: "Attenzione: continuando ad usare l'app, acconsenti al trattamento dei tuoi dati sanitari per informare il professionista per un consulto e per finalità di analisi in forma anonima. Maggiori dettagli saranno reperibili alla Privacy policy.",
        type: 0,
        activity_id: null,
        template: [],
      },
    };
  },
  computed: {
    len: function () {
      if (this.form && this.form.template) {
        return this.form.template.length;
      }
      return 0;
    },
  },
  watch: {
    activityhash(val) {
      this.form.activity_id = val;
    },
  },
  methods: {
    addNew() {
      this.loading = true;
      var newQ = {
        q: null,
        o: null,
        a: "",
        // order: this.form.template.length + 1,
      };
      //   this.form.template.unshift(newQ);
      this.form.template.push(newQ);

      this.loading = false;
    },
    addCompilate() {
      // Object.keys(this.precompilate).map((ind) => {
      //   this.precompilate[ind].order =
      //     this.precompilate[ind].order + this.form.template.length;
      // });
      this.form.template.push(...this.precompilate);
    },
    removeItem(index) {
      var newArray = [];
      Object.keys(this.form.template).map((i) => {
        if (parseInt(i) != index) {
          newArray.push(this.form.template[i]);
        }
      });
      this.form.template = newArray;
    },
    setTypeDescription(e) {
      // //console.log(e);
    },
    onSubmit(evt) {
      evt.preventDefault();
      // //console.log(this.form);
      this.$emit("send-questionario", this.form);
      //   !this.validate() && this.sendForm();
    },
  },
  created() {
    if (this.activityhash) {
      this.form.activity_id = this.activityhash;
    }
  },
};
</script>
<style>
input::placeholder {
  color: #205072 !important;
}
</style>
