<template>
  <b-row cols="1" class="my-1" v-if="company && selectable">
    <b-col v-if="surveys">
      <b-form-group label="Per quale motivo richiedi il consulto?" label-for="subtype"
        class="text-left fs-6 text-secondary">
        <b-form-select @change="emitSurvey" name="subtype" class="mx-auto shadow_6" v-model="selectedSurveyIndex"
          required>
          <b-form-select-option v-for="(template, indx) in surveys" :key="indx" :value="indx">{{ template.name }}
          </b-form-select-option>
          <b-form-select-option :value="null" disabled></b-form-select-option>
        </b-form-select>
      </b-form-group>
    </b-col>
  </b-row>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { reservationService } from "@/_services";
export default {
  name: "TeleconsultoSurveySelection",
  props: ["company", "selectable"],
  data() {
    return {
      //
      surveys: null,
      selectedSurveyIndex: null,
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    emitSurvey() {
      this.$emit("selected-tcsurvey", this.surveys[this.selectedSurveyIndex]);
    },
    getConnessaSurveys() {
      var self = this;
      reservationService
        .connessaSurveys(this.company.hashid)
        .then(function (res) {
          //console.log(res);
          self.surveys = res.data.data;
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile recuperare i questionari");
          console.log(err);
        });
    },
  },
  created() {
    this.getConnessaSurveys();
  },
};
</script>