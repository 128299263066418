<template>
  <div class="py-3" v-if="suspendable && !showDone">
    <!-- <div class="d-flex justify-content-between">
      <router-link
        class="fw-bolder text-primary no__hover"
        :to="{ name: 'questionari' }"
      >
        <b-icon icon="arrow-left-circle-fill" variant="primary"></b-icon>
        Questionari</router-link
      >
    </div> -->
    <h5
      class="font-weight-bolder mb-3"
      v-if="suspendable.suspendable_type == 'prenotazione'"
    >
      Questionario per la prenotazione prevista per il
      {{ suspendable.suspendable.data_inizio | moment("DD/MM/YYYY") }}
      ore
      {{ suspendable.suspendable.data_inizio | moment("HH:mm") }}
    </h5>
    <div v-if="survey && !complete" class="py-3">
      <h6
        class="text-prim-grad-1 fw-bolder text-left pb-1 border-bottom border-line-grey"
      >
        {{ survey.name }}
      </h6>
      <p class="text-justify text-medium-grey fw-bolder">
        {{ survey.description }}
      </p>
      <div v-if="survey.info">
        <div v-if="!proceed">
          <p class="text-justify fs-5 informativa__p" v-html="survey.info"></p>
          <!-- <p class="text-justify fs-5 informativa__p">{{ survey.info }}</p> -->
          <b-form-checkbox
            class="text-font-light-grey"
            name="data_consense"
            :value="true"
            :unchecked-value="false"
            v-model="consent"
            :state="consent == true"
          >
            Ho consegnato/comunicato l'informativa al cittadino
          </b-form-checkbox>
          <b-button
            variant="primary primary_indo text-white fw-bolder spacing_1"
            :disabled="!consent"
            @click="proceed = true"
            >Procedi</b-button
          >
        </div>
        <div v-else>
          <FillSurvey
            :survey="survey.template"
            :afteres="true"
            v-on:end-quest="handleFilledSurvey($event)"
          />
        </div>
      </div>
      <div v-else>
        <FillSurvey
          :survey="survey.template"
          :afteres="true"
          v-on:end-quest="handleFilledSurvey($event)"
        />
      </div>
    </div>
    <div v-else-if="complete">
      <h2 class="fw-bolder">Operazione completata.</h2>
      <router-link :to="{ name: 'agenda' }">
        <b-button
          variant="info info_gradient text-white fw-bolder spacing_1 m-2"
        >
          Vai all'agenda
        </b-button>
      </router-link>
    </div>
  </div>
  <div v-else-if="showDone">
    <h5 class="font-weight-bolder">Hai già compilato il questionario.</h5>
    <router-link :to="{ name: 'agenda' }">
      <b-button variant="info info_gradient text-white fw-bolder spacing_1 m-2">
        Vai all'agenda
      </b-button>
    </router-link>
  </div>
</template>
<script>
import { supportService, cittadinoService } from "@/_services";
import { mapState, mapActions } from "vuex";
import FillSurvey from "@/components/utilities/FillSurvey.vue";
export default {
  name: "UserCompilaQuestionario",
  components: {
    FillSurvey,
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
    ...mapState("utente", ["status", "user"]),
  },
  data() {
    return {
      hash: this.$route.params.quest,
      survey: null,
      consent: false,
      proceed: false,
      complete: false,
      suspendable: null,
      showDone: false,
    };
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info",
    }),
    fetchData() {
      var self = this;
      supportService
        .getSuspendedSurveyById(this.hash)
        .then(function (res) {
          self.survey = res.data.data.survey;
          self.suspendable = res.data.data;
          self.consent = self.survey.info == null;
        })
        .catch(function (err) {
          console.log(err.status);
          if (err.status == 404) {
            self.showDone = true;
          } else {
            window.scrollTo(0, 0);
            self.errorAlert("Non è stato possibile recuperare le attività");
          }
        });
      //
    },
    handleFilledSurvey(form) {
      //console.log("FILLED FORM", form);
      //console.log("this.survey", this.survey);
      var temp = {
        info: this.survey.info,
        nome: this.survey.name,
        domande: form,
      };
      var formData = {
        response: JSON.stringify(temp),
        suspended: this.suspendable.hashid,
      };
      var self = this;
      cittadinoService
        .storeSuspendedFilledSurvey(formData)
        .then(function (res) {
          //console.log(res);
          self.complete = true;
          window.scrollTo(0, 0);
          self.successAlert("Questionario inviato correttamente.");
          // self.survey = res.data.data;
          // self.consent = self.survey.info == null;
        })
        .catch(function (err) {
          window.scrollTo(0, 0);
          self.errorAlert("Non è stato possibile inviare il questionario");
          console.log(err);
        });
    },
  },
  created() {
    //console.log(this.$route.params.quest);
    if (this.$route.params.quest) {
      //console.log(this.$route.params.quest);
      this.hash = this.$route.params.quest;
      this.fetchData();
    }

    // this.fetchData();
  },
};
</script>
