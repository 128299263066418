<template>
  <div>
    <div class="d-flex w-100">
      <span class="text-secondary fw-bolder">Modifica stato servizio </span>
      <b-form-checkbox class="ml-2 text-primary" name="servicestatus" :value="1" :unchecked-value="0"
        v-model="formData.form.status" :state="statoservizio === 1" switch size="lg" @change="handleChange">
        {{ statoservizio === 1 ? "Attivo" : "Inattivo" }}
      </b-form-checkbox>
    </div>
    <b-modal centered hide-footer ref="modal-confirmupdate" title="Conferma Operazione"
      header-text-variant="text-center" content-class="text-center" @hidden="cancelOp">
      <p class="mb-4 text-center">
        Sei sicuro di voler
        {{ formData.form.status ? "attivare" : "disattivare" }}
        {{ servicename }}?
      </p>
      <div class="text-center">
        <b-button type="button" variant="outline-danger mx-2" @click="cancelOp">Annulla</b-button>
        <b-button variant="primary text-white primary_gradient mx-2" type="button" @click="submitChanges">Conferma
        </b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: "ServiceStatus",
  props: ["servicename", "statoservizio"],
  computed: {},
  data() {
    return {
      serviceStatus: null,
      serviceToUpdate: null,
      formData: {
        form: {
          status: this.statoservizio,
        },
        service_name: this.servicename,
        service_hash: null,
      },
    };
  },
  methods: {
    handleChange() {
      // //console.log("form changed", this.formData.form);
      this.$refs["modal-confirmupdate"].show();
    },
    submitChanges() {
      if (this.formData) {
        var data = this.formData;
        this.$emit("store-service-status", data);
      }
      this.$refs["modal-confirmupdate"].hide();
    },
    cancelOp() {
      this.$refs["modal-confirmupdate"].hide();
      this.formData.form.status = this.statoservizio;
    },
  },
  watch: {
    statoservizio(val) {
      if (val) {
        // //console.log("service stato watcher", val);
        this.serviceStatus = val;
        this.formData.form.status = val;
      }
    },
    servicename(val) {
      if (val) {
        // //console.log("service name val", val);
        this.serviceToUpdate = val;
      }
    },
  },
};
</script>