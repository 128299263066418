var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.user)?_c('NotificationMenu'):_vm._e(),_c('div',{staticClass:"py-1 py-md-2 py-lg-3 col-12 top_header d-flex flex-wrap align-items-center px-0"},[_c('div',{staticClass:"col-md-2 col-xl-3 col-12 px-0 mx-auto order-1 pl-xl-3"},[_c('div',{class:_vm.$root.isMobile && 'd-flex align-items-center'},[_c('router-link',{staticClass:"app__logo",class:_vm.$root.isMobile ? 'ml-2' : ' px-3 py-4',style:(_vm.cssLogo),attrs:{"to":{ name: 'Home' }}},[_c('span',{staticClass:"p-2 my-2"},[_vm._v(" APP LOGO")])]),(_vm.$root.isMobile)?_c('div',{staticClass:"col-sm-5 col-md-2 px-0 ml-auto mr-1 order-1"},[(_vm.user && _vm.user.username)?_c('b-dropdown',{attrs:{"text":'Benvenuto ' + _vm.welcomeName,"variant":"font-grey-outline fw-bolder bg-transparent text-white border-transparent px-1 text-capitalize","id":"nav_dd"}},[(_vm.user.type !== 2)?_c('b-dropdown-item',{attrs:{"variant":"font-grey-outline"},on:{"click":function($event){return _vm.$router.push({ name: _vm.helpPath })}}},[_c('span',[_c('b-icon',{attrs:{"icon":"book"}}),_vm._v(" FAQ ")],1)]):(
                _vm.user.permessi.includes('gestire aziende propria asl') &&
                _vm.user.anagrafica &&
                _vm.user.anagrafica.regione === 'lombardia'
              )?_c('b-dropdown-item',{attrs:{"variant":"font-grey-outline"},on:{"click":_vm.openManualeATS}},[_c('span',[_c('b-icon',{attrs:{"icon":"book"}}),_vm._v(" Manuale ATS ")],1)]):_vm._e(),(_vm.user.type === 1)?[_c('b-dropdown-item',{attrs:{"variant":"font-grey-outline"},on:{"click":function($event){return _vm.$router.push({ name: 'materiale informativo' })}}},[_c('span',[_c('b-icon',{attrs:{"icon":"cloud-download"}}),_vm._v(" Materiale informativo ")],1)]),(_vm.canSeeImpostazioni)?_c('b-dropdown-item',{attrs:{"variant":"font-grey-outline"},on:{"click":function($event){return _vm.$router.push({
                    name: 'Impostazioni',
                    query: { scope: 'operatori' }
                  })}}},[_c('span',[_c('b-icon',{attrs:{"icon":"shield-lock"}}),_vm._v(" Codice Azienda ")],1)]):_vm._e()]:_vm._e(),_c('b-dropdown-item',{attrs:{"variant":"font-grey-outline"},on:{"click":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('span',[_c('b-icon',{attrs:{"icon":"door-open-fill"}}),_vm._v(" Esci ")],1)])],2):_vm._e()],1):_vm._e()],1)]),(!_vm.$root.menuLeft)?_c('div',{staticClass:"col bg-white shadow_3 px-2 d-flex justify-content-center mx-auto general_border border border-white mbl__fx",class:_vm.$root.isMobile ? 'order-3' : 'order-2'},[_c('router-link',{staticClass:"my-1 my-md-3 mx-auto",class:this.$route.path === _vm.homePath && 'router-link-exact-active',attrs:{"to":{ name: 'Home' }}},[_c('b-icon',{attrs:{"font-scale":"1.5","icon":this.$route.path === _vm.homePath ? 'house-door-fill' : 'house-door'}}),_c('p',{staticClass:"mb-0 fs-5"},[_vm._v("Home")])],1),(_vm.canSeeProfilo)?_c('router-link',{staticClass:"my-1 my-md-3 mx-auto",attrs:{"to":{ name: 'Profilo' }}},[_c('b-icon',{attrs:{"font-scale":"1.5","icon":this.$route.name === 'Profilo' ? 'person-fill' : 'person'}}),_c('p',{staticClass:"mb-0 fs-5"},[_vm._v("Profilo")])],1):_vm._e(),(_vm.canSeeImpostazioni)?_c('router-link',{staticClass:"my-1 my-md-3 mx-auto",attrs:{"to":{ name: 'Impostazioni' }}},[_c('b-icon',{attrs:{"font-scale":"1.5","icon":this.$route.name === 'Impostazioni' ? 'gear-fill' : 'gear'}}),_c('p',{staticClass:"mb-0 fs-5"},[_vm._v("Impostazioni")])],1):_vm._e()],1):_c('div',{staticClass:"col px-2 mx-auto mbl__fx",class:_vm.$root.isMobile ? 'order-3' : 'order-2'},[(_vm.user.type === 1)?_c('div',[(
            _vm.$route.name === 'prenotazioni' ||
            _vm.$route.name === 'calendario prenotazioni'
          )?_c('PrenotazioniMenu',{staticClass:"bg-white shadow_3 px-2 d-flex justify-content-center mx-auto general_border border border-white"}):(
            _vm.$route.name === 'attivita' ||
            _vm.$route.name === 'questionari' ||
            _vm.$route.name === 'statistiche'
          )?_c('AttivitaMenu',{staticClass:"bg-white shadow_3 px-2 d-flex justify-content-center mx-auto general_border border border-white"}):(
            _vm.$route.name === 'teleconsulto' ||
            _vm.$route.name === 'videoconsulto' ||
            _vm.$route.name === 'telecalendario'
          )?_c('TeleconsultoMenu',{staticClass:"bg-white shadow_3 px-2 d-flex justify-content-center mx-auto general_border border border-white"}):_c('div',{staticClass:"bg-white shadow_3 px-2 d-flex justify-content-center mx-auto general_border border border-white"},[_c('p',{staticClass:"mb-0 py-1 py-md-4 text-capitalize fw-bolder"},[_vm._v(" "+_vm._s(_vm.$route.params.type ? _vm.$route.params.type : _vm.$route.name)+" ")])])],1):_c('div',{staticClass:"bg-white shadow_3 px-2 d-flex justify-content-center mx-auto general_border border border-white"},[(this.$route.name === 'EmailVerification')?_c('p',{staticClass:"mb-0 py-1 py-md-4 text-capitalize fw-bolder"},[_vm._v(" Verifica Email ")]):_c('p',{staticClass:"mb-0 py-1 py-md-4 text-capitalize fw-bolder"},[_vm._v(" "+_vm._s(_vm.$route.params.type ? _vm.$route.params.type : _vm.$route.name)+" ")])])]),(!_vm.$root.isMobile)?_c('div',{staticClass:"col-md-2 col-xl-3 col-12 px-0 order-2 ml-0 mr-auto",class:_vm.$root.isTablet ? 'text-left' : 'text-center'},[(_vm.user && _vm.user.username)?_c('b-dropdown',{attrs:{"text":'Benvenuto ' + _vm.welcomeName,"variant":"font-grey-outline fw-bolder bg-transparent text-white border-transparent px-1 text-capitalize mt-lg-4 pt-lg-4","id":"nav_dd"}},[(_vm.user.type !== 2)?_c('b-dropdown-item',{attrs:{"variant":"font-grey-outline"},on:{"click":function($event){return _vm.$router.push({ name: _vm.helpPath })}}},[_c('span',[_c('b-icon',{attrs:{"icon":"book"}}),_vm._v(" FAQ ")],1)]):(
            _vm.user.permessi.includes('gestire aziende propria asl') &&
            _vm.user.anagrafica &&
            _vm.user.anagrafica.regione === 'lombardia'
          )?_c('b-dropdown-item',{attrs:{"variant":"font-grey-outline"},on:{"click":_vm.openManualeATS}},[_c('span',[_c('b-icon',{attrs:{"icon":"book"}}),_vm._v(" Manuale ATS ")],1)]):_vm._e(),(_vm.user.type === 1)?[_c('b-dropdown-item',{attrs:{"variant":"font-grey-outline"},on:{"click":function($event){return _vm.$router.push({ name: 'materiale informativo' })}}},[_c('span',[_c('b-icon',{attrs:{"icon":"cloud-download"}}),_vm._v(" Materiale informativo ")],1)]),(_vm.canSeeImpostazioni)?_c('b-dropdown-item',{attrs:{"variant":"font-grey-outline"},on:{"click":function($event){return _vm.$router.push({
                name: 'Impostazioni',
                query: { scope: 'operatori' }
              })}}},[_c('span',[_c('b-icon',{attrs:{"icon":"shield-lock"}}),_vm._v(" Codice Azienda ")],1)]):_vm._e()]:_vm._e(),_c('b-dropdown-item',{attrs:{"variant":"font-grey-outline"},on:{"click":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('span',[_c('b-icon',{attrs:{"icon":"door-open-fill"}}),_vm._v(" Esci ")],1)])],2):_vm._e()],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }